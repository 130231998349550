import styled from 'styled-components';

export const DialogContainer = styled.ul`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  max-height: 600px;
  overflow-y: scroll;
  box-sizing: border-box;
`;

export const ModalCard = styled.li`
  width: 100%;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  list-style: none;
  margin-bottom: 30px;
`;

export const CardHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryGrey};
  border-radius: 15px 15px 0 0;
  padding-left: 30px;
`;

export const CardTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  width: 45%;
`;

export const HubCardInfoContainer = styled.ul`
  max-height: 450px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ${({ isRoot }) => isRoot && 'align-items: center'}
  padding: 30px;
`;

export const CurrentHubContainer = styled.li`
  list-style: none;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.primaryGrey}`};
`;

export const CardSmallInfoContainer = styled.div`
  padding: 0 30px;
  display: flex;
  ${({ type }) =>
    type === 'Site'
      ? {
          height: '70px',
          alignItems: 'center',
        }
      : {
          height: '100px',
          flexDirection: 'column',
          flexWrap: 'wrap',
        }}
`;

export const CardText = styled.p`
  width: 55%;
  font-size: 14px;
  color: ${({ theme }) => theme.darkGrey};
`;

export const CurrentHubValue = styled(CardText)`
  width: unset;
  font-size: 16px;
`;

export const CardBoldText = styled.b`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const IconContainer = styled.div`
  svg {
    transform: scale(1.3);
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const AlertText = styled.h2`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin: 0 0 40px;
`;
