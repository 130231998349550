import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const styles = {
  radioButton: {
    padding: '5px 0px',
    display: 'flex',
  },
};

const buttons = ['Daily', 'Weekly', 'Monthly', 'Yearly'];

const RadioGroup = ({ input, meta, style }) => (
  <RadioButtonGroup
    name="taskFrequency"
    defaultSelected={input.value.length === 0 ? 0 : input.value}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    {buttons.map((label, index) => (
      <RadioButton
        key={label}
        value={index}
        label={label}
        iconStyle={{
          fill: input.value.length === 0 || input.value === index ? theme.primaryScheduler : theme.darkGrey,
          marginRight: '-5px',
        }}
        style={styles.radioButton}
      />
    ))}
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RadioGroup;
