import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import { isEqual } from 'lodash';

import { useAppSelector } from 'redux/reducers';
import { actions as tasksActions } from 'redux/tasks';
import { ReportPhotoType } from 'types/task';

import { AddPhotoIcon } from 'components/Layout/Icons';
import { AdditionalInfo } from '../AdditionalInfo';
import { ReportPhotoDialog } from '../ReportPhotoDialog';
import * as S from './AddReportPhotosSectionStyles';

type PropsType = {
  formik: any;
  taskId: string;
};

export const AddReportPhotosSection = ({ formik, taskId }: PropsType) => {
  const reportPhotos: ReportPhotoType[] = useAppSelector(state => state.tasks.reportPhotos);
  const dispatch = useDispatch();

  const { selectedSection } = formik.values;
  const hasTheReportPhotoBeenSaved = isEqual(reportPhotos, formik.values.reportPhotos);
  const [newReportPhoto] = formik.values.reportPhotos || [];

  const showSelectedSection = (newSelectedSection: string) =>
    formik.setFieldValue('selectedSection', newSelectedSection);

  const hideSelectedSection = () => formik.setFieldValue('selectedSection', '');

  const chooseReportPhotos = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target.files![0] || {};
    if (name) {
      const reportPhoto: Partial<ReportPhotoType> = {
        FileName: name,
      };

      formik.setFieldValue('newPhoto', event.target.files![0]);
      formik.setFieldValue('reportPhotos', [reportPhoto, ...formik.values.reportPhotos]);
    }
  };

  const uploadReportPhoto = () => {
    dispatch(
      tasksActions.uploadReportPhotoRequest({
        file: formik.values.newPhoto,
        assignedTaskId: taskId,
      }),
    );
    formik.setFieldValue('newPhoto', {});
    formik.values.reportPhotos.shift();
    formik.setFieldValue('reportPhotos', formik.values.reportPhotos);
  };

  const onReportPhotosClickHandler = () =>
    selectedSection === 'photos' ? hideSelectedSection() : showSelectedSection('photos');

  return (
    <AdditionalInfo
      name="Add Report Photos"
      leftIcon={<AddPhotoIcon />}
      rightIcon={
        <S.IconWrapper onClick={onReportPhotosClickHandler} hasMarginRight>
          {selectedSection === 'photos' ? <S.ArrowDownIcon /> : <S.ArrowUpIcon />}
        </S.IconWrapper>
      }
      isShown={selectedSection === 'photos'}
      content={
        <S.ReportPhotosContainer>
          <S.List>
            {formik.values.reportPhotos.length ? (
              formik.values.reportPhotos.map((photo: ReportPhotoType) => {
                const showReportPhoto = () => {
                  dispatch(tasksActions.fetchReportPhotoRequest(photo.FileId));
                  NiceModal.show(ReportPhotoDialog, { name: photo.FileName });
                };

                const removeReportPhoto = () => {
                  dispatch(tasksActions.deleteReportPhotoRequest(photo));
                };

                const setIsIncludedIntoReport = (e: React.MouseEvent, checked: boolean) => {
                  const payload = { ...photo, IsIncludedIntoReport: checked };
                  dispatch(tasksActions.editReportPhotoRequest(payload));
                };

                return (
                  <S.ListItem key={photo?.Id}>
                    <S.PhotoName>{photo?.FileName}</S.PhotoName>
                    {reportPhotos.includes(photo) && (
                      <S.ActionsContainer>
                        <S.CheckBox checked={photo.IsIncludedIntoReport} onCheck={setIsIncludedIntoReport} />
                        <S.IconWrapper hasMarginRight onClick={showReportPhoto}>
                          <S.EyeIcon />
                        </S.IconWrapper>
                        <S.IconWrapper onClick={removeReportPhoto}>
                          <S.TrashIcon />
                        </S.IconWrapper>
                      </S.ActionsContainer>
                    )}
                  </S.ListItem>
                );
              })
            ) : (
              <S.EmptyText>This task has no report photos.</S.EmptyText>
            )}
          </S.List>

          <S.ButtonsContainer>
            <input
              type="file"
              id="choose-file-btn"
              hidden
              name="reportPhotos"
              disabled={!hasTheReportPhotoBeenSaved}
              onChange={chooseReportPhotos}
            />
            <S.ChooseFileButton htmlFor="choose-file-btn" disabled={!hasTheReportPhotoBeenSaved}>
              Choose File
            </S.ChooseFileButton>
            {!hasTheReportPhotoBeenSaved && <S.FileName>{newReportPhoto?.Name}</S.FileName>}
            <S.UploadPhotoButton type="button" disabled={!formik.values.newPhoto} onClick={uploadReportPhoto}>
              Upload New Photo
            </S.UploadPhotoButton>
          </S.ButtonsContainer>
        </S.ReportPhotosContainer>
      }
    />
  );
};
