import {
  addInventoryEntities,
  addInventoryPeriod,
  createPartTransaction,
  deleteInventoryEntity,
  editInventoryPart,
  editInventoryPartGroup,
  fetchInventoryListPartGroups,
  fetchInventoryListParts,
  fetchInventoryListPeriods,
  getPartGroupsByParam,
  getPartTransactionsList,
  getPartsByParam,
  setTenantInventory,
  getInventoryDashboardInfo,
} from 'http/inventory';
import { call, put, takeEvery } from 'redux-saga/effects';
import { actions } from './index';
import { openErrorDialog } from 'redux/errorHandler';
import { formatServerError } from 'helpers';

function* activateTenantInventorySaga({ payload }) {
  try {
    yield call(setTenantInventory, payload);
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getInventoryPeriodsList() {
  try {
    const { data } = yield call(fetchInventoryListPeriods);
    yield put(actions.getInventoryPeriodsListSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* createInventoryPeriod() {
  try {
    yield call(addInventoryPeriod);
    yield* getInventoryPeriodsList();
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getInventoryListParts({ payload }) {
  try {
    const { data } = yield call(fetchInventoryListParts, payload);
    yield put(actions.getInventoryPartsListSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getInventoryPartGroupsList({ payload }) {
  try {
    const { data } = yield call(fetchInventoryListPartGroups, payload);
    yield put(actions.getInventoryPartGroupsListSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* createInventoryEntities({ payload }) {
  try {
    yield call(addInventoryEntities, payload);
    if (payload.entityType === 'Part') {
      yield* getInventoryListParts({ payload: payload.inventoryPeriodId });
    } else {
      yield* getInventoryPartGroupsList({ payload: payload.inventoryPeriodId });
    }
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* fetchPartTransactionsList({ payload }) {
  try {
    const { data } = yield call(getPartTransactionsList, payload);
    yield put(actions.fetchPartTransactionsListSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* addPartTransaction({ payload }) {
  try {
    yield call(createPartTransaction, payload);
    const dto = {
      inventoryEntityId: payload.dto.inventoryEntityId,
      entityType: payload.entityType,
    };
    yield put(actions.fetchPartTransactionsListRequest(dto));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* fetchPartsByParam({ payload }) {
  try {
    const { data } = yield call(getPartsByParam, payload);
    yield put(actions.getPartsByParamSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* fetchPartGroupsByParam({ payload }) {
  try {
    const { data } = yield call(getPartGroupsByParam, payload);
    yield put(actions.getPartGroupsByParamSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* changeInventoryPart({ payload: { dto, inventoryPeriodId } }) {
  try {
    yield call(editInventoryPart, dto);
    yield put(actions.getInventoryPartsListRequest(inventoryPeriodId));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* changeInventoryPartGroup({ payload: { dto, inventoryPeriodId } }) {
  try {
    yield call(editInventoryPartGroup, dto);
    yield put(actions.getInventoryPartGroupsListRequest(inventoryPeriodId));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* removeInventoryEntity({ payload: { dto, inventoryPeriodId } }) {
  try {
    yield call(deleteInventoryEntity, dto);
    if (dto.entityType === 'Part') {
      yield put(actions.getInventoryPartsListRequest(inventoryPeriodId));
    } else {
      yield put(actions.getInventoryPartGroupsListRequest(inventoryPeriodId));
    }
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* fetchInventoryDashboardInfo() {
  try {
    const { data } = yield call(getInventoryDashboardInfo);

    yield put(actions.getInventoryDashboardInfoSuccess(data.entity));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setInventoryError(error));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

const inventorySagas = [
  takeEvery(actions.activateTenantInventory, activateTenantInventorySaga),
  takeEvery(actions.getInventoryPeriodsListRequest, getInventoryPeriodsList),
  takeEvery(actions.createInventoryPeriodRequest, createInventoryPeriod),
  takeEvery(actions.getInventoryPartsListRequest, getInventoryListParts),
  takeEvery(actions.getInventoryPartGroupsListRequest, getInventoryPartGroupsList),
  takeEvery(actions.createInventoryEntitiesRequest, createInventoryEntities),
  takeEvery(actions.fetchPartTransactionsListRequest, fetchPartTransactionsList),
  takeEvery(actions.createPartTransactionRequest, addPartTransaction),
  takeEvery(actions.getPartsByParamRequest, fetchPartsByParam),
  takeEvery(actions.getPartGroupsByParamRequest, fetchPartGroupsByParam),
  takeEvery(actions.editInventoryPartRequest, changeInventoryPart),
  takeEvery(actions.editInventoryPartGroupRequest, changeInventoryPartGroup),
  takeEvery(actions.deleteInventoryEntityRequest, removeInventoryEntity),
  takeEvery(actions.getInventoryDashboardInfoRequest, fetchInventoryDashboardInfo),
];

export default inventorySagas;
