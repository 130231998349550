import styled from 'styled-components';
import MenuItem from 'material-ui/MenuItem';
import Menu from 'material-ui/Menu';

import { ButtonFlat } from 'components/Layout/Buttons';
import { PopoverContainer } from 'components/Layout/Containers';

export const Button = styled(ButtonFlat)`
  min-width: unset !important;
  color: ${({ color }) => color} !important;

  & > div {
    @media screen and (max-width: 568px) {
      & > span {
        display: none;
      }
      & > svg {
        margin: 0 12px !important;
      }
    }
  }
`;

export const Container = styled.div``;

export const MenuContainer = styled(Menu)`
  display: flex !important;
  flex-direction: column;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 202px !important;
  max-width: 180px;
  box-sizing: border-box;

  ${props => props.styles}
`;

const ItemDisabled = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
`;

export const MenuTitle = styled(ItemDisabled)`
  background: ${({ theme }) => theme.headerColor} !important;
  color: ${({ theme }) => theme.primaryWhite} !important;
  font-size: 18px !important;
`;

export const EmptyItem = styled(ItemDisabled)`
  margin: 5px 0;
`;

export const Item = styled(MenuItem)`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey} !important;
  box-sizing: border-box;
  div {
    overflow-x: hidden !important;
    text-overflow: ellipsis !important;
  }

  ${props => props.styles}
`;

export const Popover = styled(PopoverContainer)`
  & > div {
    overflow-y: unset !important;
  }
`;
