import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as shortcutActions } from 'redux/shortcuts';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import TimePickerAdapter from 'components/Forms/TimePickerAdapter';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { countUnitsAndItems } from 'components/Layout/ShortcutRules/helpers';
import { isDayInThePast } from 'helpers';

import TaskRadioGroup from './TaskFrequencyRadioGroup';
import IntervalRadioGroup from './RepeatIntervalRadioGroup';
import IntervalTable from './IntervalTable';
import validate from './validator';

import * as S from './styled';

const overrideValues = {
  0: 1,
  1: 7,
  2: 31,
  4: 365,
};

const generateDate = date => {
  const modifedDate = new Date(date);
  // const modifedDate = new Date(new Date(date.setHours(0)).setMinutes(0));
  // const finalDate = moment(modifedDate).add(time.getHours(), 'h').add(time.getMinutes(), 'm').toDate();
  const utcDate = Date.UTC(modifedDate.getFullYear(), modifedDate.getMonth(), modifedDate.getDate(), 0, 0, 0);
  const preparedDate = moment.utc(utcDate).startOf('day').toISOString();
  return preparedDate;
};

const ShortcutForm = ({ currentSchedule }) => (
  <S.FormContainer>
    <S.Block>
      <S.Label>Task Frequency</S.Label>
      <S.FieldLabel>Repeat Cycle</S.FieldLabel>
      <Field
        component={TaskRadioGroup}
        name="RepeatCycle"
        style={S.radioStyle}
        initialValue={currentSchedule.Id ? currentSchedule.RepeatCycle : 0}
      />
      <S.FieldBlockContainer>
        <S.FieldBlock>
          <Field
            name="StartDate"
            component={DatePickerAdapter}
            shouldDisableDate={isDayInThePast}
            floatingLabelText="Start Date"
            initialValue={currentSchedule.Id ? new Date(currentSchedule.StartDate) : null}
          />
        </S.FieldBlock>
        <S.FieldBlock>
          <Field
            name="EndDate"
            component={DatePickerAdapter}
            shouldDisableDate={isDayInThePast}
            floatingLabelText="End Date"
            initialValue={currentSchedule.Id ? new Date(currentSchedule.EndDate) : null}
          />
        </S.FieldBlock>
        <S.FieldBlock>
          <Field
            name="startTime"
            component={TimePickerAdapter}
            floatingLabelText="Start Time"
            initialValue={currentSchedule.Id ? new Date(currentSchedule.StartDate) : 0}
          />
        </S.FieldBlock>
        <S.FieldBlock>
          <Field
            name="endTime"
            component={TimePickerAdapter}
            floatingLabelText="End Time"
            initialValue={currentSchedule.Id ? new Date(currentSchedule.EndDate) : 0}
          />
        </S.FieldBlock>
      </S.FieldBlockContainer>
    </S.Block>
    <S.Block>
      <S.Label>Repeat Interval&nbsp;(next period to schedule tasks in)</S.Label>
      <S.SecondaryContainer>
        <Field
          component={IntervalRadioGroup}
          name="RepeatInterval"
          style={S.radioIntervalStyle}
          initialValue={currentSchedule.Id ? currentSchedule.RepeatInterval : 1}
        />
        <IntervalTable />
      </S.SecondaryContainer>
    </S.Block>
  </S.FormContainer>
);

class SiteSetup extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      sendSiteScheduleRequest: PropTypes.func.isRequired,
      editSiteScheduleRequest: PropTypes.func.isRequired,
    }).isRequired,
    shortcut: PropTypes.shape().isRequired,
    currentSite: PropTypes.shape().isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    currentSchedule: PropTypes.shape().isRequired,
    listOfItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setOverride: PropTypes.func.isRequired,
    schedulesTrigger: PropTypes.number.isRequired,
  };

  state = {
    isConfirmationOpened: false,
  };

  onSend = ({ values }) => {
    const valuesToSend = {
      RepeatCycle: values.RepeatCycle ? values.RepeatCycle : 0,
      RepeatInterval: values.RepeatInterval ? values.RepeatInterval : 1,
      StartDate: generateDate(values.StartDate, values.startTime),
      EndDate: generateDate(values.EndDate, values.endTime),
      SiteID: this.props.currentSite.Id,
      ShortcutID: this.props.shortcut.Id,
    };
    if (!this.props.currentSchedule.Id) {
      this.props.actions.sendSiteScheduleRequest(valuesToSend);
      this.props.onNext();
    } else {
      this.props.actions.editSiteScheduleRequest({
        ...valuesToSend,
        id: this.props.currentSchedule.Id,
        AssignmentType: null,
        onNext: this.props.onNext,
      });
    }
  };

  cancelConfirmationDialog = ({ values }) => {
    this.props.setOverride(false);
    this.setState({ isConfirmationOpened: false });
    this.onSend({ values });
  };

  confirmConfirmationDialog = ({ values }) => {
    this.props.setOverride(true);
    this.setState({ isConfirmationOpened: false });
    this.onSend({ values });
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationOpened: false });
  };

  onSubmitForm = ({ values }) => {
    const { schedulesTrigger } = this.props;
    const diff = moment.duration(moment(values.StartDate).diff(moment(new Date()))).asDays();
    if (diff < overrideValues[schedulesTrigger]) {
      this.setState({ isConfirmationOpened: true });
    } else {
      this.onSend({ values });
    }
  };

  onPrevious = e => {
    e.preventDefault();
    this.props.onBack();
  };

  render() {
    const { currentSite, shortcut, currentSchedule, listOfItems } = this.props;
    const unitsAndItems = countUnitsAndItems(listOfItems, currentSite.Id);

    return (
      <S.Container>
        <S.MainTitle>{shortcut && shortcut.Name}</S.MainTitle>
        <S.SiteBlock>
          <S.SecondaryTitle>
            {currentSite && `${currentSite.AdditionalSiteID?.substring(0, 5)} ${currentSite.Name}`}
          </S.SecondaryTitle>
          <S.Title>{`${unitsAndItems[0]} Units`}</S.Title>
          <S.Title>{`${unitsAndItems[1]} Items`}</S.Title>
        </S.SiteBlock>
        <S.Step>4 of 5</S.Step>
        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <ShortcutForm values={values} currentSchedule={currentSchedule} />
              <ActionsContainer>
                <ActionButton label="Previous" isNext onClick={this.onPrevious} />
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
              <ConfirmationDialog
                label="YES - OVERRIDE"
                labelCancel="NO - start next period"
                width={'40%'}
                isCancelNext
                text={
                  <S.ConfirmationContainer>
                    <S.ConfirmationText>Start date is sooner than lead time for creating tasks!</S.ConfirmationText>
                    <S.ConfirmationText>
                      <b>Override the lead time?</b>
                    </S.ConfirmationText>
                  </S.ConfirmationContainer>
                }
                open={this.state.isConfirmationOpened}
                onClick={() => this.confirmConfirmationDialog({ values })}
                handleClose={this.closeConfirmationDialog}
                onCancelClick={() => this.cancelConfirmationDialog({ values })}
              />
            </form>
          )}
        />
      </S.Container>
    );
  }
}

ShortcutForm.propTypes = {
  currentSchedule: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ shortcuts, settings }) => ({
  list: shortcuts.shortcutSitesInfo,
  shortcut: shortcuts.shortcut,
  isLoading: shortcuts.isLoading,
  currentSchedule: shortcuts.currentSchedule,
  listOfItems: shortcuts.listOfItems,
  schedulesTrigger: settings.list.TasksSchedulesTrigger,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteSetup);
