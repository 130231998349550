import styled from 'styled-components';

const Form = styled.form`
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
  width: 450px;
  overflow-x: hidden;
`;

const Info = styled.div`
  padding: 20px;
`;

const UnitName = styled.div``;

const ChecklistName = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
`;

const Section = styled.div`
  padding: 10px 20px;
`;

const ItemName = styled.div`
  font-weight: bold;
`;

const ScrollContainer = styled.div`
  max-height: calc(70vh - 78px - 56px);
  overflow-y: auto;
`;

export { Form, Info, UnitName, ChecklistName, Section, ItemName, ScrollContainer };
