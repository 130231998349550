import React, { PureComponent } from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import theme from 'theme';

import { actions as statusIndexActions } from 'redux/statusIndex';
import { ButtonIcon } from 'components/Layout/Buttons';
import Loader from 'components/Loader';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import { ActionsContainer, Dialog } from 'components/Dialogs/v1/Base';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { IndexItemType } from 'configs/propTypes';
import { destringifyKeys, stringifyKeys, compareKeys } from 'helpers';

import * as S from './styled';

class EditColumns extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    indexItemConfig: PropTypes.object.isRequired,
    indexItem: IndexItemType.isRequired,
    status: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      updateIndexItemConfigRequest: PropTypes.func.isRequired,
      editIndexItemRequest: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    dataColumns: {},
    isEditMode: false,
    editingItem: '',
  };

  componentDidUpdate() {
    const { status, indexItemConfig } = this.props;
    if (status === 'updated') {
      this.setState({ dataColumns: indexItemConfig.DataColumns });
    }
  }

  addNewColumn = (values, form) => {
    const newDataColumn = { ...this.state.dataColumns, [values.newColumnTitle]: values.newColumnTitle };
    this.setState({ dataColumns: newDataColumn });
    form.restart();
  };

  activateEditMode = () => this.setState({ isEditMode: true });

  activateViewMode = () => this.setState({ isEditMode: false });

  editColumnHandler = title => {
    this.setState({ editingItem: title });
  };

  saveColumnHandler = (column, values) => {
    values = destringifyKeys(values);

    const newDataColumns = Object.keys(this.state.dataColumns).map(key => {
      if (key === column) {
        key = values[column];
      }

      return key;
    });
    const dataColumns = newDataColumns.reduce((acc, key) => ({ ...acc, [key]: key }), {});

    this.setState({ dataColumns });
    this.setState({ editingItem: '' });
  };

  deleteColumn = title => {
    const { dataColumns } = this.state;
    const newDataColumns = { ...dataColumns };
    delete newDataColumns[title];

    this.setState({ dataColumns: newDataColumns });
  };

  onSubmitForm = values => {
    values = destringifyKeys(values);
    const { actions, indexItemConfig, indexItem, onCloseDialog } = this.props;
    const { dataColumns } = this.state;
    const dataColumnsWithEmptyValue = Object.keys(dataColumns).reduce((acc, key) => ({ ...acc, [key]: '' }), {});
    const isDataColumnsEmpty = isEmpty(indexItemConfig.DataColumns);
    const isDataColumnsEqualWithPrevious = isEqual(dataColumns, indexItemConfig.DataColumns);

    const payload = {
      indexItemId: isDataColumnsEmpty ? indexItem.Id : indexItemConfig.IndexItemId,
      id: indexItemConfig.Id,
      indexItemName: values.IndexItemName,
      dataColumns: dataColumnsWithEmptyValue,
    };
    const updatedIndexItem = {
      ...indexItem,
      AdditionalIndexID: values.AdditionalIndexID,
      Name: values.IndexItemName,
    };

    if (!isDataColumnsEqualWithPrevious) {
      actions.updateIndexItemConfigRequest(payload);
    }

    actions.editIndexItemRequest(updatedIndexItem);
    onCloseDialog();
  };

  onRequestClose = () => {
    const { onCloseDialog, indexItemConfig } = this.props;

    this.setState({
      dataColumns: indexItemConfig.DataColumns,
      isEditMode: false,
      editingItem: '',
    });
    onCloseDialog();
  };

  render() {
    const { isOpen, indexItemConfig, isLoading, indexItem } = this.props;
    const { isEditMode, dataColumns, editingItem } = this.state;
    const isDataColumnsEmpty = isEmpty(dataColumns);
    const dataColumnsWithKeyAndValue =
      !isDataColumnsEmpty && Object.keys(dataColumns).reduce((acc, key) => ({ ...acc, [key]: key }), {});
    const isDataColumnsEqualWithPrevious = isEqual(dataColumns, indexItemConfig.DataColumns);
    const newDataColumns = !isDataColumnsEmpty && compareKeys(dataColumns, indexItemConfig.DataColumns);

    const initialValues = {
      AdditionalIndexID: indexItem.AdditionalIndexID,
      IndexItemName: indexItem.Name || indexItemConfig.IndexItemName,
      ...dataColumnsWithKeyAndValue,
    };

    return (
      <Dialog
        open={isOpen}
        title="Edit Columns"
        maxWidth="550px"
        onRequestClose={this.onRequestClose}
        titleColor={theme.headerColor}
      >
        <Loader isLoading={isLoading} size="large" />
        <S.DialogContainer>
          <Form
            onSubmit={this.onSubmitForm}
            initialValues={stringifyKeys(initialValues)}
            render={({ handleSubmit, form, values }) => {
              const { dirty } = form.getState();

              return (
                <form onSubmit={handleSubmit}>
                  <S.TitleContainer>
                    <div>Data for Index Item</div>
                    {isEditMode ? (
                      <S.FormContainer>
                        <Field component={TextFieldAdapter} onBlur={this.activateViewMode} name="keyIndexItemName" />
                        <Field
                          onBlur={this.activateViewMode}
                          component={TextFieldAdapter}
                          name="keyAdditionalIndexID"
                        />
                      </S.FormContainer>
                    ) : (
                      <>
                        <S.Title onDoubleClick={this.activateEditMode}>{values.keyIndexItemName}</S.Title>
                        <div onDoubleClick={this.activateEditMode}>
                          <b>ID:</b> {values.keyAdditionalIndexID}
                        </div>
                      </>
                    )}
                  </S.TitleContainer>
                  <BorderRadiusContainer column justify="">
                    <S.Label>New Data Column</S.Label>
                    <S.FormContainer>
                      <Field component={TextFieldAdapter} name="newColumnTitle" floatingLabelText="New Column Title" />
                    </S.FormContainer>

                    <ActionsContainer isColumn paddingValue={14}>
                      <S.ActionButton
                        type="button"
                        isDisable={!values.newColumnTitle}
                        label="Save / Add New Column"
                        onClick={() => this.addNewColumn(values, form)}
                      />
                    </ActionsContainer>
                  </BorderRadiusContainer>

                  {!isDataColumnsEmpty && (
                    <BorderRadiusContainer column justify="space-between">
                      <S.Label>Dynamic Data Columns</S.Label>

                      <S.TextFieldContainer>
                        {Object.keys(dataColumns).map((key, i) => (
                          <S.Wrapper key={`${i}-${key}`}>
                            <Field
                              disabled={editingItem !== key}
                              component={TextFieldAdapter}
                              name={`key${key}`}
                              inputStyle={newDataColumns.includes(key) && { color: theme.mainRed }}
                            />
                            {editingItem === key ? (
                              <ButtonIcon type="button" onClick={() => this.saveColumnHandler(key, values)}>
                                <S.Check />
                              </ButtonIcon>
                            ) : (
                              <ButtonIcon type="button" onClick={() => this.editColumnHandler(key)}>
                                <S.Edit />
                              </ButtonIcon>
                            )}
                            <ButtonIcon onClick={() => this.deleteColumn(key)}>
                              <S.Delete />
                            </ButtonIcon>
                          </S.Wrapper>
                        ))}
                      </S.TextFieldContainer>
                    </BorderRadiusContainer>
                  )}
                  <ActionsContainer isColumn paddingValue={14}>
                    <S.ActionButton
                      type="submit"
                      label="Save"
                      disabled={!dirty && isDataColumnsEqualWithPrevious}
                      isDisable={!dirty && isDataColumnsEqualWithPrevious}
                    />
                  </ActionsContainer>
                </form>
              );
            }}
          />
        </S.DialogContainer>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ statusIndex: { status, indexItem, indexItemConfig, isLoading } }) => ({
  status,
  indexItemConfig,
  indexItem,
  isLoading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...statusIndexActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditColumns);
