import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';
import IndexTriggers from '../statusIndex/pages/IndexTriggers';
import GlobalIndex from '../statusIndex/pages/GlobalIndex';
import IndexItem from '../statusIndex/pages/GlobalIndex/Details';

import SettingsList from './pages/SettingsList';
import Gateways from './Gateways';
import Sensors from './pages/Sensors';
import SensorTypes from './pages/SensorTypes';
import DistributionList from './pages/DistributionList';
import PurgeParts from './pages/PurgeParts';

const Settings = () => (
  <Switch>
    <Route exact path="/settings/global-index/:type" component={GlobalIndex} />
    <Route exact path="/settings/global-index/:type/:id" component={IndexItem} />
    <Route exact path="/settings/index-triggers" component={IndexTriggers} />
    <Route exact path="/settings/gateways/:gatewayId/sensors" component={Sensors} />
    <Route exact path="/settings/gateways" component={Gateways} />
    <Route exact path="/settings/sensortypes" component={SensorTypes} />
    <Route exact path="/settings/distribution/:type" component={DistributionList} />
    <Route exact path="/settings/purgeparts/:type" component={PurgeParts} />
    <Route exact path="/settings" component={SettingsList} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Settings;
