import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { InstructionsDialog, List, UniqueResource, ConfirmationDialog } from 'components/Dialogs/Activators';
import SearchField from 'components/Layout/Search';
import Card from 'components/Layout/Card';

import { actions as appActions } from 'redux/app';
import { actions as sitesActions } from 'redux/sites';
import { actions as gatewaysActions } from 'redux/gateways';
import { openErrorDialog } from 'redux/errorHandler';

import { activatorsData, emptyValueLabel } from './../helpers';
import * as S from './../styled';

import theme from 'theme';

class Activator extends Component {
  static propTypes = {
    action: PropTypes.shape({
      addHubRequest: PropTypes.func.isRequired,
      addServerRequest: PropTypes.func.isRequired,
      createGatewayRequest: PropTypes.func.isRequired,
      deleteGatewayRequest: PropTypes.func.isRequired,
      getSitesList: PropTypes.func.isRequired,
      getNewResourceGroupNameByTenantRequest: PropTypes.func.isRequired,
      getNewHubNameBySiteRequest: PropTypes.func.isRequired,
      getNewGatewayNameInHubRequest: PropTypes.func.isRequired,
      getTenantHubsListRequest: PropTypes.func.isRequired,
      getGatewaysByHubRequest: PropTypes.func.isRequired,
      getGatewayRequest: PropTypes.func.isRequired,
      updateCurrentModule: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    newResource: PropTypes.shape({ IsUnique: PropTypes.bool, Name: PropTypes.string }).isRequired,
    gatewaysList: PropTypes.arrayOf(
      PropTypes.shape({
        ResourceGroupName: PropTypes.string,
      }),
    ).isRequired,
  };

  state = {
    confirmationDialogOpen: false,
    instructionsDialogOpen: false,
    listDialogOpen: false,
    uniqueResourceDialogOpen: false,
    currentActivatorType: '',
    currentGateway: '',
    currentHub: '',
    ResourceGroupName: '',
    SKU: {
      SKUName: '',
      SKUTier: '',
      Capacity: 1,
    },
    region: '',
    searchValue: '',
    activatorsList: activatorsData,
    selectedSite: emptyValueLabel,
    selectedTenant: emptyValueLabel,
    confirmationDialogType: 'resourcegroup',
    listMode: 'tenant',
    instructionsData: {},
  };

  componentDidMount() {
    this.props.action.updateCurrentModule('activator');
  }

  componentDidUpdate(prevProps) {
    const { error, action } = this.props;

    if (prevProps.error.length === 0 && error.length > 0) {
      action.openErrorDialog(error, 'Error');
      this.resetState('uniqueResourceDialogOpen');
    }
  }

  resetState = dialogToClose => {
    if (dialogToClose === 'confirmationDialogOpen' && this.state.confirmationDialogType === 'confirmation') {
      this.setState({ [dialogToClose]: false });
      return;
    }

    this.setState({
      uniqueResourceDialogOpen: false,
      listDialogOpen: false,
      [dialogToClose]: false,
      selectedTenant: emptyValueLabel,
      selectedSite: emptyValueLabel,
      currentHub: '',
      SKU: {
        name: '',
        tier: '',
        capacity: null,
      },
      currentGateway: '',
      listMode: 'tenant',
      confirmationDialogType: 'resourcegroup',
    });
  };

  onSearch = (activatorsList, searchValue) => this.setState({ activatorsList, searchValue });

  onStateChange = (key, value) => this.setState({ [key]: value });

  onCardClick = instructionsData =>
    this.setState({ instructionsDialogOpen: true, instructionsData, currentActivatorType: instructionsData.type });

  handleInstructionsNext = () => this.setState({ listDialogOpen: true, instructionsDialogOpen: false });

  handleListNext = () => {
    const { action } = this.props;
    const { selectedTenant, listMode, currentActivatorType, selectedSite, currentHub } = this.state;

    if (listMode === 'tenant') {
      action.getSitesList({ tenantId: selectedTenant.value });
      if (currentActivatorType === 'setup') {
        action.getNewResourceGroupNameByTenantRequest({ tenantId: selectedTenant.value });
        this.setState({ uniqueResourceDialogOpen: true, listMode: 'site', listDialogOpen: false });
      } else {
        this.setState({ listMode: 'site' }, () =>
          action.getNewResourceGroupNameByTenantRequest({ tenantId: selectedTenant.value }),
        );
      }
    }

    if (listMode === 'site') {
      this.setState({ confirmationDialogOpen: true, confirmationDialogType: 'confirmation' });
    }

    if (listMode === 'hub') {
      if (currentActivatorType === 'add') {
        this.setState({ listDialogOpen: false, uniqueResourceDialogOpen: true }, () =>
          action.getNewGatewayNameInHubRequest({
            gatewayData: {
              tenantId: selectedTenant.value,
              siteId: selectedSite.value,
              iotHubName: currentHub,
            },
            hubData: null,
          }),
        );
      } else {
        this.setState({ listMode: 'gateway' }, () =>
          action.getGatewaysByHubRequest({
            tenantId: selectedTenant.value,
            siteId: selectedSite.value,
            iotHubName: currentHub,
          }),
        );
      }
    }

    if (listMode === 'gateway') {
      this.setState({ confirmationDialogType: 'gateway', confirmationDialogOpen: true });
    }
  };

  handleUniqueNext = iteration => {
    const { newResource, history, action } = this.props;
    const { region, selectedTenant, SKU, selectedSite, currentHub, ResourceGroupName } = this.state;

    if (iteration === 'resourcegroup') {
      if (!newResource.IsUnique) {
        this.setState({ confirmationDialogOpen: true, ResourceGroupName: newResource.Name });
        return;
      }
      this.setState(
        { listDialogOpen: true, uniqueResourceDialogOpen: false, ResourceGroupName: newResource.Name },
        () => action.addServerRequest({ Name: newResource.Name, Region: region, tenantId: selectedTenant.value }),
      );
    }

    if (iteration === 'hub') {
      this.setState({ currentHub: newResource.Name, listDialogOpen: false }, () =>
        action.getNewGatewayNameInHubRequest({
          hubData: newResource.IsUnique
            ? {
                tenantId: selectedTenant.value,
                siteId: selectedSite.value,
                Name: newResource.Name,
                Region: region,
                ResourceGroupName,
                SKU,
              }
            : null,
          gatewayData: { tenantId: selectedTenant.value, siteId: selectedSite.value, iotHubName: newResource.Name },
        }),
      );
    }

    if (iteration === 'gateway') {
      this.setState({ currentGateway: newResource.Name }, () => {
        if (!newResource.IsUnique) {
          action.getGatewayRequest(newResource.Name);
        } else {
          action.createGatewayRequest({
            DeviceId: newResource.Name,
            Region: region,
            ResourceGroupName: ResourceGroupName || `RG-${selectedTenant.label.replace(/\s/g, '')}`,
            IotHubName: currentHub,
            TenantId: selectedTenant.value,
            SiteId: selectedSite.value,
            Notes: '',
          });
        }
      });
    }

    if (iteration === 'connection') {
      this.resetState('uniqueResourceDialogOpen');
      history.push('/home');
    }
  };

  handleConfirmationNext = () => {
    const { action, history, gatewaysList, newResource } = this.props;
    const { confirmationDialogType, selectedTenant, selectedSite, currentHub, currentGateway, currentActivatorType } =
      this.state;

    this.setState({ confirmationDialogOpen: false });

    if (confirmationDialogType === 'resourcegroup') {
      this.setState({ uniqueResourceDialogOpen: false, listDialogOpen: true });
    }

    if (confirmationDialogType === 'confirmation') {
      if (currentActivatorType !== 'setup') {
        this.setState({ listMode: 'hub', ResourceGroupName: newResource.Name }, () =>
          action.getTenantHubsListRequest({ tenantId: selectedTenant.value, siteId: selectedSite.value }),
        );
      } else {
        this.setState({ uniqueResourceDialogOpen: true, listDialogOpen: false }, () =>
          action.getNewHubNameBySiteRequest({ tenantId: selectedTenant.value, siteId: selectedSite.value }),
        );
      }
    }

    if (confirmationDialogType === 'gateway') {
      const { ResourceGroupName } = gatewaysList.find(({ DeviceId }) => DeviceId === currentGateway);

      action.deleteGatewayRequest({
        deviceId: currentGateway,
        iotHubName: currentHub,
        resourceGroupName: ResourceGroupName,
      });
      history.push('/home');
    }
  };

  render() {
    const {
      activatorsList,
      searchValue,
      instructionsDialogOpen,
      instructionsData,
      listDialogOpen,
      listMode,
      currentActivatorType,
      uniqueResourceDialogOpen,
      confirmationDialogOpen,
      selectedTenant,
      selectedSite,
      currentHub,
      currentGateway,
      SKU,
      confirmationDialogType,
      region,
      ResourceGroupName,
    } = this.state;
    const { newResource } = this.props;

    return (
      <>
        <S.PageContainer>
          <SearchField
            list={activatorsData}
            filterBy="title"
            searchData={searchValue}
            placeholder="Search"
            underlineShow
            inputStyle={S.searchInputStyles}
            setSearchedItems={this.onSearch}
          />
          <S.PageTitle>Activator</S.PageTitle>
          <S.ActivatorsContainer>
            {activatorsList.map(
              ({ title, info, primaryIcon, secondaryIcon, instructionsDialogTitle, purpose, type, tooltipText }) => (
                <Card
                  key={title}
                  info={info}
                  onCardClick={() => this.onCardClick({ instructionsDialogTitle, purpose, type })}
                  PrimaryIcon={primaryIcon}
                  SecondaryIcon={secondaryIcon}
                  title={title}
                  color={theme.darkGrey}
                  tooltip={tooltipText}
                />
              ),
            )}
          </S.ActivatorsContainer>
        </S.PageContainer>
        <InstructionsDialog
          open={instructionsDialogOpen}
          handleClose={() => this.resetState('instructionsDialogOpen')}
          handleNext={this.handleInstructionsNext}
          instructionsData={instructionsData}
        />
        <List
          open={listDialogOpen}
          handleClose={() => this.resetState('listDialogOpen')}
          handleNext={this.handleListNext}
          onStateChange={this.onStateChange}
          mode={listMode}
          type={currentActivatorType}
          data={{ tenant: selectedTenant.label, site: selectedSite.label, hub: currentHub, gateway: currentGateway }}
        />
        <UniqueResource
          open={uniqueResourceDialogOpen}
          handleClose={() => this.resetState('uniqueResourceDialogOpen')}
          handleNext={this.handleUniqueNext}
          onStateChange={this.onStateChange}
          selectedTenant={selectedTenant.label}
          selectedSite={selectedSite.label}
          selectedHub={currentHub}
          newResource={newResource}
          currentGateway={currentGateway}
          SKU={SKU}
          currentRegion={region}
          activatorType={currentActivatorType}
          resourceGroupName={ResourceGroupName}
        />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          handleClose={() => this.resetState('confirmationDialogOpen')}
          onNext={this.handleConfirmationNext}
          selectedSite={selectedSite.label}
          type={confirmationDialogType}
          gateway={currentGateway}
        />
      </>
    );
  }
}
const mapStateToProps = ({ gateways }) => ({
  newResource: gateways.newResource,
  tenantHubs: gateways.tenantHubs,
  gatewaysList: gateways.list,
  error: gateways.error,
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      ...gatewaysActions,
      getSitesList: sitesActions.sitesListByTenantRequest,
      updateCurrentModule: appActions.updateCurrentModule,
      openErrorDialog,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activator);
