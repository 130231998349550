export const updateItemsState = (state, { itemId, itemsList }) => ({
  ...state,
  createdEditedItem: true,
  createdItemId: itemId,
  loading: false,
  unitItemsList: itemsList,
  unitItemsPdfsList: {
    ...state.unitItemsPdfsList,
    [itemId]: [],
  },
  unitItemsPictures: {
    ...state.unitItemsPictures,
    [itemId]: [],
  },
  unitItemsTasks: {
    ...state.unitItemsTasks,
    [itemId]: [],
  },
});

export const updatePdfsState = (state, { itemId, pdfs }) => ({
  ...state,
  loading: false,
  unitItemsPdfsList: {
    ...state.unitItemsPdfsList,
    [itemId]: pdfs,
  },
});

export const updatePicturesState = (state, { itemId, pictures }) => ({
  ...state,
  loading: false,
  unitItemsPictures: {
    ...state.unitItemsPictures,
    [itemId]: pictures,
  },
});

export const updateTasksState = (state, { itemId, tasks }) => ({
  ...state,
  createdEditedTask: true,
  loading: false,
  unitItemsTasks: {
    ...state.unitItemsTasks,
    [itemId]: tasks,
  },
});
