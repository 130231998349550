import React from 'react';
import { PrimaryIconButton, PrimaryLinkButton, ListItem } from 'components/Layout/Section';
import { MeteringIcon, PricingIcon, WarehouseIcon } from 'components/Layout/Icons';

import { useModal } from '@ebay/nice-modal-react';
import { InventoryActivationDialog } from 'components/Dialogs/InventoryActivationDialog';

export const Accounts = () => {
  const inventoryActivationDialog = useModal(InventoryActivationDialog);

  const onInventoryIconClick = () => inventoryActivationDialog.show();

  return (
    <ListItem
      key="Accounts"
      name="Accounts"
      zIndex={4}
      content={
        <>
          <PrimaryLinkButton path="/globalsettings/metering" icon={<MeteringIcon />} label="Metering" />
          {/* <S.RouterLink customMargin="12px" to=> */}
          <PrimaryLinkButton path="/globalsettings/pricing" icon={<PricingIcon />} label="Pricing" />
          <PrimaryIconButton onClick={onInventoryIconClick} icon={<WarehouseIcon />} label="Inventory" />
        </>
      }
    />
  );
};
