import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import RemoveGroupsAndHubsDialog from 'components/Dialogs/RemoveGroupsAndHubsDialog';
import { BackIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';

import { ResourceGroupType } from 'configs/propTypes';

import { actions as gatewaysActions } from 'redux/gateways';

import { groupHubsData } from '../helpers';
import { IconForward } from '../styled';

import Item from './Item';
import { getTenantHubs, warningTexts } from './helpers';

class TenantHubs extends Component {
  static propTypes = {
    action: PropTypes.shape({
      deleteTenantHubs: PropTypes.func.isRequired,
      fetchTenantHubs: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        tenantId: PropTypes.string,
      }),
    }).isRequired,
    list: PropTypes.shape(ResourceGroupType).isRequired,
  };

  state = {
    searchData: '',
    removeHubsDialogOpen: false,
    hubsData: [],
    siteHubsForDelete: '',
  };

  componentDidMount() {
    const {
      action,
      match: { params },
    } = this.props;
    action.fetchTenantHubs({ tenantId: params.tenantId });
  }

  onBackButtonClick = () => this.props.history.push('/globalsettings/hubs');

  onSearch = e => this.setState({ searchData: e.target.value });

  filterHubs = ({ site }) =>
    `${site.name}${site.city}`.toLocaleLowerCase().includes(this.state.searchData.toLocaleLowerCase());

  openHubsDialog = item =>
    this.setState({
      removeHubsDialogOpen: true,
      hubsData: groupHubsData(item),
      siteHubsForDelete: item.site.id,
    });
  closeRemoveHubsDialog = () => this.setState({ removeHubsDialogOpen: false });

  handleDeleteHubs = () => {
    const {
      action,
      match: {
        params: { tenantId },
      },
    } = this.props;
    const { siteHubsForDelete } = this.state;

    action.deleteTenantHubs({ tenantId, siteId: siteHubsForDelete });
    this.closeRemoveHubsDialog();
  };

  renderRightControls = Id => (
    <div>
      <Link to={`/globalsettings/tenant/${this.props.match.params.tenantId}/site/${Id}/hubs`}>
        <ButtonIcon tooltip="Site Hubs">
          <IconForward />
        </ButtonIcon>
      </Link>
    </div>
  );

  render() {
    const { searchData, removeHubsDialogOpen, hubsData } = this.state;
    const { list } = this.props;

    return (
      <>
        <Subheader
          title={list.length > 0 ? `IoT Hubs ${list[0].tenant.name}` : ''}
          hintText="Search"
          isSearch
          searchData={searchData}
          searchInList={this.onSearch}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onBackButtonClick,
              hint: 'Back',
            },
          ]}
        />
        <SimpleList
          data={list.filter(this.filterHubs)}
          onItemClick={this.openHubsDialog}
          emptyListMessage="There are no Hubs avaliable"
          renderItemContent={item => <Item item={item} />}
          renderRightControls={item => this.renderRightControls(item.site.id)}
        />
        <RemoveGroupsAndHubsDialog
          open={removeHubsDialogOpen}
          title="Remove Hubs"
          closeModal={this.closeRemoveHubsDialog}
          data={hubsData}
          alertText={warningTexts.notification}
          handleDelete={this.handleDeleteHubs}
          warningText={warningTexts.confirmation}
        />
      </>
    );
  }
}

const mapStateToProps = ({ gateways }) => ({
  list: getTenantHubs(gateways.tenantHubsList),
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      deleteTenantHubs: gatewaysActions.deleteAllHubsFromTenantSiteRequest,
      fetchTenantHubs: gatewaysActions.getTenantHubsListRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantHubs);
