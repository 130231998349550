import http from '../index';

export const getStats = id =>
  http.post('UserManagement/GetStatusForDeleting', { id }).then(({ data: { entity } }) => {
    const KEYS = ['ActiveTasksCount', 'CompletedTasksCount'];
    const totalCount = KEYS.reduce((accum, key) => {
      const count = entity[key];
      // eslint-disable-next-line no-return-assign
      return (accum += count);
    }, 0);

    return {
      ...entity,
      totalCount,
    };
  });
