import React, { FC } from 'react';
import { PrimaryIconButton, SecondaryLinkButton, PrimaryLinkButton, ListItem } from 'components/Layout/Section';
import { GateWayIcon, HubIcon, KeyIcon, ResourceGroupsIcon } from 'components/Layout/Icons';

type PropsType = {
  openKeysModal: () => void;
};

export const IoT: FC<PropsType> = ({ openKeysModal }) => (
  <ListItem
    key="IoT"
    name="IoT"
    zIndex={5}
    content={
      <>
        <PrimaryLinkButton
          path="/globalsettings/resourcegroups"
          icon={<ResourceGroupsIcon />}
          label="Resource Groups"
        />
        {/* <S.RouterLink customMargin="19px" to=> */}
        <PrimaryLinkButton path="/globalsettings/hubs" icon={<HubIcon />} label="Hubs" />
        <SecondaryLinkButton path="/globalsettings/gateways" icon={<GateWayIcon />} label="Gateways" />
        <PrimaryIconButton onClick={openKeysModal} icon={<KeyIcon />} label="Keys" />
      </>
    }
  />
);
