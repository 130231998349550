import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ADD_VERSIONLEGAL_REQUEST: undefined,
  ADD_VERSIONLEGAL_SUCCESS: undefined,
  ADD_VERSIONLEGAL_FAILURE: undefined,

  DELETE_VERSIONLEGAL_REQUEST: undefined,
  DELETE_VERSIONLEGAL_FAILURE: undefined,

  EDIT_VERSIONLEGAL_REQUEST: undefined,
  EDIT_VERSIONLEGAL_SUCCESS: undefined,
  EDIT_VERSIONLEGAL_FAILURE: undefined,

  VERSIONLEGALS_LIST_REQUEST: undefined,
  VERSIONLEGALS_LIST_SUCCESS: undefined,
  VERSIONLEGALS_LIST_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.addVersionlegalRequest, handlers.addVersionlegalRequest],
    [actions.addVersionlegalSuccess, handlers.addVersionlegalSuccess],
    [actions.addVersionlegalFailure, handlers.addVersionlegalFailure],

    [actions.deleteVersionlegalRequest, handlers.deleteVersionlegalRequest],
    [actions.deleteVersionlegalFailure, handlers.deleteVersionlegalFailure],

    [actions.editVersionlegalRequest, handlers.editVersionlegalRequest],
    [actions.editVersionlegalSuccess, handlers.editVersionlegalSuccess],
    [actions.editVersionlegalFailure, handlers.editVersionlegalFailure],

    [actions.versionlegalsListRequest, handlers.versionlegalsListRequest],
    [actions.versionlegalsListSuccess, handlers.versionlegalsListSuccess],
    [actions.versionlegalsListFailure, handlers.versionlegalsListFailure],
  ]),
  initialState,
);

export default reducer;
