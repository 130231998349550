import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { findSite } from 'helpers';

import * as S from './styled';

const SiteSelector = ({ onClick }) => {
  const sitesAvailableToUserFullInfo = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  let siteName = useSelector(state => state.auth.siteName);

  if (!siteName) {
    siteName = findSite(sitesAvailableToUserFullInfo, selectedSite);
  }

  return (
    <>
      {!!sitesAvailableToUserFullInfo.length && (
        <S.SelectorContainer onClick={onClick}>
          <S.IconContainer>
            <S.LocationAction />
          </S.IconContainer>
          <S.ModuleLabel>{siteName || 'Site Selection'}</S.ModuleLabel>
        </S.SelectorContainer>
      )}
    </>
  );
};

SiteSelector.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SiteSelector;
