import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { useAppSelector } from 'redux/reducers';

import Subheader from 'components/Subheader';
import TableList from 'components/TableList';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import { AddIcon } from 'components/Layout/Icons';

import { actions as inventoryActions } from 'redux/inventory';
import { convertDateToThreeDigit, findSite, searchItemByKeyword } from 'helpers';
import { InventoryPeriodType } from 'types/inventory';
import { InventoryPeriodDialog } from '../../components/dialogs';
import { TABLE_CONFIGS, Z_INDEX_VALUE } from './configs';
import * as S from './styled';

const InventoryPeriods = () => {
  const [searchValue, setSearchValue] = useState('');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sitesAvailableToUserFullInfo = useAppSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const selectedSite = useAppSelector(state => state.auth.selectedSite);
  const periodsList: InventoryPeriodType[] = useAppSelector(state => state.inventory.periodsList);
  const siteName = findSite(sitesAvailableToUserFullInfo, selectedSite);
  const history = useHistory();
  const dispatch = useDispatch();

  const goBack = () => history.push('/siteparts');

  const goToDetailsPage = (id: string) => history.push(`/siteparts/partsInventory/periods/${id}`);

  const searchInList = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.currentTarget.value);

  const mapList = (item: InventoryPeriodType) => ({
    ...item,
    CreatedOn: convertDateToThreeDigit(item.CreatedOn),
  });

  const createInventoryPeriod = () => {
    dispatch(inventoryActions.createInventoryPeriodRequest());
    NiceModal.hide(ConfirmationModal);
  };

  const addInventoryPeriod = () =>
    NiceModal.show(ConfirmationModal, {
      label: 'NEW',
      labelCancel: 'CANCEL',
      isCancelNext: true,
      text: (
        <S.ConfirmationTextContainer>
          <S.BoldText>Starting a new period</S.BoldText>
          <S.Paragraph>This will close and transfer the last period.</S.Paragraph>
          <S.Paragraph>No more adjustments can be made!</S.Paragraph>
          <S.BoldText>Are you sure?</S.BoldText>
        </S.ConfirmationTextContainer>
      ),
      maxWidth: '600px',
      onClick: createInventoryPeriod,
      onCancelClick: () => NiceModal.hide(ConfirmationModal),
    });

  const renderRightControllCell = (item: InventoryPeriodType) => {
    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      goToDetailsPage(item.Id);
    };

    return (
      <td>
        <S.IconButton tooltip="Details" tooltipPosition="bottom-center" onClick={handleClick}>
          <S.IconForward />
        </S.IconButton>
      </td>
    );
  };

  const renderLeftControllCell = (item: InventoryPeriodType) => (
    <S.DataCell status={item.Status}>
      <S.Item>{item.Status}</S.Item>
    </S.DataCell>
  );

  const showPeriodEndConfirmationModal = () =>
    NiceModal.show(ConfirmationModal, {
      label: 'OK',
      isNext: true,
      isGreenLabel: true,
      text: (
        <S.ConfirmationTextContainer>
          <S.BoldText>Period Ended</S.BoldText>
          <S.Paragraph>This period is closed.</S.Paragraph>
        </S.ConfirmationTextContainer>
      ),
      maxWidth: '600px',
      onClick: () => NiceModal.hide(ConfirmationModal),
    });

  const onRowClick = (item: InventoryPeriodType) => {
    if (item.Status === 'Open') {
      NiceModal.show(InventoryPeriodDialog, { period: item, siteName });
    } else {
      showPeriodEndConfirmationModal();
    }
  };

  const leftButtons = [
    {
      icon: <S.Back />,
      handler: goBack,
      hint: 'Back',
    },
  ];

  const rightButtons = [
    {
      icon: <AddIcon />,
      handler: addInventoryPeriod,
      hint: 'Add inventory period',
    },
  ];

  useEffect(() => {
    dispatch(inventoryActions.getInventoryPeriodsListRequest());
  }, [dispatch]);

  return (
    <>
      <Subheader
        leftButtons={leftButtons}
        rightButtons={rightButtons}
        title="Periods"
        redSubtitle={siteName}
        isSearch
        searchData={searchValue}
        searchInList={searchInList}
        zIndex={Z_INDEX_VALUE}
      />
      {periodsList.length ? (
        <S.TableContainer>
          <TableList
            list={periodsList.filter(searchItemByKeyword(TABLE_CONFIGS, searchValue)).map(mapList)}
            tableColumns={TABLE_CONFIGS}
            tableHeads={TABLE_CONFIGS}
            renderRightControllCell={renderRightControllCell}
            renderLeftControllCell={renderLeftControllCell}
            renderLeftControllTableHead={() => (
              <S.Header>
                <S.HeaderText>Status</S.HeaderText>
              </S.Header>
            )}
            onRowClick={onRowClick}
          />
        </S.TableContainer>
      ) : (
        <S.EmptyList>
          <p>There are no data.</p>
        </S.EmptyList>
      )}
    </>
  );
};

export default InventoryPeriods;
