import styled from 'styled-components';
import { Flex } from 'grid-styled';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';

import { ButtonIcon } from 'components/Layout/Buttons';
import { SearchIcon, CloseIcon } from 'components/Layout/Icons';

export const Wrapper = styled(Flex).attrs({
  column: true,
  align: 'center',
  mb: 1,
})`
  width: 100%;
`;

export const Header = styled(Flex).attrs({
  w: 1,
  flex: 1,
  align: 'center',
})`
  padding: 0 15px;
  background-color: ${({ theme }) => theme.headerColor};
  color: ${({ theme }) => theme.primaryWhite};
  min-height: 55px;
`;

export const HeaderTools = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  padding: 10px 0px;
  background-color: ${({ theme }) => theme.lightGrey};
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const SubtitleContainer = styled.div`
  text-align: center;
  flex: 1;
  margin-bottom: 10px;

  h3 {
    margin: 0;
  }
`;

export const TitleContainer = styled.p`
  margin: 0;
  padding: 0 10px 0 0;
  flex: 1;
`;

export const Button = styled(ButtonIcon).attrs({
  tooltipStyles: {
    top: '20px',
  },
})`
  padding: 0 !important;
  width: auto !important;
  height: auto !important;

  & > div {
    & > div {
      left: auto !important;
      right: 0 !important;
    }

    & > svg {
      fill: ${({ theme }) => theme.primaryWhite} !important;
    }
  }
`;

export const SearchContainer = styled(Flex).attrs({
  w: 1,
  justify: 'center',
  align: 'center',
  mt: 0,
})`
  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const SearchInput = styled(TextField).attrs({
  hintStyle: {
    padding: '15px',
    textAlign: 'center',
  },
})`
  border: 1px solid;
  padding: 0 5px 3px 25px;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.75);
  border-color: ${({ theme }) => theme.primaryGrey};
  border-radius: 5px;
  max-width: 220px !important;
  max-height: 20px !important;
`;

export const Search = styled(SearchIcon)`
  position: absolute;
  left: 3px;
  top: 3px;
  width: 20px !important;
  height: 20px !important;
`;

export const IconClose = styled(CloseIcon)``;

export const Select = styled(SelectField).attrs({
  floatingLabelStyle: {
    top: '17px',
  },
  iconStyle: {
    width: '20px',
    height: '20px',
    top: '3px',
    padding: 0,
    fill: ({ theme }) => theme.darkGrey,
  },
  underlineStyle: {
    borderColor: ({ theme }) => theme.darkGrey,
  },
  dropDownMenuProps: {
    labelStyle: {
      height: '50px',
      top: 0,
      lineHeight: 'auto',
      paddingLeft: 0,
    },
  },
})`
  height: 50px !important;
`;
