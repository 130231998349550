export const placeholders = {
  email: 'Your Email',
  name: 'Your Name',
  tenantName: 'Company Name',
  comments: 'Questions or Comments',
};

export const inputFields = [
  { name: 'requestorEmail', placeholder: placeholders.email },
  { name: 'requestorName', placeholder: placeholders.name },
  { name: 'requestorCompany', placeholder: placeholders.tenantName },
  { name: 'requestorQuestionsOrComments', placeholder: placeholders.comments },
];
