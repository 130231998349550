import styled from 'styled-components';

import { DeleteIcon } from 'components/Layout/Icons';

export const Container = styled.div`
  & > div:last-child {
    padding: 15px !important;

    & > button:first-child {
      margin-right: 10px !important;
    }

    & > button:last-child {
      margin-left: 10px !important;
    }
  }
`;

export const MainTitle = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
  margin: 13px auto;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const RedInfo = styled(Info)`
  color: ${({ theme }) => theme.mainRed};
  display: flex;
  margin: 5px 0 5px 17px;
`;

export const RedText = styled.div`
  margin: 15px 15px;
  color: ${({ theme }) => theme.mainRed} !important;
`;

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: ${({ inside }) => (inside ? '30px 30px' : '30px 16px 30px')};
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.primaryWhite};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-left: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionBody = styled.div``;

export const IconDelete = styled(DeleteIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;

export const Text = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 0;
`;

export const Title = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  text-transform: capitalize;
  margin-bottom: 10px;
`;

export const RedTextModal = styled.p`
  color: ${({ theme }) => theme.mainRed};
  margin: 0;
`;
