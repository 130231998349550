import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { UnitItemPdfType } from 'configs/propTypes';
import SimpleList from 'components/SimpleList';
import { DeleteIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

const renderItemContent = pdf => pdf.Name || 'No pdf image';

const PdfsBody = ({ handleItemClick, pdfs, onUpload, onDelete }) => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState('');
  const [lastDownloadedFile] = files;

  const onDrop = event => {
    setFiles([...event.target.files, ...files]);
    setValue(event.target.value);
  };

  const upload = () => {
    onUpload(files);
    setFiles([]);
    setValue('');
  };

  const renderRightControls = pdf => (
    <ButtonIcon onClick={() => onDelete(pdf.Id, pdf.Name)} tooltip="Delete">
      <DeleteIcon />
    </ButtonIcon>
  );

  return (
    <>
      <S.FileSection>
        <S.FileSectionHeader>
          <S.IconContainer>
            <S.IconPdf />
          </S.IconContainer>
        </S.FileSectionHeader>
        <>
          <S.ListContainer>
            <SimpleList
              emptyListMessage="There are no PDFs, yet."
              data={pdfs}
              onItemClick={handleItemClick}
              renderItemContent={renderItemContent}
              renderRightControls={renderRightControls}
            />
          </S.ListContainer>
          <S.UploadPdfRule>File should not exceed 200 MB.</S.UploadPdfRule>
          <S.UploadPdfRow>
            <S.InputButton accept="application/pdf" id="file" onChange={onDrop} type="file" value={value} />
            <S.LabelButton htmlFor="file">Choose File</S.LabelButton>
            <S.FileName>{lastDownloadedFile?.name}</S.FileName>
            <S.UploadButton label="Upload New PDF" onClick={upload} disabled={!value} />
          </S.UploadPdfRow>
        </>
      </S.FileSection>
    </>
  );
};

PdfsBody.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  pdfs: PropTypes.arrayOf(UnitItemPdfType).isRequired,
};

export default PdfsBody;
