import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Field, Form } from 'react-final-form';
import { MuiThemeProvider } from 'material-ui';
import BaseDialog from 'components/Dialogs/Base';
import RadioButtons from 'components/RadioButtons';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { PastDueLimitSiteType } from 'configs/propTypes';

import { options, muiTheme, typeRadioStyle, SLIDER_MIN_VALUE, SLIDER_MAX_VALUE, SLIDER_STEP_VALUE } from '../constants';

import * as S from './styled';

class PastDueLimitDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    currentSite: PastDueLimitSiteType.isRequired,
    checkedSites: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    handleClose: PropTypes.func.isRequired,
    setNewSettings: PropTypes.func.isRequired,
    applyToAllSelections: PropTypes.func.isRequired,
    applyToCurrentSite: PropTypes.func.isRequired,
  };

  state = {
    sliderValue: SLIDER_MIN_VALUE,
  };

  componentDidUpdate() {
    const { currentSite, status } = this.props;

    if (status === 'updated' && !isEmpty(currentSite)) {
      this.setState({ sliderValue: currentSite.PastDueLimit });
    }
  }

  onSliderValueChange = newValue => this.setState({ sliderValue: newValue });

  onSubmit = values => {
    const { applyToCurrentSite, setNewSettings } = this.props;
    const { sliderValue } = this.state;

    setNewSettings({ limit: sliderValue, periodType: values.PastDueLimitPeriodType });
    applyToCurrentSite(sliderValue, values.PastDueLimitPeriodType);
  };

  onModalClose = () => {
    this.setState({ sliderValue: SLIDER_MIN_VALUE });
    this.props.handleClose();
  };

  render() {
    const { open, currentSite, checkedSites, applyToAllSelections } = this.props;
    const { sliderValue } = this.state;

    return (
      <BaseDialog
        open={open}
        onRequestClose={this.onModalClose}
        title="Set Past Due Limit"
        modalStyles={{ maxWidth: '550px' }}
      >
        <S.DialogContainer>
          <S.Title>{currentSite.Name}</S.Title>
          <Form
            initialValues={currentSite}
            onSubmit={this.onSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Field component={RadioButtons} items={options} name="PastDueLimitPeriodType" style={typeRadioStyle} />
                <S.DividerLine />
                <S.SliderContainer>
                  <S.Value>{SLIDER_MIN_VALUE}</S.Value>

                  <MuiThemeProvider muiTheme={muiTheme}>
                    <S.Slider
                      disableFocusRipple
                      sliderValue={sliderValue}
                      value={sliderValue}
                      min={SLIDER_MIN_VALUE}
                      max={SLIDER_MAX_VALUE}
                      step={SLIDER_STEP_VALUE}
                      onChange={(event, newValue) => this.onSliderValueChange(newValue)}
                    />
                  </MuiThemeProvider>
                  <S.Value>{SLIDER_MAX_VALUE}</S.Value>
                </S.SliderContainer>

                <ActionsContainer gapValue={10}>
                  <ActionButton
                    label="Apply to All Selections"
                    type="button"
                    isDisable={!checkedSites.length}
                    disabled={!checkedSites.length}
                    onClick={() => applyToAllSelections(sliderValue, values.PastDueLimitPeriodType)}
                  />
                  <ActionButton
                    label="Apply to This Site"
                    type="submit"
                    isNext
                    isDisable={isEmpty(currentSite)}
                    disabled={isEmpty(currentSite)}
                  />
                </ActionsContainer>
              </form>
            )}
          />
        </S.DialogContainer>
      </BaseDialog>
    );
  }
}

export default PastDueLimitDialog;
