import moment from 'moment';

import http, { baseURL } from '../index';

export const queueCommitUnit = dto => http.post('Queue/CommitUnit', dto);

export const getTriggerQueue = dto => http.post('TriggeredTasks/List/', dto);

export const getAttentionTasks = date => http.post('Queue/GetRequireAttentionTasksNumber', { date });

export const getSiteStatistics = (payload, filter) => http.post(`Queue/SiteStatisticsByDate/${payload}`, filter);

export const getReportsList = () => http.post('Reports/List');

export const exportTriggers = (date, userId = '') => {
  const rangeStart = date ? moment(date).format('MM-DD-YYYY') : '';
  const url =
    `${baseURL}/TriggeredTasks/ExportListTasks/?siteId=${http.defaults.siteId}&` +
    `filter.DateRangeStart=${rangeStart}&filter.DateRangeEnd=${rangeStart}&filter.UserId=${userId}&` +
    `filter.SiteId=${http.defaults.siteId}`;
  window.open(url);
};

export const exportArchiveUnit = ({ endDate, startDate, unitId }) => {
  const rangeStart = startDate ? moment(startDate).format('MM-DD-YYYY') : '';
  const rangeEnd = endDate ? moment(endDate).format('MM-DD-YYYY') : '';
  const url =
    `${http.defaults.baseURL}/Queue/ExportUnitArchivedTasks?siteId=${http.defaults.siteId}&` +
    `filter.ScheduledDateRangeStart=${rangeStart}&filter.ScheduledDateRangeEnd=${rangeEnd}&` +
    `unitId=${unitId}&_dc=${Date.now()}`;

  window.open(url);
};

export const exportUnit = ({
  unitId,
  rangeStart,
  rangeEnd,
  isRequireAttentionTasks,
  isRequiredAttentionTasksParts,
}) => {
  const onlyPartsRequiredAttention = isRequiredAttentionTasksParts ? 'onlyTaskPartsRequireAttention=true' : '';
  const url = isRequireAttentionTasks
    ? `${http.defaults.baseURL}/Queue/ExportListTasks?siteId=${http.defaults.siteId}&` +
      `onlyRequireAttention=true&${onlyPartsRequiredAttention}&unitId=${unitId}&_dc=${Date.now()}`
    : `${http.defaults.baseURL}/Queue/ExportListTasks?siteId=${http.defaults.siteId}&` +
      `scheduledDateRangeStart=${rangeStart}&scheduledDateRangeEnd=${rangeEnd}&` +
      `unitId=${unitId}&_dc=${Date.now()}`;
  window.open(url);
};

export const getPdfReport = pdf => {
  window.open(`${baseURL}/ExportReportPdf/Get?siteId=${http.defaults.siteId}&id=${pdf.id}`);
};

export const postReport = dto => http.post('Reports/Post', { dto });

export const putReport = dto => http.post('Reports/Put', { dto });

export const removeReport = id => http.post('Reports/Delete', id);

export const getReportPreview = dto => http.post('Reports/Preview', { dto });

export const getReport = id => http.post(`Reports/Get?Id=${id}`);

export const generateAndDownloadReport = ({ id, fileFormat }) => {
  window.open(
    `${baseURL}/Reports/GenerateAndDownload?siteId=${http.defaults.siteId}&Id=${id}${
      fileFormat === 'pdf' ? '&type=2' : ''
    }`,
  );
};

export const downloadFile = id => {
  window.open(`${baseURL}/Reports/DownloadFile?siteId=${http.defaults.siteId}&fileId=${id.fileId}`);
};

export const getListFileReports = ({ id }) => http.post('Reports/ListFileReports', { id });

export const deleteFile = id => http.post('Reports/DeleteFile', id);
