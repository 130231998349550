/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { actions as scheduleActions } from 'redux/schedule';

import * as S from './styled';

export const DeleteChecklistDialog = () => {
  const currentChecklistStatus = useSelector(state => state.schedule.currentChecklistStatus);
  const isTasksModalOpened = useSelector(state => state.schedule.isTasksModalOpened);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const sites = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const history = useHistory();

  const dispatch = useDispatch();

  const getSiteWithId = () => {
    const site = sites.find(siteObject => siteObject.id === selectedSite);

    return (
      <>
        {site.additionalId ? site.additionalId.substring(0, 5) : 'Site ID'} <S.BoldText>{site.name}</S.BoldText>
      </>
    );
  };

  const goToAttention = () => {
    const { push } = history;
    push(`/queue/${moment().format('YYYY-MM-DD')}/all`);
    closeModal();
    dispatch(scheduleActions.setIsModalVisible(false));
  };

  const closeModal = () => dispatch(scheduleActions.setIsTasksModalOpened(false));

  const removeChecklist = () => {
    dispatch(scheduleActions.setIsTasksModalOpened(false));
    dispatch(scheduleActions.setIsDeleteChecklistModalOpened(true));
  };

  return (
    <ConfirmationDialog
      label="Alert"
      open={isTasksModalOpened}
      width={'80%'}
      maxWidth={'850px'}
      handleClose={closeModal}
      text={
        <S.ConfirmationContainer>
          <S.RedTitleText>Removing this Checklist may disrupt tasks in progress!</S.RedTitleText>
          <S.RedSubTitleText>
            Completed tasks will be moved to the <S.BoldText>archive</S.BoldText>
          </S.RedSubTitleText>
          <S.Section>
            <S.SectionHeader>Task Status</S.SectionHeader>
            <S.SectionBody>
              <S.TaskStatusText>
                <S.BoldText>{currentChecklistStatus.InProgressCount}</S.BoldText> Tasks are in progress
              </S.TaskStatusText>
              <S.TaskStatusText>
                <S.BoldText>{currentChecklistStatus.CompletedCount}</S.BoldText> Tasks are completed but not archived
              </S.TaskStatusText>
              <S.RedTaskStatusText>
                <S.BoldText>{currentChecklistStatus.RequireAttentionCount}</S.BoldText> Tasks require attention
              </S.RedTaskStatusText>
            </S.SectionBody>
          </S.Section>
          {!!currentChecklistStatus.RequireAttentionCount && (
            <S.Section>
              <S.SectionHeader>Requiring Attention</S.SectionHeader>
              <S.SectionBody>
                <S.SiteRow>
                  <S.IconButton onClick={goToAttention}>
                    <S.ExitIcon />
                  </S.IconButton>
                  <S.SiteText>{getSiteWithId()}</S.SiteText>
                </S.SiteRow>
              </S.SectionBody>
            </S.Section>
          )}
          <S.RedSubTitleText>All none completed tasks will be deleted.</S.RedSubTitleText>
          <ActionsContainer>
            <ActionButton isNext label="Cancel" onClick={closeModal} />
            <ActionButton label="Remove Checklist" onClick={removeChecklist} />
          </ActionsContainer>
        </S.ConfirmationContainer>
      }
    />
  );
};
