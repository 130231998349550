import http from '../index';

export const getExchangeList = (filterList, searchFilter) =>
  http.post('/MasterUnit/List', { filter: { ...filterList, ...searchFilter } });

export const getListExchangeVersions = dto => http.post('/MasterUnit/ListUnitByOriginalId', dto);

export const getUnitApprove = dto => http.post('/MasterUnit/Approve', dto);

export const getSitesByTenant = dto => http.post('/MasterUnit/SiteListByTenant', dto);

export const getListToTenant = dto => http.post('/MasterUnit/CopyUnitsToTenant', dto);

export const getUnitVersion = dto => http.post(`/MasterUnit/Get?id=${dto}`);

export const getUnitFilesList = dto => http.post('/MasterUnitFile/List', dto);

export const getUnitItemsList = dto => http.post('/MasterItem/List', dto);

export const getUnitItem = dto => http.post('/MasterItem/Get', dto);

export const getItemFilesList = dto => http.post('/MasterItemFile/List', dto);

export const getItemTasksList = dto => http.post('/MasterTaskTemplate/List', dto);

export const getItemTask = dto => http.post('/MasterTaskTemplate/Get', dto);

export const deleteLastVersion = dto => http.post('/MasterUnit/Delete', { id: dto });

export const editNotes = dto => http.post('/MasterUnit/Put', { id: dto.id, dto });
