import React, { useState, useEffect, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Provider, ReactReduxContext } from 'react-redux';

import { Dialog } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const DialogsPull = ({ open, handleClose, handleExit, isEdit, steps, ...rest }) => {
  const [step, setStep] = useState(0);
  const { store } = useContext(ReactReduxContext);

  const handlePrev = () => {
    setStep(prevState => prevState - 1);
  };

  const handleNext = () => {
    setStep(prevState => prevState + 1);
  };

  const handleStep = newStep => {
    setStep(newStep);
  };

  useEffect(() => {
    setStep(0);
  }, [open]);

  const Step = steps[step];
  if (Step?.withOnClose) {
    Step.withOnClose = 'delete';
  }
  if (Step?.withOnClose && isEdit) {
    Step.withOnClose = 'edit';
  }

  return (
    <Dialog open={open} onRequestClose={() => handleClose(Step.withOnClose)} title={Step.title} titleColor={Step.color}>
      <S.DialogContainer>
        <Provider store={store}>
          <Step.Component
            onNext={handleNext}
            onBack={handlePrev}
            onStep={handleStep}
            onClose={handleClose}
            onExit={handleExit}
            isEditShortcut={Step?.isEdit}
            isActivator={Step?.isActivator}
            {...rest}
          />
        </Provider>
      </S.DialogContainer>
    </Dialog>
  );
};

DialogsPull.propTypes = {
  open: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  steps: PropTypes.shape({
    Component: PropTypes.elementType,
    title: PropTypes.string,
    color: PropTypes.string,
    withOnClose: PropTypes.string,
    isEdit: PropTypes.bool,
    isActivator: PropTypes.bool,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleExit: PropTypes.func.isRequired,
};

export default memo(DialogsPull);
