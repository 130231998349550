import React, { FC } from 'react';
import { searchItemByKeyword, convertDateToThreeDigit } from 'helpers';

import { deepContains } from './helpers';
import { IProps, IGroupedReport, IGroupedReportInner } from './types';
import * as S from './styled';

const GroupedTable: FC<IProps> = ({ tableHeads, list, tableColumns, searchData }: IProps) => {
  const listToShow = deepContains(list, (group: IGroupedReport) =>
    deepContains(group.Data, (subGroup: IGroupedReportInner) =>
      subGroup.Data?.filter(searchItemByKeyword(tableColumns, searchData)),
    ),
  );

  return (
    <S.TableContainer>
      <S.Table>
        {tableHeads && (
          <thead>
            <S.TableRow>
              {tableHeads.map(({ title, showColumn = true }, index) => {
                if (!showColumn) {
                  return null;
                }

                return <S.TableHeaderCell key={index}>{title}</S.TableHeaderCell>;
              })}
            </S.TableRow>
          </thead>
        )}
        <tbody>
          {listToShow.map((group: IGroupedReport, i: number) => (
            <React.Fragment key={`${i}-${group.Name}`}>
              <S.Title title="groupBy">
                <S.Value colSpan={tableHeads.length}>{group.Name || 'No Team'}</S.Value>
              </S.Title>
              {group.Data?.map((subGroup: IGroupedReportInner, subGroupByIndex: number) => (
                <React.Fragment key={subGroupByIndex}>
                  <S.Title title="subGroupBy">
                    <S.Value colSpan={tableHeads.length}>{subGroup.Name || 'No Team'}</S.Value>
                  </S.Title>
                  {subGroup.Data?.map(item => (
                    <S.TableRow key={`${item.Id}-${item.ItemId}`}>
                      {tableColumns.map(({ field, showColumn = true }, index) => {
                        if (!showColumn) {
                          return null;
                        }

                        const renderTableCellValues = () => {
                          if (field === 'CompletedOn' || field === 'ExecutionDate') {
                            return convertDateToThreeDigit(item[field]);
                          }

                          return item[field];
                        };

                        return <S.TableCell key={`${item.Id}-${index}`}>{renderTableCellValues()}</S.TableCell>;
                      })}
                    </S.TableRow>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </S.Table>
    </S.TableContainer>
  );
};

export default GroupedTable;
