import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Checkbox from 'material-ui/Checkbox';

import theme from 'theme';

import { ListItemContainer } from 'components/Layout/Containers';
import { EditIcon } from 'components/Layout/Icons';

import * as S from './styled';

const RequestItem = ({ fields, request, onItemClick, changeNote }) => (
  <ListItemContainer>
    {fields.map(({ field, title, isLastField }) => (
      <Fragment key={field}>
        {!isLastField && (
          <S.ListItem onClick={onItemClick} itemsCount={fields.length}>
            {title} <S.ItemValue>{request[field]}</S.ItemValue>
          </S.ListItem>
        )}
        {field === 'isVerificationReceived' && (
          <Checkbox
            onClick={onItemClick}
            iconStyle={{ fill: theme.primaryPink }}
            style={{ width: 210 }}
            label={title}
            labelPosition="left"
            checked={request[field]}
          />
        )}
        {field === 'notes' && (
          <S.NotesContainer onClick={onItemClick}>
            <S.NoteFieldContainer data-tip data-for="registerTip">
              {title} <S.Note> {request[field]}</S.Note>
            </S.NoteFieldContainer>
            <ReactTooltip id="registerTip" place="top" effect="solid">
              {request[field]}
            </ReactTooltip>
            <S.EditNoteButton onClick={changeNote}>
              <EditIcon />
            </S.EditNoteButton>
          </S.NotesContainer>
        )}
      </Fragment>
    ))}
  </ListItemContainer>
);

RequestItem.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.string,
    note: PropTypes.string,
  }).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      title: PropTypes.string,
      isLastField: PropTypes.oneOf([true, false, undefined]),
    }),
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  changeNote: PropTypes.func.isRequired,
};

export default RequestItem;
