import {
  BorderRadiusContainer,
  Column,
  ContentContainer,
  ScrollableContentContainer,
} from 'components/Layout/Containers';
import styled from 'styled-components';

const BlockTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 3px 0;
`;

const GrayTitle = styled(BlockTitle)`
  background: ${({ theme }) => theme.lightGrey};
  font-size: 11px;
  font-weight: 500;
  padding: 10px;
  text-transform: uppercase;
`;

const Breadcrumbs = styled.div`
  color: ${({ theme }) => theme.textGray};
  display: flex;
  font-size: 14px;
  padding-left: 10px;
`;

const BorderRadiusBlock = styled(BorderRadiusContainer)`
  position: relative;
`;

const ColoredTitle = styled.div`
  background: ${({ theme }) => theme.primaryTasks};
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  padding: 10px;
  text-transform: uppercase;
`;

const ColoredTitleNoCase = styled.div`
  background: ${({ theme }) => theme.primaryTasks};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
`;

const TaskDescription = styled(Column)`
  background: ${({ theme }) => theme.primaryWhite};
  padding: 20px;
  word-break: break-all;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.2;
`;

const Container = styled(ContentContainer)``;

const Content = styled(ScrollableContentContainer)``;

const Block = styled(BorderRadiusContainer)`
  margin: 20px 40px 30px;
`;

const BlockImage = styled.span`
  display: flex;
  justify-content: flex-end;
  background: ${({ theme }) => theme.lightGrey};
  height: 100%;
  padding: 10px;
`;

const PhotoContent = styled(ScrollableContentContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DateBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    max-width: 100%;
    height: calc(80vh - 400px);
    max-height: calc(80vh - 310px);
  }
`;

const TaskNotes = styled(Column)`
  background: ${({ theme }) => theme.lightGrey};
  padding: 20px;
`;

const TextValue = styled.span`
  font-size: 16px;
  padding: 5px 10px;
`;

const InputContainer = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
`;

export {
  BlockTitle,
  GrayTitle,
  Breadcrumbs,
  BorderRadiusBlock,
  ColoredTitle,
  ColoredTitleNoCase,
  TaskDescription,
  Overlay,
  Container,
  Content,
  InputContainer,
  Block,
  BlockImage,
  PhotoContent,
  DateContainer,
  DateBlock,
  ImageContainer,
  TaskNotes,
  TextValue,
};
