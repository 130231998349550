import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import theme from 'theme';

import { BorderRadiusContainer } from 'components/Layout/Containers';
import { ActionButton, ActionsContainer, Dialog } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import CheckboxList from './CheckboxList';
import * as S from './styled';

class PageSelectionDialog extends Component {
  static propTypes = {
    landingPageList: PropTypes.object.isRequired,
    setSelectedPage: PropTypes.func.isRequired,
    selectedPage: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    openEditLandingPageDialog: PropTypes.func.isRequired,
  };
  state = {
    filteredSites: [],
    searchData: '',
  };

  componentDidUpdate(prevProps, prevState) {
    const { landingPageList } = this.props;
    const { searchData } = this.state;
    if (
      landingPageList &&
      landingPageList.length > 0 &&
      prevState.filteredSites.length === 0 &&
      searchData.length === 0
    ) {
      this.setState({
        filteredSites: landingPageList,
      });
    }
    window.dispatchEvent(new Event('resize'));
  }

  setSearchedPages = (filteredSites, searchData) => this.setState({ filteredSites, searchData });

  onCloseDialog = () => {
    const { onClose } = this.props;
    this.props.setSelectedPage({});
    this.setState({
      searchData: '',
      filteredSites: [],
    });
    onClose();
  };

  render() {
    const { filteredSites, searchData } = this.state;
    const { isOpen, landingPageList, selectedPage, setSelectedPage, openEditLandingPageDialog } = this.props;
    const nameSorter = ({ Name }) => Name.toLowerCase();
    const publishedNameSorter = ({ PublishedName }) => PublishedName.toLowerCase();
    const sortedPageList = sortBy(landingPageList, [nameSorter, publishedNameSorter]);
    const filteredPageList = sortBy(filteredSites, [nameSorter, publishedNameSorter]);

    return (
      <Dialog
        open={isOpen}
        title="Tutorial Page Setup"
        noCapitalize
        onRequestClose={this.onCloseDialog}
        centeredTitle={null}
        zIndex={null}
        titleColor={theme.headerColor}
      >
        <S.DialogContainer>
          <S.TopInfo>
            <S.Title>Select a Page</S.Title>
            <S.SubInfo>Select an available system page you would like to setup for tutorials.</S.SubInfo>
          </S.TopInfo>
          <BorderRadiusContainer column justify="">
            <S.Label>Landing Pages</S.Label>
            <S.SearchContainer>
              <SearchField
                list={landingPageList}
                filterBy={['Name', 'PublishedName']}
                searchData={searchData}
                placeholder="Filter System Names"
                setSearchedItems={this.setSearchedPages}
              />
            </S.SearchContainer>

            <S.ListContainer>
              <S.TitleContainer>
                <S.ColumnTitle>System Page Name</S.ColumnTitle>
                <S.ColumnTitle>Published Page Name</S.ColumnTitle>
              </S.TitleContainer>
              <S.Hr />
              <CheckboxList
                list={!searchData.length ? sortedPageList : filteredPageList}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </S.ListContainer>
          </BorderRadiusContainer>
          <ActionsContainer isColumn paddingValue={14}>
            <ActionButton label="Next" isNext onClick={openEditLandingPageDialog} />
          </ActionsContainer>
        </S.DialogContainer>
      </Dialog>
    );
  }
}

export default PageSelectionDialog;
