import styled, { css } from 'styled-components';

import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import RaisedButton from 'material-ui/RaisedButton';

export const ButtonFlat = styled(FlatButton)`
  ${({ isDisable }) =>
    isDisable &&
    `
      pointer-events: none;
  `}

  & span {
    text-transform: unset !important;
    font-weight: 600 !important;
    padding: 0 10px 0 6px !important;
    font-size: ${({ fontSize }) => fontSize || '14px'} !important;
  }

  ${props =>
    props.position &&
    `
    div{
      display: flex;
      justify-content: center;
      span{
        font-weight: 400 !important;
      }
    }
  `}
`;

export const DrawerSaveButton = styled(FlatButton).attrs({
  type: 'submit',
  label: 'Save',
  labelStyle: ({ theme }) => ({
    color: theme.primaryWhite,
    textTransform: 'unset',
    fontSize: '20px',
  }),
  backgroundColor: ({ theme, disabled }) => (disabled ? theme.disabledColor : theme.primaryGreen),
})`
  width: 150px;
  margin-left: 150px;
  border: 1px solid ${({ theme }) => theme.primaryGrey} !important;
  border-radius: 5px !important;
`;

export const ButtonIcon = styled(IconButton)`
  padding: 10px !important;
  transform: translate3d(0, 0, 0) !important;

  & > div {
    & > div {
      padding: 2px 6px !important;
      line-height: 12px !important;
      z-index: 999 !important;
      & > span {
        white-space: unset !important;
      }

      & > div {
        border-radius: 0 !important;
      }
    }
  }
`;

export const ButtonRaised = styled(RaisedButton)`
  min-width: unset !important;
  box-shadow: unset !important;
  overflow: hidden !important;

  button {
    color: inherit !important;

    span {
      font-size: 11px !important;
      padding: 0 10px !important;
      line-height: 38px;
    }

    svg {
      margin-left: 0 !important;
    }

    &:disabled {
      span {
        color: ${({ labelColor }) => labelColor} !important;
      }
    }
  }
`;

export const ConfirmButton = styled(ButtonFlat).attrs({
  label: 'OK',
})`
  color: ${({ theme, buttonTextColor }) => buttonTextColor || theme.primary} !important;
`;

export const GreenButton = styled(ButtonFlat)`
  ${({ disabled }) =>
    disabled
      ? null
      : css`
          span {
            color: ${({ theme }) => theme.greenButton};
          }
        `};
`;
