import React from 'react';
import PropTypes from 'prop-types';

import { ImageContainer, ImageLabel, PictureItem, NoImageText } from './styled';

const DocumentImage = ({ id, type }) => (
  <ImageContainer>
    <ImageLabel>{type}</ImageLabel>
    {id ? <PictureItem pictureId={id} /> : <NoImageText>No image</NoImageText>}
  </ImageContainer>
);

DocumentImage.defaultProps = {
  id: null,
};

DocumentImage.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default DocumentImage;
