import React from 'react';
import PropTypes from 'prop-types';

import SectionRules from './SectionRules';
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';

import { generateDistributionName } from 'modules/parts/pages/helpers';
import { types } from './constants';

import * as S from './styled';

const DistributionForm = ({
  LocationRules: locationRules,
  UnitRules: unitRules,
  ItemRules: itemRules,
  Type: type,
  MyPartID: myPartID,
  CreatedOn: createdOn,
  PartGroupName: partGroupName,
}) => (
  <S.Container>
    {!partGroupName && (
      <p>
        My Part Id: <S.PartId>{myPartID}</S.PartId>
      </p>
    )}
    {partGroupName && (
      <p>
        Group Name: <S.PartId>{partGroupName}</S.PartId>
      </p>
    )}
    <p>
      Distr Name: <span>{generateDistributionName(createdOn)}</span>
    </p>
    <RadioButtonGroup name="type" defaultSelected={String(type)} valueSelected={String(type)} disabled>
      {Object.keys(types).map(distroType => (
        <RadioButton
          key={`radio-${types[distroType]}`}
          value={`${distroType}`}
          label={types[distroType]}
          labelStyle={{ fontWeight: 700 }}
          style={{ marginBottom: '10px' }}
          disabled
        />
      ))}
    </RadioButtonGroup>
    {locationRules && <SectionRules title="Location Rules" rules={locationRules} ruleName="location" />}
    {unitRules && <SectionRules title="Unit Rules" rules={unitRules} ruleName="unit" />}
    {itemRules && <SectionRules title="Item Rules" rules={itemRules} ruleName="item" />}
  </S.Container>
);

DistributionForm.defaultProps = {
  LocationRules: null,
  UnitRules: null,
  ItemRules: null,
  Type: 0,
  MyPartID: '',
};

DistributionForm.propTypes = {
  LocationRules: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  UnitRules: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  ItemRules: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  MyPartID: PropTypes.string,
  Type: PropTypes.number,
  CreatedOn: PropTypes.string.isRequired,
  PartGroupName: PropTypes.string.isRequired,
};

export default DistributionForm;
