import styled from 'styled-components';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';
import { ButtonIcon } from 'components/Layout/Buttons';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const Block = styled(BorderRadiusContainer)``;

export const InputContainer = styled(InputWrapper)``;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
`;

export const CopyButtonIcon = styled(ButtonIcon)`
  margin: 0 0 0 -50px !important;
`;
