import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';
import { TextareaField } from 'components/Layout/Textarea';

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;

export const TextField = styled(TextareaField)`
  label {
    left: calc(20% + 10px) !important;
  }

  div {
    width: 60% !important;
    margin: 0 auto !important;
  }

  div > textarea {
    border: 1px solid ${({ theme }) => theme.primaryRed} !important;
    background-color: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const FormContainer = styled.div`
  position: relative;
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;

  form {
    & > div:nth-child(5) {
      justify-content: center;
    }

    & div > button:first-child {
      margin-right: 10px !important;
    }

    & > div:last-child {
      padding: 20px 0 !important;
    }
  }
`;

export const RedInfo = styled.div`
  margin: 15px 15px;
  color: ${({ theme }) => theme.mainRed} !important;
`;

export const Icon = styled(ButtonIcon)`
  width: 24px !important;
  padding: 0 !important;
  position: absolute !important;
  top: 0;
  right: 0;

  svg {
    color: ${({ theme }) => theme.primarySites} !important;
  }

  &:hover {
    transform: translateZ(0) scale(1);
  }
`;

export const radioStyle = {
  textAlign: 'left',
  marginBottom: 10,
};
