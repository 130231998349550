import React from 'react';

import * as CS from 'components/Dialogs/styled';
import { ButtonIcon } from 'components/Layout/Buttons';

import { separateRuleType } from './helpers';
import * as S from './styled';
import { TaskTemplateType } from 'types/scheduler';

const BASE_INDEX = 1000;

type PropsType = {
  onDelete: (index: number, itemId: string) => void;
  list: TaskTemplateType[];
};

const TasksTable = ({ list, onDelete }: PropsType) => (
  <S.Container>
    <S.ListContainer>
      {list.map(({ RuleType, Name, ItemId }, index) => (
        <S.ListItemContainer index={BASE_INDEX - index} key={BASE_INDEX - index}>
          <S.ListItem>
            <S.ItemValue>{separateRuleType(RuleType)}</S.ItemValue>
            <S.ItemValue>{Name}</S.ItemValue>
            <ButtonIcon>
              <S.IconDelete onClick={() => onDelete(index, ItemId)} />
            </ButtonIcon>
          </S.ListItem>
          {index !== list.length - 1 && <CS.DividerLine />}
        </S.ListItemContainer>
      ))}
    </S.ListContainer>
  </S.Container>
);

export default TasksTable;
