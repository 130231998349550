import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions as shortcutActions } from 'redux/shortcuts';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ShortcutRules from 'components/Layout/ShortcutRules';
import {
  generateUnitRules,
  checkOptions,
  createId,
  initialRule,
  generateInitialRules,
} from 'components/Layout/ShortcutRules/helpers';
import TestrunButton from 'components/Buttons/TestrunButton';

import { ShortCutType } from 'configs/propTypes';

import { getStartRules, initialRuleValues } from '../constants';
import * as S from './styled';

class UnitRules extends React.PureComponent {
  static propTypes = {
    listOfUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    listOfSites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    shortcut: ShortCutType.isRequired,
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      sendUnitOptionsRequest: PropTypes.func.isRequired,
      sendLocationOptionsRequest: PropTypes.func.isRequired,
      saveShortcutOptionsRequest: PropTypes.func.isRequired,
    }).isRequired,
    isShortcutFromListTemplate: PropTypes.bool.isRequired,
    createdTemplate: PropTypes.shape().isRequired,
    error: PropTypes.string.isRequired,
  };

  state = {
    isErrorShown: false,
    isPreviewShown: false,
    unitRules: generateInitialRules({
      rules: getStartRules(
        this.props.isShortcutFromListTemplate,
        this.props.shortcut.UnitRules,
        this.props.createdTemplate,
        'UnitName',
      ),
      flag: 'unit',
    }),
  };

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onChangeIgnoreRules = flag => value => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], isRulesIgnored: value },
    });
  };

  getPreviewResults = values => {
    const updatedValues = generateUnitRules(values, this.state.unitRules.rules);
    const valuesToSend = {
      ...updatedValues,
      LocationRules: this.props.shortcut.LocationRules,
    };
    if (!checkOptions(valuesToSend.UnitRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      this.setState({ isPreviewShown: true });
      this.props.actions.sendUnitOptionsRequest(valuesToSend);
    }
  };

  onSubmitForm = ({ values }) => {
    const { shortcut, actions, onNext } = this.props;

    const updatedValues = generateUnitRules(values, this.state.unitRules.rules);
    const valuesToSend = {
      ...updatedValues,
      Id: shortcut.Id,
      Name: shortcut.Name,
      LocationRules: shortcut.LocationRules,
      ItemRules: [{ ...initialRuleValues, OptionName: 'ItemIgnore', TableName: 'Items' }],
    };

    if (!checkOptions(valuesToSend.UnitRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false, isPreviewShown: false });
      actions.sendUnitOptionsRequest({
        ...valuesToSend,
        onNext: () => {
          actions.saveShortcutOptionsRequest(valuesToSend);
          onNext();
        },
      });
    }
  };

  generatePreviewResults = sites =>
    sites.map(
      item =>
        !!this.props.listOfUnits.filter(unit => unit.SiteId === item.Id).length && (
          <S.PreviewItemContainer>
            <S.PreviewItem>
              <S.PreviewItemText>{item.AdditionalSiteID.substring(0, 5)} &nbsp;</S.PreviewItemText>
              <S.PreviewItemText>{item.Name}</S.PreviewItemText>
            </S.PreviewItem>
            {this.props.listOfUnits.map(
              unit => unit.SiteId === item.Id && <S.PreviewItemSmall>{unit.Name.substring(0, 45)}</S.PreviewItemSmall>,
            )}
          </S.PreviewItemContainer>
        ),
    );

  onPrevious = e => {
    e.preventDefault();
    this.setState({ isPreviewShown: false });
    this.props.onBack();
  };

  componentDidUpdate() {
    const {
      shortcut,
      actions: { sendLocationOptionsRequest },
      listOfSites,
    } = this.props;
    if (listOfSites === 'empty' && shortcut.LocationRules) {
      sendLocationOptionsRequest({ LocationRules: shortcut.LocationRules });
    }
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { shortcut, error, isShortcutFromListTemplate, listOfSites, listOfUnits } = this.props;
    const { unitRules } = this.state;
    const isListOfUnitEmpty = listOfUnits === 'empty';
    const isInitialValues = !isShortcutFromListTemplate && isListOfUnitEmpty && unitRules.rules.length === 1;

    return (
      <S.Container>
        <S.MainTitle>{shortcut && shortcut.Name}</S.MainTitle>
        <S.Step>2 of 5</S.Step>
        <S.Title>Tasks will be created for:</S.Title>
        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <ShortcutRules
                title="Unit/Object Rules"
                rules={this.state.unitRules}
                isInitialValues={isInitialValues}
                addOption={this.addOption}
                removeOption={this.removeOption}
                onChangeMatch={this.onChangeMatch}
                onChangeIgnoreRules={this.onChangeIgnoreRules}
              />
              <S.PreviewResultsContainer>
                <div />
                <TestrunButton onClick={() => this.getPreviewResults(values)} />
                {listOfUnits !== 'empty' && this.state.isPreviewShown && (
                  <S.PreviewResultsInfo>{this.generatePreviewResults(listOfSites)}</S.PreviewResultsInfo>
                )}
              </S.PreviewResultsContainer>
              {this.state.isErrorShown && (
                <S.ErrorContainer>
                  Please add at least one rule or select &quot;Ignore rules&quot; to disable rules
                </S.ErrorContainer>
              )}
              {error.length > 0 && <S.ErrorContainer>{error}</S.ErrorContainer>}
              <ActionsContainer>
                <ActionButton label="Previous" onClick={e => this.onPrevious(e)} isNext />
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts, listTemplates }) => ({
  listOfUnits: shortcuts.listOfUnits,
  listOfSites: shortcuts.listOfSites,
  shortcut: shortcuts.shortcut,
  createdTemplate: listTemplates.createdTemplate,
  error: shortcuts.error,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitRules);
