export const WITHOUT_GROUPING = 0;

export const generateReportGroupValues = values => {
  const report = {};
  if (values['group-ignore']) {
    report.SubGroupBy = 32;
    report.GroupBy = 4;
  } else if (!values['group-select']) {
    report.GroupBy = WITHOUT_GROUPING;
    report.SubGroupBy = WITHOUT_GROUPING;
  } else {
    report.GroupBy = values['group-name'];
    report.SubGroupBy = values['subgroup-ignore'] ? 32 : values['subgroup-name'];
  }
  return report;
};
