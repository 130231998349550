/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { actions as scheduleActions } from 'redux/schedule';

import * as S from './styled';

export const DeleteChecklistConfirmation = ({ unitId, openTaskModal }) => {
  const isDeleteChecklistModalOpened = useSelector(state => state.schedule.isDeleteChecklistModalOpened);
  const isModalVisible = useSelector(state => state.schedule.isModalVisible);
  const checklistToEdit = useSelector(state => state.schedule.checklistToEdit);
  const isConfirmationOpened = useSelector(state => state.schedule.isConfirmationOpened);
  const isConfirmationDialogOpened = useSelector(state => state.schedule.isConfirmationDialogOpened);
  const schedule = useSelector(state => state.schedule.schedule);
  const isStatusInactive = useSelector(state => state.schedule.isStatusInactive);

  const dispatch = useDispatch();

  const closeModal = () => dispatch(scheduleActions.setIsDeleteChecklistModalOpened(false));

  const deleteChecklist = () => {
    closeModal();
    if (isModalVisible) {
      dispatch(scheduleActions.setIsModalVisible(false));
    }
    dispatch(scheduleActions.deleteScheduleRequest({ scheduleId: checklistToEdit.Id, unitId: checklistToEdit.UnitId }));
  };

  const handleDeleteReject = () => dispatch(scheduleActions.setIsConfirmationDialogOpened(false));

  const handleDeleteApproval = () => {
    dispatch(scheduleActions.setIsModalVisible(false));
    dispatch(scheduleActions.setChecklistToEdit({}));
    handleDeleteReject();
  };

  const closeActivationDialog = () => dispatch(scheduleActions.setIsConfirmationOpened(false));

  const checkActivationDialog = async url => {
    await dispatch(scheduleActions.fetchChecklistActivationRequest({ unitId, id: schedule.Id, url }));
    dispatch(scheduleActions.setIsConfirmationOpened(false));
  };

  return (
    <>
      <ConfirmationDialog
        label={checklistToEdit.Name ? 'Yes' : 'Delete Checklist'}
        text={
          <>
            <S.ConfirmationText>
              {checklistToEdit.Name
                ? 'Aborting this process will cancel all changes.'
                : 'Aborting the process will delete this checklist and cannot be undone!'}
            </S.ConfirmationText>
            <S.ConfirmationWarning>Are you sure?</S.ConfirmationWarning>
          </>
        }
        open={isConfirmationDialogOpened}
        onClick={handleDeleteApproval}
        handleClose={handleDeleteReject}
      />
      <ConfirmationDialog
        width={'40%'}
        maxWidth={'400px'}
        handleClose={closeActivationDialog}
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationText>Do you want to Activate or Deactivate this Checklist?</S.ConfirmationText>
            <ActionsContainer>
              <ActionButton label="Deactivate" onClick={() => checkActivationDialog('ChecklistActions/Deactivate')} />
              <ActionButton
                label="Activate"
                isNext
                onClick={() => checkActivationDialog('ChecklistActions/Activate')}
              />
            </ActionsContainer>
          </S.ConfirmationContainer>
        }
        open={isConfirmationOpened}
      />
      <ConfirmationDialog
        label="Alert"
        open={isStatusInactive}
        width={'50%'}
        maxWidth={'350px'}
        handleClose={() => dispatch(scheduleActions.setIsStatusInactive(false))}
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationText>You cannot edit this Checklist as end date is already reached.</S.ConfirmationText>
            <ActionsContainer>
              <ActionButton
                isNext
                label="CANCEL"
                onClick={() => dispatch(scheduleActions.setIsStatusInactive(false))}
              />
              <ActionButton label="ARCHIVE/DELETE" onClick={openTaskModal} />
            </ActionsContainer>
          </S.ConfirmationContainer>
        }
      />
      <ConfirmationDialog
        label="Alert"
        open={isDeleteChecklistModalOpened}
        width={'50%'}
        maxWidth={'350px'}
        handleClose={closeModal}
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationBoldText>
              <S.ConfirmationText>All none completed tasks will be deleted!</S.ConfirmationText>
            </S.ConfirmationBoldText>
            <S.ConfirmationWarningText>Are you sure you want to remove this Checklist?</S.ConfirmationWarningText>
            <ActionsContainer>
              <ActionButton label="Remove Checklist" onClick={deleteChecklist} />
            </ActionsContainer>
          </S.ConfirmationContainer>
        }
      />
    </>
  );
};
