import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useFormik } from 'formik';
import { Modal } from 'components/Modal';
import { TextInput } from 'components/Input';

import theme from 'theme';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

import { PartIssueEstimationType } from 'types/task';
import { FORM_FIELDS, formatter } from './configs';

import * as S from './PartIssueEstimationDialogStyles';

type PropsType = {
  setPartIssueEstimationDto: (partIssueEstimationDto: PartIssueEstimationType) => void;
  partIssueEstimationDto: PartIssueEstimationType;
};

export const PartIssueEstimationDialog = NiceModal.create(
  ({ partIssueEstimationDto, setPartIssueEstimationDto }: PropsType) => {
    const modal = useModal();

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: partIssueEstimationDto,
      onSubmit: values => {
        setPartIssueEstimationDto(values);
        modal.hide();
      },
    });

    const onModalClose = () => {
      modal.hide();
      formik.resetForm();
    };

    return (
      <Modal
        title="Part Issue Estimation"
        isOpen={modal.visible}
        onRequestClose={onModalClose}
        titleColor={theme.primaryDark}
      >
        <S.Form onSubmit={formik.handleSubmit}>
          {FORM_FIELDS.map(({ label, name, ...rest }) => {
            const inputStyles = [
              ...S.inputStyles,
              ...(name === 'approvedBy' || name === 'approvedByRole' ? S.widerInputStyles : []),
            ];

            const onChangeHandler = (e: null, value: Date) => formik.setFieldValue('approvedOn', value);

            if (name === 'approvedOn') {
              return (
                <S.FieldWrapper>
                  <S.Label>{label}</S.Label>
                  <S.DatePickerAdapter
                    name={name}
                    value={formik.values.approvedOn}
                    hintText={rest.placeholder}
                    formatDate={formatter}
                    onChange={onChangeHandler}
                  />
                </S.FieldWrapper>
              );
            }

            return (
              <S.FieldWrapper key={name}>
                <S.Label>{label}</S.Label>
                <TextInput
                  inputStyles={inputStyles}
                  inputWrapperStyles={S.inputWrapperStyles}
                  meta={formik.getFieldMeta(name)}
                  {...formik.getFieldProps(name)}
                  {...rest}
                />
              </S.FieldWrapper>
            );
          })}

          <ActionsContainer paddingValue={10} gapValue={14}>
            <ActionButton type="button" label="Cancel" onClick={onModalClose} />
            <ActionButton type="submit" label="Save" isNext isGreenLabel />
          </ActionsContainer>
        </S.Form>
      </Modal>
    );
  },
);
