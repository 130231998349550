import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const EmptyList = ({ isFavorite, notFavoriteAlerts, toggleFavorite }) => (
  <S.Container>
    <div>Congratulations!</div>
    <div>There are no active alerts {isFavorite ? 'at your favorite locations.' : 'anywhere.'}</div>
    {isFavorite && (
      <S.FavoriteEmpty>
        <div>
          There {notFavoriteAlerts === 1 ? 'is' : 'are'} {notFavoriteAlerts} alert{notFavoriteAlerts === 1 ? '' : 's'}{' '}
          at other locations.
        </div>
        {!!notFavoriteAlerts && <S.Button onClick={toggleFavorite}>View other alerts</S.Button>}
      </S.FavoriteEmpty>
    )}
  </S.Container>
);

EmptyList.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  notFavoriteAlerts: PropTypes.number.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
};

export default EmptyList;
