import styled, { css } from 'styled-components';
import { InputBlockLabel } from 'components/Layout/Containers';

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primaryLists};
  text-align: start;
`;

const Instruction = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.mainRed};
  padding: 10px;
`;

const Form = styled.form`
  padding: 10px;
  text-align: center;
  max-width: 40vw;
`;

const InputContainer = styled.div`
  margin: 20px 60px;
`;

const inputStyles = css`
  border: 2px solid ${({ theme }) => theme.mainRed};
  border-radius: 18px;
`;

const containerStyles = css`
  flex-direction: column;
  gap: 6px;
`;

const radioGroupStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxWidth: '500px',
  margin: '10px 50px',
  justifyContent: 'center',
};

const radioButtonStyle = {
  width: 'auto',
  minWidth: '220px',
};

const ConfirmationText = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.primaryBlack};
`;

export {
  Label,
  Instruction,
  Form,
  InputContainer,
  inputStyles,
  containerStyles,
  radioGroupStyle,
  radioButtonStyle,
  ConfirmationText,
};
