import React from 'react';
import PropTypes from 'prop-types';

import TestrunButton from 'components/Buttons/TestrunButton';

import SectionRules from './SectionRules';
import { RuleType, ManagerType } from './propTypes';
import * as S from './styled';

const generatePreviewResults = manager => (
  <S.PreviewItem>
    <S.PreviewItemText>
      {`${manager?.FirstName || ''} `}
      {`${manager?.LastName || ''}`}
    </S.PreviewItemText>
  </S.PreviewItem>
);

const generateTeamPreviewResults = team => (
  <S.PreviewItem key={team.Id}>
    <S.PreviewItemText>{team.Name}</S.PreviewItemText>
  </S.PreviewItem>
);

const DisabledRule = ({ rule }) => (
  <S.Section>
    <S.SectionHeader disabled>{rule}</S.SectionHeader>
    <S.TeamTitle />
  </S.Section>
);

DisabledRule.propTypes = {
  rule: PropTypes.string.isRequired,
};

const PickupForm = ({
  managementRules,
  teamRules,
  values,
  availabilityOptions,
  manager,
  previewNames,
  type,
  team,
  personRules,
  person,
  isErrorShown,
  withDisabledFields,
  error,
  addOption,
  removeOption,
  onChangeMatch,
  getPreviewResults,
  teamsAreEmpty,
  teamsList,
  jobsList,
  listStatus,
  reset,
}) => (
  <>
    {type === 2 ? (
      <S.Section>
        <S.SectionHeader>Management/Supervisor Rules</S.SectionHeader>
        <S.TeamTitle>Manager responsible is based on Team selection below</S.TeamTitle>
      </S.Section>
    ) : (
      <SectionRules
        title="Management/Supervisor Rules"
        rules={managementRules}
        addOption={addOption}
        removeOption={removeOption}
        onChangeMatch={onChangeMatch}
        values={values}
        reset={reset}
        availabilityOptions={availabilityOptions}
        teamsAreEmpty={teamsAreEmpty}
        teamsList={teamsList}
        jobsList={jobsList}
        listStatus={listStatus}
      />
    )}
    <S.PreviewResultsContainer>
      {type === 2 ? (
        <div />
      ) : (
        <S.WrapperText>
          <S.BoldText>If more than one manager is found:</S.BoldText>
          Manager with last name priority will be chosen (A-Z)
          <br />
          <br />
          <S.BoldText>If NO manager is found:</S.BoldText>
          <div>
            Tasks will be assigned to “<S.PrimaryText>Unknown Manager</S.PrimaryText>”
          </div>
        </S.WrapperText>
      )}
      <S.Wrapper>
        <TestrunButton
          disabled={type === 2}
          onClick={() => getPreviewResults(values, 'managementRules', 'management')}
        />
        {type === 2
          ? team.Name &&
            previewNames.includes('team') && (
              <S.PreviewResultsInfo>{generatePreviewResults(team.Manager)}</S.PreviewResultsInfo>
            )
          : manager.Name &&
            previewNames.includes('management') && (
              <S.PreviewResultsInfo>{generatePreviewResults(manager)}</S.PreviewResultsInfo>
            )}
      </S.Wrapper>
    </S.PreviewResultsContainer>
    {(type === 2 || withDisabledFields) && (
      <>
        {withDisabledFields && type !== 2 ? (
          <DisabledRule rule="Team Rules" />
        ) : (
          <>
            <S.RedText>Item tasks will be assigned to:</S.RedText>
            <SectionRules
              title="Team Rules"
              rules={teamRules}
              addOption={addOption}
              removeOption={removeOption}
              onChangeMatch={onChangeMatch}
              values={values}
              reset={reset}
              availabilityOptions={availabilityOptions}
              teamsList={teamsList}
              jobsList={jobsList}
              listStatus={listStatus}
            />
            <S.PreviewResultsContainer>
              <S.WrapperText>
                <S.BoldText>If more than one Team is found:</S.BoldText>
                Team with name priority will be chosen (A-Z)
                <br />
                <br />
                <S.BoldText>If NO Team is found:</S.BoldText>
                <div>
                  Tasks will be assigned to “<S.PrimaryText>Unknown Team</S.PrimaryText>” and “
                  <S.PrimaryText>Unknown Manager</S.PrimaryText>”
                </div>
              </S.WrapperText>
              <S.Wrapper>
                <TestrunButton onClick={() => getPreviewResults(values, 'teamRules', 'team')} />
                {team.Name && previewNames.includes('team') && (
                  <S.PreviewResultsInfo>{generateTeamPreviewResults(team)}</S.PreviewResultsInfo>
                )}
              </S.Wrapper>
            </S.PreviewResultsContainer>
          </>
        )}
      </>
    )}
    {(type === 3 || withDisabledFields) && (
      <>
        {withDisabledFields && type !== 3 ? (
          <DisabledRule rule="Person Assignment Rules" />
        ) : (
          <>
            <S.RedText>Item tasks will be assigned to:</S.RedText>
            <SectionRules
              title="Person Assignment Rules"
              rules={personRules}
              addOption={addOption}
              removeOption={removeOption}
              onChangeMatch={onChangeMatch}
              values={values}
              reset={reset}
              availabilityOptions={availabilityOptions}
              teamsList={teamsList}
              jobsList={jobsList}
              listStatus={listStatus}
            />
            <S.PreviewResultsContainer>
              <S.WrapperText>
                <S.BoldText>If more than one person is found:</S.BoldText>
                Person with last name priority will be chosen (A-Z)
                <br />
                <br />
                <S.BoldText>If NO person is found:</S.BoldText>
                <div>
                  Tasks will be assigned to “<S.PrimaryText>Pick-Up</S.PrimaryText>”(Unassigned)
                </div>
              </S.WrapperText>
              <S.Wrapper>
                <TestrunButton onClick={() => getPreviewResults(values, 'personRules', 'person')} />
                {person.Name && previewNames.includes('person') && (
                  <S.PreviewResultsInfo>{generatePreviewResults(person)}</S.PreviewResultsInfo>
                )}
              </S.Wrapper>
            </S.PreviewResultsContainer>
          </>
        )}
      </>
    )}
    {isErrorShown && <S.ErrorContainer>Please add at least one rule</S.ErrorContainer>}
    {!isErrorShown && !!error.length && <S.ErrorContainer>{error}</S.ErrorContainer>}
  </>
);

PickupForm.propTypes = {
  managementRules: RuleType.isRequired,
  teamRules: RuleType.isRequired,
  personRules: RuleType.isRequired,
  values: PropTypes.shape({}).isRequired,
  teamsList: PropTypes.shape({}).isRequired,
  jobsList: PropTypes.shape({}).isRequired,
  listStatus: PropTypes.shape().isRequired,
  type: PropTypes.number.isRequired,
  reset: PropTypes.func.isRequired,
  previewNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  manager: ManagerType.isRequired,
  person: ManagerType.isRequired,
  team: PropTypes.oneOfType([PropTypes.string, ManagerType]).isRequired,
  availabilityOptions: PropTypes.arrayOf(PropTypes.shape({ Id: PropTypes.string, Name: PropTypes.string })).isRequired,
  error: PropTypes.string.isRequired,
  isErrorShown: PropTypes.bool.isRequired,
  withDisabledFields: PropTypes.bool,
  addOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  onChangeMatch: PropTypes.func.isRequired,
  getPreviewResults: PropTypes.func.isRequired,
  teamsAreEmpty: PropTypes.bool.isRequired,
};

PickupForm.defaultProps = {
  withDisabledFields: false,
};

export default PickupForm;
