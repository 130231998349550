export const formatDataInfo = val => {
  if (val < 0) {
    return `- ${Math.abs(val)}`;
  } else if (val > 0) {
    return `+ ${val}`;
  }

  return val;
};

export const helper = () => {};
