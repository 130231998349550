import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PageFooter from 'components/PageFooter';
import ErrorRoutePage from 'components/ErrorRoutePage';

import HomePage from './pages/Home';
import * as S from './styled';

const Home = () => (
  <S.HomeContainer>
    <S.Content>
      <Switch>
        <Route exact path="/home" component={HomePage} />
        <Route path="/*" component={ErrorRoutePage} />
      </Switch>
    </S.Content>
    <PageFooter />
  </S.HomeContainer>
);

export default Home;
