import React from 'react';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import { TextInput } from 'components/Input';
import { PartGroupType, PartType } from 'types/parts';
import { FormValuesType } from '..';

import { PART_GROUP_HEADER, PART_HEADER } from '../configs';

import * as S from './styled';

type PropsType = {
  formik: any;
  type: 'part' | 'group';
};

export const Results = ({ formik, type }: PropsType) => {
  const isPartInventory = type === 'part';
  const header = isPartInventory ? PART_HEADER : PART_GROUP_HEADER;
  const { data, searchResultKeyword } = formik.values as FormValuesType;

  const filteredData = [...data].filter(d => {
    const name = isPartInventory ? (d as PartType).MyPartName : d.Name;

    return name.toLowerCase().includes(searchResultKeyword.toLowerCase());
  });

  const selectAll = (event: React.MouseEvent, isInputChecked: boolean) => {
    const res = data.map(d => ({ ...d, isChecked: isInputChecked }));
    if (isInputChecked) {
      formik.setFieldValue('isValid', true);
    }

    formik.setFieldValue('data', res);
  };

  const onCheckHandler = (isInputChecked: boolean, id: string) => {
    const res = data.map(d => (d.Id === id ? { ...d, isChecked: isInputChecked } : d));
    if (isInputChecked) {
      formik.setFieldValue('isValid', true);
    } else if (res.length && res.every(r => !r.isChecked)) {
      formik.setFieldValue('isValid', false);
    }

    formik.setFieldValue('data', res);
  };

  return (
    <BorderRadiusContainer column>
      <S.Label>Results</S.Label>
      <S.Container>
        <S.Wrapper>
          <TextInput
            {...formik.getFieldProps('searchResultKeyword')}
            meta={formik.getFieldMeta('searchResultKeyword')}
            placeholder="Filter Results"
            inputStyles={S.searchResultStyles}
            name="searchResultKeyword"
            type="search"
          />
        </S.Wrapper>

        {filteredData.length ? (
          <S.Section>
            <S.SelectAllWrapper>
              <S.CheckBox checked={!!data.length && [...data].every(d => d.isChecked)} onCheck={selectAll} />{' '}
              <span>Select All</span>
            </S.SelectAllWrapper>
            <S.List>
              <span>Add</span>
              {header.map(el => (
                <span key={el}>
                  <strong>{el}</strong>
                </span>
              ))}

              {filteredData.map(el => (
                <React.Fragment key={el.Id}>
                  <span>
                    <S.CheckBox
                      checked={el.isChecked}
                      onCheck={(event: React.MouseEvent, isInputChecked: boolean) =>
                        onCheckHandler(isInputChecked, el.Id)
                      }
                    />
                  </span>
                  <span>{isPartInventory ? (el as PartType).MyPartName : (el as PartGroupType).Name}</span>
                  <span>{isPartInventory ? (el as PartType).MyPartID : (el as PartGroupType).Description}</span>
                  <span>{isPartInventory ? (el as PartType).OEMPartID : (el as PartGroupType).LinksCount}</span>
                </React.Fragment>
              ))}
            </S.List>
          </S.Section>
        ) : (
          <div>
            <p>There are no data.</p>
          </div>
        )}
      </S.Container>
    </BorderRadiusContainer>
  );
};
