import styled, { css } from 'styled-components';
import { Checkbox } from 'material-ui';
import { InputBlockLabel } from 'components/Layout/Containers';

const Container = styled.div`
  padding: 10px;
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primaryLists};
  text-align: start;
`;

const Wrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const CheckBox = styled(Checkbox)`
  width: auto !important;
`;

const Section = styled.div`
  margin: 20px;
  color: ${({ theme }) => theme.primaryBlack};
`;

const List = styled.div`
  display: grid;
  grid-template-columns: minmax(30px, 60px) 3fr 2fr 2fr;
  text-align: justify;

  & > span {
    padding: 8px 4px;
    border-bottom: 1.8px solid ${({ theme }) => theme.primaryGrey};
    word-break: break-all;
  }
`;

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

const searchResultStyles = css`
  padding: 7px;
  background: ${({ theme }) => theme.lightGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};

  &:focus {
    border: 1px solid ${({ theme }) => theme.primaryGrey};
  }
`;

export { Container, Label, Wrapper, CheckBox, Section, List, SelectAllWrapper, searchResultStyles };
