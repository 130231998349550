import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';
import { HtmlForm } from './styled';

// think aboud handling render params!

const InputForm = ({ children, controls, flag, renderChildren, ...rest }) => (
  <Form
    {...rest}
    render={({ handleSubmit, form, values, pristine }) => (
      <HtmlForm onSubmit={handleSubmit} noValidate>
        {flag ? renderChildren({ form, values, pristine }) : children}
        {React.cloneElement(controls, { pristine })}
      </HtmlForm>
    )}
  />
);

InputForm.defaultProps = {
  children: {},
  flag: false,
  renderChildren: () => {},
};

InputForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  controls: PropTypes.object.isRequired,
  flag: PropTypes.bool,
  renderChildren: PropTypes.func,
};

export default InputForm;
