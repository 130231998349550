import React from 'react';
import PropTypes from 'prop-types';

import { getUnitImage } from 'http/unit';
import { UnitPictureType, UnitItemType } from 'configs/propTypes';
import { itemDetailsToggle } from 'configs/toggles';
import Toggle from 'components/Toggle';

import { ToolbarContainer } from '../../styled';
import PhotosBody from '../../PhotosBody';
import PdfsBody from '../../PdfsBody';

import * as S from './styled';

const Details = ({
  onDeleteUnitPdf,
  exportUnitPdf,
  uploadUnitPdf,
  pdfs,
  pictures,
  exportUnitQR,
  onDeleteUnitPicture,
  uploadUnitPicture,
  item,
  type,
  typeHandler,
  getImageSource,
}) => (
  <>
    <ToolbarContainer>
      <Toggle config={itemDetailsToggle} selected={type.toLowerCase()} handler={typeHandler} minWidth />
    </ToolbarContainer>
    <S.Container>
      <S.InformationContainer>
        <S.DescriptionContainer>
          <S.InternalSection>
            <S.InternalSectionHeader>Internal Info</S.InternalSectionHeader>
            <S.InternalSectionBody>
              <S.Text>
                <S.BoldText>Item Description: </S.BoldText>
                {item.Description}
              </S.Text>
              <br />
              <S.Text>
                <S.BoldText>Item Instructions: </S.BoldText>
                {item.Instructions}
              </S.Text>
              <S.Text>
                <S.BoldText>Item Notes: </S.BoldText>
                {item.Notes}
              </S.Text>
            </S.InternalSectionBody>
          </S.InternalSection>
        </S.DescriptionContainer>
        <S.BarcodeContainer>
          <S.Section>
            <S.Content>
              {item.Id && item.Barcode ? (
                <S.Image src={getImageSource(item.Id, item.Barcode.Value)} alt={item.Barcode.Value} />
              ) : (
                <S.QRText>QR</S.QRText>
              )}
            </S.Content>
            <S.BarcodeLink onClick={exportUnitQR}>{item.Barcode && item.Barcode.Value}</S.BarcodeLink>
          </S.Section>
        </S.BarcodeContainer>
      </S.InformationContainer>
      <S.FileContainer>
        <S.PdfContainer>
          <PdfsBody
            key="Upload item pdfs dialog"
            handleItemClick={exportUnitPdf}
            onDelete={onDeleteUnitPdf}
            onUpload={uploadUnitPdf}
            pdfs={pdfs}
          />
        </S.PdfContainer>
        <S.ImageContainer>
          <PhotosBody
            key="Upload item photos dialog"
            onDelete={onDeleteUnitPicture}
            onUpload={uploadUnitPicture}
            photos={pictures}
            getUnitImage={getUnitImage}
          />
        </S.ImageContainer>
      </S.FileContainer>
    </S.Container>
  </>
);

Details.propTypes = {
  exportUnitQR: PropTypes.func.isRequired,
  pictures: PropTypes.arrayOf(UnitPictureType).isRequired,
  item: UnitItemType.isRequired,
  type: PropTypes.string.isRequired,
  onDeleteUnitPdf: PropTypes.func.isRequired,
  exportUnitPdf: PropTypes.func.isRequired,
  uploadUnitPdf: PropTypes.func.isRequired,
  pdfs: PropTypes.array.isRequired,
  onDeleteUnitPicture: PropTypes.func.isRequired,
  uploadUnitPicture: PropTypes.func.isRequired,
  typeHandler: PropTypes.func.isRequired,
  getImageSource: PropTypes.func.isRequired,
};

export default Details;
