import { ignoreRules } from './Form/constants';

import { createId } from 'components/Layout/ShortcutRules/helpers';

const checkForIgnoreRules = item => ignoreRules.includes(item.split('-')[1]);

const generateRulesFromArrayOfKeys = (localValues, globalValues) => {
  const optionNames = localValues.filter(item => item.includes('optionName'));
  const optionConstraints = localValues.filter(item => item.includes('constraint'));
  const optionValues = localValues.filter(item => item.includes('optionValue'));
  const matchOperator = localValues.find(item => item.includes('match'));

  const ignoreRule = localValues.find(item => {
    if (checkForIgnoreRules(item) && globalValues[item]) {
      return true;
    }

    return false;
  });

  if (ignoreRule) {
    const item = {};

    item.OptionName = ignoreRule.split('-')[1];
    item.OptionConstraint = -1;
    item.OptionValue = '';
    item.MatchOperator = -1;

    return [item];
  }

  const rules = optionNames.map((optionNameKey, index) => {
    const item = {};

    item.OptionName = globalValues[optionNameKey];
    item.OptionConstraint = parseInt(globalValues[optionConstraints[index]], 10);
    item.OptionValue = globalValues[optionValues[index]];
    item.MatchOperator = parseInt(globalValues[matchOperator], 10);

    return item;
  });

  return rules;
};

export const generateManagementRules = (values, rules) => {
  const managementValues = Object.keys(values).filter(item => item.includes('management'));

  const updatedManagementValues = managementValues
    .map(item => {
      if (item === 'match-management' || rules.find(rule => rule.id === item.split('-')[1])) {
        return null;
      }
      return item;
    })
    .filter(item => item !== null);

  updatedManagementValues.forEach(item => delete values[item]);

  const fixedManagementValues = managementValues.filter(
    item => !updatedManagementValues.find(newItem => newItem === item),
  );

  const data = {
    ManagementRules: generateRulesFromArrayOfKeys(fixedManagementValues, values),
  };
  return data;
};

export const generateTeamRules = (values, rules) => {
  const teamValues = Object.keys(values).filter(item => item.includes('team'));

  const updatedTeamValues = teamValues
    .map(item => {
      if (item === 'match-team' || rules.find(rule => rule.id === item.split('-')[1])) {
        return null;
      }
      return item;
    })
    .filter(item => item !== null);

  updatedTeamValues.forEach(item => delete values[item]);

  const fixedTeamValues = teamValues.filter(item => !updatedTeamValues.find(newItem => newItem === item));

  const data = {
    TeamRules: generateRulesFromArrayOfKeys(fixedTeamValues, values),
  };
  return data;
};

export const generatePersonRules = (values, rules) => {
  const personValues = Object.keys(values).filter(item => item.includes('person'));

  const updatedPersonValues = personValues
    .map(item => {
      if (item === 'match-person' || rules.find(rule => rule.id === item.split('-')[1])) {
        return null;
      }
      return item;
    })
    .filter(item => item !== null);

  updatedPersonValues.forEach(item => delete values[item]);

  const fixedPersonValues = personValues.filter(item => !updatedPersonValues.find(newItem => newItem === item));

  const data = {
    PersonRules: generateRulesFromArrayOfKeys(fixedPersonValues, values),
  };
  return data;
};

export const initialRule = {
  optionName: '',
  optionConstraint: 0,
  optionValue: '',
  matchOperator: 0,
};

export const getInitialRules = ({ rules, name }) => {
  if (!rules) {
    return {
      name,
      match: 0,
      rules: [{ id: createId(name), name, ...initialRule }],
      isRulesIgnored: false,
    };
  }

  return {
    name,
    match: rules[0].MatchOperator,
    rules: rules.map((item, index) => {
      const tmpId = `${createId(name)}${index}`;
      const fieldNames = [`optionName-${tmpId}`, `constraint-${tmpId}`, `optionValue-${tmpId}`];

      return {
        id: tmpId,
        name,
        [fieldNames[0]]: item.OptionName,
        [fieldNames[1]]: item.OptionConstraint,
        [fieldNames[2]]: item.OptionValue,
        matchOperator: item.MatchOperator,
      };
    }),
    isRulesIgnored: false,
  };
};
