import styled from 'styled-components';

import { DeleteIcon as Delete } from 'components/Layout/Icons';
import { ButtonFlat } from 'components/Layout/Buttons';

export const RequestsTopBarInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RequestsNumberContainer = styled.div`
  width: 55px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: ${({ theme, color }) => {
    if (color) {
      return theme[color];
    }
    return theme.requestsNumberColor;
  }};
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  margin: 0 20px 0 10px;
`;

export const DeleteIcon = styled(Delete)`
  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;

export const SubmitButton = styled(ButtonFlat).attrs({})`
  width: 100% !important;
  span {
    color: ${({ theme }) => theme.modalOkColor} !important;
  }
`;
