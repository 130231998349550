/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import theme from 'theme';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import { actions as scheduleActions } from 'redux/schedule';

import { findSite, getInitialDates } from '../helpers';

import * as S from './styled';

export const ChecklistInfoDialog = ({ history }) => {
  const isOpenChecklistInfo = useSelector(state => state.schedule.isOpenChecklistInfo);
  const teams = useSelector(state => state.teams.list);
  const userManagement = useSelector(state => state.schedule.userManagement);
  const scheduleInfo = useSelector(state => state.schedule.details);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const sites = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const taskTemplates = useSelector(state => state.schedule.taskTemplates);
  const dispatch = useDispatch();

  const dates = getInitialDates(scheduleInfo.ScheduleDto);

  const site = findSite(sites, selectedSite);

  const getManager = id => teams.find(({ Id }) => Id === id)?.Manager.Name;

  const getTeamMember = type => {
    const AssignmentType = {
      0: (scheduleInfo.AssigneeID && `${userManagement.FirstName} ${userManagement.LastName}`) || 'Open Assignment',
      1: 'Open Assignment',
    };
    return AssignmentType[type];
  };

  const toEditShortcut = id =>
    history.push({
      pathname: '/shortcuts',
      search: `?id=${id}`,
    });

  const getCountTasks = () => {
    let count = 0;
    Object.keys(taskTemplates).forEach(k => {
      count += taskTemplates[k].length;
    });
    return count;
  };

  const closeChecklistInfo = () => dispatch(scheduleActions.setIsOpenChecklistInfo(false));

  return (
    <Dialog
      open={isOpenChecklistInfo}
      onRequestClose={closeChecklistInfo}
      title="Shortcut Checklist"
      titleColor={theme.mainDarkBlue}
      modalStyles={{ width: '80%', maxWidth: 500 }}
    >
      <S.Container>
        <S.Block />
        <S.Block>
          <S.Title>
            Site:&nbsp;
            <S.SecondaryTitle>{site}</S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Unit:&nbsp;
            <S.SecondaryTitle>{scheduleInfo.UnitName}</S.SecondaryTitle>
          </S.Title>
        </S.Block>
        <S.Block>
          <S.BlockDate>
            <S.Column>
              <div>Repeat Cycle: {dates.repeatCycle}</div>
              <div>Start Date: {dates.startDate}</div>
              <div>End Date: {dates.endDate}</div>
            </S.Column>
            <S.Column>
              <div>Start Time: {dates.startTime}</div>
              <div>End Time: {dates.endTime}</div>
            </S.Column>
          </S.BlockDate>
          <S.RedText>Tasks:</S.RedText>
          <S.ItemsCircle>{getCountTasks()}</S.ItemsCircle>
          <S.RedText>Assigned to:</S.RedText>
        </S.Block>
        <S.Block>
          <S.Title>
            Manager:&nbsp;
            <S.SecondaryTitle>
              {getManager(scheduleInfo.TeamId) || scheduleInfo.SupervisorName || 'All'}
            </S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Team:&nbsp;
            <S.SecondaryTitle>
              {scheduleInfo.ChecklistAssignmentType === 1 || (scheduleInfo.AssigneeID && scheduleInfo.TeamId)
                ? scheduleInfo.TeamName
                : 'All'}
            </S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Team Member:&nbsp;
            <S.SecondaryTitle>{getTeamMember(scheduleInfo.ChecklistAssignmentType)}</S.SecondaryTitle>
          </S.Title>
        </S.Block>
        <ActionsContainer>
          <ActionButton label="Open Shortcut" isNext onClick={() => toEditShortcut(scheduleInfo.ShortcutID)} />
        </ActionsContainer>
      </S.Container>
    </Dialog>
  );
};
