import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form } from 'react-final-form';
import theme from 'theme';

import { actions as statusIndexActions } from 'redux/statusIndex';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import Loader from 'components/Loader';
import { stringifyKeys, destringifyKeys } from 'helpers';

import * as S from './styled';

class IndexDetailsSetup extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      createCharacteristicItemRequest: PropTypes.func.isRequired,
      updateCharacteristicItemRequest: PropTypes.func.isRequired,
      deleteCharacteristicItemRequest: PropTypes.func.isRequired,
    }).isRequired,
    open: PropTypes.bool.isRequired,
    isEditModal: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    characteristicItem: PropTypes.object.isRequired,
    indexItemConfig: PropTypes.object.isRequired,
    indexItem: PropTypes.object.isRequired,
  };

  state = {
    isDeleteConfirmationModalOpen: false,
  };

  openDeleteConfirmationModal = () => this.setState({ isDeleteConfirmationModalOpen: true });
  closeDeleteConfirmationModal = () => this.setState({ isDeleteConfirmationModalOpen: false });

  onSubmitForm = values => {
    values = destringifyKeys(values);
    const {
      isEditModal,
      indexItem,
      handleClose,
      characteristicItem: { Id, IndexItemName, IndexItemId },
      actions: { createCharacteristicItemRequest, updateCharacteristicItemRequest },
    } = this.props;

    const payload = {
      indexItemId: IndexItemId || indexItem.Id,
      id: Id,
      indexItemName: IndexItemName || indexItem.Name,
      dataColumns: values,
    };

    if (isEditModal) {
      updateCharacteristicItemRequest(payload);
    } else {
      createCharacteristicItemRequest(payload);
    }

    handleClose();
  };

  deleteCharacteristic = () => {
    const {
      characteristicItem: { Id, IndexItemId },
      actions: { deleteCharacteristicItemRequest },
      handleClose,
    } = this.props;

    deleteCharacteristicItemRequest({ Id, IndexItemId });
    this.closeDeleteConfirmationModal();
    handleClose();
  };

  render() {
    const { open, handleClose, characteristicItem, isEditModal, indexItem, indexItemConfig, isLoading } = this.props;
    const { isDeleteConfirmationModalOpen } = this.state;
    const dataColumns = isEditModal ? characteristicItem.DataColumns : indexItemConfig.DataColumns;

    return (
      <Dialog
        open={open}
        onRequestClose={handleClose}
        maxWidth="550px"
        title={`${isEditModal ? 'Edit' : 'New'} Dynamic Data Set`}
        titleColor={theme.headerColor}
      >
        <S.DialogContainer>
          <Loader isLoading={isLoading} size="large" />
          <S.FormContainer>
            <S.TitleContainer>
              <p>Data for Index Item:</p>
              <S.Title>{characteristicItem.IndexItemName || indexItem.Name}</S.Title>
              <div>
                <b>ID: </b>
                {indexItem.AdditionalIndexID}
              </div>
            </S.TitleContainer>
            <Form
              onSubmit={this.onSubmitForm}
              initialValues={!isEmpty(dataColumns) && stringifyKeys(dataColumns)}
              render={({ handleSubmit, form }) => {
                const { dirty } = form.getState();

                return (
                  <form onSubmit={handleSubmit}>
                    <S.Block>
                      <S.Label>Dynamic Data</S.Label>
                      <S.InputContainer>
                        {Object.keys(!isEmpty(dataColumns) && dataColumns).map((key, i) => (
                          <Field key={i} component={TextFieldAdapter} floatingLabelText={key} name={`key${key}`} />
                        ))}
                      </S.InputContainer>
                    </S.Block>
                    <ActionsContainer gapValue={14}>
                      {isEditModal && (
                        <ActionButton type="button" label="Delete" onClick={this.openDeleteConfirmationModal} />
                      )}
                      <ActionButton
                        type="submit"
                        isNext
                        label={isEditModal ? 'Save' : 'Save / Add'}
                        isDisable={!dirty}
                      />
                    </ActionsContainer>
                  </form>
                );
              }}
            />
          </S.FormContainer>
        </S.DialogContainer>
        <ConfirmationDialog
          label="Yes"
          maxWidth="80%"
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationWarning>
                This action cannot be undone,
                <br /> are you sure?
              </S.ConfirmationWarning>
            </S.ConfirmationContainer>
          }
          open={isDeleteConfirmationModalOpen}
          onClick={this.deleteCharacteristic}
          handleClose={this.closeDeleteConfirmationModal}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = ({ statusIndex: { indexItem, characteristicItem, indexItemConfig, isLoading } }) => ({
  indexItem,
  characteristicItem,
  indexItemConfig,
  isLoading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...statusIndexActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexDetailsSetup);
