import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';

export const iconStyle = {
  color: '#000000',
  height: '30px',
  width: '30px',
};

export const DialogContainer = styled.div`
  padding: 30px;
`;

export const CardContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  border-radius: 15px;
  margin-bottom: 40px;
`;

export const CardHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px 15px 0 0;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.primaryTasks};
  color: ${({ theme }) => theme.lightGrey};
  font-weight: 500;
`;

export const CardContentContainer = styled.div`
  padding: 20px;
`;

export const DateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Date = styled(Info)`
  font-size: 14px;
  margin: 0 0 10px;
`;

export const Value = styled.span`
  font-weight: bold;
`;

export const CardInfoList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.primaryGrey}`};
  padding-right: 10px;
`;

export const IncheqLink = styled.a`
  color: ${({ theme }) => theme.primaryBlack};
  text-decoration: none;
`;

export const EditEmailButton = styled(ButtonIcon)`
  & svg {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  border-top: 1px solid ${({ theme }) => theme.lightGrey};
`;

export const ControlButton = styled.button`
  width: 48%;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme, isApprove }) => (isApprove ? theme.primaryGreen : theme.primaryRed)};

  &:hover {
    background-color: ${({ theme }) => theme.lightGrey};
  }
`;
