import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import * as S from './styled';

const TextInput = ({ name, placeholder, ...rest }) => (
  <Field name={name}>
    {({ input, meta }) => (
      <S.TextInput
        {...rest}
        type={name === 'password' ? 'password' : 'text'}
        {...input}
        meta={meta}
        placeholder={meta.error && meta.touched ? meta.error : placeholder}
      />
    )}
  </Field>
);

TextInput.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default TextInput;
