import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled.js';

const Controls = ({ buttons, withBorder }) => (
  <S.Container withBorder={withBorder}>
    {buttons.map((item, index) => {
      if (item.isComponent) {
        return <S.ButtonContainer key={index}>{React.cloneElement(item.component, {})}</S.ButtonContainer>;
      }
      return (
        <S.ButtonContainer key={index}>
          {item.icon && !item.title && (
            <S.IconButton
              onClick={item.handler}
              tooltip={item.hint}
              tooltipStyles={item.tooltipStyles}
              tooltipPosition={item.tooltipPosition}
              disabled={item.disabled}
              {...item}
            >
              {item.icon}
            </S.IconButton>
          )}
          {item.title && !item.icon && (
            <S.RaisedButton
              onClick={item.handler}
              label={item.title}
              buttoncolor={item.buttonColor}
              labelcolor={item.labelColor}
              borderstyle={item.borderStyle}
              disabled={item.disabled}
            />
          )}
          {item.icon && item.title && (
            <S.FlatButton
              onClick={item.handler}
              label={item.title}
              icon={item.icon}
              labelPosition={item.labelPosition}
            />
          )}
        </S.ButtonContainer>
      );
    })}
  </S.Container>
);

Controls.defaultProps = {
  buttons: [],
  withBorder: false,
};

Controls.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      handler: PropTypes.func,
      title: PropTypes.string,
      icon: PropTypes.node,
      hint: PropTypes.string,
      labelPosition: PropTypes.string,
      tooltipPosition: PropTypes.string,
    }),
  ),
  withBorder: PropTypes.bool,
};

export default Controls;
