import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { InfoIcon } from 'components/Layout/Icons';
import * as S from './styled';

const BASE_INDEX = 1000;

class UnitItem extends PureComponent {
  static propTypes = {
    getItemName: PropTypes.func.isRequired,
    getItemInstruction: PropTypes.func,
    getItemText: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    islast: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    leftCheckbox: PropTypes.bool.isRequired,
    onUnitItemClick: PropTypes.func.isRequired,
    goToDetailsPage: PropTypes.func.isRequired,
    onCheck: PropTypes.func.isRequired,
    renderLeftControls: PropTypes.func.isRequired,
    renderRightControls: PropTypes.func.isRequired,
    renderRightText: PropTypes.func.isRequired,
    checked: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleToggle: PropTypes.func,
    withDelete: PropTypes.bool.isRequired,
    showDetailsButton: PropTypes.bool,
  };

  static defaultProps = {
    handleToggle: () => {},
    getItemInstruction: null,
    showDetailsButton: false,
  };

  onItemClick = event => {
    event.stopPropagation();

    this.props.onUnitItemClick(this.props.item);
  };

  onCheck = (event, value, item) => {
    this.props.onCheck(item, value);
  };

  onDetailsClick = e => {
    e.stopPropagation();
    const { goToDetailsPage, item } = this.props;
    goToDetailsPage(item);
  };

  render() {
    const {
      getItemName,
      getItemInstruction,
      getItemText,
      item,
      islast,
      leftCheckbox,
      renderLeftControls,
      renderRightControls,
      renderRightText,
      checked,
      handleToggle,
      index,
      withDelete,
      showDetailsButton,
    } = this.props;

    return (
      <S.Container islast={islast} index={BASE_INDEX - index}>
        <S.Line>
          <S.ButtonsBlock>
            {renderLeftControls(item)}
            {leftCheckbox && (
              <S.CheckBox
                checked={checked.includes(item.Id)}
                onCheck={(event, value) => this.onCheck(event, value, item)}
              />
            )}
          </S.ButtonsBlock>
          <S.Line onClick={item.Status !== 'Completed' ? this.onItemClick : undefined}>
            <S.TextBlock>
              <S.ItemName withDelete={withDelete}>{getItemName(item)}</S.ItemName>
              <S.ItemText>{getItemText(item)}</S.ItemText>
            </S.TextBlock>
            {getItemInstruction && <S.SecondaryText>{getItemInstruction(item)}</S.SecondaryText>}
            {item.Status === 'Completed' ? (
              <S.InfoBlock>
                <S.TextInfoBlock>{renderRightText(item)}</S.TextInfoBlock>
                <S.Button tooltipPosition="bottom-center" tooltip="Details" onClick={() => handleToggle(item)}>
                  <InfoIcon />
                  {renderRightControls(item)}
                </S.Button>
              </S.InfoBlock>
            ) : (
              <S.ButtonsBlock>
                {renderRightText(item)} {renderRightControls(item)}
              </S.ButtonsBlock>
            )}
            {showDetailsButton && (
              <S.Button
                tooltipPosition="bottom-center"
                tooltip="Details"
                onClick={item.Status !== 'Completed' ? this.onDetailsClick : undefined}
              >
                <S.IconForward />
              </S.Button>
            )}
          </S.Line>
        </S.Line>
      </S.Container>
    );
  }
}

export default UnitItem;
