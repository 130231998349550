import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import withInputModal from 'components/Dialogs/withInputModal';
import { TextareaField } from 'components/Layout/Textarea';

import * as S from './styled';

const SignUpNotificationForm = () => (
  <S.FormContainer>
    <S.InstructionsText>
      When someone signs up for Incheq they receive an email with a link to a verification page.
    </S.InstructionsText>
    <Field component={TextareaField} name="signUpText" floatingLabelText="Sign Up E-Mail Text" rows={7} />
    <Field
      component={TextareaField}
      name="signUpSignatureText"
      floatingLabelText="Sign Up E-Mail Signature Text"
      rows={3}
    />
    <S.InstructionsText>The following information will be added to the email :</S.InstructionsText>
    <S.AdditionalInfoContainer>
      <S.AdditionalInfoTextContainer>
        <S.AdditionalInfoText>Incheq Logo (60x60)</S.AdditionalInfoText>
        <S.AdditionalInfoText>Unique Verification Link</S.AdditionalInfoText>
      </S.AdditionalInfoTextContainer>
      <S.Logo />
    </S.AdditionalInfoContainer>
  </S.FormContainer>
);

const SignUpNotification = withInputModal(SignUpNotificationForm);

const SignUpNotificationDialog = props => <SignUpNotification initialValues={props.notifications} {...props} />;

SignUpNotificationDialog.propTypes = {
  notifications: PropTypes.shape({
    signUpText: PropTypes.string,
    signUpSignatureText: PropTypes.string,
  }).isRequired,
};

SignUpNotificationDialog.defaultProps = {
  notifications: {
    signUpText: '',
    signUpSignatureText: '',
  },
};

const mapStateToProps = ({ settings: { signUpNotification } }) => ({
  notifications: signUpNotification,
});

export default connect(mapStateToProps)(SignUpNotificationDialog);
