import React from 'react';

import { GateWayIcon, HubIcon, ResourceGroupsIcon, TenantsModuleIcon, Location } from 'components/Layout/Icons';

export const USER_TYPES = {
  Unknown: 0,
  TaskSpecialist: 1,
  Manager: 2,
  SystemAdministrator: 4,
  SystemController: 8,
  OEMAdministrator: 16,
  OEMManager: 32,
  DashboardUser: 64,
};

export const resourceIcons = {
  resourcegroup: <ResourceGroupsIcon />,
  tenant: <TenantsModuleIcon />,
  site: <Location />,
  hub: <HubIcon />,
  gateway: <GateWayIcon />,
  connection: <GateWayIcon />,
};

export const requestsTypes = {
  new: 0,
  blocked: 1,
  sent: 2,
};
