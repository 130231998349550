import { call, put, takeEvery } from 'redux-saga/effects';

import { getSelectedSite } from 'helpers/storage';

import { actions } from './index';

import {
  getAllIndexItems,
  getListIndexRule,
  getIndexItemPreview,
  getIndexItemCharacteristicPreview,
  getHeadersConfig,
  getCharacteristicsConfig,
  addIndexRule,
  putIndexRule,
  removeIndexRule,
  createIndexItem,
  deleteIndexItem,
  editIndexItem,
  getIndexItem,
  getIndexCharacteristicList,
  getIndexCharacteristic,
  createIndexCharacteristic,
  editIndexItemConfig,
  deleteIndexCharacteristic,
  editIndexCharacteristic,
  getShortcutPreview,
} from 'http/statusIndex';
import { ERROR_DIALOG_OPENED } from '../errorHandler';
import { sortObjectByKey, parseServerError, convertDateToThreeDigit, getHourFromDate } from 'helpers';

function* fetchAllIndexItems() {
  try {
    const siteId = yield call(getSelectedSite);
    const { data } = yield call(getAllIndexItems, { siteId });
    const formattedIndexItems = data.root.map(item => ({
      ...item,
      CreatedOn: item.CreatedOn && convertDateToThreeDigit(item.CreatedOn),
      LiveDate: item.LiveDate && convertDateToThreeDigit(item.LiveDate),
      EndDate: item.EndDate && convertDateToThreeDigit(item.EndDate),
      LiveTime: item.LiveDate && getHourFromDate(item.LiveDate),
      EndTime: item.EndDate && getHourFromDate(item.EndDate),
    }));
    const sortedDataByName = sortObjectByKey(formattedIndexItems, 'Name');

    yield put(actions.setIndexItemsStatus('updated'));

    yield put(actions.setIndexItemsSuccess(sortedDataByName));
    yield put(actions.setIndexItemsStatus('succeeded'));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsFailure(error));
    yield put(actions.setIndexItemsStatus('failed'));
  }
}
function* fetchListIndexRule() {
  try {
    const siteId = yield call(getSelectedSite);
    const { data } = yield call(getListIndexRule, { siteId });
    yield put(actions.fetchListIndexRuleSuccess(data.root));
  } catch (error) {
    yield put(actions.fetchListIndexRuleFailure(error));
  }
}

function* fetchIndexItemPreview({ payload }) {
  try {
    const { data } = yield call(getIndexItemPreview, payload);
    yield put(actions.fetchIndexItemPreviewSuccess(data.root));
  } catch (error) {
    yield put(actions.fetchIndexItemPreviewFailure(error));
  }
}

function* fetchIndexItemCharacteristicPreview({ payload }) {
  try {
    const { data } = yield call(getIndexItemCharacteristicPreview, payload);
    yield put(actions.fetchIndexItemCharacteristicPreviewSuccess(data.root));
  } catch (error) {
    yield put(actions.fetchIndexItemCharacteristicPreviewFailure(error));
  }
}

function* fetchShortcutPreview({ payload }) {
  try {
    const { data } = yield call(getShortcutPreview, payload);
    yield put(actions.fetchShortcutPreviewSuccess(data.root));
  } catch (error) {
    yield put(actions.fetchShortcutPreviewFailure(error));
  }
}

function* fetchIndexItemConfig({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(getHeadersConfig, payload);

    yield put(actions.setIndexItemsStatus('updated'));
    yield put(actions.setIndexItemConfigSuccess(entity));
    yield put(actions.setIndexItemsStatus('succeeded'));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsStatus('failed'));
    yield put(actions.setIndexItemsFailure(error));
    yield put(actions.fetchIndexItemConfigFailure(error));
  }
}

function* fetchIndexItemCharacteristicConfig({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(getCharacteristicsConfig, payload);

    yield put(actions.setIndexItemsStatus('updated'));
    yield put(actions.setIndexItemConfigSuccess(entity));
    yield put(actions.fetchIndexItemCharacteristicConfigSuccess(entity));
    yield put(actions.setIndexItemsStatus('succeeded'));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsStatus('failed'));
    yield put(actions.setIndexItemsFailure(error));
    yield put(actions.fetchIndexItemCharacteristicConfigFailure(error));
  }
}

function* createIndexRule({ payload }) {
  try {
    const { data } = yield call(addIndexRule, payload);
    yield put(actions.createIndexRuleSuccess(data.entity));
    yield put(actions.fetchListIndexRuleRequest());
  } catch (error) {
    yield put(actions.createIndexRuleFailure(error));
  }
}

function* editIndexRule({ payload }) {
  try {
    const { data } = yield call(putIndexRule, payload);
    yield put(actions.editIndexRuleSuccess(data.entity));
    yield put(actions.fetchListIndexRuleRequest());
  } catch (error) {
    yield put(actions.editIndexRuleFailure(error));
  }
}

function* deleteIndexRule({ payload }) {
  try {
    const { data } = yield call(removeIndexRule, payload);
    yield put(actions.deleteIndexRuleSuccess(data.entity));
    yield put(actions.fetchListIndexRuleRequest());
  } catch (error) {
    yield put(actions.deleteIndexRuleFailure(error));
  }
}

function* fetchIndexItem({ payload }) {
  try {
    const siteId = yield call(getSelectedSite);
    const { data } = yield call(getIndexItem, { siteId, id: payload });

    yield put(actions.setIndexItemSuccess(data.entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsFailure(error));
  }
}

function* addIndexItem({ payload }) {
  try {
    const siteId = yield call(getSelectedSite);
    const { data } = yield call(createIndexItem, { siteId, dto: payload });

    yield put(actions.setIndexItemSuccess(data.entity));
    yield put(actions.fetchAllIndexItemsRequest());
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsStatus('failed'));
    yield put(actions.setIndexItemFailure(error));
  }
}

function* updateIndexItem({ payload }) {
  try {
    const siteId = yield call(getSelectedSite);
    const { data } = yield call(editIndexItem, { siteId, dto: payload });

    yield put(actions.setIndexItemSuccess(data.entity));
    yield put(actions.fetchAllIndexItemsRequest());
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsStatus('failed'));
    yield put(actions.setIndexItemsFailure(error));
  }
}

function* removeIndexItem({ payload }) {
  try {
    const siteId = yield call(getSelectedSite);
    yield call(deleteIndexItem, { siteId, id: payload });

    yield put(actions.fetchAllIndexItemsRequest());
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsStatus('failed'));
    yield put(actions.setIndexItemsFailure(error));
  }
}

function* fetchCharacteristicList({ payload }) {
  try {
    const {
      data: { root },
    } = yield call(getIndexCharacteristicList, payload);

    yield put(actions.setCharacteristicListSuccess(root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsFailure(error));
  }
}

function* fetchCharacteristicItem({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(getIndexCharacteristic, payload);

    yield put(actions.setCharacteristicItemSuccess(entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsFailure(error));
  }
}

function* updateIndexItemConfig({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(editIndexItemConfig, payload);

    yield put(actions.setIndexItemsStatus('updated'));
    yield put(actions.setIndexItemConfigSuccess(entity));
    yield put(actions.setIndexItemsStatus('succeeded'));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsFailure(error));
  }
}

function* createCharacteristicItem({ payload }) {
  try {
    yield call(createIndexCharacteristic, payload);

    yield put(actions.fetchCharacteristicListRequest(payload.indexItemId));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsFailure(error));
  }
}

function* updateCharacteristicItem({ payload }) {
  try {
    yield call(editIndexCharacteristic, payload);

    yield put(actions.fetchCharacteristicListRequest(payload.indexItemId));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsFailure(error));
  }
}

function* deleteCharacteristicItem({ payload }) {
  try {
    yield call(deleteIndexCharacteristic, payload.Id);

    yield put(actions.fetchCharacteristicListRequest(payload.IndexItemId));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setIndexItemsFailure(error));
  }
}

const statusIndexSagas = [
  takeEvery(actions.fetchAllIndexItemsRequest, fetchAllIndexItems),
  takeEvery(actions.fetchListIndexRuleRequest, fetchListIndexRule),
  takeEvery(actions.fetchIndexItemPreviewRequest, fetchIndexItemPreview),
  takeEvery(actions.fetchIndexItemCharacteristicPreviewRequest, fetchIndexItemCharacteristicPreview),
  takeEvery(actions.fetchShortcutPreviewRequest, fetchShortcutPreview),
  takeEvery(actions.createIndexRuleRequest, createIndexRule),
  takeEvery(actions.editIndexRuleRequest, editIndexRule),
  takeEvery(actions.deleteIndexRuleRequest, deleteIndexRule),
  takeEvery(actions.fetchIndexItemRequest, fetchIndexItem),
  takeEvery(actions.createIndexItemRequest, addIndexItem),
  takeEvery(actions.editIndexItemRequest, updateIndexItem),
  takeEvery(actions.deleteIndexItemRequest, removeIndexItem),
  takeEvery(actions.fetchCharacteristicListRequest, fetchCharacteristicList),
  takeEvery(actions.fetchCharacteristicItemRequest, fetchCharacteristicItem),
  takeEvery(actions.createCharacteristicItemRequest, createCharacteristicItem),
  takeEvery(actions.updateCharacteristicItemRequest, updateCharacteristicItem),
  takeEvery(actions.deleteCharacteristicItemRequest, deleteCharacteristicItem),
  takeEvery(actions.fetchIndexItemConfigRequest, fetchIndexItemConfig),
  takeEvery(actions.fetchIndexItemCharacteristicConfigRequest, fetchIndexItemCharacteristicConfig),
  takeEvery(actions.updateIndexItemConfigRequest, updateIndexItemConfig),
];

export default statusIndexSagas;
