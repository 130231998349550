import styled from 'styled-components';

export const ListSectionName = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.textGray};
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const ListContainer = styled.div`
  & > div:last-child {
    padding-bottom: 0;
  }
`;

export const RequestsInfoContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
`;

export const RequestsNumberContainer = styled.div`
  width: 55px;
  height: 37px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.requestsNumberColor};
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  margin: 0 20px 0 10px;
`;
