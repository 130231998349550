import styled from 'styled-components';

import { Item } from 'modules/globalsettings/pages/styled';

export const GroupItem = styled(Item)`
  width: calc(100% / 4);
`;

export const TiersCount = styled.div`
  width: 65px;
`;
