import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

const RadioGroup = ({ input, meta, settingsValue, fullWidth }) => (
  <RadioButtonGroup
    name="SettingDto"
    defaultSelected={settingsValue.PhotoLimit}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={fullWidth}
  >
    <RadioButton value={0} label="Include all photos in reports (default-multiple pages per task)" />
    <RadioButton value={2} label="Limit photo's to 2 (one page per reported task)" />
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  settingsValue: PropTypes.object.isRequired,
  fullWidth: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RadioGroup;
