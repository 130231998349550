import { stringComparator } from 'helpers';

export const dataSourceConfig = {
  text: 'siteName',
  value: 'siteId',
};

export const setArrayWithSites = sites =>
  sites
    .map(value => ({
      siteName: value.additionalId ? `${value.additionalId.substring(0, 5)} ${value.name}` : `${value.name}`,
      siteId: value.id,
    }))
    .sort((a, b) => stringComparator(a.siteName, b.siteName, true));

export const statisticsLabels = {
  CompletedTasksNumber: 'completed',
  PastDueTasksNumber: 'past due',
  OpenTasksNumber: 'open',
  RequireAttentionTasksNumber: 'attention',
  ContributorsNumber: 'contributors',
};

export const ATTENTION_MODULE = 'Attention';
