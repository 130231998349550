import { EMPTY_GUID } from 'helpers';

// sequence of data in request matters
export const formatUnitItem = (item, unitId) => ({
  Id: item.Id || EMPTY_GUID,
  UnitId: unitId,
  Sequence: item.Sequence || 0,
  Name: item.Name.trim(),
  Description: item.Description || '',
  Instructions: item.Instructions || '',
  Barcode: item.Barcode?.Value
    ? {
        Value: item.Barcode.Value,
        IsGeneratedDefault: false,
      }
    : null,
  Notes: item.Notes || '',
  BeaconMinor: item.BeaconMinor || 0,
  BeaconDistance: item.BeaconDistance,
});

// sequence of data in request matters
export const formatTaskDuplicate = (itemId, taskId) => `itemId=${itemId || ''}&id=${taskId}`;
