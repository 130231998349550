import styled from 'styled-components';

import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const Block = styled(BorderRadiusContainer)``;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primaryDocuments};
`;
