import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { fetchManagerStatus } from 'http/teams';
import { getStats } from 'http/users';
import theme from 'theme';
import { USER_TYPES } from 'configs/enums';
import {
  FilterTeamMemberType,
  SiteUserManagerType,
  UserType,
  MembersForAssigmentType,
  TeamMemberType,
  EditTeamType,
} from 'configs/propTypes';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { AddIcon, BackIcon, CallSplitIcon, SendIcon } from 'components/Layout/Icons';
import SetFunctionsDialog from 'components/Dialogs/SetFunctionsDialog';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import AddEditTeamDialog from 'modules/teams/AddEditTeamDialog';

import AddEditPersonDialog from './AddEditPersonDialog';
import SendLoginEmailDialog from './SendLoginEmailDialog';
import EmailContentDialog from './EmailContentDialog';
import StandartMessageDialog from './StandartMessageDialog';
import Item from './Item';
import AssignedSitesDialog from './AssignedSitesDialog';
import UserStatsDialog from './UserStatsDialog';
import LinkTeamsDialog from './LinkItemsDialog';
import AddTeamMembersDialog from './AddTeamMemberDialog';
import ManagerStatsDialog from './ManagerStatsDialog';
import { REMOVAL_TYPES, CONFIRMATION_TEXTS, ACTIONS, COMPLETION_TEXTS } from './config';
import ManagerHasTeamsDialog from './ManagerHasTeamsDialog';
import { mapStateToProps, mapDispatchToProps } from './index.props';
import * as S from './styled';

class PeopleList extends PureComponent {
  static propTypes = {
    addPerson: PropTypes.func.isRequired,
    createdEditedPersonId: PropTypes.string.isRequired,
    deletePerson: PropTypes.func.isRequired,
    editPerson: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    isManager: PropTypes.bool.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    fetchSitesList: PropTypes.func.isRequired,
    fetchAssignedSite: PropTypes.func.isRequired,
    fetchAvailabilityOptions: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    peopleList: PropTypes.arrayOf(UserType).isRequired,
    getSettings: PropTypes.func.isRequired,
    getSitesForAdmin: PropTypes.func.isRequired,
    createTeamRequest: PropTypes.func.isRequired,
    updateTeamRequest: PropTypes.func.isRequired,
    getMasterSettings: PropTypes.func.isRequired,
    addTeamMembersRequest: PropTypes.func.isRequired,
    onSiteChange: PropTypes.func.isRequired,
    requestSendEmailContent: PropTypes.func.isRequired,
    getTeamListBySite: PropTypes.func.isRequired,
    teamList: PropTypes.array.isRequired,
    fetchJobsOptions: PropTypes.func.isRequired,
    unassignedManager: PropTypes.func.isRequired,
    siteList: PropTypes.arrayOf(
      PropTypes.shape({
        siteName: PropTypes.string.isRequired,
        siteId: PropTypes.string.isRequired,
      }),
    ).isRequired,
    tenantIsOEM: PropTypes.bool.isRequired,
    updateAssignedSites: PropTypes.func.isRequired,
    userType: PropTypes.number.isRequired,
    availabilityOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    team: EditTeamType.isRequired,
    jobsList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    teamMembers: PropTypes.arrayOf(TeamMemberType).isRequired,
    membersForAssigment: PropTypes.arrayOf(MembersForAssigmentType).isRequired,
    filteredTeamMembers: PropTypes.arrayOf(FilterTeamMemberType).isRequired,
    getFilteredTeamMembersRequest: PropTypes.func.isRequired,
    getMembersForAssigmentRequest: PropTypes.func.isRequired,
    getTeamMembersRequest: PropTypes.func.isRequired,
    getSiteManagersRequest: PropTypes.func.isRequired,
    siteManagers: PropTypes.arrayOf(SiteUserManagerType).isRequired,
    sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
  };

  state = {
    addEditPersonDialogOpened: false,
    AssignSitesDialogOpened: false,
    currentPersonId: '',
    dialogTitle: '',
    emailContentOpened: false,
    personToEdit: null,
    editedPerson: null,
    userStats: {},
    teamStats: {},
    searchData: '',
    searchUserSitesData: '',
    selectedUserType: '',
    sendLoginEmailOpened: false,
    standartMessageOpened: false,
    userName: '',
    isConfirmationDialogOpened: false,
    removalType: null,
    accountsDialogOpen: false,
    showPassword: false,
    linkTeamsDialogOpened: false,
    teamToEdit: {},
    addTeamDialogOpened: false,
    addTeamMembersDialogOpened: false,
    isUserStatsDialogOpened: false,
    isManagerStatusOpened: false,
    isManagerHasTeamsDialogOpened: false,
    isManagerHasTasksDialogOpenedL: false,
    successTeamReassignmentDialogOpened: false,
    isManagerTaskDialog: false,
    successPersonRemovedDialogOpened: false,
    successTeamCreatingEditingDialogOpened: false,
    isEdit: false,
    siteId: '',
  };

  componentDidMount() {
    const {
      fetchPeople,
      fetchSitesList,
      fetchAvailabilityOptions,
      fetchJobsOptions,
      getSettings,
      getMasterSettings,
      userType,
      getTeamListBySite,
    } = this.props;
    fetchJobsOptions();
    fetchAvailabilityOptions();
    fetchPeople();
    fetchSitesList();
    getTeamListBySite();
    if (userType === USER_TYPES.SystemAdministrator) {
      getSettings();
      getMasterSettings();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }

    if (nextProps.createdEditedPersonId && !this.state.accountsDialogOpen) {
      this.closeAddEditPersonDialog();
    }
  }
  handleSuccessPersonRemovedDialog = () => {
    this.setState({ successPersonRemovedDialogOpened: false });
    this.closeAddEditPersonDialog();
  };
  succesDeleteUser = open => {
    if (open) {
      this.setState({ successPersonRemovedDialogOpened: true });
    } else {
      this.closeAddEditPersonDialog();
    }
  };

  onDeleteApprove = selectedRemovalType => {
    this.setState({ isConfirmationDialogOpened: false });
    this.props.deletePerson({
      personId: this.state.personToEdit.Id,
      type: selectedRemovalType,
      cb: this.succesDeleteUser,
    });
  };

  onDeletePerson = () => {
    const { personToEdit } = this.state;
    getStats(personToEdit.Id).then(data => {
      this.setState({ removalType: REMOVAL_TYPES.REMOVE });
      if (personToEdit.UserType === 'Manager') {
        this.onDeleteManager(data);
      } else if (data.totalCount === 0) {
        this.setState({ isConfirmationDialogOpened: true });
      } else {
        this.setState({ isUserStatsDialogOpened: true, userStats: data });
      }
    });
  };

  closeAddEditPersonDialog = () => {
    this.setState({
      addEditPersonDialogOpened: false,
      isConfirmationDialogOpened: false,
      isUserStatsDialogOpened: false,
      isManagerStatusOpened: false,
    });
  };

  goToRelatedTasks = person => this.props.history.push(`/tasks/${person.Id}/${moment().format('YYYY-MM-DD')}`);

  closeEmailContentDialog = () => this.setState({ emailContentOpened: false });

  openEmailContentDialog = () => this.setState({ emailContentOpened: true });

  setEmailContent = values => {
    const { currentPersonId } = this.state;

    this.props.requestSendEmailContent({ userId: currentPersonId, ...values });
    this.closeEmailContentDialog();
  };

  closeStandartMessageDialog = () => this.setState({ standartMessageOpened: false });

  openStandartMessageDialog = () => this.setState({ standartMessageOpened: true });

  openAddEditPersonDialog = person => {
    if (person.Id) {
      this.setState({
        addEditPersonDialogOpened: true,
        personToEdit: {
          ...person,
          JobFunction: person.JobFunctionID || this.props.jobsList[0].Id,
          Status: person.Status || 'Active',
          UserType: person.UserType.replace(/\s/g, ''),
        },
      });
      if (person.JobFunctionName === 'Unknown') {
        this.setState({
          personToEdit: {
            ...person,
            JobFunction: this.props.jobsList[0].Id,
            Status: person.Status || 'Active',
            UserType: person.UserType.replace(/\s/g, ''),
          },
        });
      }
    } else {
      this.setState({ addEditPersonDialogOpened: true, personToEdit: null });
    }
  };

  searchInList = event => this.setState({ searchData: event.target.value });

  filterSites = site => site.siteName.toLowerCase().includes(this.state.searchUserSitesData.toLowerCase());

  searchSites = event => this.setState({ searchUserSitesData: event.target.value });

  filterPeoples = item => {
    const filterBy = ['Name', 'FirstName', 'LastName'];
    const query = this.state.searchData.toLowerCase();
    return filterBy.some(field => get(item, field).toLowerCase().includes(query));
  };

  openAssignSitesDialogOpened = id => this.setState({ AssignSitesDialogOpened: true, currentPersonId: id });

  goToRelatedSites = person => {
    const { getSitesForAdmin } = this.props;
    const payload = {
      userId: person.Id,
      openDialog: this.openAssignSitesDialogOpened,
    };

    this.setState({
      dialogTitle: `${person.Name} (${person.UserType})`,
      selectedUserType: person.UserType,
      userName: person.Name,
    });

    getSitesForAdmin();
    this.props.fetchAssignedSite(payload);
  };

  closeAssignSitesDialog = () => {
    this.setState({
      AssignSitesDialogOpened: false,
      searchUserSitesData: '',
    });
  };

  saveAssignedSites = ({ siteIds }) => {
    const { currentPersonId } = this.state;
    const { updateAssignedSites } = this.props;

    updateAssignedSites({ userId: currentPersonId, siteIds });
    this.closeAssignSitesDialog();
    this.openSendLoginEmailDialog();
  };

  closeSendLoginEmailDialog = () => this.setState({ sendLoginEmailOpened: false });

  openSendLoginEmailDialog = () => this.setState({ sendLoginEmailOpened: true });

  submitSendLoginEmail = () => {
    this.closeSendLoginEmailDialog();
    this.openEmailContentDialog();
  };

  handleUserStatsDialogClose = () =>
    this.setState({ isUserStatsDialogOpened: false, userStats: {}, removalType: null, isManagerTaskDialog: false });

  handleManagerStatsDialogClose = () =>
    this.setState({
      isManagerStatusOpened: false,
      userStats: {},
      teamStats: {},
      removalType: null,
      isManagerTaskDialog: false,
    });

  handleManagerHasTeamsDialogClose = () =>
    this.setState({
      isManagerHasTeamsDialogOpened: false,
      userStats: {},
      teamStats: {},
      removalType: null,
      isManagerTaskDialog: false,
    });

  handleDeleteOrDeactivation = (type, action) => {
    if (type === REMOVAL_TYPES.REMOVE) {
      return this.onDeleteApprove(action);
    }

    if (type === REMOVAL_TYPES.DEACTIVATE) {
      const { editedPerson } = this.state;
      const { editPerson } = this.props;
      return editPerson({ personData: editedPerson, type: action });
    }

    return null;
  };

  handleManagerStatsDialogSubmit = teams => {
    if (teams > 0) {
      this.setState({ isManagerHasTeamsDialogOpened: true, isManagerStatusOpened: false });
    } else {
      this.setState({ isManagerStatusOpened: false, isUserStatsDialogOpened: true });
    }
  };

  handleSuccessTeamReassignment = () => {
    const { userStats } = this.state;
    if (userStats.totalCount === 0) {
      return this.setState({
        isManagerHasTeamsDialogOpened: false,
        successTeamReassignmentDialogOpened: false,
        isConfirmationDialogOpened: true,
      });
    }
    return this.setState({
      isManagerHasTeamsDialogOpened: false,
      successTeamReassignmentDialogOpened: false,
      isUserStatsDialogOpened: true,
    });
  };

  openSuccessDialog = open => {
    if (open) {
      this.setState({ successTeamReassignmentDialogOpened: true });
    } else {
      this.setState({
        isManagerHasTeamsDialogOpened: false,
        userStats: {},
        isManagerTaskDialog: false,
        teamStats: {},
        removalType: null,
      });
    }
  };

  handleManagerHasTeamsDialogSubmit = (type, action) => {
    const { unassignedManager } = this.props;
    const { personToEdit } = this.state;
    unassignedManager({ personId: personToEdit.Id, type: action, cb: this.openSuccessDialog });
  };

  changePersonToEdit = person => {
    this.setState({ personToEdit: person });
  };

  handleRemovalReject = () => this.setState({ isConfirmationDialogOpened: false });

  handleAddEditPerson = form => {
    const { personToEdit } = this.state;
    const { editPerson, addPerson } = this.props;

    const editedForm = {
      ...form,
      UserAvailabilityOptionID: this.props.availabilityOptions.find(item => item.Name === 'Unknown').Id,
      JobFunctionID: this.props.jobsList.find(item => item.Id === form.JobFunction).Id,
    };

    if (!personToEdit) {
      return addPerson(editedForm);
    }

    if (editedForm.Status === 'Inactive' && personToEdit.Status === 'Active') {
      return getStats(personToEdit.Id).then(data => {
        this.setState({
          removalType: REMOVAL_TYPES.DEACTIVATE,
          editedPerson: editedForm,
        });
        if (personToEdit.UserType === 'Manager') {
          this.onDeleteManager(data);
        } else if (data.totalCount === 0) {
          this.setState({
            isConfirmationDialogOpened: true,
          });
        } else {
          this.setState({
            isUserStatsDialogOpened: true,
            userStats: data,
          });
        }
      });
    }
    return editPerson({ personData: editedForm, type: ACTIONS.none });
  };

  onDeleteManager = data => {
    const { personToEdit } = this.state;
    fetchManagerStatus(personToEdit.Id).then(teamData => {
      if (teamData.totalCount === 0 && data.totalCount === 0) {
        this.setState({
          isConfirmationDialogOpened: true,
          userStats: data,
        });
      } else {
        this.setState({
          isManagerStatusOpened: true,
          userStats: data,
          teamStats: teamData,
          isManagerTaskDialog: true,
        });
      }
    });
  };

  handleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  openAccountsDialog = () => this.setState({ accountsDialogOpen: true });

  closeAccountsDialog = () => {
    this.props.fetchJobsOptions();
    this.setState({ accountsDialogOpen: false });
  };

  backHome = () => {
    this.props.history.push('/home');
  };

  closeLinkTeamsDialog = () => this.setState({ linkTeamsDialogOpened: false });

  openLinkTeamsDialog = person => {
    this.setState({ linkTeamsDialogOpened: true, personToEdit: { ...person } });
  };

  renderRightControls = person => {
    const { isManager, tenantIsOEM, availabilityOptions, editPerson } = this.props;
    const isInactive = person.Status === 'Inactive';

    return (
      !tenantIsOEM && (
        <S.RightControlsContainer>
          {person.UserType === 'Manager' && (
            <S.RelatedTasksButton
              onClick={() => (isInactive ? {} : this.openLinkTeamsDialog(person))}
              tooltip={
                isInactive ? 'Inactive User. This action can not be completed due to the status of the user.' : 'Teams'
              }
              isInactive={isInactive}
            >
              {isInactive ? <S.TeamsInactiveIcon /> : <S.TeamsIcon />}
            </S.RelatedTasksButton>
          )}
          {person.JobFunction !== 'DefaultAdmin' ? (
            <SelectField
              floatingLabelText="Availability"
              value={person.UserAvailabilityOptionID}
              onChange={(e, index, value) =>
                editPerson({
                  personData: { ...person, UserAvailabilityOptionID: value },
                  type: ACTIONS.none,
                })
              }
              style={{ width: 150, marginBottom: 24, marginRight: -6 }}
              listStyle={{ paddingBottom: 0 }}
            >
              <S.HeaderMenuItem disabled key="header">
                Availability
              </S.HeaderMenuItem>
              {availabilityOptions.map(({ Id, Name }) => (
                <MenuItem
                  style={{
                    color: theme.textGray,
                    borderBottomWidth: 1,
                    borderBottomColor: theme.textGray,
                    borderBottomStyle: 'solid',
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                  value={Id}
                  primaryText={Name}
                />
              ))}
            </SelectField>
          ) : null}
          {!isManager && (
            <S.ActiveSitesButton
              onClick={() => (isInactive ? {} : this.goToRelatedSites(person))}
              tooltip={
                isInactive
                  ? 'Inactive User. This action can not be completed due to the status of the user.'
                  : 'User Sites'
              }
              isInactive={isInactive}
            >
              <S.IconPinDrop />
            </S.ActiveSitesButton>
          )}
          {person.UserType !== 'Dashboard User' ? (
            <>
              <S.RelatedTasksButton
                onClick={() => (isInactive ? {} : this.goToRelatedTasks(person))}
                tooltip={
                  isInactive
                    ? 'Inactive User. This action can not be completed due to the status of the user.'
                    : 'Tasks'
                }
                isInactive={isInactive}
              >
                <S.CalendarIcon />
              </S.RelatedTasksButton>
            </>
          ) : (
            <S.ActionPlaceholder />
          )}
        </S.RightControlsContainer>
      )
    );
  };

  renderEmailContantTitle = title => (
    <S.Title>
      <div>{title}</div>
      <b>eMail content</b>
    </S.Title>
  );

  fromEmailContentDialog = () => {
    this.closeAssignSitesDialog();
    this.openEmailContentDialog();
  };

  renderSplitButton = () => (
    <S.SplitButton onClick={this.fromEmailContentDialog} tooltip="Send mail" tooltipPosition="top-center">
      <CallSplitIcon />
    </S.SplitButton>
  );

  renderAssignedSitesTitle = dialogTitle => <S.AssignedSitesTitleUser>{dialogTitle}</S.AssignedSitesTitleUser>;

  closeAddEditTeamDialog = () => this.setState({ addTeamDialogOpened: false });

  openAddEditTeamDialog = (team, siteId) => {
    const { getSiteManagersRequest } = this.props;
    if (team) {
      this.setState({ addTeamDialogOpened: true, teamToEdit: team });
      this.setState({ siteId });
      getSiteManagersRequest({ siteId });
    } else {
      this.setState({ addTeamDialogOpened: true, teamToEdit: {} });
      getSiteManagersRequest({ siteId: null });
    }
  };

  closeAddTeamMembersDialog = () => {
    this.setState({
      siteId: '',
      addTeamMembersDialogOpened: false,
      successTeamCreatingEditingDialogOpened: true,
    });
  };

  openTeamMembersDialog = () => {
    const { team, getTeamMembersRequest, getMembersForAssigmentRequest, getFilteredTeamMembersRequest } = this.props;
    this.closeAddEditTeamDialog();
    getTeamMembersRequest({ teamId: team.Id });
    getFilteredTeamMembersRequest({ teamId: team.Id, filter: { includeIsMultiGroupUser: true } });
    getMembersForAssigmentRequest({ teamId: team.Id });
    this.setState({ addTeamMembersDialogOpened: true, teamToEdit: team });
  };

  handleSubmitAddEditTeamDialog = item => {
    const { teamToEdit } = this.state;
    const { updateTeamRequest, createTeamRequest } = this.props;
    if (teamToEdit?.Name) {
      updateTeamRequest({ team: item, ignoreSecurityRestriction: true, cb: this.openTeamMembersDialog });
      this.setState({ isEdit: true });
    } else {
      createTeamRequest({ item, cb: this.openTeamMembersDialog });
      this.setState({ isEdit: false });
    }
  };

  goToAttention = name => {
    const {
      history: { push },
      sitesAvailableToUserFullInfo,
      onSiteChange,
    } = this.props;
    const updatedSites = sitesAvailableToUserFullInfo.map(item => ({
      siteName: item.name,
      siteId: item.id,
    }));
    onSiteChange({ checkedSite: name, sitesCollection: updatedSites });
    push(`/queue/${moment().format('YYYY-MM-DD')}/all`);
  };

  handleSuccessTeamCreatingEditing = () =>
    this.setState({ successTeamCreatingEditingDialogOpened: false, isEdit: false });

  render() {
    const {
      addEditPersonDialogOpened,
      AssignSitesDialogOpened,
      currentPersonId,
      dialogTitle,
      emailContentOpened,
      personToEdit,
      searchData,
      searchUserSitesData,
      selectedUserType,
      sendLoginEmailOpened,
      standartMessageOpened,
      userName,
      userStats,
      teamStats,
      isConfirmationDialogOpened,
      removalType,
      accountsDialogOpen,
      showPassword,
      linkTeamsDialogOpened,
      teamToEdit,
      addTeamDialogOpened,
      addTeamMembersDialogOpened,
      isUserStatsDialogOpened,
      isManagerStatusOpened,
      isManagerHasTeamsDialogOpened,
      successTeamReassignmentDialogOpened,
      isManagerTaskDialog,
      successPersonRemovedDialogOpened,
      successTeamCreatingEditingDialogOpened,
      isEdit,
      siteId,
    } = this.state;
    const {
      history,
      peopleList,
      siteList,
      tenantIsOEM,
      userType,
      jobsList,
      isLoading,
      addTeamMembersRequest,
      membersForAssigment,
      teamMembers,
      filteredTeamMembers,
      siteManagers,
      teamList,
    } = this.props;
    const editDefaultAdmin =
      !!personToEdit &&
      (personToEdit.JobFunction === 'DefaultAdmin' || personToEdit.JobFunctionName === 'Default Admin');

    return [
      <Subheader
        key="People list subheader"
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: this.backHome,
            hint: 'Back',
          },
        ]}
        rightButtons={[
          {
            icon: <AddIcon />,
            handler: this.openAddEditPersonDialog,
            hint: 'Add',
          },
        ]}
        title="People List"
        isSearch
        searchData={searchData}
        searchInList={this.searchInList}
      />,
      <SimpleList
        key="People list"
        data={sortBy(peopleList.filter(this.filterPeoples), [user => user.Name.toLowerCase()])}
        emptyListMessage="There are no people avaliable"
        onItemClick={this.openAddEditPersonDialog}
        renderItemContent={Item}
        renderRightControls={this.renderRightControls}
      />,
      <AddEditPersonDialog
        key="Add edit person dialog"
        handleClose={this.closeAddEditPersonDialog}
        handleDelete={this.onDeletePerson}
        onSubmit={this.handleAddEditPerson}
        open={addEditPersonDialogOpened}
        handleShowPassword={this.handleShowPassword}
        showPassword={showPassword}
        person={personToEdit || undefined}
        title={personToEdit ? 'Edit Person' : 'Add Person'}
        withDelete={false}
        gapValue="20px"
        hasPadding
        hasPaddingBottom
        tenantIsOEM={tenantIsOEM}
        editDefaultAdmin={editDefaultAdmin}
        disableButtons={editDefaultAdmin}
        jobsList={jobsList}
        openAccountsDialog={this.openAccountsDialog}
        isNewDeleteButton={!!personToEdit && !editDefaultAdmin}
        isNewSubmitButton
        withSubmit={false}
        userType={userType}
        deleteLabel="Remove Person"
      />,
      <AssignedSitesDialog
        key="User sites dialog"
        title={this.renderAssignedSitesTitle(dialogTitle)}
        subtitle={<h3>User Sites</h3>}
        open={AssignSitesDialogOpened}
        handleClose={this.closeAssignSitesDialog}
        history={history}
        onSubmit={this.saveAssignedSites}
        personId={currentPersonId}
        provideIcon={this.renderSplitButton()}
        isSearch
        searchData={searchUserSitesData}
        searchInList={this.searchSites}
        siteList={siteList.filter(this.filterSites)}
        withoutTitleBorderBottom
      />,
      <SendLoginEmailDialog
        key="Send login email dialog"
        handleClose={this.closeSendLoginEmailDialog}
        onSubmit={this.submitSendLoginEmail}
        open={sendLoginEmailOpened}
        cancelLabel="No"
        submitLabel="Yes"
        title="Send login e-mail"
        userName={userName}
        withCancel
      />,
      <EmailContentDialog
        key="Email content dialog"
        handleClose={this.closeEmailContentDialog}
        icon={<SendIcon color={theme.primaryScheduler} />}
        onClick={this.openStandartMessageDialog}
        onSubmit={this.setEmailContent}
        open={emailContentOpened}
        title={this.renderEmailContantTitle(dialogTitle)}
        withoutTopBorder
      />,
      <StandartMessageDialog
        key="Standart message dialog"
        handleClose={this.closeStandartMessageDialog}
        open={standartMessageOpened}
        title={`Standart message for ${selectedUserType}`}
        userType={selectedUserType}
      />,
      <UserStatsDialog
        open={isUserStatsDialogOpened}
        handleClose={this.handleUserStatsDialogClose}
        onSubmit={this.handleDeleteOrDeactivation}
        name={personToEdit?.Name}
        data={userStats}
        type={removalType}
        goToAttention={this.goToAttention}
        isManagerTaskDialog={isManagerTaskDialog}
      />,
      <ManagerStatsDialog
        open={isManagerStatusOpened}
        handleClose={this.handleManagerStatsDialogClose}
        onSubmit={this.handleManagerStatsDialogSubmit}
        data={userStats}
        teamData={teamStats}
        name={personToEdit?.Name}
        type={removalType}
      />,
      <ManagerHasTeamsDialog
        open={isManagerHasTeamsDialogOpened}
        handleClose={this.handleManagerHasTeamsDialogClose}
        onSubmit={this.handleManagerHasTeamsDialogSubmit}
        teamData={teamStats}
        name={personToEdit?.Name}
        type={removalType}
        goToAttention={this.goToAttention}
      />,
      <ConfirmationDialog
        headingText={CONFIRMATION_TEXTS[removalType]}
        descriptionText="This action cannot be undone, are you sure?"
        open={isConfirmationDialogOpened}
        onClick={() => this.handleDeleteOrDeactivation(removalType, ACTIONS.none)}
        handleClose={this.handleRemovalReject}
      />,
      <SetFunctionsDialog
        key="Accounts"
        open={accountsDialogOpen}
        handleClose={this.closeAccountsDialog}
        title="Job functions"
        changePersonToEdit={this.changePersonToEdit}
        personToEdit={personToEdit}
      />,
      <LinkTeamsDialog
        handleClose={this.closeLinkTeamsDialog}
        open={linkTeamsDialogOpened}
        units={teamList}
        openAddEditTeamDialog={this.openAddEditTeamDialog}
        isLoading={isLoading}
        manager={personToEdit}
      />,
      <AddEditTeamDialog
        teamToEdit={teamToEdit}
        peopleList={siteManagers}
        handleClose={this.closeAddEditTeamDialog}
        onSubmit={this.handleSubmitAddEditTeamDialog}
        open={addTeamDialogOpened}
        title="Team Assignments"
      />,
      <AddTeamMembersDialog
        isLoading={isLoading}
        onAdd={addTeamMembersRequest}
        team={teamToEdit}
        people={membersForAssigment}
        assignedMembers={teamMembers}
        filteredTeamMembers={filteredTeamMembers}
        open={addTeamMembersDialogOpened}
        handleClose={this.closeAddTeamMembersDialog}
        title="Select Team Members"
        siteId={isEdit ? siteId : null}
      />,
      <ConfirmationDialog
        open={successTeamReassignmentDialogOpened}
        onClick={this.handleSuccessTeamReassignment}
        handleClose={this.handleSuccessTeamReassignment}
        label="Next"
        isNext
        width="70%"
        maxWidth="400px"
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationTitle>Team reassignment succeeded!</S.ConfirmationTitle>
          </S.ConfirmationContainer>
        }
      />,
      <ConfirmationDialog
        open={successTeamCreatingEditingDialogOpened}
        onClick={this.handleSuccessTeamCreatingEditing}
        handleClose={this.handleSuccessTeamCreatingEditing}
        label="Next"
        isNext
        width="70%"
        maxWidth="400px"
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationTitle>{isEdit ? COMPLETION_TEXTS.EDITING : COMPLETION_TEXTS.CREATING}</S.ConfirmationTitle>
          </S.ConfirmationContainer>
        }
      />,
      <ConfirmationDialog
        open={successPersonRemovedDialogOpened}
        onClick={this.handleSuccessPersonRemovedDialog}
        handleClose={this.handleSuccessPersonRemovedDialog}
        label="Next"
        isNext
        width="70%"
        maxWidth="400px"
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationTitle>Success!</S.ConfirmationTitle>
            <S.ConfirmationText>The selected person has been removed.</S.ConfirmationText>
          </S.ConfirmationContainer>
        }
      />,
    ];
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleList);
