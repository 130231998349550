import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useModal } from '@ebay/nice-modal-react';
import theme from 'theme';
import { IndustryType, ShortCutType, SiteType, UnitType } from 'configs/propTypes';
import { USER_TYPES } from 'configs/enums';

import UpgradeInfoDialog from 'components/Dialogs/UpgradeInfoDialog';
import UpgradeInfoSuccessDialog from 'components/Dialogs/UpgradeInfoDialog/UpgradeInfoSuccessDialog';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import SearchField from 'components/Layout/Search';
import Card from 'components/Layout/Card';

import ShortcutSetup from '../dialogs/ShortcutSetup';
import NewSite from '../dialogs/NewSite';
import ListTemplate from '../dialogs/ListTemplate';
import { FirstTimeSetupDialog } from '../dialogs/FirstTimeSetup';
import AddingChecklistDialog from '../dialogs/AddingChecklist';

import { CARDS } from './../helpers';
import * as S from './../styled';

import { mapStateToProps, mapDispatchToProps } from './index.props';
import {
  renderConfirmationText,
  renderConfirmationTextWithWarning,
  renderMappedConfirmationText,
  renderInfoListTemplateConfirmationText,
} from './helpers';

function GeneralActivator({
  userType,
  industriesList,
  isNewSiteAvailable,
  limit,
  currentItemsNumber,
  user,
  peopleList,
  sitesList,
  shortcut,
  history,
  action,
}) {
  const [dialogsOpened, setDialogsOpened] = useState({
    firstSetup: false,
    addChecklist: false,
    newSite: false,
    addListTemplate: false,
  });
  const [isDemoMode, setIsDemoMode] = useState(false);
  const [firstTimeNewSite, setFirstTimeNewSite] = useState(true);
  const [isShortcutFromListTemplate, setIsShortcutFromListTemplate] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [firstTimeTemplate, setFirstTimeTemplate] = useState(true);
  const [anotherUnitTemplate, setAnotherUnitTemplate] = useState(false);
  const [listTemplateCurrentSite, setListTemplateCurrentSite] = useState(null);
  const [listTemplateNames, setListTemplateNames] = useState({
    templateNames: [],
    checkDuplicate: true,
  });
  const [cards, setCards] = useState(CARDS[userType]);

  const confirmationModal = useModal(ConfirmationModal);
  const upgradeInfoModal = useModal(UpgradeInfoDialog);
  const upgradeInfoSuccessModal = useModal(UpgradeInfoSuccessDialog);

  const allCards = CARDS[userType];
  const isFirstTimeSetupVisible =
    user.sitesAvailableToUserFullInfo?.length === 0 ||
    sitesList ||
    (peopleList.length === 1 && peopleList[0].Id === user.userId && userType === USER_TYPES.SystemAdministrator);
  const activatorCards = isFirstTimeSetupVisible ? cards : cards.filter(item => item.type !== 'firstSetup');

  const resetState = dialogToClose => setDialogsOpened({ ...dialogsOpened, [dialogToClose]: false });

  const confirmTemplatesExit = () => {
    resetState('addListTemplate');
    confirmationModal.hide();
    setFirstTimeTemplate(true);
    setListTemplateCurrentSite(null);
  };

  const onPositiveHandlerForTemplatesDialog = () => {
    confirmTemplatesExit();
    setListTemplateNames({ templateNames: [], heckDuplicate: true });
    setAnotherUnitTemplate(false);
  };

  const cancelConfirmationTemplatesDialog = () => {
    if (dialogsOpened.addListTemplate) {
      confirmationModal.hide();
    } else {
      confirmationModal.show(MODALS.anotherTemplate);
      confirmationModal.hide();
    }
  };

  const handleDeleteShortcutApproval = () => {
    action.deleteShortcutRequest({ shortcutId: shortcut.Id });

    setIsShortcutFromListTemplate(false);
    if (dialogsOpened.addChecklist) {
      resetState('addChecklist');
    } else {
      resetState('addShortcut');
    }
    confirmationModal.hide();
  };

  const confirmNewSitesExit = () => {
    resetState('newSite');
    setFirstTimeNewSite(true);
    confirmationModal.hide();
  };

  const cancelConfirmationSitesDialog = () => {
    if (dialogsOpened.newSite) {
      confirmationModal.hide();
    } else {
      confirmationModal.show(MODALS.anotherSite);
      confirmationModal.hide();
    }
  };

  const closeUpgradeInfoDialog = () => {
    resetState('addListTemplate');
    upgradeInfoModal.hide();
  };

  const submitUpgradeInfoDialog = values => {
    action.requestUpgradeInfo({ values, openSuccessDialog: () => upgradeInfoSuccessModal.show({ isActivator: true }) });
    closeUpgradeInfoDialog();
  };

  const handleAnotherItemClose = () => {
    setFirstTimeTemplate(true);
    confirmationModal.hide();
    confirmationModal.show(MODALS.anotherTemplate);
  };

  const handleAnotherTemplateClose = () => {
    setAnotherUnitTemplate(false);
    confirmationModal.hide();
    confirmationModal.show(MODALS.infoListTemplate);
  };

  const onCancelClickForAddAnotherSiteDialog = () => {
    if (!isNewSiteAvailable) {
      setFirstTimeNewSite(true);
      handleNewSiteExit();
      confirmationModal.hide();
      confirmationModal.show(MODALS.siteLimit);
    } else {
      setFirstTimeNewSite(false);
      confirmationModal.hide();
      setDialogsOpened({ ...dialogsOpened, newSite: true });
    }
  };

  const MODALS = {
    shortcutDeleting: {
      label: 'Delete Shortcut',
      text: renderConfirmationTextWithWarning(
        'Aborting the process will delete this shortcut and cannot be undone!',
        'Are you sure?',
      ),
      onClick: handleDeleteShortcutApproval,
    },
    sites: {
      label: 'Yes',
      labelCancel: 'Return',
      isNext: true,
      text: renderConfirmationText('Are you sure you want to stop and cancel all other actions?'),
      onClick: confirmNewSitesExit,
      onCancelClick: cancelConfirmationSitesDialog,
    },
    templates: {
      label: 'Yes',
      labelCancel: 'Return',
      isNext: true,
      text: renderConfirmationText('Are you sure you want to stop and cancel all other actions?'),
      onClick: onPositiveHandlerForTemplatesDialog,
      onCancelClick: cancelConfirmationTemplatesDialog,
    },
    upgradeInfo: {
      title: 'Upgrade Info',
      onSubmit: submitUpgradeInfoDialog,
      BigButtons: true,
      industryList: industriesList,
      submitLabel: 'SEND REQUEST',
    },
    siteLimit: {
      label: 'NO/NEXT',
      labelCancel: 'Request Upgrade',
      isNext: true,
      isColumn: true,
      withBorder: true,
      width: '40%',
      text: renderMappedConfirmationText(['Sorry, you have reached your Site limit.', 'Please contact us to upgrade.']),
      onClick: confirmationModal.hide,
      onCancelClick: () => {
        confirmationModal.hide();
        upgradeInfoModal.show(MODALS.upgradeInfo);
      },
    },
    itemLimit: {
      labelCancel: 'Request Upgrade',
      isNext: true,
      isColumn: true,
      withBorder: true,
      width: '40%',
      text: renderMappedConfirmationText(['Sorry, you have reached your Item limit.', 'Please contact us to upgrade.']),
      onCancelClick: () => {
        confirmationModal.hide();
        upgradeInfoModal.show(MODALS.upgradeInfo);
      },
    },
    anotherSite: {
      title: 'Confirmation',
      label: 'NO/NEXT',
      labelCancel: 'YES',
      titleColor: theme.mainGreen,
      isNext: true,
      width: '40%',
      isColumn: true,
      withBorder: true,
      text: renderConfirmationText('Would you like to add another Site?'),
      onClick: () => {
        confirmationModal.hide();
        confirmationModal.show(MODALS.templateAfterSite);
      },
      handleClose: () => {
        confirmationModal.hide();
        confirmationModal.show(MODALS.sites);
      },
      onCancelClick: onCancelClickForAddAnotherSiteDialog,
    },
    anotherItem: {
      title: 'Confirmation',
      label: 'NO/NEXT',
      labelCancel: 'YES',
      titleColor: theme.mainGreen,
      isNext: true,
      width: '40%',
      isColumn: true,
      withBorder: true,
      text: renderConfirmationText('Would you like to add another Item?'),
      onClick: handleAnotherItemClose,
      handleClose: handleAnotherItemClose,
      onCancelClick: () => {
        confirmationModal.hide();
        if (limit && currentItemsNumber === limit) {
          openItemLimitDialog();
        } else {
          setFirstTimeTemplate(false);
          setAnotherUnitTemplate(false);
          setDialogsOpened({ ...dialogsOpened, addListTemplate: true });
        }
      },
    },
    anotherTemplate: {
      title: 'Confirmation',
      label: 'NO/NEXT',
      labelCancel: 'YES',
      titleColor: theme.mainGreen,
      isNext: true,
      width: '40%',
      isColumn: true,
      withBorder: true,
      text: renderConfirmationText('Would you like to add another Template?'),
      onClick: handleAnotherTemplateClose,
      handleClose: handleAnotherTemplateClose,
      onCancelClick: () => {
        confirmationModal.hide();
        setListTemplateNames({ ...listTemplateNames, checkDuplicate: true });
        if (limit && currentItemsNumber === limit) {
          confirmationModal.show(MODALS.itemLimit);
        } else {
          setAnotherUnitTemplate(true);
          setDialogsOpened({ ...dialogsOpened, addListTemplate: true });
        }
      },
    },
    templateAfterSite: {
      title: 'Confirmation',
      label: 'NO/NEXT',
      labelCancel: 'YES',
      titleColor: theme.mainGreen,
      isNext: true,
      width: '40%',
      isColumn: true,
      withBorder: true,
      text: renderConfirmationText('Would you like to add List Template(s) to this or other Sites?'),
      onClick: confirmationModal.hide,
      onCancelClick: () => {
        confirmationModal.hide();
        setDialogsOpened({ ...dialogsOpened, addListTemplate: true });
      },
    },
    importListTemplate: {
      title: 'Confirmation',
      label: 'NO/NEXT',
      labelCancel: 'YES',
      titleColor: theme.mainGreen,
      isNext: true,
      width: '40%',
      isColumn: true,
      withBorder: true,
      text: renderConfirmationText('Would you like to import another List Template for this site?'),
      onClick: confirmationModal.hide,
      onCancelClick: () => {
        confirmationModal.hide();
        setListTemplateNames({ ...listTemplateNames, checkDuplicate: true });
        setAnotherUnitTemplate(true);
        setDialogsOpened({ ...dialogsOpened, addListTemplate: true });
      },
    },
    infoListTemplate: {
      title: 'Confirmation',
      label: 'NEXT',
      titleColor: theme.mainGreen,
      isNext: true,
      width: '70%',
      maxWidth: '550px',
      isColumn: true,
      withBorder: true,
      text: renderInfoListTemplateConfirmationText(listTemplateCurrentSite, listTemplateNames),
      onClick: () => {
        setListTemplateNames({ templateNames: [], checkDuplicate: true });
        confirmationModal.hide();
        confirmationModal.show(MODALS.scheduleTaskOffer);
      },
    },
    scheduleTaskOffer: {
      title: 'Confirmation',
      label: 'NO/NEXT',
      labelCancel: 'YES',
      titleColor: theme.mainGreen,
      isNext: true,
      width: '40%',
      isColumn: true,
      withBorder: true,
      text: renderConfirmationText('Would you like to schedule Tasks for someone?'),
      onClick: () => {
        confirmationModal.hide();
        setAnotherUnitTemplate(false);
      },
      onCancelClick: () => {
        setAnotherUnitTemplate(false);
        confirmationModal.hide();
        setIsShortcutFromListTemplate(true);
        setDialogsOpened({ ...dialogsOpened, addShortcut: true });
      },
    },
  };

  const openItemLimitDialog = () => confirmationModal.show(MODALS.itemLimit);
  const openSiteLimitDialog = () => confirmationModal.show(MODALS.siteLimit);
  const openAnotherSiteDialog = useCallback(
    () => confirmationModal.show(MODALS.anotherSite),
    [MODALS.anotherSite, confirmationModal],
  );
  const openAnotherItemDialog = () => confirmationModal.show(MODALS.anotherItem);
  const openImportListTemplateDialog = () => confirmationModal.show(MODALS.importListTemplate);
  const openScheduleTaskOfferDialog = () => confirmationModal.show(MODALS.scheduleTaskOffer);
  const openShortcutDeletingDialog = () => confirmationModal.show(MODALS.shortcutDeleting);

  const setListTemplateCurrentSiteHandler = site => setListTemplateCurrentSite(site);

  const onSearch = (newCards, value) => {
    setSearchValue(value);
    setCards(newCards);
  };

  const handleCardPress = type => {
    if (type === 'addShortcut') {
      setIsShortcutFromListTemplate(false);
    }
    if ((type === 'newSite' || type === 'firstSetup') && !isNewSiteAvailable) {
      openSiteLimitDialog();
    } else if (type === 'addListTemplate' && limit && currentItemsNumber === limit) {
      openItemLimitDialog();
    } else {
      setDialogsOpened({ ...dialogsOpened, [type]: true });
    }
  };

  const handleShortcutClose = () => {
    if (shortcut && shortcut.Id) {
      openShortcutDeletingDialog();
    } else {
      resetState('addShortcut');
    }

    setIsShortcutFromListTemplate(false);
  };

  const handleChecklistClose = props => {
    if (props === false) {
      resetState('addChecklist');
    }

    if (shortcut && shortcut.Id) {
      openShortcutDeletingDialog();
    }

    setIsShortcutFromListTemplate(false);
    resetState('addChecklist');
  };

  const handleShortcutExit = () => {
    setIsShortcutFromListTemplate(true);
    setIsDemoMode(false);
    resetState('addShortcut');
  };

  const handleListExit = () => resetState('addListTemplate');

  const handleListClose = () => {
    confirmationModal.show(MODALS.templates);
    setIsDemoMode(false);
  };

  const handleNewSiteClose = useCallback(() => {
    confirmationModal.show(MODALS.sites);
    setIsDemoMode(false);
  }, [MODALS.sites, confirmationModal]);

  const handleNewSiteExit = useCallback(() => {
    setFirstTimeNewSite(true);
    resetState('newSite');
  }, [resetState]);

  const handleNextFirstTimeSetup = () => {
    setDialogsOpened({ ...dialogsOpened, firstSetup: false, newSite: true });
    setIsDemoMode(true);
  };

  const openCreateShortcutDialog = () => {
    setIsShortcutFromListTemplate(false);
    setDialogsOpened({ ...dialogsOpened, addChecklist: false, addShortcut: true });
  };

  useEffect(() => {
    action.updateCurrentModule('activator');
    action.requestGetSettings();
    action.getLimitsRequest();

    if (userType !== USER_TYPES.OEMManager) {
      action.checkSiteRequest();
      action.fetchSitesList();
      action.fetchPeople();
      action.fetchTeams();
    }
  }, [action, userType]);

  return (
    <>
      <S.PageContainer>
        <SearchField
          list={allCards}
          filterBy="title"
          searchData={searchValue}
          placeholder="Search"
          underlineShow
          inputStyle={S.searchInputStyles}
          setSearchedItems={onSearch}
        />
        <S.PageTitle>Activator</S.PageTitle>
        <S.ActivatorsContainer>
          {activatorCards.map(({ title, info, primaryIcon, secondaryIcon, type, color, tooltipText }) => (
            <Card
              key={title}
              info={info}
              onCardClick={() => handleCardPress(type)}
              PrimaryIcon={primaryIcon}
              SecondaryIcon={secondaryIcon}
              title={title}
              color={color}
              tooltip={tooltipText}
            />
          ))}
        </S.ActivatorsContainer>
      </S.PageContainer>
      <FirstTimeSetupDialog
        open={dialogsOpened.firstSetup}
        handleClose={() => resetState('firstSetup')}
        handleNext={handleNextFirstTimeSetup}
      />
      <AddingChecklistDialog
        open={dialogsOpened.addChecklist}
        handleClose={handleChecklistClose}
        handleExit={() => resetState('addChecklist')}
        openCreateShortcutDialog={openCreateShortcutDialog}
      />
      <ShortcutSetup
        open={dialogsOpened.addShortcut}
        handleClose={handleShortcutClose}
        handleExit={handleShortcutExit}
        isShortcutFromListTemplate={isShortcutFromListTemplate}
        isDemoMode={isDemoMode}
        history={history}
      />
      <NewSite
        open={dialogsOpened.newSite}
        handleClose={handleNewSiteClose}
        handleExit={handleNewSiteExit}
        addAnotherSite={openAnotherSiteDialog}
        firstTimeNewSite={firstTimeNewSite}
        isDemoMode={isDemoMode}
      />
      <ListTemplate
        open={dialogsOpened.addListTemplate}
        handleClose={handleListClose}
        handleExit={handleListExit}
        addAnotherItem={openAnotherItemDialog}
        openImportListTemplateDialog={openImportListTemplateDialog}
        openScheduleTaskOfferDialog={openScheduleTaskOfferDialog}
        openItemLimitDialog={openItemLimitDialog}
        firstTimeTemplate={firstTimeTemplate}
        anotherUnitTemplate={anotherUnitTemplate}
        currentSite={listTemplateCurrentSite}
        listTemplateNames={listTemplateNames}
        setCurrentSite={setListTemplateCurrentSiteHandler}
      />
    </>
  );
}

GeneralActivator.propTypes = {
  userType: PropTypes.number.isRequired,
  shortcut: ShortCutType.isRequired,
  action: PropTypes.shape({
    updateCurrentModule: PropTypes.func.isRequired,
    deleteShortcutRequest: PropTypes.func.isRequired,
    checkSiteRequest: PropTypes.func.isRequired,
    requestUpgradeInfo: PropTypes.func.isRequired,
    getLimitsRequest: PropTypes.func.isRequired,
    requestGetSettings: PropTypes.func.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    fetchTeams: PropTypes.func.isRequired,
    fetchSitesList: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    userId: PropTypes.string,
    sitesAvailableToUserFullInfo: PropTypes.arrayOf(SiteType),
    unitsAvailableToUser: PropTypes.arrayOf(UnitType),
  }).isRequired,
  peopleList: PropTypes.arrayOf(
    PropTypes.shape({
      UserType: PropTypes.number,
      Id: PropTypes.number,
    }),
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isNewSiteAvailable: PropTypes.bool.isRequired,
  industriesList: PropTypes.arrayOf(IndustryType).isRequired,
  limit: PropTypes.number.isRequired,
  currentItemsNumber: PropTypes.number.isRequired,
  sitesList: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralActivator);
