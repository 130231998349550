import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const BeaconItem = ({ AdditionalSiteID, Address: { City, Country, State, Street, ZipCode }, Name }) => (
  <S.Information>
    <S.Text>
      {AdditionalSiteID.substring(0, 5)} {Name}
    </S.Text>
    {Street && <S.Text>{Street}</S.Text>}
    {(City || State || ZipCode) && (
      <S.Text>
        {City}
        {City && (State || ZipCode) ? ',' : ''} {State} {ZipCode}
      </S.Text>
    )}
    {Country && <S.Text>{Country}</S.Text>}
  </S.Information>
);

BeaconItem.propTypes = {
  AdditionalSiteID: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  Address: PropTypes.shape({
    City: PropTypes.string.isRequired,
    Country: PropTypes.string.isRequired,
    State: PropTypes.string.isRequired,
    Street: PropTypes.string.isRequired,
    ZipCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default BeaconItem;
