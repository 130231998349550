import React from 'react';
import PropTypes from 'prop-types';

import UnitItem from './UnitItem';
import UnitName from './UnitName';
import { List, UnitList } from './styled';

const UnitListTable = ({
  getItemName,
  getItemInstruction,
  getItemText,
  leftCheckbox,
  onCheck,
  onCommitUnit,
  onExportUnit,
  onUnitItemClick,
  unitColor,
  unitData,
  unitName,
  unitNameTextColor,
  renderLeftControls,
  renderRightControls,
  renderRightText,
  withCommitButton,
  withUnitControls,
  withDelete,
  withExport,
  checked,
  handleToggle,
  onDeleteUnit,
  showDetailsButton,
  goToDetailsPage,
  downloadPdf,
  customControls,
}) =>
  unitData?.length ? (
    <List>
      <UnitList>
        <UnitName
          onCommit={() => onCommitUnit(unitData[0].UnitId)}
          onExport={() => onExportUnit(unitData[0])}
          downloadPdf={downloadPdf}
          textColor={unitNameTextColor}
          unitColor={unitColor}
          unitName={unitName}
          withCommit={withCommitButton}
          withExport={withExport}
          withControls={withUnitControls}
          withDelete={withDelete}
          onDelete={() => onDeleteUnit(unitData)}
          customControls={customControls}
        />
        {unitData.map((item, index) => (
          <UnitItem
            key={index}
            index={index}
            getItemName={getItemName}
            getItemInstruction={getItemInstruction}
            getItemText={getItemText}
            islast={`${index === unitData.length - 1}`}
            item={item}
            leftCheckbox={leftCheckbox}
            onCheck={onCheck}
            checked={checked}
            onUnitItemClick={onUnitItemClick}
            goToDetailsPage={goToDetailsPage}
            renderLeftControls={renderLeftControls}
            renderRightControls={renderRightControls}
            renderRightText={renderRightText}
            handleToggle={handleToggle}
            withDelete={withDelete}
            showDetailsButton={showDetailsButton}
          />
        ))}
      </UnitList>
    </List>
  ) : null;

UnitListTable.defaultProps = {
  getItemText: () => '',
  leftCheckbox: false,
  onCheck: () => {},
  onCommitUnit: () => {},
  onDeleteUnit: () => {},
  onExportUnit: () => {},
  onUnitItemClick: null,
  goToDetailsPage: null,
  renderLeftControls: () => {},
  renderRightControls: () => null,
  renderRightText: () => null,
  unitNameTextColor: '',
  withCommitButton: false,
  withUnitControls: false,
  withExport: false,
  withDelete: false,
  checked: [],
  handleToggle: () => {},
  showDetailsButton: false,
  downloadPdf: null,
  customControls: null,
};

UnitListTable.propTypes = {
  getItemName: PropTypes.func.isRequired,
  getItemInstruction: PropTypes.func.isRequired,
  getItemText: PropTypes.func,
  leftCheckbox: PropTypes.bool,
  onCheck: PropTypes.func,
  onCommitUnit: PropTypes.func,
  onDeleteUnit: PropTypes.func,
  onExportUnit: PropTypes.func,
  onUnitItemClick: PropTypes.func,
  goToDetailsPage: PropTypes.func,
  renderLeftControls: PropTypes.func,
  renderRightControls: PropTypes.func,
  renderRightText: PropTypes.func,
  unitColor: PropTypes.string.isRequired,
  unitData: PropTypes.arrayOf(PropTypes.object).isRequired,
  unitName: PropTypes.string.isRequired,
  unitNameTextColor: PropTypes.string,
  checked: PropTypes.array,
  withCommitButton: PropTypes.bool,
  withUnitControls: PropTypes.bool,
  withDelete: PropTypes.bool,
  withExport: PropTypes.bool,
  showDetailsButton: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  downloadPdf: PropTypes.func,
  customControls: PropTypes.node,
};

export default UnitListTable;
