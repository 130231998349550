import styled from 'styled-components';
import { TextareaField } from 'components/Layout/Textarea';

export const DialogContainer = styled.div`
  padding: 40px 40px 0;
  text-align: center;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.primaryBlack}
  font-size: 18px;
  text-align: center;
  padding-bottom: 20px;
`;

export const BoldText = styled.div`
  font-weight: bold;
`;

export const FormContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;

  form {
    & div > button:first-child {
      margin-right: 10px !important;
    }

    & > div:last-child {
      padding: 20px 0 !important;
    }
  }
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const TextField = styled(TextareaField)`
  label {
    left: calc(20% + 10px) !important;
  }

  div {
    width: 60% !important;
    margin: 0 auto !important;
  }

  div > textarea {
    border: 1px solid ${({ theme }) => theme.primaryRed} !important;
    background-color: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const NotesField = styled(TextField)`
  label {
    left: calc(2% + 10px) !important;
  }
  div {
    width: 100% !important;
  }

  div > textarea {
    border: 1px solid ${({ theme }) => theme.primaryBlack} !important;
    height: 150px !important;
  }
`;

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  text-align: start;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

export const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export const RolesText = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  text-align: left;
  padding: 15px 30px 10px;
  font-weight: bold;
`;

export const RolesBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  width: 100%;
  flex-wrap: wrap;
`;

export const RolesItem = styled.div`
  position: relative;
  min-width: 30%;
  text-align: left;
  color: ${({ theme, isColorRed }) => (isColorRed ? theme.primaryRed : theme.primaryGrey)};
`;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const ConfirmationContainer = styled.div`
  & > div:last-child {
    padding: 0px !important;
    margin-top: 20px;
    & > button:first-child {
      margin-right: 5px !important;
    }

    & > button:last-child {
      margin-left: 5px !important;
    }
  }
`;
