import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow-x: auto;

  th,
  td {
    padding: 0 25px !important;
    min-width: 16vw;
  }

  & td:nth-child(-n + 1) {
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
    position: sticky;
    left: 0;
    z-index: 2;
  }

  & th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  & td:nth-child(1) {
    background-color: ${({ theme }) => theme.archiveNameColor};
  }
`;

export const EmptyList = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 220px;
`;

export const EmptyListText = styled.p`
  color: ${({ theme }) => theme.tenantNameColor};
  font-size: 16px;
`;
