import styled from 'styled-components';

export const DialogContainer = styled.form`
  min-width: 650px;
`;

export const NameContainer = styled.div`
  padding: 20px 60px;
`;

export const DescriptionCardContainer = styled.div`
  margin: 30px 40px 50px;
`;

export const DescriptionHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
`;

export const DescriptionFieldContainer = styled.div`
  padding: 40px 10px;

  border: ${({ theme }) => `1px solid ${theme.primaryGrey}`};
`;
