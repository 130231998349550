import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as tenantsActions } from 'redux/tenants';

import { EMPTY_GUID } from 'helpers';

import EditPricingDialog from 'components/Dialogs/EditPricingDialog';

import AddEditTenantDialog from './AddEditTenantDialog';
import Content from './Content';

const TenantsList = () => {
  const [addEditTenantDialogOpened, setAddEditTenantDialogOpened] = useState(false);
  const [addEditDeleteIndustryDialogOpened, setAddEditDeleteIndustryDialogOpened] = useState(false);
  const [disableIndustryField, setDisableIndustryField] = useState(false);
  const [industryToEdit, setIndustryToEdit] = useState({});
  const [newIndustryName, setNewIndustryName] = useState(false);
  const [tenantToEdit, setTenantToEdit] = useState(null);
  const [isPricingEditDialogOpen, setIsPricingEditDialogOpen] = useState(false);

  const industryList = useSelector(state => state.tenants.industryList);
  const tiersList = useSelector(state => state.tenants.tiersList);
  const error = useSelector(state => state.tenants.error);
  const dispatch = useDispatch();

  const onDeleteApprove = () => {
    closeAddEditTenantDialog();
    dispatch(tenantsActions.deleteTenantRequest(tenantToEdit.Id));
  };

  const onDeleteTenant = () => {
    dispatch(
      openConfirmationDialog(
        'Are you sure you want to permanently delete this Tenant? This cannot be undone!',
        onDeleteApprove,
        'Delete?',
      ),
    );
  };

  const onAddIndustry = name => {
    dispatch(tenantsActions.addTenantIndustryRequest({ Name: name.trim() }));
    setNewIndustryName('');
  };

  const onEditIndustry = (id, name) => {
    dispatch(
      tenantsActions.editTenantIndustryRequest({
        IndustryID: id,
        Name: name.trim(),
      }),
    );
    setDisableIndustryField(true);
  };

  const onEnableEditIndustry = industry => {
    setDisableIndustryField(industry.Id);
    setIndustryToEdit(industry);
  };

  const onDeleteIndustry = industry => {
    dispatch(
      openConfirmationDialog(
        'Are you sure you want to permanently delete this Industry? This cannot be undone!',
        () => onDeleteIndustryApprove(industry),
        'Delete?',
      ),
    );
  };

  const onDeleteIndustryApprove = industry => {
    closeAddEditTenantDialog();
    dispatch(tenantsActions.deleteTenantIndustryRequest(industry));
  };

  const handleIndustryChange = industryId => {
    const industry = industryList.find(({ IndustryID }) => IndustryID === industryId);
    if (industry) {
      dispatch(tenantsActions.fetchTiersRequest(industry.MeeteringGroupID));
    }
  };

  const handleChange = event => {
    if (event.target.name === 'Industry') {
      setIndustryToEdit({
        ...industryToEdit,
        Name: event.target.value,
      });
    } else {
      setNewIndustryName(event.target.value.trimLeft());
    }
  };

  const closeAddEditTenantDialog = () => {
    setAddEditTenantDialogOpened(false);
    setDisableIndustryField(true);
  };

  const closeAddEditDeleteIndustryDialog = () => {
    setAddEditDeleteIndustryDialogOpened(false);
    setAddEditTenantDialogOpened(true);
    setDisableIndustryField(true);
  };

  const openAddEditTenantDialog = tenant => {
    const { IndustryId } = tenant;

    if (tenant.Id) {
      setAddEditTenantDialogOpened(true);
      setTenantToEdit({ ...tenant });
      handleIndustryChange(IndustryId);
    } else {
      dispatch(tenantsActions.clearTiers());
      setAddEditTenantDialogOpened(true);
      setTenantToEdit(null);
    }
  };

  const openAddEditDeleteIndustryDialog = () => setAddEditDeleteIndustryDialogOpened(true);

  const openPricingEditDialog = currentTenant => e => {
    e.stopPropagation();
    const industry = industryList.find(({ Name }) => Name === currentTenant.IndustryName);

    if (industry) {
      dispatch(tenantsActions.clearTiers());
      dispatch(tenantsActions.fetchTiersRequest(industry.MeeteringGroupID));
    }

    setIsPricingEditDialogOpen(true);
    setTenantToEdit({ ...currentTenant });
  };

  const closeEditPricingDialog = () => setIsPricingEditDialogOpen(false);

  const onSubmit = tenant => {
    if (tenantToEdit) {
      dispatch(tenantsActions.editTenantRequest(tenant));
    } else {
      dispatch(tenantsActions.addTenantRequest(tenant));
    }

    closeAddEditTenantDialog();
  };

  const submitPricing = values => {
    if (!values.MasterTierId) {
      values.MasterTierId = EMPTY_GUID;
    }

    const TenantTierDto = {
      TenantId: tenantToEdit.Id,
      IsOverwritten: false,
      ...values,
    };

    dispatch(tenantsActions.overwritePricingValuesRequest(TenantTierDto));
    closeEditPricingDialog();
  };

  useEffect(() => {
    dispatch(tenantsActions.tenantsListRequest());
    dispatch(tenantsActions.tenantIndustryListRequest());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(openErrorDialog(error, 'Error'));
    }
  }, [dispatch, error]);

  return [
    <Content openAddEditTenantDialog={openAddEditTenantDialog} openPricingEditDialog={openPricingEditDialog} />,
    <AddEditTenantDialog
      key="Add edit tenant dialog"
      handleClose={closeAddEditTenantDialog}
      handleDelete={onDeleteTenant}
      onSubmit={onSubmit}
      open={addEditTenantDialogOpened}
      tenant={tenantToEdit || undefined}
      title={tenantToEdit ? 'Edit Tenant' : 'Add Tenant'}
      withDelete={!!tenantToEdit}
      enabledIsOEM={!tenantToEdit}
      dataIndustry={industryList}
      tiers={tiersList}
      onItemClick={openAddEditDeleteIndustryDialog}
      onCloseDialog={closeAddEditDeleteIndustryDialog}
      onIndustryChange={handleIndustryChange}
      handleAddIndustry={onAddIndustry}
      handleDeleteIndustry={onDeleteIndustry}
      handleEditIndustry={onEditIndustry}
      openIndustryDialog={addEditDeleteIndustryDialogOpened}
      enableField={disableIndustryField}
      onEnableEditIndustry={onEnableEditIndustry}
      handleChange={handleChange}
      newIndustryName={newIndustryName}
      editIndustryName={industryToEdit.Name}
    />,
    <EditPricingDialog
      title="Edit Pricing"
      open={isPricingEditDialogOpen}
      handleClose={closeEditPricingDialog}
      onSubmit={submitPricing}
      tenant={tenantToEdit || {}}
      modalStyles={{ maxWidth: '75%' }}
    />,
  ];
};

export default TenantsList;
