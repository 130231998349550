import { ForwardIcon } from 'components/Layout/Icons';
import { fixedTableHeadStyles } from 'components/TableList/styled';
import styled from 'styled-components';

const TableContainer = styled.div`
  ${fixedTableHeadStyles}

  td:nth-child(n) {
    font-size: 14px;
  }

  table {
    tbody > tr {
      height: 50px;
      border-bottom: 1px solid #ddd;
    }
  }

  thead {
    th {
      font-weight: bold;
      font-size: 14px;
      box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd !important;
    }
  }

  th:nth-child(n + 1):nth-child(-n + 4),
  td:nth-child(n + 1):nth-child(-n + 4) {
    padding: 0 24px !important;
  }
`;

const StatusRowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const Status = styled.div`
  border-right: 6px solid ${({ color }) => color};
  height: 50px;
`;

const IconForward = styled(ForwardIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

const BoldText = styled.b`
  color: ${({ theme }) => theme.primaryBlack};
`;

const EmptyList = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 220px;
`;

const EmptyListText = styled.p`
  color: ${({ theme }) => theme.tenantNameColor};
  font-size: 16px;
`;

export { TableContainer, Status, IconForward, StatusRowContainer, BoldText, EmptyList, EmptyListText };
