import { createSelector } from 'reselect';

const getSensorLevels = sensorLevels => sensorLevels;

export const getSensorLevelsListForForm = createSelector([getSensorLevels], sensorLevels => {
  let escalation = 'up';
  const escalationObj = {};

  const levels = sensorLevels.reduce((obj, level) => {
    obj[`${level.Id}LevelName`] = level.LevelName;
    obj[`${level.Id}StartData`] = level.StartData;
    obj[`${level.Id}Original`] = level.Id;
    obj[`${level.Id}OrderField`] = level.OrderField;
    obj[`${level.Id}ShouldSendAlert`] = level.ShouldSendAlert;

    if (level.LevelName === 'Normal') {
      escalationObj.Normal = level.StartData;
    } else if (level.LevelName === 'Alert') {
      escalationObj[`Alert${escalationObj.Alert ? 2 : ''}`] = level.StartData;
    }

    return obj;
  }, {});

  if (escalationObj.Alert2) {
    escalation = 'both';
  } else if (+escalationObj.Normal > +escalationObj.Alert) {
    escalation = 'down';
  }

  levels.escalation = escalation;
  return levels;
});

export const getSensorLevelsList = createSelector([getSensorLevels], sensorLevels =>
  sensorLevels.map(level => ({
    Id: level.Id,
    [`${level.Id}ShouldSendAlert`]: level.ShouldSendAlert,
    masterSensorTypeId: level.MasterSensorTypeId,
    [`${level.Id}LevelName`]: level.LevelName,
    [`${level.Id}StartData`]: level.StartData,
  })),
);

export const selector = () => {};
