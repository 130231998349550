import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { TeamType } from 'configs/propTypes';
import { actions as teamsActions } from 'redux/teams';
import { actions as peopleActions } from 'redux/people';
import Subheader from 'components/Subheader';
import SimpleList from 'components/SimpleList';
import { ButtonIcon } from 'components/Layout/Buttons';
import { BackIcon, AddIcon } from 'components/Layout/Icons';

import Item from './Item';
import AddTeamMemberDialog from './AddTeamMemberDialog';
import TeamMemberInfoDialog from './TeamMemberInfoDialog';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import * as S from './styled';

import theme from 'theme';

class Teams extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      addTeamMembersRequest: PropTypes.func.isRequired,
      getTeamMembersRequest: PropTypes.func.isRequired,
      requestPeopleList: PropTypes.func.isRequired,
      getTeamsRequest: PropTypes.func.isRequired,
      getMembersForAssigmentRequest: PropTypes.func.isRequired,
      deleteTeamMemberRequest: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.object.isRequired,
    list: PropTypes.arrayOf(TeamType).isRequired,
    members: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    membersForAssigment: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    searchData: '',
    addTeamMembersDialogOpened: false,
    teamMemberInfoDialogOpened: false,
    confirmationDeleteOpened: false,
    currentMember: null,
    userToDelete: null,
  };

  componentDidMount() {
    const { actions } = this.props;
    const { teamId } = this.props.match.params;

    actions.requestPeopleList();
    actions.getTeamsRequest();
    actions.getTeamMembersRequest({ teamId });
    actions.getMembersForAssigmentRequest({ teamId });
  }

  filterMembers = member => {
    if (typeof member.User === 'string') return null;
    const filterBy = ['User.Name', 'User.UserType', 'User.JobFunction', 'User.Status'];
    const query = this.state.searchData.toLowerCase();
    return filterBy.some(field => get(member, field)?.toLowerCase().includes(query));
  };

  backHome = () => {
    this.props.history.push('/teams');
  };

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  openAddTeamMembersDialog = () => this.setState({ addTeamMembersDialogOpened: true });
  closeAddTeamMembersDialog = () => this.setState({ addTeamMembersDialogOpened: false });

  openConfiramtionDeleteDialog = item => this.setState({ confirmationDeleteDialogOpened: true, userToDelete: item });
  closeCloseConfiramtionDeleteDialog = () =>
    this.setState({
      confirmationDeleteDialogOpened: false,
      usertToDelete: null,
    });

  openTeamMemberInfoDialog = member => {
    this.setState({ teamMemberInfoDialogOpened: true, currentMember: member });
  };

  closeTeamMemberInfoDialog = () => {
    this.setState({ teamMemberInfoDialogOpened: false, currentMember: null });
  };

  onDeleteMember = item => {
    const { actions } = this.props;
    actions.deleteTeamMemberRequest({ teamId: item.TeamID, userIds: [item.UserID] });
    this.closeCloseConfiramtionDeleteDialog();
  };

  renderRightControls = item => (
    <div>
      <ButtonIcon>
        <S.IconDelete onClick={() => this.openConfiramtionDeleteDialog(item)} />
      </ButtonIcon>
    </div>
  );

  render() {
    const { members, people, list, actions, membersForAssigment } = this.props;
    const { searchData, addTeamMembersDialogOpened, teamMemberInfoDialogOpened, currentMember } = this.state;
    const { teamId } = this.props.match.params;

    return (
      <>
        <Subheader
          title={list.length ? list.find(item => item.Id === teamId).Name : ''}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backHome,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              icon: <AddIcon />,
              handler: this.openAddTeamMembersDialog,
              hint: 'Add',
            },
          ]}
          hintText="Search"
          isSearch
          isSiteOnLeftSide
          searchData={searchData}
          searchInList={this.searchInList}
        />
        <SimpleList
          data={members.filter(this.filterMembers)}
          emptyListMessage="There are no Members available"
          renderItemContent={item => <Item item={item} people={people} onClick={this.openTeamMemberInfoDialog} />}
          renderRightControls={item => this.renderRightControls(item)}
        />
        <AddTeamMemberDialog
          onAdd={actions.addTeamMembersRequest}
          teamId={teamId}
          people={membersForAssigment}
          open={addTeamMembersDialogOpened}
          handleClose={this.closeAddTeamMembersDialog}
          title="Select Team Members"
        />
        <TeamMemberInfoDialog
          open={teamMemberInfoDialogOpened}
          handleClose={this.closeTeamMemberInfoDialog}
          currentMember={currentMember || {}}
        />
        <ConfirmationDialog
          isNext={false}
          handleClose={this.closeCloseConfiramtionDeleteDialog}
          title={'Alert'}
          titleColor={theme.redButton}
          text={'Are you sure you want to remove this member from team?'}
          label={'Remove'}
          onClick={() => this.onDeleteMember(this.state.userToDelete)}
          open={this.state.confirmationDeleteDialogOpened}
        />
      </>
    );
  }
}

const mapStateToProps = ({ teams, people }) => ({
  list: teams.list,
  members: teams.teamMembers,
  people: people.list,
  membersForAssigment: teams.membersForAssigment,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      requestPeopleList: peopleActions.peopleListRequest,
      ...teamsActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
