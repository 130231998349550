import { ButtonIcon } from 'components/Layout/Buttons';
import { BackIcon, ForwardIcon } from 'components/Layout/Icons';
import { fixedTableHeadStyles } from 'components/TableList/styled';
import styled from 'styled-components';

const TableContainer = styled.div`
  ${fixedTableHeadStyles}

  th,
  td {
    padding: 0 18px !important;
  }
`;

const Back = styled(BackIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

const EmptyList = styled.div`
  margin: 0 auto;
  font-size: 16px;
  color: ${({ theme }) => theme.tenantNameColor};
`;

const IconButton = styled(ButtonIcon).attrs({
  tooltipStyles: {
    top: '24px',
  },
})``;

const ConfirmationTextContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
`;

const BoldText = styled.b`
  font-size: 20px;
`;

const Paragraph = styled.p`
  font-size: 18px;
`;

const IconForward = styled(ForwardIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

const DataCell = styled.td<{ status: string }>`
  background: ${({ theme, status }) => (status === 'Open' ? theme.primaryRed : theme.mainGreen)};
`;

const Header = styled.th`
  background-color: ${({ theme }) => theme.lightGrey};
  font-weight: normal;
`;

const HeaderText = styled.span`
  vertical-align: super;
`;

const Item = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
`;

export {
  Back,
  TableContainer,
  EmptyList,
  ConfirmationTextContainer,
  BoldText,
  Paragraph,
  IconButton,
  IconForward,
  DataCell,
  Header,
  HeaderText,
  Item,
};
