import styled from 'styled-components';
import { LinkExternalIcon } from 'components/Layout/Icons';
import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

const RootContainer = styled.div<{ hasPaddingTop?: boolean }>`
  padding: ${({ hasPaddingTop }) => (hasPaddingTop ? '20px' : '0 20px 20px 20px')};
  text-align: center;
  min-width: 36vw;
  font-size: 1.2rem;
`;

const ConfirmationText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 1.2rem;
  margin: 10px 0;

  & > p,
  div {
    font-weight: 300;
  }
`;

const Text = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

const LinkIcon = styled(LinkExternalIcon)`
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.primaryBlack};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
`;

const LabelContainer = styled(BorderRadiusContainer)``;

const Label = styled(InputBlockLabel)`
  text-align: left;
  background: ${({ theme }) => theme.mainRed};
  padding: 1rem;
`;

const radioGroupStyle = { alignSelf: 'center', width: '50%', margin: '20px 0' };
const radioButtonStyle = { padding: '10px 0px' };
const labelStyle = { paddingLeft: '20px' };

export {
  RootContainer,
  ConfirmationText,
  Text,
  LinkIcon,
  Wrapper,
  LabelContainer,
  Label,
  radioGroupStyle,
  radioButtonStyle,
  labelStyle,
};
