import styled from 'styled-components';

export const RootContainer = styled.main`
  flex: 10;
  width: 100%;
  position: relative;
`;

export const Content = styled.div``;

export const Container = styled.div`
  max-width: 450px;
  min-width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ isActive }) => (isActive ? 1 : 0)};
`;
