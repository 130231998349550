import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import { actions as tenantsActions } from 'redux/tenants';
import { actions as listTemplatesActions } from 'redux/listTemplates';
import { checkMatchImportListRequest } from 'redux/listTemplates/handlers';
import { IndustryType } from 'configs/propTypes';

import CheckboxList from './CheckboxList';
import SiteForm from './SiteForm';
import validate from './validator';

import * as S from './styled';

class SearchListTemplate extends Component {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    currentSite: PropTypes.shape().isRequired,
    industriesList: PropTypes.arrayOf(IndustryType).isRequired,
    action: {
      requestTennantIndustryList: PropTypes.func.isRequired,
      searchListTemplateRequest: PropTypes.func.isRequired,
      getAllUnitsRequest: PropTypes.func.isRequired,
    }.isRequired,
    searchResults: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setCurrentTemplate: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    industryId: PropTypes.string.isRequired,
    tenantName: PropTypes.string.isRequired,
  };

  state = {
    members: [],
    membersForSearch: [],
    checkedTemplate: {},
    searchData: '',
    isSimilarID: false,
  };

  setCheckedTemplate = checkedTemplate => this.setState({ checkedTemplate });
  setSearchedMembers = (members, searchData) => this.setState({ members, searchData });

  onSubmitForm = ({ values }) => {
    const filter = {
      IndustryId: values.IndustryId,
      displayOption: values.SelectionLists,
    };
    this.props.action.searchListTemplateRequest({ filter });
  };

  onNextClick = () => {
    if (this.state.checkedTemplate.Name) {
      this.setState({ isErrorShown: false });
      this.props.action.checkMatchImportListRequest({
        siteId: this.props.currentSite.id,
        masterUnit: this.state.checkedTemplate,
        onNext: this.props.onNext,
      });
    } else {
      this.setState({ isErrorShown: true });
    }
  };

  componentDidMount() {
    this.props.action.requestTennantIndustryList();
    this.props.action.getAllUnitsRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchResults } = this.props;
    const { searchData } = this.state;
    if (searchResults && searchResults.length > 0 && prevState.members.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        members: searchResults,
        membersForSearch: searchResults,
      });
      // fixes material-ui dialog height CU610-2191
      window.dispatchEvent(new Event('resize'));
    }

    if (prevProps.searchResults !== searchResults) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        members: searchResults,
        membersForSearch: searchResults,
      });
      // fixes material-ui dialog height CU610-2191
      window.dispatchEvent(new Event('resize'));
    }
  }

  render() {
    const { currentSite, industriesList, searchResults, setCurrentTemplate, limit, industryId, tenantName } =
      this.props;
    const { members, membersForSearch, searchData, checkedTemplate } = this.state;

    return (
      <S.Container>
        <S.UpperInfo>
          <S.BoldText>Results: </S.BoldText>
          <S.SubInfo>Lists with</S.SubInfo>
          <S.LimitCount>{limit}</S.LimitCount>
          <S.SubInfo>
            max<S.BoldText> items </S.BoldText>each
          </S.SubInfo>
        </S.UpperInfo>
        <S.TopInfo>
          <S.Title>
            {currentSite.additionalId.length > 0
              ? `${currentSite.additionalId.substring(0, 5)} ${currentSite.name}`
              : currentSite.name}
          </S.Title>
        </S.TopInfo>

        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SiteForm
                industriesList={industriesList}
                industryId={industryId}
                values={values}
                tenantName={tenantName}
                onSubmit={this.onSubmitForm}
              />
            </form>
          )}
        />
        <S.Block>
          <S.Label>Search Results</S.Label>
          <S.SearchContainer>
            <SearchField
              list={membersForSearch.length > 0 || searchData.length > 0 ? membersForSearch : searchResults}
              filterBy={['Name']}
              searchData={searchData}
              placeholder="Filter Lists"
              setSearchedItems={this.setSearchedMembers}
            />
          </S.SearchContainer>
          <S.ListContainer>
            <CheckboxList
              list={members.length > 0 || searchData.length > 0 ? members : searchResults}
              checkedTemplate={checkedTemplate}
              setCheckedTemplate={this.setCheckedTemplate}
              setCurrentTemplate={setCurrentTemplate}
            />
          </S.ListContainer>
        </S.Block>
        {this.state.isErrorShown && <S.ErrorContainer>Please select Unit</S.ErrorContainer>}
        <ActionsContainer paddingValue={14}>
          <ActionButton label="Next" isNext onClick={this.onNextClick} />
        </ActionsContainer>
      </S.Container>
    );
  }
}

const mapStateToProps = ({
  auth: {
    user: { industryId, tenantName },
  },
  tenants,
  listTemplates,
}) => ({
  industryId,
  tenantName,
  checkMatch: listTemplates.checkMatch,
  industriesList: tenants.industryList,
  searchResults: listTemplates.searchResults.filter(item => item.Approved),
  limit: listTemplates.limits.MaxItemsNumber,
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      checkMatchImportListRequest,
      requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
      ...listTemplatesActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchListTemplate);
