import React from 'react';
import PropTypes from 'prop-types';

import withDrawer from 'components/Dialogs/Drawer';

import DndList from 'components/DndList';

import * as S from './styled';

const DragItemsListForm = ({ data, onSubmit, listId, isNewSaveButton }) => [
  <DndList
    key="TutorialsList"
    data={data}
    name="DndList"
    listId={listId}
    onSave={onSubmit}
    isNewSaveButton={isNewSaveButton}
  />,
];

const DragItemsListWithInput = withDrawer(DragItemsListForm, 22);

const DragItemsListDialog = ({ data, title, ...rest }) => (
  <DragItemsListWithInput title={<S.Title>{title}</S.Title>} data={data} {...rest} />
);

DragItemsListDialog.defaultProps = {
  handleDelete: () => {},
  withDelete: false,
  withSubmit: true,
  title: 'Sequence Setup (drag to order)',
  isNewSaveButton: false,
};

DragItemsListDialog.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          sequence: PropTypes.number.isRequired,
        }),
      ),
    }),
  ).isRequired,
  title: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  withDelete: PropTypes.bool,
  withSubmit: PropTypes.bool,
  isNewSaveButton: PropTypes.bool,
};

export default DragItemsListDialog;
