import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import theme from 'theme';
import * as S from './styled';

const WarningDialog = ({
  open,
  handleClose,
  title,
  label,
  labelCancel,
  isNext,
  isDisable,
  titleColor,
  onClick,
  onCancelClick,
  text,
  width,
  isColumn,
  withBorder,
  maxWidth,
  headingText,
  descriptionText,
  items,
  handleJobReplace,
  jobToReplace,
  disabled,
}) => (
  <Dialog
    open={open}
    onRequestClose={handleClose}
    title={title}
    titleColor={titleColor}
    width={width}
    maxWidth={maxWidth}
  >
    <S.DialogContainer>
      {text}
      {headingText ? <S.ConfirmationText>{headingText}</S.ConfirmationText> : null}
      {descriptionText ? <S.ConfirmationWarning>{descriptionText}</S.ConfirmationWarning> : null}
      {items && (
        <S.Select
          floatingLabelText="Function Selection"
          value={jobToReplace}
          onChange={(e, i, value) => handleJobReplace(value)}
          style={{ width: 350, textAlign: 'left' }}
        >
          {!!items.length && items.map(item => <S.Item key={item.Id} value={item} primaryText={item.Name} />)}
        </S.Select>
      )}
    </S.DialogContainer>
    <ActionsContainer>
      <S.Container isColumn={isColumn}>
        {onCancelClick && (
          <ActionButton
            withBorder={withBorder}
            label={labelCancel}
            onClick={onCancelClick}
            isNext={isNext}
            isDisable={isDisable}
            disabled={disabled}
          />
        )}
        {onClick && <ActionButton label={label} onClick={onClick} disable isNext={isNext} />}
      </S.Container>
    </ActionsContainer>
  </Dialog>
);

WarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  label: PropTypes.string,
  labelCancel: PropTypes.string,
  isNext: PropTypes.bool.isRequired,
  titleColor: PropTypes.string,
  width: PropTypes.string,
  text: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func,
  isColumn: PropTypes.bool,
  withBorder: PropTypes.bool,
  maxWidth: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleJobReplace: PropTypes.func.isRequired,
  jobToReplace: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  isDisable: PropTypes.bool,
};

WarningDialog.defaultProps = {
  titleColor: theme.mainRed,
  title: 'Alert',
  label: 'Yes',
  labelCancel: 'Previous',
  headingText: '',
  descriptionText: '',
  onCancelClick: null,
  width: '30%',
  isColumn: false,
  withBorder: false,
  maxWidth: '350px',
  disabled: false,
  isDisable: false,
};

export default WarningDialog;
