import styled from 'styled-components';

import { Select } from '../../styled';

export const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 50px;
  margin-top: 50px;
  background-color: ${({ theme }) => theme.signUpColor};
  border-radius: 15px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.primaryWhite};
  text-shadow: ${({ theme }) => `-2px 2px 2px ${theme.primaryBlack}`};
  font-size: 24px;
  font-weight: 300;
`;

export const GreetingTitle = styled.h2`
  color: ${({ theme }) => theme.primaryWhite};
  text-shadow: ${({ theme }) => `-2px 2px 2px ${theme.primaryBlack}`};
  font-size: 20px;
  font-weight: bold;
`;

export const GreetingMessage = styled.p`
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 300px;
  font-size: 14px;
  margin-bottom: 40px;
  text-align: center;
`;

export const FormContainer = styled.form``;

export const Label = styled.label`
  color: ${({ theme }) => theme.primaryWhite};
`;

export const SelectField = styled(Select)`
  border-radius: 10px;
  margin: 7px 0 15px;
`;

export const TextInput = styled.input`
  width: fill-available;
  padding: 10px 40px 10px 10px;
  margin: 7px 0 30px;
  border-radius: 10px;
  border: ${({ theme, meta }) => `1px solid ${meta.error && meta.touched ? theme.primaryRed : theme.primaryGrey}`};
  background: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  font-weight: 500;
  &::placeholder {
    color: ${({ theme, meta }) => (meta.error && meta.touched ? theme.primaryRed : theme.primaryGrey)};
    font-size: 16px;
    font-weight: 500;
  }
`;
