import styled from 'styled-components';
import { ButtonFlat } from 'components/Layout/Buttons';

const List = styled.ul`
  padding-inline-start: 0px;
  color: ${({ theme }) => theme.primaryBlack};
  margin-block-start: 0;
  padding: 0 1rem;
`;

const ListItem = styled.li`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 18px;
`;

const Text = styled.div`
  flex: 1;
  text-align: start;
  padding: 0;
  padding-left: 30px;
  font-size: 16px;
`;

const Quantity = styled.div<{ isRedBack?: boolean }>`
  font-size: 22px;
  border: 1.8px solid ${({ theme }) => theme.primaryGrey};
  color: ${({ theme }) => theme.mainRed};
  min-width: 70px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background: ${({ theme, isRedBack }) => (isRedBack ? theme.secondaryRed : theme.primaryWhite)};
`;

const EditButton = styled(ButtonFlat)`
  width: auto !important;
  background-color: ${({ theme }) => theme.lightGreenButton} !important;
  border-radius: 6px !important;

  span {
    color: ${({ theme }) => theme.greenButton} !important;
  }
`;

export { ListItem, List, Text, Quantity, EditButton };
