import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  appendHub,
  appendServer,
  editMasterGateway,
  fetchDeviceConnectionString,
  fetchGateway,
  fetchGateways,
  fetchGatewaysByHub,
  fetchHubTierInfo,
  fetchIotHubs,
  fetchNewGatewayNameInHub,
  fetchNewHubNameBySite,
  fetchNewResourceGroupNameByTenant,
  fetchRegions,
  fetchResourceGroupInfo,
  fetchResourceGroups,
  fetchServerData,
  getTenantHubsList,
  makeGateway,
  removeAllHubsFromTenant,
  removeGateway,
  removeHub,
  removeServer,
  removeTenantHubs,
  removeTenantSiteHub,
} from 'http/gateways';
import IOT_HUBS from 'configs/iotHubs';
import { formatServerError, stringComparator } from 'helpers';

import { actions } from './index';

function* getGateways({ payload: { isMaster } }) {
  try {
    const { data } = yield call(fetchGateways, isMaster);
    yield put(actions.getGatewaysSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getGatewaysFailure(formatServerError(error, 'An error occurred while getting the Gateways list.')),
    );
  }
}

function* getGatewaysByHub({ payload }) {
  try {
    yield put(actions.resetField({ fieldName: 'list', value: [] }));
    const { data } = yield call(fetchGatewaysByHub, payload);
    const sortedGateways = data.root.sort((a, b) => stringComparator(a.DeviceId, b.DeviceId, true));
    yield put(actions.getGatewaysSuccess(sortedGateways));
  } catch (error) {
    yield put(
      actions.getGatewaysFailure(formatServerError(error, 'An error occurred while getting the Gateways list.')),
    );
  }
}

function* getServerData({ payload }) {
  try {
    const { data } = yield call(fetchServerData, payload);
    yield put(actions.getServerDataSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getServerDataFailure(
        formatServerError(error, 'An error occurred while getting the Resource Groups list.'),
      ),
    );
  }
}

function* getIotHubs({ payload }) {
  try {
    const { data } = yield call(fetchIotHubs, payload);
    yield put(actions.getIotHubsSuccess(data.root));
  } catch (error) {
    yield put(actions.getIotHubsFailure(formatServerError(error, 'An error occurred while getting the IotHubs list.')));
  }
}

function* getRegions() {
  try {
    const { data } = yield call(fetchRegions);
    yield put(actions.getRegionsSuccess(data));
  } catch (error) {
    yield put(actions.getRegionsFailure(formatServerError(error, 'An error occurred while getting the Regions list.')));
  }
}

function* addServer({ payload: { region, ...params } }) {
  try {
    yield call(appendServer, params);
    yield put(actions.addServerSuccess());
    yield put(actions.getServerDataRequest(region));
  } catch (error) {
    yield put(
      actions.addServerFailure(formatServerError(error, 'An error occurred while creating the Resource Group.')),
    );
  }
}

function* addHub({ payload }) {
  try {
    yield call(appendHub, payload);
    yield put(actions.addHubSuccess());
    yield put(actions.getIotHubsRequest(payload.ResourceGroupName));
  } catch (error) {
    yield put(actions.addHubFailure(formatServerError(error, 'An error occurred while creating the Hub.')));
  }
}
function* deleteServer({ payload: { groupName, region } }) {
  try {
    yield call(removeServer, groupName);
    yield put(actions.deleteServerSuccess());
    if (region) {
      yield put(actions.getServerDataRequest(region));
    }
    yield all([put(actions.getGatewaysRequest({ isMaster: true })), put(actions.getResourceGroupsRequest())]);
  } catch (error) {
    yield put(
      actions.deleteServerFailure(formatServerError(error, 'An error occurred while deleting the Resource Group.')),
    );
  }
}

function* deleteHub({ payload }) {
  try {
    yield call(removeHub, payload);
    yield put(actions.deleteHubSuccess());
    yield all([
      put(actions.getIotHubsRequest(payload.resourceGroupName)),
      put(actions.getGatewaysRequest({ isMaster: true })),
    ]);
  } catch (error) {
    yield put(actions.deleteHubFailure(formatServerError(error, 'An error occurred while deleting the IotHub.')));
  }
}

function* createGateway({ payload }) {
  try {
    yield call(makeGateway, payload);
    yield put(actions.createGatewaySuccess());
    yield put(actions.getGatewaysRequest({ isMaster: true }));
  } catch (error) {
    yield put(actions.createGatewayFailure(formatServerError(error, 'An error occurred while creating the Gateway.')));
  }
}

function* getGateway({ payload }) {
  try {
    const { data } = yield call(fetchGateway, payload);
    yield put(actions.getGatewaySuccess(data.entity));
  } catch (error) {
    yield put(actions.getGatewayFailure(formatServerError(error, 'An error occurred while getting the Gateway.')));
  }
}

function* editGateway({ payload: { isMaster, values } }) {
  try {
    yield call(editMasterGateway, isMaster, values);
    yield put(actions.editGatewaySuccess());
    yield put(actions.getGatewaysRequest({ isMaster }));
  } catch (error) {
    yield put(actions.editGatewayFailure(formatServerError(error, 'An error occurred while editing the Gateway.')));
  }
}

function* deleteGateway({ payload }) {
  try {
    yield call(removeGateway, payload);
    yield put(actions.deleteGatewaySuccess());
    yield put(actions.getGatewaysRequest({ isMaster: true }));
  } catch (error) {
    yield put(actions.deleteGatewayFailure(formatServerError(error, 'An error occurred while deleting the Gateway.')));
  }
}

function* getResourceGroups() {
  try {
    const { data } = yield call(fetchResourceGroups);
    yield put(actions.getResourceGroupsSuccess(data.root));
  } catch (error) {
    yield put(actions.getResourceGroupsFailure(error));
  }
}

function* deleteAllHubsFromTenant({ payload }) {
  try {
    yield call(removeAllHubsFromTenant, payload);
    yield put(actions.getResourceGroupsRequest());
    yield put(actions.deleteAllHubsFromTenantSuccess());
  } catch (error) {
    yield put(actions.deleteAllHubsFromTenantFailure(error));
  }
}

function* fetchTenantHubsList({ payload }) {
  const { tenantId, siteId } = payload;
  try {
    const { data } = yield call(getTenantHubsList, tenantId);
    if (siteId) {
      const site = data.root.find(({ Id }) => Id === siteId);
      yield site ? put(actions.getTenantHubsListSuccess(site.Hubs)) : put(actions.getTenantHubsListSuccess([]));
    } else {
      yield put(actions.getTenantHubsListSuccess(data.root));
    }
  } catch (error) {
    yield put(actions.getTenantHubsListFailure(error));
  }
}

function* deleteTenantHubs({ payload }) {
  try {
    yield call(removeTenantHubs, payload);
    yield put(actions.getTenantHubsListRequest({ tenantId: payload.tenantId }));
    yield put(actions.deleteAllHubsFromTenantSuccess());
  } catch (error) {
    yield put(actions.deleteAllHubsFromTenantFailure(error));
  }
}

function* deleteTenantSiteHub({ payload }) {
  try {
    yield call(removeTenantSiteHub, payload);
    yield put(actions.getTenantHubsListRequest({ tenantId: payload.tenantId }));
    yield put(actions.deleteHubFromTenantSiteSuccess());
  } catch (error) {
    yield put(actions.deleteHubFromTenantSiteFailure(error));
  }
}

function* getNewResourceGroupNameByTenant({ payload }) {
  const { tenantId, region, changeFormField, changeState } = payload;
  try {
    const { data } = yield call(fetchNewResourceGroupNameByTenant, tenantId);

    if (changeFormField) {
      const resourceGroupName = data.entity.Name;
      changeFormField('ResourceGroupName', resourceGroupName);

      if (!data.entity.IsUnique) {
        const { data: info } = yield call(fetchResourceGroupInfo, resourceGroupName);
        changeState('resourceGroupRegion', info.entity.Region);
      } else {
        yield put(actions.addServerRequest({ name: data.entity.Name, region, tenantId }));
      }
    }

    if (changeState) {
      changeState('isResourceGroupUnique', data.entity.IsUnique);
    }

    yield put(actions.getNewResourceNameSuccess(data.entity));
  } catch (error) {
    yield put(actions.getNewResourceNameFailure(error));
  }
}

function* getNewHubNameBySite({ payload }) {
  const { tenantId, siteId, changeFormField, hubData } = payload;
  try {
    const { data } = yield call(fetchNewHubNameBySite, tenantId, siteId);
    yield put(actions.getNewResourceNameSuccess(data.entity));
    if (changeFormField) {
      changeFormField('IotHubName', data.entity.Name);
      yield put(
        actions.getNewGatewayNameInHubRequest({
          hubData: { ...hubData, name: data.entity.Name },
          gatewayData: { tenantId, siteId, iotHubName: data.entity.Name },
          changeFormField,
        }),
      );
    }
  } catch (error) {
    yield put(actions.getNewResourceNameFailure(error));
  }
}

function* getNewGatewayNameInHub({ payload }) {
  const { hubData, gatewayData, changeFormField } = payload;
  try {
    yield put(actions.resetField({ fieldName: 'newResource', value: {} }));
    if (hubData) {
      yield call(appendHub, hubData);
    }
    const { data } = yield call(fetchNewGatewayNameInHub, gatewayData);
    if (changeFormField) {
      changeFormField('DeviceId', data.entity.Name);
    }
    yield put(actions.getNewResourceNameSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.getNewResourceNameFailure(formatServerError(error, 'You can have only one hub within a free tier.')),
    );
  }
}

function* getDeviceConnectionString({ payload }) {
  const { resourceGroupName, iotHubName, deviceId, cb } = payload;
  const params = { resourceGroupName, iotHubName, deviceId };
  try {
    const { data } = yield call(fetchDeviceConnectionString, params);

    cb(data.entity);
    yield put(actions.getDeviceConnectionStringSuccess());
  } catch (error) {
    yield put(
      actions.getDeviceConnectionStringFailure(
        formatServerError(error, 'An error occurred while getting a connection string'),
      ),
    );
  }
}

function* getResourceGroupInfo({ payload }) {
  const { ResourceGroupName, cb } = payload;
  try {
    const { data } = yield call(fetchResourceGroupInfo, ResourceGroupName);
    if (cb) {
      cb(data.entity.Region);
    }
    yield put(actions.getResourceGroupInfoSuccess());
  } catch (error) {
    yield put(
      actions.getResourceGroupInfoFailure(
        formatServerError(error, 'An error occurred while getting a Resource Group info'),
      ),
    );
  }
}

function* getHubTierInfo({ payload }) {
  const { iotHubName, resourceGroupName, cb } = payload;
  try {
    const { data } = yield call(fetchHubTierInfo, iotHubName, resourceGroupName);
    if (cb) {
      const { skuName, skuTier } = IOT_HUBS[data.entity.SKU.SKUName];
      cb({ SKUName: skuName, SKUTier: skuTier, Capacity: data.entity.SKU.Capacity });
    }
    yield put(actions.getHubTierInfoSuccess());
  } catch (error) {
    yield put(
      actions.getHubTierInfoFailure(formatServerError(error, 'An error occurred while getting a Hub tier info')),
    );
  }
}

const gatewaysSagas = [
  takeEvery(actions.getGatewaysRequest, getGateways),
  takeEvery(actions.getGatewaysByHubRequest, getGatewaysByHub),
  takeEvery(actions.getServerDataRequest, getServerData),
  takeEvery(actions.getIotHubsRequest, getIotHubs),
  takeEvery(actions.getRegionsRequest, getRegions),
  takeEvery(actions.addServerRequest, addServer),
  takeEvery(actions.addHubRequest, addHub),
  takeEvery(actions.deleteServerRequest, deleteServer),
  takeEvery(actions.deleteHubRequest, deleteHub),
  takeEvery(actions.createGatewayRequest, createGateway),
  takeEvery(actions.getGatewayRequest, getGateway),
  takeEvery(actions.editGatewayRequest, editGateway),
  takeEvery(actions.deleteGatewayRequest, deleteGateway),
  takeEvery(actions.getResourceGroupsRequest, getResourceGroups),
  takeEvery(actions.deleteAllHubsFromTenantRequest, deleteAllHubsFromTenant),
  takeEvery(actions.getTenantHubsListRequest, fetchTenantHubsList),
  takeEvery(actions.deleteAllHubsFromTenantSiteRequest, deleteTenantHubs),
  takeEvery(actions.deleteHubFromTenantSiteRequest, deleteTenantSiteHub),
  takeEvery(actions.getNewResourceGroupNameByTenantRequest, getNewResourceGroupNameByTenant),
  takeEvery(actions.getNewHubNameBySiteRequest, getNewHubNameBySite),
  takeEvery(actions.getNewGatewayNameInHubRequest, getNewGatewayNameInHub),
  takeEvery(actions.getDeviceConnectionStringRequest, getDeviceConnectionString),
  takeEvery(actions.getResourceGroupInfoRequest, getResourceGroupInfo),
  takeEvery(actions.getHubTierInfoRequest, getHubTierInfo),
];

export default gatewaysSagas;
