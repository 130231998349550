import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { SensorLevelType } from 'configs/propTypes';

import { NotificationOff, NotificationOn } from 'components/Layout/Icons';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';

import * as S from './styled';

const LevelsList = ({ isBoth, isSettings, levelsList }) => (
  <S.Container>
    {levelsList.map(level => {
      const levelName = level[`${level.Id}LevelName`];

      return (
        <S.Level key={level.Id}>
          <S.LevelLabel name={levelName} isSettings={isSettings}>
            {levelName}
          </S.LevelLabel>
          <S.Parameters>
            {(!isBoth || levelName !== 'Normal') && (
              <>
                <Field
                  type="number"
                  component={S.TextField}
                  name={`${level.Id}StartData`}
                  floatingLabelText="low"
                  isDecimal
                />
                {levelName !== 'Normal' && (
                  <S.AlertCheckboxField
                    component={CheckboxAdapter}
                    name={`${level.Id}ShouldSendAlert`}
                    checkedIcon={<NotificationOn />}
                    uncheckedIcon={<NotificationOff />}
                  />
                )}
              </>
            )}
          </S.Parameters>
        </S.Level>
      );
    })}
  </S.Container>
);

LevelsList.propTypes = {
  isBoth: PropTypes.bool.isRequired,
  isSettings: PropTypes.bool.isRequired,
  levelsList: PropTypes.arrayOf(SensorLevelType).isRequired,
};

export default LevelsList;
