import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ButtonIcon } from 'components/Layout/Buttons';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import PopoverMenu from 'components/PopoverMenu';

import { BackIcon, AddIcon, PauseIcon, PlayIcon, TenantsModuleIcon } from 'components/Layout/Icons';

import { actions as authActions } from 'redux/auth';
import { actions as tenantsActions } from 'redux/tenants';

import Item from '../Item';

import * as S from './styled';

const Content = ({ history, openAddEditTenantDialog, openPricingEditDialog }) => {
  const [searchData, setSearchData] = useState('');
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const tenantsList = useSelector(state => state.tenants.list);
  const usersList = useSelector(state => state.tenants.loginAsUsers);
  const isLocalLoading = useSelector(state => state.tenants.isLocalLoading);
  const dispatch = useDispatch();

  const searchInList = e => setSearchData(e.target.value);

  const goBack = () => history.push('/home');

  const filterTenantsList = ({ Name }) => Name.toLowerCase().includes(searchData.toLowerCase());

  const leftButtons = [
    {
      icon: <BackIcon />,
      handler: goBack,
      hint: 'Back',
    },
  ];

  const rightButtons = [
    {
      icon: <AddIcon />,
      handler: openAddEditTenantDialog,
      hint: 'Add',
    },
  ];

  const loginAsUser = useCallback(
    (selectedUser, tenant) => {
      dispatch(authActions.usersLoginasRequest({ user: selectedUser, tenant }));
    },
    [dispatch],
  );

  const getMenuItemStyle = useCallback(({ UserType: userType }) => {
    switch (userType) {
      case 'SystemAdministrator':
      case 'OEMAdministrator':
        return S.adminStyle;
      case 'Manager':
      case 'OEMManager':
        return S.managerStyle;
      case 'TaskSpecialist':
      case 'DashboardUser':
        return {};
      default:
        return {};
    }
  }, []);

  const handleClose = () => {
    setSelectedTenantId('');
    setIsPopoverVisible(false);
    setAnchorEl(null);
  };

  const handleOpen = (event, id) => {
    setIsPopoverVisible(true);
    setAnchorEl(event.currentTarget);
    setSelectedTenantId(id);
    dispatch(tenantsActions.usersLoginasListRequest(id));
  };

  const renderRightControls = tenant => {
    const onItemClick = selectedUser => {
      loginAsUser(selectedUser, tenant);
      handleClose();
    };

    const onStatusIconClick = () => {
      if (tenant.Status === 'Deactivated') {
        dispatch(tenantsActions.activateTenantRequest(tenant.Id));
      } else {
        dispatch(tenantsActions.deactivateTenantRequest(tenant.Id));
      }
    };

    const statusIconTooltip = tenant.Status === 'Active' ? 'Block tenant' : 'Unlock tenant';

    return (
      <S.ControlsBlock>
        <div>
          <S.Button
            icon={<S.GroupIconColored />}
            label="login as"
            labelPosition="before"
            onClick={e => handleOpen(e, tenant.Id)}
          />
          {selectedTenantId === tenant.Id && (
            <PopoverMenu
              menuTitle="Login As"
              keyName="Name"
              emptyText="No available users"
              data={usersList}
              getMenuItemStyle={getMenuItemStyle}
              menuStyle={S.menuStyle}
              onItemClick={onItemClick}
              isLoading={isLocalLoading}
              handleClose={handleClose}
              anchorEl={anchorEl}
              isOpen={isPopoverVisible}
            />
          )}
        </div>
        <ButtonIcon onClick={onStatusIconClick} tooltip={statusIconTooltip}>
          {tenant.Status === 'Active' ? <PauseIcon /> : <PlayIcon />}
        </ButtonIcon>
      </S.ControlsBlock>
    );
  };

  return (
    <>
      <Subheader
        key="Tenants list subheader"
        leftButtons={leftButtons}
        rightButtons={rightButtons}
        title="Tenants"
        subTitleIcon={<TenantsModuleIcon />}
        isSearch
        searchData={searchData}
        searchInList={searchInList}
      />
      <SimpleList
        key="Tenants"
        data={tenantsList.filter(filterTenantsList)}
        emptyListMessage="There are no tenants avaliable"
        onItemClick={openAddEditTenantDialog}
        renderItemContent={Item(openPricingEditDialog)}
        renderRightControls={renderRightControls}
      />
    </>
  );
};

Content.propTypes = {
  openAddEditTenantDialog: PropTypes.func.isRequired,
  openPricingEditDialog: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Content);
