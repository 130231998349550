import styled from 'styled-components';

export const DialogContainer = styled.div`
  text-align: center;

  & > div:last-child {
    padding: 20px 0 !important;
  }
`;

export const TopInfo = styled.div``;

export const Text = styled.p`
  margin: 30px 0;
  font-size: 20px;
  font-weight: 300;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const BoldText = styled.b`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 20px;
`;
