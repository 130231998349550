import styled from 'styled-components';

export const IconStyle = {
  color: '#FFF',
  height: '48px',
  width: '48px',
};

export const Article = styled.article`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px 26px;
`;

export const Section = styled.section`
  border: 1.6px solid ${({ module, theme }) => (module === 'Health' ? theme.textGray : theme.primaryWhite)};
  border-radius: 8px;
  max-width: 295px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 52px);
`;

export const Header = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const Title = styled.h2`
  font-weight: lighter;
  color: ${({ module, theme }) => (module === 'Health' ? theme.textGray : theme.primaryWhite)};
  margin-block-start: unset;
`;

export const VideoHeader = styled.p`
  font-size: 22px;
  margin-block-end: 10px;

  &:not(:first-child) {
    margin-block-start: 30px;
  }
`;

export const Subtitle = styled.p`
  font-size: 16px;
  margin-block-start: unset;
  margin-block-end: 20px;
`;

export const Main = styled.main`
  font-weight: lighter;
  color: ${({ module, theme }) => (module === 'Health' ? theme.textGray : theme.primaryWhite)};
  overflow-y: auto;
  margin-bottom: 60px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SectionTitle = styled.p`
  text-align: start;
  word-break: break-word;
`;

export const SectionContent = styled.div`
  padding: 0 20px;
`;
