import React from 'react';
import { PropTypes } from 'prop-types';
import * as S from './styled';

const propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

const defaultProps = {
  onClick: undefined,
};

const PrimaryIconButton = ({ onClick, label, icon }) => (
  <S.IconButton onClick={onClick}>
    {icon}
    <S.ButtonCaption>{label}</S.ButtonCaption>
  </S.IconButton>
);

const SecondaryIconButton = ({ onClick, label, icon }) => (
  <S.SecondButton onClick={onClick}>
    {icon}
    <S.ButtonCaption>{label}</S.ButtonCaption>
  </S.SecondButton>
);

const SecondaryLinkButton = ({ path, ...props }) => (
  <S.RouterLink to={path}>
    <SecondaryIconButton {...props} />
  </S.RouterLink>
);

const PrimaryLinkButton = ({ path, ...props }) => (
  <S.RouterLink to={path}>
    <PrimaryIconButton {...props} />
  </S.RouterLink>
);

const ListItem = ({ key, name, content, zIndex }) => (
  <S.ListItem key={key} zIndex={zIndex}>
    <S.ItemTitle type={name}>{name}</S.ItemTitle>
    {content}
  </S.ListItem>
);

PrimaryIconButton.propTypes = propTypes;
SecondaryIconButton.propTypes = propTypes;
PrimaryIconButton.defaultProps = defaultProps;
SecondaryIconButton.defaultProps = defaultProps;
SecondaryLinkButton.propTypes = { ...propTypes, path: PropTypes.string.isRequired };
PrimaryLinkButton.propTypes = { ...propTypes, path: PropTypes.string.isRequired };

ListItem.propTypes = {
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  zIndex: PropTypes.number.isRequired,
};

export { PrimaryIconButton, SecondaryIconButton, SecondaryLinkButton, PrimaryLinkButton, ListItem };
