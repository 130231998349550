import { transformSystemLinksList } from './helpers';

const initialState = {
  error: '',
  isUpdated: false,
  loading: false,
  loadingEmailLogo: false,
  loadingPDFLogo: false,
  deleteLoading: false,
  list: {
    ExistsLogoFromTenant: false,
    ExistsLogoEmailFromTenant: false,
  },
  logo: {
    email: false,
    pdf: false,
  },
  masterSettings: {},
  signUpNotification: {},
  systemLimits: {},
  systemLinks: [],
  masterSystemLinks: [],
  qr: 'UniqueID',
  tenantSettingsList: [],
};

export const changeScheduleTrigger = (state, { payload }) => ({
  ...state,
  list: {
    ...state.list,
    [payload.key]: payload.value,
  },
});

export const uploadLogoRequest = state => ({
  ...state,
  list: {
    ...state.list,
    ExistsLogoFromTenant: false,
  },
  error: '',
  loading: true,
  loadingPDFLogo: true,
});

export const uploadLogoSuccess = state => ({
  ...state,
  loading: false,
});

export const uploadLogoFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
  loadingPDFLogo: false,
});

export const getLogoRequest = state => ({
  ...state,
  logo: {
    ...state.logo,
    pdf: false,
  },
  error: '',
  loading: true,
  loadingPDFLogo: true,
});

export const getLogoSuccess = state => ({
  ...state,
  logo: {
    ...state.logo,
    pdf: true,
  },
  loading: false,
  loadingPDFLogo: true,
});

export const getLogoFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
  loadingPDFLogo: false,
});

export const getLogoMasterSuccess = state => ({
  ...state,
  logo: {
    ...state.logo,
    pdf: true,
  },
  loading: false,
  loadingPDFLogo: false,
});

export const deleteLogoRequest = state => ({
  ...state,
  error: '',
  loading: true,
  loadingPDFLogo: true,
});

export const deleteLogoSuccess = state => ({
  ...state,
  logo: {
    ...state.logo,
    pdf: false,
  },
  loading: false,
});

export const deleteLogoFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
  loadingPDFLogo: false,
});

export const uploadLogoEmailRequest = state => ({
  ...state,
  list: {
    ...state.list,
    ExistsLogoEmailFromTenant: false,
  },
  error: '',
  loading: true,
  loadingEmailLogo: true,
});

export const uploadLogoEmailSuccess = state => ({
  ...state,
  loading: false,
});

export const uploadLogoEmailFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
  loadingEmailLogo: false,
});

export const getLogoEmailRequest = state => ({
  ...state,
  logo: {
    ...state.logo,
    email: false,
  },
  error: '',
  loading: true,
  loadingEmailLogo: true,
});

export const getLogoEmailSuccess = state => ({
  ...state,
  logo: {
    ...state.logo,
    email: true,
  },
  loading: false,
});

export const getLogoEmailFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
  loadingEmailLogo: false,
});

export const getLogoEmailMasterSuccess = state => ({
  ...state,
  logo: {
    ...state.logo,
    email: true,
  },
  loading: false,
  loadingEmailLogo: false,
});

export const deleteLogoEmailRequest = state => ({
  ...state,
  error: '',
  loading: true,
  loadingEmailLogo: true,
});

export const deleteLogoEmailSuccess = state => ({
  ...state,
  logo: {
    ...state.logo,
    email: false,
  },
  loading: false,
});

export const deleteLogoEmailFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
  loadingEmailLogo: false,
});

export const setSettingsRequest = state => ({
  ...state,
  isUpdated: false,
  error: '',
  loading: true,
});

export const setSettingsSuccess = (state, { payload }) => ({
  ...state,
  isUpdated: true,
  list: payload,
  loading: false,
});
export const setSettingsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const setSettingsLinksRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const setSettingsLinksSuccess = (state, { payload }) => transformSystemLinksList(state, payload);

export const setSettingsLinksFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  masterSystemLinks: [],
  loading: false,
});

export const getSettingsLinksListSuccess = (state, { payload }) => ({
  ...state,
  systemLinks: payload,
  loading: false,
});

export const getMasterSettingsLinksForAdminRequest = state => ({
  ...state,
  loading: true,
});

export const getMasterSettingsLinksForAdminSuccess = (state, { payload }) => ({
  ...state,
  masterSystemLinks: payload,
  loading: false,
});

export const getMasterSettingsLinksForAdminFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const getSettingsRequest = state => ({
  ...state,
  error: '',
  isUpdated: false,
  loading: true,
});

export const getSettingsSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
  loading: false,
  loadingEmailLogo: false,
  loadingPDFLogo: false,
});

export const getSettingsFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
  loadingEmailLogo: false,
  loadingPDFLogo: false,
});

export const getMasterSettingsForAdminRequest = state => ({
  ...state,
  loading: true,
});

export const getMasterSettingsForAdminSuccess = (state, { payload }) => ({
  ...state,
  masterSettings: payload,
  loading: false,
});

export const getMasterSettingsForAdminFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const resetMasterSettingsForAdmin = state => ({
  ...state,
  masterSettings: {},
  masterSystemLinks: [],
  loading: false,
});

export const deleteDemoTasksRequest = state => ({
  ...state,
  deleteLoading: true,
});

export const deleteDemoTasksSuccess = state => ({
  ...state,
  deleteLoading: false,
});

export const deleteDemoTasksFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  deleteLoading: false,
});

export const getDemoTasksCountRequest = state => ({
  ...state,
  loading: true,
});

export const getDemoTasksCountSuccess = (state, { payload }) => ({
  ...state,
  demoTasksCount: payload,
  loading: false,
});

export const getDemoTasksCountFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const getSignUpNotificationRequest = state => ({
  ...state,
  loading: true,
});

export const getSignUpNotificationSuccess = (state, { payload }) => ({
  ...state,
  signUpNotification: payload,
  loading: false,
});

export const getSignUpNotificationFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const setSignUpNotificationRequest = state => ({
  ...state,
  loading: true,
});

export const setSignUpNotificationSuccess = state => ({
  ...state,
  loading: false,
});

export const setSignUpNotificationFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const getSystemLimitsRequest = state => ({
  ...state,
  loading: true,
});

export const getSystemLimitsSuccess = (state, { payload }) => ({
  ...state,
  systemLimits: payload,
  loading: false,
});

export const getSystemLimitsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const setSystemLimitsRequest = state => ({
  ...state,
  loading: true,
});

export const setSystemLimitsSuccess = state => ({
  ...state,
  loading: false,
});

export const setSystemLimitsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const upgradeInfoRequest = state => ({
  ...state,
  loading: true,
});

export const upgradeInfoSuccess = state => ({
  ...state,
  loading: false,
});

export const upgradeInfoFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const getTenantSettingsListRequest = state => ({
  ...state,
  loading: true,
});

export const getTenantSettingsListSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  tenantSettingsList: payload,
});

export const refresh = () => initialState;

export default initialState;
