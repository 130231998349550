import { InputBlockLabel } from 'components/Layout/Containers';
import { EditIcon, LinkExternalIcon } from 'components/Layout/Icons';
import styled, { css } from 'styled-components';

const RootContainer = styled.form`
  width: 100%;
  padding: 10px 16px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 50px;
`;

const Label = styled(InputBlockLabel)`
  background-color: ${({ theme }) => theme.primaryRed};
  padding: 16px 28px;
`;

const SecondaryLabel = styled(InputBlockLabel)`
  padding: 16px 28px;
  background-color: ${({ theme }) => theme.darkGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkIcon = styled(LinkExternalIcon)`
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.primaryBlack};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 170px;
  height: 170px;
  justify-content: center;
`;

const inputStyles = css`
  padding: 0.7rem 1rem;
`;

const inputWrapperStyles = css`
  margin: 10px;
  float: left;
  width: 100%;
`;

const Image = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 10px;
  padding: 12px;
  border: 1px dashed ${({ theme }) => theme.darkGrey};
`;

const InputContainer = styled.div`
  flex: 1;
`;

const Edit = styled(EditIcon)`
  color: ${({ theme }) => theme.primaryWhite} !important;
`;

const Text = styled.span`
  font-weight: 300;
  color: ${({ theme }) => theme.primaryBlack};
`;

export {
  RootContainer,
  Container,
  Label,
  LinkIcon,
  Wrapper,
  Image,
  Edit,
  Text,
  InputContainer,
  SecondaryLabel,
  inputStyles,
  inputWrapperStyles,
};
