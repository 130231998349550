import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
`;

const Text = styled.li`
  font-size: 20px;
`;

const UnorderedList = styled.ul`
  list-style-type: none;
  margin-inline-start: 0;
  margin: 1.4rem 0;
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primaryLists};
  text-align: start;
`;

const LabelContainer = styled(BorderRadiusContainer)`
  margin: 1rem 0;
`;

const BoldText = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const Wrapper = styled.div``;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 34px;
`;

export { Container, LabelContainer, Wrapper, Label, Text, BoldText, Top, UnorderedList };
