import styled from 'styled-components';

import { DirectionRunsIcon } from 'components/Layout/Icons';

import { DialogContainer } from '../../styled';

export const Section = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: start;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
`;

export const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PreviewItem = styled.div`
  display: flex;
  padding: 0 10px;
  margin: 25px 0;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
`;

export const PreviewItemText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Container = styled(DialogContainer)`
  padding: 40px 40px 0;
  text-align: center;
`;

export const TestRunIconPreview = styled(DirectionRunsIcon).attrs({
  color: ({ theme }) => theme.textGray,
})``;

export const IconContainerPreview = styled.div`
  width: 40px;
  height: 40px !important;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 20px);
`;

export const Info = styled.div`
  margin: 15px 0;
`;

export const RedInfo = styled(Info)`
  color: ${({ theme }) => theme.mainRed} !important;
`;
