import styled from 'styled-components';

export const Tooltip = styled.div`
  text-align: center;
`;

export const TooltipHeader = styled.div`
  font-weight: bold;
`;

export const TooltipFirstPart = styled.div``;

export const TooltipSecondPart = styled.div``;
