import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 30px 15px;

  form {
    & > div:last-child {
      padding: 0 !important;

      & > button:first-child {
        margin-right: 10px !important;
      }

      & > button:last-child {
        margin-left: 10px !important;
      }
    }
  }
`;

export const ErrorContainer = styled.p`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;

export const Title = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.mainRed};
  font-weight: 300;
  max-width: 450px;
  margin: 10px auto;
`;

export const MainTitle = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  margin: 0px auto;
`;

export const PreviewResultsContainer = styled.div`
  padding: 30px 0 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const PreviewResultsInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  p {
    margin-top: 0;
    user-select: none;

    span {
      color: ${({ theme }) => theme.primaryBlack};
      font-weight: 700;
    }
  }
`;

export const PreviewItem = styled.div`
  display: flex;
`;

export const PreviewItemContainer = styled.div`
  width: 50%;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  padding: 0 10px;
  margin: 10px 0;
`;

export const PreviewItemSmall = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  display: flex;
  font-size: 10px;
`;

export const PreviewItemText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Step = styled.div`
  background: ${({ theme }) => theme.mainRed};
  color: ${({ theme }) => theme.primaryWhite};
  width: 60px;
  border-radius: 7px;
  margin-bottom: -35px;
  padding: 3px 0;
`;
