import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

export const groupBy = (list, key) => {
  if (!list || !Array.isArray(list)) {
    return [];
  }

  const formattedData = list.reduce((accum, item) => {
    const keyValue = item[key];

    if (!accum[keyValue]) {
      accum[keyValue] = { items: [] };
    }

    accum[keyValue].items.push(item);

    return accum;
  }, {});

  return Object.entries(formattedData).map(([groupName, groupData]) => ({
    [key]: groupName,
    items: groupData.items,
  }));
};

const sortByExecutionDateAndItemSequence = arr =>
  [...arr].sort((a, b) => a.ExecutionDate - b.ExecutionDate || a.ItemSequence - b.ItemSequence);

const Item = ({ task }) => (
  <div>
    <S.ItemName>{task.ItemName}</S.ItemName>
    <div>
      {task.items.map(item => (
        <S.Table>
          <S.Item styles={S.styles1}>{item.TaskType}</S.Item>
          <S.Item styles={S.addStyles}>{item.OriginatingTaskName || 'Task Instruction'}</S.Item>
          <S.Item>{!item.CompletedOn ? item.ExecutionDate : item.CompletedOn}</S.Item>
        </S.Table>
      ))}
    </div>
  </div>
);

Item.propTypes = {
  task: PropTypes.object.isRequired,
};

export const Table = ({ tasksList }) => {
  const groupedTasks = tasksList.reduce(
    (acc, task) => {
      if (task.Status === 'Completed') {
        acc.completed = [...acc.completed, task];
      }

      if (task.Status === 'Assigned' || task.Status === 'Unassigned' || task.Status === 'AttentionRequired') {
        acc.nonCompleted = [...acc.nonCompleted, task];
      }

      return acc;
    },
    { completed: [], nonCompleted: [] },
  );

  const sortedCompletedTasks = sortByExecutionDateAndItemSequence(groupedTasks.completed);

  const sortedNonCompletedTasks = sortByExecutionDateAndItemSequence(groupedTasks.nonCompleted);

  const completedTasksGroupedByItemName = groupBy(sortedCompletedTasks, 'ItemName');
  const nonCompletedTasksGroupedByItemName = groupBy(sortedNonCompletedTasks, 'ItemName');

  return (
    <>
      {!!groupedTasks.completed.length && (
        <S.Container>
          <S.Name>{`${groupedTasks.completed.length} Completed tasks`}</S.Name>
          {completedTasksGroupedByItemName.map(task => (
            <Item task={task} />
          ))}
        </S.Container>
      )}
      {!!groupedTasks.nonCompleted.length && (
        <S.Container>
          <S.Name>{`${groupedTasks.nonCompleted.length} Non Completed tasks`}</S.Name>
          {nonCompletedTasksGroupedByItemName.map(task => (
            <Item task={task} />
          ))}
        </S.Container>
      )}
    </>
  );
};

Table.propTypes = {
  tasksList: PropTypes.array.isRequired,
};
