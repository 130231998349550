import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as exchangeActions } from 'redux/exchange';
import { exportUnitItemPdfToListExchange } from 'http/exportPdf';
import Subheader from 'components/Subheader';
import Item from './Item';
import { Container, Message } from './styled';
import PdfsDialog from '../PdfsDialog';
import PhotosDialog from '../PhotosDialog';
import theme from 'theme';

class ItemsList extends PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    itemFilesList: PropTypes.array.isRequired,
    requestItemFilesList: PropTypes.func.isRequired,
    requestItemTasksList: PropTypes.func.isRequired,
  };

  state = {
    addPhotosDialogOpened: false,
    expandedItemId: null,
    uploadPdfDialogOpened: false,
  };

  closeUploadPdfDialog = () => {
    this.setState({ uploadPdfDialogOpened: false });
  };

  closeItemPhotosDialog = () => {
    this.setState({ addPhotosDialogOpened: false });
  };

  exportUnitItemPdf = item => {
    exportUnitItemPdfToListExchange(item.Id, item.ItemId);
  };

  handleCollapse = id => {
    if (id !== this.state.expandedItemId) {
      this.props.requestItemTasksList({ itemId: id });
      this.props.requestItemFilesList({ parentId: id });
      this.setState({ expandedItemId: id });
    } else {
      this.setState({ expandedItemId: null });
    }
  };

  openItemPdfDialog = itemId => {
    this.props.requestItemFilesList({ parentId: itemId });
    this.setState({
      uploadPdfDialogOpened: true,
    });
  };

  openItemPhotosDialog = itemId => {
    this.props.requestItemFilesList({ parentId: itemId });
    this.setState({
      addPhotosDialogOpened: true,
    });
  };

  render() {
    const { items, itemFilesList } = this.props;
    const { addPhotosDialogOpened, expandedItemId, uploadPdfDialogOpened } = this.state;

    return [
      <Subheader
        key="Items list subheader"
        rightButtons={[
          {
            buttonColor: theme.primaryLists,
            title: 'Add Item',
            disabled: true,
          },
        ]}
        title="Items"
        zIndex={1}
      />,
      <Container key="Items list">
        {items.length ? (
          items.map(item => (
            <Item
              key={item.Id}
              item={item}
              onAddPdfClick={this.openItemPdfDialog}
              onAddPhotosClick={this.openItemPhotosDialog}
              onToggleCollapse={this.handleCollapse}
              expanded={item.Id === expandedItemId}
              pictures={itemFilesList.filter(file => file.MimeType !== 'application/pdf')}
            />
          ))
        ) : (
          <Message>This unit has no items.</Message>
        )}
      </Container>,
      <PhotosDialog
        key="Upload item photos dialog"
        handleClose={this.closeItemPhotosDialog}
        open={addPhotosDialogOpened}
        onDelete={this.onDeleteItemPicture}
        onUpload={this.uploadItemPicture}
        photos={itemFilesList.filter(file => file.MimeType !== 'application/pdf')}
      />,
      <PdfsDialog
        key="Upload unit pdfs dialog"
        handleClose={this.closeUploadPdfDialog}
        handleItemClick={this.exportUnitItemPdf}
        open={uploadPdfDialogOpened}
        onDelete={this.onDeleteUnitItemPdf}
        onUpload={this.uploadItemPdf}
        pdfs={itemFilesList.filter(file => file.MimeType === 'application/pdf')}
      />,
    ];
  }
}

const mapStateToProps = ({ exchange }) => ({
  itemFilesList: exchange.itemFilesList,
});

const mapDispatchToProps = {
  requestItemFilesList: exchangeActions.getItemFilesListRequest,
  requestItemTasksList: exchangeActions.getItemTasksListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList);
