import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { actions as statusIndexActions } from 'redux/statusIndex';

import { InfoIcon } from 'components/Layout/Icons';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import TestrunButton from 'components/Buttons/TestrunButton';

import TestrunDialog from './TestrunDialog';
import ItemRules from './ItemRules';
import IndexTypeSelection from './IndexTypeSelection';
import {
  createId,
  initialRule,
  generateInitialRules,
  generateItemRules,
  indexRules,
  checkOptions,
} from './ItemRules/helpers';
import { options } from './IndexTypeSelection/constants';

import { Status } from './ItemRules/constants';

import validate from './validator';
import * as S from './styled';

class SelectionRules extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      fetchIndexItemPreviewRequest: PropTypes.func.isRequired,
      fetchAllIndexItemsRequest: PropTypes.func.isRequired,
      deleteIndexRuleRequest: PropTypes.func.isRequired,
    }).isRequired,
    indexRule: PropTypes.object.isRequired,
    onExit: PropTypes.func.isRequired,
    setIndexRule: PropTypes.func.isRequired,
    itemRuleToEdit: PropTypes.shape().isRequired,
    onNext: PropTypes.func.isRequired,
    indexItemPreview: PropTypes.array.isRequired,
    listIndexRule: PropTypes.array.isRequired,
    openErrorNameDialog: PropTypes.func.isRequired,
    indexItemLoading: PropTypes.bool.isRequired,
    indexItems: PropTypes.array.isRequired,
  };

  state = {
    itemRules: generateInitialRules({
      rules: this.props.indexRule.IndexItemRules || this.props.itemRuleToEdit?.IndexItemRules,
      flag: 'item',
    }),
    archivedTaskTime: { isAllArchivedTasks: true },
    isErrorShown: false,
    isConfirmationDialogOpened: false,
    previewOpen: false,
    isErrorName: false,
    isItemAlert: false,
    isNoItems: false,
    name: '',
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.fetchAllIndexItemsRequest();
  }

  setInitialValue = () => {
    const { itemRuleToEdit, indexRule } = this.props;
    const initialValue = {
      'index-Type': indexRule?.Type || options[0].value,
    };
    if (itemRuleToEdit.Type) {
      initialValue['index-Type'] = itemRuleToEdit.Type;
    }
    const data = { ...initialValue };
    return data;
  };

  componentDidUpdate(prevProps) {
    const { indexItemLoading, indexItemPreview, onNext } = this.props;
    const { previewOpen } = this.state;
    if (prevProps.indexItemLoading !== indexItemLoading && !indexItemLoading && !previewOpen) {
      if (indexItemPreview.length === 0) {
        this.setState({ isItemAlert: true, isNoItems: true });
      } else if (indexItemPreview.length === 1) {
        onNext();
      } else {
        this.setState({ isItemAlert: true });
      }
    }
  }

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0]?.name);
    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onKeyDownHandler = e => {
    const enterButton = 13;
    if (e.keyCode === enterButton) {
      e.preventDefault();
    }
  };

  onChangeHandler = (e, value, name, input) => {
    input.onChange(value);
    this.onChangeMatch(name, value);
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  getOptionValue = (value, indexType) => {
    switch (value) {
      case 'IndexItemState':
        return Status;
      case 'IndexItemName': {
        indexType = indexType === undefined ? 0 : indexType;
        const type = options.filter(s => s.value === indexType)[0].name;
        return this.props.indexItems.filter(x => x.Type === type);
      }
      default:
        return Status;
    }
  };

  onSubmitForm = values => {
    const { itemRuleToEdit, setIndexRule, actions, listIndexRule, openErrorNameDialog } = this.props;
    const { itemRules } = this.state;
    const itemValues = generateItemRules(values, itemRules.rules);
    const rulesToSend = {
      ...itemValues,
    };

    const valuesToSend = {
      Name: values.Name,
      Type: values['index-Type'],
      ...rulesToSend,
    };
    let isError = false;
    listIndexRule.forEach(item => {
      if (item.Name === valuesToSend.Name) {
        if (itemRuleToEdit?.Name !== valuesToSend.Name) {
          openErrorNameDialog();
          isError = true;
        }
      }
    });
    if (itemRuleToEdit.Id) {
      valuesToSend.Id = itemRuleToEdit.Id;
    }
    setIndexRule(valuesToSend);
    if (!checkOptions(valuesToSend.IndexItemRules) || isError) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      actions.fetchIndexItemPreviewRequest(valuesToSend);
    }
  };

  getPreviewResults = values => {
    const { actions } = this.props;
    const { itemRules } = this.state;
    const itemValues = generateItemRules(values, itemRules.rules);
    const rulesToSend = {
      ...itemValues,
    };
    const valuesToSend = {
      Name: values.Name,
      Type: values['index-Type'],
      ...rulesToSend,
    };
    this.setState({ name: values.Name });
    if (!checkOptions(valuesToSend.IndexItemRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      actions.fetchIndexItemPreviewRequest(valuesToSend);
      this.setState({ previewOpen: true });
    }
  };

  openDeleteDialog = () => {
    this.setState({ isConfirmationDialogOpened: true });
  };

  closeDeleteDialog = () => {
    this.setState({ isConfirmationDialogOpened: false });
  };

  onClickDialogHandler = () => {
    const { onExit, actions, itemRuleToEdit } = this.props;
    this.setState({ isConfirmationDialogOpened: false });
    actions.deleteIndexRuleRequest(itemRuleToEdit.Id);
    onExit();
  };

  closePreview = () => {
    this.setState({ previewOpen: false });
  };

  closeItemAlert = () => {
    this.setState({ isItemAlert: false, isNoItems: false });
  };

  render() {
    const { itemRuleToEdit, indexRule, indexItemPreview, onNext } = this.props;
    const { isErrorShown, isConfirmationDialogOpened, previewOpen, isItemAlert, isNoItems, name } = this.state;

    return (
      <S.DialogContainer>
        <S.FormContainer>
          <Form
            onSubmit={this.onSubmitForm}
            validate={validate}
            initialValues={this.setInitialValue()}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <S.FieldBlock>
                  <Field
                    component={S.TextField}
                    name="Name"
                    floatingLabelText="Index Rule Name"
                    initialValue={indexRule.Name || itemRuleToEdit?.Name}
                  />
                </S.FieldBlock>
                <S.Icon
                  tooltip="Selections will determine what data source will cause tasks to get created."
                  tooltipStyles={{ width: 120 }}
                  tooltipPosition="bottom-left"
                >
                  <InfoIcon />
                </S.Icon>
                <S.RedInfo> Set up rules to create tasks when something changes in the Index</S.RedInfo>
                <IndexTypeSelection values={values} initialValue={itemRuleToEdit} />
                {indexRules.map(rule => (
                  <ItemRules
                    key={rule}
                    title={'Index Item Selection Rules'}
                    rules={this.state[`${rule}Rules`]}
                    addOption={this.addOption}
                    removeOption={this.removeOption}
                    onChangeMatch={this.onChangeMatch}
                    isInitialValues={!!itemRuleToEdit.Name || !!indexRule.IndexItemRules}
                    values={values}
                    getOptionValue={this.getOptionValue}
                    onKeyDownHandler={this.onKeyDownHandler}
                    onChangeHandler={this.onChangeHandler}
                  />
                ))}
                {isErrorShown && <S.ErrorContainer>Please add at least one rule or select</S.ErrorContainer>}
                <TestrunButton isCentered onClick={() => this.getPreviewResults(values)} />
                <ActionsContainer>
                  <ActionButton
                    type="button"
                    label={itemRuleToEdit.Name ? 'Delete' : 'Cancel'}
                    onClick={itemRuleToEdit.Name ? this.openDeleteDialog : this.closeModal}
                  />
                  <ActionButton type="submit" isNext label={'Next'} />
                </ActionsContainer>
                <ConfirmationDialog
                  labelCancel="Return"
                  label="Delete"
                  title="Warning"
                  maxWidth={'80%'}
                  text={
                    <S.ConfirmationContainer>
                      <S.ConfirmationWarning>
                        Are you sure you want to delete?
                        <br /> All data will be removed
                      </S.ConfirmationWarning>
                    </S.ConfirmationContainer>
                  }
                  open={isConfirmationDialogOpened}
                  onClick={this.onClickDialogHandler}
                  handleClose={this.closeDeleteDialog}
                  onCancelClick={this.closeDeleteDialog}
                  isCancelNext
                />
                <ConfirmationDialog
                  title="Alert"
                  maxWidth={'80%'}
                  text={
                    <S.ConfirmationContainer>
                      {isNoItems ? (
                        <>
                          <S.ConfirmationWarning>
                            <S.BoldText>No Items!</S.BoldText>
                            <br />
                            The current selection contains no item results
                          </S.ConfirmationWarning>
                          <S.Text>You can change rules to widen your selection.</S.Text>
                        </>
                      ) : (
                        <>
                          <S.ConfirmationWarning>
                            <S.BoldText>Multiple Items Qualify!</S.BoldText>
                            <br />
                            Are you sure?
                          </S.ConfirmationWarning>
                          <S.Text>You can change rules to narrow your selection.</S.Text>
                        </>
                      )}
                      <ActionsContainer>
                        <ActionButton label="Return" onClick={this.closeItemAlert} />
                        {!isNoItems && <ActionButton label="Next" isNext onClick={onNext} />}
                      </ActionsContainer>
                    </S.ConfirmationContainer>
                  }
                  open={isItemAlert}
                  handleClose={this.closeItemAlert}
                />
                <TestrunDialog open={previewOpen} handleClose={this.closePreview} data={indexItemPreview} name={name} />
              </form>
            )}
          />
        </S.FormContainer>
      </S.DialogContainer>
    );
  }
}

const mapStateToProps = ({ statusIndex: { indexItemPreview, indexItemLoading, indexItems } }) => ({
  indexItemPreview,
  indexItemLoading,
  indexItems,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...statusIndexActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionRules);
