import React from 'react';
import PropTypes from 'prop-types';

import { CalendarIcon, ExportCSVIcon, BackIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';
import { DatePicker } from './styled';

const Toolbar = ({ exportDayTriggers, onDayChange, openCalendar, goBack }) => (
  <Subheader
    title="Triggers"
    isSiteOnLeftSide
    leftButtons={[
      {
        icon: <BackIcon />,
        handler: goBack,
        hint: 'Back',
      },
    ]}
    rightButtons={[
      {
        icon: <CalendarIcon />,
        handler: openCalendar,
        hint: 'Set day',
      },
      {
        icon: <ExportCSVIcon />,
        handler: exportDayTriggers,
        hint: 'Export per Day',
        tooltipStyles: {
          whiteSpace: 'nowrap',
        },
      },
      {
        isComponent: true,
        component: <DatePicker id="queue-day-picker" onChange={onDayChange} />,
      },
    ]}
  />
);

Toolbar.propTypes = {
  exportDayTriggers: PropTypes.func.isRequired,
  onDayChange: PropTypes.func.isRequired,
  openCalendar: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default Toolbar;
