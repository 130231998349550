import styled, { css } from 'styled-components';

const Container = styled.div`
  margin: 20px;
`;

const Table = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemName = styled.div`
  background-color: #c5dfe4;
  padding: 10px 30px 10px;
`;

const Name = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const addStyles = css`
  flex: 1;
`;

const styles1 = css`
  padding-left: 30px;
  min-width: 250px;
`;

const Item = styled.div`
  padding: 20px 20px 10px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  background: ${({ theme }) => theme.primaryWhite};

  ${({ styles }) => styles};
`;

export { Container, Table, ItemName, Name, Item, addStyles, styles1 };
