import styled from 'styled-components';

const RootContainer = styled.form`
  margin: 20px;
  margin-bottom: 10px;
  text-align: center;
  min-width: 30vw;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Input = styled.input<{ isRedBack?: boolean }>`
  max-width: 80px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  color: ${({ theme }) => theme.mainRed};
  border-radius: 6px;
  text-align: center;
  font-size: 20px;
  background: ${({ theme, isRedBack }) => isRedBack && theme.secondaryRed};
`;

const Label = styled.div`
  padding: 6px;
  font-size: 14px;
`;

const Paragraph = styled.p`
  font-size: 20px; // TODO rem
  color: ${({ theme }) => theme.primaryBlack};
`;

const Title = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.mainRed};
`;

const BoldTitle = styled(Title)`
  font-weight: bold;
`;

const Text = styled.div`
  margin-top: 10px;
  font-size: 20px;
`;

const Question = styled.div`
  font-size: 20px;
`;

const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
`;

const Info = styled.p`
  font-weight: 200;
`;

const ConfirmationModalContent = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
`;

export {
  RootContainer,
  Wrapper,
  Input,
  Label,
  Paragraph,
  Title,
  BoldTitle,
  Text,
  Container,
  Info,
  Question,
  ConfirmationModalContent,
};
