import React from 'react';
import PropTypes from 'prop-types';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const InfoPage = ({ onNext }) => (
  <S.DialogContainer>
    <S.SectionContainer>
      <S.Title>Purpose of this Action Card:</S.Title>
      <S.Text>Create a Checklist from a new Shortcut or an existing Shortcut.</S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Preparation:</S.Title>
      <S.Text>Make sure you have a List Template in your system to start with.</S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Required information:</S.Title>
      <S.Text>
        <S.BoldText>Duration</S.BoldText> of a schedule
      </S.Text>
      <S.Text>
        <S.BoldText>Manager responsible</S.BoldText> for the schedule
      </S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Result:</S.Title>
      <S.Text>
        When a Checklist is activated, the items on the list will be turned into tasks users of the mobile app will see
        and can respond to.
      </S.Text>
    </S.SectionContainer>
    <ActionsContainer>
      <ActionButton label="Next" onClick={onNext} isNext />
    </ActionsContainer>
  </S.DialogContainer>
);

InfoPage.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default InfoPage;
