import styled from 'styled-components';
import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
  & > div:first-child {
    margin: 17px !important;
  }
`;

export const FieldBlockContainer = styled.div`
  display: flex;

  & > div:last-child {
    text-align: left;
    padding-left: 10px;
  }
`;

export const Block = styled(BorderRadiusContainer)`
  padding-bottom: 15px;
  margin-top: 35px;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ color }) => color};
  text-align: left;
`;

export const SecondaryContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SmallInfoContainer = styled.div`
  display: flex;
  margin: 10px 0 0 10px;
`;

export const InfoSmall = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const RedInfoSmall = styled(InfoSmall)`
  color: ${({ theme }) => theme.mainRed};
  display: flex;
  margin-left: 17px;
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 10px;
  width: 20%;

  input {
    font-size: 13px !important;
  }
`;

export const TimeFieldBlock = styled(FieldBlock)`
  width: 100%;
  justify-content: center;

  & > div:last-child {
    text-align: left;
    padding-left: 10px;
  }
`;

export const RedText = styled.div`
  margin-top: 25px;
  margin-bottom: -10px;
  color: ${({ theme }) => theme.mainRed} !important;
`;

export const radioIntervalStyle = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  width: '100%',
};

export const assignmentRadioStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const radioStyle = {
  display: 'grid',
  width: '100%',
};

export const typeRadioStyle = {
  width: '60%',
  fontWeight: 'bold',
  fontSize: '14px',
};

export const LabelsBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelsContainer = styled.div`
  display: flex;
  height: 35px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

export const LabelAssignment = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 15px 0;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;

  label {
    left: 10px !important;
  }

  div {
    div {
      div {
        div {
          display: flex;
          padding-left: 10px !important;
        }
      }
    }
  }

  & > div:last-child {
    text-align: left;
    padding-left: 10px;
  }
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
  text-align: left;
  padding-left: 25px;
  margin-bottom: 10px;
`;
