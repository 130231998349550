import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 16px 20px 20px;
`;

export const List = styled.div`
  max-height: 364px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const ItemContainer = styled.div`
  display: flex;
`;

export const ItemName = styled.div`
  display: flex;
  flex: 1;

  & input:disabled {
    color: ${({ theme }) => theme.primaryBlack} !important;
  }
`;

export const ItemActions = styled.div``;

export const CreateContainer = styled.form`
  display: flex;
  align-items: flex-end;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.mainRed} !important;
  margin-bottom: 4px;
`;
