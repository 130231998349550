import { Checkbox, List } from 'material-ui';
import styled from 'styled-components';

const RootContainer = styled.form`
  padding: 20px;
  width: 650px;
`;

const SearchContainer = styled.div`
  padding: 10px;
`;

const Article = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

const Title = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

const ListItemContainer = styled.div`
    display: flex;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.lightGrey};
}
`;

const CheckBox = styled(Checkbox)`
  width: auto !important;

  & > div > div > div > svg {
    fill: ${({ theme, checked }) => checked && theme.primaryGreen} !important;
  }
`;

const ListContainer = styled(List)`
  overflow: auto;
  max-height: 50vh;
`;

export { RootContainer, SearchContainer, Article, Title, ListItemContainer, CheckBox, ListContainer };
