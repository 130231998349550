const initialState = {
  assignedUsers: [],
  assignedUsersFullInfo: [],
  createdEditedSiteId: '',
  error: '',
  list: [],
  loading: false,
  pastDueLimitSite: {},
  status: 'pending',
  siteSettingsList: [],
};

export const addSiteRequest = state => ({
  ...state,
  createdEditedSiteId: '',
  error: '',
  loading: true,
});
export const addSiteSuccess = (state, { payload }) => ({
  ...state,
  createdEditedSiteId: payload.Id,
});
export const addSiteFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const assignSiteRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const assignSiteFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const assignedUsersRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const assignedUsersSuccess = (state, { payload }) => ({
  ...state,
  assignedUsers: payload.formatted,
  assignedUsersFullInfo: payload.full,
  loading: false,
});
export const assignedUsersFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteSiteRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deleteSiteFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const editSiteRequest = state => ({
  ...state,
  createdEditedSiteId: '',
  error: '',
  loading: true,
});
export const editSiteSuccess = (state, { payload }) => ({
  ...state,
  createdEditedSiteId: payload.Id,
});
export const editSiteFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const multipleSiteEditRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const multipleSiteEditSuccess = state => ({
  ...state,
  loading: false,
});
export const multipleSiteEditFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const sitesListByTenantRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const sitesListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const sitesListSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
  loading: false,
});
export const sitesListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const getPastDueLimitRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const getPastDueLimitSuccess = (state, { payload }) => ({
  ...state,
  pastDueLimitSite: payload,
  loading: false,
});

export const getSiteSettingsListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const updatePastDueLimit = state => ({
  ...state,
  error: '',
  loading: true,
});

export const setSitesStatus = (state, { payload }) => ({
  ...state,
  status: payload,
});

export const resetSitesList = state => ({
  ...state,
  list: [],
});

export const setSiteInventoryRequest = state => ({
  ...state,
  loading: true,
});

export const setSiteSettingsListSuccess = (state, { payload }) => ({
  ...state,
  siteSettingsList: payload,
  loading: false,
});

export const setIsLoading = (state, { payload }) => ({
  ...state,
  loading: payload,
});

export default initialState;
