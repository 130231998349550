import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withDrawer from 'components/Dialogs/Drawer';
import { generatePreviewList } from '../helpers';

import * as S from './styled';

const checkItemsAvailability = list => {
  let itemsCount = 0;

  list.forEach(item => {
    item.units.forEach(unit => {
      itemsCount += unit.Items.length;
      return unit;
    });
  });

  return !!itemsCount;
};

const PreviewList = ({ previewList, handleClose, handleLinkParts }) => {
  const list = generatePreviewList(previewList);

  return (
    <>
      <S.Container>
        {!list.length ? (
          <S.EmptyListText>No Location includes, unit includes and item includes</S.EmptyListText>
        ) : (
          list.map(item => (
            <>
              <S.SiteBlock>
                <S.SiteBlockRow>
                  <S.SiteBlockCol direction="right">Site Name:</S.SiteBlockCol>
                  <S.SiteBlockCol weight={700}>{item.SiteName}</S.SiteBlockCol>
                </S.SiteBlockRow>
                <S.SiteBlockRow>
                  <S.SiteBlockCol direction="right">Site ID:</S.SiteBlockCol>
                  <S.SiteBlockCol weight={700}>{item.AdditionalSiteId}</S.SiteBlockCol>
                </S.SiteBlockRow>
              </S.SiteBlock>
              {item.units.map(unit => (
                <S.UnitsList>
                  <li>
                    <S.UnitTitle>{unit.Name}</S.UnitTitle>
                    {unit.Items.map(unitItem => (
                      <S.ItemsList>
                        <li>{unitItem.Name}</li>
                      </S.ItemsList>
                    ))}
                  </li>
                </S.UnitsList>
              ))}
            </>
          ))
        )}
      </S.Container>
      <S.ButtonsGroup>
        <S.Button label="Cancel" onClick={handleClose} />
        <S.Button
          label="Link Parts"
          weight={700}
          onClick={handleLinkParts}
          disabled={!list.length || !checkItemsAvailability(list)}
        />
      </S.ButtonsGroup>
    </>
  );
};

PreviewList.propTypes = {
  previewList: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLinkParts: PropTypes.func.isRequired,
};

const PreviewListInput = withDrawer(PreviewList, 1600);

const PreviewListDialog = props => <PreviewListInput title="Preview" {...props} />;

const mapStateToProps = ({ distributions }) => ({
  previewList: distributions.previewList,
});

export default connect(mapStateToProps)(PreviewListDialog);
