import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions as appActions } from 'redux/app';

import { VersionType, IndustryType } from 'configs/propTypes';
import { USER_TYPES } from 'configs/enums';
import { useModal } from '@ebay/nice-modal-react';
import { actions as tenantsActions } from 'redux/tenants';

import UpgradeInfoDialog from 'components/Dialogs/UpgradeInfoDialog';
import UpgradeInfoSuccessDialog from 'components/Dialogs/UpgradeInfoDialog/UpgradeInfoSuccessDialog';
import { UpgradeIcon } from 'components/Layout/Icons';

import { actions as settingsActions } from 'redux/settings';

import * as S from './styled';

function PageFooter({
  userType,
  industriesList,
  version,
  requestFetchCurrentVersion,
  requestTennantIndustryList,
  requestUpgradeInfo,
}) {
  const upgradeInfoSuccessDialog = useModal(UpgradeInfoSuccessDialog);
  const upgradeInfoDialog = useModal(UpgradeInfoDialog);

  const { VersionNumber, CopyrightText, PrivacyUrl, VersionNotes } = version;
  const isUpgradeAccountAvalable =
    userType === USER_TYPES.SystemAdministrator || userType === USER_TYPES.OEMAdministrator;

  const openUpgradeInfoDialog = () => {
    if (isUpgradeAccountAvalable) {
      upgradeInfoDialog.show({
        title: 'Upgrade Info',
        onSubmit: submitUpgradeInfoDialog,
        BigButtons: true,
        industryList: industriesList,
        submitLabel: 'SEND REQUEST',
      });
    }
  };

  const submitUpgradeInfoDialog = values => {
    requestUpgradeInfo({ values, openSuccessDialog: upgradeInfoSuccessDialog.show });
    upgradeInfoDialog.hide();
  };

  useEffect(() => {
    requestFetchCurrentVersion();
    requestTennantIndustryList();
  }, [requestFetchCurrentVersion, requestTennantIndustryList]);

  return (
    <S.Container>
      <S.Legal>
        <S.CopyrightText>{CopyrightText}</S.CopyrightText>
        <S.PrivacyLink href={PrivacyUrl} target="_blank">
          Privacy Policy
        </S.PrivacyLink>
        {isUpgradeAccountAvalable && (
          <S.UpgradeAccountContainer onClick={openUpgradeInfoDialog}>
            <UpgradeIcon />
            Upgrade account
          </S.UpgradeAccountContainer>
        )}
      </S.Legal>
      <S.Version>
        <S.VersionText data-tip data-for="version">
          V{VersionNumber}
        </S.VersionText>
        <S.Tooltip id="version" effect="solid">
          <S.VersionNotes>{VersionNotes}</S.VersionNotes>
        </S.Tooltip>
      </S.Version>
    </S.Container>
  );
}

PageFooter.propTypes = {
  version: VersionType.isRequired,
  industriesList: PropTypes.arrayOf(IndustryType).isRequired,
  userType: PropTypes.number.isRequired,
  requestFetchCurrentVersion: PropTypes.func.isRequired,
  requestUpgradeInfo: PropTypes.func.isRequired,
  requestTennantIndustryList: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app, tenants, auth }) => ({
  version: app.version,
  industriesList: tenants.industryList,
  userType: auth.user.userType,
});

const mapDispatchToProps = {
  requestFetchCurrentVersion: appActions.fetchCurrentVersionRequest,
  requestUpgradeInfo: settingsActions.upgradeInfoRequest,
  requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageFooter);
