import styled from 'styled-components';
import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

const Container = styled.form`
  padding: 10px;
  text-align: center;
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
  text-align: left;
  padding: 12px;
`;

const Section = styled(BorderRadiusContainer)`
  > div {
    background: ${({ theme }) => theme.primaryGrey} !important;
  }
`;

const TasksCount = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => theme.primaryGrey};
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: ${({ theme }) => theme.primaryWhite};
`;

const TasksCountContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 14px 0;
`;

const Status = styled.div`
  background: ${({ color }) => color};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 20px;
  font-size: 22px;
  margin: 20px auto;
  max-width: max-content;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 20px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 18px;
`;

export { Container, Label, Section, TasksCountContainer, TasksCount, Status, Text, Title };
