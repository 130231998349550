import {
  AvailabilityOptionsIcon,
  ExportCSVIcon,
  PastDueBacklog,
  SchedullingTriggers,
  TimezoneIcon,
} from 'components/Layout/Icons';
import { ListItem } from 'components/Layout/Section';
import React, { FC } from 'react';
import { ButtonIcon } from 'components/Layout/Buttons';

type PropsType = {
  openSetTimezonesDialog: () => void;
  openScheduleTriggersDialog: () => void;
  openAvailabilityOptionsDialog: () => void;
  openTasksArchivingSchedulesDialog: () => void;
  openPastDueBacklogDialog: () => void;
};

export const Scheduling: FC<PropsType> = ({
  openSetTimezonesDialog,
  openScheduleTriggersDialog,
  openAvailabilityOptionsDialog,
  openTasksArchivingSchedulesDialog,
  openPastDueBacklogDialog,
}) => (
  <ListItem
    zIndex={3}
    key="Scheduling"
    name="Scheduling"
    content={
      <>
        <ButtonIcon onClick={openSetTimezonesDialog} tooltip="Zones">
          <TimezoneIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openScheduleTriggersDialog} tooltip="Scheduling Triggers">
          <SchedullingTriggers />
        </ButtonIcon>
        <ButtonIcon onClick={openAvailabilityOptionsDialog} tooltip="Availability">
          <AvailabilityOptionsIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openTasksArchivingSchedulesDialog} tooltip="Task Archiving">
          <ExportCSVIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openPastDueBacklogDialog} tooltip="Past Due Backlog">
          <PastDueBacklog />
        </ButtonIcon>
      </>
    }
  />
);
