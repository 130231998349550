import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { SensorLevelColors } from 'theme';

export const Container = styled(Flex)``;

export const Level = styled.div`
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  color: ${({ theme }) => theme.primaryWhite};
  background-color: ${({ name, isSelected, theme }) => (isSelected ? SensorLevelColors[name] : theme.primaryHome)};
  text-align: center;
`;
