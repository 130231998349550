import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { actions as tasksActions } from 'redux/tasks';
import { BackIcon, ForwardIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';
import EmptyList from 'components/EmptyList';
import * as S from './styled';

const UsersList = () => {
  const users = useSelector(state => state.tasks.users);
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState('');

  const backHome = () => history.push('/home');

  const filterList = item => item.Name.toLowerCase().includes(searchData.toLowerCase());

  const searchInList = event => setSearchData(event.target.value);

  useEffect(() => {
    dispatch(tasksActions.usersListRequest());
  }, []);

  return (
    <S.Container>
      <Subheader
        title="Assignees"
        zIndex={4}
        isSiteOnLeftSide
        isSearch
        searchData={searchData}
        searchInList={searchInList}
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: backHome,
            hint: 'Back',
          },
        ]}
      />
      {users.length ? (
        <S.List>
          {users.filter(filterList).map(({ Id, IsTeam, Name, TodayTasksCount, WeekTasksCount }) => {
            const onTaskClick = () =>
              history.push(`/tasks/${Id}/${IsTeam ? 'team' : 'user'}/${moment().format('YYYY-MM-DD')}`);

            return (
              <S.ListItem
                key={Id}
                rightIcon={
                  <S.IconButton onClick={onTaskClick} tooltipPosition="top-left" tooltip="Show tasks">
                    <ForwardIcon />
                  </S.IconButton>
                }
                secondaryText={
                  <S.SecondaryTextContainer>
                    <S.ListItemText isTeam={IsTeam} isPickUp={Name === 'Pick-Up'}>
                      Today Tasks: {TodayTasksCount}
                    </S.ListItemText>
                    <S.ListItemText isTeam={IsTeam} isPickUp={Name === 'Pick-Up'}>
                      7Day Tasks: {WeekTasksCount}
                    </S.ListItemText>
                  </S.SecondaryTextContainer>
                }
                primaryText={
                  <S.ListItemText isTeam={IsTeam} isPickUp={Name === 'Pick-Up'}>
                    {Name}
                  </S.ListItemText>
                }
              />
            );
          })}
        </S.List>
      ) : (
        <EmptyList text="There are no users" />
      )}
    </S.Container>
  );
};

export default UsersList;
