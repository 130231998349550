import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';
import styled from 'styled-components';

const RootContainer = styled.form`
  padding: 20px;
`;

const Title = styled.h3`
  text-align: center;
`;

const Container = styled(BorderRadiusContainer)``;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-inline-start: 0;
`;

const BoldText = styled.b`
  font-size: 16px;
`;

const Text = styled.span`
  margin-left: 20px;
  font-size: 16px;
`;

const Paragraph = styled.p`
  font-size: 14px;
  margin: 16px 24px;
`;

const Label = styled(InputBlockLabel)`
  background-color: ${({ theme }) => theme.secondaryGreen};
`;

const ConfirmationTextContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 300;
`;

export { RootContainer, Title, Label, Container, List, BoldText, Text, Paragraph, ConfirmationTextContainer };
