import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import { SiteType } from 'configs/propTypes';

import * as S from './styled';

const checkIfLast = list => {
  let counter = 0;
  list.forEach(item => {
    if (item.IsScheduled === true) counter += 1;
  });
  if (counter + 1 === list.length) return true;
  return false;
};

const Confirmation = ({
  onBackToSites,
  checklistsList,
  currentSite,
  list,
  onNext,
  onStep,
  siteSelectionStepNumber,
  siteSchedule,
}) => {
  const backToSites = () => {
    onBackToSites();
    onStep(siteSelectionStepNumber);
  };

  return (
    <S.Container>
      <S.Text>{`Checklists have been ${siteSchedule?.IsScheduled ? 'edit' : 'created'} for:`}</S.Text>
      <S.PreviewItemContainer>
        <S.PreviewItem>
          <S.PreviewItemText>{currentSite.AdditionalSiteID.substring(0, 5)} &nbsp;</S.PreviewItemText>
          <S.PreviewItemText>{currentSite.Name}</S.PreviewItemText>
        </S.PreviewItem>
        {checklistsList[currentSite.Id] &&
          checklistsList[currentSite.Id].map(
            unit =>
              unit.TeamId === checklistsList[currentSite.Id][0].TeamId && (
                <S.PreviewItemSmall key={unit.UnitId}>{unit.UnitName}</S.PreviewItemSmall>
              ),
          )}
      </S.PreviewItemContainer>
      <ActionsContainer>
        {siteSchedule?.IsScheduled ? (
          <>
            <ActionButton type="button" label="Edit site" onClick={backToSites} />
            <ActionButton type="button" label="Next" isNext onClick={onNext} />
          </>
        ) : (
          <ActionButton label="Next" isNext onClick={checkIfLast(list) ? onNext : backToSites} />
        )}
      </ActionsContainer>
    </S.Container>
  );
};

Confirmation.propTypes = {
  currentSite: SiteType.isRequired,
  onBackToSites: PropTypes.func.isRequired,
  onStep: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(SiteType).isRequired,
  checklistsList: PropTypes.shape({
    siteId: PropTypes.number,
    isFinal: PropTypes.bool,
    checklists: PropTypes.arrayOf(PropTypes.shape({})).isRequired, // need to provide type later
  }).isRequired,
  siteSelectionStepNumber: PropTypes.number.isRequired,
  siteSchedule: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ shortcuts }) => ({
  currentChecklist: shortcuts.currentChecklist,
  list: shortcuts.shortcutSitesInfo,
  checklistsList: shortcuts.checklistsList,
  siteSchedule: shortcuts.siteSchedule,
});

export default connect(mapStateToProps, null)(Confirmation);
