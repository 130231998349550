const RULES = {
  CheckFlowRate: ['ExpectedUpperBound', 'ExpectedLowerBound'],
  CheckQuantity: ['ExpectedUpperBound', 'ExpectedLowerBound'],
  CheckTemperature: ['ExpectedUpperBound', 'ExpectedLowerBound'],
  Feedback: [],
  PhotoConfirmation: ['MinAttachments', 'MaxAttachments'],
  ScanCode: [],
  Signature: [],
  Verification: [],
};

export const validator = values => {
  const errors = {};

  if (values.RuleData.Specification && values.RuleData.Specification.length > 255) {
    errors.RuleData = { ...errors.RuleData };
    errors.RuleData.Specification = 'Longer than 255 characters';
  }

  if (
    values.RuleData &&
    parseFloat(values.RuleData.ExpectedUpperBound) <= parseFloat(values.RuleData.ExpectedLowerBound)
  ) {
    errors.RuleData = { ...errors.RuleData };
    errors.RuleData.ExpectedUpperBound = 'The maximum value can not be equal or less than the minimum';
    errors.RuleData.ExpectedLowerBound = 'The minimum value can not be equal or greater than the maximum';
  }

  if (values.RuleData && parseFloat(values.RuleData.MaxAttachments) <= parseFloat(values.RuleData.MinAttachments)) {
    errors.RuleData = { ...errors.RuleData };
    errors.RuleData.MaxAttachments = 'The maximum value can not be equal or less than the minimum';
    errors.RuleData.MinAttachments = 'The minimum value can not be equal or greater than the maximum';
  }

  if (values.RuleData && parseFloat(values.RuleData.MaxAttachments) < 0) {
    errors.RuleData = { ...errors.RuleData };
    errors.RuleData.MaxAttachments = 'The maximum value can not be less than zero';
  }

  if (values.RuleData && parseFloat(values.RuleData.MinAttachments) < 0) {
    errors.RuleData = { ...errors.RuleData };
    errors.RuleData.MinAttachments = 'The minimum value can not be less than zero';
  }

  if (
    values.RuleData &&
    values.RuleData.MaxAttachments &&
    !Number.isInteger(parseFloat(values.RuleData.MaxAttachments))
  ) {
    errors.RuleData = { ...errors.RuleData };
    errors.RuleData.MaxAttachments = 'The maximum value should be integer';
  }

  if (
    values.RuleData &&
    values.RuleData.MinAttachments &&
    !Number.isInteger(parseFloat(values.RuleData.MinAttachments))
  ) {
    errors.RuleData = { ...errors.RuleData };
    errors.RuleData.MinAttachments = 'The minimum value should be integer';
  }

  if (!values.RuleType) {
    errors.RuleType = 'Required';
  } else {
    RULES[values.RuleType].forEach(rule => {
      if (!values.RuleData[rule]) {
        errors.RuleData = { ...errors.RuleData };
        errors.RuleData[rule] = 'Required';
      }
    });
  }
  return errors;
};

export default validator;
