import styled, { css } from 'styled-components';
import { Flex } from 'grid-styled';

import { ButtonFlat } from 'components/Layout/Buttons';
import { NextIcon, PreviousIcon } from 'components/Layout/Icons';

const iconStyles = css`
  fill: ${({ theme }) => theme.secondaryGreen} !important;
  height: 30px !important;
  width: 30px !important;
`;

export const Container = styled(Flex).attrs({
  justify: 'space-between',
  p: 1,
})`
  background-color: ${({ theme }) => theme.lightGrey};
  box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 6px, rgba(0, 0, 0, 0.12) 0px -1px 4px;
  min-height: 52px;
  position: relative;
  z-index: 10;
`;

export const Button = styled(ButtonFlat)`
  color: ${({ theme }) => theme.secondaryGreen} !important;
`;

export const NextTemplateIcon = styled(NextIcon)`
  ${iconStyles}
`;

export const PreviousTemplateIcon = styled(PreviousIcon)`
  ${iconStyles}
`;
