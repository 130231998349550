import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PageFooter from 'components/PageFooter';

import ForgotPage from './pages/ForgotPage';
import LoginPage from './pages/LoginPage';
import ActivatePage from './pages/ActivatePage';

import * as S from './styled';

const Login = () => (
  <S.Container>
    <S.LogoContainer>
      <S.Logo />
    </S.LogoContainer>
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/forgot" component={ForgotPage} />
      <Route path="/activate" component={ActivatePage} />
    </Switch>
    <PageFooter />
  </S.Container>
);

export default Login;
