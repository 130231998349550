import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Field } from 'react-final-form';
import moment from 'moment';
import theme from 'theme';
import MenuItem from 'material-ui/MenuItem';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import TimePickerAdapter from 'components/Forms/TimePickerAdapter';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { isDayInThePast } from 'helpers';
import { TeamType } from 'configs/propTypes';
import TypeRadioGroup from '../TypeRadioGroup';
import TaskRadioGroup from '../TaskFrequencyRadioGroup';
import IntervalRadioGroup from '../RepeatIntervalRadioGroup';
import IntervalTable from '../IntervalTable';
import AssignmentTypeRadioGroup from '../AssignmentTypeRadioGroup';
import { ASSIGNMENT_TYPE } from './constants';

import * as S from './styled';

const ShortcutForm = ({
  values,
  sitePeople,
  list,
  form,
  checklistToEdit,
  initialValues,
  dublicateChecklist,
  errorName,
}) => {
  const isTeamSelection = values.AssignmentType === ASSIGNMENT_TYPE.TEAM && !!list.length;
  const isPersonSelection = values.AssignmentType === ASSIGNMENT_TYPE.PERSON;

  return (
    <S.FormContainer>
      <S.FieldBlockContainer>
        <Field component={TextFieldAdapter} name="Name" floatingLabelText="Checklist Name" />
      </S.FieldBlockContainer>
      {errorName && <S.ErrorContainer>Checklist with name &apos;{errorName}&apos; already exists</S.ErrorContainer>}
      <S.FieldBlockContainer>
        <Field component={TypeRadioGroup} name="Type" style={S.typeRadioStyle} />
      </S.FieldBlockContainer>
      {!checklistToEdit.Name ? (
        <>
          <S.Block>
            <S.Label color={theme.primarySites}>Task Repeat</S.Label>
            <Field component={TaskRadioGroup} name="RepeatCycle" style={S.radioStyle} />
          </S.Block>
          <S.Block>
            <S.Label color={values.RepeatCycle !== -1 ? theme.primarySites : theme.textGray}>
              Repeat Interval&nbsp;(next period to schedule tasks in)
            </S.Label>
            {values.RepeatCycle !== -1 && (
              <S.SecondaryContainer>
                <Field component={IntervalRadioGroup} name="RepeatInterval" style={S.radioIntervalStyle} />
                <IntervalTable />
              </S.SecondaryContainer>
            )}
          </S.Block>
        </>
      ) : (
        <S.Block>
          <S.Label color={theme.primarySites}>Task Repeat</S.Label>
          <S.SmallInfoContainer>
            <S.RedInfoSmall>
              Repeat:&nbsp;
              <S.InfoSmall>{initialValues.Repeat}</S.InfoSmall>
            </S.RedInfoSmall>
            <S.RedInfoSmall>
              Skip:&nbsp;<S.InfoSmall>{initialValues.Skip}</S.InfoSmall>
            </S.RedInfoSmall>
          </S.SmallInfoContainer>
        </S.Block>
      )}
      <S.Block>
        <S.Label color={theme.primarySites}>Checklist Duration (repeat start-end)</S.Label>
        <S.FieldBlockContainer>
          <S.TimeFieldBlock>
            <Field
              name="StartDate"
              component={DatePickerAdapter}
              shouldDisableDate={isDayInThePast}
              floatingLabelText="Start Date"
              initialValue={initialValues.StartDate ? new Date(initialValues.StartDate) : null}
              formatDate={date => moment(date).format('ddd-MMM-D-YYYY')}
              disabled={!dublicateChecklist && checklistToEdit.Name}
            />
          </S.TimeFieldBlock>
          <S.TimeFieldBlock>
            <Field
              name="EndDate"
              component={DatePickerAdapter}
              shouldDisableDate={isDayInThePast}
              floatingLabelText="End Date"
              initialValue={initialValues.EndDate ? new Date(initialValues.EndDate) : null}
              formatDate={date => moment(date).format('ddd-MMM-D-YYYY')}
              disabled={values.RepeatCycle === -1 || initialValues.Repeat === 'One Time'}
            />
          </S.TimeFieldBlock>
        </S.FieldBlockContainer>
      </S.Block>
      <S.Block>
        <S.Label color={theme.primarySites}>Daily Time Slot/Shift</S.Label>
        <S.FieldBlockContainer>
          <S.TimeFieldBlock>
            <Field
              name="startTime"
              component={TimePickerAdapter}
              floatingLabelText="Start Time"
              initialValue={initialValues.startTime ? new Date(initialValues.startTime) : 0}
              disabled={!dublicateChecklist && checklistToEdit.Name}
            />
          </S.TimeFieldBlock>
          <S.TimeFieldBlock>
            <Field
              name="endTime"
              component={TimePickerAdapter}
              floatingLabelText="End Time"
              initialValue={initialValues.endTime ? new Date(initialValues.endTime) : 0}
              disabled={values.RepeatCycle === -1 || initialValues.Repeat === 'One Time'}
            />
          </S.TimeFieldBlock>
        </S.FieldBlockContainer>
      </S.Block>
      <S.Block>
        <S.Label color={theme.primarySites}>Checklist Owner</S.Label>
        <S.FieldContainer>
          <Field component={SelectFieldAdapter} floatingLabelText="Manager/Supervisor" name="OwnerName">
            {sitePeople
              ?.filter(item => item.UserType === 'Manager')
              .map(item => (
                <MenuItem key={item.UserId} primaryText={item.UserName} value={item.UserId} />
              ))}
          </Field>
        </S.FieldContainer>
      </S.Block>
      <S.RedText>Tasks will be assigned to:</S.RedText>
      <S.Block>
        <S.Label color={theme.mainRed}>Assignment Type</S.Label>
        <S.FieldBlockContainer>
          <Field
            component={AssignmentTypeRadioGroup}
            name="AssignmentType"
            style={S.assignmentRadioStyle}
            isDisabledRadioButton={!list.length}
            defaultValue={ASSIGNMENT_TYPE.ANYONE}
          />
          <S.LabelsBlock>
            <S.LabelsContainer>
              <S.LabelAssignment>PICKUP</S.LabelAssignment>
              <S.Text>Anyone and any Team pick-up&nbsp;(Open Assignment)</S.Text>
            </S.LabelsContainer>
            <S.LabelsContainer data-tip={!list.length && "You don't have any teams"}>
              <S.LabelAssignment>TEAM</S.LabelAssignment>
              <S.Text>All Tasks are assigned to a whole Team</S.Text>
              {!list.length && <ReactTooltip />}
            </S.LabelsContainer>
            <S.LabelsContainer>
              <S.LabelAssignment>PERSON</S.LabelAssignment>
              <S.Text>All Tasks are assigned to one specific person</S.Text>
            </S.LabelsContainer>
          </S.LabelsBlock>
        </S.FieldBlockContainer>
      </S.Block>
      <S.Block>
        <S.Label color={isTeamSelection ? theme.primarySites : theme.textGray}>Team Selection</S.Label>
        {isTeamSelection && (
          <S.FieldContainer>
            <Field
              component={SelectFieldAdapter}
              floatingLabelText="Team Name"
              name="TeamName"
              onChangeControl={() => form.reset({ ...values, PersonName: null })}
            >
              {list?.map(item => (
                <MenuItem key={item.Id} primaryText={item.Name} value={item.Id} />
              ))}
            </Field>
            {values.TeamName && (
              <S.InfoContainer>
                <S.Info>
                  Team Manager:&nbsp;
                  <b>{list.find(team => team.Id === values.TeamName).Manager.Name}</b>
                </S.Info>
                <S.Info>
                  Team Assistant:&nbsp;
                  <b>{list.find(team => team.Id === values.TeamName).Assistant.Name}</b>
                </S.Info>
              </S.InfoContainer>
            )}
          </S.FieldContainer>
        )}
      </S.Block>
      <S.Block>
        <S.Label color={isPersonSelection ? theme.primarySites : theme.textGray}>Person Selection</S.Label>
        {isPersonSelection && (
          <S.FieldContainer>
            <Field
              component={SelectFieldAdapter}
              floatingLabelText="Name"
              name="PersonName"
              onChangeControl={() => form.reset({ ...values, TeamName: null })}
            >
              {sitePeople
                .filter(item => item.UserType === 'Manager' || item.UserType === 'TaskSpecialist')
                .map(item => (
                  <MenuItem key={item.UserId} primaryText={item.UserName} value={item.UserId} />
                ))}
            </Field>
          </S.FieldContainer>
        )}
      </S.Block>
    </S.FormContainer>
  );
};

export default ShortcutForm;

ShortcutForm.propTypes = {
  values: PropTypes.shape().isRequired,
  people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sitePeople: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  list: PropTypes.arrayOf(TeamType).isRequired,
  form: PropTypes.object.isRequired,
  checklistToEdit: PropTypes.shape().isRequired,
  initialValues: PropTypes.shape().isRequired,
  dublicateChecklist: PropTypes.bool.isRequired,
  errorName: PropTypes.string.isRequired,
};
