import styled from 'styled-components';

import { ShowPasswordButton } from 'modules/auth/pages/LoginPage/LoginForm/styled';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const EditWarning = styled.div.attrs({
  textColor: ({ theme }) => theme.modalCancelColor,
})`
  margin-top: 15px;
  color: ${props => props.textColor};
`;

export const FieldContainer = styled.div`
  position: relative;
  width: fill-available;
`;

export const PasswordShowButton = styled(ShowPasswordButton).attrs({})`
  top: 34px !important;
`;
