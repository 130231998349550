import styled from 'styled-components';
import { RadioButtonGroup } from 'material-ui';

const DialogContainer = styled.div`
  text-align: center;
`;

const SearchContainer = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
  margin: 20px 0;
`;

const GroupRadioButton = styled(RadioButtonGroup)`
  padding: 10px 40px;
  text-align: start;
`;

const radioButtonStyle = {
  borderTop: '1px solid',
  padding: '10px 0',
  width: '100%',
};

export { DialogContainer, SearchContainer, GroupRadioButton, radioButtonStyle };
