import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

export const Gateway = styled(Flex).attrs({
  w: 1,
})`
  cursor: pointer;
`;

export const InfoBlock = styled(Flex).attrs({
  direction: 'column',
  justify: 'flex-start',
  w: [0.2],
})`
  align-items: flex-start !important;
`;

export const Text = styled(Box).attrs({})``;

export const InactiveMarker = styled.div`
  margin-left: -28px;
  width: 10px;
  height: 100%;
  background: ${({ theme }) => theme.primaryRed};
  position: absolute;
  top: 0;
  left: 0;
`;
