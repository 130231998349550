const initialState = {
  error: '',
  exchangeList: [],
  exchangeVersionsList: [],
  loading: false,
  unitVersion: {},
  unitFilesList: [],
  unitFile: {},
  itemsList: [],
  item: {},
  itemFilesList: [],
  itemFile: {},
  itemTasksList: [],
  itemTask: {},
  sitesByTenant: [],
  isImported: false,
};

export const exchangeListRequest = state => ({
  ...state,
  error: '',
  isImported: false,
  loading: true,
});
export const exchangeListSuccess = (state, { payload }) => ({
  ...state,
  exchangeList: payload,
  loading: false,
});
export const exchangeListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const listExchangeVersionsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const listExchangeVersionsSuccess = (state, { payload }) => ({
  ...state,
  exchangeVersionsList: payload,
  loading: false,
});
export const listExchangeVersionsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const unitApproveRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const unitApproveSuccess = state => ({
  ...state,
  loading: false,
});
export const unitApproveFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const sitesByTenantsListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const sitesByTenantsListSuccess = (state, { payload }) => ({
  ...state,
  sitesByTenant: payload,
  loading: false,
});
export const sitesByTenantsListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const importListToTenantRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const importListToTenantSuccess = state => ({
  ...state,
  isImported: true,
  loading: false,
});
export const importListToTenantFailure = (state, { payload }) => ({
  ...state,
  isImported: false,
  error: payload,
  loading: false,
});
export const getUnitVersionRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getUnitVersionSuccess = (state, { payload }) => ({
  ...state,
  unitVersion: payload,
  loading: false,
});
export const getUnitVersionFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getUnitFilesListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getUnitFilesListSuccess = (state, { payload }) => ({
  ...state,
  unitFilesList: payload,
  loading: false,
});
export const getUnitFilesListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getItemsListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getItemsListSuccess = (state, { payload }) => ({
  ...state,
  itemsList: payload,
  loading: false,
});
export const getItemsListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getItemRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getItemSuccess = (state, { payload }) => ({
  ...state,
  item: payload,
  loading: false,
});
export const getItemFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getItemFilesListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getItemFilesListSuccess = (state, { payload }) => ({
  ...state,
  itemFilesList: payload,
  loading: false,
});
export const getItemFilesListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getItemTasksListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getItemTasksListSuccess = (state, { payload }) => ({
  ...state,
  itemTasksList: payload,
  loading: false,
});
export const getItemTasksListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getItemTaskRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getItemTaskSuccess = (state, { payload }) => ({
  ...state,
  itemTask: payload,
  loading: false,
});
export const getItemTaskFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteLastVersionRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deleteLastVersionSuccess = state => ({
  ...state,
  loading: false,
});
export const deleteLastVersionFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const editNotesRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const editNotesSuccess = (state, { payload }) => ({
  ...state,
  unitVersion: payload,
  loading: false,
});
export const editNotesFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export default initialState;
