import styled, { css } from 'styled-components';
import { Box } from 'grid-styled';
import { ChevronUp, ChevronDown } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import { VictoryBar } from 'victory';

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.lightGrey};
  padding: 4px;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px 2px;
  position: relative;
`;

const StaticticsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StaticticsHeader = styled.div`
  color: ${({ theme }) => theme.primarySettings};
  font-size: 22px;
`;

const StatisticsModules = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
`;

const StatisticsModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatisticsValue = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 50% !important;
  background-color: ${({ theme, label }) => (label === 'completed' ? '#FBB300' : theme.mainRed)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, label }) => (label === 'completed' ? theme.primaryBlack : theme.primaryWhite)};
  font-weight: 200;
  font-size: 24px;
`;

const StatisticsLabel = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.primaryBlack};
  text-transform: uppercase;
`;

const iconStyles = css`
  width: auto !important;
  height: auto !important;
`;

const arrowIconStyles = css`
  width: 48px !important;
  height: 48px !important;
  color: ${({ theme }) => theme.mainRed} !important;
`;

const Button = styled(ButtonIcon)`
  ${iconStyles};
`;

const InfoIcon = styled(ButtonIcon)`
  ${iconStyles};
  position: absolute !important;
  top: 8%;
  right: 5%;
`;

const ChevronUpIcon = styled(ChevronUp)`
  ${arrowIconStyles};
`;

const ChevronDownIcon = styled(ChevronDown)`
  ${arrowIconStyles};
`;

const VictoryBarContainer = styled(VictoryBar).attrs({
  style: {
    data: {
      fill: '#D50000',
    },
  },
})``;

export {
  StatisticsContainer,
  StaticticsHeaderContainer,
  StaticticsHeader,
  StatisticsModules,
  StatisticsModuleContainer,
  StatisticsValue,
  StatisticsLabel,
  ChevronUpIcon,
  ChevronDownIcon,
  Button,
  InfoIcon,
  VictoryBarContainer,
};
