import styled from 'styled-components';
import { AddIcon } from 'components/Layout/Icons';

export const DialogContainer = styled.div`
  padding: 40px 40px 0;
  text-align: center;
`;

export const Container = styled.div`
  & > div:last-child {
    padding: 15px !important;

    & > button:first-child {
      margin-right: 10px !important;
    }

    & > button:last-child {
      margin-left: 10px !important;
    }
  }
`;

export const MainTitle = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
  font-size: 20px;
  margin: 5px auto;
`;

export const RedText = styled.div`
  margin: 15px 15px;
  color: ${({ theme }) => theme.mainRed} !important;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const RedInfo = styled(Info)`
  color: ${({ theme }) => theme.mainRed};
  display: flex;
  margin: 10px 25px;
`;

export const FooterText = styled.div`
  margin: 15px 0 15px 45%;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const IconDelete = styled(AddIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;
