import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import withInputModal from 'components/Dialogs/withInputModal';
import Search from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';

import validate from './validator';
import * as S from './styled';

class ChooseIndustryForm extends PureComponent {
  static propTypes = {
    industry: PropTypes.shape({
      Name: PropTypes.string,
      MeeteringGroupID: PropTypes.string,
      Id: PropTypes.string,
    }).isRequired,
    groupsList: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string,
        Name: PropTypes.string,
        IndustryID: PropTypes.string,
      }),
    ).isRequired,
  };

  state = {
    searchData: '',
    groups: this.props.groupsList,
  };

  setSearchedItems = (groups, searchData) => this.setState({ groups, searchData });

  render() {
    const { searchData, groups } = this.state;
    const { industry, groupsList } = this.props;

    return (
      <S.FormContainer>
        <S.DialogHeader>
          <S.GroupName>{industry.Name}</S.GroupName>
          <S.SearchContainer>
            <Search
              list={groupsList}
              filterBy="Name"
              searchData={searchData}
              placeholder="filter groups"
              setSearchedItems={this.setSearchedItems}
              icon={<SearchIcon />}
            />
          </S.SearchContainer>
        </S.DialogHeader>
        <Field name="MeeteringGroupID" type="radio">
          {({ input }) => (
            <S.RadioGroup {...input} defaultSelected={industry.MeeteringGroupID}>
              {groups.length > 0
                ? groups.map(({ Id, Name, IndustryID }) => (
                    <S.RadioField
                      key={Id}
                      value={Id}
                      label={Name}
                      disabled={IndustryID && IndustryID !== industry.Id}
                    />
                  ))
                : []}
            </S.RadioGroup>
          )}
        </Field>
      </S.FormContainer>
    );
  }
}

const ChooseIndustry = withInputModal(ChooseIndustryForm);

const ChooseIndustryDialog = props => <ChooseIndustry initialValues={props.industry} validate={validate} {...props} />;

ChooseIndustryDialog.propTypes = {
  industry: PropTypes.shape({
    Name: PropTypes.string,
    MeeteringGroupID: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ meteringGroups }) => ({
  groupsList: meteringGroups.list,
});

export default connect(mapStateToProps)(ChooseIndustryDialog);
