import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'material-ui/List';
import TextField from 'material-ui/TextField';

import withInputModal from 'components/Dialogs/withInputModal';
import { AddIcon, EditIcon, SaveIcon, DeleteIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

const AddEditDeleteIndustryForm = ({
  industries,
  addIndustry,
  deleteIndustry,
  editIndustry,
  fieldStatus,
  enableIndustry,
  handleChange,
  newIndustryName,
  editIndustryName,
}) => (
  <div>
    <List>
      {industries.map(industry => (
        <S.ListItemWrapper key={industry.Id}>
          {fieldStatus === industry.Id ? (
            <TextField
              disabled={fieldStatus !== industry.Id}
              name="Industry"
              value={fieldStatus === industry.Id ? editIndustryName : industry.Name}
              defaultValue={industry.Name}
              onChange={handleChange}
              style={S.textFieldStyle}
            />
          ) : (
            <S.ItemWrapper>{industry.Name}</S.ItemWrapper>
          )}
          <S.IconWrapper>
            {fieldStatus === industry.Id ? (
              <ButtonIcon onClick={() => editIndustry(industry.Id, editIndustryName || industry.Name)} tooltip="Save">
                <SaveIcon />
              </ButtonIcon>
            ) : (
              <ButtonIcon onClick={() => enableIndustry(industry)} tooltip="Edit">
                <EditIcon />
              </ButtonIcon>
            )}
            <ButtonIcon onClick={() => deleteIndustry(industry.Id)} tooltip="Delete">
              <DeleteIcon />
            </ButtonIcon>
          </S.IconWrapper>
        </S.ListItemWrapper>
      ))}
    </List>
    <S.ListItemWrapper>
      <TextField
        name="addIndustry"
        hintText="New industry name"
        style={S.textFieldStyle}
        value={newIndustryName}
        onChange={handleChange}
      />
      <ButtonIcon
        onClick={() => addIndustry(newIndustryName)}
        tooltip="Add"
        tooltipPosition="top-left"
        disabled={!newIndustryName}
      >
        <AddIcon />
      </ButtonIcon>
    </S.ListItemWrapper>
  </div>
);

const AddEditDeleteIndustryWithInput = withInputModal(AddEditDeleteIndustryForm);

AddEditDeleteIndustryForm.propTypes = {
  industries: PropTypes.arrayOf(PropTypes.object).isRequired,
  addIndustry: PropTypes.func.isRequired,
  deleteIndustry: PropTypes.func.isRequired,
  editIndustry: PropTypes.func.isRequired,
  fieldStatus: PropTypes.bool.isRequired,
  enableIndustry: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  newIndustryName: PropTypes.string.isRequired,
  editIndustryName: PropTypes.string.isRequired,
};

const AddEditDeleteIndustryDialog = ({
  industries,
  handleAddIndustry,
  handleDeleteIndustry,
  handleEditIndustry,
  enableField,
  onEnableEditIndustry,
  handleChange,
  newIndustryName,
  editIndustryName,
  ...rest
}) => (
  <AddEditDeleteIndustryWithInput
    {...rest}
    industries={industries}
    addIndustry={handleAddIndustry}
    deleteIndustry={handleDeleteIndustry}
    editIndustry={handleEditIndustry}
    fieldStatus={enableField}
    enableIndustry={onEnableEditIndustry}
    handleChange={handleChange}
    newIndustryName={newIndustryName}
    editIndustryName={editIndustryName}
  />
);

AddEditDeleteIndustryDialog.defaultProps = {
  withSubmit: false,
};

AddEditDeleteIndustryDialog.propTypes = {
  industries: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAddIndustry: PropTypes.func.isRequired,
  handleDeleteIndustry: PropTypes.func.isRequired,
  handleEditIndustry: PropTypes.func.isRequired,
  enableField: PropTypes.bool.isRequired,
  onEnableEditIndustry: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  newIndustryName: PropTypes.string.isRequired,
  editIndustryName: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

AddEditDeleteIndustryDialog.defaultProps = {
  editIndustryName: '',
};

export default AddEditDeleteIndustryDialog;
