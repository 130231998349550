import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ADD_PERSON_REQUEST: undefined,
  ADD_PERSON_SUCCESS: undefined,
  ADD_PERSON_FAILURE: undefined,

  DELETE_PERSON_REQUEST: undefined,
  DELETE_PERSON_FAILURE: undefined,

  EDIT_PERSON_REQUEST: undefined,
  EDIT_PERSON_SUCCESS: undefined,
  EDIT_PERSON_FAILURE: undefined,

  MULTIPLE_EDIT_PERSON_REQUEST: undefined,
  MULTIPLE_EDIT_PERSON_SUCCESS: undefined,
  MULTIPLE_EDIT_PERSON_FAILURE: undefined,

  PEOPLE_LIST_REQUEST: undefined,
  PEOPLE_LIST_SUCCESS: undefined,
  PEOPLE_LIST_FAILURE: undefined,

  ASSIGNED_SITES_REQUEST: undefined,
  ASSIGNED_SITES_SUCCESS: undefined,
  ASSIGNED_SITES_FAILURE: undefined,
  UPDATE_ASSIGNED_SITES_REQUEST: undefined,

  SEND_EMAIL_CONTENT_REQUEST: undefined,
  SEND_EMAIL_CONTENT_SUCCESS: undefined,
  SEND_EMAIL_CONTENT_FAILURE: undefined,

  GET_AVAILABILITY_OPTIONS_REQUEST: undefined,
  GET_AVAILABILITY_OPTIONS_SUCCESS: undefined,
  GET_AVAILABILITY_OPTIONS_FAILURE: undefined,

  GET_JOBS_OPTIONS_REQUEST: undefined,
  GET_JOBS_OPTIONS_SUCCESS: undefined,
  GET_JOBS_OPTIONS_FAILURE: undefined,

  GET_CAN_DELETE_OPTION_REQUEST: undefined,
  GET_CAN_DELETE_OPTION_SUCCESS: undefined,
  GET_CAN_DELETE_OPTION_FAILURE: undefined,

  UNASSIGNED_MANAGER_REQUEST: undefined,
  UNASSIGNED_MANAGER_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.addPersonRequest, handlers.addPersonRequest],
    [actions.addPersonSuccess, handlers.addPersonSuccess],
    [actions.addPersonFailure, handlers.addPersonFailure],

    [actions.deletePersonRequest, handlers.deletePersonRequest],
    [actions.deletePersonFailure, handlers.deletePersonFailure],

    [actions.editPersonRequest, handlers.editPersonRequest],
    [actions.editPersonSuccess, handlers.editPersonSuccess],
    [actions.editPersonFailure, handlers.editPersonFailure],

    [actions.peopleListRequest, handlers.peopleListRequest],
    [actions.peopleListSuccess, handlers.peopleListSuccess],
    [actions.peopleListFailure, handlers.peopleListFailure],

    [actions.multipleEditPersonRequest, handlers.multipleEditPersonRequest],
    [actions.multipleEditPersonSuccess, handlers.multipleEditPersonSuccess],
    [actions.multipleEditPersonFailure, handlers.multipleEditPersonFailure],

    [actions.sendEmailContentRequest, handlers.sendEmailContentRequest],
    [actions.sendEmailContentSuccess, handlers.sendEmailContentSuccess],
    [actions.sendEmailContentFailure, handlers.sendEmailContentFailure],

    [actions.getAvailabilityOptionsRequest, handlers.getAvailabilityOptionsRequest],
    [actions.getAvailabilityOptionsSuccess, handlers.getAvailabilityOptionsSuccess],
    [actions.getAvailabilityOptionsFailure, handlers.getAvailabilityOptionsFailure],

    [actions.getJobsOptionsRequest, handlers.getJobsOptionsRequest],
    [actions.getJobsOptionsSuccess, handlers.getJobsOptionsSuccess],
    [actions.getJobsOptionsFailure, handlers.getJobsOptionsFailure],

    [actions.getCanDeleteOptionRequest, handlers.getCanDeleteOptionRequest],
    [actions.getCanDeleteOptionSuccess, handlers.getCanDeleteOptionSuccess],
    [actions.getCanDeleteOptionFailure, handlers.getCanDeleteOptionFailure],

    [actions.assignedSitesRequest, handlers.assignedSitesRequest],
    [actions.assignedSitesSuccess, handlers.assignedSitesSuccess],
    [actions.assignedSitesFailure, handlers.assignedSitesFailure],

    [actions.unassignedManagerRequest, handlers.unassignedManagerRequest],
    [actions.unassignedManagerFailure, handlers.unassignedManagerFailure],

    [actions.updateAssignedSitesRequest, handlers.updateAssignedSitesRequest],
  ]),
  initialState,
);

export default reducer;
