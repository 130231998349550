import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useAppSelector } from 'redux/reducers';
import theme from 'theme';

import { actions as inventoryActions } from 'redux/inventory';
import { PartGroupType, PartType } from 'types/parts';
import { GroupOptionType, PartOptionType } from 'types/inventory';

import { Modal } from 'components/Modal';
import { TextInput } from 'components/Input';
import RadioGroup from 'components/RadioButtons';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import { ActionButton } from 'components/Dialogs/v1/Base';
import TestrunButton from 'components/Buttons/TestrunButton';
import { Results } from './Results';
import { GROUP_OPTIONS, PART_OPTIONS } from './configs';

import * as S from './styled';

type PropsType = {
  type: 'part' | 'group';
  periodId: string;
  siteName: string;
};

interface NewPartType extends PartType {
  isChecked: boolean;
}

interface NewPartGroupType extends PartGroupType {
  isChecked: boolean;
}

export type FormValuesType = {
  searchKeyword: string;
  searchBy: PartOptionType['value'] | GroupOptionType['value'];
  searchResultKeyword: string;
  data: Array<NewPartType> | Array<NewPartGroupType>;
  isSearchButtonClicked: boolean;
  isValid: boolean;
};

const formatData = (arr: Array<PartType> | Array<PartGroupType>) => arr.map(obj => ({ ...obj, isChecked: false }));

export const AddPartOrGroupDialog = NiceModal.create(({ type, periodId, siteName }: PropsType) => {
  const modal = useModal();
  const dispatch = useDispatch();
  const partsList: PartType[] = useAppSelector(state => state.inventory.parts);
  const groupsList: PartGroupType[] = useAppSelector(state => state.inventory.partGroups);
  const isPartInventory = type === 'part';

  const data = isPartInventory
    ? (formatData(partsList) as Array<NewPartType>)
    : (formatData(groupsList) as Array<NewPartGroupType>);

  const initialValues: FormValuesType = {
    searchKeyword: '',
    searchBy: isPartInventory ? PART_OPTIONS[0].value : GROUP_OPTIONS[0].value,
    searchResultKeyword: '',
    data,
    isSearchButtonClicked: false,
    isValid: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      const entityIds = [...values.data].filter(v => v.isChecked).map(v => v.Id);
      const payload = {
        inventoryPeriodId: periodId,
        entityIds,
        entityType: isPartInventory ? 'Part' : 'PartGroup',
      };
      dispatch(inventoryActions.createInventoryEntitiesRequest(payload));
      showAlert(entityIds.length);
      modal.hide();
    },
  });
  const { isSearchButtonClicked, isValid, searchKeyword } = formik.values;

  const showAlert = (count: number) =>
    NiceModal.show(ConfirmationModal, {
      label: 'OK',
      isNext: true,
      isGreenLabel: true,
      text: (
        <>
          <S.ConfirmationText>
            {count} New {isPartInventory ? 'Parts' : 'Groups'}
          </S.ConfirmationText>
          <S.ConfirmationText>Included in inventory with 0 quantity.</S.ConfirmationText>
        </>
      ),
      maxWidth: '600px',
      onClick: () => {
        formik.resetForm();
        NiceModal.hide(ConfirmationModal);
      },
    });

  const runSearch = () => {
    const { searchBy } = formik.values;
    formik.setFieldValue('isSearchButtonClicked', true);

    const payload = {
      partSearchParam: searchBy,
      searchText: searchKeyword,
    };

    if (isPartInventory) {
      dispatch(inventoryActions.getPartsByParamRequest(payload));
    } else {
      dispatch(inventoryActions.getPartGroupsByParamRequest(payload));
    }
  };

  const onRequestClose = () => {
    formik.resetForm();
    modal.hide();
  };

  useEffect(() => {
    if (partsList.length || groupsList.length) {
      const newData = isPartInventory ? partsList : groupsList;
      formik.setFieldValue('data', newData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsList, partsList, type]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      title={`Add ${isPartInventory ? 'Parts' : 'Groups'}`}
      titleColor={theme.primaryDark}
      isOpen={modal.visible}
    >
      <S.Form onSubmit={formik.handleSubmit}>
        <div>{siteName}</div>
        <S.Instruction>Select and Find Parts</S.Instruction>
        <BorderRadiusContainer column>
          <S.Label>Search By</S.Label>
          <RadioGroup
            input={{ ...formik.getFieldProps('searchBy') }}
            meta={formik.getFieldMeta('searchBy')}
            style={S.radioGroupStyle}
            name="searchBy"
            items={isPartInventory ? PART_OPTIONS : GROUP_OPTIONS}
            radioButtonStyle={S.radioButtonStyle}
            labelStyle={{}}
          />
        </BorderRadiusContainer>
        <S.InputContainer>
          <TextInput
            {...formik.getFieldProps('searchKeyword')}
            meta={formik.getFieldMeta('searchKeyword')}
            label="Search Input"
            inputStyles={S.inputStyles}
            withFloatingLabel
            name="searchKeyword"
          />
        </S.InputContainer>
        <TestrunButton
          onClick={runSearch}
          disabled={!searchKeyword}
          label="Run Search"
          containerStyles={S.containerStyles}
        />
        {isSearchButtonClicked && <Results formik={formik} type={type} />}
        <ActionButton
          type="submit"
          isNext
          labelFontSize="18px"
          isGreenLabel
          label="Include Selected Parts in Inventory"
          disabled={!isSearchButtonClicked || !isValid}
          isDisable={!isSearchButtonClicked || !isValid}
        />
      </S.Form>
    </Modal>
  );
});
