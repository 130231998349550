import React from 'react';
import PropTypes from 'prop-types';

import { BackIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';

const TopBar = ({ goBack, title, type, searchData, searchInList, rightButtons }) => (
  <Subheader
    leftButtons={[
      {
        icon: <BackIcon />,
        handler: goBack,
        hint: 'Back',
        tooltipStyles: { marginTop: '-10px' },
      },
    ]}
    isSearch={type !== 'specs'}
    searchData={searchData}
    searchInList={searchInList}
    rightButtons={type !== 'specs' ? rightButtons : []}
    title={title}
    isSiteOnLeftSide
  />
);

TopBar.defaultProps = {
  title: '',
};

TopBar.propTypes = {
  type: PropTypes.string.isRequired,
  searchData: PropTypes.string.isRequired,
  searchInList: PropTypes.func.isRequired,
  title: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  rightButtons: PropTypes.array.isRequired,
};

export default TopBar;
