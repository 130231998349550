import styled from 'styled-components';
import { RadioButtonGroup } from 'material-ui/RadioButton';
import Checkbox from 'material-ui/Checkbox';

export const Container = styled.div`
  padding: 30px 30px 15px;
`;

export const Section = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
`;

export const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export const MatchSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  span {
    display: inline-block;
    margin-right: 10px;
  }
`;

export const RuleContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
  grid-column-gap: 10px;
`;

export const RadioGroup = styled(RadioButtonGroup)`
  display: flex;
  flex: 1;
`;

export const PartId = styled.span`
  font-weight: bold;
`;

export const StyledCheckbox = styled(Checkbox).attrs({ style: { marginBottom: '15px' } })``;
