import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'components/Modal';
import theme from 'theme';
import moment from 'moment';
import { useFormik } from 'formik';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import { actions as settingsActions } from 'redux/settings';
import { actions as queueActions } from 'redux/queue';
import * as S from './styled';

const STYLES = {
  radioIcon: {
    fill: theme.mainRed,
  },
  radioButtonGroup: {
    margin: '20px 50px',
  },
  radioLabel: {
    fontSize: 15,
  },
};

export const QUICK_TASKS_RADIO_BUTTONS = [
  {
    label: 'Older than a week',
    value: 0,
  },
  {
    label: 'Older than 2 weeks',
    value: 1,
  },
  {
    label: 'Older than 1 month',
    value: 2,
  },
];

export const COMPLETED_TASKS_RADIO_BUTTONS = [
  {
    label: 'Older than 1 month',
    value: 0,
  },
  {
    label: 'Older than 2 months',
    value: 1,
  },
  {
    label: 'Older than 3 months',
    value: 2,
  },
  {
    label: 'Older than 6 months',
    value: 4,
  },
];

export const ArchivingDialog = NiceModal.create(({ unitId, date }) => {
  const modal = useModal();
  const dispatch = useDispatch();
  const siteSettings = useSelector(state => state.settings.list);
  const formik = useFormik({
    initialValues: {},
    onSubmit: values => {
      confirmationModal.show({
        title: 'Alert',
        label: 'YES - ARCHIVE',
        labelCancel: 'Cancel',
        isNext: true,
        width: '40%',
        text: (
          <S.ConfirmationTextContainer>
            All completed tasks will be moved to the Archive. <br />
            <S.BoldText> Are you sure? </S.BoldText>
          </S.ConfirmationTextContainer>
        ),
        onClick: () => {
          const rangeStart = moment(date).format('MM/DD/YYYY');
          let rangeEnd;

          if (values.period === QUICK_TASKS_RADIO_BUTTONS[0].value) {
            rangeEnd = moment(date).add(6, 'd').format('MM/DD/YYYY');
          } else if (values.period === QUICK_TASKS_RADIO_BUTTONS[1].value) {
            rangeEnd = moment(date).add(13, 'd').format('MM/DD/YYYY');
          } else {
            rangeEnd = moment(date).add(30, 'd').format('MM/DD/YYYY');
          }
          dispatch(queueActions.commitUnitRequest({ rangeEnd, rangeStart, unitId }));
          confirmationModal.hide();
        },
        onCancelClick: () => {
          confirmationModal.hide();
        },
      });
      modal.hide();
    },
  });

  const confirmationModal = useModal(ConfirmationModal);

  const quickTaskValue = QUICK_TASKS_RADIO_BUTTONS.find(el => el.value === siteSettings?.QuickTasksArchiving);
  const completedTasksValue = COMPLETED_TASKS_RADIO_BUTTONS.find(
    el => el.value === siteSettings?.CompletedTasksArchiving,
  );

  useEffect(() => {
    dispatch(settingsActions.getSettingsRequest());
  }, []);

  return (
    <Modal isOpen={modal.visible} onRequestClose={modal.hide} title={'Archiving'} titleColor={theme.primaryDark}>
      <S.RootContainer onSubmit={formik.handleSubmit}>
        <S.Title>Current auto archiving schedule:</S.Title>
        <S.List>
          <li>
            <S.BoldText>Regular Tasks: </S.BoldText> <S.Text>{completedTasksValue?.label}</S.Text>
          </li>
          <li>
            <S.BoldText>Quick Tasks: </S.BoldText> <S.Text>{quickTaskValue?.label}</S.Text>
          </li>
        </S.List>

        <S.Container>
          <S.Label>Archiving All Completed Tasks</S.Label>
          <RadioButtonGroup
            name="period"
            onChange={(event, value) => formik.setFieldValue('period', value)}
            style={STYLES.radioButtonGroup}
          >
            {QUICK_TASKS_RADIO_BUTTONS.map(({ label, value }) => (
              <RadioButton
                key={value}
                iconStyle={STYLES.radioIcon}
                labelStyle={STYLES.radioLabel}
                label={label}
                value={value}
              />
            ))}
          </RadioButtonGroup>
        </S.Container>
        <S.Paragraph>
          Tasks moved to the Archive will be removed from the queue. <br /> Secure and permanent reporting takes place
          in the Archive.
        </S.Paragraph>
        <ActionsContainer gapValue={10} paddingValue={14}>
          <ActionButton type="button" onClick={modal.hide} label="Cancel" />
          <ActionButton type="submit" isNext label="Archive Tasks" />
        </ActionsContainer>
      </S.RootContainer>
    </Modal>
  );
});
