import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import theme from 'theme';
import * as S from './styled';

const InfoList = ({ open, handleClose, unitToEdit, unitWithItemsTasksList, openListTemplate }) => (
  <Dialog
    open={open}
    onRequestClose={handleClose}
    title={'Unit and Items View'}
    titleColor={theme.primaryScheduler}
    width={'40%'}
  >
    <S.DialogContainer>
      <S.Paragraph>Template Name</S.Paragraph>
      <S.RedTitle>{unitToEdit.Name}</S.RedTitle>
      {unitToEdit.Description && <S.Paragraph>{unitToEdit.Description}</S.Paragraph>}
      <S.Section>
        <S.SectionHeader>Unit Info</S.SectionHeader>
        <S.SectionBody>
          <S.Text>{unitToEdit.ModelNumber}</S.Text>
          <S.Text>
            <S.BoldText>Year/Built: </S.BoldText>
            {unitToEdit.YearBuilt}
          </S.Text>
          <S.Text>
            <S.BoldText>Items Total : </S.BoldText>
            {unitWithItemsTasksList.length}
          </S.Text>
        </S.SectionBody>
      </S.Section>
      <S.Section>
        <S.SectionHeader>Items</S.SectionHeader>
        <S.SectionBody>
          {unitWithItemsTasksList.map(item => (
            <S.Row key={item.Id}>
              <S.NameText>{item.Name}</S.NameText>
              <S.TaskText>Tasks: {item.TaskTemplates.length}</S.TaskText>
            </S.Row>
          ))}
        </S.SectionBody>
      </S.Section>
      <ActionsContainer>
        <ActionButton
          type="button"
          onClick={() => openListTemplate(unitToEdit.Id)}
          isNext
          label={'Open/Edit List Template'}
        />
      </ActionsContainer>
    </S.DialogContainer>
  </Dialog>
);

InfoList.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  unitToEdit: PropTypes.object.isRequired,
  unitWithItemsTasksList: PropTypes.array.isRequired,
  openListTemplate: PropTypes.func.isRequired,
};

export default InfoList;
