import styled, { css } from 'styled-components';
import Checkbox from 'material-ui/Checkbox';

import CheckboxAdapter from 'components/Forms/CheckboxAdapter';

import theme from 'theme';

export const searchInputStyles = css`
  border: 1px solid ${theme.primaryHome};
  background-color: ${theme.lightGrey};
  border-radius: 20px;
  height: 25px;
  padding: 0 12px;
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CheckboxSimple = styled(Checkbox).attrs({
  style: { marginBottom: '15px' },
  iconStyle: ({ isChecked }) => ({ fill: isChecked && theme.primaryScheduler }),
})``;

export const CheckboxForm = styled(CheckboxAdapter).attrs({
  style: { marginBottom: '15px' },
  iconStyle: ({ input }) => ({ fill: input.value && theme.primaryScheduler }),
})``;

export const Form = styled.form`
  display: flex;
  flex: 1;
  min-height: 450px;
`;

export const SearchContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
`;

export const TableListContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding: 0px 20px;
`;

export const TableList = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: ${theme.primaryBlack};
`;

export const TableRow = styled.tr`
  vertical-align: bottom;
  display: table;
  width: 100%;
  table-layout: fixed;
`;

export const TableHeaderCell = styled.th`
  color: ${theme.primaryBlack};
  font-size: 18px;
  border-bottom: ${() => `1px solid ${theme.primaryGrey}`};
  padding-bottom: 10px;

  &:first-child {
    width: 50px;
  }
`;

export const SelectAll = styled.span`
  text-align: center;
  display: flex;
  font-weight: 300;
  margin-bottom: 5px;
`;

export const TableBody = styled.tbody`
  display: block;
`;

export const TableCell = styled.td`
  text-align: center;
  vertical-align: middle;
  border-bottom: ${() => `1px solid ${theme.primaryGrey}`};
  height: 50px;

  &:first-child {
    width: 50px;

    & > div {
      margin: 0 !important;
    }
  }
`;

export const Controls = styled.div``;
