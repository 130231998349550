import React, { FC } from 'react';
import { SecondaryIconButton, PrimaryLinkButton, ListItem } from 'components/Layout/Section';
import { AssigmentIcon, ExportQRIcon } from 'components/Layout/Icons';

type PropsType = {
  openScanDialog: () => void;
};

export const Verification: FC<PropsType> = ({ openScanDialog }) => (
  <ListItem
    key="Verification"
    name="Verification"
    zIndex={7}
    content={
      <>
        <PrimaryLinkButton path="/globalsettings/pages" icon={<AssigmentIcon />} label="Tasks" />
        <SecondaryIconButton onClick={openScanDialog} icon={<ExportQRIcon />} label="Scan" />
      </>
    }
  />
);
