import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import UnitDetails from './pages/UnitDetails';
import UnitsList from './pages/UnitsList';
import ItemDetails from './pages/UnitDetails/ItemDetails';

const Lists = () => (
  <Switch>
    <Route exact path="/units" component={UnitsList} />
    <Route exact path="/units/items/:unitId/details/:itemId" component={ItemDetails} />
    <Route exact path="/units/items/:unitId" component={UnitDetails} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Lists;
