import styled from 'styled-components';
import { ButtonFlat } from 'components/Layout/Buttons';

export const Container = styled.div`
  padding: 20px 20px 50px;
  overflow-y: auto;
`;

export const EmptyListText = styled.p``;

export const SiteBlock = styled.div`
  background: ${({ theme }) => theme.lightGrey};
  padding: 15px;
  margin-bottom: 20px;
`;

export const SiteBlockRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const SiteBlockCol = styled.p`
  margin: 0;
  padding: 0;
  padding-left: ${({ direction }) => (!direction || direction === 'left' ? '10px' : 0)};
  text-align: ${({ direction }) => direction || 'left'};
  font-weight: ${({ weight }) => weight || 400};
  color: ${({ theme }) => theme.primaryBlack};
  min-width: 100px;
`;

export const UnitsList = styled.ul`
  li {
    list-style-type: none;
  }
`;

export const UnitTitle = styled.p`
  margin-bottom: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ItemsList = styled.ul`
  li {
    margin-bottom: 20px;
    list-style-type: none;
  }
`;

export const ButtonsGroup = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.primaryWhite};
  padding: 15px 0;
  border-top: 1px solid ${({ theme }) => theme.lightGrey};
`;

export const Button = styled(ButtonFlat)`
  div {
    span {
      font-size: 18px !important;
      font-weight: ${({ weight }) => weight || 400} !important;
      color: ${({ theme }) => theme.greenButton};
    }
  }

  span {
    font-size: 18px !important;
  }
`;
