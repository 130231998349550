import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import theme from 'theme';

import * as S from './styled';

class RadioGroup extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      error: PropTypes.string,
      meta: PropTypes.bool,
      visited: PropTypes.bool.isRequired,
      touched: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const { input, meta } = this.props;

    return (
      <S.RadioGroup
        name="IsDemoTasksMode"
        valueSelected={input.value}
        onChange={(event, data) => input.onChange(data)}
        errorText={meta.visited || meta.touched ? meta.error : ''}
      >
        <S.FieldRadio
          label="Create Demo Tasks only (can be deleted)"
          labelStyle={{ color: theme.darkGrey }}
          value="DemoTasks"
        />
        <S.FieldRadio
          label="Create Live Tasks only (cannot be deleted)"
          labelStyle={{ color: theme.darkGrey }}
          value="LiveTasks"
        />
      </S.RadioGroup>
    );
  }
}

export default RadioGroup;
