import React from 'react';
import PropTypes from 'prop-types';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine } from 'victory';

import theme, { SensorLevelColors } from 'theme';
import { isIE11 } from 'helpers';
import { formatDataInfo } from './helpers';

import * as S from './styled';

const AlertTrends = ({
  alertTrends: {
    data,
    dataInfo: { average, current, high, low },
    normalMax,
    normalMin,
    tickFormat,
  },
  hasPositiveValue,
  isVisible,
}) => (
  <S.Container isVisible={isVisible}>
    <S.DataInfo>
      <S.Text>
        Current: <S.Value val={current}>{formatDataInfo(current)}</S.Value>
      </S.Text>
      <S.HighLow>
        <S.Text>
          High: <S.Value val={high}>{formatDataInfo(high)}</S.Value>
        </S.Text>
        <S.Text>
          Low: <S.Value val={low}>{formatDataInfo(low)}</S.Value>
        </S.Text>
        <S.Text>
          Average: <S.Value val={average}>{formatDataInfo(average)}</S.Value>
        </S.Text>
      </S.HighLow>
    </S.DataInfo>
    <VictoryChart
      width={280}
      height={230}
      padding={{
        top: 30,
        bottom: 30,
        left: 0,
        right: 0,
      }}
      style={{
        parent: {
          height: isIE11 ? '230px' : '100%',
        },
      }}
    >
      <VictoryLabel
        x={10}
        y={15}
        text="Alert Trend"
        textAnchor="start"
        style={{
          fontSize: 15,
          fontFamily: 'Roboto',
        }}
      />
      {normalMax && (
        <VictoryLine
          data={[
            { x: -5, y: normalMax },
            { x: 0.5, y: normalMax },
          ]}
          animate={{
            duration: 1000,
            onLoad: { duration: 1000 },
          }}
          style={{ data: { strokeDasharray: 2 } }}
        />
      )}
      {normalMin && (
        <VictoryLine
          data={[
            { x: -5, y: normalMin },
            { x: 0.5, y: normalMin },
          ]}
          animate={{
            duration: 1000,
            onLoad: { duration: 1000 },
          }}
          style={{ data: { strokeDasharray: 2 } }}
        />
      )}
      <VictoryBar
        data={data}
        x="time"
        y="value"
        barWidth={22}
        animate={{
          duration: 1000,
          onLoad: { duration: 1000 },
        }}
        style={{
          data: {
            fill: ({ datum }) => (datum.fill ? SensorLevelColors[datum.fill] : theme.primaryGrey),
            stroke: ({ datum }) => (datum.fill ? theme.darkGrey : 'transparent'),
            fillOpacity: ({ datum }) => (datum.fill ? 1 : 0),
            strokeWidth: 1,
          },
        }}
      />
      <VictoryAxis
        tickValues={[-4, -3, -2, -1, 0]}
        tickFormat={tickFormat}
        offsetY={hasPositiveValue ? 30 : 235}
        style={{
          axis: { stroke: 'transparent' },
          tickLabels: { fontSize: 12 },
        }}
      />
    </VictoryChart>
  </S.Container>
);

AlertTrends.propTypes = {
  alertTrends: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        fill: PropTypes.string.isRequired,
        time: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ).isRequired,
    dataInfo: PropTypes.shape({
      average: PropTypes.number,
      current: PropTypes.number,
      high: PropTypes.number,
      low: PropTypes.number,
    }),
    normalMax: PropTypes.number,
    normalMin: PropTypes.number,
    tickFormat: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  hasPositiveValue: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
};

AlertTrends.defaultProps = {
  isVisible: PropTypes.false,
};

export default AlertTrends;
