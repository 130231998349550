import styled from 'styled-components';
import AppBar from 'material-ui/AppBar';

import logoImage from 'components/Layout/Images/logo-light.png';

export const Bar = styled(AppBar)`
  background-color: ${({ theme }) => theme.headerColor} !important;
`;

export const titleStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const Logo = styled.img.attrs({
  src: logoImage,
  alt: 'InCheq logo',
})`
  height: 1.5em;

  @media only screen and (max-width: 400px) {
    display: none;
  }
`;
