import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'material-ui/RadioButton';

import Levels from './Levels';

import * as S from './styled';

const levelsUp = (
  <S.Container>
    Levels Escalate Upward
    <S.LevelsImage>
      <Levels />
      <S.DashedLine />
    </S.LevelsImage>
  </S.Container>
);

const levelsDown = (
  <S.Container>
    Levels Escalate Downward
    <S.LevelsImage>
      <S.DashedLine />
      <Levels isDown />
    </S.LevelsImage>
  </S.Container>
);

const levelsBoth = (
  <S.Container>
    Levels Escalate Both Ways
    <S.LevelsImage>
      <Levels />
      <S.DashedLine />
      <Levels isDown />
    </S.LevelsImage>
  </S.Container>
);

const RadioGroup = ({ input: { onChange, value } }) => (
  <S.RadioGroup name="escalation" valueSelected={value} onChange={(event, data) => onChange(data)}>
    <RadioButton label={levelsUp} value="up" />
    <RadioButton label={levelsDown} value="down" />
    <RadioButton label={levelsBoth} value="both" />
  </S.RadioGroup>
);

RadioGroup.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default RadioGroup;
