import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const TableHeaderCell = styled.th`
  font-weight: normal;
  background-color: ${({ theme }) => theme.lightGrey};
  text-align: left;
  padding: 0 25px;

  &:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
`;

export const TableRow = styled.tr`
  height: 60px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const TableCell = styled.td`
  padding: 0 25px;
  min-width: 16vw;

  &:first-child {
    background-color: ${({ theme }) => theme.archiveNameColor};
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
    position: sticky;
    left: 0;
    z-index: 2;
  }
`;

export const Title = styled.tr`
  height: 60px;
  color: ${({ theme }) => theme.primaryWhite};
  background-color: ${({ theme, title }) => (title === 'groupBy' ? theme.darkGreen : theme.secondaryGreen)};
`;

export const Value = styled.td`
  padding: 0 25px;
  font-weight: bold;
`;
