/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { MouseEvent, useEffect } from 'react';
import { useFormik } from 'formik';
import { useAppSelector } from 'redux/reducers';
import { useDispatch } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ListItem } from 'material-ui';
import { omit } from 'lodash';
import theme from 'theme';

import SearchField from 'components/Layout/Search';
import { Modal } from 'components/Modal';
import { ActionButton } from 'components/Dialogs/v1/Base';
import { SiteSettingsType } from 'types/site';

import { USER_TYPES } from 'configs/enums';
import { actions as settingsActions } from 'redux/settings';
import { actions as inventoryActions } from 'redux/inventory';
import { actions as sitesActions } from 'redux/sites';
import { TenantInventoryType } from 'types/inventory';

import * as S from './styled';

const formatData = (arr: SiteSettingsType[]) =>
  arr.map(a => {
    const { Site } = a;
    return { ...a, Name: `${Site?.AdditionalSiteID} ${Site?.Name}`, SiteId: Site.Id };
  });

type FormattedSiteSettingsType = SiteSettingsType & Record<'Name' | 'SiteId', string>;

export const InventoryActivationDialog = NiceModal.create(() => {
  const tenantSettingsList: TenantInventoryType[] = useAppSelector(state => state.settings.tenantSettingsList);
  const siteSettingsList: SiteSettingsType[] = useAppSelector(state => state.sites.siteSettingsList);
  // @ts-ignore
  const userType = useAppSelector(state => state.auth.user.userType);

  const isSystemController = USER_TYPES.SystemController === userType;
  const data = isSystemController ? tenantSettingsList : formatData(siteSettingsList);
  const modal = useModal();
  const dispatch = useDispatch();

  const initialValues = {
    searchValue: '',
    data,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: values => {
      if (isSystemController) {
        dispatch(inventoryActions.activateTenantInventory(values.data));
      } else {
        const payload = values.data.map(v => omit(v, ['Name', 'SiteId']));
        dispatch(sitesActions.setSiteInventoryRequest(payload));
      }
      modal.hide();
    },
  });

  const onSearchHandler = (filteredItems: (TenantInventoryType | FormattedSiteSettingsType)[], searchData: string) => {
    formik.setFieldValue('data', filteredItems);
    formik.setFieldValue('searchValue', searchData);
  };

  const toggleInventoryActivation = (id: string, isInputChecked: boolean) =>
    formik.values.data.map(el => {
      const selected = isSystemController
        ? (el as TenantInventoryType).TenantID
        : (el as FormattedSiteSettingsType).SiteId;

      return selected === id ? { ...el, IsInventoryActivated: isInputChecked } : el;
    });

  const renderListElements = () =>
    formik.values.data.map(el => {
      const onCheckHandler = (event: MouseEvent, isInputChecked: boolean) => {
        const id = isSystemController ? (el as TenantInventoryType).TenantID : (el as FormattedSiteSettingsType).SiteId;
        const result = toggleInventoryActivation(id, isInputChecked);
        formik.setFieldValue('data', result);
      };

      return (
        <S.ListItemContainer key={el.Id}>
          <S.CheckBox checked={el.IsInventoryActivated} onCheck={onCheckHandler} />
          <ListItem disabled>
            {isSystemController ? (el as TenantInventoryType).TenantName : (el as FormattedSiteSettingsType).Name}
          </ListItem>
        </S.ListItemContainer>
      );
    });

  useEffect(() => {
    if (isSystemController) {
      dispatch(settingsActions.getTenantSettingsListRequest());
    }
  }, [dispatch, isSystemController]);

  return (
    <Modal
      isOpen={modal.visible}
      onRequestClose={modal.hide}
      title={`${isSystemController ? 'Tenant' : 'Site'} Inventory Activation`}
      titleColor={theme.primaryDark}
    >
      <S.RootContainer onSubmit={formik.handleSubmit}>
        <S.SearchContainer>
          <SearchField<TenantInventoryType | FormattedSiteSettingsType>
            list={data}
            name="searchValue"
            filterBy={isSystemController ? 'TenantName' : 'Name'}
            searchData={formik.values.searchValue}
            placeholder={`Filter ${isSystemController ? 'Tenants' : 'Sites'}`}
            setSearchedItems={onSearchHandler}
          />
        </S.SearchContainer>
        <S.Article>
          <S.Title>Inventory</S.Title>
          <S.Title>{isSystemController ? 'Tenant' : 'Site'}</S.Title>
        </S.Article>
        <S.ListContainer>{renderListElements()}</S.ListContainer>
        <ActionButton type="submit" label="Save" isNext />
      </S.RootContainer>
    </Modal>
  );
});
