import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import { RadioButton } from 'material-ui/RadioButton';

import { deletePeriodOptions } from './constants';

import * as S from './styled';

const RemoveRequestsDialog = ({ open, onClose, handleDeleteOptionChange, currentDeleteOption, actions }) => (
  <BaseDialog
    open={open}
    onRequestClose={onClose}
    title="Remove action"
    modalStyles={{ maxWidth: '400px' }}
    defaultCancel
    actions={actions}
  >
    <S.DialogContainer>
      <S.StyledRadioButtons
        name="deleteOptions"
        onChange={handleDeleteOptionChange}
        defaultSelected={currentDeleteOption.value}
        valueSelected={currentDeleteOption.value}
      >
        {deletePeriodOptions.map(button => (
          <RadioButton value={button.value} label={button.label} key={button.value} />
        ))}
      </S.StyledRadioButtons>
    </S.DialogContainer>
  </BaseDialog>
);

RemoveRequestsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDeleteOptionChange: PropTypes.func.isRequired,
  currentDeleteOption: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default RemoveRequestsDialog;
