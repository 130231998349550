import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import RadioButtons from 'components/RadioButtons';
import { IndustryType } from 'configs/propTypes';
import { getUnitDisplayOptions } from './constants';

import * as S from './styled';

const SiteForm = ({ industriesList, values, onSubmit, industryId, tenantName }) => (
  <S.FormContainer>
    <S.Section>
      <S.SectionHeader>List Selection Criteria</S.SectionHeader>
      <S.SectionBody>
        <S.FieldBlock>
          <Field
            component={SelectFieldAdapter}
            floatingLabelText="Select Industry"
            name="IndustryId"
            initialValue={industryId}
          >
            {industriesList.map(item => (
              <MenuItem key={item.IndustryID} primaryText={item.Name} value={item.IndustryID} />
            ))}
          </Field>
        </S.FieldBlock>
        <S.FieldBlock>
          <Field
            component={RadioButtons}
            name="SelectionLists"
            items={getUnitDisplayOptions(tenantName)}
            style={S.typeRadioStyle}
          />
        </S.FieldBlock>

        <S.ButtonContainer onClick={() => onSubmit({ values })}>
          <S.IconContainer>
            <S.TestRunIcon />
          </S.IconContainer>
          <S.Text>Run results (show below)</S.Text>
        </S.ButtonContainer>
      </S.SectionBody>
    </S.Section>
  </S.FormContainer>
);

export default SiteForm;

SiteForm.propTypes = {
  industriesList: PropTypes.arrayOf(IndustryType).isRequired,
  values: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  industryId: PropTypes.string.isRequired,
  tenantName: PropTypes.string.isRequired,
};
