export const validator = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Username is required!';
  }
  if (!values.submittedPassword) {
    errors.submittedPassword = 'Password is required!';
  }
  if (!values.firstName) {
    errors.firstName = 'First name is required!';
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required!';
  }

  return errors;
};

export default validator;
