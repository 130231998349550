import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import Loader from 'components/Loader';
import { EditTeamType, FilterTeamMemberType, MembersForAssigmentType, TeamMemberType } from 'configs/propTypes';
import theme from 'theme';

import CheckboxList from './CheckboxList';
import * as S from './styled';

class AddTeamMembersDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    people: PropTypes.arrayOf(MembersForAssigmentType).isRequired,
    onAdd: PropTypes.func.isRequired,
    team: EditTeamType.isRequired,
    assignedMembers: PropTypes.arrayOf(TeamMemberType).isRequired,
    filteredTeamMembers: PropTypes.arrayOf(FilterTeamMemberType).isRequired,
    isLoading: PropTypes.bool.isRequired,
    siteId: PropTypes.string.isRequired,
  };

  state = {
    members: [],
    checkedMembers: [],
    searchData: '',
    isAssignedTaskOpened: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { people } = this.props;
    const { searchData, checkedMembers } = this.state;

    if (people && people.length > 0 && prevState.members.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        members: people,
      });
    }

    if (
      prevState.members.length !== people.length &&
      prevState.searchData === searchData &&
      prevState.checkedMembers.length === checkedMembers.length
    ) {
      this.setState({
        members: people,
      });
    }
  }

  clearCheckedMembers = () => this.setState({ checkedMembers: [] });

  fillCheckedMembers = checkedMembers => this.setState({ checkedMembers });

  setSearchedMembers = (members, searchData) => this.setState({ members, searchData });

  closeModal = () => {
    this.setState({
      members: [],
      membersForSearch: [],
      searchData: '',
    });
    this.clearCheckedMembers();
    this.props.handleClose();
  };

  addTeamMembers = () => {
    const { onAdd, team, siteId } = this.props;
    const { checkedMembers } = this.state;
    this.setState({ isAssignedTaskOpened: false });
    if (siteId) {
      onAdd({ teamId: team?.Id, userIds: checkedMembers, siteId });
    } else {
      onAdd({ teamId: team?.Id, userIds: checkedMembers });
    }
    this.closeModal();
  };

  mapAssignedMembers = () => {
    const { filteredTeamMembers, assignedMembers } = this.props;
    if (assignedMembers[0]?.User) {
      return assignedMembers.map(assignedItem => ({
        ...assignedItem.User,
        IsMultiGroupUser: filteredTeamMembers.find(filteredItem => filteredItem.UserID === assignedItem.UserID)
          ?.IsMultiGroupUser,
      }));
    }
    return assignedMembers;
  };

  render() {
    const { open, title, isLoading, team, people } = this.props;
    const { members, checkedMembers, searchData, isAssignedTaskOpened } = this.state;

    return (
      <>
        <Dialog open={open} onRequestClose={this.closeModal} title={title} titleColor={theme.primaryBlue}>
          <S.Container>
            {isLoading ? (
              <Loader size="large" isLoading={isLoading} />
            ) : (
              <>
                <S.TitleText>{team?.Name}</S.TitleText>
                <S.RedRow>{team?.Manager?.Name}</S.RedRow>
                <S.Section>
                  <S.SectionHeader>Available</S.SectionHeader>
                  <S.SectionBody>
                    <S.SearchSection>
                      <SearchField
                        list={people}
                        filterBy={['Name', 'JobFunction']}
                        searchData={searchData}
                        placeholder="Filter people"
                        setSearchedItems={this.setSearchedMembers}
                      />
                    </S.SearchSection>
                    <CheckboxList
                      list={members}
                      checkedMembers={checkedMembers}
                      fillCheckedMembers={this.fillCheckedMembers}
                    />
                  </S.SectionBody>
                </S.Section>
                <S.ButtonContainer onClick={() => this.setState({ isAssignedTaskOpened: true })}>
                  <S.IconContainer>
                    <S.TestRunIcon />
                  </S.IconContainer>
                  <S.Text>Run results (show below)</S.Text>
                </S.ButtonContainer>
                {isAssignedTaskOpened ? (
                  <S.Section>
                    <S.SectionHeader>Assigned</S.SectionHeader>
                    <S.SectionBody>
                      <CheckboxList list={this.mapAssignedMembers()} disabled />
                    </S.SectionBody>
                  </S.Section>
                ) : null}
                <ActionsContainer>
                  {!!checkedMembers.length && <ActionButton onClick={this.addTeamMembers} label="Save" isNext />}
                </ActionsContainer>
              </>
            )}
          </S.Container>
        </Dialog>
      </>
    );
  }
}

export default AddTeamMembersDialog;
