import React, { FC } from 'react';
import * as S from '../Layout/Progresses';

type PropsType = {
  isLoading: boolean;
  size?: 'small' | 'large' | number;
};

const LOADER_SIZES = {
  small: 30,
  large: 90,
};

const Loader: FC<PropsType> = ({ isLoading, size }) => (
  <S.Overlay show={isLoading}>
    <S.Loader size={typeof size === 'string' ? LOADER_SIZES[size] : size} />
  </S.Overlay>
);

Loader.defaultProps = {
  size: LOADER_SIZES.small,
};

export default Loader;
