const schedulerStatus = {
  Active: {
    title: 'Run',
    color: '#2b8402',
  },
  Inactive: {
    title: 'End',
    color: '#ff0000',
  },
  Pause: {
    title: 'Pause',
    color: '#ffe201',
  },
};

export { schedulerStatus };
