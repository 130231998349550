import { openErrorDialog } from 'redux/errorHandler';
import { actions as unitsActions } from 'redux/units';
import { getUnitNameById } from 'redux/units/selectors';
import { actions as tasksActions } from 'redux/tasks';

import { actions as queueActions } from 'redux/queue';
import { actions as exportPdfReportActions } from 'redux/exportPdfReport';

const mapStateToProps = ({ auth, queue, units, tasks, exportPdfReport }, { match }) => ({
  error: auth.error || queue.error || units.error,
  notesPhotos: tasks.taskNotes,
  photos: tasks.taskPhotos,
  reportList: exportPdfReport.reportList,
  selectedSite: auth.selectedSite,
  tasksList: queue.assignedTasks,
  toggleType: queue.toggleType,
  loading: tasks.loading,
  unitName: getUnitNameById(units, match.params.unitId),
  userId: auth.user.userId,
  taskDetails: tasks.taskDetails,
  taskParts: tasks?.taskDetails?.AssignedTaskParts?.map(({ PartID }) => {
    const { MyPartID, MyPartName } = tasks?.taskDetails?.Parts.find(({ Id }) => Id === PartID);

    return { myPartId: MyPartID, myPartName: MyPartName };
  }),
});

const mapDispatchToProps = {
  clearStateError: queueActions.stateErrorClear,
  fetchAssignedTasks: queueActions.fetchAssignedTasksRequest,
  fetchCreatePdfReport: exportPdfReportActions.createPdfReportRequest,
  fetchDeletePdfReport: exportPdfReportActions.deletePdfReportFileRequest,
  fetchReportList: exportPdfReportActions.getPdfReportListRequest,
  fetchTasks: tasksActions.fetchNotesRequest,
  fetchTasksPhotos: tasksActions.fetchPhotosRequest,
  fetchUnitsList: unitsActions.unitsListRequest,
  resetAssignedTasks: queueActions.resetAssignedTasks,
  setToggleType: queueActions.setToggleType,
  openErrorDialog,
  requestTaskDetails: tasksActions.fetchDetailsRequest,
  sendEmailWithTaskInfoRequest: tasksActions.sendTaskInfoEmailRequest,
  requestSaveTask: tasksActions.saveTaskRequest,
  setSelectedTasksInfo: queueActions.setSelectedTasksInfo,
};

export { mapStateToProps, mapDispatchToProps };
