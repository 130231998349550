import moment from 'moment';
import { schedulerStatus } from './config';

const schedulerStatusHandler = ({ IsDynamic, ActiveStatus, IsPaused }) => {
  if (!IsDynamic && ActiveStatus === 'Active') {
    return schedulerStatus.Pause;
  }
  if (ActiveStatus === 'Active') {
    if (IsPaused) {
      return schedulerStatus.Pause;
    }
    return schedulerStatus.Active;
  }
  if (ActiveStatus === 'Inactive') {
    return schedulerStatus.Inactive;
  }
  return { title: '' };
};

const getInitialDates = scheduleDto => {
  let dates = {};
  if (scheduleDto.ScheduleType === 'RecurringDaily') {
    dates = {
      repeatCycle: 'Daily',
      startDate: moment(scheduleDto.ScheduleData.DailyStartDate).format('L').replaceAll('/', '-'),
      endDate: moment(scheduleDto.ScheduleData.DailyRepeatUntil).format('L').replaceAll('/', '-'),
      startTime: moment(scheduleDto.ScheduleData.DailyStartDate).format('h:mm a'),
      endTime: moment(scheduleDto.ScheduleData.DailyRepeatUntil).format('h:mm a'),
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringWeekly') {
    dates = {
      repeatCycle: 'Weekly',
      startDate: moment(scheduleDto.ScheduleData.WeeklyStartDate).format('L').replaceAll('/', '-'),
      endDate: moment(scheduleDto.ScheduleData.WeeklyRepeatUntil).format('L').replaceAll('/', '-'),
      startTime: moment(scheduleDto.ScheduleData.WeeklyStartDate).format('h:mm a'),
      endTime: moment(scheduleDto.ScheduleData.WeeklyRepeatUntil).format('h:mm a'),
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringMonthly') {
    dates = {
      repeatCycle: 'Monthly',
      startDate: moment(scheduleDto.ScheduleData.MonthlyStartDate).format('L').replaceAll('/', '-'),
      endDate: moment(scheduleDto.ScheduleData.MonthlyRepeatUntil).format('L').replaceAll('/', '-'),
      startTime: moment(scheduleDto.ScheduleData.MonthlytartDate).format('h:mm a'),
      endTime: moment(scheduleDto.ScheduleData.MonthlyRepeatUntil).format('h:mm a'),
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringYearly') {
    dates = {
      repeatCycle: 'Yearly',
      startDate: moment(scheduleDto.ScheduleData.YearlyStartDate).format('L').replaceAll('/', '-'),
      endDate: moment(scheduleDto.ScheduleData.YearlyRepeatUntil).format('L').replaceAll('/', '-'),
      startTime: moment(scheduleDto.ScheduleData.YearlyStartDate).format('h:mm a'),
      endTime: moment(scheduleDto.ScheduleData.YearlyRepeatUntil).format('h:mm a'),
    };
  }

  if (scheduleDto.ScheduleType === 'OneTime') {
    dates = {
      repeatCycle: null,
      startDate: moment(scheduleDto.ScheduleData.DailyStartDate).format('L').replaceAll('/', '-'),
      endDate: null,
      startTime: moment(scheduleDto.ScheduleData.DailyStartDate).format('h:mm a'),
      endTime: null,
    };
  }
  return dates;
};

const findSite = (sitesArray, selectedSite) => {
  const site = sitesArray.find(siteObject => siteObject.id === selectedSite);
  if (site.additionalId) {
    return `${site.additionalId.substring(0, 5)} ${site.name}`;
  }
  return `${site.name}`;
};

export { getInitialDates, findSite, schedulerStatusHandler };
