import React from 'react';
import { ReactComponent as ElectricBoltIcon } from 'components/Layout/Images/bolt.svg';

export const periodToggle = [
  { key: 'day', title: 'day' },
  { key: 'week', title: 'week' },
  { key: 'past', title: 'past due' },
];

export const progressTasksToggle = [
  { key: 'today', title: 'today' },
  { key: 'days', title: '7 days' },
  { key: 'month', title: 'month' },
];

export const attentionTasksToggle = [
  { key: 'all', title: 'all' },
  { key: 'parts', title: 'parts' },
];

export const taskToggle = [
  { key: 'my', title: 'my tasks' },
  { key: 'team', title: 'team tasks' },
  { key: 'pickUp', title: 'pick-up tasks' },
];

export const queueTaskToggle = [
  { key: 'all', title: 'all' },
  { key: 'completed', title: 'completed' },
  { key: 'open', title: 'unfinished' },
  {
    key: 'quicktask',
    title: 'qt',
    icon: <ElectricBoltIcon />,
  },
];

export const unitsToggle = [
  { key: 'new', title: 'new', buttonLabel: 'approve' },
  { key: 'approved', title: 'approved', buttonLabel: 'copy to a tennant' },
];

export const tenantRequestsToggle = [
  { key: 'new', title: 'new' },
  { key: 'sent', title: 'sent' },
  { key: 'blocked', title: 'blocked' },
];

export const partsToggle = [
  { key: 'parts', title: 'Parts' },
  { key: 'groups', title: 'Groups' },
];

export const partDetailsToggle = [
  { key: 'all', title: 'All' },
  { key: 'financial', title: 'Financial' },
  { key: 'attributes', title: 'Attributes' },
];

export const schedulerToggle = [
  { key: 'manual', title: 'Manual' },
  { key: 'shortcut', title: 'Shortcut' },
];

export const unitDetailsToggle = [
  { key: 'items', title: 'Items' },
  { key: 'specs', title: 'Unit Specs' },
];

export const itemDetailsToggle = [
  { key: 'tasks', title: 'Tasks' },
  { key: 'specs', title: 'Item Specs' },
];
