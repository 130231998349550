import http from '../index';

export const getAvailabilityOptions = () => http.post('UserProfile/ListOptions');

export const createAvailabilityOption = dto => http.post('UserProfile/CreateOption', { dto });

export const updateAvailabilityOption = dto => http.post('UserProfile/EditOption', { dto });

export const removeAvailabilityOption = id => http.post('UserProfile/DeleteOption', { id });

export const getJobsOptions = () => http.post('UserProfile/ListJobFunctions');

export const createJobOption = jobFunctionDto => http.post('UserProfile/CreateJobFunction', { jobFunctionDto });

export const editJobOption = jobFunctionDto => http.post('UserProfile/EditJobFunction', { jobFunctionDto });

export const removeJobOption = (id, newId) =>
  http.post('UserProfile/DeleteJobFunction', { jobFunctionId: id, newJobFunctionId: newId });

export const getCanDeleteOption = async id => {
  const result = await http.post('UserProfile/CanDeleteJobFunction', { jobFunctionId: id });
  return result.data;
};
