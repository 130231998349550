const initialState = {
  createdEditedVersionLegalId: '',
  error: '',
  versionNumber: '',
  versionNotes: '',
  copyrightTest: '',
  privacyUrl: '',
  loading: false,
  list: [],
};

export const addVersionlegalRequest = state => ({
  ...state,
  createdEditedVersionLegalId: '',
  error: '',
  loading: true,
});
export const addVersionlegalSuccess = (state, { payload }) => ({
  ...state,
  createdEditedVersionLegalId: payload.Id,
});
export const addVersionlegalFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const deleteVersionlegalRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deleteVersionlegalFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const editVersionlegalRequest = state => ({
  ...state,
  createdEditedVersionLegalId: '',
  error: '',
  loading: true,
});
export const editVersionlegalSuccess = (state, { payload }) => ({
  ...state,
  createdEditedVersionLegalId: payload.Id,
});
export const editVersionlegalFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const versionlegalsListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const versionlegalsListSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
  loading: false,
});
export const versionlegalsListFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export default initialState;
