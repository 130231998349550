import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actions as unitsActions } from 'redux/units';
import { actions as unitActions } from 'redux/unit';
import { actions as settingsActions } from 'redux/settings';
import { setSchedulerType } from 'helpers/storage';

import Subheader from 'components/Subheader';
import EmptyList from 'components/EmptyList';
import TableList from 'components/TableList';
import { ButtonIcon } from 'components/Layout/Buttons';
import { BackIcon } from 'components/Layout/Icons';

import { searchItemByKeyword } from 'helpers';

import { TaskCreationInfo } from './TaskCreationInfo';
import InfoList from './InfoList';
import { tableColumns } from './constants';

import * as S from './styled';

const UnitsList = () => {
  const [searchData, setSearchData] = useState('');
  const [unitToEdit, setUnitToEdit] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const selectedSite = useSelector(state => state.auth.selectedSite);
  const unitsList = useSelector(state => state.units.unitsList);
  const unitWithItemsTasksList = useSelector(state => state.unit.unitWithItemsTasksList);

  const history = useHistory();
  const { type } = useParams();

  const dispatch = useDispatch();

  const searchInList = event => setSearchData(event.target.value);

  const openListTemplate = id => history.push(`/units/items/${id}`);

  const backHome = () => history.push('/scheduler');

  const mapList = item => {
    const isQuickTask = type === 'quickTask';

    return {
      ...item,
      ModelNumber: (
        <>
          <S.BoldText>Model: </S.BoldText>
          {item.ModelNumber}
        </>
      ),
      Checklists: (
        <>
          <S.BoldText>{isQuickTask ? 'QuickTasks: ' : 'Checklists: '}</S.BoldText>
          <S.RedText>{isQuickTask ? item.TotalCountTask : item.TotalCountChecklist}</S.RedText>
        </>
      ),
    };
  };

  const renderRightControllCell = item => (
    <td>
      <ButtonIcon onClick={() => goForward(item)} tooltip="View Details">
        <S.IconForward />
      </ButtonIcon>
    </td>
  );

  const goForward = item => history.push({ pathname: `/scheduler/list/${item.Id}` });

  const editUnit = item => {
    setUnitToEdit(item);
    setIsModalVisible(true);
    dispatch(unitActions.getUnitItemsTasksRequest({ Id: item.Id }));
  };

  const closeModal = () => {
    setUnitToEdit({});
    setIsModalVisible(false);
  };

  useEffect(() => {
    setSchedulerType(type);

    const payload = {
      includeChecklistData: true,
      includeTaskData: true,
      filter: {
        SiteID: selectedSite,
        checklistType: type,
      },
    };

    dispatch(unitsActions.unitsListRequest(payload));
  }, [dispatch, selectedSite, type]);

  useEffect(() => {
    dispatch(settingsActions.getSettingsRequest());
  }, [dispatch]);

  return (
    <>
      <Subheader
        title="List Templates"
        isSiteOnLeftSide
        isSearch
        searchData={searchData}
        searchInList={searchInList}
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: backHome,
            hint: 'Back',
          },
        ]}
        rightButtons={[
          {
            isComponent: true,
            component: <TaskCreationInfo />,
          },
        ]}
      />
      {unitsList && unitsList.length ? (
        <S.TableContainer>
          <TableList
            list={unitsList.filter(searchItemByKeyword(tableColumns, searchData)).map(mapList)}
            tableColumns={tableColumns}
            onRowClick={editUnit}
            isPointer
            renderRightControllCell={renderRightControllCell}
          />
        </S.TableContainer>
      ) : (
        <EmptyList text="There are no units" />
      )}
      <InfoList
        open={isModalVisible}
        handleClose={closeModal}
        unitToEdit={unitToEdit}
        unitWithItemsTasksList={unitWithItemsTasksList}
        openListTemplate={openListTemplate}
      />
    </>
  );
};

export default UnitsList;
