import { call, put, takeEvery, select } from 'redux-saga/effects';

import { getTenants, updateTenants } from 'http/tenants';
import {
  getTaskTypes,
  putTaskTypes,
  createAssetCategory,
  createAssetType,
  deleteAssetCategory,
  deleteAssetType,
  getAssetCategory,
  getAssetCategoryList,
  getAssetType,
  getAssetTypeList,
  updateAssetCategory,
  updateAssetType,
} from 'http/globalSettings';

import { ERROR_DIALOG_OPENED } from 'redux/errorHandler';
import { actions } from './index';
import { sortBy } from 'lodash';
import { parseServerError } from '../../helpers';

function* getTenantsRequest({ payload }) {
  try {
    const data = yield call(getTenants, { payload });
    yield put(actions.getTenantsSuccess(data));
  } catch (error) {
    yield put(actions.getTenantsFailure(error));
  }
}

function* updateTenantsRequest({ payload }) {
  try {
    const { data } = yield select(({ globalSettings }) => globalSettings.tenants);
    yield call(updateTenants, { data });
    payload.onCloseDialog();
    yield put(actions.updateTenantsSuccess());
  } catch (error) {
    yield put(actions.updateTenantsFailure, payload(error));
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: {
        title: 'Error due to data update',
        message: error.message,
      },
    });
  }
}

function* getTaskTypesRequest() {
  try {
    const {
      data: {
        entity: { Results },
      },
    } = yield call(getTaskTypes);
    yield put(actions.getTaskTypesSuccess(Results));
  } catch (error) {
    yield put(actions.getTaskTypesFailure(error));
  }
}

function* putTaskTypesRequest({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(putTaskTypes, payload.id, payload.value);
    yield put(actions.putTaskTypesSuccess({ entity }));
  } catch (error) {
    yield put(actions.putTaskTypesFailure(error));
  }
}

function* fetchAssetCategoryList() {
  try {
    const { data } = yield call(getAssetCategoryList);
    yield put(actions.fetchAssetCategoryListSuccess(sortBy(data.root, ({ Name }) => Name.toLowerCase())));
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* fetchAssetCategory({ payload }) {
  try {
    const { data } = yield call(getAssetCategory, payload);
    yield put(actions.fetchAssetCategorySuccess(data.entity.Dto));
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* addAssetCategory({ payload }) {
  try {
    yield call(createAssetCategory, payload);
    yield* fetchAssetCategoryList();
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* editAssetCategory({ payload }) {
  try {
    yield call(updateAssetCategory, payload);
    yield* fetchAssetCategoryList();
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* removeAssetCategory({ payload }) {
  try {
    yield call(deleteAssetCategory, payload);
    yield* fetchAssetCategoryList();
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* fetchAssetTypeList({ payload }) {
  try {
    const { data } = yield call(getAssetTypeList, { categoryID: payload });
    yield put(actions.fetchAssetTypeListSuccess(sortBy(data.root, ({ Name }) => Name.toLowerCase())));
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* fetchAssetType({ payload }) {
  try {
    const { data } = yield call(getAssetType, payload);
    yield put(actions.fetchAssetTypeSuccess(data.entity));
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* addAssetType({ payload }) {
  try {
    yield call(createAssetType, payload);
    yield* fetchAssetTypeList({ payload: payload.masterAssetCategoryID });
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* editAssetType({ payload }) {
  try {
    yield call(updateAssetType, payload);
    yield* fetchAssetTypeList({ payload: payload.masterAssetCategoryID });
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

function* removeAssetType({ payload }) {
  try {
    yield call(deleteAssetType, payload.id);
    yield* fetchAssetTypeList({ payload: payload.masterAssetCategoryID });
  } catch (e) {
    const errorMessage = e.isAxiosError ? e.response.data.error : e.message;

    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(e) });
    yield put(actions.setGlobalSettingsLoading(errorMessage));
  }
}

const globalSettingsSagas = [
  takeEvery(actions.getTenantsRequest, getTenantsRequest),
  takeEvery(actions.updateTenantsRequest, updateTenantsRequest),
  takeEvery(actions.getTaskTypesRequest, getTaskTypesRequest),
  takeEvery(actions.putTaskTypesRequest, putTaskTypesRequest),

  takeEvery(actions.fetchAssetCategoryListRequest, fetchAssetCategoryList),
  takeEvery(actions.fetchAssetCategoryRequest, fetchAssetCategory),
  takeEvery(actions.createAssetCategoryRequest, addAssetCategory),
  takeEvery(actions.updateAssetCategoryRequest, editAssetCategory),
  takeEvery(actions.deleteAssetCategoryRequest, removeAssetCategory),

  takeEvery(actions.fetchAssetTypeListRequest, fetchAssetTypeList),
  takeEvery(actions.fetchAssetTypeRequest, fetchAssetType),
  takeEvery(actions.createAssetTypeRequest, addAssetType),
  takeEvery(actions.updateAssetTypeRequest, editAssetType),
  takeEvery(actions.deleteAssetTypeRequest, removeAssetType),
];

export default globalSettingsSagas;
