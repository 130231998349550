import styled from 'styled-components';
import { LoyaltyIcon } from 'components/Layout/Icons';

export const Container = styled.div`
  padding: 0 30px;
  font-size: 14px;
`;

export const ListItemContainer = styled.div`
  z-index: ${({ index }) => index} !important;
`;

export const SelectCheckobesContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: flex-start;
  margin-left: 35px;

  & > div {
    justify-content: flex-end;
    color: ${({ theme }) => theme.primaryGrey} !important;
  }
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const ListItem = styled.li`
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const ItemValue = styled.div`
  color: ${({ theme }) => theme.primaryDark};
  display: flex;
  align-items: center;
  width: 25%;
  word-break: break-word;
  text-align: start;
  justify-content: flex-end;
`;

export const Loyalty = styled(LoyaltyIcon)`
  color: ${({ theme }) => theme.darkGrey} !important;
`;
