import moment from 'moment';

/* eslint-disable max-len */
const formatTime = schedule => {
  if (schedule.ScheduleDto.ScheduleType === 'OneTime') {
    return schedule.ScheduleDto.ScheduleData.ScheduledDate;
  } else if (schedule.ScheduleDto.ScheduleType === 'RecurringDaily') {
    return `${moment(schedule.ScheduleDto.ScheduleData.DailyStartDate).format('L')} thru ${moment(
      schedule.ScheduleDto.ScheduleData.DailyRepeatUntil,
    ).format('L')} every
    ${schedule.ScheduleDto.ScheduleData.DailyRepeatInterval} day(s)`;
  } else if (schedule.ScheduleDto.ScheduleType === 'RecurringWeekly') {
    return `${schedule.ScheduleDto.ScheduleData.WeeklyStartDate} thru ${schedule.ScheduleDto.ScheduleData.WeeklyRepeatUntil} every
    ${schedule.ScheduleDto.ScheduleData.WeeklyRepeatInterval} week(s)`;
  } else if (schedule.ScheduleDto.ScheduleType === 'RecurringMonthly') {
    return `${schedule.ScheduleDto.ScheduleData.MonthlyStartDate} thru ${schedule.ScheduleDto.ScheduleData.MonthlyRepeatUntil} every
    ${schedule.ScheduleDto.ScheduleData.MonthlyRepeatInterval} month(s)`;
  } else if (schedule.ScheduleDto.ScheduleType === 'RecurringYearly') {
    return `${schedule.ScheduleDto.ScheduleData.YearlyStartDate} thru ${schedule.ScheduleDto.ScheduleData.YearlyRepeatUntil} every
    ${schedule.ScheduleDto.ScheduleData.YearlyRepeatInterval} year(s)`;
  }
  return null;
};

export const formatScheduleInfo = schedule => `${schedule.ScheduleDto.ScheduleType} || ${formatTime(schedule)} `;

export const format = () => ({});
