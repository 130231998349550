import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { bindActionCreators } from 'redux';

import { resizeWindow } from 'helpers';
import { SensorTypeType, SensorLevelType } from 'configs/propTypes';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as sensorActions } from 'redux/sensors';
import withInputModal from 'components/Dialogs/withInputModal';
import { ResetButton } from 'components/Dialogs/withInputModal/Controls/styled';
import { ButtonIcon } from 'components/Layout/Buttons';
import { RestoreIcon, NotificationOff } from 'components/Layout/Icons';

import LevelEscalateDialog from 'modules/settings/pages/SensorTypes/LevelEscalateDialog';
import LevelsQuantityDialog from 'modules/settings/pages/SensorTypes/LevelsQuantityDialog';

import LevelsList from './LevelsList';
import validate from './validator';
import { getSensorLevelsList, getSensorLevelsListForForm } from './selectors';
import * as S from './styled';

const config = {
  2: ['Alert'],
  3: ['Elevated', 'Alert'],
  '3Wh': ['Watch', 'Alert'],
  '3Wg': ['Warning', 'Alert'],
  4: ['Elevated', 'Watch', 'Alert'],
  '4EW': ['Elevated', 'Warning', 'Alert'],
  '4WW': ['Watch', 'Warning', 'Alert'],
  5: ['Elevated', 'Watch', 'Warning', 'Alert'],
};

class LevelSettingsDialogForm extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      reset: PropTypes.func.isRequired,
    }).isRequired,
    pristine: PropTypes.bool.isRequired,
    action: PropTypes.shape({
      openConfirmationDialog: PropTypes.func.isRequired,
      setSensorLevelAlertRequest: PropTypes.func.isRequired,
      getSensorLevelsSettingsRequest: PropTypes.func.isRequired,
    }).isRequired,
    isSettings: PropTypes.bool,
    levelsList: PropTypes.arrayOf(SensorLevelType).isRequired,
    selectedSensorType: SensorTypeType.isRequired,
    values: PropTypes.shape({
      MasterSensorTypeId: PropTypes.string,
      Notes: PropTypes.string,
      Type: PropTypes.string,
      escalation: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    isSettings: false,
  };

  state = {
    escalation: '',
    levelEscalateDialogOpened: false,
    levelsQuantityDialogOpened: false,
    levelsList: this.props.levelsList,
    AreLevelsDifferentFromTemplate: this.props.selectedSensorType.AreLevelsDifferentFromTemplate,
  };

  createNewLevel = LevelName => {
    const Id = Math.round(Math.random() * 1e6).toString();

    return {
      Id,
      [`${Id}LevelName`]: LevelName,
      [`${Id}StartData`]: '',
      [`${Id}OrderField`]: 0,
    };
  };

  closeLevelEscalateDialog = () => {
    this.setState({ levelEscalateDialogOpened: false });
  };

  openLevelEscalateDialog = () => {
    const { levelsList } = this.state;

    if (levelsList.length) {
      this.props.action.openConfirmationDialog(
        'Starting the configurator will delete the current settings!',
        () => this.setState({ levelEscalateDialogOpened: true, levelsList: [] }, () => resizeWindow()),
        'Warning',
      );
    } else {
      this.setState({ levelEscalateDialogOpened: true });
    }
  };

  closeLevelsQuantityDialog = () => {
    this.setState({ escalation: '', levelsQuantityDialogOpened: false });
  };

  openLevelsQuantityDialog = escalation => {
    this.setState({ escalation, levelEscalateDialogOpened: false, levelsQuantityDialogOpened: true });
  };

  submitLevelEscalateDialog = ({ escalation }) => {
    this.openLevelsQuantityDialog(escalation);
  };

  submitLevelsQuantityDialog = ({ options }) => {
    const {
      form,
      values: { MasterSensorTypeId, Notes, Type, ...levelValues },
    } = this.props;
    const { escalation } = this.state;

    const levelsList = [];
    let levelsForReset = {};

    let levelNamesList = [...config[options]];

    if (escalation === 'up' || escalation === 'both') {
      levelNamesList.reverse().push('Normal');

      if (escalation === 'both') {
        levelNamesList = [...levelNamesList, ...config[options]];
      }
    } else {
      levelNamesList.unshift('Normal');
    }

    levelNamesList.forEach((name, index) => {
      const newLevel = this.createNewLevel(name);
      newLevel[`${newLevel.Id}OrderField`] = index + 1;

      levelsList.push(newLevel);
    });

    levelsList.forEach(item => {
      levelsForReset = { ...levelsForReset, ...item };
    });

    Object.keys(levelValues).forEach(val => {
      if (val.endsWith('Original')) {
        const deletedId = val.slice(0, -8);

        levelsForReset[`${deletedId}Delete`] = deletedId;
      } else if (val.endsWith('Delete')) {
        levelsForReset[val] = val.slice(0, -6);
      }
    });

    form.reset({
      ...levelsForReset,
      escalation,
      MasterSensorTypeId,
      Notes,
      Type,
    });

    this.setState({ levelsQuantityDialogOpened: false, levelsList }, () => resizeWindow());
  };

  setTemplateValues = () => this.setState({ levelsList: this.props.levelsList });

  getTemplateValues = () => {
    const {
      values: { MasterSensorTypeId },
      action: { getSensorLevelsSettingsRequest },
      form,
    } = this.props;
    const { AreLevelsDifferentFromTemplate } = this.state;

    if (AreLevelsDifferentFromTemplate) {
      getSensorLevelsSettingsRequest({
        sensorData: { Id: MasterSensorTypeId },
        callback: this.setTemplateValues,
      });
    } else {
      form.reset();
    }
    this.setState({ AreLevelsDifferentFromTemplate: false });
  };

  render() {
    const {
      isSettings,
      selectedSensorType: { Type },
      values,
      pristine,
    } = this.props;
    const {
      escalation,
      levelEscalateDialogOpened,
      levelsQuantityDialogOpened,
      levelsList,
      AreLevelsDifferentFromTemplate,
    } = this.state;

    return (
      <>
        <S.Container>
          <Field component={S.TextField} name="Type" floatingLabelText="Sensor Template Name" />
          <Field component={S.TextField} name="Notes" floatingLabelText="Sensor Template Notes" />
          {!!levelsList.length && (
            <S.NotificationIconContainer>
              <NotificationOff />
            </S.NotificationIconContainer>
          )}
          {levelsList.length ? (
            <LevelsList
              deleteLevel={this.deleteLevel}
              isSettings={isSettings}
              levelsList={levelsList}
              isBoth={values.escalation === 'both'}
            />
          ) : (
            <S.EmptyList>It would be great if sensor levels were added here.</S.EmptyList>
          )}
          {isSettings ? (
            <ButtonIcon onClick={this.openLevelEscalateDialog} tooltip="Configurator">
              <S.IconSettings />
            </ButtonIcon>
          ) : (
            <ResetButton
              type="button"
              label="Reset to Template Settings"
              position
              onClick={this.getTemplateValues}
              disabled={!AreLevelsDifferentFromTemplate && pristine}
            >
              <RestoreIcon />
            </ResetButton>
          )}
        </S.Container>
        <LevelEscalateDialog
          handleClose={this.closeLevelEscalateDialog}
          title={Type}
          submitLabel="Save / Next"
          open={levelEscalateDialogOpened}
          onSubmit={this.submitLevelEscalateDialog}
          withoutTopBorder
        />
        <LevelsQuantityDialog
          handleClose={this.closeLevelsQuantityDialog}
          title={Type}
          submitLabel="Save / Next"
          open={levelsQuantityDialogOpened}
          onSubmit={this.submitLevelsQuantityDialog}
          escalation={escalation}
          withoutTopBorder
        />
      </>
    );
  }
}

const LevelSettingsDialogWithInput = withInputModal(LevelSettingsDialogForm);

const LevelSettingsDialog = props => (
  <LevelSettingsDialogWithInput
    initialValues={{
      MasterSensorTypeId: props.selectedSensorType.Id,
      Notes: props.selectedSensorType.Notes,
      Type: props.selectedSensorType.Type,
      ...props.sensorLevels,
    }}
    validate={validate}
    {...props}
  />
);

LevelSettingsDialog.propTypes = {
  selectedSensorType: SensorTypeType.isRequired,
  sensorLevels: PropTypes.shape({}).isRequired,
};

LevelSettingsDialog.defaultProps = {
  selectedSensorType: {},
};

const mapStateToProps = ({ sensors: { sensorLevels } }) => ({
  levelsList: getSensorLevelsList(sensorLevels),
  sensorLevels: getSensorLevelsListForForm(sensorLevels),
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      openConfirmationDialog,
      getSensorLevelsSettingsRequest: sensorActions.getSensorLevelsSettingsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LevelSettingsDialog);
