import { createSelector } from 'reselect';

const getGatewaysList = gateways => gateways.list;
const getSitesList = (gateways, user) => user.sitesAvailableToUserFullInfo;

export const getGatewaysListWithSiteName = createSelector([getGatewaysList, getSitesList], (gateways, sites) =>
  gateways.map(gateway => {
    const site = sites.find(item => item.id === gateway.SiteID);

    gateway.siteName = site ? `${site.additionalId.substring(0, 5)} ${site.name}` : '';
    return gateway;
  }),
);

export const selector = () => {};
