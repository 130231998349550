import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';

import * as S from './styled';

const NotifyForm = ({ onSubmit, manager, assignee }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Field
          name="message"
          floatingLabelText="Message:"
          component={TextFieldAdapter}
          hintText="your message"
          multiLine
          floatingLabelFixed
          floatingLabelStyle={S.textFieldStyles.floatingLabelStyle}
          floatingLabelShrinkStyle={S.textFieldStyles.floatingLabelShrinkStyle}
        />
        <S.FormFooter>
          <div>
            {manager && (
              <Field
                name="manager"
                label="Manager Assigned To Task"
                component={CheckboxAdapter}
                iconStyle={S.checkBoxStyle.iconStyle}
                style={S.checkBoxStyle.style}
              />
            )}
            {assignee && (
              <Field
                name="assignee"
                label="Person Assigned To Task"
                component={CheckboxAdapter}
                iconStyle={S.checkBoxStyle.iconStyle}
                style={S.checkBoxStyle.style}
              />
            )}
          </div>
          <S.Button type="submit">send e-mail</S.Button>
        </S.FormFooter>
      </form>
    )}
  />
);

NotifyForm.defaultProps = {
  manager: '',
  assignee: '',
};

NotifyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  manager: PropTypes.string,
  assignee: PropTypes.string,
};

export default NotifyForm;
