import styled from 'styled-components';
import { Flex } from 'grid-styled';

import logoImage from 'components/Layout/Images/logo-dark.png';

const Container = styled.section`
  min-height: 100vh;
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Content = styled(Flex).attrs({
  column: true,
  py: 1,
  w: [0.8, 0.65, 0.5, 0.4, 0.35],
})`
  position: relative;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    transform: scale(0.75);
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const Logo = styled.img.attrs({
  src: logoImage,
  alt: 'InCheq logo',
})`
  height: 80px;
`;

const Select = styled.select`
  width: fill-available;
  border-radius: 0 0 10px 10px;
  padding: 10px 40px 10px 6px;
  border: ${({ theme, meta }) => `1px solid ${meta.error && meta.touched ? theme.primaryRed : theme.primaryGrey}`};
  background: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryBlack};
`;

const SubmitButton = styled.button`
  width: fill-available;
  height: 50px;
  background-color: ${({ theme }) => theme.loginButtonColor};
  border: ${({ theme }) => `5px double ${theme.primaryWhite}`};
  color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  align-self: flex-end;
  cursor: pointer;
  margin-top: 15px;

  &:disabled {
    cursor: not-allowed;
  }
`;

export { Container, Content, LogoContainer, Logo, Select, SubmitButton };
