import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import theme from 'theme';
import { FavoriteIcon, FavoriteBorderIcon } from 'components/Layout/Icons';

import { CheckboxButton } from './styled';

const FavoriteSite = ({ isFavorite, site }) => (
  <Field type="checkbox" value={site.siteId} name="siteIds">
    {({ input: { checked, checkedIcon, uncheckedIcon, onChange, ...rest } }) => (
      <CheckboxButton
        label={site.siteName}
        checked={checked}
        checkedIcon={isFavorite ? <FavoriteIcon /> : checkedIcon}
        uncheckedIcon={isFavorite ? <FavoriteBorderIcon /> : uncheckedIcon}
        onCheck={onChange}
        iconStyle={{ fill: theme.darkGrey }}
        {...rest}
      />
    )}
  </Field>
);

FavoriteSite.propTypes = {
  isFavorite: PropTypes.bool,
  site: PropTypes.shape({
    siteName: PropTypes.string.isRequired,
    siteId: PropTypes.string.isRequired,
  }).isRequired,
};

FavoriteSite.defaultProps = {
  isFavorite: false,
};

export default FavoriteSite;
