import http, { baseURL } from '../index';

export const fetchUnit = unitId => http.post('/Unit/Get/', `id=${unitId}`);

export const fetchUnitItemPdfs = itemId => http.post('/ItemPdf/List', `parentId=${itemId}`);

export const fetchUnitItemPictures = itemId => http.post('/ItemPicture/List', `parentId=${itemId}`);

export const fetchUnitItems = (unitId, SiteID) => http.post(`/Item/List?siteId=${SiteID}`, { unitId });

export const fetchUnitItemTaskTemplates = itemId => http.post('/TaskTemplate/List', `itemId=${itemId}`);

export const fetchUnitPdfs = unitId => http.post('/UnitPdf/List', `parentId=${unitId}`);

export const fetchUnitPictures = unitId => http.post('/UnitPicture/List', `parentId=${unitId}`);

export const postUnitItem = dto => http.post('/Item/Post', { dto });

export const removeTaskTemplate = (taskId, itemId) =>
  http.post('/TaskTemplate/Delete', `id=${taskId}&itemId=${itemId}`);

export const removeUnitItem = (payload, unitId) => http.post('/Item/Delete', `id=${payload}&unitId=${unitId}`);

export const removeUnit = dto => http.post('/Unit/Delete', `id=${dto}`);

export const removeUnitItemPdf = (pdfId, pdfName, itemId) =>
  http.post('/ItemPdf/Delete', `id=${pdfId}&name=${pdfName}&parentId=${itemId}`);

export const removeUnitItemPicture = (pictureId, pictureName, itemId) =>
  http.post('/ItemPicture/Delete', `id=${pictureId}&name=${pictureName}&parentId=${itemId}`);

export const removeUnitPdf = (pdfId, pdfName, unitId) =>
  http.post('/UnitPdf/Delete', `id=${pdfId}&name=${pdfName}&parentId=${unitId}`);

export const removeUnitPicture = (pictureId, pictureName, unitId) =>
  http.post('/UnitPicture/Delete', `id=${pictureId}&name=${pictureName}&parentId=${unitId}`);

export const duplicateItem = (Id, unitId, encodedName, NewSequence) =>
  http.post(`/Item/Duplicate?itemId=${Id}&unitId=${unitId}&name=${encodedName}&sequence=${NewSequence}`);

export const putUnit = dto => http.post(`/Unit/Put/${dto.Id}`, { dto });

export const putUnitItem = (payload, dto) => http.post(`/Item/Put/${payload.Id}`, { dto });

export const postUnitItemPdf = (itemId, dto) => http.post(`/ItemPdf/Post/${itemId}`, dto);

export const postUnitItemPicture = (itemId, dto) => http.post(`/ItemPicture/Post/${itemId}`, dto);

export const postUnitPdf = (unitId, dto) => http.post(`/UnitPdf/Post/${unitId}`, dto);

export const postUnitPicture = (unitId, dto) => http.post(`/UnitPicture/Post/${unitId}`, dto);

export const fetchDefaultBarcode = params => http.post('/Unit/GetDefaultBarcodeValue', { ...params });

export const fetchUnitItemsTasks = Id => http.post(`/Unit/GetItemListWithTasks/?unitId=${Id}`);

export const updateUnitItemsSequence = dtos => http.post('/Item/UpdateItemsSequence', { dtos });

export const getImageSource = picture =>
  `${http.defaults.baseURL}` +
  `/ItemPicture/Get/?id=${picture.Id}` +
  `&parentId=${picture.ItemId}&width=200&height=200&inline=true&siteId=${http.defaults.siteId}`;

export const exportUnitQR = unitId => {
  window.open(`${baseURL}/UnitBarcode/Get?unitId=${unitId}&siteId=${http.defaults.siteId}&height=&width=&inline=true`);
};

export const getQRSource = itemId => {
  const timestamp = Date.now();
  window.open(
    `${baseURL}/ItemBarcode/Get` +
      `?itemId=${itemId}&siteId=${http.defaults.siteId}&width=200&height=200&inline=true&_dc=${timestamp}`,
  );
};

export const exportItemQR = itemId => {
  window.open(`${baseURL}/ItemBarcode/Get?itemId=${itemId}&siteId=${http.defaults.siteId}&height=&width=&inline=true`);
};

export const exportUnitPdf = (id, unitId, inline = false) => {
  window.open(`${baseURL}/UnitPdf/Get?id=${id}&parentId=${unitId}&siteId=${http.defaults.siteId}&inline=${inline}`);
};

export const exportUnitItemPdf = (id, itemId, inline = false) => {
  window.open(`${baseURL}/ItemPdf/Get?id=${id}&parentId=${itemId}&siteId=${http.defaults.siteId}&inline=${inline}`);
};

export const getUnitImage = picture =>
  `${http.defaults.baseURL}` +
  `/UnitPicture/Get/?id=${picture.Id}` +
  `&parentId=${picture.UnitId}&siteId=${http.defaults.siteId}&width=200&height=200&inline=true`;
