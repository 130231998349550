import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { actions as partsActions } from 'redux/parts';
import { commitUpload, abortUpload } from 'http/parts';
import BaseDialog from 'components/Dialogs/Base';

import * as S from './styled';

import Upload from './Upload';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import { useModal } from '@ebay/nice-modal-react';

const styles = {
  modal: {
    maxWidth: '500px',
  },
};

const UploadPartsDialog = ({ open, onRequestClose }) => {
  const [isUploadFinished, setIsUploadFinished] = useState(false);
  const [temproraryId, setTemproraryId] = useState(null);
  const dispatch = useDispatch();

  const confirmationModal = useModal(ConfirmationModal);

  const commit = () => {
    commitUpload(temproraryId).then(() => {
      onRequestClose();
      dispatch(partsActions.getPartsRequest());
    });
  };

  const abort = () => {
    abortUpload(temproraryId).then(() => {
      onRequestClose();
    });
  };

  const handleConfirmationApprove = () => abort();

  const handleConfirmationReject = () => confirmationModal.hide();

  const handleAbortPress = () => {
    confirmationModal.show({
      title: 'Warning',
      text: (
        <S.Text>
          Are you sure your want to abort? <br /> All Data will be removed
        </S.Text>
      ),
      label: 'Return',
      labelCancel: 'Abort Upload',
      onClick: handleConfirmationReject,
      onCancelClick: handleConfirmationApprove,
      isNext: true,
      maxWidth: '650px',
    });
  };

  const handleWarningDialogClose = () => confirmationModal.hide();

  // eslint-disable-next-line no-shadow
  const onUploadStateChanged = (isUploadFinished, info) => {
    setTemproraryId(info.PartTemplateTemporaryId);

    if (info.HadErrors) {
      confirmationModal.show({
        onClick: handleWarningDialogClose,
        text: (
          <S.Text>
            The process has failed <br /> Please check the file and try again
          </S.Text>
        ),
        label: 'OK',
        title: 'Warning',
        isNext: true,
        maxWidth: '650px',
      });

      setIsUploadFinished(false);
    }

    if (!info.HadErrors && isUploadFinished) {
      setIsUploadFinished(true);
    }
  };

  return (
    <BaseDialog
      open={open}
      title="Upload Parts List"
      modalStyles={styles.modal}
      onRequestClose={onRequestClose}
      repositionOnUpdate
    >
      <S.Container>
        <Upload onUploadStateChanged={onUploadStateChanged} />
      </S.Container>
      <ActionsContainer gapValue={10}>
        <ActionButton
          type="button"
          label="Abort"
          disabled={!isUploadFinished}
          isDisable={!isUploadFinished}
          onClick={handleAbortPress}
        />
        <ActionButton
          type="button"
          label="Commit"
          disabled={!isUploadFinished}
          isDisable={!isUploadFinished}
          onClick={commit}
          isNext
        />
      </ActionsContainer>
    </BaseDialog>
  );
};

UploadPartsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default UploadPartsDialog;
