/* eslint-disable react/require-default-props */
import React, { HTMLProps } from 'react';
import { FieldMetaProps, useField } from 'formik';
import { FlattenInterpolation, ThemeProps } from 'styled-components';

import * as S from './TextareaStyles';

type DefaultTextareaPropsType = HTMLProps<HTMLTextAreaElement>;

type PropsType = DefaultTextareaPropsType & {
  styles?: FlattenInterpolation<ThemeProps<any>>[];
  name: string;
  isDisabled?: boolean;
};

type TextareaPropsType<Value> = PropsType & {
  meta: FieldMetaProps<Value>;
};

export const Textarea = <Value,>({
  name,
  value,
  placeholder,
  onChange,
  styles,
  meta,
  onBlur,
  isDisabled,
}: TextareaPropsType<Value>) => {
  const hasError = meta?.touched && meta?.error;

  return (
    <div>
      <S.TextareaField
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        styles={styles}
        onBlur={onBlur}
        hasError={!!hasError}
        disabled={isDisabled}
      />
      {hasError ? <S.ErrorMessage>{meta.error}</S.ErrorMessage> : null}
    </div>
  );
};

export const TextareaWithState = ({ name, ...restProps }: PropsType) => {
  const [field, meta] = useField(name);

  return <Textarea {...field} {...restProps} name={name} meta={meta} />;
};

Textarea.defaultProps = {
  styles: [],
  isDisabled: false,
};
