import styled from 'styled-components';
import { FieldRadio } from 'components/Layout/Inputs';

export const Container = styled.div`
  padding: 0 30px;
  overflow: auto;
  max-height: 25vh;
`;

export const ListItemContainer = styled.div`
  z-index: ${({ index }) => index} !important;
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const ListItem = styled.li`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.primaryGrey : theme.primaryDark)};
  min-height: 48px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:last-child {
    justify-content: center;
  }
`;

export const ItemNameAndCheckbox = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
  text-align: left;
  flex: 1;
`;

export const SelectionItems = styled.div`
  display: grid;
  word-break: break-word;
  width: 100%;
  grid-template-columns: 50% 50%;
  justify-items: start;
`;

export const ItemRadio = styled(FieldRadio).attrs({
  iconStyle: ({ theme, isRed }) => ({
    fill: isRed && theme.mainRed,
    marginRight: '50px',
  }),
})`
  width: auto !important;
`;
