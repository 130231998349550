import React, { useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'components/Modal';
import { ActionButton } from 'components/Dialogs/v1/Base';

import { useDispatch } from 'react-redux';
import { actions as itemActions } from 'redux/item';
import { actions as unitActions } from 'redux/unit';
import { useAppSelector } from 'redux/reducers';
import { exportUnitItemPdf, exportUnitPdf } from 'http/unit';
import { ItemPdfType } from 'types/task';

import * as S from './PdfsDialogStyles';
import theme from 'theme';

type PropsType = {
  itemId?: string;
  unitId?: string;
  type: 'unit' | 'item';
};

export const PdfsDialog = NiceModal.create(({ itemId, unitId, type }: PropsType) => {
  const modal = useModal();
  const pdfs: ItemPdfType[] = useAppSelector(state => state.item.pdfs);
  const unitPdfsList = useAppSelector(state => state.unit.unitPdfsList);
  const dispatch = useDispatch();
  const data = type === 'unit' ? unitPdfsList : pdfs;

  useEffect(() => {
    if (itemId) {
      dispatch(itemActions.fetchItemPdfsRequest({ itemId }));
    }

    if (unitId) {
      dispatch(unitActions.getUnitPdfRequest({ unitId }));
    }
  }, [dispatch, itemId, unitId]);

  return (
    <Modal
      isOpen={modal.visible}
      title="Item PDF's"
      onRequestClose={modal.hide}
      titleColor={theme.primaryDark}
      onAfterClose={modal.remove}
    >
      <S.RootContainer>
        <S.FieldWrapper>
          <S.Field>Available PDF&apos;s</S.Field>
          <S.Field>In browser view</S.Field>
        </S.FieldWrapper>
        {unitPdfsList.length ? (
          <S.List>
            {data.map(pdf => (
              <S.ListItem>
                <S.Name>{pdf.Name}</S.Name>
                <S.IconWrapper
                  onClick={() => {
                    if (unitId) {
                      exportUnitPdf(pdf.Id, pdf.UnitId, true);
                    } else {
                      exportUnitItemPdf(pdf.Id, pdf.ItemId, true);
                    }
                  }}
                >
                  <S.EyeIcon />
                </S.IconWrapper>
              </S.ListItem>
            ))}
          </S.List>
        ) : (
          <S.EmptyText>There are no PDFs, yet.</S.EmptyText>
        )}
        <ActionButton type="button" isNext isCancelNext label="Cancel" onClick={modal.hide} />
      </S.RootContainer>
    </Modal>
  );
});
