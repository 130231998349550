export const validator = values => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }
  if (!values.FirstName) {
    errors.FirstName = 'Required';
  }
  if (!values.LastName) {
    errors.LastName = 'Required';
  }

  if (!values.Email) {
    errors.Email = 'Required';
  } else if (!/[\w_-]+@[\w_\-.]+\.([A-Za-z]{2,10})$/.test(values.Email.trim())) {
    errors.Email = 'Please enter a valid email address';
  }

  if (!values.Id && !values.SubmittedPassword) {
    errors.SubmittedPassword = 'Required';
  } else if (values.SubmittedPassword && !values.SubmittedPassword.match(/^[A-Za-z0-9!@#$%^&*()_+-=.?\s]*$/)) {
    errors.SubmittedPassword =
      'Password must contain only english alphabet characters, spaces, ' +
      'numbers or characters from this enum: "! @ # $ % ^ & * ( ) _ + - = . ?"';
  } else if (values.SubmittedPassword && values.SubmittedPassword.length < 8) {
    errors.SubmittedPassword = 'Password must be at least 8 characters long';
  }

  return errors;
};

export default validator;
