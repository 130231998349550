import styled from 'styled-components';

export const Container = styled.div`
  form {
    & > div:last-child {
      padding: 15px !important;

      & > button:first-child {
        margin-right: 10px !important;
      }

      & > button:last-child {
        margin-left: 10px !important;
      }
    }
  }
`;
