import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { BorderRadiusContainer } from 'components/Layout/Containers';

export const Container = styled.div`
  margin: 10px;
`;

export const Block = styled(BorderRadiusContainer)``;

export const Label = styled(Flex).attrs({
  p: 2,
  justify: 'space-between',
  align: 'center',
})`
  background-color: #02488f;
  color: ${({ theme }) => theme.primaryWhite};
`;
