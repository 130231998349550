import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import { InfoIcon } from 'components/Layout/Icons';
import { Dialog, ActionButton } from 'components/Dialogs/v1/Base';
import InnerCard from 'components/Layout/InnerCard';
import { actions as settingsActions } from 'redux/settings';

import theme from 'theme';

import * as S from './styled';
import { PEOPLE_RADIO_BUTTONS, TASKS_RADIO_BUTTONS } from './config';

const STYLES = {
  radioIcon: {
    fill: theme.mainRed,
  },
  radioButtonGroup: {
    display: 'flex',
  },
  radioLabel: {
    fontSize: 15,
  },
};

class GlobalScheduleTriggerDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      requestSetSettings: PropTypes.func.isRequired,
    }).isRequired,
    settings: PropTypes.shape({
      PeopleSchedulesTrigger: PropTypes.number,
      TasksSchedulesTrigger: PropTypes.number,
    }).isRequired,
  };

  state = {
    PeopleSchedulesTrigger: 0,
    TasksSchedulesTrigger: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { PeopleSchedulesTrigger, TasksSchedulesTrigger } = this.props.settings;

    if (nextProps.open && !this.props.open) {
      this.setState({ PeopleSchedulesTrigger, TasksSchedulesTrigger });
    }
  }

  handleRadioChange = type => (event, value) => this.setState({ [type]: value });
  handlePeopleRadioChange = this.handleRadioChange('PeopleSchedulesTrigger');
  handleTasksRadioChange = this.handleRadioChange('TasksSchedulesTrigger');

  handleSave = event => {
    event.preventDefault();
    const { settings, actions, handleClose } = this.props;
    const { TasksSchedulesTrigger, PeopleSchedulesTrigger } = this.state;

    actions.requestSetSettings({
      isMaster: false,
      values: { ...settings, TasksSchedulesTrigger, PeopleSchedulesTrigger },
    });
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { PeopleSchedulesTrigger, TasksSchedulesTrigger } = this.state;

    return (
      <Dialog
        open={open}
        onRequestClose={handleClose}
        title="Global Schedule Trigger"
        titleColor={theme.mainRed}
        modalStyles={{ maxWidth: '940px' }}
      >
        <S.Container>
          <S.Description>
            <S.DescriptionText>Select default global settings for all Sites in the organization</S.DescriptionText>
            <S.Icon
              tooltip="The system will schedule tasks or people one or more days before the actual events start.
                This is done so users can see the tasks and schedules ahead of time."
              tooltipStyles={{ width: 120 }}
              tooltipPosition="center-left"
            >
              <InfoIcon />
            </S.Icon>
          </S.Description>
          <InnerCard title="People Schedules Trigger">
            <S.RadioGroup>
              <S.DescriptionTextWithMarging>
                Default People Schedules will be executed in advance:
              </S.DescriptionTextWithMarging>
              <RadioButtonGroup
                name="PeopleSchedulesTrigger"
                onChange={this.handlePeopleRadioChange}
                valueSelected={PeopleSchedulesTrigger}
                style={STYLES.radioButtonGroup}
              >
                {PEOPLE_RADIO_BUTTONS.map(({ label, value }) => (
                  <RadioButton
                    key={value}
                    iconStyle={STYLES.radioIcon}
                    label={label}
                    value={value}
                    labelStyle={STYLES.radioLabel}
                  />
                ))}
              </RadioButtonGroup>
            </S.RadioGroup>
          </InnerCard>
          <S.Spacer />
          <InnerCard title="Tasks Schedules Trigger">
            <S.RadioGroup>
              <S.DescriptionTextWithMarging>
                Default Task Schedules will be executed in advance:
              </S.DescriptionTextWithMarging>
              <RadioButtonGroup
                name="TasksSchedulesTrigger"
                onChange={this.handleTasksRadioChange}
                valueSelected={TasksSchedulesTrigger}
                style={STYLES.radioButtonGroup}
              >
                {TASKS_RADIO_BUTTONS.map(({ label, value }) => (
                  <RadioButton
                    key={value}
                    iconStyle={STYLES.radioIcon}
                    labelStyle={STYLES.radioLabel}
                    label={label}
                    value={value}
                  />
                ))}
              </RadioButtonGroup>
            </S.RadioGroup>
          </InnerCard>
          <S.Spacer />
          <ActionButton label="Save" isNext onClick={this.handleSave} />
        </S.Container>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ settings }) => ({
  settings: settings.list,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      requestSetSettings: settingsActions.setSettingsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalScheduleTriggerDialog);
