import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SEND_SITE_SCHEDULE_REQUEST: undefined,
  SEND_SITE_SCHEDULE_SUCCESS: undefined,
  SEND_SITE_SCHEDULE_FAILURE: undefined,

  GET_SHORTCUTS_REQUEST: undefined,
  GET_SHORTCUTS_SUCCESS: undefined,
  GET_SHORTCUTS_FAILURE: undefined,

  GET_SINGLE_SHORTCUT_REQUEST: undefined,
  GET_SINGLE_SHORTCUT_SUCCESS: undefined,
  GET_SINGLE_SHORTCUT_FAILURE: undefined,

  GET_SHORTCUT_SITES_REQUEST: undefined,
  GET_SHORTCUT_SITES_SUCCESS: undefined,
  GET_SHORTCUT_SITES_FAILURE: undefined,

  GET_SWITCH_AUTORUN_REQUEST: undefined,
  GET_SWITCH_AUTORUN_SUCCESS: undefined,
  GET_SWITCH_AUTORUN_FAILURE: undefined,

  GET_RUN_NEXT_PERIOD_REQUEST: undefined,
  GET_RUN_NEXT_PERIOD_SUCCESS: undefined,
  GET_RUN_NEXT_PERIOD_FAILURE: undefined,

  CREATE_CHECKLISTS_REQUEST: undefined,
  CREATE_CHECKLISTS_SUCCESS: undefined,
  CREATE_CHECKLISTS_FAILURE: undefined,

  EDIT_CHECKLIST_REQUEST: undefined,
  EDIT_CHECKLIST_SUCCESS: undefined,
  EDIT_CHECKLIST_FAILURE: undefined,

  CREATE_SHORTCUT_REQUEST: undefined,
  CREATE_SHORTCUT_SUCCESS: undefined,
  CREATE_SHORTCUT_FAILURE: undefined,

  EDIT_SHORTCUT_REQUEST: undefined,
  EDIT_SHORTCUT_SUCCESS: undefined,
  EDIT_SHORTCUT_FAILURE: undefined,

  GET_SITE_SCHEDULE_REQUEST: undefined,
  GET_SITE_SCHEDULE_SUCCESS: undefined,
  GET_SITE_SCHEDULE_FAILURE: undefined,

  GET_LIST_SCHEDULE_REQUEST: undefined,
  GET_LIST_SCHEDULE_SUCCESS: undefined,
  GET_LIST_SCHEDULE_FAILURE: undefined,

  EDIT_SITE_SCHEDULE_REQUEST: undefined,
  EDIT_SITE_SCHEDULE_SUCCESS: undefined,
  EDIT_SITE_SCHEDULE_FAILURE: undefined,

  SAVE_SHORTCUT_OPTIONS_REQUEST: undefined,
  SAVE_SHORTCUT_OPTIONS_SUCCESS: undefined,
  SAVE_SHORTCUT_OPTIONS_FAILURE: undefined,

  SEND_LOCATION_OPTIONS_REQUEST: undefined,
  SEND_LOCATION_OPTIONS_SUCCESS: undefined,
  SEND_LOCATION_OPTIONS_FAILURE: undefined,

  SEND_UNIT_OPTIONS_REQUEST: undefined,
  SEND_UNIT_OPTIONS_SUCCESS: undefined,
  SEND_UNIT_OPTIONS_FAILURE: undefined,

  SEND_ITEM_OPTIONS_REQUEST: undefined,
  SEND_ITEM_OPTIONS_SUCCESS: undefined,
  SEND_ITEM_OPTIONS_FAILURE: undefined,

  DELETE_SHORTCUT_REQUEST: undefined,
  DELETE_SHORTCUT_SUCCESS: undefined,
  DELETE_SHORTCUT_FAILURE: undefined,

  SEND_MANAGEMENT_OPTIONS_REQUEST: undefined,
  SEND_MANAGEMENT_OPTIONS_SUCCESS: undefined,
  SEND_MANAGEMENT_OPTIONS_FAILURE: undefined,

  SEND_PERSON_OPTIONS_REQUEST: undefined,
  SEND_PERSON_OPTIONS_SUCCESS: undefined,
  SEND_PERSON_OPTIONS_FAILURE: undefined,

  SEND_TEAM_OPTIONS_REQUEST: undefined,
  SEND_TEAM_OPTIONS_SUCCESS: undefined,
  SEND_TEAM_OPTIONS_FAILURE: undefined,

  LIST_STATUS_REQUEST: undefined,
  LIST_STATUS_SUCCESS: undefined,
  LIST_STATUS_FAILURE: undefined,

  DELETE_SITE_SCHEDULE: undefined,
  DELETE_PERSON_OPTIONS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.sendSiteScheduleRequest, handlers.sendSiteScheduleRequest],
    [actions.sendSiteScheduleSuccess, handlers.sendSiteScheduleSuccess],
    [actions.sendSiteScheduleFailure, handlers.sendSiteScheduleFailure],

    [actions.getSingleShortcutRequest, handlers.getSingleShortcutRequest],
    [actions.getSingleShortcutSuccess, handlers.getSingleShortcutSuccess],
    [actions.getSinglehortcutFailure, handlers.getSingleShortcutFailure],

    [actions.getShortcutsRequest, handlers.getShortcutsRequest],
    [actions.getShortcutsSuccess, handlers.getShortcutsSuccess],
    [actions.getShortcutsFailure, handlers.getShortcutsFailure],

    [actions.getShortcutSitesRequest, handlers.getShortcutSitesRequest],
    [actions.getShortcutSitesSuccess, handlers.getShortcutSitesSuccess],
    [actions.getShortcutSitesFailure, handlers.getShortcutSitesFailure],

    [actions.getSwitchAutorunRequest, handlers.getSwitchAutorunRequest],
    [actions.getSwitchAutorunSuccess, handlers.getSwitchAutorunSuccess],
    [actions.getSwitchAutorunFailure, handlers.getSwitchAutorunFailure],

    [actions.getRunNextPeriodRequest, handlers.getRunNextPeriodRequest],
    [actions.getRunNextPeriodSuccess, handlers.getRunNextPeriodSuccess],
    [actions.getRunNextPeriodFailure, handlers.getRunNextPeriodFailure],

    [actions.createChecklistsRequest, handlers.createChecklistsRequest],
    [actions.createChecklistsSuccess, handlers.createChecklistsSuccess],
    [actions.createChecklistsFailure, handlers.createChecklistsFailure],

    [actions.editChecklistRequest, handlers.editChecklistRequest],
    [actions.editChecklistSuccess, handlers.editChecklistSuccess],
    [actions.editChecklistFailure, handlers.editChecklistFailure],

    [actions.createShortcutRequest, handlers.createShortcutRequest],
    [actions.createShortcutSuccess, handlers.createShortcutSuccess],
    [actions.createShortcutFailure, handlers.createShortcutFailure],

    [actions.getSiteScheduleRequest, handlers.getSiteScheduleRequest],
    [actions.getSiteScheduleSuccess, handlers.getSiteScheduleSuccess],
    [actions.getSiteScheduleFailure, handlers.getSiteScheduleFailure],

    [actions.editShortcutRequest, handlers.editShortcutRequest],
    [actions.editShortcutSuccess, handlers.editShortcutSuccess],
    [actions.editShortcutFailure, handlers.editShortcutFailure],

    [actions.getListScheduleRequest, handlers.getListScheduleRequest],
    [actions.getListScheduleSuccess, handlers.getListScheduleSuccess],
    [actions.getListScheduleFailure, handlers.getListScheduleFailure],

    [actions.editSiteScheduleRequest, handlers.editSiteScheduleRequest],
    [actions.editSiteScheduleSuccess, handlers.editSiteScheduleSuccess],
    [actions.editSiteScheduleFailure, handlers.editSiteScheduleFailure],

    [actions.saveShortcutOptionsRequest, handlers.saveShortcutOptionsRequest],
    [actions.saveShortcutOptionsSuccess, handlers.saveShortcutOptionsSuccess],
    [actions.saveShortcutOptionsFailure, handlers.saveShortcutOptionsFailure],

    [actions.sendLocationOptionsRequest, handlers.sendLocationOptionsRequest],
    [actions.sendLocationOptionsSuccess, handlers.sendLocationOptionsSuccess],
    [actions.sendLocationOptionsFailure, handlers.sendLocationOptionsFailure],

    [actions.sendUnitOptionsRequest, handlers.sendUnitOptionsRequest],
    [actions.sendUnitOptionsSuccess, handlers.sendUnitOptionsSuccess],
    [actions.sendUnitOptionsFailure, handlers.sendUnitOptionsFailure],

    [actions.sendItemOptionsRequest, handlers.sendItemOptionsRequest],
    [actions.sendItemOptionsSuccess, handlers.sendItemOptionsSuccess],
    [actions.sendItemOptionsFailure, handlers.sendItemOptionsFailure],

    [actions.deleteShortcutRequest, handlers.deleteShortcutRequest],
    [actions.deleteShortcutSuccess, handlers.deleteShortcutSuccess],
    [actions.deleteShortcutFailure, handlers.deleteShortcutFailure],

    [actions.sendManagementOptionsRequest, handlers.sendManagementOptionsRequest],
    [actions.sendManagementOptionsSuccess, handlers.sendManagementOptionsSuccess],
    [actions.sendManagementOptionsFailure, handlers.sendManagementOptionsFailure],

    [actions.sendPersonOptionsRequest, handlers.sendPersonOptionsRequest],
    [actions.sendPersonOptionsSuccess, handlers.sendPersonOptionsSuccess],
    [actions.sendPersonOptionsFailure, handlers.sendPersonOptionsFailure],

    [actions.sendTeamOptionsRequest, handlers.sendTeamOptionsRequest],
    [actions.sendTeamOptionsSuccess, handlers.sendTeamOptionsSuccess],
    [actions.sendTeamOptionsFailure, handlers.sendTeamOptionsFailure],

    [actions.listStatusRequest, handlers.listStatusRequest],
    [actions.listStatusSuccess, handlers.listStatusSuccess],
    [actions.listStatusFailure, handlers.listStatusFailure],

    [actions.deleteSiteSchedule, handlers.deleteSiteSchedule],
    [actions.deletePersonOptions, handlers.deletePersonOptions],
  ]),
  initialState,
);

export default reducer;
