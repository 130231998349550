import { call, put, takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';

import { formatServerError } from 'helpers';
import {
  createPerson,
  editUser,
  editUserSites,
  getAssignedSites,
  getPeopleList,
  removePerson,
  sendLoginEmail,
  setNotificationStatus,
} from 'http/people';
import { getAvailabilityOptions, getCanDeleteOption, getJobsOptions } from 'http/system';

import { actions } from './index';
import { formatPerson } from './formatters';
import { addSpacingForNotifications } from '../settings/helpers';
import { teamUnassignedManager } from 'http/teams';

export function* fetchPeopleList() {
  try {
    const { data } = yield call(getPeopleList);
    yield put(actions.peopleListSuccess(data.root));
  } catch (error) {
    yield put(
      actions.peopleListFailure({
        error,
        message: formatServerError(error, 'An error occurred while get list of the Persons.'),
      }),
    );
  }
}

export function* addPerson({ payload }) {
  try {
    const params = queryString.stringify(formatPerson(payload));
    const { data } = yield call(createPerson, params);
    yield put(actions.addPersonSuccess(data.entity));
    yield* fetchPeopleList();
  } catch (error) {
    yield put(
      actions.addPersonFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Person.'),
      }),
    );
  }
}

export function* deletePerson({ payload }) {
  const { personId, type, cb } = payload;
  try {
    yield call(removePerson, personId, type);
    yield* fetchPeopleList();
    cb(true);
  } catch (error) {
    cb(false);
    yield put(
      actions.deletePersonFailure({
        error,
        message: formatServerError(error, 'An error occurred while deleting the Person.'),
      }),
    );
  }
}

export function* unassignedManager({ payload }) {
  try {
    const { personId, type, cb } = payload;
    yield call(teamUnassignedManager, personId, type);
    cb(true);
    yield* fetchPeopleList();
  } catch (error) {
    const { cb } = payload;
    cb(false);
    yield put(
      actions.unassignedManagerFailure({
        error,
        message: formatServerError(error, 'An error occurred while deleting the Person.'),
      }),
    );
  }
}

export function* editPerson({ payload }) {
  try {
    const { personData, type } = payload;
    personData.UserType = personData.UserType.replaceAll(' ', '');
    const params = queryString.stringify(formatPerson(personData, type));
    const { data } = yield call(editUser, params);
    yield put(actions.editPersonSuccess(data.entity));
    yield* fetchPeopleList();
  } catch (error) {
    yield put(
      actions.editPersonFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Person.'),
      }),
    );
  }
}

export function* multipleEdit({ payload: { selectedSite, notificationStatusUsers } }) {
  try {
    yield call(setNotificationStatus, selectedSite, notificationStatusUsers);
    yield* fetchPeopleList();
    yield put(actions.multipleEditPersonSuccess());
  } catch (error) {
    yield put(
      actions.multipleEditPersonFailure({
        error,
        message: formatServerError(error, 'An error occurred while edit the Person.'),
      }),
    );
  }
}

export function* fetchAssignedSites({ payload: { userId, openDialog } }) {
  try {
    const {
      data: { root },
    } = yield call(getAssignedSites, userId);
    yield put(actions.assignedSitesSuccess({ root, userId }));
    yield openDialog(userId);
  } catch (error) {
    yield put(
      actions.assignedSitesFailure({
        error,
        message: formatServerError(error, 'An error occurred while fetching sites.'),
      }),
    );
  }
}

export function* updateAssignedSites({ payload }) {
  try {
    yield call(editUserSites, payload);
  } catch (error) {
    yield put(
      actions.assignedSitesFailure({
        error,
        message: formatServerError(error, 'An error occurred while fetching sites.'),
      }),
    );
  }
}

export function* sendEmailContent({ payload }) {
  try {
    const valuesWithSpacing = addSpacingForNotifications(payload, /[\r\n]/g, '<br>');
    yield call(sendLoginEmail, valuesWithSpacing);
    yield put(actions.sendEmailContentSuccess());
  } catch (error) {
    yield put(
      actions.sendEmailContentFailure({
        error,
        message: formatServerError(error, 'An error occurred while sending email content.'),
      }),
    );
  }
}

export function* fetchAvailabilityOptions() {
  try {
    const { data } = yield call(getAvailabilityOptions);
    yield put(actions.getAvailabilityOptionsSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getAvailabilityOptionsFailure({
        error,
        message: formatServerError(error, 'An error occurred while getting options.'),
      }),
    );
  }
}

export function* fetchJobsOptions() {
  try {
    const { data } = yield call(getJobsOptions);
    yield put(actions.getJobsOptionsSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getJobsOptionsFailure({
        error,
        message: formatServerError(error, 'An error occurred while getting options.'),
      }),
    );
  }
}

export function* fetchCanDeleteOption() {
  try {
    const { data } = yield call(getCanDeleteOption);
    yield put(actions.getCanDeleteOptionSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.getCanDeleteOptionFailure({
        error,
        message: formatServerError(error, 'An error occurred while getting options.'),
      }),
    );
  }
}

const peopleSagas = [
  takeEvery(actions.addPersonRequest, addPerson),
  takeEvery(actions.deletePersonRequest, deletePerson),
  takeEvery(actions.editPersonRequest, editPerson),
  takeEvery(actions.multipleEditPersonRequest, multipleEdit),
  takeEvery(actions.peopleListRequest, fetchPeopleList),
  takeEvery(actions.sendEmailContentRequest, sendEmailContent),
  takeEvery(actions.assignedSitesRequest, fetchAssignedSites),
  takeEvery(actions.updateAssignedSitesRequest, updateAssignedSites),
  takeEvery(actions.getAvailabilityOptionsRequest, fetchAvailabilityOptions),
  takeEvery(actions.getJobsOptionsRequest, fetchJobsOptions),
  takeEvery(actions.getCanDeleteOptionRequest, fetchCanDeleteOption),
  takeEvery(actions.unassignedManagerRequest, unassignedManager),
];

export default peopleSagas;
