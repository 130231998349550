import styled from 'styled-components';

import { ForwardIcon } from 'components/Layout/Icons';

export const IconForward = styled(ForwardIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin: 0;
`;

export const ConfirmationContainer = styled.div``;
