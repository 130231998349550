export const deletePeriodOptions = [
  {
    name: 'Older3Months',
    value: 0,
    label: 'Older  then  3 months',
  },
  {
    name: 'Older2Months',
    value: 1,
    label: 'Older  then  2 months',
  },
  {
    name: 'Older1Month',
    value: 2,
    label: 'Older  then  1 months',
  },
  {
    name: 'Older2Weeks',
    value: 4,
    label: 'Older  then  2 weeks',
  },
];
