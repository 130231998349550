import { call, put, takeEvery } from 'redux-saga/effects';

import { formatServerError } from 'helpers';
import { getTenantId, getSelectedSite } from 'helpers/storage';

import { fetchUnitsList } from 'redux/units/sagas.js';

import { actions } from './index';
import {
  canImportUnit,
  copyUnitsToTenantFromMaster,
  createItemListTemplate,
  fetchAllUnits,
  fetchItems,
  fetchLimits,
  getListMasterUnits,
  makeListTemplate,
  fetchUnitTaskStatus,
} from 'http/listTemplates';

function* getLimits() {
  try {
    const tenantId = yield call(getTenantId);
    const { data } = yield call(fetchLimits, tenantId);
    yield put(actions.getLimitsSuccess(data.entity));
  } catch (error) {
    yield put(actions.getLimitsFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getItems({ payload }) {
  try {
    const { data } = yield call(fetchItems, payload);
    yield put(actions.getItemsSuccess(data.root));
  } catch (error) {
    yield put(actions.getItemsFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* copyUnits({ payload }) {
  try {
    yield call(copyUnitsToTenantFromMaster, payload);
    yield put(
      actions.copyUnitsSuccess({
        unit: { ...payload.masterUnit, SiteName: payload.siteName },
        items: {
          ...payload.itemDtos
            .map(item => ({ [item.Id]: item }))
            .reduce((accum, value) => {
              const [id] = Object.keys(value);
              accum[id] = value[id];
              return accum;
            }, {}),
        },
      }),
    );
    yield put(actions.getLimitsRequest());
    payload.onSuccess();
  } catch (error) {
    yield put(actions.copyUnitsFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* createListTemplate({ payload }) {
  try {
    if (!payload.isClear) {
      const { data } = yield call(makeListTemplate, payload);
      yield put(actions.createListTemplateSuccess(data.entity));
      yield* fetchUnitsList({ payload: { filter: { SiteID: getSelectedSite() } } });
      yield put(
        actions.createListTemplateItemRequest({
          siteId: payload.siteId,
          unitId: data.entity.Id,
          tasks: payload.tasks,
          itemName: payload.itemName,
          onSuccess: payload.onSuccess,
        }),
      );
    } else {
      yield put(actions.createListTemplateClear());
    }
  } catch (error) {
    yield put(
      actions.createListTemplateFailure(formatServerError(error, 'An error occurred while creating List Template.')),
    );
  }
}

function* createListTemplateItem({ payload }) {
  try {
    const { data } = yield call(createItemListTemplate, payload);
    yield put(actions.createListTemplateItemSuccess(data.entity));
    yield put(actions.getLimitsRequest());
    payload.onSuccess();
  } catch (error) {
    yield put(
      actions.createListTemplateItemFailure(
        formatServerError(error, 'An error occurred while creating List Template.'),
      ),
    );
  }
}

function* getAllUnits() {
  try {
    const { data } = yield call(fetchAllUnits);
    yield put(actions.getAllUnitsSuccess(data.root));
  } catch (error) {
    yield put(actions.getAllUnitsFailure(formatServerError(error, 'An error occurred while creating List Template.')));
  }
}

function* searchListTemplate({ payload }) {
  try {
    const { data } = yield call(getListMasterUnits, payload);
    yield put(actions.searchListTemplateSuccess(data.root));
  } catch (error) {
    yield put(
      actions.searchListTemplateFailure(formatServerError(error, 'An error occurred while creating List Template.')),
    );
  }
}

function* checkMatchImportList({ payload }) {
  try {
    const { data } = yield call(canImportUnit, payload);
    yield put(actions.checkMatchImportListSuccess(data.entity));
    payload.onNext();
    if (!data.entity.IsPresent) {
      payload.onNext();
    }
  } catch (error) {
    yield put(actions.checkMatchImportListFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getUnitTaskStatus({ payload }) {
  try {
    const { data } = yield call(fetchUnitTaskStatus, payload);
    yield put(actions.getUnitTaskStatusSuccess(data.entity));
  } catch (error) {
    yield put(actions.getUnitTaskStatusFailure(formatServerError(error, 'An error occurred.')));
  }
}

const listTemplatesSagas = [
  takeEvery(actions.copyUnitsRequest, copyUnits),
  takeEvery(actions.getLimitsRequest, getLimits),
  takeEvery(actions.getItemsRequest, getItems),
  takeEvery(actions.getAllUnitsRequest, getAllUnits),
  takeEvery(actions.createListTemplateRequest, createListTemplate),
  takeEvery(actions.createListTemplateItemRequest, createListTemplateItem),
  takeEvery(actions.searchListTemplateRequest, searchListTemplate),
  takeEvery(actions.checkMatchImportListRequest, checkMatchImportList),
  takeEvery(actions.getUnitTaskStatusRequest, getUnitTaskStatus),
];

export default listTemplatesSagas;
