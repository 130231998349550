import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Form } from 'react-final-form';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { BorderRadiusContainer } from 'components/Layout/Containers';

import { actions as newSitesActions } from 'redux/newSites';
import { actions as peopleActions } from 'redux/people';
import { actions as sitesActions } from 'redux/sites';

import theme from 'theme';

import { USER_TYPES } from 'configs/enums';

import validate from './validator';
import CheckboxList from './CheckboxList';
import SiteForm from './Form';
import MissingResultsDialog from './MissingResultsDialog';
import * as S from './styled';

const userTypes = ['DashboardUser', 'SystemAdministrator', 'Manager', 'TaskSpecialist'];

class Setup extends Component {
  static propTypes = {
    onExit: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      addSiteMemberRequest: PropTypes.func.isRequired,
      createSiteRequest: PropTypes.func.isRequired,
      requestPeopleList: PropTypes.func.isRequired,
      getOptionsListRequest: PropTypes.func.isRequired,
      getJobsListRequest: PropTypes.func.isRequired,
      requestAssignSite: PropTypes.func.isRequired,
    }).isRequired,
    currentSite: PropTypes.shape({
      Id: PropTypes.string,
      Name: PropTypes.string,
      AdditionalSiteID: PropTypes.string,
    }).isRequired,
    people: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string,
        UserType: PropTypes.string,
      }),
    ).isRequired,
    addAnotherSite: PropTypes.func.isRequired,
    optionsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    jobsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isDemoMode: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool,
    user: PropTypes.shape({ userId: PropTypes.string, userType: PropTypes.number }).isRequired,
    assignedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    isEditMode: false,
  };

  state = {
    isFormShown: false,
    members: this.props.people,
    membersForSearch: this.props.people,
    checkedMembers: this.props.assignedUsers,
    searchData: '',
    confirmationDialogOpened: false,
    createSiteDialogOpened: false,
    missingDataDialogOpened: false,
  };

  onAddClick = form => {
    this.setState({ isFormShown: true }, () => form.restart());
  };

  onCloseClick = () => this.setState({ isFormShown: false });

  onAddMember = member => {
    this.setState(
      state => ({
        members: [...state.members, member],
        checkedMembers: [...state.checkedMembers, member.Id],
        membersForSearch: [...state.membersForSearch, member.Id],
      }),
      () => this.props.actions.requestPeopleList(),
    );
  };

  onSubmitForm = values => {
    const { optionsList, jobsList, actions } = this.props;

    const valuesToSend = {
      ...values,
      UserType: userTypes[values.UserType],
      OptionId: optionsList.find(item => item.Name === 'Unknown').Id,
      JobsId: jobsList[0].Id,
    };
    actions.addSiteMemberRequest({ valuesToSend, onAdd: this.onAddMember });
    this.onCloseClick();
  };

  clearCheckedMembers = () => this.setState({ checkedMembers: [] });
  fillCheckedMembers = checkedMembers => this.setState({ checkedMembers });
  setSearchedMembers = (members, searchData) => this.setState({ members, searchData });

  openConfirmationDialog = () => this.setState({ confirmationDialogOpened: true });
  closeConfirmationDialog = () => this.setState({ confirmationDialogOpened: false });

  openCreateSiteDialog = () => this.setState({ createSiteDialogOpened: true });
  closeCreateSiteDialog = () => {
    this.setState({ createSiteDialogOpened: false });
    this.props.onExit();
  };

  requestEditSiteMembers = () => {
    const { currentSite, actions, onExit } = this.props;

    actions.requestAssignSite({
      siteId: currentSite.Id,
      userIds: this.state.checkedMembers,
      mode: 'Put',
      onNext: () => onExit(currentSite),
    });
  };

  onCreateSite = () => {
    const { currentSite, actions, isEditMode } = this.props;
    const { checkedMembers } = this.state;

    if (isEditMode) {
      this.requestEditSiteMembers();
    } else {
      const valuesToSend = {
        siteName: currentSite.Name,
        additionalSiteId: currentSite.Id,
        userIdsToLink: checkedMembers,
        onSuccess: this.openCreateSiteDialog,
      };
      actions.createSiteRequest(valuesToSend);
      this.closeConfirmationDialog();
    }
  };

  onNextClick = () => {
    const { people, isEditMode } = this.props;
    const { checkedMembers } = this.state;

    if (checkedMembers.length === 0) {
      this.openConfirmationDialog();
      return;
    }

    const chosenMembers = people.filter(({ Id }) => checkedMembers.includes(Id));
    const hasManager = chosenMembers.some(({ UserType }) => UserType === userTypes[2]);
    const hasTasksSpecialist = chosenMembers.some(({ UserType }) => UserType === userTypes[3]);

    if (!hasManager || !hasTasksSpecialist) {
      if (isEditMode) {
        this.requestEditSiteMembers();
      }
      this.openMissingDataDialog();
    } else {
      this.onCreateSite();
    }
  };

  componentDidMount() {
    const { actions, isDemoMode, user } = this.props;

    if (isDemoMode && user?.userType === USER_TYPES.SystemAdministrator) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ checkedMembers: [user.userId] });
    }

    actions.requestPeopleList();
    actions.getOptionsListRequest();
    actions.getJobsListRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const { people } = this.props;
    const { searchData, checkedMembers } = this.state;

    if (people && people.length > 0 && prevState.members.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        members: people,
        membersForSearch: people,
      });
      // fixes material-ui dialog height CU610-2191
      window.dispatchEvent(new Event('resize'));
    }

    if (
      prevState.members.length !== people.length &&
      prevState.searchData === searchData &&
      prevState.checkedMembers.length === checkedMembers.length
    ) {
      this.setState({
        members: people,
        membersForSearch: people,
      });
      // fixes material-ui dialog height CU610-2191
      window.dispatchEvent(new Event('resize'));
    }
  }

  confirmSaveSite = () => {
    this.closeCreateSiteDialog();
    this.props.addAnotherSite();
  };

  openMissingDataDialog = () => {
    this.setState({ missingDataDialogOpened: true });
  };

  closeMissingDataDialog = () => {
    this.setState({ missingDataDialogOpened: false });
  };

  confirmMissingResults = () => {
    this.closeMissingDataDialog();
    this.onCreateSite();
  };

  render() {
    const { currentSite, isDemoMode, user, people, isEditMode } = this.props;
    const {
      isFormShown,
      members,
      membersForSearch,
      checkedMembers,
      searchData,
      confirmationDialogOpened,
      createSiteDialogOpened,
      missingDataDialogOpened,
    } = this.state;

    return (
      <S.Container>
        <S.TopInfo>
          <S.SubInfo>Site Name: {currentSite?.Name}</S.SubInfo>
          <S.SubInfo>Site ID: {currentSite?.Id}</S.SubInfo>
        </S.TopInfo>
        <Form
          onSubmit={values => this.onSubmitForm(values)}
          validate={validate}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <SiteForm
                isFormShown={isFormShown}
                onAddClick={this.onAddClick}
                onCloseClick={this.onCloseClick}
                form={form}
                values={values}
              />
            </form>
          )}
        />
        <BorderRadiusContainer>
          <S.Label>Select People From List</S.Label>
          <S.SearchContainer>
            <SearchField
              list={membersForSearch}
              filterBy={['FirstName', 'LastName']}
              searchData={searchData}
              placeholder="Filter people"
              setSearchedItems={this.setSearchedMembers}
            />
          </S.SearchContainer>
          <S.ListContainer>
            <CheckboxList
              list={members}
              checkedMembers={checkedMembers}
              fillCheckedMembers={this.fillCheckedMembers}
              clearCheckedMembers={this.clearCheckedMembers}
              allLength={people.length}
              isDemoMode={isDemoMode}
              user={user}
            />
          </S.ListContainer>
        </BorderRadiusContainer>
        <ActionsContainer>
          <ActionButton label="Next" isNext onClick={this.onNextClick} />
        </ActionsContainer>
        <ConfirmationDialog
          isNext
          handleClose={this.closeConfirmationDialog}
          title={'Alert'}
          titleColor={theme.redButton}
          text={'No one added to this new Site! Are you sure?'}
          label={'PROCEED'}
          width={'40%'}
          onClick={this.onCreateSite}
          onCancelClick={this.closeConfirmationDialog}
          open={confirmationDialogOpened}
        />
        <ConfirmationDialog
          isNext
          handleClose={this.closeCreateSiteDialog}
          title={'Confirmation'}
          titleColor={theme.mainGreen}
          text={'Your Site has been saved. Selected people have been added.'}
          label={'Next'}
          width={'40%'}
          onClick={this.confirmSaveSite}
          open={createSiteDialogOpened}
        />
        <MissingResultsDialog
          open={missingDataDialogOpened}
          label={isEditMode ? 'ADD PEOPLE' : 'PROCEED'}
          handleClose={this.closeMissingDataDialog}
          confirm={this.confirmMissingResults}
        />
      </S.Container>
    );
  }
}

SiteForm.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  isFormShown: PropTypes.bool.isRequired,
  form: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ people, newSites, auth, sites }) => ({
  people: people.list.filter(user => user.Status !== 'Inactive'),
  optionsList: newSites.optionsList,
  jobsList: newSites.jobsList,
  user: auth.user,
  assignedUsers: sites.assignedUsers,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      requestPeopleList: peopleActions.peopleListRequest,
      requestAssignSite: sitesActions.assignSiteRequest,
      ...newSitesActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
