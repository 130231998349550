import React from 'react';
import PropTypes from 'prop-types';

import { ToggleItem } from 'configs/propTypes';
import { ToolbarContainer } from 'components/Layout/Containers';
import Toggle from 'components/Toggle';

const Toolbar = ({ periodConfig, periodHandler, selectedPeriod, minWidth }) => (
  <ToolbarContainer>
    <Toggle config={periodConfig} selected={selectedPeriod} handler={periodHandler} minWidth={minWidth} />
  </ToolbarContainer>
);

Toolbar.propTypes = {
  periodConfig: PropTypes.arrayOf(ToggleItem).isRequired,
  periodHandler: PropTypes.func.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  minWidth: PropTypes.bool.isRequired,
};

Toolbar.defaultProps = {
  withoutTypeConfig: false,
};

export default Toolbar;
