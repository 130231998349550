import styled from 'styled-components';
import { ButtonFlat } from '../../../../Layout/Buttons';
import { InfoWrapper } from '../InstructionBlock/styled';

export const PurposeBold = styled.div`
  font-weight: 500;
`;

export const ActionButton = styled(ButtonFlat)`
  width: 50% !important;
  background-color: ${({ theme, disabled }) => (disabled ? theme.lightGrey : theme.secondaryRed)} !important;
  border-radius: 10px !important;

  span {
    color: ${({ theme, disabled }) => (disabled ? theme.textGray : theme.redButton)} !important;
  }
`;

export const InfoBlockContainer = styled(InfoWrapper)`
  justify-content: space-between;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AvailableInfoBlock = styled.div`
  background-color: #fff1d5;
  padding: 6px 20px;
  border-radius: 20px;
  text-align: center;
`;
