import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { actions as statusIndexActions } from 'redux/statusIndex';

import { InfoIcon } from 'components/Layout/Icons';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import TestrunButton from 'components/Buttons/TestrunButton';

import TestrunDialog from './TestrunDialog';
import ItemRules from './ItemRules';
import TaskCreation from './TaskCreation';
import {
  createId,
  initialRule,
  generateInitialRules,
  generateItemRules,
  indexItemRules,
  checkOptions,
} from './ItemRules/helpers';

import validate from './validator';
import * as S from './styled';
import { options } from './TaskCreation/constants';

class ShortcutSelection extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      createIndexRuleRequest: PropTypes.func.isRequired,
      editIndexRuleRequest: PropTypes.func.isRequired,
      fetchShortcutPreviewRequest: PropTypes.func.isRequired,
    }).isRequired,
    onExit: PropTypes.func.isRequired,
    itemRuleToEdit: PropTypes.shape().isRequired,
    setIndexRule: PropTypes.func.isRequired,
    indexRule: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    listIndexRule: PropTypes.array.isRequired,
    shortcutPreview: PropTypes.array.isRequired,
    shortcutPreviewLoading: PropTypes.bool.isRequired,
    openErrorNameDialog: PropTypes.func.isRequired,
    shortcutsList: PropTypes.array.isRequired,
  };

  state = {
    shortcutRules: generateInitialRules({
      rules: this.props.indexRule.ShortcutRules || this.props.itemRuleToEdit?.ShortcutRules,
      flag: 'shortcut',
    }),
    archivedTaskTime: { isAllArchivedTasks: true },
    isErrorShown: false,
    previewOpen: false,
    name: '',
    indexValues: {},
    isItemAlert: false,
    isNoItems: false,
  };

  setInitialValue = () => {
    const { itemRuleToEdit, indexRule } = this.props;
    const initialValue = {
      'ignore-Lead-Time': indexRule?.IgnoreLeadTime || options[0].value,
    };
    if (itemRuleToEdit.Name) {
      initialValue['ignore-Lead-Time'] = Number(itemRuleToEdit.IgnoreLeadTime);
    }
    const data = { ...initialValue };
    return data;
  };

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0]?.name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onKeyDownHandler = e => {
    const enterButton = 13;
    if (e.keyCode === enterButton) {
      e.preventDefault();
    }
  };

  onChangeHandler = (e, value, name, input) => {
    input.onChange(value);
    this.onChangeMatch(name, value);
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  onSubmitForm = values => {
    const { itemRuleToEdit, setIndexRule, listIndexRule, indexRule, openErrorNameDialog, actions } = this.props;
    const { shortcutRules } = this.state;
    const itemValues = generateItemRules(values, shortcutRules.rules);
    const rulesToSend = {
      ...itemValues,
    };
    const valuesToSend = {
      Id: itemRuleToEdit.Id,
      ...indexRule,
      Name: values.Name,
      IgnoreLeadTime: values['ignore-Lead-Time'],
      ...rulesToSend,
    };
    let isError = false;
    listIndexRule.forEach(item => {
      if (item.Name === valuesToSend.Name) {
        if (itemRuleToEdit?.Name !== valuesToSend.Name) {
          openErrorNameDialog();
          isError = true;
        }
      }
    });
    setIndexRule(valuesToSend);
    if (!checkOptions(valuesToSend.ShortcutRules) || isError) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false, indexValues: valuesToSend });
      actions.fetchShortcutPreviewRequest(valuesToSend);
    }
  };

  componentDidUpdate(prevProps) {
    const { shortcutPreviewLoading, shortcutPreview, itemRuleToEdit, actions, onExit } = this.props;
    const { previewOpen, indexValues } = this.state;
    if (prevProps.shortcutPreviewLoading !== shortcutPreviewLoading && !shortcutPreviewLoading && !previewOpen) {
      if (shortcutPreview.length === 0) {
        this.setState({ isItemAlert: true, isNoItems: true });
      } else if (shortcutPreview.length === 1) {
        if (itemRuleToEdit.Name) {
          actions.editIndexRuleRequest(indexValues);
        } else {
          actions.createIndexRuleRequest(indexValues);
        }
        onExit();
      } else {
        this.setState({ isItemAlert: true });
      }
    }
  }

  getPreviewResults = values => {
    const { actions } = this.props;
    const { shortcutRules } = this.state;
    const itemValues = generateItemRules(values, shortcutRules.rules);
    const rulesToSend = {
      ...itemValues,
    };
    const valuesToSend = {
      ...rulesToSend,
    };
    this.setState({ name: values.Name });
    if (!checkOptions(valuesToSend.ShortcutRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      actions.fetchShortcutPreviewRequest(valuesToSend);
      this.setState({ previewOpen: true });
    }
  };

  closePreview = () => {
    this.setState({ previewOpen: false });
  };

  closeItemAlert = () => {
    this.setState({ isItemAlert: false, isNoItems: false });
  };

  previousModal = values => {
    const { setIndexRule } = this.props;
    const { shortcutRules } = this.state;
    const itemValues = generateItemRules(values, shortcutRules.rules);
    const rulesToSend = {
      ...itemValues,
    };
    const valuesToSend = {
      Name: values.Name,
      IgnoreLeadTime: values['ignore-Lead-Time'],
      ...rulesToSend,
    };
    setIndexRule(valuesToSend);
    this.props.onBack();
  };

  render() {
    const { itemRuleToEdit, indexRule, shortcutsList, shortcutPreview, shortcutPreviewLoading } = this.props;
    const { isErrorShown, previewOpen, name, isItemAlert, isNoItems } = this.state;
    return (
      <S.DialogContainer>
        <S.FormContainer>
          <Form
            onSubmit={this.onSubmitForm}
            validate={validate}
            initialValues={this.setInitialValue()}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <S.FieldBlock>
                  <Field
                    component={S.TextField}
                    name="Name"
                    floatingLabelText="Index Rule Name"
                    initialValue={indexRule.Name || itemRuleToEdit?.Name}
                  />
                </S.FieldBlock>
                <S.Icon>
                  <InfoIcon />
                </S.Icon>
                <S.BoldText>Set rules to select a Shortcut</S.BoldText>
                <S.RedInfo>
                  If all of the previous choices become true , they will cause the Shortcuts
                  <br />
                  selection below to get activated and tasks will get created.
                </S.RedInfo>
                {indexItemRules.map(rule => (
                  <ItemRules
                    key={rule}
                    title={'Fixed Shortcut Selection'}
                    rules={this.state[`${rule}Rules`]}
                    addOption={this.addOption}
                    removeOption={this.removeOption}
                    onChangeMatch={this.onChangeMatch}
                    isInitialValues={!!itemRuleToEdit.Name || !!indexRule.ShortcutRules}
                    values={values}
                    onKeyDownHandler={this.onKeyDownHandler}
                    onChangeHandler={this.onChangeHandler}
                    shortcutsList={shortcutsList}
                  />
                ))}
                <TestrunButton isCentered onClick={() => this.getPreviewResults(values)} />
                <TaskCreation values={values} initialValue={itemRuleToEdit} />
                {isErrorShown && <S.ErrorContainer>Please add at least one rule or select</S.ErrorContainer>}
                <ActionsContainer>
                  <ActionButton type="button" label={'Previous'} onClick={() => this.previousModal(values)} />
                  <ActionButton type="submit" isNext label={'Save'} />
                </ActionsContainer>
                <TestrunDialog
                  open={previewOpen}
                  handleClose={this.closePreview}
                  data={shortcutPreview}
                  loading={shortcutPreviewLoading}
                  name={name}
                />
                <ConfirmationDialog
                  title="Alert"
                  maxWidth={'80%'}
                  text={
                    <S.ConfirmationContainer>
                      {isNoItems ? (
                        <>
                          <S.ConfirmationWarning>
                            <S.BoldDialogText>No Items!</S.BoldDialogText>
                            <br />
                            The current selection contains no Shortcut results
                          </S.ConfirmationWarning>
                          <S.Text>You can change rules to widen your selection.</S.Text>
                        </>
                      ) : (
                        <>
                          <S.ConfirmationWarning>
                            <S.BoldDialogText>Multiple Shortcuts Qualify!</S.BoldDialogText>
                            <br />
                            Only one Shortcut can be activated.
                          </S.ConfirmationWarning>
                          <S.Text>You can change rules to narrow your selection.</S.Text>
                        </>
                      )}
                      <ActionsContainer>
                        <ActionButton label="Return" onClick={this.closeItemAlert} />
                      </ActionsContainer>
                    </S.ConfirmationContainer>
                  }
                  open={isItemAlert}
                  handleClose={this.closeItemAlert}
                />
              </form>
            )}
          />
        </S.FormContainer>
      </S.DialogContainer>
    );
  }
}

const mapStateToProps = ({ shortcuts, statusIndex }) => ({
  shortcutsList: shortcuts.shortcutsList,
  shortcutPreview: statusIndex.shortcutPreview,
  shortcutPreviewLoading: statusIndex.shortcutPreviewLoading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchShortcutPreviewRequest: statusIndexActions.fetchShortcutPreviewRequest,
      editIndexRuleRequest: statusIndexActions.editIndexRuleRequest,
      createIndexRuleRequest: statusIndexActions.createIndexRuleRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutSelection);
