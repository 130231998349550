import styled from 'styled-components';
import ReactModal from 'react-modal';

export const Modal = styled(ReactModal).attrs({
  ariaHideApp: false,
  contentLabel: 'Instructions Dialog',
  shouldCloseOnOverlayClick: false,
  style: ({ backgroundColor, theme }) => ({
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.54)',
      height: 'calc(100% - 64px)',
      left: '0px',
      position: 'fixed',
      top: '64px',
      width: '100%',
      zIndex: 1040,
    },
    content: {
      alignItems: 'center',
      backgroundColor: backgroundColor || theme.primaryWhite,
      border: 'none',
      borderRadius: 'none',
      boxSizing: 'border-box',
      color: theme.modalsContentColor,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      left: '0px',
      position: 'absolute',
      top: '0px',
      width: '100%',
      zIndex: 1050,
    },
  }),
})``;
