import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { DocumentItem } from 'configs/propTypes';
import { ArrowForwardIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import EmptyList from 'components/EmptyList';
import { ButtonColumn, Column, Container, Header, HeaderColumn, ItemContainer, List } from './styled';

class Table extends Component {
  static propTypes = {
    documents: PropTypes.arrayOf(DocumentItem).isRequired,
    history: PropTypes.object.isRequired,
  };

  toDetails = item => {
    this.props.history.push(`/documents/details/${item.Id}`);
  };

  render() {
    const { documents } = this.props;

    return (
      <Container>
        <List>
          <Header>
            <HeaderColumn>Page Number</HeaderColumn>
            <HeaderColumn>Header Text</HeaderColumn>
            <HeaderColumn>Body Text</HeaderColumn>
            <ButtonColumn />
          </Header>
          {documents.map(item => (
            <ItemContainer key={item.Id} onClick={() => this.toDetails(item)}>
              <Column>{item.PageNumber}</Column>
              <Column>{item.HeaderText}</Column>
              <Column>{item.BodyText}</Column>
              <ButtonIcon tooltip="View" tooltipStyles={{ marginTop: '-17px' }}>
                <ArrowForwardIcon />
              </ButtonIcon>
            </ItemContainer>
          ))}
        </List>
        {!documents.length && <EmptyList text="There are no documents available" />}
      </Container>
    );
  }
}

export default withRouter(Table);
