import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions as settingsActions } from 'redux/settings';
import { actions as appActions } from 'redux/app';
import { actions as tenantsActions } from 'redux/tenants';

import LogoDialog from 'components/Dialogs/LogoDialog';
import ScanDialog from 'components/Dialogs/ScanDialog';
import LinksDialog from 'components/Dialogs/LinksDialog';
import TextDialog from 'components/Dialogs/TextDialog';

import KeysDialog from './ui/dialogs/Keys';
import SignUpNotificationDialog from './ui/dialogs/SignUpNotification';
import SystemLimitsDialog from './ui/dialogs/SystemLimits';
import VimeoAccountSetupDialog from './ui/dialogs/VimeoAccountSetupDialog';

import { General } from './ui/components/General';
import { Verification } from './ui/components/Verification';
import { Notifications } from './ui/components/Notifications';
import { IoT } from './ui/components/IoT';
import { Accounts } from './ui/components/Accounts';
import { Technical } from './ui/components/Technical';

import * as S from './styled';

const SettingsList = () => {
  const [logoOpened, setLogoOpened] = useState(false);
  const [scanOpened, setScanOpened] = useState(false);
  const [linksOpened, setLinksOpened] = useState(false);
  const [textOpened, setTextOpened] = useState(false);
  const [keysOpened, setKeysOpened] = useState(false);
  const [signUpNotificationOpened, setSignUpNotificationOpened] = useState(false);
  const [limitsOpened, setLimitsOpened] = useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [isVimeoAccountSetupDialogOpen, setIsVimeoAccountSetupDialogOpen] = useState(false);

  const loading = useSelector(state => state.settings.loading);
  const settings = useSelector(state => state.settings.list);
  const dispatch = useDispatch();

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.loading && nextState.ScanOpened) {
  //     return false;
  //   }
  //   return true;
  // }

  const closeLogoDialog = () => setLogoOpened(false);

  const openLogoDialog = () => {
    dispatch(settingsActions.getLogoRequest('master'));
    dispatch(settingsActions.getLogoEmailRequest('master'));
    setLogoOpened(true);
  };

  const closeScanDialog = () => {
    dispatch(appActions.updateCurrentGlobalSettingsModule());
    setScanOpened(false);
  };

  const openScanDialog = () => {
    dispatch(appActions.updateCurrentGlobalSettingsModule('qr'));

    setScanOpened(true);
  };

  const closeTextDialog = () => setTextOpened(false);

  const openTextDialog = () => setTextOpened(true);

  const submitScanDialog = values => {
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: true,
        values: { ...settings, ...values },
      }),
    );
    closeScanDialog();
  };

  const closeLinksDialog = () => {
    dispatch(appActions.updateCurrentGlobalSettingsModule());
    setLinksOpened(false);
  };

  const openLinksDialog = () => {
    dispatch(appActions.updateCurrentGlobalSettingsModule('systemLinks'));
    dispatch(settingsActions.getSettingsLinksListRequest({ isMaster: true }));
    setLinksOpened(true);
  };

  const openKeysModal = () => setKeysOpened(true);
  const closeKeysModal = () => setKeysOpened(false);

  const openVimeoAccountSetupDialog = () => setIsVimeoAccountSetupDialogOpen(true);

  const closeVimeoAccountSetupDialog = () => setIsVimeoAccountSetupDialogOpen(false);

  const openSignUpNotificationDialog = () => {
    dispatch(settingsActions.getSignUpNotificationRequest());
    setSignUpNotificationOpened(true);
  };
  const closeSignUpNotificationDialog = () => setSignUpNotificationOpened(false);

  const openLimitsDialog = () => {
    dispatch(settingsActions.getSystemLimitsRequest());
    setLimitsOpened(true);
  };
  const closeLimitsDialog = () => setLimitsOpened(false);

  const getChangedValues = (value, rest) => {
    setChangedValues({
      ...changedValues,
      [rest.link.Id]: rest.reset
        ? rest.link
        : {
            ...(changedValues[rest.link.Id] ? changedValues[rest.link.Id] : rest.link),
            [rest.name.substring(1)]: value,
          },
    });
  };

  const submitLinksDialog = values => {
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: true,
        values: { ...settings, LinkIntroText: values.LinkIntroText },
      }),
    );
    dispatch(
      settingsActions.setSettingsLinksRequest({ requestValues: changedValues, isMaster: true, stateValues: values }),
    );
    closeLinksDialog();
  };

  const submitTextDialog = values => {
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: true,
        values: { ...settings, ...values },
      }),
    );
    closeTextDialog();
  };

  const submitSignUpNotification = values => {
    dispatch(settingsActions.setSignUpNotificationRequest(values));
    closeSignUpNotificationDialog();
  };

  const submitSystemLimits = values => {
    dispatch(settingsActions.setSystemLimitsRequest(values));
    closeLimitsDialog();
  };

  useEffect(() => {
    dispatch(settingsActions.getSettingsRequest(true));
    dispatch(tenantsActions.getCurrentTenantsCountRequest());
  }, []);

  return [
    <S.Container key="ListItemsContainer">
      <S.List>
        <General openLogoDialog={openLogoDialog} openLimitsDialog={openLimitsDialog} />
        <Verification openScanDialog={openScanDialog} />
        <Notifications
          openTextDialog={openTextDialog}
          openLinksDialog={openLinksDialog}
          openSignUpNotificationDialog={openSignUpNotificationDialog}
        />
        <IoT openKeysModal={openKeysModal} />
        <Accounts />
        <Technical openVimeoAccountSetupDialog={openVimeoAccountSetupDialog} />
      </S.List>
    </S.Container>,
    <LogoDialog
      key="LogoDialog"
      handleClose={closeLogoDialog}
      title="Tenants Logos (replace defaults)"
      open={logoOpened}
    />,
    <ScanDialog
      key="ScanDialog"
      handleClose={closeScanDialog}
      title="Default QR settings for Units and Items"
      open={scanOpened}
      onSubmit={submitScanDialog}
      withoutTopBorder
      BigButtons
    />,
    <TextDialog
      key="TextDialog"
      handleClose={closeTextDialog}
      title="Notifications Text"
      open={textOpened}
      onSubmit={submitTextDialog}
      BigButtons
    />,
    <LinksDialog
      key="LinksDialog"
      handleClose={closeLinksDialog}
      title="System Links"
      open={linksOpened}
      BigButtons
      onSubmit={submitLinksDialog}
      getChangedValues={getChangedValues}
    />,
    <KeysDialog key="KeysDialog" handleClose={closeKeysModal} open={keysOpened} />,
    <SignUpNotificationDialog
      key="SignUpNotification"
      title="New Account Message"
      handleClose={closeSignUpNotificationDialog}
      onSubmit={submitSignUpNotification}
      open={signUpNotificationOpened}
    />,
    <SystemLimitsDialog
      key="SystemLimits"
      title="System Limits"
      open={limitsOpened}
      handleClose={closeLimitsDialog}
      onSubmit={submitSystemLimits}
    />,
    <VimeoAccountSetupDialog open={isVimeoAccountSetupDialogOpen} handleClose={closeVimeoAccountSetupDialog} />,
  ];
};

export default SettingsList;
