import styled from 'styled-components';
import MenuItem from 'material-ui/MenuItem';

import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';
import { TextareaField } from 'components/Layout/Textarea';
import { FieldSelect } from 'components/Layout/Selects';

export const Container = styled.div`
  padding: 0 30px;
`;

export const Block = styled(BorderRadiusContainer)`
  margin: 16px 0;
  border-radius: 5px 5px 0 0;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
`;

export const SelectContainer = styled(InputWrapper)``;

export const SelectField = styled(SelectFieldAdapter).attrs({
  maxHeight: window.innerHeight / 4,
})`
  margin-bottom: 15px;
`;

export const RegionSelectField = styled(SelectField)``;
export const ResourceGroupRegion = styled(FieldSelect)``;

export const Item = styled(MenuItem).attrs({
  innerDivStyle: ({ theme, value }) => ({
    color: value ? '' : theme.primaryHome,
  }),
})``;

export const Textarea = styled(TextareaField)`
  margin-top: 20px;

  div > textarea {
    border-color: #e0e0e0 !important;
  }
`;
