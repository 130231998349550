import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';

import { UnitItemTaskTemplateType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { RULE_DATA, RULE_INITIAL_VALUES, decorator } from 'helpers';

import validate from './validator';
import * as S from './styled';

const AddEditForm = ({ values }) => (
  <S.AddEditForm>
    <Field component={SelectFieldAdapter} floatingLabelText="Task Type" name="RuleType">
      <MenuItem primaryText="Verification" value="Verification" />
      <MenuItem primaryText="Feedback" value="Feedback" />
      <MenuItem primaryText="Check Flow Rate" value="CheckFlowRate" />
      <MenuItem primaryText="Check Quantity" value="CheckQuantity" />
      <MenuItem primaryText="Check Temperature" value="CheckTemperature" />
      <MenuItem primaryText="Photo Confirmation" value="PhotoConfirmation" />
      <MenuItem primaryText="Scan Code" value="ScanCode" />
      <MenuItem primaryText="Signature Collection" value="Signature" />
      <MenuItem primaryText="Replace Parts" value="PartReplacement" />
    </Field>
    <S.Instructions>
      <S.InstructionsLabel>Instructions</S.InstructionsLabel>
      <S.InputContainer>
        <Field
          component={TextFieldAdapter}
          floatingLabelText="Short Instruction or Question"
          name="Name"
          maxLength="255"
        />
        <Field
          component={TextFieldAdapter}
          floatingLabelText="Task Notes / Additional Instructions"
          name="InstructionalNotes"
        />
      </S.InputContainer>
    </S.Instructions>
    {!!RULE_DATA[values.RuleType].length && (
      <S.RuleData>
        <S.RuleDataLabel>Rule Data</S.RuleDataLabel>
        <S.InputContainer>
          {RULE_DATA[values.RuleType].map(item => (
            <Field key={item.name} component={TextFieldAdapter} {...item} />
          ))}
        </S.InputContainer>
      </S.RuleData>
    )}
  </S.AddEditForm>
);

AddEditForm.propTypes = {
  values: PropTypes.shape({
    InstructionalNotes: PropTypes.string,
    Name: PropTypes.string,
    RuleData: PropTypes.shape({
      ExpectedLowerBound: PropTypes.string,
      ExpectedScanCode: PropTypes.string,
      ExpectedUpperBound: PropTypes.string,
      MaxAttachments: PropTypes.string,
      MinAttachments: PropTypes.string,
      Specification: PropTypes.string,
    }),
    RuleType: PropTypes.string,
  }).isRequired,
};

const AddEditWithInput = withInputModal(AddEditForm);

const AddEditTaskTemplateDialog = ({ task, ...rest }) => (
  <AddEditWithInput {...rest} decorators={[decorator]} initialValues={task} validate={validate} />
);

AddEditTaskTemplateDialog.defaultProps = {
  handleDelete: () => {},
  task: {
    InstructionalNotes: '',
    Name: '',
    ...RULE_INITIAL_VALUES,
  },
  withDelete: false,
};

AddEditTaskTemplateDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  task: UnitItemTaskTemplateType,
  title: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
};

export default AddEditTaskTemplateDialog;
