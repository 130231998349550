import styled from 'styled-components';

const Checklists = styled.div`
  color: ${({ theme }) => theme.primaryScheduler};
`;

const RedText = styled.div`
  color: ${({ theme }) => theme.mainRed};
`;

const Text = styled.div`
  text-transform: capitalize;
`;

export { Checklists, RedText, Text };
