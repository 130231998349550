import styled from 'styled-components';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
  padding-top: 10px;
`;

export const SecondaryTitle = styled.div`
  padding: 30px 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Title = styled.p`
  text-align: center;
  margin-bottom: 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Block = styled(BorderRadiusContainer)`
  margin: 40px 0 20px 0;
`;

export const InputContainer = styled(InputWrapper)``;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
  text-align: left;
  padding: 20px 15px 15px 15px;
`;

export const DialogContainer = styled.div`
  padding: 10px 10px 0;
  text-align: center;
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationWarning = styled.div`
  font-size: 18px;
  margin: 15px 0;
`;
