import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as shortcutActions } from 'redux/shortcuts';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import { initialRuleValues } from '../constants';

import ShortcutForm from './ShortcutForm';
import validate from './validator';
import * as S from './styled';

class Setup extends Component {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    shortcut: PropTypes.shape().isRequired,
    actions: PropTypes.shape({
      createShortcutRequest: PropTypes.func.isRequired,
      saveShortcutOptionsRequest: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
  };

  state = {
    toggled: this.props.shortcut.Name ? !!this.props.shortcut.Type : false,
  };

  onToggle = () => {
    const { toggled } = this.state;
    this.setState({ toggled: !toggled });
  };

  onSubmitForm = ({ values, toggled, onNext, onSave }) => {
    const { shortcut } = this.props;
    if (!shortcut.Name) {
      const valuesToSend = {
        ...values,
        Type: toggled ? 1 : 0,
        onNext,
        locationRules: [{ ...initialRuleValues, OptionName: 'SiteIgnore', TableName: 'Sites' }],
        itemRules: [{ ...initialRuleValues, OptionName: 'ItemIgnore', TableName: 'Items' }],
        unitRules: [{ ...initialRuleValues, OptionName: 'UnitIgnore', TableName: 'Units' }],
      };
      onSave(valuesToSend);
    } else {
      const valuesToSendEdit = {
        ...values,
        Type: toggled ? 1 : 0,
        Id: this.props.shortcut.Id,
      };
      onSave(valuesToSendEdit);
      onNext();
    }
  };

  render() {
    const {
      onNext,
      actions: { createShortcutRequest, saveShortcutOptionsRequest },
      error,
      shortcut,
    } = this.props;
    const { toggled } = this.state;
    return (
      <S.Container>
        <S.TopInfo>
          <S.ShortCutIcon />
          <S.Title>Shortcut setup</S.Title>
          <S.SubInfo>A shortcut automates the creation of checklists.</S.SubInfo>
          <S.SubInfo>
            Please use a name that indicates the purpose of the shortcut (like &quot;weekly maintenance&quot;)
          </S.SubInfo>
        </S.TopInfo>

        <Form
          onSubmit={values =>
            this.onSubmitForm({
              values,
              toggled,
              onNext,
              onSave: shortcut.Name ? saveShortcutOptionsRequest : createShortcutRequest,
            })
          }
          validate={validate}
          initialValues={shortcut.Name ? { Name: shortcut.Name } : null}
          render={({ handleSubmit, values, modifiedSinceLastSubmit, submitSucceeded }) => (
            <form onSubmit={handleSubmit}>
              <ShortcutForm
                modifiedSinceLastSubmit={modifiedSinceLastSubmit}
                submitSucceeded={submitSucceeded}
                values={values}
                toggled={toggled}
                onToggle={this.onToggle}
                error={error}
              />
              <ActionsContainer>
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts }) => ({
  shortcut: shortcuts.shortcut,
  error: shortcuts.error,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
