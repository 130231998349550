/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

import CheckboxAdapter from 'components/Forms/CheckboxAdapter';

export const Section = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: start;
  width: 100%;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.textGray};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

export const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export const Checkbox = styled(CheckboxAdapter).attrs({
  style: { marginBottom: '15px' },
  iconStyle: ({ input, theme }) => ({ fill: input.value && theme.primaryScheduler }),
})``;

export const FieldBlockContainer = styled.div`
  display: flex;
  jistify-content: start;
`;

export const typeRadioStyle = {
  width: '100%',
  fontSize: '18px',
};

export const RedTitle = styled.div`
  color: ${({ theme }) => theme.mainRed};
  font-size: 14px;
  text-align: center;
  margin: 10px auto 20px auto;
  font-weight: 700;
`;

export const SelectContainer = styled.div`
  margin-top: -10px;
`;
