import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { TextInput } from 'components/Input';
import Controls from 'components/Subheader/Controls';
import { findSite } from 'helpers';

import * as S from './styled';

type ButtonType = {
  handler?: () => void;
  title?: string;
  icon?: ReactNode;
  hint?: string;
  labelPosition?: string;
  tooltipPosition?: string;
};

type PropsType = {
  leftButtons?: Array<ButtonType>;
  rightButtons?: Array<ButtonType>;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  isSearch?: boolean;
  inputProps?: any; // FIXME
  withSelectedSite?: boolean;
  containerStyles?: any;
};

export const Subheader: React.FC<PropsType> = ({
  leftButtons,
  rightButtons,
  leftContent,
  rightContent,
  isSearch,
  withSelectedSite,
  inputProps,
  containerStyles,
}) => {
  const sitesAvailableToUserFullInfo = useSelector((state: any) => state.auth.user.sitesAvailableToUserFullInfo);
  const selectedSite = useSelector((state: any) => state.auth.selectedSite);
  const siteName = findSite(sitesAvailableToUserFullInfo, selectedSite);

  return (
    <S.Container styles={containerStyles}>
      <Controls buttons={leftButtons} />
      <S.Box>
        {withSelectedSite && !leftContent ? (
          <S.OrderList>
            <S.List>Title</S.List>
            <S.List>{siteName}</S.List>
          </S.OrderList>
        ) : (
          leftContent
        )}
      </S.Box>

      {isSearch && (
        <S.Box>
          <TextInput {...inputProps} />
        </S.Box>
      )}
      <S.Box>{rightContent}</S.Box>
      <Controls buttons={rightButtons} />
    </S.Container>
  );
};

Subheader.defaultProps = {
  leftButtons: [],
  rightButtons: [],
  leftContent: null,
  rightContent: null,
  isSearch: true,
  inputProps: {},
  withSelectedSite: true,
  containerStyles: [],
};
