import { InputBlockLabel } from 'components/Layout/Containers';
import styled, { css } from 'styled-components';

const RootContainer = styled.form`
  margin: 20px;
  margin-bottom: 10px;
  text-align: center;
  min-width: 30vw;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.mainRed};
`;

const BoldTitle = styled(Title)`
  font-weight: bold;
`;

const Label = styled(InputBlockLabel)`
  text-align: start;
  background: ${({ theme }) => theme.primaryLists};
`;

const Text = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 20px;
`;

const Input = styled.input<{ isRedBack?: boolean }>`
  max-width: 80px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  color: ${({ theme }) => theme.mainRed};
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  background: ${({ theme, isRedBack }) => isRedBack && theme.secondaryRed};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Wrapper = styled.div`
  padding: 0 20px 20px;
`;

const Paragraph = styled.p`
  font-weight: 400;
  color: ${({ theme }) => theme.primaryBlack};
`;

const Name = styled.div`
  font-size: 12px;
  padding: 10px 0;
`;

const ConfirmationText = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.primaryBlack};
`;

const textareaStyles = css`
  border: 1px solid ${({ theme }) => theme.mainRed};
  background: ${({ theme }) => theme.lightGrey};
`;

export {
  RootContainer,
  Title,
  BoldTitle,
  Label,
  Text,
  Input,
  Container,
  Wrapper,
  Paragraph,
  Name,
  ConfirmationText,
  textareaStyles,
};
