import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { AddIcon } from 'components/Layout/Icons';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import { ButtonIcon } from 'components/Layout/Buttons';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import SearchField from 'components/Layout/Search';
import withInputModal from 'components/Dialogs/withInputModal';
import { AssetCategoryType, AssetType } from 'configs/propTypes';

import { mapStateToProps, mapDispatchToProps } from './index.props';
import AssetTypes from './AssetTypes';

import * as S from './styled';

const AddEditAssetsForm = ({
  initialValues,
  form,
  values,
  isEditModal,
  assetTypeList,
  updateAssetTypeRequest,
  deleteAssetTypeRequest,
  createAssetTypeRequest,
}) => {
  const [search, setSearch] = useState('');
  const [assetTypes, setAssetTypes] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);

  const openConfirmationModel = useCallback(() => setIsConfirmationModelOpen(true), []);

  const closeConfirmationModel = () => setIsConfirmationModelOpen(false);

  const onConfirmationModalClose = () => {
    closeConfirmationModel();
    setSelectedId('');
  };

  const deleteAssetType = () => {
    deleteAssetTypeRequest({ id: selectedId, masterAssetCategoryID: initialValues.Id });
    onConfirmationModalClose();
  };

  const createAssetType = () => {
    const payload = {
      masterAssetCategoryID: initialValues.Id,
      masterAssetCategoryName: initialValues.Name,
      name: values.newAssetValue,
    };

    createAssetTypeRequest(payload);
    form.restart();
  };

  const onSearch = (list, searchData) => {
    setSearch(searchData);
    setAssetTypes(list);
  };

  useEffect(() => {
    setAssetTypes(assetTypeList);
  }, [assetTypeList]);

  return (
    <S.FormContainer>
      <S.InputContainer>
        <Field component={TextFieldAdapter} floatingLabelText="Asset Category Name" name="Name" />
        <Field component={TextFieldAdapter} floatingLabelText="Asset Category Description (---)" name="Description" />
      </S.InputContainer>
      <BorderRadiusContainer>
        <S.Label>Asset Types</S.Label>
        <S.SearchContainer>
          <SearchField
            list={assetTypeList}
            filterBy={['Name']}
            searchData={search}
            borderRadius="10px"
            placeholder="Filter Types"
            setSearchedItems={onSearch}
          />
        </S.SearchContainer>
        <AssetTypes
          assetTypes={assetTypes}
          openConfirmationModel={openConfirmationModel}
          initialValues={initialValues}
          setAssetTypes={setAssetTypes}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          updateAssetTypeRequest={updateAssetTypeRequest}
        />
        <S.NewItemContainer>
          <Field component={S.TextField} floatingLabelText="New Asset Type Name" name="newAssetValue" />
          <ButtonIcon disabled={!isEditModal || !values.newAssetValue} onClick={createAssetType}>
            <AddIcon />
          </ButtonIcon>
        </S.NewItemContainer>
      </BorderRadiusContainer>
      <ConfirmationDialog
        open={isConfirmationModelOpen}
        handleClose={onConfirmationModalClose}
        onClick={deleteAssetType}
        onCancelClick={onConfirmationModalClose}
        text={
          <S.ConfirmationTextContainer>
            <h3>Warning</h3>
            Deleted Type will be replaced with:
            <p>&quot;Undefined Type&quot;</p>
          </S.ConfirmationTextContainer>
        }
        maxWidth="80%"
        isCancelNext
        label="Replace"
        labelCancel="Cancel/Return"
      />
    </S.FormContainer>
  );
};

AddEditAssetsForm.propTypes = {
  initialValues: PropTypes.object,
  values: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isEditModal: PropTypes.bool,
  assetTypeList: PropTypes.arrayOf(AssetType).isRequired,
  updateAssetTypeRequest: PropTypes.func.isRequired,
  deleteAssetTypeRequest: PropTypes.func.isRequired,
  createAssetTypeRequest: PropTypes.func.isRequired,
};

AddEditAssetsForm.defaultProps = {
  initialValues: {},
  isEditModal: false,
};

const AddEditAssetsDialog = withInputModal(AddEditAssetsForm);

const AddEditAssetsDialogContainer = ({ assetCategory, assetTypeList, isEditModal, ...rest }) => (
  <AddEditAssetsDialog
    initialValues={isEditModal ? assetCategory : {}}
    assetTypeList={isEditModal ? assetTypeList : []}
    isEditModal={isEditModal}
    {...rest}
  />
);

AddEditAssetsDialogContainer.propTypes = {
  assetCategory: AssetCategoryType.isRequired,
  assetTypeList: PropTypes.arrayOf(AssetType).isRequired,
  isEditModal: PropTypes.bool,
};

AddEditAssetsDialogContainer.defaultProps = {
  isEditModal: false,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(AddEditAssetsDialogContainer));
