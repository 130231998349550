import styled from 'styled-components';
import { Slider as MaterialUISlider } from 'material-ui';
import Divider from 'material-ui/Divider';

export const DialogContainer = styled.div`
  margin: 20px;
`;

export const Title = styled.p`
  text-align: center;
  font-size: 20px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Slider = styled(MaterialUISlider)`
  height: 76px;
  flex: 1;

  div > div:not(:last-child) {
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.darkGrey};
  }

  div > div > div:last-child:hover:after {
    background: ${({ theme }) => theme.darkGrey};
    border-radius: 0.5em;
    bottom: 1.8em;
    color: ${({ theme }) => theme.primaryWhite};
    content: '${({ sliderValue }) => sliderValue}';
    display: block;
    left: -8px;
    padding: 0.8em 1.1em;
    position: absolute;
    white-space: nowrap;
    z-index: 98;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  gap: 20px;
  margin: 0 auto;
  padding: 20px 0;
`;

export const Value = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const DividerLine = styled(Divider)`
  margin: 30px 0 0 !important;
  height: 2px !important;
`;
