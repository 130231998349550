import React, { useState, useEffect, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Provider, ReactReduxContext, useSelector } from 'react-redux';

import { Dialog } from 'components/Dialogs/v1/Base';
import Loader from 'components/Loader';

import theme from 'theme';

import InfoPage from './InfoPage';
import SiteName from './SiteName';
import AddPeople from './AddPeople';

import * as S from './styled';

const STEPS_NEW = {
  0: {
    Component: InfoPage,
  },
  1: {
    Component: SiteName,
  },
  2: {
    Component: AddPeople,
  },
};

const STEPS = {
  0: {
    Component: SiteName,
  },
  1: {
    Component: AddPeople,
  },
};

const NewSite = ({ open, handleClose, handleExit, firstTimeNewSite, isDemoMode, addAnotherSite }) => {
  const isLoading = useSelector(state => state.newSites.isLoading);
  const { store } = useContext(ReactReduxContext);

  const [step, setStep] = useState(0);
  const [currentSite, setCurrentSite] = useState(null);
  const Step = firstTimeNewSite ? STEPS_NEW[step].Component : STEPS[step].Component;

  const onCurrentSiteChange = site => {
    setCurrentSite(site);
  };

  const handlePrev = () => {
    setStep(prevState => prevState - 1);
  };

  const handleNext = () => {
    setStep(prevState => prevState + 1);
  };

  useEffect(() => {
    setStep(0);
    setCurrentSite(null);
  }, [open]);

  return (
    <Dialog
      open={open}
      onRequestClose={(firstTimeNewSite && step < 2) || (!firstTimeNewSite && step < 1) ? handleExit : handleClose}
      title="Site Setup"
      titleColor={theme.primarySites}
    >
      <S.DialogContainer>
        <Loader isLoading={isLoading} size="large" />
        <Provider store={store}>
          <Step
            onNext={handleNext}
            onBack={handlePrev}
            onClose={handleClose}
            onExit={handleExit}
            setCurrentSite={onCurrentSiteChange}
            currentSite={currentSite}
            addAnotherSite={addAnotherSite}
            isDemoMode={isDemoMode}
          />
        </Provider>
      </S.DialogContainer>
    </Dialog>
  );
};

NewSite.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleExit: PropTypes.func.isRequired,
  addAnotherSite: PropTypes.func.isRequired,
  firstTimeNewSite: PropTypes.bool.isRequired,
  isDemoMode: PropTypes.bool.isRequired,
};

export default memo(NewSite);
