import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useModal } from '@ebay/nice-modal-react';

import { TenantType } from 'configs/propTypes';

import { openErrorDialog as openErrorDialogAC } from 'redux/errorHandler';
import { openConfirmationDialog as openConfirmationDialogAC } from 'redux/confirmationHandler';
import { actions as tenantsActions } from 'redux/tenants';

import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { BackIcon } from 'components/Layout/Icons';

import UpgradeInfoDialog from './UpgradeInfoDialog';
import Item from './Item';
import * as S from './styled';

import theme from 'theme';

function UpgradeRequests({
  history,
  error,
  requestUpgradeRequestsList,
  requestTenantIndustryList,
  requestDeleteUpgradeRequest,
  requestEditUpgradeRequest,
  upgradeRequestsList,
  openErrorDialog,
  openConfirmationDialog,
}) {
  const [searchData, setSearchData] = useState('');
  const upgradeInfoDialog = useModal(UpgradeInfoDialog);

  const internalList = upgradeRequestsList.filter(({ TenantID }) => TenantID);
  const externalList = upgradeRequestsList.filter(({ TenantID }) => !TenantID);

  const handleBack = () => history.push('/home');

  const searchInList = e => setSearchData(e.target.value);

  const filterTenantsList = ({ ControllerNotes, RequestorCompany, RequestorName }) =>
    `${ControllerNotes} ${RequestorCompany} ${RequestorName}`.toLowerCase().includes(searchData.toLowerCase());

  const openAddEditTenantDialog = tenant => {
    upgradeInfoDialog.show({
      title: 'Upgrade Info',
      onSubmit: submitUpgradeRequest,
      info: tenant,
      withDelete: true,
      withGreenButton: true,
      customColor: theme.primaryRed,
      handleDelete: () => openConfirmationDelete(tenant.Id),
    });
  };

  const closeUpgradeInfoDialog = () => upgradeInfoDialog.hide();

  const submitUpgradeRequest = values => {
    requestEditUpgradeRequest(values);
    closeUpgradeInfoDialog();
  };

  const deleteUpgradeRequest = upgradeTenantRequestId => {
    requestDeleteUpgradeRequest({ upgradeTenantRequestId });
    closeUpgradeInfoDialog();
  };

  const openConfirmationDelete = id => {
    openConfirmationDialog(
      'Are you sure you want to permanently delete this Request? This cannot be undone!',
      () => deleteUpgradeRequest(id),
      'Delete?',
    );
  };

  useEffect(() => {
    if (error) {
      openErrorDialog(error, 'Error');
    }
  }, [error, openErrorDialog]);

  useEffect(() => {
    requestUpgradeRequestsList();
    requestTenantIndustryList();
  }, [requestTenantIndustryList, requestUpgradeRequestsList]);

  return (
    <>
      <Subheader
        title="Upgrade Requests"
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: handleBack,
            hint: 'Back',
          },
        ]}
        isSearch
        searchData={searchData}
        searchInList={searchInList}
        rightContent={
          <S.RequestsInfoContainer>
            Requests: <S.RequestsNumberContainer>{upgradeRequestsList.length}</S.RequestsNumberContainer>
          </S.RequestsInfoContainer>
        }
      />
      <S.ListContainer>
        <S.ListSectionName>Internal Requests (existing Tenants)</S.ListSectionName>
        <SimpleList
          data={internalList.filter(filterTenantsList)}
          emptyListMessage="There are no Upgrade Requests avaliable"
          onItemClick={openAddEditTenantDialog}
          renderItemContent={Item}
        />
      </S.ListContainer>
      <S.ListContainer>
        <S.ListSectionName>External Requests</S.ListSectionName>
        <SimpleList
          data={externalList.filter(filterTenantsList)}
          emptyListMessage="There are no Upgrade Requests avaliable"
          onItemClick={openAddEditTenantDialog}
          renderItemContent={Item}
        />
      </S.ListContainer>
    </>
  );
}

UpgradeRequests.propTypes = {
  error: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  upgradeRequestsList: PropTypes.arrayOf(TenantType).isRequired,
  requestUpgradeRequestsList: PropTypes.func.isRequired,
  requestTenantIndustryList: PropTypes.func.isRequired,
  requestEditUpgradeRequest: PropTypes.func.isRequired,
  requestDeleteUpgradeRequest: PropTypes.func.isRequired,
  openConfirmationDialog: PropTypes.func.isRequired,
  openErrorDialog: PropTypes.func.isRequired,
};

const mapStateToProps = ({ tenants }) => ({
  error: tenants.error,
  upgradeRequestsList: tenants.upgradeRequestsList,
});

const mapDispatchToProps = {
  requestTenantIndustryList: tenantsActions.tenantIndustryListRequest,
  requestUpgradeRequestsList: tenantsActions.getUpgradeRequestsRequest,
  requestEditUpgradeRequest: tenantsActions.editUpgradeRequestRequest,
  requestDeleteUpgradeRequest: tenantsActions.deleteUpgradeRequestRequest,
  openErrorDialog: openErrorDialogAC,
  openConfirmationDialog: openConfirmationDialogAC,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeRequests);
