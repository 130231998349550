export const constraints = {
  0: 'is',
  1: 'is not',
  2: 'begins with',
  4: 'ends with',
  8: 'contains',
  16: 'does not contain',
};

export const Status = [
  { Name: 'Future', Value: 'Future' },
  { Name: 'Live', Value: 'Live' },
  { Name: 'Old', Value: 'Old' },
];

export const IndexItemName = [
  { Name: 'Is', Value: 'Is' },
  { Name: 'Is not', Value: 'IsNot' },
];

export const optionArray = ['IndexItemState', 'IndexItemName'];

export const dateTimeArray = [
  'IndexItemCreatedOn',
  'IndexItemLiveDate',
  'IndexItemEndDate',
  'IndexItemLiveTime',
  'IndexItemEndTime',
];

export const timeArray = ['IndexItemLiveTime', 'IndexItemEndTime'];

export const matchOperator = {
  0: 'All',
  1: 'Any of the following rules',
};

export const optionNames = {
  item: [
    { name: 'Index Item Name', value: 'IndexItemName' },
    { name: 'Index Item ID', value: 'IndexItemAdditionalIndexId' },
    { name: 'Date added', value: 'IndexItemCreatedOn' },
    { name: 'Status', value: 'IndexItemState' },
    { name: 'Go Live Date', value: 'IndexItemLiveDate' },
    { name: 'End Date', value: 'IndexItemEndDate' },
    { name: 'Go Live Time', value: 'IndexItemLiveTime' },
    { name: 'End time', value: 'IndexItemEndTime' },
  ],
};
