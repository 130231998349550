import styled from 'styled-components';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

const UnitNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextField = styled(TextFieldAdapter)`
  width: 90% !important;
`;

export { UnitNameWrapper, FormContainer, TextField };

export const Blank = () => {};
