const FORM_FIELDS = [
  { type: 'number', name: 'estimatedHours', placeholder: '0.00 hours', label: 'Estimated hours this task:' },
  { type: 'number', name: 'estimatedMaterials', placeholder: '0.00', label: 'Estimated materials costs this task:' },
  { type: 'text', name: 'approvedOn', placeholder: '12/12/2022', label: 'Approved on:' },
  { type: 'text', name: 'approvedBy', placeholder: 'Name', label: 'Approved By:' },
  { type: 'text', name: 'approvedByRole', placeholder: 'Role', label: 'Role:' },
];

const formatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}).format;

export { FORM_FIELDS, formatter };
