import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

import { ButtonIcon } from 'components/Layout/Buttons';
import { HomeIcon, AttentionIcon } from 'components/Layout/Icons';

export const Container = styled(Flex).attrs({
  align: 'center',
})``;

export const CurrentLocationLabel = styled(Box).attrs({
  ml: [0, 1],
})`
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 500;
  font-size: 18px;
  min-width: 48px;
`;

export const HomeButton = styled(ButtonIcon).attrs({
  tooltip: 'Home',
})``;

export const IconHome = styled(HomeIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;

export const ActivatorButton = styled(ButtonIcon).attrs({
  tooltip: 'Activator',
})``;

export const ActivatorIcon = styled(AttentionIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;
