import { VisibilityIcon } from 'components/Layout/Icons';
import { IconButton } from 'material-ui';
import styled from 'styled-components';

const RootContainer = styled.div`
  padding: 1rem 2rem;
  min-width: 25vw;
`;

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Field = styled.span`
  font-weight: 600;
  font-size: 1.1rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-block-start: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

const IconWrapper = styled(IconButton)`
  width: auto !important;
  height: auto !important;
  padding: 0px !important;
  flex: 1;
`;
// ${({ theme }) => theme.}
const EyeIcon = styled(VisibilityIcon)`
  color: #f19c38 !important;
  width: 30px !important;
  height: 30px !important;
`;

const EmptyText = styled.p`
  text-align: center;
`;

const Name = styled.div`
  flex: 10;
`;

export { RootContainer, FieldWrapper, Field, List, ListItem, IconWrapper, EyeIcon, EmptyText, Name };
