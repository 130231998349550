import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useDispatch } from 'react-redux';

import { Switch } from 'components/Switch';
import { Textarea } from 'components/Textarea';
import { InformationIcon } from 'components/Layout/Icons';
import { useAppSelector } from 'redux/reducers';
import { actions as tasksActions } from 'redux/tasks';
import { USER_TYPES } from 'configs/enums';
import { PartGroupType, PartIssueEstimationType, PartType } from 'types/task';

import { AdditionalInfo } from '../AdditionalInfo';
import { PartDetailsDialog } from '../PartDetailsDialog';
import { PartIssueEstimationDialog } from '../PartIssueEstimationDialog';
import { DividerLine } from 'components/Dialogs/styled';
import * as S from './PartsRequiringAttentionStyles';

type PropsType = {
  formik: any;
};

export const PartsRequiringAttention = ({ formik }: PropsType) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const userType = useAppSelector(state => state.auth.user.userType);
  const parts: Array<PartType & { IsAssigned: boolean }> = useAppSelector(state => state.tasks.parts);
  const partGroups: Array<PartGroupType & { IsAssigned: boolean }> = useAppSelector(state => state.tasks.partGroups);
  const dispatch = useDispatch();

  const onPartsRequiringAttentionToggle = (e: React.MouseEvent, isInputChecked: boolean) =>
    formik.setFieldValue('isPartsRequireAttention', isInputChecked);

  const onPartsChange = (partId: string, isChecked: boolean) => {
    const newParts = parts.map((part: PartType & { IsAssigned: boolean }) =>
      part.Id === partId ? { ...part, IsAssigned: isChecked } : part,
    );
    dispatch(tasksActions.changeParts(newParts));
  };

  const onPartGroupsChange = (partId: string, isChecked: boolean) => {
    const newPartGroups = partGroups.map((part: PartGroupType & { IsAssigned: boolean }) =>
      part.Id === partId ? { ...part, IsAssigned: isChecked } : part,
    );
    dispatch(tasksActions.changePartGroups(newPartGroups));
  };

  const renderPartInfo = () =>
    parts.map((part: PartType & { IsAssigned: boolean }) => {
      const onPartCheckHandler = (event: React.MouseEvent, value: boolean) => onPartsChange(part.Id, value);

      const onInfoIconHandler = () => NiceModal.show(PartDetailsDialog, { partId: part.Id, onPartsChange });

      return (
        <S.Body key={part.Id}>
          <S.Col>
            <S.CheckBox checked={part.IsAssigned} onCheck={onPartCheckHandler} />
          </S.Col>
          <S.PartInfo>{part.MyPartID}</S.PartInfo>
          <S.PartInfo>{part.Name}</S.PartInfo>
          <S.RightActionIcon onClick={onInfoIconHandler}>
            <InformationIcon />
          </S.RightActionIcon>
        </S.Body>
      );
    });

  const renderPartGroupsInfo = () =>
    partGroups.map((part: PartGroupType & { IsAssigned: boolean }) => {
      const onPartCheckHandler = (event: React.MouseEvent, value: boolean) => onPartGroupsChange(part.Id, value);

      return (
        <S.Body key={part.Id}>
          <S.Col>
            <S.CheckBox checked={part.IsAssigned} onCheck={onPartCheckHandler} />
          </S.Col>
          <S.PartInfo>{part.Name}</S.PartInfo>
          <S.PartInfo>{part.Description}</S.PartInfo>
        </S.Body>
      );
    });

  const setPartIssueEstimationDto = (partIssueEstimationDto: PartIssueEstimationType) =>
    formik.setFieldValue('partIssueEstimationDto', partIssueEstimationDto);

  const onMonetizationIconClick = () =>
    NiceModal.show(PartIssueEstimationDialog, {
      partIssueEstimationDto: formik.values.partIssueEstimationDto,
      setPartIssueEstimationDto,
    });

  return (
    <AdditionalInfo
      name="Parts Requiring Attention"
      isShown
      content={
        <div>
          <Switch
            name="isPartsRequiringAttention"
            toggled={formik.values.isPartsRequireAttention}
            onToggle={onPartsRequiringAttentionToggle}
          />
          {formik.values.isPartsRequireAttention && (
            <>
              <S.PartInfoContainer column>
                <S.Label>Parts Info</S.Label>
                <S.PartInfoSection>
                  <S.Body>
                    <S.Col />
                    <S.PartInfo>Part ID</S.PartInfo>
                    <S.PartInfo>Part Name</S.PartInfo>
                    <S.PartInfo>Part Details</S.PartInfo>
                  </S.Body>
                  <DividerLine />
                  {renderPartInfo()}
                </S.PartInfoSection>
              </S.PartInfoContainer>

              <S.PartInfoContainer column>
                <S.Label>Part Groups Info</S.Label>
                <S.PartInfoSection>
                  <S.Body>
                    <S.Col />
                    <S.PartInfo>Group Name</S.PartInfo>
                    <S.PartInfo>Group Description</S.PartInfo>
                  </S.Body>
                  <DividerLine />
                  {renderPartGroupsInfo()}
                </S.PartInfoSection>
              </S.PartInfoContainer>

              <S.BoldLabel>Explain Part(s) issue</S.BoldLabel>
              <Textarea
                {...formik.getFieldProps('partResolution')}
                meta={formik.getFieldMeta('partResolution')}
                name="partResolution"
              />

              <S.CostsInfoContainer>
                <S.MonetizationIcon onClick={onMonetizationIconClick} />
                <S.BoldLabel>Time/Costs Estimation</S.BoldLabel>
              </S.CostsInfoContainer>

              <S.BoldLabel>Part(s) Approval Notes</S.BoldLabel>
              <Textarea
                {...formik.getFieldProps('approvalNotes')}
                meta={formik.getFieldMeta('approvalNotes')}
                name="approvalNotes"
                isDisabled={USER_TYPES.Manager !== userType}
              />
            </>
          )}
        </div>
      }
    />
  );
};
