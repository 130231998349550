import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.lightGrey};
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px 2px;
`;

const StaticticsHeader = styled.span`
  color: ${({ theme }) => theme.primarySettings};
  font-size: 22px;
`;

const StatisticsModules = styled(Flex).attrs({
  flex: 'none',
  justify: 'space-around',
})`
  width: 100%;
  margin-top: 20px;
`;

const StatisticsModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatisticsValue = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 50% !important;
  background-color: ${({ color }) => color} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 200;
  font-size: 24px;
`;

const StatisticsLabel = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.primaryBlack};
  text-transform: uppercase;
`;

const TasksNumberContainer = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 60px;
  height: 35px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primaryRed};
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  StatisticsContainer,
  StaticticsHeader,
  StatisticsModules,
  StatisticsModuleContainer,
  StatisticsValue,
  StatisticsLabel,
  TasksNumberContainer,
};
