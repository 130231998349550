/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import { ChevronDown, ChevronUp, PdfIcon, WarningIcon } from 'components/Layout/Icons';
import { FlatButton, Slider } from 'material-ui';
import { getMuiTheme } from 'material-ui/styles';
import theme, { SensorLevelColors } from 'theme';

const muiTheme = getMuiTheme({
  slider: {
    trackColor: theme.primaryGrey,
    selectionColor: theme.primaryTasks,
    handleColorZero: theme.primaryTasks,
    handleFillColor: theme.primaryTasks,
    handleSize: 24,
    handleSizeActive: 24,
    trackSize: 6,
    rippleColor: theme.primaryTasks,
  },
});

const ItemName = styled.div`
  font-weight: bold;
`;

const Main = styled.div`
  background: ${({ theme }) => theme.primaryHealth};
  text-align: center;
  padding: 20px;
  position: relative;
`;

const IconButtonContainer = styled.div`
  background: ${({ theme }) => theme.primaryWhite};
  margin: 20px 0;
  padding: 4px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const IconButton = styled(FlatButton).attrs({
  labelStyle: {
    textTransform: 'capitalize',
    fontWeight: 'lighter',
  },
})``;

const VisibilityIconButton = styled(FlatButton).attrs({
  labelStyle: ({ theme }) => ({
    color: theme.darkGrey,
    textTransform: 'capitalize',
    fontWeight: 'lighter',
    fontSize: '1rem',
  }),
})``;

const AlertIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.primaryWhite} !important;
`;

const Wrapper = styled.div<{ isPhotosShown: boolean; status: keyof typeof SensorLevelColors }>`
  background: ${({ theme, status }) => (status ? SensorLevelColors[status] : theme.primaryGrey)};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto;
  position: absolute;
  left: 46%;
  top: ${({ isPhotosShown }) => (isPhotosShown ? 7 : 12)}%;
`;

const PDFIcon = styled(PdfIcon)`
  fill: ${({ theme }) => theme.primaryGrey} !important;
  width: 36px !important;
  height: 36px !important;
`;

const Text = styled.span`
  font-weight: 300;
  font-size: 0.8rem;
  padding: 10px;
`;

const textareaStyles = css`
  border: 1px solid ${({ theme }) => theme.primaryTasks};
`;

const errorStyles = css`
  border: 1px solid ${({ theme }) => theme.mainRed};
`;

const inputStyles = css`
  padding: 0.4rem;
  border: 1px solid ${({ theme }) => theme.primaryTasks};
  color: ${({ theme }) => theme.mainRed};
  width: 50%;
  font-weight: bold;
  font-size: 1.2rem;
`;

const inputWrapperStyles = css`
  flex-direction: column;
`;

const TaskNotesContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.primaryGrey};
  text-align: start;
  padding: 0px 28px 16px;
`;

const Name = styled.div`
  padding: 6px 0;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.primaryGrey};
`;

const Note = styled.div`
  font-size: 0.8rem;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
`;

const InfoContainer = styled.div`
  overflow: hidden;
  border-radius: 10px;
  background: ${({ theme }) => theme.primaryWhite};
`;

const FeedbackContainer = styled.div``;

const RedText = styled.div`
  color: ${({ theme }) => theme.mainRed};
  padding-bottom: 1rem;
  font-size: 1.13rem;
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 10px;
`;

const RangeSlider = styled(Slider)`
  width: 100%;

  div > div:not(:last-child) {
    border-radius: 6px;
  }
`;

const PhotoInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  cursor: default;
`;

const ButtonContainer = styled.div`
  margin: 20px;
`;

const buttonStyles = css`
  background: #f19c38;
  outline: none;
  border: 0;
  padding: 12px 30px;
  color: ${({ theme }) => theme.primaryWhite};
  border-radius: 4px;
  margin: 4px;
  font-size: 1rem;
  cursor: pointer;
`;

const Button = styled.button`
  ${buttonStyles}
`;

const Label = styled.label`
  ${buttonStyles}
`;

const Value = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
`;

const Message = styled.div<{ error?: string }>`
  color: ${({ theme, error }) => (error ? theme.mainRed : theme.primaryBlack)}
  font-size: 0.8rem;
`;

const VerificationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
`;

const VerificationIconWrapper = styled.div<{ isSelected: boolean }>`
  background: ${({ theme, isSelected }) => (isSelected ? '#f19c38' : theme.primaryWhite)};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.primaryTasks};
  border-radius: 4px;

  & img {
    width: 36px !important;
    height: 36px !important;
  }
`;

const WarningText = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
`;

const RuleDataContainer = styled.div`
  padding: 0 10px;
`;

const iconStyles = css`
  fill: ${({ theme }) => theme.primaryTasks} !important;
  width: 40px !important;
  height: 40px !important;
`;

const ChevronDownIcon = styled(ChevronDown)`
  ${iconStyles}
`;

const ChevronUpIcon = styled(ChevronUp)`
  ${iconStyles}
`;

export {
  ItemName,
  Main,
  IconButtonContainer,
  IconButton,
  PDFIcon,
  AlertIcon,
  Wrapper,
  Text,
  TaskNotesContainer,
  Name,
  Note,
  ToggleContainer,
  InfoContainer,
  FeedbackContainer,
  RedText,
  SliderContainer,
  RangeSlider,
  PhotoInfoContainer,
  ButtonContainer,
  Button,
  Label,
  Value,
  Message,
  WarningText,
  VerificationContainer,
  VerificationIconWrapper,
  textareaStyles,
  inputStyles,
  inputWrapperStyles,
  errorStyles,
  muiTheme,
  RuleDataContainer,
  VisibilityIconButton,
  ChevronDownIcon,
  ChevronUpIcon,
};
