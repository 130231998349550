import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import theme from 'theme';
import { AssignedTaskType, ToggleItem } from 'configs/propTypes';
import { getSelectedPeriodUnits, getSelectedPeriodUnitsNames } from 'redux/tasks/selectors';
import EmptyList from 'components/EmptyList';
import UnitListTable from 'components/UnitListTable';
import { ListContainer } from 'components/Layout/Containers';
import moment from 'moment';
import { CollectionsIcon, PdfIcon } from 'components/Layout/Icons';
import { PhotosDialog } from '../../TaskDetails/PhotosDialog';
import { PdfsDialog } from '../../TaskDetails/PdfsDialog';

import * as S from './styled';

const emptyListMessage = 'There are no tasks';
const getItemName = item => item.ItemName;
const getItemText = item => item.OriginatingTaskName || item.RuleType;
const getCorrectTimeFormat = (units, unit) =>
  units[unit][0].ExecutionDate === units[unit][0].UnitName
    ? moment(units[unit][0].UnitName).format('dddd, MMM D, YYYY')
    : units[unit][0].UnitName;

const List = ({
  checked,
  onCheck,
  onItemClick,
  renderLeftControls,
  renderRightControls,
  tasksType,
  units,
  unitsNames,
  showLeftCheckbox,
}) => {
  const openPdfDialog = unitName => {
    const [task] = units[unitName];

    NiceModal.show(PdfsDialog, { unitId: task.UnitId, type: 'unit' });
  };

  const openPhotosDialog = unitName => {
    const [task] = units[unitName];

    NiceModal.show(PhotosDialog, { task });
  };

  return (
    <ListContainer>
      {unitsNames.length ? (
        unitsNames.map((unit, index) => (
          <UnitListTable
            key={`Unit${index}`}
            getItemName={getItemName}
            getItemText={getItemText}
            leftCheckbox={showLeftCheckbox || tasksType.key === 'my'}
            checked={checked}
            onUnitItemClick={onItemClick}
            onCheck={onCheck}
            unitColor={theme.primaryTasks}
            unitData={units[unit]}
            renderRightControls={renderRightControls}
            renderLeftControls={renderLeftControls}
            unitName={getCorrectTimeFormat(units, unit)}
            customControls={
              <S.Controls>
                <S.Wrapper>
                  <S.Text>Unit Pdf&apos;s</S.Text>
                  <PdfIcon onClick={() => openPdfDialog(unit)} />
                </S.Wrapper>
                <S.Wrapper>
                  <S.Text>Unit Photos</S.Text>
                  <CollectionsIcon onClick={() => openPhotosDialog(unit)} />
                </S.Wrapper>
              </S.Controls>
            }
          />
        ))
      ) : (
        <EmptyList text={emptyListMessage} />
      )}
    </ListContainer>
  );
};

List.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCheck: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  period: ToggleItem.isRequired, // eslint-disable-line react/no-unused-prop-types
  renderLeftControls: PropTypes.func.isRequired,
  renderRightControls: PropTypes.func.isRequired,
  tasksType: ToggleItem.isRequired,
  units: PropTypes.objectOf(PropTypes.arrayOf(AssignedTaskType)).isRequired,
  unitsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  showLeftCheckbox: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ tasks }, { period, tasksType }) => ({
  units: getSelectedPeriodUnits(tasks, period, tasksType),
  unitsNames: getSelectedPeriodUnitsNames(tasks, period, tasksType),
});

export default connect(mapStateToProps)(List);
