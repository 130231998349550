import { createSelector } from 'reselect';

export const getAssignedList = createSelector(
  (people, sites) => sites.assignedUsers,
  people => people.list,
  (assignedUsersIds, peopleList) =>
    peopleList.map(user => ({
      ...user,
      checked: assignedUsersIds.includes(user.Id),
    })),
);

export const selector = () => {};
