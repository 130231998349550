import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { PartsListType } from 'configs/propTypes';

import { openErrorDialog } from 'redux/errorHandler';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as partsActions } from 'redux/parts';

import Subheader from 'components/Subheader';
import { AddIcon, BackIcon } from 'components/Layout/Icons';
import TableList from 'components/TableList';
import PartSelectionDialog from 'components/Dialogs/PartSelectionDialog';

import { tableHeads, tableColumns } from './constants';
import * as S from './styled';
import theme from 'theme';

class GroupParts extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      addPartGroupLinkRequest: PropTypes.func.isRequired,
      deletePartsGroupLinkRequest: PropTypes.func.isRequired,
      getPartsByGroupIdRequest: PropTypes.func.isRequired,
      getPartsRequest: PropTypes.func.isRequired,
      openConfirmationDialog: PropTypes.func.isRequired,
      deletePartFromGroupRequest: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        group: PropTypes.shape({
          Name: PropTypes.string.isRequired,
          Description: PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        groupId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    list: PartsListType.isRequired,
    fullPartsList: PartsListType.isRequired,
  };

  state = {
    searchData: '',
    partSelectionDialogOpen: false,
  };

  componentDidMount() {
    const {
      actions,
      match: { params },
    } = this.props;

    actions.getPartsByGroupIdRequest({ groupId: params.groupId });
    actions.getPartsRequest();
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;

    if (nextProps.error) {
      actions.openErrorDialog(nextProps.error, 'Error');
    }
  }

  openPartsSelectionDialog = () => this.setState({ partSelectionDialogOpen: true });
  closePartsSelectionDialog = () => this.setState({ partSelectionDialogOpen: false });
  submitPartSelection = values => {
    const {
      match: { params },
    } = this.props;

    const partIDs = Object.entries(values)
      .filter(([, value]) => value)
      .map(([key]) => key);

    this.props.actions.addPartGroupLinkRequest({ partGroupDto: { Id: params.groupId }, partIDs });

    this.closePartsSelectionDialog();
  };

  rightButtons = [{ icon: <AddIcon />, handler: this.openPartsSelectionDialog, hint: 'Link Parts' }];

  deletePart = part => {
    const {
      actions,
      match: { params },
    } = this.props;

    actions.openConfirmationDialog(
      'Are you sure you want to delete this part from this group?',
      () => actions.deletePartFromGroupRequest({ partGroupId: params.groupId, partId: part.Id }),
      'Warning',
      'Cancel',
      'Delete',
      theme.redButton,
    );
  };

  deleteAllParts = () => {
    const {
      actions,
      match: { params },
      list,
    } = this.props;

    if (list.length === 0) return;

    actions.openConfirmationDialog(
      'Are you sure you want to delete all parts from this group?',
      () => actions.deletePartsGroupLinkRequest({ partGroupId: params.groupId }),
      'Warning',
      'Cancel',
      'Delete',
      theme.redButton,
    );
  };

  renderTitle = () => {
    const {
      location: { state },
    } = this.props;

    const groupName = state?.group.Name || 'Group Parts';
    const description = state?.group.Description || '';

    return (
      <div>
        <S.GroupName>{groupName}</S.GroupName>
        <S.GroupDescription>{description}</S.GroupDescription>
      </div>
    );
  };

  searchInList = e => this.setState({ searchData: e.target.value });

  filterList = item =>
    tableColumns
      .map(({ field }) => field)
      .some(
        field =>
          item[field] &&
          typeof item[field] === 'string' &&
          item[field].toLowerCase().includes(this.state.searchData.toLowerCase()),
      );

  goBack = () => this.props.history.goBack();

  tableHandlers = {
    deleteAllParts: this.deleteAllParts,
    deletePart: this.deletePart,
  };

  render() {
    const { searchData, partSelectionDialogOpen } = this.state;
    const { list, fullPartsList } = this.props;

    const listIds = list.map(({ Id }) => Id);
    const unlinkedPartsList = fullPartsList.filter(part => !listIds.includes(part.Id));

    return (
      <>
        <Subheader
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.goBack,
              hint: 'Back',
            },
          ]}
          rightButtons={this.rightButtons}
          title={this.renderTitle()}
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
        />
        <S.TableContainer>
          <TableList
            tableHeads={tableHeads}
            list={list.filter(this.filterList)}
            tableColumns={tableColumns}
            renderRightControllCell={this.renderRightControllCell}
            handlers={this.tableHandlers}
          />
        </S.TableContainer>
        <PartSelectionDialog
          open={partSelectionDialogOpen}
          title="Select Part"
          submitLabel="Add Selection to Group"
          handleClose={this.closePartsSelectionDialog}
          onSubmit={this.submitPartSelection}
          parts={unlinkedPartsList}
        />
      </>
    );
  }
}

const mapStateToProps = ({ parts }) => ({
  error: parts.error,
  fullPartsList: parts.list,
  list: parts.groupPartsList,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openConfirmationDialog,
      openErrorDialog,
      ...partsActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupParts);
