import React from 'react';

import { Group } from 'modules/globalsettings/pages/styled';

import * as S from './styled';

const fields = [
  { field: 'Name', title: '' },
  { field: 'MeeteringGroupName', title: 'Metering Group Name:' },
];

const Item = group => (
  <Group>
    {fields.map(({ field, title }) => (
      <S.GroupItem key={field}>
        {title} {group[field]}
      </S.GroupItem>
    ))}
  </Group>
);

export default Item;
