import { ChevronDown, ChevronUp } from 'components/Layout/Icons';
import { IconButton } from 'material-ui';
import styled, { css } from 'styled-components';

const IconWrapper = styled(IconButton)<{ hasMarginRight?: boolean }>`
  width: auto !important;
  height: auto !important;
  padding: 0px !important;

  ${({ hasMarginRight }) =>
    hasMarginRight &&
    css`
      margin-right: 16px !important;
    `}
`;

const iconStyles = css`
  color: ${({ theme }) => theme.primaryWhite} !important;
  width: 30px !important;
  height: 30px !important;
`;

const ArrowUpIcon = styled(ChevronUp)`
  ${iconStyles}
`;

const ArrowDownIcon = styled(ChevronDown)`
  ${iconStyles}
`;

export { IconWrapper, ArrowUpIcon, ArrowDownIcon };
