import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Container = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  px: [2, 3],
  py: 1,
  w: 1,
})`
  background-color: ${({ color }) => color};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  color: ${({ textсolor, theme }) => textсolor || theme.primaryWhite};
  font-size: 16px;
  font-weight: 500;
  min-width: 0;
  position: sticky;
  top: 0;
  word-wrap: break-word;
  word-break: break-all;
  z-index: 2;
`;
