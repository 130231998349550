import moment from 'moment';

import { periodToggle, taskToggle } from 'configs/toggles';

const initialState = {
  error: '',
  loading: false,
  scanCodeLoading: false,
  loadingUser: false,
  users: [],
  tasks: {
    day: {
      my: { tasks: {}, amount: 0 },
      team: { tasks: {}, amount: 0 },
      pickUp: { tasks: {}, amount: 0 },
    },
    week: {
      my: { tasks: {}, amount: 0 },
      team: { tasks: {}, amount: 0 },
      pickUp: { tasks: {}, amount: 0 },
    },
    past: {
      my: { tasks: {}, amount: 0 },
      team: { tasks: {}, amount: 0 },
      pickUp: { tasks: {}, amount: 0 },
    },
  },
  currentUser: {},
  periodSelected: periodToggle[0],
  tasksSelected: taskToggle[0],
  rangeStart: moment(),
  taskDetails: {},
  taskPhotos: [],
  taskNotes: [],
  lastScanCode: {},
  page: 1,
  rowsPerPage: 25,
  taskCount: {
    day: 0,
    week: 0,
    past: 0,
  },
  isTaskUpdated: false,
  reportPhotos: [],
  reportPhoto: '',
  parts: [],
  partGroups: [],
};

export const usersListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const usersListSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  users: payload,
});
export const usersListFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const fetchUserRequest = state => ({
  ...state,
  error: '',
  loadingUser: true,
});
export const fetchUserSuccess = (state, { payload }) => ({
  ...state,
  loadingUser: false,
  currentUser: payload,
});
export const fetchUserFailure = (state, { payload }) => ({
  ...state,
  loadingUser: false,
  error: payload.message,
});

export const fetchTasksRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchTasksSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  tasks: payload,
});
export const fetchTasksFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const fetchDetailsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchDetailsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  taskDetails: payload,
  lastScanCode: {},
});
export const fetchDetailsFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});
export const clearTaskDetails = state => ({
  ...state,
  taskDetails: {},
});

export const fetchPhotosRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchPhotosSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  taskPhotos: payload,
});
export const fetchPhotosFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const fetchNotesRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchNotesSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  taskNotes: payload,
});
export const fetchNotesFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const releaseTaskRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const releaseTaskSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  taskNotes: payload,
});
export const releaseTaskFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const assignTaskRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const assignTaskSuccess = state => ({
  ...state,
  loading: false,
});
export const assignTaskFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const saveTaskRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const saveTaskSuccess = state => ({
  ...state,
  loading: false,
});
export const saveTaskFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const saveNoteRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const saveNoteSuccess = state => ({
  ...state,
  loading: false,
});
export const saveNoteFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const updatePage = (state, { payload }) => ({
  ...state,
  page: payload,
});
export const updateRowsPerPage = (state, { payload }) => ({
  ...state,
  rowsPerPage: payload,
});
export const updatePeriod = (state, { payload }) => ({
  ...state,
  periodSelected: payload,
});
export const updateTasksType = (state, { payload }) => ({
  ...state,
  tasksSelected: payload,
});
export const updateRangeStart = (state, { payload }) => ({
  ...state,
  rangeStart: payload,
});
export const resetRangeStart = state => ({
  ...state,
  rangeStart: moment(),
});

export const resetPeriod = state => ({
  ...state,
  periodSelected: periodToggle[0],
});
export const resetTasksType = state => ({
  ...state,
  tasksSelected: taskToggle[0],
});

export const uploadPhotoTasksRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const uploadPhotoTasksSuccess = state => ({
  ...state,
  loading: false,
});
export const uploadPhotoTasksFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const uploadScanCodeRequest = state => ({
  ...state,
  error: '',
  loading: true,
  scanCodeLoading: true,
});
export const uploadScanCodeSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  scanCodeLoading: false,
  lastScanCode: payload,
});
export const uploadScanCodeFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  scanCodeLoading: false,
  error: payload.error,
  lastScanCode: payload.lastScanCode,
});

export const sendTaskInfoEmailRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const sendTaskInfoEmailSuccess = state => ({
  ...state,
  loading: false,
});
export const sendTaskInfoEmailFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const getFilteredTaskCountRequest = state => ({
  ...state,
  loading: true,
});

export const getFilteredTaskCountSuccess = (state, { payload }) => ({
  ...state,
  taskCount: { ...state.taskCount, ...payload },
  loading: false,
});

export const deletePhotoRequest = state => ({
  ...state,
  loading: true,
});

export const setIsLoading = (state, { payload }) => ({
  ...state,
  loading: payload,
});

export const setIsTaskUpdated = (state, { payload }) => ({
  ...state,
  isTaskUpdated: payload,
});

export const fetchReportPhotoRequest = state => ({
  ...state,
  loading: true,
});

export const fetchReportPhotoSuccess = (state, { payload }) => ({
  ...state,
  reportPhoto: payload,
});

export const editReportPhotoRequest = state => ({
  ...state,
  loading: true,
});

export const uploadReportPhotoRequest = state => ({
  ...state,
  loading: true,
});

export const deleteReportPhotoRequest = state => ({
  ...state,
  loading: true,
});

export const getReportPhotosListRequest = state => ({
  ...state,
  loading: true,
});

export const getReportPhotosListSuccess = (state, { payload }) => ({
  ...state,
  reportPhotos: payload,
});

export const changeParts = (state, { payload }) => ({
  ...state,
  parts: payload,
});

export const changePartGroups = (state, { payload }) => ({
  ...state,
  partGroups: payload,
});

export default initialState;
