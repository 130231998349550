import styled from 'styled-components';

import { NotificationOn } from 'components/Layout/Icons';

export const SelectCheckobesContainer = styled.div`
  padding: 0 30px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActivateLabel = styled.span`
  margin-left: 20px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ActivateChecbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SelectCheckobesLabel = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const ListItem = styled.li`
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const ItemNameAndCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemValue = styled.div`
  color: ${({ theme }) => theme.primaryDark};
  display: flex;
  align-items: center;
`;

export const ValueIcon = styled(NotificationOn)`
  margin-left: 20px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
`;
