import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import theme from 'theme';

import BaseDialog from 'components/Dialogs/Base';
import SearchField from 'components/Layout/Search';
import { FieldText } from 'components/Layout/Inputs';
import { ButtonIcon } from 'components/Layout/Buttons';
import { AddIcon, CheckIcon, DeleteIcon, EditIcon } from 'components/Layout/Icons';

import { openConfirmationDialog } from 'redux/confirmationHandler';

import * as S from './styled';

const EditableListDialog = ({
  open,
  searchPlaceholder,
  title,
  idTitle,
  handleClose,
  addItem,
  editItem,
  deleteItem,
  defaultList,
}) => {
  const [fieldsToEdit, setFieldsToEdit] = useState([]);
  const [list, setList] = useState(defaultList);
  const [newItem, setNewItem] = useState('');
  const [searchData, setSearchData] = useState('');
  const dispatch = useDispatch();

  const onInputChange = (e, id) => {
    setList(list.map(item => (item.Id === id ? { ...item, Name: e.target.value } : item)));
  };

  const editItemHandler = (id, name) => {
    if (fieldsToEdit.includes(id)) {
      const filteredFields = fieldsToEdit.filter(field => field !== id);
      const editedItem = list.find(({ Id }) => Id === id);

      const isValueNotChanged = defaultList.some(({ Name, Id }) => Id === id && Name === name);

      if (isValueNotChanged) {
        setFieldsToEdit(filteredFields);
        return;
      }

      if (editedItem) {
        dispatch(editItem({ Id: id, Name: editedItem.Name }));
      }

      setFieldsToEdit(filteredFields);
    } else {
      setFieldsToEdit(prevState => [...prevState, id]);
    }
  };

  const openConfirmationDeleteItem = id => {
    dispatch(
      openConfirmationDialog(
        'Are you sure you want to permanently delete this Item? This cannot be undone!',
        () => dispatch(deleteItem({ [idTitle]: id })),
        'Delete?',
        'No',
        'Yes',
        theme.redButton,
      ),
    );
  };

  const onNewItemChange = e => setNewItem(e.target.value);

  const addNewItem = () => {
    dispatch(addItem({ Name: newItem }));
    setNewItem('');
  };

  // eslint-disable-next-line no-shadow
  const onSearch = (list, searchData) => {
    setList(list);
    setSearchData(searchData);
  };

  const onClose = () => {
    setSearchData('');
    setList([]);
    setFieldsToEdit([]);
    setNewItem('');
    handleClose();
  };

  useEffect(() => {
    if (open && defaultList.length && !list.length) {
      setList(defaultList);
    }
  }, [defaultList, list, open]);

  return (
    <BaseDialog open={open} title={`Edit ${title}`} onRequestClose={onClose}>
      <S.DialogContainer>
        <S.SearchContainer>
          <SearchField
            list={list}
            filterBy="Name"
            searchData={searchData}
            placeholder={searchPlaceholder}
            inputStyle={S.searchInputStyles}
            setSearchedItems={onSearch}
          />
        </S.SearchContainer>
        <S.List>
          {list.length > 0 ? (
            list.map(({ Name, Id }) => (
              <S.Item key={Id}>
                <FieldText
                  value={Name}
                  id={Id}
                  onChange={e => onInputChange(e, Id)}
                  underlineShow={fieldsToEdit.includes(Id)}
                  disabled={!fieldsToEdit.includes(Id)}
                  errorText={!Name && 'Name is required!'}
                />
                <S.ItemControls>
                  <ButtonIcon disabled={!Name} onClick={() => editItemHandler(Id, Name)}>
                    {fieldsToEdit.includes(Id) ? <CheckIcon /> : <EditIcon />}
                  </ButtonIcon>
                  <ButtonIcon onClick={() => openConfirmationDeleteItem(Id)}>
                    <DeleteIcon />
                  </ButtonIcon>
                </S.ItemControls>
              </S.Item>
            ))
          ) : (
            <S.EmptyListContainer>{`There are no ${title}`}</S.EmptyListContainer>
          )}
        </S.List>
      </S.DialogContainer>
      <S.NewItemContainer>
        <FieldText value={newItem} onChange={onNewItemChange} hintText={`New ${title.split(' ')[0]} name`} />
        <ButtonIcon disabled={!newItem} onClick={addNewItem}>
          <AddIcon />
        </ButtonIcon>
      </S.NewItemContainer>
    </BaseDialog>
  );
};

EditableListDialog.propTypes = {
  defaultList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  open: PropTypes.bool.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  idTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

EditableListDialog.defaultProps = {
  error: '',
};

export default EditableListDialog;
