import React from 'react';
import { Toggle } from 'material-ui';
import * as S from './SwitchStyles';

type SwitchPropsType = {
  name: string;
  toggled: boolean;
  onToggle: (e: React.MouseEvent, isInputChecked: boolean) => void;
};

export const Switch = ({ name, toggled, onToggle }: SwitchPropsType) => (
  <Toggle
    name={name}
    toggled={toggled}
    onToggle={onToggle}
    thumbStyle={S.toggleStyles.thumbOff}
    trackStyle={S.toggleStyles.trackOff}
    thumbSwitchedStyle={S.toggleStyles.thumbSwitched}
    trackSwitchedStyle={S.toggleStyles.trackSwitched}
  />
);
