import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import AlertsList from './pages/AlertsList';

const Health = () => (
  <Switch>
    <Route exact path="/health" component={AlertsList} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Health;
