import styled, { css } from 'styled-components';
import { Box } from 'grid-styled';

import BaseDialog from 'components/Dialogs/Base';

export const DialogContainer = styled(BaseDialog).attrs({
  actionsContainerStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
})`
  & > div > div {
    width: 95% !important;
  }

  @media only screen and (min-width: 32em) {
    & > div > div {
      width: 70% !important;
    }
  }

  @media only screen and (min-width: 48em) {
    & > div > div {
      width: 60% !important;
    }
  }

  @media only screen and (min-width: 64em) {
    & > div > div {
      width: 50% !important;
    }
  }

  @media only screen and (min-width: 80em) {
    & > div > div {
      width: 35% !important;
    }
  }
`;

const textStyle = css`
  font-size: 16px;
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
`;

export const PrimaryText = styled(Box).attrs({
  p: 24,
})`
  ${textStyle};
  font-weight: 600;
`;

export const SecondaryText = styled(Box).attrs({
  p: 24,
  pt: 0,
})`
  ${textStyle};
  font-weight: 300;
`;
