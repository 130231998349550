import React from 'react';
import { DeleteIcon } from 'components/Layout/Icons';

export const tableHeads = [
  { title: 'OEM Part ID' },
  { title: 'My Part ID' },
  { title: 'My Part Name' },
  { title: 'My Part Category' },
  { title: 'My Part Type' },
  { title: 'My Part Unit' },
  { title: '', headerIcon: <DeleteIcon />, handlerName: 'deleteAllParts' },
];

export const tableColumns = [
  { field: 'OEMPartID' },
  { field: 'MyPartID' },
  { field: 'MyPartName' },
  { field: 'MyPartCategory' },
  { field: 'MyPartType' },
  { field: 'MyPartUnitOfMeasure' },
  { field: 'delete', icon: <DeleteIcon />, handlerName: 'deletePart' },
];
