const initialState = {
  indexItems: [],
  indexItem: {},
  error: '',
  loading: false,
  listIndexRule: [],
  indexItemPreview: [],
  indexItemCharacteristicPreview: [],
  shortcutPreview: [],
  indexItemLoading: false,
  shortcutPreviewLoading: false,
  status: 'idle',
  characteristicList: [],
  characteristicItem: {},
  indexItemConfig: {},
  isLoading: false,
};

export const setIndexItemsSuccess = (state, { payload }) => ({
  ...state,
  indexItems: payload,
  loading: false,
});

export const setIndexItemsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchAllIndexItemsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const fetchListIndexRuleRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const fetchListIndexRuleSuccess = (state, { payload }) => ({
  ...state,
  listIndexRule: payload,
  loading: false,
});

export const fetchListIndexRuleFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchIndexItemPreviewRequest = state => ({
  ...state,
  error: '',
  indexItemLoading: true,
});

export const fetchIndexItemPreviewSuccess = (state, { payload }) => ({
  ...state,
  indexItemPreview: payload,
  indexItemLoading: false,
});

export const fetchIndexItemPreviewFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  indexItemLoading: false,
});

export const fetchIndexItemCharacteristicPreviewRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const fetchIndexItemCharacteristicPreviewSuccess = (state, { payload }) => ({
  ...state,
  indexItemCharacteristicPreview: payload,
  loading: false,
});

export const fetchShortcutPreviewRequest = state => ({
  ...state,
  error: '',
  shortcutPreviewLoading: true,
});

export const fetchShortcutPreviewSuccess = (state, { payload }) => ({
  ...state,
  shortcutPreview: payload,
  shortcutPreviewLoading: false,
});

export const fetchShortcutPreviewFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  shortcutPreviewLoading: false,
});

export const fetchIndexItemCharacteristicPreviewFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchIndexItemConfigRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const setIndexItemConfigSuccess = (state, { payload }) => ({
  ...state,
  indexItemConfig: payload,
  isLoading: false,
});

export const fetchIndexItemConfigFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const fetchIndexItemCharacteristicConfigRequest = state => ({
  ...state,
  error: '',
  loadingConfig: true,
});

export const fetchIndexItemCharacteristicConfigSuccess = (state, { payload }) => ({
  ...state,
  dataColumns: payload,
  loadingConfig: false,
});

export const fetchIndexItemCharacteristicConfigFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loadingConfig: false,
});

export const createIndexRuleRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const createIndexRuleSuccess = state => ({
  ...state,
  loading: false,
});

export const createIndexRuleFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const editIndexRuleRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const editIndexRuleSuccess = state => ({
  ...state,
  loading: false,
});

export const editIndexRuleFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteIndexRuleRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deleteIndexRuleSuccess = state => ({
  ...state,
  loading: false,
});

export const deleteIndexRuleFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const setIndexItemSuccess = (state, { payload }) => ({
  ...state,
  indexItem: payload,
  isLoading: false,
});

export const setIndexItemFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const fetchIndexItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createIndexItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editIndexItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteIndexItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const setIndexItemsStatus = (state, { payload }) => ({
  ...state,
  status: payload,
});

export const fetchCharacteristicListRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const setCharacteristicListSuccess = (state, { payload }) => ({
  ...state,
  characteristicList: payload,
  isLoading: false,
});

export const fetchCharacteristicItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const setCharacteristicItemSuccess = (state, { payload }) => ({
  ...state,
  characteristicItem: payload,
  isLoading: false,
});

export const createCharacteristicItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const updateCharacteristicItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteCharacteristicItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const updateIndexItemConfigRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export default initialState;
