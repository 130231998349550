import styled from 'styled-components';

export const ConfirmationText = styled.p`
  font-size: 18px;
  width: 49%;
  margin: 30px auto 18px;
  text-align: center;
`;

export const BoldText = styled(ConfirmationText)`
  font-weight: bold;
  margin: 0 auto 20px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ModalButtonsContainer = styled.div`
  height: 50px;
`;
