import { createPdf, deletePdf, getPdfList } from 'http/exportPdf';
import { call, put, takeEvery } from 'redux-saga/effects';
import { openErrorDialog } from 'redux/errorHandler';
import { actions } from './index';
import { CREATE_PDF_DIALOG_MESSAGE, CREATE_PDF_ERROR, CREATE_PDF_DIALOG_TITLE } from './constants';

let payloadForUpdateList;

function* fetchPdfList({ payload }) {
  payloadForUpdateList = payload;
  try {
    const { data } = yield call(getPdfList, payload);
    yield put(actions.getPdfListSuccess(data.root));
  } catch (error) {
    yield put(actions.getPdfListFailure(error));
  }
}

function* fetchDeletePdf({ payload }) {
  try {
    yield call(deletePdf, payload);
    yield put(actions.deletePdfFileSuccess());
    yield put(actions.getPdfListRequest(payloadForUpdateList));
  } catch (error) {
    yield put(actions.deletePdfFileFailure(error));
    yield put(actions.getPdfListFailure(error));
  }
}

function* fetchCreatePdf({ payload }) {
  try {
    yield call(createPdf, payload);
    yield put(actions.createPdfFileSuccess());
    yield put(actions.getPdfListRequest(payloadForUpdateList));
  } catch (error) {
    if (error.response.data.error.includes(CREATE_PDF_ERROR)) {
      yield put(openErrorDialog(CREATE_PDF_DIALOG_MESSAGE, 'Alert', '', CREATE_PDF_DIALOG_TITLE));
    }

    yield put(actions.createPdfFileFailure(error.response.data));
  }
}

const exportPdfSagas = [
  takeEvery(actions.getPdfListRequest, fetchPdfList),
  takeEvery(actions.deletePdfFileRequest, fetchDeletePdf),
  takeEvery(actions.createPdfFileRequest, fetchCreatePdf),
];

export default exportPdfSagas;
