import { ListItem } from 'components/Layout/Section';
import React, { FC } from 'react';
import { ButtonIcon } from 'components/Layout/Buttons';
import { ExportQRIcon, WifiIcon } from 'components/Layout/Icons';

type PropsType = {
  openBeaconDialog: () => void;
  openScanDialog: () => void;
};

export const Verification: FC<PropsType> = ({ openBeaconDialog, openScanDialog }) => (
  <ListItem
    zIndex={5}
    key="Verification"
    name="Verification"
    content={
      <>
        <ButtonIcon onClick={openBeaconDialog} tooltip="Beacons settings">
          <WifiIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openScanDialog} tooltip="QR code settings">
          <ExportQRIcon />
        </ButtonIcon>
      </>
    }
  />
);
