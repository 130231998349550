export const ASSIGNMENT_TYPE = {
  MANAGER: 1,
  TEAM: 2,
  PERSON: 3,
};

export const initialRuleValues = {
  ColumnName: 'ignore',
  MatchOperator: -1,
  OptionConstraint: -1,
  OptionValue: '',
};

export const getStartRules = (isShortcutFromListTemplate, rules, createdTemplate, ruleName) => {
  if (isShortcutFromListTemplate && !rules && createdTemplate.Id) {
    return createdTemplate.map(item => ({
      MatchOperator: 1,
      OptionConstraint: 0,
      OptionName: ruleName,
      OptionValue: item.Name,
    }));
  } else if (isShortcutFromListTemplate && !rules && !createdTemplate.Id) {
    return Object.values(createdTemplate).map(item => ({
      MatchOperator: 1,
      OptionConstraint: 0,
      OptionName: ruleName,
      OptionValue: item.Name,
    }));
  }

  return rules;
};
