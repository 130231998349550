import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import TimePickerAdapter from 'components/Forms/TimePickerAdapter';

export const datePickers = [
  {
    name: 'StartDate',
    Picker: DatePickerAdapter,
    label: 'Start Date',
    initialValue: null,
  },
  {
    name: 'EndDate',
    Picker: DatePickerAdapter,
    label: 'End Date',
    initialValue: null,
  },
  {
    name: 'startTime',
    Picker: TimePickerAdapter,
    label: 'Start Time',
    initialValue: 0,
  },
  {
    name: 'endTime',
    Picker: TimePickerAdapter,
    label: 'End Time',
    initialValue: 0,
  },
];
