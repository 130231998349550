import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'theme';

import { ScheduleDto, ScheduleInfo } from 'configs/propTypes';
import { actions as scheduleActions } from 'redux/schedule';
import { actions as unitActions } from 'redux/unit';

import ScheduleData from './ScheduleData';
import EditCheqListModal from './EditCheqListModal';
import AddMissingTasksModal from './AddMissingTasksModal';
import { createInitialValues } from './helpers';

import * as S from './styled';

const AssignToolbar = ({ scheduleDto, scheduleInfo, people }) => {
  const [isEditCheqListVisible, setIsEditCheqListVisible] = useState(false);
  const [isAddTasksOpened, setIsAddTasksOpened] = useState(false);

  const missingTasks = useSelector(state => state.schedule.missingTasks);
  const { scheduleId, unitId } = useParams();

  const dispatch = useDispatch();
  const initialValues = createInitialValues(scheduleDto);

  const openAddTasks = () => setIsAddTasksOpened(true);

  const closeAddTasks = () => setIsAddTasksOpened(false);

  const closeModal = () => {
    setIsEditCheqListVisible(false);
  };

  const renderRightButtons = () => [
    {
      component: (
        <>
          <S.Info>Excluded Tasks:</S.Info>
          <S.RedInfo>&nbsp;{missingTasks.length}</S.RedInfo>
        </>
      ),
      isComponent: true,
    },
    scheduleInfo.ActiveStatus === 'Inactive' || missingTasks.length === 0
      ? {
          icon: <S.IconAdd color={theme.primaryGrey} />,
          tooltipStyles: { marginTop: -10 },
          hint: 'No possibility to add tasks',
        }
      : {
          icon: <S.IconAdd />,
          handler: openAddTasks,
          tooltipStyles: { marginTop: -10 },
          hint: 'Add',
        },
  ];

  const changeSchedule = () => dispatch(scheduleActions.changeScheduleRequest());

  useEffect(() => {
    dispatch(scheduleActions.missingTasksListRequest({ checklistId: scheduleId, unitId }));
    dispatch(unitActions.getUnitItemsTasksRequest({ Id: unitId }));
  }, [dispatch, scheduleId, unitId]);

  return (
    <>
      <S.Container
        key="AssignToolbar"
        title={<ScheduleData scheduleDto={scheduleDto} />}
        zIndex={3}
        rightButtons={renderRightButtons()}
      />
      <EditCheqListModal
        key="EditCheqListModal"
        open={isEditCheqListVisible}
        handleClose={closeModal}
        unitId={unitId}
        scheduleId={scheduleId}
        scheduleDto={initialValues}
        changeSchedule={changeSchedule}
      />
      <AddMissingTasksModal
        open={isAddTasksOpened}
        handleClose={closeAddTasks}
        unitId={unitId}
        scheduleId={scheduleId}
        people={people}
        scheduleInfo={scheduleInfo}
      />
    </>
  );
};

AssignToolbar.propTypes = {
  accessWarning: PropTypes.string.isRequired,
  checkedTasks: PropTypes.arrayOf(PropTypes.string).isRequired,
  scheduleDto: ScheduleDto.isRequired,
  scheduleInfo: ScheduleInfo.isRequired,
  people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default withRouter(AssignToolbar);
