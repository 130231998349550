import styled from 'styled-components';

import { DirectionRunsIcon } from 'components/Layout/Icons';
import Checkbox from 'material-ui/Checkbox';

export const CheckboxContainer = styled(Checkbox).attrs({
  color: ({ theme }) => theme.textGray,
  iconStyle: ({ HasMatch, theme }) => (HasMatch ? { fill: theme.mainGreen } : { fill: theme.mainRed }),
  style: { width: '24px', height: '24px', cursor: 'initial' },
  checked: true,
})``;

export const SectionBody = styled.div`
  padding: 50px 50px 15px 30px;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PreviewItemContainer = styled.div`
  margin: 15px 0;
  span:first-child {
    border-top: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  }
`;

export const PreviewItem = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
`;

export const PreviewItemText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  overflow: hidden;
  padding: 15px 30px 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PreviewText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  font-size: 18px;
`;

export const PreviewBottomText = styled(PreviewText)`
  font-weight: 200;
  text-align: left;
  padding-bottom: 10px;
`;

export const ItemsCount = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  white-space: nowrap;
`;

export const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  padding: 40px 50px 0;
  text-align: center;
`;

export const TestRunIconPreview = styled(DirectionRunsIcon).attrs({
  color: ({ theme }) => theme.textGray,
})``;

export const IconContainerPreview = styled.div`
  width: 34px;
  height: 34px !important;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 17px);
`;
