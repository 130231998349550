import styled from 'styled-components';

import { DirectionRunsIcon } from 'components/Layout/Icons';

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  margin-left: ${({ isCentered }) => !isCentered && '115px'};
  margin: 0 auto;
  margin-bottom: 25px;
  border: none;
  background: none;
  cursor: pointer;

  ${({ styles }) => styles}
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px !important;
  background-color: ${({ theme, disabled }) => (disabled ? theme.textGray : theme.mainRed)};
  border-radius: 50px;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TestRunIcon = styled(DirectionRunsIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;

export const Text = styled.span`
  margin-left: 10px;
`;
