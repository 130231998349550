import http from '../index';

export const getDocument = dto => http.post(`/Document/Get?id=${dto}`);

export const removeDocument = dto => http.post(`/Document/Delete?id=${dto}`);

export const deleteDocumentImage = imageId => http.post(`/DocumentImage/Delete?id=${imageId}`);

export const putDocument = dto => http.post(`Document/Put/${dto.Id}`, { dto });

export const uploadDocumentImage = dto => http.post('DocumentImage/Post', dto);

export const getDocumentList = () => http.post('/Document/List');

export const postDocument = dto => http.post('Document/POST', dto);
