import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';

import CheckboxAdapter from 'components/Forms/CheckboxAdapter';
import { actions as authActions } from 'redux/auth';

import validate from './validator';
import * as S from './styled';
import { LoginButton } from 'components/Buttons/LoginButton/styled';

const LoginForm = ({ authMode, loading, chooseSignInTab }) => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleShowPassword = () => setShowPassword(!showPassword);

  const onFormSubmit = values => {
    dispatch(authActions.authenticateRequest(values));
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <S.FormContainer onSubmit={handleSubmit} noValidate authMode={authMode}>
          <S.Head onClick={chooseSignInTab}>
            <S.Text>sign in</S.Text>
          </S.Head>
          <S.Label>Username</S.Label>
          <Field disabled={loading} name="Username">
            {({ input, meta }) => (
              <S.TextInput
                type="text"
                {...input}
                meta={meta}
                placeholder={meta.error && meta.touched ? meta.error : 'A cool name, email or phone number'}
              />
            )}
          </Field>
          <S.Label>Password</S.Label>
          <S.FieldContainer>
            <Field disabled={loading} name="Password">
              {({ input, meta }) => (
                <S.TextInput
                  type={showPassword ? 'text' : 'password'}
                  {...input}
                  meta={meta}
                  placeholder={meta.error && meta.touched ? meta.error : 'A string, phrase or sentence'}
                />
              )}
            </Field>
            <S.ShowPasswordButton type="button" onClick={handleShowPassword}>
              {showPassword ? 'hide' : 'show'}
            </S.ShowPasswordButton>
          </S.FieldContainer>
          <Field
            component={CheckboxAdapter}
            disabled={loading}
            label="Remember me"
            name="RememberMe"
            style={{ margin: 0 }}
          />
          <LoginButton type="submit">LOGIN</LoginButton>
        </S.FormContainer>
      )}
    />
  );
};

LoginForm.propTypes = {
  authMode: PropTypes.oneOf(['signin', 'signup']).isRequired,
  loading: PropTypes.bool.isRequired,
  chooseSignInTab: PropTypes.func.isRequired,
};

export default LoginForm;
