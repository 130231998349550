import styled from 'styled-components';
import FlatButton from 'material-ui/FlatButton';
import { SaveButtonContainer } from 'components/Dialogs/styled';

export const ButtonContainer = styled(SaveButtonContainer)`
  width: 94%;
  border-top: 2px solid ${({ theme }) => theme.lightGrey};
  cursor: pointer;
`;

export const ButtonFlat = styled(FlatButton).attrs({
  labelStyle: {
    fontSize: '20px',
  },
})`
  width: 100%;

  & span {
    text-transform: unset !important;
    font-weight: 600 !important;
    color: ${({ theme, disabled }) => (disabled ? theme.textGray : theme.greenButton)} !important;
  }
`;
