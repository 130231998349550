import styled, { css } from 'styled-components';

import { Row } from 'components/Layout/Containers';
import { GroupIcon } from 'components/Layout/Icons';
import { ButtonFlat } from 'components/Layout/Buttons';

const menuStyle = css`
  padding: 0 !important;
`;

const managerStyle = css`
  border-left: 8px solid ${({ theme }) => theme.tiersColor} !important;
`;

const adminStyle = css`
  border-left: 8px solid ${({ theme }) => theme.redButton} !important;
`;

const GroupIconColored = styled(GroupIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})``;

const ControlsBlock = styled(Row)`
  height: 100%;
  color: ${({ theme }) => theme.darkGrey} !important;
`;

export const Button = styled(ButtonFlat)`
  min-width: unset !important;
  color: ${({ color }) => color} !important;

  & > div {
    @media screen and (max-width: 568px) {
      & > span {
        display: none;
      }
      & > svg {
        margin: 0 12px !important;
      }
    }
  }
`;

export { GroupIconColored, ControlsBlock, menuStyle, managerStyle, adminStyle };
