export const tableColumns = [
  { field: 'RuleName' },
  { field: 'RuleType' },
  { field: 'Created' },
  { field: 'Shortcut' },
  { field: 'LastTime' },
];

export const sortTableColumns = [
  { field: 'Name' },
  { field: 'RuleType' },
  { field: 'Created' },
  { field: 'Shortcut' },
  { field: 'LastTime' },
];
