import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import validate from './validator';
import theme from 'theme';
import * as S from '../styled';
import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

const TimeFrameForm = ({ onSubmitForm, dates, disableEndDate }) => (
  <Form
    onSubmit={values => onSubmitForm({ values })}
    validate={values => validate({ ...values, currentEndDate: dates.endDate })}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <S.Block>
          <S.Label color={theme.primarySites}>Checklist Duration</S.Label>
          <S.BlockTimeFrame>
            <S.FieldBlock>
              <Field
                name="StartDate"
                component={DatePickerAdapter}
                floatingLabelText="Start Date"
                formatDate={date => moment(date).format('ddd-MMM-D-YYYY')}
                initialValue={new Date(dates.startDate)}
                disabled
              />
            </S.FieldBlock>
            <S.FieldBlock>
              <Field
                name="EndDate"
                component={DatePickerAdapter}
                floatingLabelText="End Date"
                formatDate={date => moment(date).format('ddd-MMM-D-YYYY')}
                initialValue={disableEndDate ? new Date(dates.startDate) : new Date(dates.endDate)}
                disabled={disableEndDate}
              />
            </S.FieldBlock>
          </S.BlockTimeFrame>
        </S.Block>
        <ActionsContainer>
          <ActionButton label="Save" isNext />
        </ActionsContainer>
      </form>
    )}
  />
);

TimeFrameForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  dates: PropTypes.shape().isRequired,
  disableEndDate: PropTypes.bool.isRequired,
};

export default TimeFrameForm;
