import React from 'react';
import theme from 'theme';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Modal } from 'components/Modal';
import { ActionButton } from 'components/Dialogs/v1/Base';

import { InventoryLevelType, InventoryPeriodType } from 'types/inventory';
import { PartInventoryLevels } from '../../ui/PartInventoryLevels';

import * as S from './styled';

type PropsType = {
  period: InventoryPeriodType;
  siteName: string;
};

export const InventoryPeriodDialog = NiceModal.create(({ period, siteName }: PropsType) => {
  const modal = useModal();
  const { Allocated, OnHand, Available, BackOrder, PurchasOrderAdvice } = period;

  const DATA: InventoryLevelType[] = [
    { quantity: Allocated, name: 'Allocated / Requested', price: '0' },
    { quantity: OnHand, name: 'On-Hand', price: '0' },
    { quantity: Available, name: 'Available', price: '0' },
    { quantity: BackOrder, name: 'Back-Order', price: '0', isRedBack: true },
    { quantity: PurchasOrderAdvice, name: 'Current Purchase Order Advice', price: '0', isRedBack: true },
  ];

  return (
    <Modal isOpen={modal.visible} onRequestClose={modal.hide} title="Period" titleColor={theme.primaryDark}>
      <S.Container>
        <S.UnorderedList>
          <S.Text>Parts Inventory</S.Text>
          <S.Text>{siteName}</S.Text>
        </S.UnorderedList>
        <S.LabelContainer column>
          <S.Label>Inventory Info</S.Label>
          <S.Wrapper>
            <S.Top>
              <S.BoldText>Quantity</S.BoldText>
              <S.BoldText>Value</S.BoldText>
            </S.Top>
          </S.Wrapper>
          <PartInventoryLevels data={DATA} />
        </S.LabelContainer>
        <ActionButton label="Close" isNext isGreenLabel labelFontSize="18px" type="button" onClick={modal.hide} />
      </S.Container>
    </Modal>
  );
});
