export const getTenantHubs = list =>
  list
    .filter(({ HubsNumber }) => HubsNumber > 0)
    .map(({ Tenant: { Name, IndustryName, DefaultAdminName, Id }, Hubs, HubsNumber }) => ({
      tenant: { name: Name, industry: IndustryName, adminName: DefaultAdminName, id: Id },
      hubs: Hubs,
      hubsNumber: HubsNumber,
    }));

export const warningTexts = {
  notification: 'Deleting All Hubs will delete all Gateways and Sensors',
  confirmation: 'Are you sure you want to delete ALL the IoT Hubs for this Tenant?',
};
