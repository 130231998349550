import styled from 'styled-components';

import { CenteredXYGreyContainer } from 'components/Layout/Containers';
import { CircularProgress } from 'material-ui';

export const Container = styled(CenteredXYGreyContainer)`
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5000;
`;

export const Progress = styled(CircularProgress).attrs({
  size: 100,
})``;

export const LongLoading = styled.div`
  margin-top: 20px;
  font-size: 20px;
  color: ${({ theme }) => theme.primaryWhite};
`;
