export const getSitesList = sites =>
  sites
    ? sites.map(({ Id, AdditionalSiteID, Name, NotificationIoTFrequency }) => ({
        SiteId: Id,
        Name: `${AdditionalSiteID} ${Name}`,
        NotificationIoTFrequency,
      }))
    : [];

export const blank = () => {};
