import queryString from 'query-string';
import { call, put, takeEvery } from 'redux-saga/effects';

import { addPrefixToObjectKeys } from 'helpers';
import { getDocumentList, postDocument } from 'http/document';
import { actions } from './index';

function* fetchDocumentsList() {
  try {
    const { data } = yield call(getDocumentList);
    yield put(actions.documentsListSuccess(data.root));
  } catch (error) {
    yield put(actions.documentsListFailure({ error }));
  }
}

function* addDocument({ payload }) {
  try {
    const params = queryString.stringify(addPrefixToObjectKeys(payload, 'dto'));
    yield call(postDocument, params);
    yield put(actions.addDocumentSuccess());
    yield* fetchDocumentsList();
  } catch (error) {
    yield put(actions.addDocumentFailure({ error, message: 'An error occurred while saving the Document.' }));
  }
}

const documentsSagas = [
  takeEvery(actions.documentsListRequest, fetchDocumentsList),
  takeEvery(actions.addDocumentRequest, addDocument),
];

export default documentsSagas;
