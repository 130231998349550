import styled from 'styled-components';
import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';
import { TextareaField } from 'components/Layout/Textarea';

export const Form = styled.form`
  padding: 0 20px 10px;
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
  padding: 16px;
  text-align: left;
`;

export const Container = styled(BorderRadiusContainer)`
  margin: 0 0 20px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
`;

export const Textarea = styled(TextareaField)`
  width: 50% !important;

  & > div {
    height: auto !important;
  }
`;

export const SecondaryBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LabelStyle = {
  textTransform: 'capitalize',
  fontSize: '16px',
  fontWeight: 'normal',
};
