export const getResourceGroups = list =>
  list.map(
    ({
      Tenant: { Name, IndustryName, DefaultAdminName },
      Hubs,
      Name: ResourceGroupName,
      Location: ResourceGroupLocation,
      HubsNumber,
      GatewaysNumber,
    }) => ({
      tenant: { name: Name, industry: IndustryName, adminName: DefaultAdminName },
      hubs: Hubs,
      resourceGroup: { name: ResourceGroupName, location: ResourceGroupLocation },
      hubsNumber: HubsNumber,
      gatewaysNumber: GatewaysNumber,
    }),
  );

export const warningTexts = {
  notification: 'Deleting The Resource Group will remove ALL the following resources:',
  confirmation: 'Are you sure you want to delete ALL the IoT resources for this Tenant?',
};
