const PART_INFO_CONFIGS = [
  { label: 'My Part ID', name: 'MyPartID' },
  { label: 'My Part Name', name: 'MyPartName' },
  { label: 'My Part Category', name: 'MyPartCategory' },
  { label: 'My Part Type', name: 'MyPartType' },
  { label: 'OEM Part ID', name: 'OEMPartID' },
];

const PART_GROUP_INFO_CONFIGS = [
  { label: 'My Group Name', name: 'MyGroupName' },
  { label: 'My Group Description', name: 'Description' },
];

export { PART_INFO_CONFIGS, PART_GROUP_INFO_CONFIGS };
