import React from 'react';
import PropTypes from 'prop-types';

import * as CS from 'components/Dialogs/styled';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

const BASE_INDEX = 1000;

const TasksTable = ({ list, onDelete }) => (
  <S.Container>
    <S.SelectCheckobesContainer>
      <S.ItemValue>Type</S.ItemValue>
      <S.ItemValue>Min</S.ItemValue>
      <S.ItemValue>Max</S.ItemValue>
      <S.ItemValue />
    </S.SelectCheckobesContainer>
    <CS.DividerLine />
    <S.ListContainer>
      {list.length > 0 &&
        list.map(({ RuleType, RuleData }, index) => (
          <S.ListItemContainer index={BASE_INDEX - index} key={BASE_INDEX - index}>
            <S.ListItem>
              <S.ItemValue>{RuleType}</S.ItemValue>
              <S.ItemValue>{RuleData && (RuleData.ExpectedLowerBound || RuleData.MinAttachments)}</S.ItemValue>
              <S.ItemValue>{RuleData && (RuleData.ExpectedUpperBound || RuleData.MaxAttachments)}</S.ItemValue>
              <S.ItemValue>
                <ButtonIcon>
                  <S.IconDelete onClick={() => onDelete(index)} />
                </ButtonIcon>
              </S.ItemValue>
            </S.ListItem>
            <CS.DividerLine />
          </S.ListItemContainer>
        ))}
    </S.ListContainer>
  </S.Container>
);

TasksTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TasksTable;
