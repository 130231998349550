import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

export const Container = styled(Flex).attrs({
  column: true,
  flex: 'auto',
})``;

export const Message = styled(Box)`
  font-size: 16px;
  padding: 15px;
`;

export const Name = styled(Box)`
  font-size: 16px;
`;

export const TaskContainer = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  px: 2,
})``;
