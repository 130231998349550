import React from 'react';
import PropTypes from 'prop-types';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const InfoPage = ({ onNext }) => (
  <S.DialogContainer>
    <S.SectionContainer>
      <S.Title>Purpose of this Action Card:</S.Title>
      <S.Text>Setup rules to create checklists automatically.</S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Text>
        The shortcut can be run at any time to create tasks based on the latest
        <S.WhiteSpace> information - new items - new people</S.WhiteSpace> or anything else that (frequently) changes.
      </S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Preparation:</S.Title>
      <S.Text>Think of a name for the shortcut.</S.Text>
      <S.Text>Be ready to make assignment and sheduling choices.</S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Result:</S.Title>
      <S.Text>
        When a Shortcut is applied it will create a checklist for a specific time period and assign the task(s) to a
        specific person.
      </S.Text>
    </S.SectionContainer>
    <ActionsContainer>
      <ActionButton label="Next" onClick={onNext} isNext />
    </ActionsContainer>
  </S.DialogContainer>
);

InfoPage.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default InfoPage;
