import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useAppSelector } from 'redux/reducers';
import { actions as unitActions } from 'redux/unit';

import { ActionButton } from 'components/Dialogs/v1/Base';
import { Modal } from 'components/Modal';
import { PhotosSlider } from '../PhotosSlider';

import * as S from './PhotosDialogStyles';
import theme from 'theme';
import { TaskType } from 'types/task';

type PropsType = {
  task: TaskType;
};

export const PhotosDialog = NiceModal.create(({ task }: PropsType) => {
  const modal = useModal();
  const dispatch = useDispatch();
  const photos = useAppSelector(state => state.unit.unitPicturesList);

  useEffect(() => {
    dispatch(unitActions.getUnitPicturesRequest({ unitId: task.UnitId }));
  }, [dispatch, task.UnitId]);

  return (
    <Modal isOpen={modal.visible} title="Unit Photos" onRequestClose={modal.hide} titleColor={theme.primaryDark}>
      <S.RootContainer>
        <div>{task.UnitName}</div>
        <S.InfoContainer>
          <PhotosSlider type="unit" photos={photos} />
          <S.NotesContainer>
            <S.Name>UNIT NOTES</S.Name>
            <S.Note>{task.InstructionalNotes}</S.Note>
          </S.NotesContainer>
        </S.InfoContainer>
        <ActionButton type="button" label="Close" isNext isGreenLabel onClick={modal.hide} />
      </S.RootContainer>
    </Modal>
  );
});
