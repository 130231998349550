import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { SensorLevelColors } from 'theme';
import { BorderRadiusContainer, InputWrapper } from 'components/Layout/Containers';
import { ChevronRightIcon, DeleteIcon, WarningIcon } from 'components/Layout/Icons';

export const Container = styled.div`
  width: 100%;
`;

export const Sensor = styled(BorderRadiusContainer)``;

export const Type = styled(Flex).attrs({
  pl: 2,
  justify: 'space-between',
  align: 'center',
})`
  background: ${({ theme }) => theme.primaryLists};
  color: ${({ theme }) => theme.primaryWhite};
`;

export const IconDelete = styled(DeleteIcon)`
  color: ${({ theme }) => theme.primaryWhite} !important;
  transform: scale(1.3);
`;

export const Data = styled(InputWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0 !important;
`;

export const SensorType = styled.div`
  margin-top: 10px;
  text-transform: capitalize;
`;

export const InpurContainer = styled.div`
  width: 70%;
  margin-right: 10px;
`;

export const StatusAndLevels = styled.div`
  margin-right: ${({ hasLevels }) => (hasLevels ? 0 : '48px')};
`;

export const StatusBlock = styled(Flex).attrs({
  align: 'center',
})``;

export const IconWarning = styled(WarningIcon)`
  color: ${({ status, theme }) => (status ? SensorLevelColors[status] : theme.primaryGrey)} !important;
`;

export const LevelsBlock = styled(Flex).attrs({
  align: 'center',
})``;

export const LevelsCount = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
})`
  width: 30px;
  height: 30px;
  border: 3px solid ${({ theme }) => theme.primaryHome};
  border-radius: 50%;
  margin-left: 10px;
`;

export const RightIcon = styled(ChevronRightIcon)`
  transform: scale(1.4);
`;

export const EmptyListMessage = styled.div`
  margin: 40px 0;
`;
