import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import MenuItem from 'material-ui/MenuItem';

import { SensorType, SensorTypeType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';

import { getTypesList } from 'redux/sensors/selectors';
import validate from './validator';

import * as S from './styled';

const LinkSensorDialogForm = ({ getSelectedSensorId, sensorsForSite, typesList, values }) => (
  <S.Container>
    <S.Sensor>
      <S.Label>Sensor Link</S.Label>
      <S.InputContainer>
        <Field
          component={SelectFieldAdapter}
          name="SensorID"
          floatingLabelText="Sensor Number"
          onChangeControl={getSelectedSensorId}
        >
          {sensorsForSite.map(({ Id, SerialNumber }) => (
            <MenuItem key={Id} primaryText={SerialNumber} value={Id} />
          ))}
        </Field>
        <S.Type>
          Reported Type:{' '}
          {values.SensorID && sensorsForSite.find(item => item.Id === values.SensorID).SensorTypeVariable}
        </S.Type>
      </S.InputContainer>
    </S.Sensor>
    <S.Sensor>
      <S.Label>Sensor Template and Settings</S.Label>
      <S.InputContainer>
        <Field component={S.SelectField} name="SensorTypeID" floatingLabelText="Sensor Template Name">
          {typesList.map(({ Id, Type }) => (
            <MenuItem key={Id} primaryText={Type} value={Id} />
          ))}
        </Field>
        <div>
          {`Sensor Levels: ${
            typesList.some(item => item.Id === values.SensorTypeID)
              ? typesList.find(item => item.Id === values.SensorTypeID).LevelsCount
              : 0
          }`}
        </div>
      </S.InputContainer>
    </S.Sensor>
  </S.Container>
);

LinkSensorDialogForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  getSelectedSensorId: PropTypes.func.isRequired,
  sensorsForSite: PropTypes.arrayOf(SensorType.isRequired).isRequired,
  typesList: PropTypes.arrayOf(SensorTypeType.isRequired).isRequired,
  values: PropTypes.shape({
    SerialNumber: PropTypes.string,
    Type: PropTypes.string,
    SensorID: PropTypes.string,
    SensorTypeID: PropTypes.string,
  }).isRequired,
};

const LinkSensorDialogWithInput = withInputModal(LinkSensorDialogForm);

const LinkSensorDialog = props => <LinkSensorDialogWithInput validate={validate} {...props} />;

const mapStateToProps = ({ sensors: { sensorsForSite, typesList } }) => ({
  sensorsForSite,
  typesList: getTypesList(typesList),
});

export default connect(mapStateToProps)(LinkSensorDialog);
