import styled from 'styled-components';

import { BarcodeWrapper, BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';
import { setQrContainerSize } from 'helpers';

export const Block = styled(BorderRadiusContainer)``;

export const InputContainer = styled(InputWrapper)``;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
`;

export const Container = styled.div`
  padding: 0 20px;
`;

export const WrapperBarcode = styled(BarcodeWrapper)`
  min-width: ${({ valueLength }) => setQrContainerSize(valueLength)};
  min-height: ${({ valueLength }) => setQrContainerSize(valueLength)};
`;
