import styled from 'styled-components';

import { ContentContainer, ToolbarContainer } from 'components/Layout/Containers';
import { ButtonIcon } from 'components/Layout/Buttons';

export const Container = styled(ContentContainer)``;

export const DateLabel = styled.span`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  font-size: 12px;
  padding: 0 15px 0 5px;
`;

export const InfoTooltip = styled(ButtonIcon).attrs({
  tooltipStyles: {
    wordBreak: 'normal',
    marginTop: -13,
  },
})`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')} !important;
`;

export const Toolbar = styled(ToolbarContainer)``;
