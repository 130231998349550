import { call, put, takeEvery } from 'redux-saga/effects';

import { formatServerError } from 'helpers';
import {
  createVersionLegal,
  editVersionLegalRequest,
  getVersionLegalsList,
  removeVersionLegal,
} from 'http/versionLegals';

import { actions } from './index';

function* fetchVersionLegalsList() {
  try {
    const { data } = yield call(getVersionLegalsList);
    yield put(actions.versionlegalsListSuccess(data.root));
  } catch (error) {
    yield put(
      actions.versionlegalsListFailure(
        formatServerError(error, 'An error occurred while fetching the Version legals list.'),
      ),
    );
  }
}

function* addVersionLegal({ payload }) {
  try {
    const { data } = yield call(createVersionLegal, payload);
    yield put(actions.addVersionlegalSuccess(data.entity));
    yield* fetchVersionLegalsList();
  } catch (error) {
    yield put(
      actions.addVersionlegalFailure(formatServerError(error, 'An error occurred while saving the Version legal.')),
    );
  }
}

function* deleteVersionLegal({ payload }) {
  try {
    yield call(removeVersionLegal, payload);
    yield* fetchVersionLegalsList();
  } catch (error) {
    yield put(
      actions.deleteVersionlegalFailure(
        formatServerError(error, 'An error occurred while deleting the Version legal.'),
      ),
    );
  }
}

function* editVersionLegal({ payload }) {
  try {
    const { data } = yield call(editVersionLegalRequest, payload);
    yield put(actions.editVersionlegalSuccess(data.entity));
    yield* fetchVersionLegalsList();
  } catch (error) {
    yield put(
      actions.editVersionlegalFailure(formatServerError(error, 'An error occurred while saving the Version legal.')),
    );
  }
}

const versionLegalsSagas = [
  takeEvery(actions.addVersionlegalRequest, addVersionLegal),
  takeEvery(actions.deleteVersionlegalRequest, deleteVersionLegal),
  takeEvery(actions.editVersionlegalRequest, editVersionLegal),
  takeEvery(actions.versionlegalsListRequest, fetchVersionLegalsList),
];

export default versionLegalsSagas;
