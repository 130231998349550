import React from 'react';
import PropTypes from 'prop-types';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const Final = ({ onExit }) => (
  <S.Container>
    <S.Text>
      <b>Complete.</b>
    </S.Text>
    <S.Text>All Sites have been completed, all tasks are assigned</S.Text>
    <ActionsContainer>
      <ActionButton label="OK" isNext onClick={onExit} />
    </ActionsContainer>
  </S.Container>
);

Final.propTypes = {
  onExit: PropTypes.func.isRequired,
};

export default Final;
