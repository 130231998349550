import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useModal } from '@ebay/nice-modal-react';

import { UnitItemType } from 'configs/propTypes';
import { ButtonIcon } from 'components/Layout/Buttons';
import { SearchIcon } from 'components/Layout/Icons';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import SelectListTemplate from '../SelectListTemplateDialog';
import validate from './validator';

import * as S from './styled';

const DuplicateForm = ({ initialValues, form }) => {
  const selectListTemplateModal = useModal(SelectListTemplate);

  const openSelectListTemplateModal = () =>
    selectListTemplateModal.show({
      unitId: initialValues.UnitId,
      onChange: form.change,
    });

  return (
    <S.FormContainer>
      <Field
        key="CurrentNameField"
        component={TextFieldAdapter}
        disabled
        floatingLabelText="Current Name"
        name="Name"
      />
      <S.UnitNameWrapper>
        <Field
          component={S.TextField}
          floatingLabelText="Current List Template"
          name="UnitName"
          onKeyDown={e => e.preventDefault()}
        />
        <ButtonIcon iconStyle={{ width: '36px', height: '36px' }} onClick={openSelectListTemplateModal}>
          <SearchIcon />
        </ButtonIcon>
      </S.UnitNameWrapper>
      <Field key="NewNameField" component={TextFieldAdapter} floatingLabelText="New Name" name="NewName" />
    </S.FormContainer>
  );
};

const DuplicateWithInput = withInputModal(DuplicateForm);

const DuplicateDialog = ({ itemToDuplicate, ...rest }) => (
  <DuplicateWithInput
    {...rest}
    title="Duplicate this Item with all Tasks"
    initialValues={itemToDuplicate}
    validate={validate}
  />
);

DuplicateDialog.defaultProps = {
  itemToDuplicate: { Name: '', NewName: '', NewSequence: 0 },
};

DuplicateDialog.propTypes = {
  itemToDuplicate: UnitItemType,
};

DuplicateForm.propTypes = {
  initialValues: UnitItemType.isRequired,
  form: PropTypes.object.isRequired,
};

export default DuplicateDialog;
