import React from 'react';
import * as S from './styled';
import NiceModal from '@ebay/nice-modal-react';
import { InventoryInfoDialog } from '../../dialogs';
import { InventoryPartType, PartGroupsType, TransactionType } from 'types/inventory';
import { isEmpty } from 'lodash';

type PropsType = {
  currentEntity: InventoryPartType | PartGroupsType;
  type: string;
  transaction?: TransactionType;
};

export type TransactionReasonType = 'Received' | 'Released' | 'Adjusted' | 'Confirmed';

export const InventoryChanges = ({ currentEntity, type, transaction }: PropsType) => {
  const isPartInventory = type === 'parts';
  const entityType = isPartInventory ? 'Part' : 'PartGroup';

  const receiveNewHandler = () => {
    NiceModal.show(InventoryInfoDialog, { currentEntity, type: 'add', entityType, recordType: 'Received' });
  };

  const adjustHandler = () =>
    NiceModal.show(InventoryInfoDialog, {
      currentEntity,
      type: 'addOrRemove',
      entityType,
      recordType: 'Adjusted',
      transaction,
    });

  return (
    <S.Container column>
      <S.Label>Inventory Changes</S.Label>
      <S.Paragraph>
        Add incoming quantities or adjust quantities <br />
        after an inventory check.
      </S.Paragraph>
      <S.Button onClick={receiveNewHandler}>Receive New (add)</S.Button>
      <S.Button onClick={adjustHandler} disabled={isEmpty(transaction)}>
        Adjust (add or remove)
      </S.Button>
    </S.Container>
  );
};

InventoryChanges.defaultProps = {
  transaction: {},
};
