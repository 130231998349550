import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { FieldRadio } from 'components/Layout/Inputs';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import SelectField from 'components/Forms/SelectFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';

import { matchOperator, constraints, constraintsNumber, constraintsAvailability, optionNames } from './constants';
import * as S from './styled';
import theme from 'theme';

const numberArray = ['TodayQty', 'WeekQty', 'MonthQty', 'YearQty'];
const optionArray = ['UserUserAvailabilityOptionId', 'TeamName', 'UserJobFunction', 'TeamStatus', 'UserTeamName'];

const defineConstraints = optionName => {
  if (optionArray.includes(optionName)) {
    return constraintsAvailability;
  }
  if (numberArray.includes(optionName)) {
    return constraintsNumber;
  }
  return constraints;
};

const checkHintText = value => {
  if (value.length > 12) {
    return `${value.substring(0, 9)}...`;
  }
  return value;
};

const getOptionNames = (name, teamsAreEmpty) => {
  if (teamsAreEmpty) {
    return optionNames[name].filter(item => item.value !== 'UserTeamName');
  }

  return optionNames[name];
};

const SectionRules = ({
  title,
  rules,
  addOption,
  removeOption,
  reset,
  onChangeMatch,
  values,
  listStatus,
  teamsAreEmpty,
  jobsList,
  availabilityOptions,
  teamsList,
}) => {
  const getOptionValue = value => {
    switch (value) {
      case 'UserUserAvailabilityOptionId':
        return availabilityOptions;
      case 'UserJobFunction':
        return jobsList;
      case 'TeamName':
      case 'UserTeamName':
        return teamsList;
      case 'TeamStatus':
        return listStatus;
      default:
        return [...availabilityOptions, ...jobsList, ...teamsList, ...listStatus];
    }
  };

  return (
    <S.Section>
      <S.SectionHeader>{title}</S.SectionHeader>
      <S.SectionBody>
        <S.RuleBlock>
          <S.RedText>
            {title !== 'Person Assignment Rules' ? 'Item tasks will be managed by:' : 'Item task will be assigned to:'}
          </S.RedText>
          <S.RuleText>
            {title !== 'Person Assignment Rules'
              ? 'first manager matching the following:'
              : 'first person matching the following:'}
          </S.RuleText>
        </S.RuleBlock>
        <S.MatchSection>
          <span>Match</span>
          <Field name={`match-${rules.name}`} defaultValue={rules.match.toString()}>
            {({ input }) => (
              <S.RadioGroup
                name={input.name}
                defaultSelected={rules.match.toString()}
                onChange={(e, value) => {
                  input.onChange(value);
                  onChangeMatch(rules.name, value);
                }}
                selectedValue={rules.match.toString()}
              >
                {Object.keys(matchOperator).map(key => (
                  <FieldRadio
                    key={key}
                    value={key}
                    label={matchOperator[key]}
                    style={{ width: 'auto', marginRight: '15px', flex: key }}
                    iconStyle={{ fill: input.value === key && theme.primaryScheduler }}
                    disabled={rules.isRulesIgnored}
                  />
                ))}
              </S.RadioGroup>
            )}
          </Field>
        </S.MatchSection>
        {rules.rules.map(rule => (
          <S.RuleContainer key={`${rule.name}${rule.id}`}>
            <Field
              name={`optionName-${rule.id}`}
              subscription={{ value: true, active: true }}
              render={props => (
                <Adapter reset={reset} values={values} nameResetField={`optionValue-${rule.id}`} {...props}>
                  <Field
                    name={`optionName-${rule.id}`}
                    component={SelectField}
                    hintText={optionNames[rule.name][0].name}
                    disabled={rules.isRulesIgnored}
                    initialValue={rule[`optionName-${rule.id}`]}
                    style={{ width: '190px' }}
                    autoWidth
                  >
                    {getOptionNames(rule.name, teamsAreEmpty).map(({ name, value }) => (
                      <SelectItem key={name} value={value} primaryText={name} />
                    ))}
                  </Field>
                </Adapter>
              )}
            />
            <Field
              name={`constraint-${rule.id}`}
              component={SelectField}
              hintText={constraints[0]}
              disabled={rules.isRulesIgnored}
              initialValue={rule[`constraint-${rule.id}`]}
              style={{ width: '148px' }}
              autoWidth
            >
              {Object.keys(defineConstraints(values[`optionName-${rule.id}`])).map(key => (
                <SelectItem
                  key={defineConstraints(values[`optionName-${rule.id}`])[key]}
                  value={Number(key)}
                  primaryText={defineConstraints(values[`optionName-${rule.id}`])[key]}
                />
              ))}
            </Field>
            {!optionArray.includes(values[`optionName-${rule.id}`]) ? (
              <Field
                name={`optionValue-${rule.id}`}
                component={TextFieldAdapter}
                hintText="Enter Parameter"
                isDecimal={numberArray.includes(values[`optionName-${rule.id}`])}
                initialValue={rule[`optionValue-${rule.id}`]}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
              />
            ) : (
              <Field
                name={`optionValue-${rule.id}`}
                component={SelectField}
                hintText={checkHintText(getOptionValue(values[`optionName-${rule.id}`])?.[0]?.Name)}
                disabled={rules.isRulesIgnored}
                initialValue={rule[`optionValue-${rule.id}`]}
                style={{ width: '148px' }}
                autoWidth
              >
                {getOptionValue(values[`optionName-${rule.id}`]).map(key => (
                  <SelectItem key={key.Id} value={key.Id} primaryText={key.Name} />
                ))}
              </Field>
            )}
            {!rules.isRulesIgnored && (
              <S.RuleButtonsGroup>
                {rules.rules.length > 1 ? (
                  <S.IconButton onClick={() => removeOption(rule.name, rule.id)}>
                    <S.RemoveIcon />
                  </S.IconButton>
                ) : null}
                <S.IconButton onClick={() => addOption(rule.name)}>
                  <S.AddIcon />
                </S.IconButton>
              </S.RuleButtonsGroup>
            )}
          </S.RuleContainer>
        ))}
      </S.SectionBody>
    </S.Section>
  );
};

SectionRules.propTypes = {
  title: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    name: PropTypes.string,
    match: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isRulesIgnored: PropTypes.bool.isRequired,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        optionName: PropTypes.string,
        optionConstraint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        optionValue: PropTypes.string,
        matchOperator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }).isRequired,
  addOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  onChangeMatch: PropTypes.func.isRequired,
  teamsAreEmpty: PropTypes.bool.isRequired,
  values: PropTypes.shape().isRequired,
  teamsList: PropTypes.shape().isRequired,
  listStatus: PropTypes.shape().isRequired,
  reset: PropTypes.func.isRequired,
  jobsList: PropTypes.shape().isRequired,
  availabilityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string,
      Name: PropTypes.string,
    }),
  ).isRequired,
};

export default SectionRules;

class Adapter extends Component {
  componentDidUpdate(prevProps) {
    const { nameResetField, reset, input, values } = this.props;

    if (input.value !== prevProps.input.value && prevProps.input.value !== '') {
      reset({
        ...values,
        [nameResetField]: '',
      });
    }
  }

  render() {
    return this.props.children;
  }

  static propTypes = {
    nameResetField: PropTypes.string.isRequired,
    input: PropTypes.shape().isRequired,
    values: PropTypes.shape().isRequired,
    children: PropTypes.shape().isRequired,
    reset: PropTypes.func.isRequired,
  };
}
