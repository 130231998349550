import styled from 'styled-components';
import CircularProgress from 'material-ui/CircularProgress';

type PropsType = {
  size?: number;
  isCentered?: boolean;
};

export const Overlay = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none !important')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.overlay};
  z-index: 2000;
`;

export const Loader = styled(CircularProgress).attrs<PropsType>({
  size: (props: PropsType) => props.size,
  style: (props: PropsType) =>
    props.isCentered
      ? {
          textAlign: 'center',
          width: '100%',
        }
      : null,
})`
  circle {
    stroke: ${({ theme }) => `${theme.primary} !important`};
  }
`;
