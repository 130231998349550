import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import theme from 'theme';
import { bindActionCreators } from 'redux';
import { Field, Form } from 'react-final-form';

import Loader from 'components/Loader';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import TimePickerAdapter from 'components/Forms/TimePickerAdapter';
import { actions as statusIndexActions } from 'redux/statusIndex';
import { convertDateToThreeDigit, generateStateFromDate, concatenateDateAndTime } from 'helpers';

import validate from './validator';
import * as S from './styled';

class IndexSetup extends PureComponent {
  static defaultProps = {
    isEditModal: false,
  };

  static propTypes = {
    open: PropTypes.bool.isRequired,
    isEditModal: PropTypes.bool,
    isCreateIndexModalOpen: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    indexItem: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      editIndexItemRequest: PropTypes.func.isRequired,
      createIndexItemRequest: PropTypes.func.isRequired,
      setIndexItemsStatus: PropTypes.func.isRequired,
      deleteIndexItemRequest: PropTypes.func.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
  };

  state = {
    isDeleteConfirmationModalOpen: false,
  };

  openDeleteConfirmationModal = () => this.setState({ isDeleteConfirmationModalOpen: true });
  closeDeleteConfirmationModal = () => this.setState({ isDeleteConfirmationModalOpen: false });

  onSubmitForm = values => {
    const { isEditModal, actions, type, indexItem, handleClose } = this.props;
    const { Name, AdditionalIndexID, LiveDate, LiveTime, EndDate, EndTime } = values;

    const payload = {
      Name,
      AdditionalIndexID,
      Type: type,
      State: LiveDate || EndDate ? generateStateFromDate(LiveDate, EndDate) : 'Live',
      DateAdded: moment().format('YYYY-MM-DD[T]HH:mm'),
      LiveDate: (LiveDate || LiveTime) && concatenateDateAndTime(LiveDate, LiveTime),
      EndDate: (EndDate || EndTime) && concatenateDateAndTime(EndDate, EndTime),
      Id: indexItem.Id && indexItem.Id,
    };

    actions.setIndexItemsStatus('pending');
    if (isEditModal) {
      actions.editIndexItemRequest(payload);
    } else {
      actions.createIndexItemRequest(payload);
    }
    handleClose();
  };

  deleteIndexItem = () => {
    const { handleClose, actions, indexItem } = this.props;

    actions.setIndexItemsStatus('pending');
    actions.deleteIndexItemRequest(indexItem.Id);
    this.closeDeleteConfirmationModal();
    handleClose();
  };

  render() {
    const { open, isEditModal, handleClose, indexItem, status, type, isCreateIndexModalOpen } = this.props;
    const { isDeleteConfirmationModalOpen } = this.state;
    const iotTitle = type === 'IoT' ? 'IoT' : '';

    const initialValues = {
      ...indexItem,
      DateAdded: indexItem.CreatedOn ? new Date(indexItem.CreatedOn) : null,
      LiveDate: indexItem.LiveDate ? new Date(indexItem.LiveDate) : null,
      EndDate: indexItem.EndDate ? new Date(indexItem.EndDate) : null,
      LiveTime: indexItem.LiveDate ? new Date(indexItem.LiveDate) : null,
      EndTime: indexItem.EndDate ? new Date(indexItem.EndDate) : null,
    };

    return (
      <Dialog
        open={open}
        onRequestClose={handleClose}
        maxWidth="550px"
        title={isEditModal ? `Edit ${iotTitle} Item` : 'New Item'}
        titleColor={theme.headerColor}
      >
        <S.DialogContainer>
          <Loader isLoading={status === 'pending'} size="large" />
          <S.FormContainer>
            {isCreateIndexModalOpen || (isEditModal && type === 'Manual') ? (
              <S.Title>Fixed data Input for new Index item</S.Title>
            ) : (
              <S.SecondaryTitle>
                Data for IoT Index Item:
                <S.Text>{indexItem.Name}</S.Text>
                <div>
                  <b>ID: </b>
                  {indexItem.AdditionalIndexID}
                </div>
              </S.SecondaryTitle>
            )}

            <Form
              onSubmit={this.onSubmitForm}
              validate={validate}
              initialValues={isEditModal && initialValues}
              render={({ handleSubmit, form, values }) => {
                const { dirty } = form.getState();

                return (
                  <form onSubmit={handleSubmit}>
                    {(isCreateIndexModalOpen || (isEditModal && type === 'Manual')) && (
                      <>
                        <Field component={TextFieldAdapter} floatingLabelText="Name" name="Name" />
                        <Field component={TextFieldAdapter} floatingLabelText="ID" name="AdditionalIndexID" />
                        <Field
                          component={DatePickerAdapter}
                          floatingLabelText="Date added"
                          name="DateAdded"
                          disabled
                          formatDate={date => convertDateToThreeDigit(date)}
                        />
                        <Field
                          component={TextFieldAdapter}
                          floatingLabelText="State (based on timing)"
                          name="State"
                          disabled
                          input={{
                            value:
                              values.LiveDate || values.EndDate
                                ? generateStateFromDate(values.LiveDate, values.EndDate)
                                : '',
                          }}
                        />
                      </>
                    )}
                    <BorderRadiusContainer column justify="">
                      <S.Label>Timing (optional)</S.Label>
                      <S.InputContainer>
                        <Field component={DatePickerAdapter} floatingLabelText="Go Live Date" name="LiveDate" />
                        <Field component={TimePickerAdapter} floatingLabelText="Go Live Time" name="LiveTime" />
                        <Field component={DatePickerAdapter} floatingLabelText="End Date" name="EndDate" />
                        <Field component={TimePickerAdapter} floatingLabelText="End Time" name="EndTime" />
                      </S.InputContainer>
                    </BorderRadiusContainer>
                    <ActionsContainer gapValue={20} paddingValue={14}>
                      {isEditModal && type === 'Manual' && (
                        <ActionButton type="button" label="Delete" onClick={this.openDeleteConfirmationModal} />
                      )}
                      <ActionButton type="submit" isNext label="Save" disabled={!dirty} isDisable={!dirty} />
                    </ActionsContainer>
                  </form>
                );
              }}
            />
          </S.FormContainer>
        </S.DialogContainer>
        <ConfirmationDialog
          label="Yes"
          maxWidth="80%"
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationWarning>
                This action cannot be undone,
                <br /> are you sure?
              </S.ConfirmationWarning>
            </S.ConfirmationContainer>
          }
          open={isDeleteConfirmationModalOpen}
          onClick={this.deleteIndexItem}
          handleClose={this.closeDeleteConfirmationModal}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = ({ statusIndex: { indexItem, status } }) => ({
  indexItem,
  status,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...statusIndexActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexSetup);
