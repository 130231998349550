import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import theme from 'theme';

import { closeErrorDialog } from 'redux/errorHandler';

import BaseSystemDialog from 'components/Dialogs/BaseSystem';
import { ActionButton } from '../v1/Base';

import * as S from './styled';

const ErrorDialog = ({ children, errorMessage, errorMessageTitle, handleClose, opened, title, ...rest }) => (
  <BaseSystemDialog
    actions={[<ActionButton isNext label="OK" onClick={handleClose} />]}
    handleClose={handleClose}
    open={opened}
    title={title}
    titleColor={theme.mainRed}
    isError
    {...rest}
  >
    {errorMessageTitle && <S.Title>{errorMessageTitle}</S.Title>}
    <S.Text>{errorMessage}</S.Text>
    {children}
  </BaseSystemDialog>
);

ErrorDialog.defaultProps = {
  children: null,
  errorMessage: '',
  title: 'Error',
  errorMessageTitle: '',
};

ErrorDialog.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  title: PropTypes.string,
  errorMessageTitle: PropTypes.string,
};

const mapStateToProps = ({ errorHandler }) => ({
  errorMessage: errorHandler.errorMessage,
  errorMessageTitle: errorHandler.errorMessageTitle,
  opened: errorHandler.errorDialogOpened,
  title: errorHandler.errorDialogTitle,
});

export default connect(mapStateToProps, { handleClose: closeErrorDialog })(ErrorDialog);
