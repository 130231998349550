import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useModal } from '@ebay/nice-modal-react';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { EnvelopeIcon } from 'components/Layout/Icons';

import { actions as settingsActions } from 'redux/settings';
import { actions as authActions } from 'redux/auth';
import { openErrorDialog } from 'redux/errorHandler';

import * as CS from 'modules/auth/styled';
import UpgradeInfoDialog from 'components/Dialogs/UpgradeInfoDialog';
import UpgradeInfoSuccessDialog from 'components/Dialogs/UpgradeInfoDialog/UpgradeInfoSuccessDialog';

import SuccessDialog from './SuccessDialog';
import TextInput from './TextInput';
import { nothingAvailableText, signupText, signUpInputFields, entryExists, activeFieldsObject } from './constants';
import validate from './validator';
import * as S from './styled';

const tenantsCountFields = [
  { field: 'AvailableDemoTenantsCount', title: 'Available' },
  { field: 'DemoTenantMonthTerm', title: 'Months Term' },
];

const SignUpForm = ({ authMode, industryList, tenantRequestsStatistics, chooseSignUpTab, chooseSignInTab }) => {
  const [activeFields, setActiveFields] = useState(activeFieldsObject);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const upgradeInfoDialog = useModal(UpgradeInfoDialog);
  const upgradeInfoSuccessDialog = useModal(UpgradeInfoSuccessDialog);

  const isSignUpPage = authMode === 'signup';

  const dispatch = useDispatch();

  const openSuccessDialog = () => setSuccessDialogOpen(true);

  const closeSuccessDialog = form => {
    form.reset();
    setSuccessDialogOpen(false);
    setActiveFields(activeFieldsObject);
    chooseSignInTab();
  };

  const activateField = (fieldName, value) => {
    if (!value) {
      dispatch(openErrorDialog(entryExists, 'Error'));
    }

    setActiveFields({ ...activeFields, [fieldName]: value });
  };

  const handleInputNext = (fieldToActivate, currentValue) => {
    const { fieldName, value } = currentValue;

    setActiveFields({ ...activeFields, [fieldToActivate]: true });

    if (fieldName === 'userName') {
      dispatch(authActions.checkIsDemoUserNameExistsRequest({ userName: value, cb: activateField }));
    }

    if (fieldName === 'tenantName') {
      dispatch(authActions.checkIsDemoTenantNameExistsRequest({ tenantName: value, cb: activateField }));
    }
  };

  const onFormSubmit = values => {
    dispatch(authActions.createDemoTenantRequest({ values, openSuccessDialog }));
  };

  const submitUpgradeInfoDialog = values => {
    dispatch(settingsActions.upgradeInfoRequest({ values, openSuccessDialog: upgradeInfoSuccessDialog.show() }));
    upgradeInfoDialog.hide();
  };

  const openUpgradeInfoDialog = () => {
    upgradeInfoDialog.show({
      title: 'Upgrade Info',
      onSubmit: submitUpgradeInfoDialog,
      BigButtons: true,
      industryList,
      submitLabel: 'SEND REQUEST',
    });
  };

  return (
    <S.Container>
      <S.SignUpContainer isSignUpPage={isSignUpPage}>
        <S.SignUpTitle>Sign up for a free demonstration account.</S.SignUpTitle>
        <S.AccountsInfoContainer>
          <S.AccountsDataContainer>
            {tenantsCountFields.map(({ field, title }) => (
              <S.DataBlock key={field}>
                <S.AccountDataSquare>
                  {Number.isInteger(+tenantRequestsStatistics[field])
                    ? Math.round(tenantRequestsStatistics[field])
                    : tenantRequestsStatistics[field]}
                </S.AccountDataSquare>
                <S.AccountDataLabel>{title}</S.AccountDataLabel>
              </S.DataBlock>
            ))}
          </S.AccountsDataContainer>
          <S.AccountInfoTextContainer>
            {tenantRequestsStatistics.AvailableDemoTenantsCount === 0 && (
              <S.AccountInfoText>{nothingAvailableText}</S.AccountInfoText>
            )}
            <S.AccountInfoText>{signupText}</S.AccountInfoText>
          </S.AccountInfoTextContainer>
        </S.AccountsInfoContainer>
        <S.FormLabel>Start with a unique name, email or phone#</S.FormLabel>
        <Form
          onSubmit={onFormSubmit}
          validate={validate}
          render={({ handleSubmit, form, submitting, values }) => (
            <S.FormContainer onSubmit={handleSubmit} noValidate authMode={authMode}>
              {signUpInputFields.map(
                ({ fieldName, nextField, placeholder }) =>
                  (fieldName === 'userName' || activeFields[fieldName]) && (
                    <S.FieldContainer key={fieldName}>
                      <TextInput
                        name={fieldName}
                        placeholder={placeholder}
                        isFirst={fieldName === 'userName'}
                        isLast={!activeFields[nextField]}
                      />
                      {values[fieldName] && !activeFields[nextField] && (
                        <S.NextButtonContainer
                          type="button"
                          onClick={() => handleInputNext(nextField, { fieldName, value: values[fieldName] })}
                        >
                          &#10142;
                        </S.NextButtonContainer>
                      )}
                    </S.FieldContainer>
                  ),
              )}
              {activeFields.industry && !!industryList.length && (
                <Field name="industryId" placeholder="Select Closest Industry Type">
                  {({ input, ...rest }) => (
                    <CS.Select {...input} {...rest}>
                      <option value="" disabled hidden>
                        Select Closest Industry Type
                      </option>
                      {industryList.map(({ Id, Name }) => (
                        <option key={Id} value={Id}>
                          {Name}
                        </option>
                      ))}
                    </CS.Select>
                  )}
                </Field>
              )}
              {values.industryId && (
                <CS.SubmitButton type="submit" disabled={submitting}>
                  SUBMIT
                </CS.SubmitButton>
              )}
              <SuccessDialog open={successDialogOpen} onClose={() => closeSuccessDialog(form)} />
            </S.FormContainer>
          )}
        />
        <div>
          {authMode === 'signin' ? (
            <S.ForgotPasswordContainer>
              <S.ForgotLink to="/forgot">Forgot your password?</S.ForgotLink>
            </S.ForgotPasswordContainer>
          ) : (
            <S.UpgradeAccountContainer>
              <EnvelopeIcon style={S.envelopeIconStyles} />
              <S.UpgradeAccountLink onClick={openUpgradeInfoDialog}>
                Click here for info or to ask about <br /> upgrading to a <b>paid</b> account
              </S.UpgradeAccountLink>
            </S.UpgradeAccountContainer>
          )}
          <S.BottomBlock onClick={chooseSignUpTab}>
            <S.Text>sign up</S.Text>
          </S.BottomBlock>
        </div>
      </S.SignUpContainer>
    </S.Container>
  );
};

SignUpForm.propTypes = {
  authMode: PropTypes.oneOf(['signin', 'signup']).isRequired,
  industryList: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string,
      Name: PropTypes.string,
    }),
  ).isRequired,
  tenantRequestsStatistics: PropTypes.shape({
    AvailableDemoTenantsCount: PropTypes.number,
    DemoTenantMonthTerm: PropTypes.number,
  }).isRequired,
  chooseSignInTab: PropTypes.func.isRequired,
  chooseSignUpTab: PropTypes.func.isRequired,
};

export default SignUpForm;
