const initialState = {
  error: '',
  loading: false,
  reportList: [],
};

export const getPdfReportListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getPdfReportListSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  reportList: payload,
});
export const getPdfReportListFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const deletePdfReportFileRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deletePdfReportFileSuccess = state => ({
  ...state,
  loading: false,
});
export const deletePdfReportFileFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const createPdfReportRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const createPdfReportSuccess = state => ({
  ...state,
  loading: false,
});
export const createPdfReportFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export default initialState;
