import React from 'react';

import moment from 'moment';
import { AttentionIndicator, VerificationIndicator } from 'components/Layout/Indicators';
import { FlagIcon } from 'components/Layout/Icons';

import * as S from './styled';

const getItemName = item => item.ItemName;
const getItemText = item => item.RuleType;
const getItemInstruction = item => item.OriginatingTaskName;
const renderLeftControls = item => <VerificationIndicator isActive={item.RuleType === 'Verification'} />;
const renderRightText = item => {
  const isQuickTask = item.TaskType === 'QuickTask';

  return (
    <S.ItemInfo>
      {isQuickTask && (
        <>
          <S.TooltipContent data-for="quicktask" data-tip="Quick Task">
            <S.ElectricBolt />
          </S.TooltipContent>
          <S.Tooltip id="quicktask" place="bottom" effect="solid" />
        </>
      )}

      <S.Status>{!item.CompletedOn ? `Due on ${item.ExecutionDate}` : `Completed on ${item.CompletedOn}`}</S.Status>
      <AttentionIndicator isActive={item.Status === 'AttentionRequired'} />

      {item.IsDemoStatus && (
        <S.TooltipContent data-for="demo" data-tip="Demo Task">
          <FlagIcon />
          <S.Tooltip id="demo" place="bottom" effect="solid" />
        </S.TooltipContent>
      )}
    </S.ItemInfo>
  );
};

const calculateRanges = (date, period) => {
  const rangesMap = {
    day: {
      startPeriod: moment(date).format('MM/DD/YYYY'),
      finishPeriod: moment(date).format('MM/DD/YYYY'),
    },
    week: {
      startPeriod: moment(date).format('MM/DD/YYYY'),
      finishPeriod: moment(date).add(1, 'week').format('MM/DD/YYYY'),
    },
    past: {
      finishPeriod: moment().subtract(1, 'day').format('MM/DD/YYYY'),
    },
  };

  return rangesMap[period];
};

export { getItemName, getItemText, getItemInstruction, renderLeftControls, renderRightText, calculateRanges };
