import { createSelector } from 'reselect';

const getPerson = (people, id) => {
  if (id) {
    return people.list.find(person => person.Id === id);
  }
  return {};
};

export const assignSitesToUser = createSelector([getPerson], person => ({
  siteIds: person.sitesAssignedToUser ? person.sitesAssignedToUser.map(site => site.Id) : [],
}));

export const blank = () => {};
