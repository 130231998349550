import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

class InformationForm extends PureComponent {
  static propTypes = {
    authMode: PropTypes.oneOf(['info', 'signup']).isRequired,
  };

  render() {
    const { authMode } = this.props;

    return (
      <S.FormContainer authMode={authMode}>
        <S.TextContainer>
          {authMode === 'info' && (
            <>
              <S.TitleInformation>IoT-Tasks-Parts</S.TitleInformation>
              <S.TextInformation>Incheq is an industrial strength task management platform.</S.TextInformation>
              <S.TextInformation>
                Its goal is to greatly reduce time spent on managing instructions, lists and resources through
                intelligent automation.
              </S.TextInformation>
              <S.TextInformation>
                The platform is ideally suited for complex operations and organizations where up-time, quality and/or
                safety are paramount.
              </S.TextInformation>
            </>
          )}
          <S.TextInformation>
            Incheq&#39;s automation is unparalleled in the industry. Changing circumstances automatically result in
            tasks being launched and sent to the right individuals at the right time for the right location.
          </S.TextInformation>
        </S.TextContainer>
      </S.FormContainer>
    );
  }
}

export default InformationForm;
