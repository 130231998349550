import styled from 'styled-components';

import { RadioButtonGroup } from 'material-ui/RadioButton';

export const DialogContainer = styled.div`
  padding: 20px 30px;
  margin: 0 auto;
  max-width: 300px;
`;

export const StyledRadioButtons = styled(RadioButtonGroup)`
  && {
    display: flex;
    width: 100%;
    margin-left: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;

    & > div {
      margin-bottom: 15px;
    }
  }
`;
