const PART_TABLE_CONFIGS = [
  { field: 'OEMPartID', title: 'OEM Part ID' },
  { field: 'MyPartID', title: 'My Part ID' },
  { field: 'MyPartName', title: 'My Part Name' },
  { field: 'MyPartCategory', title: 'My Part Category' },
  { field: 'Allocated', title: 'Allocated/Requested' },
  { field: 'OnHand', title: 'On-Hand' },
  { field: 'Available', title: 'Available' },
  { field: 'BackOrder', title: 'Back-Order' },
  { field: 'POAdvice', title: 'PO Advice' },
  { field: '', title: 'Part Detail', colSpan: 2 },
];

const PART_GROUPS_TABLE_CONFIGS = [
  { field: 'MyGroupName', title: 'My Group Name' },
  { field: 'Description', title: 'My Group Description' },
  { field: 'Allocated', title: 'Allocated / Requested' },
  { field: 'OnHand', title: 'On-Hand' },
  { field: 'Available', title: 'Available' },
  { field: 'BackOrder', title: 'Back-Order' },
  { field: 'POAdvice', title: 'PO Advice' },
  { field: '', title: 'Group Detail', colSpan: 2 },
];

const PERIOD_MENU_ITEMS = [
  { value: 'part', label: 'Part' },
  { value: 'group', label: 'Group' },
];

export { PART_TABLE_CONFIGS, PART_GROUPS_TABLE_CONFIGS, PERIOD_MENU_ITEMS };
