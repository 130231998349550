export const tableHeads = {
  parts: [
    { title: 'OEM Part ID' },
    { title: 'My Part ID' },
    { title: 'My Part Name' },
    { title: 'My Part Category' },
    { title: 'My Part Type' },
    { title: 'My Part Unit' },
    { title: 'Link Qty' },
  ],
  groups: [{ title: 'Group Name' }, { title: 'Group Description' }, { title: 'Part Qty' }],
};

export const tableColumns = {
  parts: [
    { field: 'OEMPartID' },
    { field: 'MyPartID' },
    { field: 'MyPartName' },
    { field: 'MyPartCategory' },
    { field: 'MyPartType' },
    { field: 'MyPartUnitOfMeasure' },
    { field: 'LinksCount' },
  ],
  groups: [{ field: 'Name' }, { field: 'Description' }, { field: 'LinksCount' }],
};
