import React from 'react';
import PropTypes from 'prop-types';

import withDrawer from 'components/Dialogs/Drawer';

import DndList from 'components/DndList';

import * as S from './styled';

const DragItemsListForm = ({ units, onSubmit, listId, commonLockHide }) => [
  <DndList
    key="CurrentNameField"
    data={units}
    name="DndList"
    listId={listId}
    onSave={onSubmit}
    commonLockHide={commonLockHide}
  />,
];

const DragItemsListWithInput = withDrawer(DragItemsListForm, 1000);

const DragItemsListDialog = ({ units, title, ...rest }) => (
  <DragItemsListWithInput title={<S.Title>{title}</S.Title>} units={units} {...rest} />
);

DragItemsListDialog.defaultProps = {
  handleDelete: () => {},
  withDelete: false,
  withSubmit: true,
  title: 'Sequence Setup (drag items to order)',
  commonLockHide: false,
};

DragItemsListDialog.propTypes = {
  units: PropTypes.array.isRequired,
  title: PropTypes.string,
  commonLockHide: PropTypes.bool,
};

export default DragItemsListDialog;
