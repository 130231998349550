import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actions as partsActions } from 'redux/parts';
import { actions as partOptionsActions } from 'redux/partOptions';
import { openErrorDialog } from 'redux/errorHandler';
import { partDetailsToggle } from 'configs/toggles';
import { Form } from 'react-final-form';

import Header from './Header';
import Toggle from 'components/Toggle';
import Card from './Card';
import { ButtonFlat } from 'components/Layout/Buttons';
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'components/Layout/Icons';
import EditableListDialog from 'components/Dialogs/EditableListDialog';

import { partCards, editModalValues } from './constants';

import validate from './validator';

import * as S from './styled';

const PartDetails = () => {
  const [toggleSelected, setToggleSelected] = useState('all');
  const [needMore, setNeedMore] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editableValue, setEditableValue] = useState('oems');

  const oems = useSelector(state => state.parts.oemList);
  const suppliers = useSelector(state => state.parts.suppliersList);

  const data = {
    oems,
    suppliers,
  };

  const currentPart = useSelector(state => state.parts.currentPart);

  const error = useSelector(state => state.parts.error);
  const dispatch = useDispatch();
  const history = useHistory();

  const { partId } = useParams();

  const toggleHandler = ({ key }) => setToggleSelected(key);
  const moreHandler = () => setNeedMore(prevState => !prevState);

  // eslint-disable-next-line no-shadow
  const openEditModal = editableValue => {
    setEditableValue(editableValue);
    setEditModalOpen(true);
  };
  const closeEditModal = () => setEditModalOpen(false);

  useEffect(() => {
    dispatch(partsActions.getPartByIdRequest({ partId }));
    dispatch(partsActions.getSuppliersListRequest());
    dispatch(partsActions.getOemListRequest());
    dispatch(partOptionsActions.getPartOptionsRequest());
  }, [dispatch, partId]);

  useEffect(() => {
    if (error) {
      dispatch(openErrorDialog(error, 'Error'));
    }
  }, [dispatch, error]);

  return (
    <>
      <S.Container>
        <Header part={currentPart} goBack={history.goBack} />
        <S.ToggleContainer>
          <Toggle config={partDetailsToggle} handler={toggleHandler} selected={toggleSelected} />
        </S.ToggleContainer>
        <Form
          initialValues={currentPart}
          validate={validate}
          onSubmit={e => e.preventDefault}
          render={({ form, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <S.Content>
                {toggleSelected === 'all' && (
                  <S.Block>
                    <S.BlockHeader>Info</S.BlockHeader>
                    <S.BlockBody>
                      <S.Row>
                        {partCards.map((card, index) => (
                          <Card
                            key={`part_${index}_${card.titleType}`}
                            type={card.titleType}
                            {...card}
                            fieldsType="part"
                            form={form}
                            openEditModal={openEditModal}
                          />
                        ))}
                      </S.Row>
                      <S.ToggleContent visible={needMore}>
                        <S.Row>
                          {partCards.map((card, index) => (
                            <Card
                              key={`morePart_${index}_${card.titleType}`}
                              type={card.titleType}
                              {...card}
                              fieldsType="morePart"
                              form={form}
                            />
                          ))}
                        </S.Row>
                      </S.ToggleContent>
                      <ButtonFlat
                        label={needMore ? 'Less' : 'More'}
                        labelPosition="before"
                        fullWidth
                        icon={needMore ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        onClick={moreHandler}
                      />
                    </S.BlockBody>
                  </S.Block>
                )}
                {(toggleSelected === 'all' || toggleSelected === 'financial') && (
                  <S.Block>
                    <S.BlockHeader>Financial</S.BlockHeader>
                    <S.BlockBody>
                      <S.Row>
                        {partCards.map((card, index) => (
                          <Card
                            key={`financial${index}_${card.titleType}`}
                            type={card.titleType}
                            {...card}
                            fieldsType="financial"
                            form={form}
                          />
                        ))}
                      </S.Row>
                    </S.BlockBody>
                  </S.Block>
                )}
                {(toggleSelected === 'all' || toggleSelected === 'attributes') && (
                  <S.Block>
                    <S.BlockHeader>Attributes</S.BlockHeader>
                    <S.BlockBody>
                      <S.Row>
                        {partCards.map((card, index) => (
                          <Card
                            key={`attributes_${index}_${card.titleType}`}
                            type={card.titleType}
                            {...card}
                            fieldsType="attributes"
                            form={form}
                          />
                        ))}
                      </S.Row>
                    </S.BlockBody>
                  </S.Block>
                )}
              </S.Content>
            </form>
          )}
        />
      </S.Container>
      <EditableListDialog
        defaultList={data[editableValue]}
        open={editModalOpen}
        searchPlaceholder={editModalValues[editableValue].placeholder}
        title={editModalValues[editableValue].title}
        idTitle={editModalValues[editableValue].deleteId}
        handleClose={closeEditModal}
        addItem={partsActions[editModalValues[editableValue].addItem]}
        editItem={partsActions[editModalValues[editableValue].editItem]}
        deleteItem={partsActions[editModalValues[editableValue].deleteItem]}
      />
    </>
  );
};

PartDetails.propTypes = {
  getPartById: PropTypes.func.isRequired,
  fetchOptions: PropTypes.func.isRequired,
  fetchOEMs: PropTypes.func.isRequired,
  fetchSuppliers: PropTypes.func.isRequired,
  openConfirmationDialog: PropTypes.func.isRequired,
  openErrorDialog: PropTypes.func.isRequired,
};

export default PartDetails;
