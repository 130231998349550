import { ButtonIcon } from 'components/Layout/Buttons';
import { AssigmentIcon, TaskIcon } from 'components/Layout/Icons';
import { ListItem } from 'components/Layout/Section';
import React from 'react';
import { Link } from 'react-router-dom';

export const External = () => (
  <ListItem
    zIndex={1}
    key="External"
    name="External"
    content={
      <>
        <Link to="/settings/global-index/manual">
          <ButtonIcon tooltip="Global Index">
            <AssigmentIcon />
          </ButtonIcon>
        </Link>
        <Link to="/settings/index-triggers">
          <ButtonIcon tooltip="Index Triggers">
            <TaskIcon />
          </ButtonIcon>
        </Link>
      </>
    }
  />
);
