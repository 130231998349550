import React from 'react';
import PropTypes from 'prop-types';

import { getUnitImage } from 'http/unit';
import { UnitPictureType, UnitType } from 'configs/propTypes';
import { unitDetailsToggle } from 'configs/toggles';
import Toggle from 'components/Toggle';

import PdfsBody from '../PdfsBody';
import * as S from './styled';
import { ToolbarContainer } from '../styled';
import PhotosBody from '../PhotosBody';

const Details = ({
  onDeleteUnitPdf,
  exportUnitPdf,
  uploadUnitPdf,
  pdfs,
  exportUnitQR,
  onDeleteUnitPicture,
  pictures,
  uploadUnitPicture,
  unit,
  type,
  typeHandler,
  getImageSource,
}) => (
  <>
    <ToolbarContainer>
      <Toggle config={unitDetailsToggle} selected={type.toLowerCase()} handler={typeHandler} minWidth />
    </ToolbarContainer>
    <S.Container>
      <S.TemplateText>
        <S.BoldText>Template use: </S.BoldText>
        {unit.UnitType}
      </S.TemplateText>
      <S.InformationContainer>
        <S.DescriptionContainer>
          <S.Section>
            <S.Text>
              <S.BoldText>TEMPLATE DESCRIPTION: </S.BoldText>
              {unit.Description}
            </S.Text>
            <br />
            <S.Text>
              <S.BoldText>ASSET TYPE: </S.BoldText>
              {unit.AssetTypeName}
            </S.Text>
            <S.Text>
              <S.BoldText>MODEL: </S.BoldText>
              {unit.ModelNumber}
            </S.Text>
            <S.Text>
              <S.BoldText>BUILT: </S.BoldText>
              {unit.YearBuilt}
            </S.Text>
            <br />
            <S.Text>
              <S.BoldText>LOCATION: </S.BoldText>
              {unit.LocationDescription}
            </S.Text>
          </S.Section>
        </S.DescriptionContainer>
        <S.BarcodeContainer>
          <S.Section>
            <S.Content>
              {unit.Id && unit.Barcode ? (
                <S.Image src={getImageSource(unit.Id, unit.Barcode.Value)} alt={unit.Barcode.Value} />
              ) : (
                <S.QRText>QR</S.QRText>
              )}
            </S.Content>
            <S.BarcodeLink onClick={exportUnitQR}>{unit.Barcode && unit.Barcode.Value}</S.BarcodeLink>
          </S.Section>
        </S.BarcodeContainer>
      </S.InformationContainer>
      <S.FileContainer>
        <S.PdfContainer>
          <PdfsBody
            key="Upload unit pdfs dialog"
            handleItemClick={exportUnitPdf}
            onDelete={onDeleteUnitPdf}
            onUpload={uploadUnitPdf}
            pdfs={pdfs}
          />
        </S.PdfContainer>
        <S.ImageContainer>
          <PhotosBody
            key="Upload unit photos dialog"
            onDelete={onDeleteUnitPicture}
            onUpload={uploadUnitPicture}
            photos={pictures}
            getUnitImage={getUnitImage}
          />
        </S.ImageContainer>
      </S.FileContainer>
      <S.VendorContainer>
        <S.VendorSection>
          <S.VendorSectionHeader>Vendor Info</S.VendorSectionHeader>
          <S.VendorSectionBody>
            <S.Text>
              <S.BoldText>NAME: </S.BoldText>
              {unit.VendorName}
            </S.Text>
            <br />
            <S.Text>
              <S.BoldText>E-Mail: </S.BoldText>
              {unit.VendorEmail}
            </S.Text>
            <S.Text>
              <S.BoldText>Phone: </S.BoldText>
              {unit.VendorPhone}
            </S.Text>
            <br />
            <S.Text>
              <S.BoldText>NOTES: </S.BoldText>
              {unit.VendorNotes}
            </S.Text>
          </S.VendorSectionBody>
        </S.VendorSection>
      </S.VendorContainer>
    </S.Container>
  </>
);

Details.propTypes = {
  exportUnitQR: PropTypes.func.isRequired,
  pictures: PropTypes.arrayOf(UnitPictureType).isRequired,
  unit: UnitType.isRequired,
  type: PropTypes.string.isRequired,
  onDeleteUnitPdf: PropTypes.func.isRequired,
  exportUnitPdf: PropTypes.func.isRequired,
  uploadUnitPdf: PropTypes.func.isRequired,
  pdfs: PropTypes.array.isRequired,
  onDeleteUnitPicture: PropTypes.func.isRequired,
  uploadUnitPicture: PropTypes.func.isRequired,
  typeHandler: PropTypes.func.isRequired,
  getImageSource: PropTypes.func.isRequired,
};

export default Details;
