import React from 'react';

import * as S from 'modules/globalsettings/pages/styled';
import { RedItem, CustomItem, FirstItem, LastItem, SpaceItem, ContainerItem } from './styled';

const Item = tier => (
  <S.Group>
    <FirstItem>{tier.Name}</FirstItem>

    <CustomItem>Max Sites: {tier.TenantMaxSitesEach}</CustomItem>
    <CustomItem>Max Items: {tier.TenantMaxItemsEach}</CustomItem>
    <RedItem>$ {tier.TasksCharge}</RedItem>

    <CustomItem>Max Sensors: {tier.TenantMaxSensorsEach}</CustomItem>
    <SpaceItem />
    <ContainerItem>
      <LastItem>$ {tier.IotCharge}</LastItem>
      <LastItem>$ {tier.TotalCharge}</LastItem>
    </ContainerItem>
  </S.Group>
);

export default Item;
