import React from 'react';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import RadioGroup from './RadioGroup';

import * as S from './styled';

const LevelEscalateDialogForm = () => (
  <S.Container>
    <S.Label>Levels Escalation Style</S.Label>
    <Field component={RadioGroup} name="escalation" />
  </S.Container>
);

const LevelEscalateDialogWithInput = withInputModal(LevelEscalateDialogForm);

const LevelEscalateDialog = props => <LevelEscalateDialogWithInput initialValues={{ escalation: 'up' }} {...props} />;

export default LevelEscalateDialog;
