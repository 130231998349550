import styled from 'styled-components';
import MenuItem from 'material-ui/MenuItem';

import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';

export const Container = styled.div`
  padding: 0 20px;
`;

export const Sensors = styled(BorderRadiusContainer)`
  border-radius: 5px 5px 0 0;
`;

export const TypeLabel = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primaryLists};
`;

export const LinkLabel = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
`;

export const SelectContainer = styled(InputWrapper)``;

export const SelectField = styled(SelectFieldAdapter).attrs({
  selectedMenuItemStyle: ({ theme }) => ({
    color: theme.primary,
  }),
  maxHeight: window.innerHeight / 3.5,
})``;

export const LevelsCount = styled.span`
  margin-left: 5px;
`;

export const Item = styled(MenuItem).attrs({
  innerDivStyle: ({ theme, value }) => ({
    color: value ? '' : theme.primaryHome,
  }),
})``;
