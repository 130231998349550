import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import PartsDashboard from './pages/Dashboard';
import PartsList from './pages/PartsList';
import Periods from './pages/Periods';
import Transactions from './pages/Transactions';
import { PeriodParts } from './pages/PeriodParts';

const SiteParts = () => (
  <Switch>
    <Route exact path="/siteparts" component={PartsDashboard} />
    <Route exact path="/siteparts/list" component={PartsList} />
    <Route exact path="/siteparts/partsInventory/periods" component={Periods} />
    <Route exact path="/siteparts/partsInventory/periods/:id" component={PeriodParts} />
    <Route exact path="/siteparts/partsInventory/periods/:id/:type/:entityId" component={Transactions} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default SiteParts;
