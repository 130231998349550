import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Container = styled(Flex).attrs({
  w: 1,
  column: true,
  justify: 'center',
  align: 'center',
})`
  height: 100%;
`;

export const FavoriteEmpty = styled.div`
  margin-top: 20px;
  text-align: center;
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.primaryRed};
  border: none;
  background-color: inherit;
  outline: none;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
`;
