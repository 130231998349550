import { ButtonIcon } from 'components/Layout/Buttons';
import { BackIcon, ForwardIcon } from 'components/Layout/Icons';
import { fixedTableHeadStyles } from 'components/TableList/styled';
import styled, { css } from 'styled-components';

// 'Active' | 'Off' | 'Lock'
const STATUS_COLOR: Record<string, string> = {
  Active: 'mainGreen',
  Off: 'primaryRed',
  Locked: 'primaryGrey',
  Inactive: 'primaryRed',
};

const TableContainer = styled.div`
  ${fixedTableHeadStyles}

  & th:nth-child(-n + 3), td:nth-child(-n + 3) {
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
  }

  th,
  td {
    padding: 0 20px !important;
  }

  & td:nth-child(2),
  & td:nth-child(3) {
    background-color: ${({ theme }) => theme.lightBlue};
  }
`;

const Back = styled(BackIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

const EmptyList = styled.div`
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  color: ${({ theme }) => theme.tenantNameColor};
`;

const Header = styled.th`
  background-color: ${({ theme }) => theme.lightGrey};
  font-weight: normal;
`;

const HeaderText = styled.span`
  vertical-align: super;
`;

const IconForward = styled(ForwardIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

const DataCell = styled.td<{ status: string }>`
  background: ${({ theme, status }) => theme[STATUS_COLOR[status]]};
`;

const Item = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
`;

const IconButton = styled(ButtonIcon).attrs({
  tooltipStyles: {
    top: '24px',
  },
})``;

const Title = styled.span`
  padding: 4px 0;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.mainRed};
  margin: 6px 0;
`;

const ConfirmationText = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.primaryBlack};
`;

const BoldText = styled.b`
  color: ${({ theme }) => theme.mainRed};
`;

const menuItemStyles = css`
  &:hover {
    background-color: ${({ theme }) => theme.lightBlue} !important;
  }
`;

export {
  TableContainer,
  EmptyList,
  Back,
  Header,
  HeaderText,
  IconForward,
  DataCell,
  IconButton,
  Item,
  Title,
  Subtitle,
  BoldText,
  ConfirmationText,
  menuItemStyles,
};
