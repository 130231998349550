const addAssignedSitesToPerson = (state, payload) => {
  const personsArray = state.list.map((person, index) => {
    if (person.Id === payload.userId) {
      return {
        ...state.list[index],
        sitesAssignedToUser: payload.root,
      };
    }
    return person;
  });
  return { ...state, list: [...personsArray], error: '', loading: false };
};

export default addAssignedSitesToPerson;
