import React from 'react';
import PropTypes from 'prop-types';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';

import { IndustryType } from 'configs/propTypes';

import withInputModal from 'components/Dialogs/withInputModal';
import CustomNumberInputField from 'components/Forms/CustomNumberInputField';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { TextareaField } from 'components/Layout/Textarea';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';

import validate from './validator';
import * as S from './styled';

const limits = [
  { field: 'SitesCount', label: <S.Label>How many Sites (Locations/Plants) will you need?</S.Label> },
  { field: 'ItemsCount', label: <S.Label>How many Items to service or check per Site (rough)?</S.Label> },
  { field: 'SensorsCount', label: <S.Label>How many IoT Sensors do you think you need per Site?</S.Label> },
];

const requestorFields = [
  { name: 'RequestorName', label: 'Name' },
  { name: 'RequestorEmail', label: 'E-Mail' },
  { name: 'RequestorCompany', label: 'Company' },
  { name: 'RequestorQuestionsOrComments', label: 'Questions or Comments' },
];

const UpgradeInfoForm = ({ industries }) => (
  <S.FormContainer>
    <S.TenantValuesList>
      {limits.map(({ field, label }) => (
        <CustomNumberInputField key={field} name={field} labelComponent={label} readOnly />
      ))}
    </S.TenantValuesList>
    <S.FormCardContainer>
      <S.CardHeader>Requester Info</S.CardHeader>
      <S.CardContentContainer>
        <Field name="IndustryID" component={SelectFieldAdapter} floatingLabelText="Industry Type" disabled={false}>
          {industries.length > 0
            ? industries.map(({ Name, Id }) => <MenuItem primaryText={Name} value={Id} key={Id} />)
            : []}
        </Field>
        {requestorFields.map(({ name, label }) =>
          name === 'RequestorQuestionsOrComments' ? (
            <Field name={name} component={TextareaField} rows={4} floatingLabelText={label} />
          ) : (
            <Field component={TextFieldAdapter} name={name} floatingLabelText={label} />
          ),
        )}
      </S.CardContentContainer>
    </S.FormCardContainer>
    <S.FormCardContainer>
      <S.NotesHeader>Our Notes</S.NotesHeader>
      <S.CardContentContainer>
        <Field component={TextFieldAdapter} name="ControllerNotes" floatingLabelText="Our Notes" />
      </S.CardContentContainer>
    </S.FormCardContainer>
  </S.FormContainer>
);

UpgradeInfoForm.propTypes = {
  industries: PropTypes.arrayOf(IndustryType).isRequired,
};

const UpgradeInfo = withInputModal(UpgradeInfoForm);

const UpgradeInfoDialog = NiceModal.create(props => {
  const modal = useModal();

  return (
    <UpgradeInfo
      open={modal.visible}
      handleClose={modal.hide}
      initialValues={props.info}
      validate={validate}
      {...props}
    />
  );
});

UpgradeInfoDialog.propTypes = {
  info: PropTypes.shape({
    SitesCount: PropTypes.number,
    ItemsCount: PropTypes.number,
    SensorsCount: PropTypes.number,
    IndustryID: PropTypes.string,
    RequestorName: PropTypes.string,
    RequestorEmail: PropTypes.string,
    RequestorCompany: PropTypes.string,
    RequestorQuestionsOrComments: PropTypes.string,
    ControllerNotes: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ settings: { systemLimits }, tenants }) => ({
  systemLimits,
  currentTenantsCount: tenants.currentTenantsCount,
  industries: tenants.industryList,
});

export default connect(mapStateToProps)(UpgradeInfoDialog);
