import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ACTIVATE_TENANT_INVENTORY: undefined,
  SET_INVENTORY_ERROR: undefined,
  SET_IS_LOADING: undefined,
  GET_INVENTORY_PERIODS_LIST_REQUEST: undefined,
  GET_INVENTORY_PERIODS_LIST_SUCCESS: undefined,
  CREATE_INVENTORY_PERIOD_REQUEST: undefined,
  GET_INVENTORY_PARTS_LIST_REQUEST: undefined,
  GET_INVENTORY_PARTS_LIST_SUCCESS: undefined,
  GET_INVENTORY_PART_GROUPS_LIST_REQUEST: undefined,
  GET_INVENTORY_PART_GROUPS_LIST_SUCCESS: undefined,
  CREATE_INVENTORY_ENTITIES_REQUEST: undefined,

  GET_PARTS_BY_PARAM_REQUEST: undefined,
  GET_PARTS_BY_PARAM_SUCCESS: undefined,

  GET_PART_GROUPS_BY_PARAM_REQUEST: undefined,
  GET_PART_GROUPS_BY_PARAM_SUCCESS: undefined,

  EDIT_INVENTORY_PART_REQUEST: undefined,
  EDIT_INVENTORY_PART_GROUP_REQUEST: undefined,
  DELETE_INVENTORY_ENTITY_REQUEST: undefined,

  CREATE_PART_TRANSACTION_REQUEST: undefined,
  FETCH_PART_TRANSACTIONS_LIST_REQUEST: undefined,
  FETCH_PART_TRANSACTIONS_LIST_SUCCESS: undefined,
  GET_INVENTORY_DASHBOARD_INFO_REQUEST: undefined,
  GET_INVENTORY_DASHBOARD_INFO_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.activateTenantInventory, handlers.activateTenantInventory],
    [actions.setInventoryError, handlers.setInventoryError],
    [actions.setIsLoading, handlers.setIsLoading],
    [actions.getInventoryPeriodsListRequest, handlers.getInventoryPeriodsListRequest],
    [actions.getInventoryPeriodsListSuccess, handlers.getInventoryPeriodsListSuccess],
    [actions.createInventoryPeriodRequest, handlers.createInventoryPeriodRequest],
    [actions.getInventoryPartsListRequest, handlers.getInventoryPartsListRequest],
    [actions.getInventoryPartsListSuccess, handlers.getInventoryPartsListSuccess],
    [actions.getInventoryPartGroupsListRequest, handlers.getInventoryPartGroupsListRequest],
    [actions.getInventoryPartGroupsListSuccess, handlers.getInventoryPartGroupsListSuccess],
    [actions.createInventoryEntitiesRequest, handlers.createInventoryEntitiesRequest],

    [actions.getPartsByParamRequest, handlers.getPartsByParamRequest],
    [actions.getPartsByParamSuccess, handlers.getPartsByParamSuccess],
    [actions.getPartGroupsByParamRequest, handlers.getPartGroupsByParamRequest],
    [actions.getPartGroupsByParamSuccess, handlers.getPartGroupsByParamSuccess],
    [actions.editInventoryPartRequest, handlers.editInventoryPartRequest],
    [actions.editInventoryPartGroupRequest, handlers.editInventoryPartGroupRequest],
    [actions.deleteInventoryEntityRequest, handlers.deleteInventoryEntityRequest],

    [actions.fetchPartTransactionsListRequest, handlers.fetchPartTransactionsListRequest],
    [actions.fetchPartTransactionsListSuccess, handlers.fetchPartTransactionsListSuccess],
    [actions.createPartTransactionRequest, handlers.createPartTransactionRequest],
    [actions.getInventoryDashboardInfoRequest, handlers.getInventoryDashboardInfoRequest],
    [actions.getInventoryDashboardInfoSuccess, handlers.getInventoryDashboardInfoSuccess],
  ]),
  initialState,
);

export default reducer;
