import React from 'react';
import PropTypes from 'prop-types';

import { ListContainer } from 'components/Layout/Containers';
import EmptyList from 'components/EmptyList';
import Item from './Item';

const emptyListMessage = 'There are no triggers for this day';

const List = ({ onExport, expandedToggle, isExpanded, triggers }) => (
  <ListContainer>
    {triggers.userIds.length ? (
      triggers.userIds.map(userId => (
        <Item
          key={userId}
          onExport={onExport}
          triggersByUserId={triggers.triggersByUserId}
          userId={userId}
          expandedToggle={expandedToggle}
          isExpanded={isExpanded === userId}
        />
      ))
    ) : (
      <EmptyList text={emptyListMessage} />
    )}
  </ListContainer>
);

List.propTypes = {
  onExport: PropTypes.func.isRequired,
  expandedToggle: PropTypes.func.isRequired,
  isExpanded: PropTypes.string.isRequired,
  triggers: PropTypes.shape({
    triggersByUserId: PropTypes.objectOf(PropTypes.objectOf(PropTypes.array)),
    userIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default List;
