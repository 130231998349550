import moment from 'moment';
import { ignoreRules } from './DistributionDialog/constants';
import { checkOptions } from 'modules/queue/pages/Archive/ReportSetup/ReportRules/helpers';

export const firstLetterUpperCase = (str = '') => str.charAt(0).toUpperCase() + str.slice(1);

export const generateDistributionName = (createdOn = new Date()) =>
  `Distro-${moment.utc(createdOn).local().format('ddd-MMM-DD-YYYY - hh:mma')}`;

export const formatExecutionDate = date => moment.utc(date).local().format('ddd MMM DD, YYYY - hh:mma');

export const createId = (flag = '') => `${flag}${new Date().getTime()}`;

const checkForIgnoreRules = item => ignoreRules.includes(item.split('-')[1]);

export const isDistributionFormValid = distributionRules => {
  const { LocationRules, UnitRules, ItemRules } = distributionRules;
  const errorsBySection = [];

  if (!checkOptions(LocationRules)) {
    errorsBySection.push('Location');
  }

  if (!checkOptions(ItemRules)) {
    errorsBySection.push('Item');
  }

  if (!checkOptions(UnitRules)) {
    errorsBySection.push('Unit');
  }

  return errorsBySection;
};

const generateRulesFromArrayOfKeys = (localValues, globalValues) => {
  const optionNames = localValues.filter(item => item.includes('optionName'));
  const optionConstraints = localValues.filter(item => item.includes('constraint'));
  const optionValues = localValues.filter(item => item.includes('optionValue'));
  const matchOperator = localValues.find(item => item.includes('match'));

  const ignoreRule = localValues.find(item => {
    if (checkForIgnoreRules(item) && globalValues[item]) {
      return true;
    }

    return false;
  });

  if (ignoreRule) {
    const item = {};

    item.OptionName = ignoreRule.split('-')[1];
    item.OptionConstraint = -1;
    item.OptionValue = '';
    item.MatchOperator = -1;

    return [item];
  }

  const rules = optionNames.map((optionNameKey, index) => {
    const item = {};

    item.OptionName = globalValues[optionNameKey];
    item.OptionConstraint = parseInt(globalValues[optionConstraints[index]], 10);
    item.OptionValue = globalValues[optionValues[index]];
    item.MatchOperator = parseInt(globalValues[matchOperator], 10);

    return item;
  });

  return rules;
};

export const generateDistributionRules = (values, rest) => {
  const locationValues = Object.keys(values).filter(item => item.includes('Location'));
  const unitValues = Object.keys(values).filter(item => item.includes('Unit'));
  const itemValues = Object.keys(values).filter(item => item.includes('Item'));

  const data = {
    ...rest,
    Type: Number(values['distribution-type']),
    LocationRules: generateRulesFromArrayOfKeys(locationValues, values),
    UnitRules: generateRulesFromArrayOfKeys(unitValues, values),
    ItemRules: generateRulesFromArrayOfKeys(itemValues, values),
  };
  return data;
};

export const generatePreviewList = list => {
  const output = [];

  list.map(listItem => {
    const site = output.find(outputItem => outputItem.SiteName === listItem.SiteName);
    if (site) {
      const index = output.indexOf(site);
      output[index].units.push(listItem);
    } else {
      output.push({
        SiteName: listItem.SiteName,
        SiteId: listItem.SiteId,
        AdditionalSiteId: listItem.AdditionalSiteId,
        units: [listItem],
      });
    }
    return listItem;
  });

  return output;
};
