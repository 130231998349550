import styled from 'styled-components';

export const ContentContainer = styled.div`
  text-align: center;
`;

export const TaskType = styled.div`
  font-size: 20px;
  padding: 35px 0 10px 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Notification = styled.div`
  font-size: 16px;
  padding: 10px 0 10px 0;
  color: ${({ theme }) => theme.primaryBlack};
`;
