/* eslint-disable react/prop-types */
import React from 'react';
import Swiper from 'react-id-swiper';

import { getUnitImage, getImageSource } from 'http/unit';
import { DropleftCircleIcon, DroprightCircleIcon } from 'components/Layout/Icons';

import * as S from './styled';

const params = {
  grabCursor: false,
  rebuildOnUpdate: true,
  shouldSwiperUpdate: true,
  noSwiping: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  containerClass: 'custom-swiper-container',
  wrapperClass: 'custom-swiper-wrapper',
  renderNextButton: () => <DroprightCircleIcon className="swiper-button-next" />,
  renderPrevButton: () => <DropleftCircleIcon className="swiper-button-prev" />,
};

export const PhotosSlider = ({ photos, type }) => (
  <S.CustomSwiperContainer>
    <S.Content>
      {photos.length ? (
        <Swiper {...params}>
          {photos.map((photo, index) => (
            <S.ImageContainer>
              <img key={index} alt={photo.Name} src={type === 'unit' ? getUnitImage(photo) : getImageSource(photo)} />
            </S.ImageContainer>
          ))}
        </Swiper>
      ) : (
        <S.EmptyText>This task has no reference photos.</S.EmptyText>
      )}
    </S.Content>
  </S.CustomSwiperContainer>
);
