import { createSelector } from 'reselect';

const getSiteId = selectedGateway => selectedGateway.SiteID;
const getSitesList = (selectedGateway, sites) => sites.list;

export const getSiteNameSelector = createSelector([getSiteId, getSitesList], (SiteID, sites) => {
  const siteOfGateway = sites.find(site => site.Id === SiteID);

  return siteOfGateway ? `${siteOfGateway.AdditionalSiteID} ${siteOfGateway.Name}` : '';
});

export const helper = () => {};
