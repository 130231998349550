import React, { FC } from 'react';

import { Textarea } from 'components/Textarea';
import { FilePenIcon } from 'components/Layout/Icons';

import { AdditionalInfo } from '../AdditionalInfo';

import * as S from './AddReportNotesSectionStyles';

type PropsType = {
  formik: any;
};

export const AddReportNotesSection: FC<PropsType> = ({ formik }) => {
  const { selectedSection } = formik.values;

  const showSelectedSection = (newSelectedSection: string) =>
    formik.setFieldValue('selectedSection', newSelectedSection);

  const hideSelectedSection = () => formik.setFieldValue('selectedSection', '');

  const onReportNotesClickHandler = () =>
    selectedSection === 'notes' ? hideSelectedSection() : showSelectedSection('notes');

  return (
    <AdditionalInfo
      name="Add Report Notes"
      leftIcon={<FilePenIcon />}
      rightIcon={
        <S.IconWrapper onClick={onReportNotesClickHandler} hasMarginRight>
          {selectedSection === 'notes' ? <S.ArrowDownIcon /> : <S.ArrowUpIcon />}
        </S.IconWrapper>
      }
      isShown={selectedSection === 'notes'}
      content={
        <>
          <p>Add notes to clarify or highlight issues</p>
          <Textarea
            {...formik.getFieldProps('reportNotes')}
            meta={formik.getFieldMeta('reportNotes')}
            name="reportNotes"
          />
        </>
      }
    />
  );
};
