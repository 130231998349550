import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Gateway = styled(Flex).attrs({
  w: 1,
})``;

export const SerialNumber = styled.div`
  display: block !important;
  width: 34%;
`;

export const Type = styled.div`
  display: block !important;
  width: 24%;
`;

export const TypeName = styled.span`
  font-weight: bold;
  text-transform: capitalize;
`;
