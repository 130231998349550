import http from '../index';

export const fetchGateways = isMaster => http.post(`MasterGateway/List${isMaster ? '' : 'ForSystemAdministrator'}`);

export const fetchGatewaysByHub = dto => http.post('MasterGateway/ListByTenantAndSite', dto);

export const fetchServerData = dto => http.post('/AzureResourceGroup/List', { region: dto });

export const fetchIotHubs = dto => http.post('/azureiothub/list', { resourceGroupName: dto });

export const fetchRegions = () => http.post('/azure/GetAzureRegions');

export const appendServer = dto => http.post('/AzureResourceGroup/Post', { dto });

export const appendHub = dto => http.post('/AzureIotHub/Post', { dto });

export const removeServer = groupName => http.post('/azureresourcegroup/delete', { groupName });

export const removeHub = dto => http.post('/azureiothub/delete', dto);

export const makeGateway = dto => http.post('/AzureDevice/Post', dto);

export const fetchGateway = id => http.post(`MasterGateway/GetForSystemAdministrator?id=${id}`);

export const editMasterGateway = (isMaster, values) =>
  http.post(`MasterGateway/Put${isMaster ? '' : 'ForSystemAdministrator'}`, {
    id: values.Id,
    dto: values,
  });

export const removeGateway = dto => http.post('/Azuredevice/Delete', dto);

export const fetchResourceGroups = () => http.post('AzureResourceGroup/ListWithHubsAndGateways');

export const removeAllHubsFromTenant = dto => http.post('AzureIotHub/DeleteAllHubsFromTenant', dto);

export const getTenantHubsList = tenantId => http.post('AzureIotHub/ListByTenantGroupBySite', { tenantId });

export const removeTenantHubs = dto => http.post('AzureIotHub/DeleteAllHubsFromSite', dto);

export const removeTenantSiteHub = dto => http.post('AzureIotHub/DeleteHubFromTenant', dto);

export const fetchNewResourceGroupNameByTenant = tenantId =>
  http.post('AzureResourceGroup/GetNewResourceGroupNameByTenant', { tenantId });

export const fetchResourceGroupInfo = resourceGroupName =>
  http.post('AzureResourceGroup/GetResourceGroupInfo', { resourceGroupName });

export const fetchNewHubNameBySite = (tenantId, siteId) =>
  http.post('AzureIotHub/GetNewHubNameBySite', { tenantId, siteId });

export const fetchNewGatewayNameInHub = dto => http.post('AzureDevice/GetNewGatewayNameInHub', dto);

export const fetchDeviceConnectionString = dto => http.post('AzureDevice/GetDeviceConnectionString', dto);

export const fetchHubTierInfo = (iotHubName, resourceGroupName) =>
  http.post('AzureIotHub/GetHubTierInfo', { iotHubName, resourceGroupName });
