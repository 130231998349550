import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DialogsPull from 'components/Dialogs/DialogsPull';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { shortcutType } from 'configs/types';
import { actions as shortcutsActions } from 'redux/shortcuts';

import SelectionRules from './SelectionRules';
import CharacteristicsRules from './CharacteristicsRules';
import ShortcutSelection from './ShortcutSelection';
import * as S from './styled';

const STEPS = [
  {
    Component: SelectionRules,
    title: 'Index Rule Setup',
  },
  {
    Component: CharacteristicsRules,
    title: 'Index Rule Setup',
  },
  {
    Component: ShortcutSelection,
    title: 'Shortcut',
  },
];

class ShortcutTriggerSetup extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      getShortcutsRequest: PropTypes.func.isRequired,
    }).isRequired,
    open: PropTypes.bool.isRequired,
    listIndexRule: PropTypes.array.isRequired,
    handleExit: PropTypes.func.isRequired,
    itemRuleToEdit: PropTypes.object.isRequired,
  };

  state = {
    indexRule: {},
    isErrorName: false,
  };

  componentDidMount() {
    this.props.actions.getShortcutsRequest({ filter: { type: shortcutType.manual } });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ currentSite: null });
    }
  }

  setIndexRule = item => {
    const { indexRule } = this.state;
    this.setState({ indexRule: { ...indexRule, ...item } });
  };

  exitForm = () => {
    this.setState({ indexRule: {} });
    this.props.handleExit();
  };

  closeErrorNameDialog = () => {
    this.setState({
      isErrorName: false,
    });
  };

  openErrorNameDialog = () => {
    this.setState({
      isErrorName: true,
    });
  };

  render() {
    const { indexRule, isErrorName } = this.state;
    const { open, listIndexRule, itemRuleToEdit } = this.props;

    return (
      <>
        <DialogsPull
          open={open}
          steps={STEPS}
          handleClose={this.exitForm}
          itemRuleToEdit={itemRuleToEdit}
          handleExit={this.exitForm}
          siteSelectionStepNumber={1}
          indexRule={indexRule}
          setIndexRule={this.setIndexRule}
          listIndexRule={listIndexRule}
          openErrorNameDialog={this.openErrorNameDialog}
        />
        <ConfirmationDialog
          labelCancel="Return"
          title="Alert"
          maxWidth={'80%'}
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationWarning>
                This Index rule name <br /> is already in use!
              </S.ConfirmationWarning>
            </S.ConfirmationContainer>
          }
          open={isErrorName}
          handleClose={this.closeErrorNameDialog}
          onCancelClick={this.closeErrorNameDialog}
          isCancelNext
        />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getShortcutsRequest: shortcutsActions.getShortcutsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutTriggerSetup);
