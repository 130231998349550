import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { Card, CardHeader, CardText } from 'material-ui/Card';

import { ButtonIcon } from 'components/Layout/Buttons';

export const CardContent = styled(CardText)`
  display: flex;
  flex: auto;
  font-size: 16px;
  padding: 0px !important;
`;

export const Category = styled(Flex).attrs({
  column: true,
  flex: 'auto',
})``;

export const CategoryName = styled(Flex)`
  background-color: ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkGrey};
  font-size: 15px;
  font-weight: 500;
  padding: 5px 20px;
`;

export const Container = styled(Card)`
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  margin: 15px 15px 0px 15px;

  button {
    height: 100% !important;
  }
`;

export const Content = styled(Flex).attrs({
  column: true,
  flex: 'auto',
})``;

export const Header = styled(CardHeader).attrs({
  titleStyle: {
    display: 'flex',
    flex: 'auto',
  },
})`
  background: ${({ theme }) => theme.primaryQueue} !important;
  border-radius: 4px 4px 0 0;
  font-weight: 500 !important;
  padding: 10px !important;
  position: sticky !important;
  top: 0;
  z-index: 2;

  svg {
    color: ${({ theme }) => theme.primaryWhite} !important;
  }

  & > div {
    align-items: center !important;
    display: flex !important;
    justify-content: space-between !important;
    padding-right: 34px !important;

    span {
      color: ${({ theme }) => theme.primaryWhite} !important;
    }

    span:first-child {
      min-width: 0;
      word-break: break-word;
    }
  }
`;

export const IconButton = styled(ButtonIcon).attrs({
  iconStyle: ({ theme }) => ({
    color: theme.primaryWhite,
  }),
  tooltipStyles: {
    top: '32px',
    whiteSpace: 'nowrap',
  },
})``;

export const Title = styled(Flex).attrs({
  flex: 'auto',
  pl: '20px',
})``;

export const Trigger = styled(Flex).attrs({
  px: '20px',
  py: '10px',
})`
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};

  :last-of-type {
    border-bottom: none;
  }
`;

export const UserName = styled.div`
  min-width: 53%;
  padding-right: 10px;
`;
