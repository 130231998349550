import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';

import * as S from './styled';

const DrawerTitle = ({
  handleClose,
  isSearch,
  provideIcon,
  searchData,
  searchInList,
  title,
  subtitle,
  withSiteDropdown,
  dropdownList,
  handleDropdownChange,
  dropdownValue,
  dropdownLabel,
}) => (
  <S.Wrapper isSearch={isSearch}>
    <S.Header>
      <S.TitleContainer>{title}</S.TitleContainer>
      <S.Button onClick={() => handleClose()} tooltip="Close">
        <S.IconClose />
      </S.Button>
    </S.Header>
    <S.HeaderTools visible={isSearch || withSiteDropdown}>
      {subtitle ? <S.SubtitleContainer>{subtitle}</S.SubtitleContainer> : null}
      {isSearch && (
        <S.SearchContainer>
          {provideIcon}
          <S.InputContainer>
            <S.Search color="grey" />
            <S.SearchInput
              underlineShow={false}
              value={searchData}
              onChange={searchInList}
              placeholder="filter sites"
            />
          </S.InputContainer>
        </S.SearchContainer>
      )}
      {withSiteDropdown ? (
        <S.Select floatingLabelText={dropdownLabel} value={dropdownValue} onChange={handleDropdownChange}>
          {dropdownList.map(item => (
            <MenuItem value={item.value} primaryText={item.label} />
          ))}
        </S.Select>
      ) : null}
    </S.HeaderTools>
  </S.Wrapper>
);

DrawerTitle.defaultProps = {
  handleClose: () => {},
  isSearch: false,
  provideIcon: null,
  searchInList: undefined,
  searchData: '',
  withSiteDropdown: false,
  dropdownList: [],
  handleDropdownChange: () => {},
  dropdownValue: '',
  dropdownLabel: '',
  subtitle: '',
};

DrawerTitle.propTypes = {
  handleClose: PropTypes.func,
  isSearch: PropTypes.bool,
  provideIcon: PropTypes.node,
  searchData: PropTypes.string,
  searchInList: PropTypes.func,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  withSiteDropdown: PropTypes.bool,
  dropdownList: PropTypes.array,
  handleDropdownChange: PropTypes.func,
  dropdownValue: PropTypes.string,
  dropdownLabel: PropTypes.string,
};
export default DrawerTitle;
