import styled from 'styled-components';

import { AddIcon } from 'components/Layout/Icons';

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;

export const FormContainer = styled.div`
  button {
    padding: 0 20px;
  }
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 10px 15px 0;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;

  form {
    & > div:last-child {
      padding: 20px 0 !important;
    }
  }
`;

export const TopInfo = styled.div``;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 14px;
`;

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: ${({ inside }) => (inside ? '30px 30px' : '30px 0')};
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.primaryWhite};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 15px;
`;

export const SectionBody = styled.div`
  padding: 0 30px 15px;
`;

export const BoldText = styled.b`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const AddBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0;
`;

export const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  width: calc(13px + 50%);
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.mainRed};
  svg {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const PlusIcon = styled(AddIcon)`

}`;

export const SubText = styled.div`
  font-size: 14px;
`;
