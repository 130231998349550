export const REMOVAL_TYPES = {
  REMOVE: 'remove',
  DEACTIVATE: 'deactivate',
};

export const CONFIRMATION_TEXTS = {
  [REMOVAL_TYPES.REMOVE]: 'This user will be removed from all sites and all teams.',
  [REMOVAL_TYPES.DEACTIVATE]: 'This user will be removed from all teams.',
};

export const CONFIRMATION_BUTTON_LABELS = {
  [REMOVAL_TYPES.REMOVE]: 'Delete',
  [REMOVAL_TYPES.DEACTIVATE]: 'Deactivate',
};

export const ALERT_REQUIRING_TASKS_BUTTON_LABELS = {
  [REMOVAL_TYPES.REMOVE]: 'Remove this person',
  [REMOVAL_TYPES.DEACTIVATE]: 'Deactivate this person',
};

export const COMPLETION_TEXTS = {
  CREATING: 'Team creation succeeded!',
  EDITING: 'Team update succeeded!',
};

export const TASK_SPECIALIST_TITLE_DELETE = {
  0: 'All tasks will be re-assigned.',
  1: 'All none completed tasks will be deleted.',
};

export const ACTIONS = {
  none: -1,
  open: 0,
  delete: 1,
};
