import styled from 'styled-components';
import { ButtonIcon } from 'components/Layout/Buttons';
import { AddCircleIcon, RemoveCircleIcon } from 'components/Layout/Icons';
import { RadioButtonGroup } from 'material-ui/RadioButton';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';

export const Container = styled.div`
  padding: 30px 30px 15px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  p {
    margin: 0;
    padding-bottom: 15px;

    span {
      font-weight: 700;
      color: ${({ theme }) => theme.primaryBlack};
    }
  }
`;

export const IconButton = styled(ButtonIcon)`
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  transform: translate3d(0, 0, 0) !important;
`;

export const Title = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.primaryRed};
  font-weight: 300;
  max-width: 450px;
  margin: 10px auto;
`;

export const Section = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
`;

export const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export const MatchSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  span {
    display: inline-block;
    margin-right: 10px;
  }
`;

export const RuleContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
  grid-column-gap: 10px;
`;

export const RuleButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddIcon = styled(AddCircleIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
  isScaling: false,
})``;

export const RemoveIcon = styled(RemoveCircleIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const RadioGroup = styled(RadioButtonGroup)`
  display: flex;
  flex: 1;
`;

export const PreviewResultsContainer = styled.div`
  padding: 30px 0 40px;
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-column-gap: 20px;
  align-items: center;
`;

export const PreviewResultsInfo = styled.div`
  opacity: ${({ isVisible }) => (isVisible === 'true' ? 1 : 0)};
  transition: opacity 0.4s;

  p {
    margin-top: 0;
    user-select: none;

    span {
      color: ${({ theme }) => theme.primaryBlack};
      font-weight: 700;
    }
  }
`;

export const Tooltip = styled.div`
  padding-top: 10px;
  width: 200px;
`;

export const TooltipText = styled.p`
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  text-align: center;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  line-height: 1.3 !important;
`;

export const Checkbox = styled(CheckboxAdapter).attrs({
  style: { marginBottom: '15px' },
  iconStyle: ({ input, theme }) => ({ fill: input.value && theme.primaryScheduler }),
})``;
