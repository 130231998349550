import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 30px;
`;

export const ListItemContainer = styled.div`
  z-index: ${({ index }) => index} !important;
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const ListItem = styled.li`
  min-height: 48px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > div:last-child {
    justify-content: center;
  }
`;

export const ItemNameAndCheckbox = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
  text-align: left;
`;

export const ItemName = styled.div`
  font-size: 14px;
`;
