import styled from 'styled-components';

export const Container = styled.div`
  margin: 25px 74px;
  padding-bottom: 60px;
  color: ${({ theme }) => theme.tenantNameColor};
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const Content = styled.div`
  width: 85px;
  height: 85px;
  margin: 15px auto;
`;

export const Image = styled.img`
  vertical-align: middle;
  width: 85px;
  height: 85px;
`;

export const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QRText = styled.div`
  padding-top: 50px;
  text-align: center;
`;

export const Section = styled.div`
  margin: 10px 0px;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  min-height: 135px;
`;

export const ImageContainer = styled.div`
  width: 49%;
`;

export const PdfContainer = styled.div`
  width: 49%;
`;

export const FileSectionBody = styled.div`
  padding: 20px 15px;
`;

export const InternalSection = styled(Section)`
  padding: 0;
  overflow: hidden;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
`;

export const InternalSectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

export const InternalSectionBody = styled.div`
  padding: 20px 15px;
`;

export const DescriptionContainer = styled.div`
  width: 74%;
`;

export const InformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BarcodeContainer = styled.div`
  width: 24%;
`;

export const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BarcodeLink = styled.div`
  color: #0000ee;
  cursor: pointer;
  text-align: center;
  min-width: 0;
  text-decoration-line: underline;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :active {
    color: #ff0000;
  }

  :focus {
    outline: 1px dotted #000000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  :visited {
    color: #551a8b;
  }
`;
