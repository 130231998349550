export function getAssignedItems<T extends { IsAssigned: boolean; Id: string }, K extends string>(
  items: T[],
  id: string,
  key: K,
): Array<Record<'AssignedTaskId' | K, string>> {
  return items.reduce<Array<Record<'AssignedTaskId' | K, string>>>((acc, item) => {
    if (item.IsAssigned) {
      const newItem = { AssignedTaskId: id } as Record<'AssignedTaskId' | K, string>;
      newItem[key] = item.Id;
      return [...acc, newItem];
    }

    return acc;
  }, []);
}
