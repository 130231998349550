import React from 'react';
import moment from 'moment';

import * as S from './styled';

const Item = request => (
  <S.Container>
    <S.Cell>
      <S.Text>
        Company Name: <S.ItemValue>{request.RequestorCompany}</S.ItemValue>
      </S.Text>
    </S.Cell>
    <S.Cell>
      <S.Text>
        Contact Name: <S.ItemValue>{request.RequestorName}</S.ItemValue>
      </S.Text>
    </S.Cell>
    <S.Cell>
      <S.Text>
        Request Date: <S.ItemValue>{moment(request.CreatedOn).format('MMM-DD-YYYY')}</S.ItemValue>
      </S.Text>
    </S.Cell>
    <S.Cell>
      <S.Text>
        Time: <S.ItemValue>{moment(request.CreatedOn).format('hh:mm A')}</S.ItemValue>
      </S.Text>
    </S.Cell>
    <S.Cell>
      <S.Text>
        Our Notes: <S.ItemValue>{request.ControllerNotes}</S.ItemValue>
      </S.Text>
    </S.Cell>
  </S.Container>
);

export default Item;
