import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { actions as authActions } from 'redux/auth';
import { actions as tasksActions } from 'redux/tasks';
import { actions as tenantsActions } from 'redux/tenants';

import { getUser } from 'helpers/storage';

import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import * as S from './styled';

const LoginPage = () => {
  const [authMode, setAuthMode] = useState('signin');
  const authenticated = useSelector(state => state.auth.authenticated);
  const loading = useSelector(state => state.auth.loading);
  const industryList = useSelector(state => state.tenants.industryList);
  const tenantRequestsStatistics = useSelector(state => state.tenants.tenantRequestsStatistics);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetedUnset());
    dispatch(tasksActions.resetRangeStart());
    dispatch(tenantsActions.tenantIndustryListRequest());
    dispatch(tenantsActions.tenantRequestsStatisticsRequest());
  }, [dispatch]);

  useEffect(() => {
    const storedUser = getUser();

    if (storedUser && authenticated && !loading) {
      const path = location.state ? location.state.from.pathname : '/home';
      history.push(path);
    }
  }, [authenticated, history, loading, location.state]);

  const chooseSignInTab = () => setAuthMode('signin');
  const chooseSignUpTab = () => setAuthMode('signup');

  return (
    <>
      <S.RootContainer>
        <S.Container isActive={authMode === 'signin'}>
          <LoginForm loading={loading} authMode={authMode} chooseSignInTab={chooseSignInTab} />
        </S.Container>
        <S.Container isActive={authMode === 'signup'}>
          <SignUpForm
            authMode={authMode}
            chooseSignUpTab={chooseSignUpTab}
            industryList={industryList}
            chooseSignInTab={chooseSignInTab}
            tenantRequestsStatistics={tenantRequestsStatistics}
          />
        </S.Container>
      </S.RootContainer>
    </>
  );
};

export default LoginPage;
