export const tableColumns = [{ field: 'Name' }, { field: 'ModelNumber' }, { field: 'Checklists' }];

export const TASK_CREATION_OPTIONS = [
  {
    label: 'Day before',
    value: 0,
  },
  {
    label: 'Week before',
    value: 1,
  },
  {
    label: 'Month before',
    value: 2,
  },
  {
    label: 'Year before',
    value: 4,
  },
];
