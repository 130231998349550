import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';

import { UserType } from 'configs/propTypes';
import { USER_TYPES } from 'configs/enums';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import validate from './validator';
import { EditWarning, FormContainer, FieldContainer, PasswordShowButton } from './styled';

const AddEditPersonForm = ({
  tenantIsOEM,
  editDefaultAdmin,
  jobsList,
  openAccountsDialog,
  showPassword,
  handleShowPassword,
  userType,
}) => (
  <FormContainer>
    {editDefaultAdmin && <EditWarning>Default administrator can&apos;t be edited</EditWarning>}
    <Field component={TextFieldAdapter} floatingLabelText="Username" name="Name" disabled={editDefaultAdmin} />
    <Field component={TextFieldAdapter} floatingLabelText="First Name" name="FirstName" disabled={editDefaultAdmin} />
    <Field component={TextFieldAdapter} floatingLabelText="Last Name" name="LastName" disabled={editDefaultAdmin} />
    <Field component={SelectFieldAdapter} floatingLabelText="User Type" name="UserType" disabled={editDefaultAdmin}>
      {tenantIsOEM
        ? [
            <MenuItem key="MenuItemOEMAdministrator" primaryText="OEM Administrator" value="OEMAdministrator" />,
            <MenuItem key="MenuItemOEMManager" primaryText="OEM Manager" value="OEMManager" />,
          ]
        : [
            <MenuItem key="MenuItemTaskSpecialist" primaryText="Task Specialist" value="TaskSpecialist" />,
            <MenuItem key="MenuItemManager" primaryText="Manager" value="Manager" />,
            <MenuItem
              key="MenuItemSystemAdministrator"
              primaryText="System Administrator"
              value="SystemAdministrator"
            />,
            <MenuItem key="MenuItemDashboardUser" primaryText="Dashboard User" value="DashboardUser" />,
          ]}
    </Field>
    <Field component={TextFieldAdapter} floatingLabelText="Email" name="Email" disabled={editDefaultAdmin} />
    <Field
      component={TextFieldAdapter}
      floatingLabelText="Phone"
      type="number"
      name="Phone"
      disabled={editDefaultAdmin}
    />
    <Field component={SelectFieldAdapter} floatingLabelText="Status" name="Status" disabled={editDefaultAdmin}>
      <MenuItem primaryText="Active" value="Active" />
      <MenuItem primaryText="Inactive" value="Inactive" />
    </Field>
    <Field
      component={SelectFieldAdapter}
      floatingLabelText="Job Function"
      name="JobFunction"
      disabled={editDefaultAdmin}
    >
      {!!jobsList && jobsList.map(item => <MenuItem primaryText={item.Name} value={item.Id} />)}
      {userType === USER_TYPES.SystemAdministrator && (
        <MenuItem primaryText="Edit List" value="Edit List" onClick={openAccountsDialog} />
      )}
    </Field>
    <FieldContainer>
      <Field
        component={TextFieldAdapter}
        floatingLabelText="Password"
        name="SubmittedPassword"
        type={showPassword ? 'text' : 'password'}
        disabled={editDefaultAdmin}
      />
      <PasswordShowButton type="button" onClick={handleShowPassword}>
        {showPassword ? 'hide' : 'show'}
      </PasswordShowButton>
    </FieldContainer>
  </FormContainer>
);

AddEditPersonForm.propTypes = {
  editDefaultAdmin: PropTypes.bool.isRequired,
  tenantIsOEM: PropTypes.bool.isRequired,
  jobsList: PropTypes.array.isRequired,
  openAccountsDialog: PropTypes.func.isRequired,
  userType: PropTypes.number.isRequired,
  handleShowPassword: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
};

const AddEditPersonWithInput = withInputModal(AddEditPersonForm);

const AddEditPersonDialog = ({ person, jobsList, ...rest }) => {
  const initialValues = { ...person, JobFunction: !person.Name ? jobsList[0]?.Id : person.JobFunction };
  if (!initialValues.UserType) {
    initialValues.UserType = rest.tenantIsOEM ? 'OEMManager' : 'TaskSpecialist';
  }
  return <AddEditPersonWithInput {...rest} jobsList={jobsList} initialValues={initialValues} validate={validate} />;
};

AddEditPersonDialog.defaultProps = {
  handleDelete: () => {},
  person: {
    JobFunction: 'Trainee',
    Status: 'Active',
  },
  withDelete: false,
};

AddEditPersonDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  person: UserType,
  title: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
  jobsList: PropTypes.array.isRequired,
};

export default AddEditPersonDialog;
