import styled from 'styled-components';

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  & svg {
    color: ${({ theme }) => theme.primaryWhite} !important;
    width: 24px !important;
    height: 24px !important;
  }
`;

const Text = styled.span`
  padding-right: 20px;
  font-weight: 300;
`;

const Wrapper = styled.div`
  display: flex;
`;

export { Controls, Text, Wrapper };
