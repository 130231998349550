import theme from 'theme';

const toggleStyles = {
  thumbOff: {
    backgroundColor: theme.primaryGrey,
  },
  trackOff: {
    backgroundColor: theme.lightGrey,
  },
  thumbSwitched: {
    backgroundColor: theme.primaryTasks,
  },
  trackSwitched: {
    backgroundColor: theme.primaryTasks,
    opacity: 0.5,
  },
};

export { toggleStyles };
