import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useFormik } from 'formik';
import { Modal } from 'components/Modal';
import theme from 'theme';

import { actions as inventoryActions } from 'redux/inventory';
import { InventoryPartType, PartGroupsType, TransactionType } from 'types/inventory';

import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { Textarea } from 'components/Textarea';

import { TransactionReasonType } from '../../ui/InventoryChanges';
import { TransactionsDialog } from '../TransactionsDialog';

import * as S from './styled';

type PropsType = {
  entityType: 'Part' | 'PartGroup';
  currentEntity: InventoryPartType | PartGroupsType;
  title: string;
  negativeBtnLabel: string;
  recordType: TransactionReasonType;
  transaction: TransactionType;
};

export const AddOrRemovePartDialog = NiceModal.create(
  ({ title, negativeBtnLabel, entityType, currentEntity, recordType, transaction }: PropsType) => {
    const modal = useModal();
    const dispatch = useDispatch();
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        current: Number(transaction.Quantity),
        newQuantity: 0,
        newResult: 0,
        reason: '',
      },
      validate: values => {
        const errors = {} as { reason: string };
        if (!values.reason) {
          errors.reason = 'Required';
        }

        return errors;
      },
      onSubmit: values => {
        NiceModal.show(ConfirmationModal, {
          label: 'YES',
          labelCancel: 'RETURN',
          isCancelNext: true,
          text: (
            <S.ConfirmationText>
              <b>
                This {negativeBtnLabel === 'ADD' ? 'adds' : 'removes'} {values.newQuantity || 0} parts{' '}
                {negativeBtnLabel === 'ADD' ? 'to' : 'from'} inventory
              </b>
              <br /> Are you sure?
            </S.ConfirmationText>
          ),
          maxWidth: '600px',
          onClick: () => {
            const payload = {
              entityType,
              recordType,
              dto: {
                inventoryEntityId: currentEntity.Id,
                quantity: Number(formik.values.newQuantity),
                type: negativeBtnLabel === 'ADD' ? 'Add' : 'Sub',
                comment: formik.values.reason,
              },
            };

            dispatch(inventoryActions.createPartTransactionRequest(payload));
            handleClose();
          },
          onCancelClick: () => NiceModal.hide(ConfirmationModal),
        });
      },
    });

    const handleClose = () => {
      formik.resetForm();
      NiceModal.hide(TransactionsDialog);
      NiceModal.hide(ConfirmationModal);
      modal.hide();
    };

    const onQuantityChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      const newQuantity = Number(e.currentTarget.value);

      if (negativeBtnLabel === 'ADD') {
        formik.setFieldValue('newResult', formik.values.current + newQuantity);
      } else {
        formik.setFieldValue('newResult', formik.values.current - newQuantity);
      }

      formik.setFieldValue('newQuantity', newQuantity);
    };

    const onRequestClose = () => {
      formik.resetForm();
      modal.hide();
    };

    return (
      <Modal
        onRequestClose={onRequestClose}
        title={title}
        titleColor={theme.mainRed}
        isOpen={modal.visible}
        onAfterClose={modal.remove}
      >
        <S.RootContainer onSubmit={formik.handleSubmit}>
          {entityType === 'Part' && <S.Title>{(currentEntity as InventoryPartType).MyPartID}</S.Title>}
          <S.BoldTitle>
            {entityType === 'Part'
              ? (currentEntity as InventoryPartType).MyPartName
              : (currentEntity as PartGroupsType).MyGroupName}
          </S.BoldTitle>

          <S.Text>
            The following quantity is {negativeBtnLabel === 'ADD' ? 'added' : 'subtracted'} to <br />
            correct the inventory count:
          </S.Text>
          <S.Container>
            <div>
              <S.Input type="number" value={formik.values.current} name="current" isRedBack />
              <S.Name>Current</S.Name>
            </div>
            <div>
              <S.Input
                type="number"
                value={formik.values.newQuantity}
                onChange={onQuantityChangeHandler}
                name="newQuantity"
              />
              <S.Name>enter qty to {negativeBtnLabel === 'ADD' ? 'add' : 'remove'}</S.Name>
            </div>
            <div>
              <S.Input type="number" name="newResult" value={formik.values.newResult} isRedBack />
              <S.Name>New</S.Name>
            </div>
          </S.Container>

          <BorderRadiusContainer column>
            <S.Label>Inventory Changes</S.Label>
            <S.Wrapper>
              <S.Paragraph>Please provide a reason (required)</S.Paragraph>
              <Textarea
                placeholder="Adjustment Reason"
                styles={S.textareaStyles}
                {...formik.getFieldProps('reason')}
                meta={formik.getFieldMeta('reason')}
              />
            </S.Wrapper>
          </BorderRadiusContainer>
          <ActionsContainer gapValue={10} paddingValue={14}>
            <ActionButton type="button" isNext isGreenLabel label="CANCEL" labelFontSize="18px" onClick={modal.hide} />
            <ActionButton
              type="submit"
              label={negativeBtnLabel}
              labelFontSize="18px"
              disabled={!formik.isValid}
              isDisable={!formik.isValid}
            />
          </ActionsContainer>
        </S.RootContainer>
      </Modal>
    );
  },
);
