import { ButtonFlat } from 'components/Layout/Buttons';
import styled, { css } from 'styled-components';
import { DeleteIcon as Delete } from 'components/Layout/Icons';

const partStyles = css`
  & td:nth-child(-n + 3),
  th:nth-child(-n + 3) {
    border-right: 1px solid ${({ theme }) => theme.primaryGrey};
  }

  & td:nth-child(3) {
    background-color: ${({ theme }) => theme.fieldsBorderColor};
  }
`;

export const TableContainer = styled.div`
  & td:first-child {
    width: 50px;
  }

  & th:not(:first-child) {
    padding-left: 10px;
  }

  ${partStyles}

  // ${({ mode }) => mode === 'parts' && partStyles};


  & th:nth-child(5) {
    width: 30%;
  }

  & th:first-child {
    background-color: ${({ theme }) => theme.primaryWhite};
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div:last-child {
        & > div {
          justify-content: center;
          & > div {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  & td:first-child > div {
    margin-left: 14px;
  }
`;

export const DeleteButton = styled(ButtonFlat)`
  && {
    min-width: ${({ fullWidth }) => !fullWidth && '50%'} !important;
    padding: ${({ fullWidth }) => fullWidth && '0 20px 0 20px'} !important;
    color: ${({ theme, invert }) => (invert ? theme.primaryWhite : theme.modalCancelColor)} !important;
    background-color: ${({ theme, invert }) => invert && theme.primaryRed} !important;
  }
`;

export const CancelButton = styled(ButtonFlat).attrs({
  label: 'Cancel',
})`
  && {
    min-width: 50% !important;
    color: ${({ theme }) => theme.modalOkColor} !important;
  }
`;

export const ModalText = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 20px;
`;

export const Page = styled.div`
  position: relative;
`;

export const DeleteIcon = styled(Delete)`
  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;
