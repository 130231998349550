export const notificationInfo = {
  SendDailyMail: `Selected users will receive a daily mail with a
  summary of activities`,
  SendIotMail: `Selected users will receive an e-mail message
  with IOT activity for current day`,
  SendIotTxt: `Selected users will receive an (sms) TxT Message
  This message will contain the following:
  - Sensor name -
  - Site ID and Name -
  - List Template name -
  - Item name -
  A link to the dashboard to view the data`,
  SendIotPush: `Selected users will receive a Push notification through
  the web application
  This message will contain the following:
  - Sensor name -
  - Site ID and Name -
  - List Template name -
  - Item name -
  A link to the dashboard to view the data`,
};

export const notAvailableInfo = 'This action is not available for this user type';
