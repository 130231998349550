import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import theme from 'theme';

import { UnitItemType, UnitPdfType, UnitPictureType, UnitType } from 'configs/propTypes';
import { exportUnitQR, exportUnitPdf } from 'http/unit';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as unitActions } from 'redux/unit';
import { actions as unitsActions } from 'redux/units';
import { getUnitsIdsList } from 'redux/units/selectors';
import http from '../../../../http';
import DragItemsListDialog from '../UnitsList/DragItemsListDialog';

import Footer from 'components/Footer';

import Details from './Details';
import ItemsList from './ItemsList';
import TopBar from './TopBar';
import * as S from './styled';

let barcode = '';
let timestamp = '';

const getImageSource = (unitId, barcodeValue) => {
  if (barcodeValue !== barcode) {
    timestamp = Date.now();
    barcode = barcodeValue;
  }

  return (
    `${http.defaults.baseURL}/UnitBarcode/Get` +
    `?unitId=${unitId}&siteId=${http.defaults.siteId}&width=200&height=200&inline=true&_dc=${timestamp}`
  );
};

class UnitDetails extends PureComponent {
  static propTypes = {
    deleteUnitPdf: PropTypes.func.isRequired,
    deleteUnitPicture: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    fetchUnitData: PropTypes.func.isRequired,
    fetchUnitsList: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    pdfs: PropTypes.arrayOf(UnitPdfType).isRequired,
    pictures: PropTypes.arrayOf(UnitPictureType).isRequired,
    selectedSite: PropTypes.string.isRequired,
    unitPdfUploaded: PropTypes.bool.isRequired,
    unitPictureUploaded: PropTypes.bool.isRequired,
    unit: UnitType.isRequired,
    unitsIdsList: PropTypes.arrayOf(PropTypes.string).isRequired,
    unsetUnitPdfUploadedFlad: PropTypes.func.isRequired,
    unsetUnitPictureUploadedFlad: PropTypes.func.isRequired,
    uploadUnitPdf: PropTypes.func.isRequired,
    uploadUnitPicture: PropTypes.func.isRequired,
    getUnitItemsTasks: PropTypes.func.isRequired,
    unitWithItemsTasksList: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateUnitItemsTasks: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(UnitItemType).isRequired,
    isUnitLoading: PropTypes.bool.isRequired,
    updateItemsList: PropTypes.func.isRequired,
  };

  state = {
    sequenceSetupDialogOpened: false,
    dragItemsListDialogOpened: false,
    type: 'items',
    searchData: '',
  };

  componentDidMount() {
    const {
      fetchUnitData,
      fetchUnitsList,
      match: {
        params: { unitId },
      },
      selectedSite,
    } = this.props;

    fetchUnitData(unitId);
    fetchUnitsList({ filter: { SiteID: selectedSite } });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error && nextProps.error !== this.props.error) {
      this.props.openErrorDialog(nextProps.error, 'Alert');
    }

    if (nextProps.unitPdfUploaded) {
      this.props.unsetUnitPdfUploadedFlad();
    }

    if (nextProps.unitPictureUploaded) {
      this.props.unsetUnitPictureUploadedFlad();
    }

    if (nextProps.match.params.unitId !== this.props.match.params.unitId) {
      this.props.fetchUnitData(nextProps.match.params.unitId);
    }
  }

  onDeleteUnitPdf = (pdfId, pdfName) => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this PDF?',
      () => this.props.deleteUnitPdf({ pdfId, pdfName }),
      'Delete PDF?',
    );
  };

  onDeleteUnitPicture = (pictureId, pictureName) => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Photo?',
      () => this.props.deleteUnitPicture({ pictureId, pictureName }),
      'Delete Photo?',
    );
  };

  onNextUnit = () => {
    const {
      history: { push },
      match: {
        params: { unitId },
      },
      unitsIdsList,
    } = this.props;
    const nextUnitIndex = unitsIdsList.indexOf(unitId) + 1;

    const index = nextUnitIndex > unitsIdsList.length - 1 ? 0 : nextUnitIndex;
    push(`../items/${unitsIdsList[index]}`);
  };

  onPrevUnit = () => {
    const {
      history: { push },
      match: {
        params: { unitId },
      },
      unitsIdsList,
    } = this.props;
    const prevUnitIndex = unitsIdsList.indexOf(unitId) - 1;

    const index = prevUnitIndex < 0 ? unitsIdsList.length - 1 : prevUnitIndex;
    push(`../items/${unitsIdsList[index]}`);
  };

  exportUnitPdf = item => exportUnitPdf(item.Id, item.UnitId, true);

  exportQR = () => exportUnitQR(this.props.unit.Id);

  goBack = () => this.props.history.push('/units');

  toggleSequenceSetupDialog = () => {
    const { unit, getUnitItemsTasks } = this.props;
    const { Id } = unit;

    getUnitItemsTasks({ Id });

    this.setState(prevState => ({
      sequenceSetupDialogOpened: !prevState.sequenceSetupDialogOpened,
    }));
  };

  prepareUnitItemsTasksDragListContent = () => {
    const { unitWithItemsTasksList } = this.props;
    return unitWithItemsTasksList.map((item, i) => ({
      content: item.Name,
      sequence: i + 1,
      isStrictOrder: item.IsStrictOrder,
      id: item.Id,
      items: item.TaskTemplates.map((task, j) => ({
        id: task.Id,
        content: task.RuleType,
        sequence: j + 1,
        tooltipTitle: task.Name,
      })),
    }));
  };

  filterList = item => item?.Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  typeHandler = status => {
    this.setState({ type: status.key });
  };

  searchInList = event => {
    this.setState({
      searchData: event.target.value,
    });
  };

  itemsForReorder = items => {
    if (!items.length) {
      return [];
    }

    return [
      {
        items: items.map(el => ({
          id: el.Id,
          content: el.Name,
          sequence: el.Sequence,
        })),
        content: items[0].UnitName,
      },
    ];
  };

  openDragItemsListDialog = () => this.setState({ dragItemsListDialogOpened: true });

  closeDragItemsListDialog = () => this.setState({ dragItemsListDialogOpened: false });

  saveNewUnit = items => {
    const {
      updateItemsList,
      match: {
        params: { unitId },
      },
    } = this.props;

    updateItemsList({ items, unitId });
  };

  rightButtons = [
    {
      icon: <S.ItemsIcon />,
      handler: this.openDragItemsListDialog,
      hint: 'Items',
      tooltipStyles: { marginTop: '-10px' },
    },
    {
      icon: <S.SequenceSetupIcon />,
      hint: 'Tasks',
      handler: this.toggleSequenceSetupDialog,
      tooltipStyles: { marginTop: '-10px' },
    },
  ];

  render() {
    const { sequenceSetupDialogOpened, dragItemsListDialogOpened, type, searchData } = this.state;
    const {
      pdfs,
      pictures,
      uploadUnitPdf,
      uploadUnitPicture,
      unit,
      updateUnitItemsTasks,
      items,
      isUnitLoading,
      unitsIdsList,
    } = this.props;
    const hasOnlyOneOrLessUnit = unitsIdsList.length <= 1;

    return (
      <S.Container>
        <TopBar
          type={type}
          key="Unit Details Bar"
          title={<S.RedTitle>{unit.Name}</S.RedTitle>}
          goBack={this.goBack}
          searchData={searchData}
          searchInList={this.searchInList}
          rightButtons={this.rightButtons}
        />
        {type === 'items' ? (
          <S.Content key="Unit Details Content">
            <ItemsList items={items.filter(this.filterList)} type={type} typeHandler={this.typeHandler} />
          </S.Content>
        ) : (
          <Details
            getImageSource={getImageSource}
            uploadUnitPdf={uploadUnitPdf}
            pdfs={pdfs}
            exportUnitQR={this.exportQR}
            pictures={pictures}
            unit={unit}
            type={type}
            onDeleteUnitPicture={this.onDeleteUnitPicture}
            uploadUnitPicture={uploadUnitPicture}
            typeHandler={this.typeHandler}
            onDeleteUnitPdf={this.onDeleteUnitPdf}
            exportUnitPdf={this.exportUnitPdf}
          />
        )}
        <Footer
          key="Unit Details Footer"
          isDisabled={hasOnlyOneOrLessUnit}
          label="Template"
          onNext={this.onNextUnit}
          onPrev={this.onPrevUnit}
          iconColor={theme.primaryLists}
        />
        {sequenceSetupDialogOpened && (
          <DragItemsListDialog
            open={sequenceSetupDialogOpened}
            key="DragItemsList Dialog"
            units={this.prepareUnitItemsTasksDragListContent()}
            handleClose={this.toggleSequenceSetupDialog}
            onSubmit={data => updateUnitItemsTasks({ values: data })}
            listId="ItemsTasks"
            title="Sequence Setup (drag tasks to order)"
          />
        )}
        {dragItemsListDialogOpened && (
          <DragItemsListDialog
            key="DragItemList Dialog"
            handleClose={this.closeDragItemsListDialog}
            units={this.itemsForReorder(items)}
            isLoading={isUnitLoading}
            onSubmit={this.saveNewUnit}
            open={dragItemsListDialogOpened}
            listId="UnitItems"
            commonLockHide
          />
        )}
      </S.Container>
    );
  }
}

const mapStateToProps = ({ auth: { selectedSite }, unit, units }) => ({
  error: unit.error || units.error,
  pdfs: unit.unitPdfsList,
  pictures: unit.unitPicturesList,
  selectedSite,
  unitPdfUploaded: unit.unitPdfUploaded,
  unitPictureUploaded: unit.unitPictureUploaded,
  unit: unit.unitData,
  unitsIdsList: getUnitsIdsList(units),
  unitWithItemsTasksList: unit.unitWithItemsTasksList,
  items: unit.unitItemsList,
  isUnitLoading: unit.loading,
});

const mapDispatchToProps = {
  deleteUnit: unitActions.deleteUnitRequest,
  deleteUnitPdf: unitActions.deleteUnitPdfRequest,
  deleteUnitPicture: unitActions.deleteUnitPictureRequest,
  editUnit: unitActions.editUnitRequest,
  fetchUnitData: unitActions.unitDataFetchRequest,
  fetchUnitsList: unitsActions.unitsListRequest,
  openConfirmationDialog,
  openErrorDialog,
  unsetUnitPdfUploadedFlad: unitActions.unitPdfUploadedFlagUnset,
  unsetUnitPictureUploadedFlad: unitActions.unitPictureUploadedFlagUnset,
  uploadUnitPdf: unitActions.uploadUnitPdfRequest,
  uploadUnitPicture: unitActions.uploadUnitPictureRequest,
  getUnitItemsTasks: unitActions.getUnitItemsTasksRequest,
  updateUnitItemsTasks: unitActions.updateTasksListRequest,
  updateItemsList: unitsActions.updateItemsListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetails);
