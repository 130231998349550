import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'components/Dialogs/v1/Base';

import { ShortCutType, SiteType, UnitType, UnitItemType } from 'configs/propTypes';

import theme from 'theme';

import * as S from './styled';

const generatePreviewResults = entity =>
  entity.map(({ AdditionalSiteID, Name, Id }) => (
    <S.PreviewItem key={Id}>
      {AdditionalSiteID && <S.PreviewItemText>{AdditionalSiteID.substring(0, 5)} &nbsp;</S.PreviewItemText>}
      <S.PreviewItemText>{Name}</S.PreviewItemText>
    </S.PreviewItem>
  ));

const ShortcutTestrunDialog = ({ open, handleClose, shortcutToPreview, results, withHeader, shortcutName }) => (
  <Dialog open={open} onRequestClose={handleClose} title="Shortcut Testrun" titleColor={theme.mainDarkBlue}>
    <S.Container>
      {withHeader && (
        <S.Info>
          <S.PreviewItemText>{shortcutToPreview.Name || shortcutName}</S.PreviewItemText>
        </S.Info>
      )}
      {results.length > 0 &&
        results.map(({ data, header, foundItems, isEmpty }) => (
          <S.Section key={header}>
            <S.SectionHeader>
              {header}
              <S.IconContainerPreview>
                <S.TestRunIconPreview />
              </S.IconContainerPreview>
            </S.SectionHeader>
            <S.SectionBody>
              <S.CounterContainer>
                <S.PreviewItemText>{foundItems}</S.PreviewItemText>
              </S.CounterContainer>
              {!isEmpty && generatePreviewResults(data)}
            </S.SectionBody>
          </S.Section>
        ))}
      <S.RedInfo>(Scheduling is setup per Site separately)</S.RedInfo>
    </S.Container>
  </Dialog>
);

ShortcutTestrunDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  withHeader: PropTypes.bool,
  shortcutName: PropTypes.string,
  shortcutToPreview: ShortCutType.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.oneOfType(
        PropTypes.arrayOf(SiteType),
        PropTypes.arrayOf(UnitType),
        PropTypes.arrayOf(UnitItemType),
        PropTypes.string,
      ),
      isEmpty: PropTypes.bool,
      header: PropTypes.string,
      foundItems: PropTypes.string,
    }),
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
};

ShortcutTestrunDialog.defaultProps = {
  withHeader: true,
  CustomHeaderComponent: null,
  shortcutName: '',
};

export default ShortcutTestrunDialog;
