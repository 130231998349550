import React from 'react';
import PropTypes from 'prop-types';

import { uploadParts } from 'http/parts';

import * as S from './styled';

export default class Upload extends React.Component {
  static propTypes = {
    onUploadStateChanged: PropTypes.func.isRequired,
  };

  state = {
    isFileSelected: false,
    isLoading: false,
    selectedFile: null,
    uploadInfo: {},
  };

  clearFileInput = event => {
    event.target.value = null;
  };

  handleFileChange = event => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    this.props.onUploadStateChanged(false, {});
    this.setState({
      isFileSelected: true,
      selectedFile: file,
      isLoading: true,
      uploadInfo: {},
    });

    uploadParts(file).then(info => {
      this.setState({ uploadInfo: info.entity, isLoading: false }, () => {
        this.props.onUploadStateChanged(true, info.entity);
      });
    });
  };

  render() {
    const { selectedFile, uploadInfo, isLoading } = this.state;

    return (
      <S.Container>
        <S.Header>
          <S.UploadContainer>
            <S.FileUpload>
              <S.FileUploadText>Select Parts File and Start Upload</S.FileUploadText>
              <S.UploadIcon />
            </S.FileUpload>
            <S.FileInput type="file" onChange={this.handleFileChange} onClick={this.clearFileInput} />
          </S.UploadContainer>
          <S.PartsFileText>
            {isLoading ? <S.Spinner size={24} /> : null}
            {selectedFile ? `Parts File ${selectedFile.name}` : null}
          </S.PartsFileText>
        </S.Header>
        <S.List>
          {uploadInfo.Results &&
            uploadInfo.Results.map((result, index) => (
              <S.Item key={result[index]}>
                <S.Loader />
                <S.InfoText>{result.Message}</S.InfoText>
                <S.CheckContainer>
                  <S.CheckCircle isSuccess={result.IsSuccess}>
                    <S.ResultCheckIcon />
                  </S.CheckCircle>
                </S.CheckContainer>
                <S.Count>{result.EntitiesNumber ? result.EntitiesNumber : null}</S.Count>
              </S.Item>
            ))}
        </S.List>
      </S.Container>
    );
  }
}
