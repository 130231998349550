import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { omit } from 'lodash';

import theme from 'theme';
import { actions as inventoryActions } from 'redux/inventory';
import { InventoryPartType, PartGroupsType } from 'types/inventory';
import RadioGroup from 'components/RadioButtons';
import { Modal } from 'components/Modal';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';

import { getStatusOptions } from '../PartProfileDialog';

import * as S from './styled';

type PropsType = {
  type: 'parts' | 'groups';
  data: InventoryPartType | PartGroupsType;
  history: RouteComponentProps['history'];
  inventoryPeriodId: string;
};

export const SetStatusForInventoryDialog = NiceModal.create(({ data, history, type, inventoryPeriodId }: PropsType) => {
  const modal = useModal();
  const [isUnlockPartBtnClicked, setIsUnlockPartBtnClicked] = useState(false);
  const [status, setStatus] = useState(getStatusOptions(type)[1].value);
  const dispatch = useDispatch();

  const isPartInventory = type === 'parts';
  const title = isPartInventory ? 'Part' : 'Group';

  const goToPartDetail = () =>
    history.push(
      `/parts/${type}/${isPartInventory ? (data as InventoryPartType).PartId : (data as PartGroupsType).PartGroupId}`,
    );

  const unlockPart = () => {
    setIsUnlockPartBtnClicked(true);
    if (isUnlockPartBtnClicked) {
      const dataWithoutElement = omit(data, ['POAdvice', 'Status']);
      const dto = { ...dataWithoutElement, status };
      const payload = { dto, inventoryPeriodId };
      if (isPartInventory) {
        dispatch(inventoryActions.editInventoryPartRequest(payload));
      } else {
        dispatch(inventoryActions.editInventoryPartGroupRequest(payload));
      }

      modal.hide();
    }
  };

  const openConfirmationModal = () =>
    NiceModal.show(ConfirmationModal, {
      label: 'OK',
      labelCancel: 'RETURN',
      isCancelNext: true,
      text: (
        <S.ConfirmationText>
          <b>Leaving Inventory.</b>
          <div>Are you sure?</div>
        </S.ConfirmationText>
      ),
      maxWidth: '600px',
      onCancelClick: () => NiceModal.hide(ConfirmationModal),
      onClick: () => {
        NiceModal.hide(ConfirmationModal);
        NiceModal.hide(SetStatusForInventoryDialog);
        goToPartDetail();
      },
    });

  const onStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.currentTarget.value);
  };

  const onRequestClose = () => {
    modal.hide();
    setIsUnlockPartBtnClicked(false);
    setStatus(getStatusOptions(type)[1].value);
  };

  return (
    <Modal isOpen={modal.visible} title="Alert" titleColor={theme.mainRed} onRequestClose={onRequestClose}>
      <>
        {isUnlockPartBtnClicked ? (
          <S.RootContainer>
            <h3>Unlocking Part</h3>
            <S.LabelContainer column>
              <S.Label>Release Status</S.Label>
              <p>
                Active parts are available for release <br /> from inventory.
              </p>
              <RadioGroup
                input={{ value: status, onChange: onStatusChange }}
                meta={{}}
                style={S.radioGroupStyle}
                name="Status"
                items={getStatusOptions(title)}
                radioButtonStyle={S.radioButtonStyle}
                labelStyle={S.labelStyle}
              />
            </S.LabelContainer>
          </S.RootContainer>
        ) : (
          <S.RootContainer hasPaddingTop>
            {isPartInventory && (
              <div>
                <b>My Part ID:</b> {(data as InventoryPartType).MyPartID}
              </div>
            )}
            <div>
              <b>Part Name:</b>{' '}
              {isPartInventory ? (data as InventoryPartType).MyPartName : (data as PartGroupsType).MyGroupName}
            </div>
            <b>This Part is Locked</b>
            <div>
              It can not be purchased or get released. <br /> Quantities cannot be changed.
            </div>
            <S.Wrapper onClick={openConfirmationModal}>
              <S.LinkIcon />
              <S.Text>Full {title} Detail</S.Text>
            </S.Wrapper>
          </S.RootContainer>
        )}
        <ActionsContainer isColumn={!isUnlockPartBtnClicked} gapValue={10} paddingValue={20}>
          <ActionButton type="button" label="Unlock Part" labelFontSize="18px" onClick={unlockPart} />
          <ActionButton isNext isGreenLabel type="button" label="RETURN" onClick={modal.hide} labelFontSize="18px" />
        </ActionsContainer>
      </>
    </Modal>
  );
});
