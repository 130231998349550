/* eslint-disable no-underscore-dangle */
import queryString from 'query-string';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  activateTenantRequest,
  createIndustry,
  createTenant,
  deactivateTenantRequest,
  deleteTenantRequest,
  getApproveDemoTenant,
  getBlockDemoTenant,
  getCurrentTenantPricingValues,
  getCurrentTenantsCount,
  deleteSentRequest,
  deleteUpgradeRequest,
  editUpgradeRequest,
  getIndustryList,
  getListMasterTiers,
  getMasterSystemLimits,
  getOverwritePricingValues,
  getRecentUpgradeRequest,
  getTenantRequestsList,
  getTenantRequestsStatistics,
  getTenantsList,
  getUpgradeRequestsList,
  getUsersList,
  putIndustry,
  putTenant,
  removeIndustry,
  removeTenant,
} from 'http/tenants';
import { formatServerError, stringComparator, EMPTY_GUID } from 'helpers';
import { requestsTypes } from 'configs/enums';

import { actions } from './index';
import { formatTenant } from './formatters';

function* fetchTenantsList() {
  try {
    const { data } = yield call(getTenantsList);
    yield put(actions.tenantsListSuccess(data.root));
  } catch (error) {
    yield put(
      actions.tenantsListFailure({
        error,
        message: formatServerError(error, 'An error occurred while fetching the tenants list.'),
      }),
    );
  }
}

function* addTenant({ payload }) {
  try {
    const params = queryString.stringify(formatTenant(payload));
    const { data } = yield call(createTenant, params);
    yield put(actions.addTenantSuccess(data.entity));
    yield* fetchTenantsList();
  } catch (error) {
    yield put(
      actions.addTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Tenant.'),
      }),
    );
  }
}

function* deleteTenant({ payload }) {
  try {
    yield call(removeTenant, payload);
    yield* fetchTenantsList();
  } catch (error) {
    yield put(
      actions.deleteTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while deleting the Tenant.'),
      }),
    );
  }
}

function* deactivateTenant({ payload }) {
  try {
    yield call(deactivateTenantRequest, payload);
    yield* fetchTenantsList();
  } catch (error) {
    yield put(
      actions.deactivateTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while deactivating the Tenant.'),
      }),
    );
  }
}

function* activateTenant({ payload }) {
  try {
    yield call(activateTenantRequest, payload);
    yield* fetchTenantsList();
  } catch (error) {
    yield put(
      actions.activateTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while deactivating the Tenant.'),
      }),
    );
  }
}

function* editTenant({ payload }) {
  try {
    const params = queryString.stringify(formatTenant(payload));
    const { data } = yield call(putTenant, params);
    yield put(actions.editTenantSuccess(data.entity));
    yield* fetchTenantsList();
  } catch (error) {
    yield put(
      actions.editTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Tenant.'),
      }),
    );
  }
}

function* fetchLoginAsUsers({ payload }) {
  try {
    const { data } = yield call(getUsersList, payload);
    data.root.sort((a, b) => stringComparator(a.Name, b.Name, true));
    yield put(actions.usersLoginasListSuccess(data.root));
  } catch (error) {
    yield put(
      actions.usersLoginasListFailure({
        error,
        message: formatServerError(error, 'An error occurred while fetching users.'),
      }),
    );
  }
}

function* fetchIndustryList() {
  try {
    const { data } = yield call(getIndustryList);
    yield put(actions.tenantIndustryListSuccess(data.root));
  } catch (error) {
    yield put(
      actions.tenantIndustryListFailure({
        error,
        message: formatServerError(error, 'An error occurred while fetching industries.'),
      }),
    );
  }
}

function* addIndustry({ payload }) {
  try {
    const params = queryString.stringify(formatTenant(payload));
    const { data } = yield call(createIndustry, params);
    yield put(actions.addTenantIndustrySuccess(data));
    yield* fetchIndustryList();
  } catch (error) {
    yield put(
      actions.addTenantIndustryFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Industry.'),
      }),
    );
  }
}

function* deleteIndustry({ payload }) {
  try {
    yield call(removeIndustry, payload);
    yield* fetchIndustryList();
  } catch (error) {
    yield put(
      actions.deleteTenantIndustryFailure({
        error,
        message: formatServerError(error, 'An error occurred while deleting the Industry.'),
      }),
    );
  }
}

function* editIndustry({ payload }) {
  const { IndustryID, Name, cb } = payload;
  try {
    const params = queryString.stringify(formatTenant({ IndustryID, Name }));
    const { data } = yield call(putIndustry, params);
    yield put(actions.editTenantIndustrySuccess(data.entity));
    yield* fetchIndustryList();
    if (cb) {
      cb();
    }
  } catch (error) {
    yield put(
      actions.editTenantIndustryFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Industry.'),
      }),
    );
  }
}

function* fetchTenantRequestsStatistics() {
  try {
    const { data } = yield call(getTenantRequestsStatistics);
    yield put(actions.tenantRequestsStatisticsSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.tenantRequestsStatisticsFailure({
        error,
        message: formatServerError(error, 'An error occurred while getting tenant requests statistics.'),
      }),
    );
  }
}

function* fetchTenantRequestsList({ payload }) {
  try {
    const { data } = yield call(getTenantRequestsList, payload, requestsTypes);
    const results = data.entity.TenantRequestList.Results.map(request => ({
      ...request,
      FullName: `${request.FirstName} ${request.LastName}`,
    }));
    yield put(actions.tenantRequestsListSuccess(results));
    yield* fetchTenantRequestsStatistics();
  } catch (error) {
    yield put(
      actions.tenantRequestsListFailure({
        error,
        message: formatServerError(error, 'An error occurred while getting tenant requests.'),
      }),
    );
  }
}

function* fetchRemoveDemoTenant({ payload }) {
  const { id, requestType } = payload;
  try {
    yield call(deleteTenantRequest, id);
    yield put(actions.removeDemoTenantSuccess());
    yield* fetchTenantRequestsList({ payload: requestType });
  } catch (error) {
    yield put(
      actions.removeDemoTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while removing tenant request.'),
      }),
    );
  }
}

function* fetchApproveDemoTenant({ payload }) {
  const { id, emailText, requestType } = payload;
  const params = `id=${id}&emailText=${emailText}`;
  try {
    yield call(getApproveDemoTenant, params);
    yield put(actions.approveDemoTenantSuccess());
    yield* fetchTenantRequestsList({ payload: requestType });
  } catch (error) {
    yield put(
      actions.approveDemoTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while approving tenant request.'),
      }),
    );
  }
}

function* fetchBlockDemoTenant({ payload }) {
  const { id, notes, requestType } = payload;
  const params = `id=${id}&notes=${notes}`;
  try {
    yield call(getBlockDemoTenant, params);
    yield put(actions.blockDemoTenantSuccess());
    yield* fetchTenantRequestsList({ payload: requestType });
  } catch (error) {
    yield put(
      actions.blockDemoTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while blocking tenant request.'),
      }),
    );
  }
}

function* fetchTiersListByGroupId({ payload }) {
  try {
    const { data } = yield call(getListMasterTiers, payload);
    const { data: limits } = yield call(getMasterSystemLimits);
    const demoTier = {
      Id: EMPTY_GUID,
      Name: 'Demo',
      TenantMaxItemsEach: limits.entity.DemoTenantMaxItemsEach,
      TenantMaxSensorsEach: limits.entity.DemoTenantMaxSensorsEach,
      TenantMaxSitesEach: limits.entity.DemoTenantMaxSitesEach,
      IotCharge: 0,
      TasksCharge: 0,
      TotalCharge: 0,
    };
    const tiers = [demoTier, ...data.root];
    yield put(actions.fetchTiersSuccess(tiers));
  } catch (error) {
    yield put(
      actions.fetchTiersFailure({ message: formatServerError(error, 'An error occurred while getting tiers list.') }),
    );
  }
}

function* fetchCurrentTenantsCount() {
  try {
    const { data } = yield call(getCurrentTenantsCount);
    yield put(actions.getCurrentTenantsCountSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.getCurrentTenantsCountFailure({
        error,
        message: formatServerError(error, 'An error occurred while getting current tenants count.'),
      }),
    );
  }
}

function* fetchRecentUpgradeRequest({ payload }) {
  try {
    const { data } = yield call(getRecentUpgradeRequest, payload);
    yield put(actions.getRecentUpgradeRequestSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.getRecentUpgradeRequestFailure({
        error,
        message: formatServerError(error, 'An error occurred while fetchig an recent upgrade request.'),
      }),
    );
  }
}

function* fetchCurrentTenantPricingValues({ payload }) {
  try {
    const { data } = yield call(getCurrentTenantPricingValues, payload);
    yield put(actions.getTenantPricingValuesSuccess(data.entity.responseDto));
    yield* fetchRecentUpgradeRequest({ payload });
  } catch (error) {
    yield put(
      actions.getTenantPricingValuesFailure({
        error,
        message: formatServerError(error, 'An error occurred while getting current tenants pricing values.'),
      }),
    );
  }
}

function* fetchOverwritePricingValues({ payload }) {
  try {
    yield call(getOverwritePricingValues, payload);
    yield put(actions.overwritePricingValuesSuccess());
    yield* fetchTenantsList();
  } catch (error) {
    yield put(
      actions.overwritePricingValuesFailure({
        error,
        message: formatServerError(error, 'An error occurred while overwriting pricing values.'),
      }),
    );
  }
}

function* fetchUpgradeRequestsList() {
  try {
    const { data } = yield call(getUpgradeRequestsList);
    yield put(actions.getUpgradeRequestsSuccess(data.root));
    yield* fetchTenantRequestsStatistics();
  } catch (error) {
    yield put(
      actions.getUpgradeRequestsFailure({
        error,
        message: formatServerError(error, 'An error occurred while getting upgrade requests.'),
      }),
    );
  }
}

function* fetchEditUpgradeRequest({ payload }) {
  try {
    yield call(editUpgradeRequest, payload);
    yield put(actions.editUpgradeRequestSuccess());
    yield* fetchUpgradeRequestsList();
    yield* fetchTenantsList();
  } catch (error) {
    yield put(
      actions.editUpgradeRequestFailure({
        error,
        message: formatServerError(error, 'An error occurred while editing an upgrade request.'),
      }),
    );
  }
}

function* fetchDeleteUpgradeRequest({ payload }) {
  try {
    yield call(deleteUpgradeRequest, payload);
    yield put(actions.deleteUpgradeRequestSuccess());
    yield* fetchUpgradeRequestsList();
    yield* fetchTenantsList();
  } catch (error) {
    yield put(
      actions.deleteUpgradeRequestFailure({
        error,
        message: formatServerError(error, 'An error occurred while deleting an upgrade request.'),
      }),
    );
  }
}

function* fetchDeleteSentRequest({ payload }) {
  try {
    yield call(deleteSentRequest, payload);
    yield put(actions.deleteSentRequestsSuccess());
    yield* fetchTenantRequestsList({ payload: 'sent' });
  } catch (error) {
    yield put(
      actions.deleteSentRequestsFailure({
        error,
        message: formatServerError(error, 'An error occurred while deleting an sent request.'),
      }),
    );
  }
}

const tenantsSagas = [
  takeEvery(actions.addTenantRequest, addTenant),
  takeEvery(actions.deleteTenantRequest, deleteTenant),
  takeEvery(actions.editTenantRequest, editTenant),
  takeEvery(actions.usersLoginasListRequest, fetchLoginAsUsers),
  takeEvery(actions.tenantsListRequest, fetchTenantsList),
  takeEvery(actions.deactivateTenantRequest, deactivateTenant),
  takeEvery(actions.activateTenantRequest, activateTenant),
  takeEvery(actions.tenantIndustryListRequest, fetchIndustryList),
  takeEvery(actions.addTenantIndustryRequest, addIndustry),
  takeEvery(actions.deleteTenantIndustryRequest, deleteIndustry),
  takeEvery(actions.editTenantIndustryRequest, editIndustry),
  takeEvery(actions.tenantRequestsStatisticsRequest, fetchTenantRequestsStatistics),
  takeEvery(actions.tenantRequestsListRequest, fetchTenantRequestsList),
  takeEvery(actions.removeDemoTenantRequest, fetchRemoveDemoTenant),
  takeEvery(actions.approveDemoTenantRequest, fetchApproveDemoTenant),
  takeEvery(actions.blockDemoTenantRequest, fetchBlockDemoTenant),
  takeEvery(actions.fetchTiersRequest, fetchTiersListByGroupId),
  takeEvery(actions.getCurrentTenantsCountRequest, fetchCurrentTenantsCount),
  takeEvery(actions.getTenantPricingValuesRequest, fetchCurrentTenantPricingValues),
  takeEvery(actions.overwritePricingValuesRequest, fetchOverwritePricingValues),
  takeEvery(actions.getUpgradeRequestsRequest, fetchUpgradeRequestsList),
  takeEvery(actions.editUpgradeRequestRequest, fetchEditUpgradeRequest),
  takeEvery(actions.deleteUpgradeRequestRequest, fetchDeleteUpgradeRequest),
  takeEvery(actions.getRecentUpgradeRequestRequest, fetchRecentUpgradeRequest),
  takeEvery(actions.deleteSentRequestsRequest, fetchDeleteSentRequest),
];

export default tenantsSagas;
