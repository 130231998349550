import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Item, PartsItem, PartsValue } from '../styled';

import * as S from './styled';

const EmailDialog = ({
  OriginatingTaskName: originatingTaskName,
  selectedAssignee,
  selectedManager,
  SiteName: siteName,
  UnitName: unitName,
  ItemName: itemName,
  taskParts,
  PartResolution: partResolution,
  Exceptions: exceptions,
  message,
  handleClose,
  handleSend,
  RuleType: ruleType,
}) => (
  <>
    <S.Title>The following information will be sent to the selected people</S.Title>
    <S.Content>
      <S.TaskInfo>
        <li>Task: {originatingTaskName || ruleType}</li>
        <li>Responsible: {selectedAssignee?.name}</li>
        <li>Manager: {selectedManager?.name}</li>
      </S.TaskInfo>
      <S.LocationInfo>
        <li>
          <Item>Site:</Item>
          {siteName}
        </li>
        <li>
          <Item>Unit:</Item>
          {unitName}
        </li>
        <li>
          <Item>Item:</Item>
          {itemName}
        </li>
      </S.LocationInfo>
      <S.Parts>
        <PartsItem>Part ID</PartsItem>
        <PartsItem>Part Name</PartsItem>
        {taskParts.map(({ myPartId, myPartName }, index) => (
          <Fragment key={`${myPartId}-${index}`}>
            <PartsItem>
              <PartsValue>{myPartId}</PartsValue>
            </PartsItem>
            <PartsItem>
              <PartsValue>{myPartName}</PartsValue>
            </PartsItem>
          </Fragment>
        ))}
      </S.Parts>
      <S.TextSection>
        <S.TextTitle>Part Issue</S.TextTitle>
        <p>{partResolution}</p>
      </S.TextSection>
      <S.TextSection>
        <S.TextTitle>Attention Issue</S.TextTitle>
        <p>{exceptions}</p>
      </S.TextSection>
      <S.TextSection>
        <S.TextTitle>Message</S.TextTitle>
        <p>{message}</p>
      </S.TextSection>
      <S.TextSection>
        <S.TextTitle>Note</S.TextTitle>
        <p>
          This is an automated message - do not reply
          <br />
          Please contact the appropriate person for further information to resolution
        </p>
      </S.TextSection>
    </S.Content>
    <S.Buttons>
      <S.CancelButton style={{ width: '50%' }} onClick={handleClose}>
        Cancel
      </S.CancelButton>
      <S.RedButton style={{ width: '50%' }} onClick={handleSend}>
        Send
      </S.RedButton>
    </S.Buttons>
  </>
);

EmailDialog.defaultProps = {
  OriginatingTaskName: '',
  SiteName: '',
  ItemName: '',
  UnitName: '',
  taskParts: [],
  PartResolution: '',
  Exceptions: '',
  message: '',
};

EmailDialog.propTypes = {
  OriginatingTaskName: PropTypes.string,
  selectedAssignee: PropTypes.shape().isRequired,
  selectedManager: PropTypes.shape().isRequired,
  UnitName: PropTypes.string,
  SiteName: PropTypes.string,
  ItemName: PropTypes.string,
  taskParts: PropTypes.array,
  PartResolution: PropTypes.string,
  Exceptions: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
  RuleType: PropTypes.isRequired,
};

export default EmailDialog;
