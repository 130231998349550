import http from '../index';

export const setSpecialist = (taskId, currentUser) =>
  http.post('AssignedTaskActions/SetSpecialist', `userId=${currentUser.userId}&id=${taskId}`);

export const getTaskDetails = id => http.post('AssignedTask/Get', { id });

export const getConfirmationPhotoList = dto => http.post('ConfirmationPhoto/List', `parentId=${dto}`);

export const getItemPictureList = dto => http.post('ItemPicture/List', `parentId=${dto}`);

export const getTasks = filter => http.post('AssignedTask/List', filter);

export const getUserProfile = userId => http.post('UserProfile/Get', `userId=${userId}`);

export const getUsersList = siteId => http.post(`AssignedTask/GetSiteStatistics?siteId=${siteId}`);

export const setCommentsOnTask = (parentId, comments) =>
  http.post(`AssignedTaskActions/SetCommentsOnTask?id=${parentId}&comments=${comments}`);

export const saveTaskRequest = (url, params) => http.post(url, params);

export const postPhoto = dto => http.post('ConfirmationPhoto/Post', dto);

export const deletePhoto = ({ parentId, id }) => http.post(`ConfirmationPhoto/Delete?parentId=${parentId}&id=${id}`);

export const verifyScanCodeTask = (parentId, formData) => http.post('AssignedTaskActions/VerifyScanCodeTask', formData);

export const sendEmailAboutTask = params => http.post('AssignedTask/SendEmailAboutTask', params);

export const releaseTasks = batchTaskAssignments =>
  http.post('AssignedTaskActions/BatchSetSpecialists', { batchTaskAssignments });

export const fetchFilteredTaskCount = filter => http.post('AssignedTask/GetFilteredTaskCount', { filter });

export const changeReportPhoto = dto => http.post('AssignedTaskActions/EditReportPhoto', { dto });

export const uploadReportPhoto = dto => http.post('AssignedTaskActions/UploadReportPhoto', dto);

export const fetchReportPhoto = fileId =>
  http.post(
    'AssignedTaskActions/GetReportPhoto',
    { fileId },
    {
      responseType: 'arraybuffer',
    },
  );

export const deleteReportPhoto = reportPhotoId => http.post('AssignedTaskActions/DeleteReportPhoto', { reportPhotoId });

export const getReportPhotosList = assignedTaskId =>
  http.post('AssignedTaskActions/ListReportPhotos', { assignedTaskId });
