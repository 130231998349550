import http from '../index';

export const fetchItemsWithAlert = dto => http.post('SiteManagement/ListWithItemStatusAlert', { isFavorite: dto });

export const fetchFavoriteSites = () => http.post('SiteUserManagement/ListIsFavorite');

export const setSiteIsFavorite = ({ siteId, isFavorite }) =>
  http.post('SiteUserManagement/SetSiteIsFavorite', { siteId, isFavorite });

export const getMasterSensorRecordList = id => http.post('MasterSensorRecord/List', { masterSensorID: id });

export const getWithInternalObjects = id => http.post('MasterSensor/GetWithInternalObjects', { id });
