import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import moment from 'moment';

import { TASK_RULE_TYPES } from 'types/task';

import { TaskNotesPhotos } from 'configs/propTypes';
import { ButtonIcon } from 'components/Layout/Buttons';
import { CameraIcon } from 'components/Layout/Icons';
import withInputModal from 'components/Dialogs/withInputModal';
import { FieldRadio } from 'components/Layout/Inputs';
import { RadioGroupContainer } from 'components/Layout/Containers';
import TaskPhotoDialog from '../TaskPhotoDialog';

import * as S from './styled';

const params = {
  grabCursor: true,
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets',
  },
  rebuildOnUpdate: true,
  shouldSwiperUpdate: true,
};

const Info = ({ min, max, actualValue }) => (
  <div>
    <S.BorderRadiusBlock>
      <S.GrayTitle>Min</S.GrayTitle>
      <S.TextValue>{min}</S.TextValue>
      <S.Overlay />
    </S.BorderRadiusBlock>
    <S.BorderRadiusBlock>
      <S.GrayTitle>Max</S.GrayTitle>
      <S.TextValue>{max}</S.TextValue>
      <S.Overlay />
    </S.BorderRadiusBlock>
    <S.BorderRadiusBlock>
      <S.ColoredTitle>Actual reading</S.ColoredTitle>
      <S.TextValue>{actualValue}</S.TextValue>
    </S.BorderRadiusBlock>
  </div>
);

const TaskInfoForm = ({ item, notesPhotos, siteId, openPhotoDialog }) => {
  const renderTaskRuleType = () => {
    const {
      FeedbackQuestion,
      FeedbackResponse,
      MinTemperature,
      MaxTemperature,
      ActualTemperature,
      VerificationSpecification,
      VerificationResponse,
      MinFlow,
      MaxFlow,
      ActualFlow,
      MinQty,
      MaxQty,
      ActualQty,
      MinPhotos,
      MaxPhotos,
      CurrentPhotos,
      ExpectedScanCode,
    } = item.RuleData;

    switch (item.RuleType) {
      case TASK_RULE_TYPES.FEEDBACK:
        return (
          <S.BorderRadiusBlock>
            <S.ColoredTitle>{FeedbackQuestion}</S.ColoredTitle>
            <S.GrayTitle>Response</S.GrayTitle>
            <S.TextValue>{FeedbackResponse}</S.TextValue>
          </S.BorderRadiusBlock>
        );
      case TASK_RULE_TYPES.CHECK_TEMPERATURE:
        return <Info min={MinTemperature} max={MaxTemperature} actualValue={ActualTemperature} />;
      case TASK_RULE_TYPES.VERIFICATION:
        return (
          <S.BorderRadiusBlock>
            <S.ColoredTitleNoCase>{VerificationSpecification}</S.ColoredTitleNoCase>
            <RadioGroupContainer valueSelected={+VerificationResponse} name="verification">
              <FieldRadio value={1} label="Yes" disabled />
              <FieldRadio value={0} label="No" disabled />
            </RadioGroupContainer>
          </S.BorderRadiusBlock>
        );
      case TASK_RULE_TYPES.CHECK_FLOW_RATE:
        return <Info min={MinFlow} max={MaxFlow} actualValue={ActualFlow} />;
      case TASK_RULE_TYPES.CHECK_QUANTITY:
        return <Info min={MinQty} max={MaxQty} actualValue={ActualQty} />;
      case TASK_RULE_TYPES.PHOTO_CONFIRMATION:
        return <Info min={MinPhotos} max={MaxPhotos} actualValue={CurrentPhotos} />;
      case TASK_RULE_TYPES.SCAN_CODE:
        return (
          <div>
            <S.BorderRadiusBlock>
              <S.ColoredTitle>Scan Code</S.ColoredTitle>
              <S.TextValue>{ExpectedScanCode}</S.TextValue>
              <S.Overlay />
            </S.BorderRadiusBlock>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <S.Block>
      <S.Content>
        <S.DateContainer>
          <S.DateBlock>
            <S.Block>
              <S.BlockTitle>Date Scheduled</S.BlockTitle>
              <div>{moment(item.CreatedOn).format('MM/DD/YYYY')}</div>
            </S.Block>
          </S.DateBlock>
          <S.DateBlock>
            <S.TaskNotes>
              <S.BlockTitle>Date Completed</S.BlockTitle>
              <div>{moment(item.CompletedOnWithTime).format('MM/DD/YYYY LT')}</div>
            </S.TaskNotes>
          </S.DateBlock>
          <S.DateBlock>
            <S.BlockImage>
              <ButtonIcon tooltip="View photos" onClick={() => openPhotoDialog(item)}>
                <CameraIcon />
              </ButtonIcon>
            </S.BlockImage>
          </S.DateBlock>
        </S.DateContainer>
        <S.TaskDescription>
          <S.BlockTitle>{item.OriginatingTaskName || item.RuleType}</S.BlockTitle>
          <S.Breadcrumbs>
            {item.UnitName} {' > '} {item.ItemName} {' > '} {item.OriginatingChecklistName}
          </S.Breadcrumbs>
        </S.TaskDescription>
        <S.TaskNotes>
          <S.BlockTitle>Task notes</S.BlockTitle>
          <div>{item.InstructionalNotes}</div>
        </S.TaskNotes>

        {item.RuleType && (
          <div>
            {renderTaskRuleType()}
            <S.BorderRadiusBlock>
              <S.ColoredTitle>User notes</S.ColoredTitle>
              <S.TextValue>{item.UserComments || 'There is no comment'}</S.TextValue>
            </S.BorderRadiusBlock>
            <S.PhotoContent>
              {notesPhotos && notesPhotos.length ? (
                <Swiper {...params}>
                  {notesPhotos.map((photo, index) => (
                    <S.ImageContainer key={index}>
                      <img alt={photo.Name} src={`${photo.src}?parentId=${item.Id}&inline=true&siteId=${siteId}`} />
                    </S.ImageContainer>
                  ))}
                </Swiper>
              ) : null}
            </S.PhotoContent>
          </div>
        )}
      </S.Content>
    </S.Block>
  );
};

const TaskInfoWithInput = withInputModal(TaskInfoForm);

TaskInfoForm.defaultProps = {
  item: {},
};

TaskInfoForm.propTypes = {
  item: PropTypes.object,
  notesPhotos: TaskNotesPhotos.isRequired,
  siteId: PropTypes.string.isRequired,
  openPhotoDialog: PropTypes.func.isRequired,
};

const TaskInfoDialog = ({
  item,
  notesPhotos,
  siteId,
  openDialog,
  openPhotoDialog,
  closePhotoDialog,
  photos,
  ...rest
}) => [
  <TaskInfoWithInput
    key="TaskInfoWithInput"
    item={item}
    notesPhotos={notesPhotos}
    siteId={siteId}
    openPhotoDialog={openPhotoDialog}
    {...rest}
  />,
  <TaskPhotoDialog
    key="Task photo dialog"
    // title="User Photos"
    item={item}
    open={openDialog}
    closePhotoDialog={closePhotoDialog}
    photos={photos}
    siteId={siteId}
  />,
];

TaskInfoDialog.defaultProps = {
  item: {},
  withSubmit: false,
};

TaskInfoDialog.propTypes = {
  item: PropTypes.object,
  notesPhotos: TaskNotesPhotos.isRequired,
  siteId: PropTypes.string.isRequired,
};

Info.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  actualValue: PropTypes.number.isRequired,
};

export default TaskInfoDialog;
