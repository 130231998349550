import styled from 'styled-components';

const ConfirmationContainer = styled.div`
  & > div:last-child {
    padding: 0px !important;
    margin-top: 20px;
    & > button:first-child {
      margin-right: 5px !important;
    }

    & > button:last-child {
      margin-left: 5px !important;
    }
  }
`;

const ConfirmationBoldText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 600;
`;

const ConfirmationWarningText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

const ConfirmationWarning = styled.p`
  font-size: 18px;
`;

const ConfirmationText = styled.p`
  font-size: 16px;
  margin: 0px;
`;

export { ConfirmationContainer, ConfirmationBoldText, ConfirmationText, ConfirmationWarningText, ConfirmationWarning };
