import styled, { css } from 'styled-components';

import theme from 'theme';

export const searchInputStyles = css`
  border: 1px solid ${theme.primaryHome};
  backgroundColor: ${theme.lightGrey},
  border-radius: 20px;
  height: 25px;
  padding: 0 12px;
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const SearchContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${() => `1px solid ${theme.darkGrey}`};
`;

export const List = styled.ul`
  overflow-y: scroll;
  height: 420px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Item = styled.li`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border-bottom: ${() => `1px solid ${theme.darkGrey}`};

  & input:disabled {
    color: ${() => theme.primaryBlack} !important;
  }
`;

export const ItemControls = styled.div`
  display: flex;
  align-items: center;
`;

export const NewItemContainer = styled.div`
  height: 80px;
  padding: 0 50px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${() => theme.lightGrey};
`;

export const EmptyListContainer = styled.div`
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
