import {
  InventoryEntityType,
  InventoryPartType,
  TenantInventoryType,
  PartGroupsParamsType,
  PartGroupsType,
  PartParamsType,
  PartTransactionPayload,
} from 'types/inventory';
import http from '../index';

export const setTenantInventory = (dtos: TenantInventoryType[]) =>
  http.post('MasterInventory/SetTenantInventory', { dtos });

export const fetchInventoryListPeriods = () => http.post('Inventory/ListPeriods');

export const addInventoryPeriod = () => http.post('Inventory/CreatePeriod');

export const fetchInventoryListParts = (inventoryPeriodId: string) =>
  http.post('Inventory/ListParts', { inventoryPeriodId });

export const fetchInventoryListPartGroups = (inventoryPeriodId: string) =>
  http.post('Inventory/ListPartGroups', { inventoryPeriodId });

export const addInventoryEntities = (dto: InventoryEntityType) => http.post('Inventory/AddInventoryEntities', dto);

export const deleteInventoryEntity = (dto: InventoryEntityType) => http.post('Inventory/DeleteInventoryEntity', dto);

export const changeEntityProfile = () => http.post('Inventory/EditEntityProfile');

export const getEntityQrCode = (dto: Record<'entityId' | 'entityType', string>) =>
  `${http.defaults.baseURL}/Inventory/GetEntityQrCode` +
  `?entityId=${dto.entityId}&entityType=${dto.entityType}&inline=true`; // "Part" | "PartGroup"

export const getPartTransactionsList = (dto: Record<'inventoryEntityId' | 'entityType', string>) =>
  http.post('Inventory/ListPartTransactions', dto);

export const createPartTransaction = (dto: PartTransactionPayload) => http.post('Inventory/CreatePartTransaction', dto);

export const getPartsByParam = (dto: PartParamsType) => http.post('Inventory/GetPartsByParam', dto);

export const getPartGroupsByParam = (dto: PartGroupsParamsType) => http.post('Inventory/GetPartGroupsByParam', dto);

export const editInventoryPart = (dto: InventoryPartType) => http.post('Inventory/EditInventoryPart', { dto });

export const editInventoryPartGroup = (dto: PartGroupsType) => http.post('Inventory/EditInventoryPartGroup', { dto });

export const getInventoryDashboardInfo = () => http.post('Inventory/Dashboard');
