import { createSelector } from 'reselect';

const filterPeopleByRole = (list, role) =>
  list.filter(({ UserType, Status }) => UserType === role && Status === 'Active');

export const getPeople = createSelector([filterPeopleByRole], people =>
  people.length > 0
    ? people.map(({ Id, Name, SendDailyMail, SendIotMail, SendIotTxt, SendIotPush }) => ({
        UserId: Id,
        Name,
        Notification: {
          SendDailyMail,
          SendIotMail,
          SendIotTxt,
          SendIotPush,
        },
      }))
    : [],
);

export const blank = () => {};
