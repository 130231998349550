import React, { FC, ReactNode } from 'react';
import * as S from './AdditionalInfoStyles';

type PropsType = {
  isShown: boolean;
  name: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  content: ReactNode;
};

export const AdditionalInfo: FC<PropsType> = ({ isShown, rightIcon, leftIcon, name, content }) => (
  <S.Container>
    <S.Label>
      {leftIcon && <div>{leftIcon}</div>}
      <S.Name>{name}</S.Name>
      {rightIcon}
    </S.Label>

    <S.Section>{isShown && content}</S.Section>
  </S.Container>
);

AdditionalInfo.defaultProps = {
  rightIcon: null,
  leftIcon: null,
};
