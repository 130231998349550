import React from 'react';

import * as S from './styled';

export const ConfirmationDeleteDialogContent = () => (
  <S.ConfirmationText>
    Aborting the process will delete this shortcut and cannot be undone!
    <br />
    Are you sure?
  </S.ConfirmationText>
);
