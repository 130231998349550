import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Item = ({ LevelsCount, Notes, Type }) => (
  <S.Gateway>
    <S.Type>Template: {Type}</S.Type>
    <S.Notes>Notes: {Notes}</S.Notes>
    <S.Levels>
      <S.LevelsTitle>Levels:</S.LevelsTitle>
      <S.LevelsCount>{LevelsCount}</S.LevelsCount>
    </S.Levels>
  </S.Gateway>
);

Item.propTypes = {
  LevelsCount: PropTypes.number.isRequired,
  Notes: PropTypes.string.isRequired,
  Type: PropTypes.string.isRequired,
};

export default Item;
