import styled from 'styled-components';
import { ForwardIcon } from 'components/Layout/Icons';

export const TableContainer = styled.div`
  & td:nth-child(-n + 1) {
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
  }

  & td:nth-child(1) {
    padding-left: 10px;
    background-color: ${({ theme }) => theme.archiveNameColor};
  }

  & td:nth-child(2) {
    width: 40%;
  }
`;

export const IconForward = styled(ForwardIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;
