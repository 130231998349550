import React from 'react';
import { useSelector } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import moment from 'moment';
import theme from 'theme';
import { Modal } from 'components/Modal';
import { ActionButton } from 'components/Dialogs/v1/Base';
import { findSite } from 'helpers';

import * as S from './styled';

export const SummaryInfo = NiceModal.create(({ item, date }) => {
  const modal = useModal();
  const sitesAvailableToUserFullInfo = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const siteName = findSite(sitesAvailableToUserFullInfo, selectedSite);
  const { AssigneeName, TasksTotal, TasksComplete, TaskCompletePercent } = item;

  const renderInfo = () => {
    switch (AssigneeName) {
      case 'All Tasks':
        return (
          <>
            All tasks, completed or open for this location and <br /> timeframe
          </>
        );
      case 'Quick Tasks':
        return (
          <>
            QuickTasks are tasks users have scheduled for <br /> themselves by scanning a QR code.
          </>
        );

      case 'Pick-Up':
        return (
          <>
            Pickup Tasks are Tasks made available to all TaskApp <br /> users at the current location
          </>
        );

      case 'Team':
        return (
          <>
            Team tasks are tasks assigned to a group of people <br />
            at the current location and becomes assigned to
            <br /> the person who completes them.
            <br />
            (Completed tasks cannot be re-assigned)
          </>
        );

      default:
        return (
          <>
            User tasks are tasks assigned to or completed by a <br /> specific person at the current location. <br />
            (Completed tasks cannot be re-assigned)
          </>
        );
    }
  };

  return (
    <Modal isOpen={modal.visible} onRequestClose={modal.hide} title={'Summary Info'} titleColor={theme.primaryDark}>
      <S.RootContainer>
        <h3>{moment(date).format('dddd | MMMM D, YYYY')}</h3>
        <p>{siteName}</p>

        <p>{AssigneeName === 'Pick-Up' ? 'Pickup Tasks' : AssigneeName}</p>
        <p>{`${TasksComplete} of ${TasksTotal} tasks finished (${TaskCompletePercent}% completed)`}</p>
        <p>{renderInfo()}</p>

        <ActionButton type="button" label="OK" isNext onClick={modal.hide} />
      </S.RootContainer>
    </Modal>
  );
});
