import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { FieldRadio } from 'components/Layout/Inputs';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import SelectField from 'components/Forms/SelectFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';

import theme from 'theme';

import {
  matchOperator,
  constraints,
  constraintsSelect,
  constraintsQR,
  optionNames,
  ignoreRulesBySection,
  redHeader,
  optionArray,
  numberArray,
  constraintsNumber,
  dateTimeArray,
} from './constants';
import * as S from './styled';

const changeOptionNameControl = (values, rule) => {
  values[`optionValue-${rule.id}`] = '';
  values[`constraint-${rule.id}`] = '';
  return values;
};

const defineConstraints = optionName => {
  if (optionName === 'UnitQR') {
    return constraintsQR;
  } else if (optionArray.includes(optionName)) {
    return constraintsSelect;
  } else if (numberArray.includes(optionName)) {
    return constraintsNumber;
  }
  return constraints;
};

const checkHintText = value => {
  if (value.length > 12) {
    return `${value.substring(0, 9)}...`;
  }
  return value;
};

const ReportRules = ({
  title,
  rules,
  addOption,
  removeOption,
  onKeyDownHandler,
  onChangeHandler,
  onChangeIgnoreRules,
  isInitialValues,
  isEditMode,
  initialValue,
  values,
  getOptionValue,
}) => {
  const renderRuleValue = rule => {
    if (optionArray.includes(values[`optionName-${rule.id}`])) {
      return (
        <Field
          name={`optionValue-${rule.id}`}
          component={SelectField}
          hintText={checkHintText(getOptionValue(values[`optionName-${rule.id}`])?.[0]?.Name)}
          initialValue={isInitialValues ? rule[`optionValue-${rule.id}`] : initialValue.is}
          style={{ width: '148px' }}
          autoWidth
        >
          {getOptionValue(values[`optionName-${rule.id}`]).map(({ Id, Value, Name }) => (
            <SelectItem key={Id || Value} value={Id || Value} primaryText={Name} />
          ))}
        </Field>
      );
    } else if (dateTimeArray.includes(values[`optionName-${rule.id}`])) {
      return (
        <Field
          name={`optionValue-${rule.id}`}
          initialValue={isInitialValues ? new Date(rule[`optionValue-${rule.id}`]) : null}
        >
          {({ input, meta }) => (
            <DatePickerAdapter meta={meta} input={{ ...input, value: new Date(values[`optionValue-${rule.id}`]) }} />
          )}
        </Field>
      );
    }

    return (
      <Field
        name={`optionValue-${rule.id}`}
        component={TextFieldAdapter}
        hintText="Enter Parameter"
        type={numberArray.includes(values[`optionName-${rule.id}`]) ? 'number' : 'text'}
        initialValue={isInitialValues ? rule[`optionValue-${rule.id}`] : initialValue.is}
        onKeyDown={e => onKeyDownHandler(e)}
      />
    );
  };

  return (
    <S.Section>
      <S.SectionHeader redColor={redHeader.includes(rules.name)}>{title}</S.SectionHeader>
      <S.SectionBody>
        <Field
          name={`${rules.name}-${ignoreRulesBySection[rules.name].name}`}
          label={ignoreRulesBySection[rules.name].label}
          component={S.Checkbox}
          onChangeControl={onChangeIgnoreRules(rules.name)}
          defaultValue={rules.isRulesIgnored}
        />
        {!rules.isRulesIgnored && (
          <>
            <S.MatchSection>
              <span>Match</span>
              <Field name={`match-${rules.name}`} defaultValue={rules.match?.toString()}>
                {({ input }) => (
                  <S.RadioGroup
                    name={input.name}
                    defaultSelected={rules.match?.toString()}
                    onChange={(e, value) => onChangeHandler(e, value, rules.name, input)}
                    selectedValue={rules.match?.toString()}
                  >
                    {Object.keys(matchOperator).map(key => (
                      <FieldRadio
                        key={key}
                        value={key}
                        label={matchOperator[key]}
                        style={{ width: 'auto', marginRight: '15px', flex: key }}
                        iconStyle={{ fill: input.value === key && theme.primaryScheduler }}
                      />
                    ))}
                  </S.RadioGroup>
                )}
              </Field>
            </S.MatchSection>
            {rules.rules.map(rule => (
              <S.RuleContainer key={`${rule.name}${rule.id}`}>
                <Field
                  name={`optionName-${rule.id}`}
                  component={SelectField}
                  autoWidth
                  onChangeControl={() => changeOptionNameControl(values, rule)}
                  hintText={optionNames[rule.name][0].name}
                  initialValue={isInitialValues ? rule[`optionName-${rule.id}`] : initialValue.is}
                >
                  {optionNames[rule.name].map(({ name, value }) => (
                    <SelectItem key={name} value={value} primaryText={name} />
                  ))}
                </Field>
                <Field
                  name={`constraint-${rule.id}`}
                  component={SelectField}
                  hintText={constraints[0]}
                  disabled={rules.isRulesIgnored || isEditMode}
                  initialValue={
                    isInitialValues && rule.optionConstraint !== 0
                      ? `${rule[`constraint-${rule.id}`]}`
                      : initialValue.is
                  }
                >
                  {Object.keys(defineConstraints(values[`optionName-${rule.id}`])).map(key => (
                    <SelectItem
                      key={defineConstraints(values[`optionName-${rule.id}`])[key]}
                      value={key}
                      primaryText={defineConstraints(values[`optionName-${rule.id}`])[key]}
                    />
                  ))}
                </Field>
                {renderRuleValue(rule)}
                {!rules.isRulesIgnored && (
                  <S.RuleButtonsGroup>
                    {rules.rules.length > 1 ? (
                      <S.IconButton onClick={() => removeOption(rule.name, rule.id)}>
                        <S.RemoveIcon />
                      </S.IconButton>
                    ) : null}
                    <S.IconButton onClick={() => addOption(rule.name)}>
                      <S.AddIcon />
                    </S.IconButton>
                  </S.RuleButtonsGroup>
                )}
              </S.RuleContainer>
            ))}
          </>
        )}
      </S.SectionBody>
    </S.Section>
  );
};

ReportRules.propTypes = {
  title: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    name: PropTypes.string,
    match: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isRulesIgnored: PropTypes.bool.isRequired,
    getOptionValue: PropTypes.func.isRequired,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        optionName: PropTypes.string,
        optionConstraint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        optionValue: PropTypes.string,
        matchOperator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }).isRequired,
  isInitialValues: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  addOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  onKeyDownHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onChangeIgnoreRules: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    is: PropTypes.string,
  }),
  values: PropTypes.shape().isRequired,
  getOptionValue: PropTypes.func.isRequired,
};

ReportRules.defaultProps = {
  initialValue: {
    is: '',
  },
};

export default ReportRules;
