import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import BaseDialog from 'components/Dialogs/Base';
import SearchField from 'components/Layout/Search';

import theme from 'theme';

import { actions as tenantsActions } from 'redux/tenants';
import { actions as sitesActions } from 'redux/sites';
import { actions as gatewaysActions } from 'redux/gateways';

import { ValueLabelType } from 'configs/propTypes';
import { resourceIcons } from 'configs/enums';

import { getListByMode, itemLabels, selectedItem, modalTitles } from '../helpers';
import * as CS from '../styled';

import RadioButtonLabel from './RadioButtonLabel';
import * as S from './styled';

class ListDialog extends Component {
  static propTypes = {
    action: PropTypes.shape({
      getNewResourceGroupNameName: PropTypes.func.isRequired,
      getTenantsList: PropTypes.func.isRequired,
      getSitesList: PropTypes.func.isRequired,
    }).isRequired,
    handleClose: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    onStateChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        ...ValueLabelType,
        gatewaysNumber: PropTypes.number,
      }),
    ).isRequired,
    type: PropTypes.oneOf(['setup', 'add', 'remove', '']),
    mode: PropTypes.oneOf(['tenant', 'site', 'hub', 'gateway']).isRequired,
    data: PropTypes.shape({
      tenant: PropTypes.string,
      site: PropTypes.string,
      hub: PropTypes.string,
      gateway: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: false,
    type: '',
  };

  state = {
    list: this.props.list,
    listForSearch: [],
    searchData: '',
  };

  componentDidMount() {
    this.props.action.getTenantsList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchData } = this.state;
    const { list, mode } = this.props;

    if (
      (prevState.list.length === 0 && searchData.length === 0 && list.length > 0) ||
      prevProps.mode !== mode ||
      !isEqual(prevProps.list, list)
    ) {
      this.setState({ list, listForSearch: list, searchData: '' });
    }
  }

  onSearch = (list, searchData) => this.setState({ list, searchData });

  handleRadioButtonChange = (e, value) => {
    const { mode, onStateChange } = this.props;

    const item = this.state.list.find(({ value: itemId }) => itemId === value);
    const selectedValue = mode === 'tenant' || mode === 'site' ? item : value;

    onStateChange([selectedItem[mode]], selectedValue);
  };

  closeModal = () => this.setState({ searchData: '', list: [], listForSearch: [] }, () => this.props.handleClose());

  render() {
    const { open, type, isLoading, handleNext, mode, data } = this.props;
    const { searchData, list, listForSearch } = this.state;

    return (
      <BaseDialog open={open} onRequestClose={this.closeModal} title={modalTitles[type]} titleColor={theme.darkGrey}>
        <CS.DialogContainer>
          {isLoading ? (
            <S.Loader />
          ) : (
            <>
              {(mode === 'tenant' || mode === 'site') && (
                <CS.NotificationText>
                  {`What ${mode} is this new Gateway ${type === 'remove' ? 'removal' : 'setup'} for ?`}
                </CS.NotificationText>
              )}
              {mode === 'hub' && (
                <CS.NotificationText>
                  {`What Hub should this Gateway be ${type === 'remove' ? 'removed from' : 'added to'}`}
                </CS.NotificationText>
              )}
              {mode === 'gateway' && <CS.NotificationText>Which Gateway should be removed?</CS.NotificationText>}
              {mode !== 'tenant' && <CS.CurrentTenant>{`Tenant: ${data.tenant}`}</CS.CurrentTenant>}
              {(mode === 'hub' || mode === 'gateway') && <CS.CurrentTenant>{`Site: ${data.site}`}</CS.CurrentTenant>}
              {mode === 'gateway' && <CS.CurrentTenant>{`Hub: ${data.hub}`}</CS.CurrentTenant>}
              <CS.CardContainer>
                <CS.CardHeader>
                  <CS.CardHeaderTitle>{`${mode}s`}</CS.CardHeaderTitle>
                  <CS.CardHeaderIconContainer>{resourceIcons[mode]}</CS.CardHeaderIconContainer>
                </CS.CardHeader>
                <CS.CardContentContainer>
                  <S.ContentHeaderContainer>
                    <SearchField
                      list={listForSearch}
                      filterBy="label"
                      searchData={searchData}
                      placeholder={`Filter ${mode}s`}
                      setSearchedItems={this.onSearch}
                    />
                    <S.ItemLabelContainer>
                      <S.ItemLabel>{itemLabels[mode].label}</S.ItemLabel>
                      {itemLabels[mode].additionalInfo.length > 0 && (
                        <S.ItemLabel>{itemLabels[mode].additionalInfo}</S.ItemLabel>
                      )}
                    </S.ItemLabelContainer>
                  </S.ContentHeaderContainer>
                  {list.length > 0 ? (
                    <S.FieldRadioGroup name={`${mode}s`} onChange={this.handleRadioButtonChange}>
                      {list.map(({ value, label, additionalInfo }) => (
                        <S.FieldRadio
                          key={value}
                          value={value}
                          label={
                            mode === 'tenant' || mode === 'site' ? (
                              label
                            ) : (
                              <RadioButtonLabel label={label} additionalInfo={`${additionalInfo}`} />
                            )
                          }
                          labelStyle={S.radioButtonStyles.labelStyle}
                          iconStyle={S.radioButtonStyles.radioButton}
                          style={S.radioButtonStyles.style}
                        />
                      ))}
                    </S.FieldRadioGroup>
                  ) : (
                    <S.EmptyListContainer>There are no {mode}s!</S.EmptyListContainer>
                  )}
                </CS.CardContentContainer>
              </CS.CardContainer>
            </>
          )}
        </CS.DialogContainer>
        <CS.NextButton onClick={handleNext} isNext label="Save / Next" disabled={data[mode].length === 0} />
      </BaseDialog>
    );
  }
}

const mapStateToProps = ({ tenants, sites, gateways }, { mode }) => ({
  isLoading: sites.isLoading,
  list: getListByMode({
    mode,
    site: sites.list,
    tenant: tenants.list,
    hub: gateways.tenantHubsList,
    gateway: gateways.list,
  }),
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      getNewResourceGroupNameName: gatewaysActions.getNewResourceGroupNameByTenantRequest,
      getSitesList: sitesActions.sitesListRequest,
      getTenantsList: tenantsActions.tenantsListRequest,
    },
    dispatch,
  ),
});

const List = connect(mapStateToProps, mapDispatchToProps)(ListDialog);

export { List };
