import { call, put, select, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import { EMPTY_GUID, formatServerError, parseServerError } from 'helpers';

import { getSelectedSite } from 'helpers/storage';
import { ERROR_DIALOG_OPENED, openErrorDialog } from 'redux/errorHandler';
import { getCurrentUser } from 'redux/auth/selectors';
import { taskToggle } from 'configs/toggles';
import {
  getConfirmationPhotoList,
  getItemPictureList,
  getTaskDetails,
  getTasks,
  getUserProfile,
  getUsersList,
  postPhoto,
  deletePhoto,
  saveTaskRequest,
  sendEmailAboutTask,
  setCommentsOnTask,
  setSpecialist,
  verifyScanCodeTask,
  releaseTasks,
  fetchFilteredTaskCount,
  uploadReportPhoto,
  changeReportPhoto,
  getReportPhotosList,
  fetchReportPhoto,
  deleteReportPhoto,
} from 'http/tasks';
import http from '../../http';

import { divideByDate, divideByUnitName, findNextTaskId, prepareSaveTaskUrl, prepareSaveTaskParams } from './helpers';
import { getRangeStart, getTasksForSelectedPeriod, getUsersSelector } from './selectors';

import { actions } from './index';

function* assignTask({ payload: { taskId, goToNext, goBack } }) {
  try {
    const currentUser = yield select(getCurrentUser);
    yield call(setSpecialist, taskId, currentUser);
    const tasks = yield select(getTasksForSelectedPeriod);
    const nextId = findNextTaskId(tasks, taskId);
    yield put(actions.assignTaskSuccess());
    if (nextId) {
      goToNext(nextId);
    } else {
      goBack();
    }
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: error });
    yield put(actions.assignTaskFailure(error));
  }
}

function* fetchTaskDetails({ payload: { taskId } }) {
  try {
    const { data } = yield call(getTaskDetails, taskId);
    yield put(actions.fetchDetailsSuccess(data.entity));
    yield put(actions.changeParts(data.entity.Parts.map(p => ({ ...p, IsAssigned: false }))));
    yield put(actions.changePartGroups(data.entity.PartGroups.map(p => ({ ...p, IsAssigned: false }))));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: error });
    yield put(actions.fetchDetailsFailure(error));
  }
}

function* fetchTaskNotes({ payload }) {
  try {
    const { data } = yield call(getConfirmationPhotoList, payload);
    const notesWithSrc = data.root.map(item => {
      const newItem = { ...item };
      newItem.src = `${http.defaults.baseURL}/ConfirmationPhoto/Get?id=${item.Id}&parentId=${payload}`;
      return newItem;
    });
    yield put(actions.fetchNotesSuccess(notesWithSrc));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: error });
    yield put(actions.fetchNotesFailure(error));
  }
}

function* fetchTaskPhotos({ payload }) {
  try {
    const { data } = yield call(getItemPictureList, payload);
    const photosWithSrc = data.root.map(item => {
      const newItem = { ...item };
      newItem.src = `${http.defaults.baseURL}/ItemPicture/Get?id=${item.Id}`;
      return newItem;
    });
    yield put(actions.fetchPhotosSuccess(photosWithSrc));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: error });
    yield put(actions.fetchPhotosFailure(error));
  }
}

function* getFilteredTaskCount({ payload: { period, ...rest } }) {
  try {
    const { data } = yield call(fetchFilteredTaskCount, rest);
    yield put(actions.getFilteredTaskCountSuccess({ [period]: data.entity }));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error)));
    yield put(actions.fetchTasksFailure(error));
  }
}

function* fetchTasks({ payload }) {
  try {
    const startRange = yield select(getRangeStart);
    let users = yield select(getUsersSelector);
    if (!users.length) {
      const siteId = yield call(getSelectedSite);
      const { data } = yield call(getUsersList, siteId);
      yield put(actions.usersListSuccess(data.root));
      users = yield select(getUsersSelector);
      const assignee = users.find(user => user.Id === payload.id) || {};
      const isPickUp = assignee.Name === 'Pick-Up';
      if (assignee.IsTeam) {
        yield put(actions.updateTasksType(taskToggle[1]));
        payload.type = taskToggle[1].key;
      } else if (isPickUp) {
        yield put(actions.updateTasksType(taskToggle[2]));
        payload.type = taskToggle[2].key;
      }
    }
    const { id, period, type, page, rowsPerPage } = payload;
    const assignee = users.find(user => user.Id === id) || {};
    const key = assignee.IsTeam ? 'ByTeam' : 'ByUser';
    const startDate = moment(startRange).format('MM/DD/YYYY');
    const yesterday = moment().subtract(1, 'day').format('MM/DD/YYYY');
    const weekAfterStart = moment(startRange).add(6, 'day').format('MM/DD/YYYY');
    const params = {
      day: {
        my: {
          [key]: id,
          ScheduledDateRangeStart: startDate,
          ScheduledDateRangeEnd: startDate,
          RequiresAttention: false,
        },
        team: {
          [key]: id,
          ScheduledDateRangeStart: startDate,
          ScheduledDateRangeEnd: startDate,
          RequiresAttention: false,
        },
        pickUp: {
          ByUser: EMPTY_GUID,
          ScheduledDateRangeStart: startDate,
          ScheduledDateRangeEnd: startDate,
          RequiresAttention: false,
        },
      },
      week: {
        my: {
          [key]: id,
          ScheduledDateRangeStart: startDate,
          ScheduledDateRangeEnd: weekAfterStart,
          RequiresAttention: false,
        },
        team: {
          [key]: id,
          ScheduledDateRangeStart: startDate,
          ScheduledDateRangeEnd: weekAfterStart,
          RequiresAttention: false,
        },
        pickUp: {
          ByUser: EMPTY_GUID,
          ScheduledDateRangeStart: startDate,
          ScheduledDateRangeEnd: weekAfterStart,
          RequiresAttention: false,
        },
      },
      past: {
        my: {
          [key]: id,
          ScheduledDateRangeEnd: yesterday,
          RequiresAttention: false,
        },
        team: {
          [key]: id,
          ScheduledDateRangeEnd: yesterday,
          RequiresAttention: false,
        },
        pickUp: {
          ByUser: EMPTY_GUID,
          ScheduledDateRangeEnd: yesterday,
          RequiresAttention: false,
        },
      },
    };

    const tasks = {
      day: {
        my: { tasks: {}, amount: 0 },
        team: { tasks: {}, amount: 0 },
        pickUp: { tasks: {}, amount: 0 },
      },
      week: {
        my: { tasks: {}, amount: 0 },
        team: { tasks: {}, amount: 0 },
        pickUp: { tasks: {}, amount: 0 },
      },
      past: {
        my: { tasks: {}, amount: 0 },
        team: { tasks: {}, amount: 0 },
        pickUp: { tasks: {}, amount: 0 },
      },
    };
    yield* getFilteredTaskCount({ payload: { ...params[period][type], period } });
    const taskCount = yield select(state => state.tasks.taskCount[period]);

    const paramsWithLimits = {
      filter: {
        ...params[period][type],
      },
      options: {
        start: page * rowsPerPage - rowsPerPage,
        limit: rowsPerPage,
      },
    };
    if (taskCount < page * rowsPerPage) {
      const newPage = Math.trunc(taskCount / rowsPerPage) + 1;
      paramsWithLimits.options.start = newPage * rowsPerPage - rowsPerPage;
      yield put(actions.updatePage(newPage));
    }

    const responseTask = yield call(getTasks, paramsWithLimits);
    if (period === 'week') {
      tasks[period][type] = divideByDate(responseTask.data.root);
    } else {
      tasks[period][type] = divideByUnitName(responseTask.data.root);
    }
    tasks[period][type].amount = taskCount;
    yield put(actions.fetchTasksSuccess(tasks));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error)));
    yield put(actions.fetchTasksFailure(error));
  }
}

function* fetchUser({ payload: { id } }) {
  try {
    const { data } = yield call(getUserProfile, id);
    yield put(actions.fetchUserSuccess(data.entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchUserFailure(error));
  }
}

function* fetchUsersList() {
  try {
    const siteId = yield call(getSelectedSite);
    const { data } = yield call(getUsersList, siteId);
    yield put(actions.usersListSuccess(data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: error });
    yield put(actions.usersListFailure(error));
  }
}

function* releaseTask({ payload: { taskIds, id, userId, period, type, page, rowsPerPage } }) {
  try {
    const params = taskIds.map(item => ({ AssignedTaskId: item, UserId: userId ?? null }));
    yield call(releaseTasks, params);
    yield put(actions.releaseTaskSuccess());
    yield put(actions.fetchTasksRequest({ id, period, type, page, rowsPerPage }));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: error });
    yield put(actions.releaseTaskFailure(error));
  }
}

function* saveNote({ payload: { parentId, comments, onSuccess } }) {
  try {
    yield call(setCommentsOnTask, parentId, comments);
    yield put(actions.saveNoteSuccess());
    yield put(actions.fetchDetailsRequest({ taskId: parentId }));

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Alert'));
    yield put(actions.saveNoteFailure(error));
  }
}

function* saveTask({ payload }) {
  try {
    const url = prepareSaveTaskUrl(payload);
    const params = prepareSaveTaskParams(payload);

    const { data } = yield call(saveTaskRequest, url, params);
    if (data.entity.Success === false) {
      const message = data.entity.Message;
      yield put({ type: ERROR_DIALOG_OPENED, payload: { message } });
      yield put(actions.saveTaskFailure({ message }));
      const error = { status: 200 };
      throw error;
    }

    yield put(actions.saveTaskSuccess());
    yield put(actions.setIsTaskUpdated(true));
  } catch (error) {
    if (error.status !== 200) {
      const message = error.response.data.error || 'An error occurred while saving the task details.';
      yield put({ type: ERROR_DIALOG_OPENED, payload: { message } });
      yield put(actions.saveTaskFailure(error));
    }
  }
}

function* uploadPhoto({ payload: { file, parentId } }) {
  try {
    const data = new FormData();
    data.append('file', file);
    data.append('parentId', parentId);
    yield call(postPhoto, data);
    yield put(actions.uploadPhotoTasksSuccess());
    yield put(actions.fetchNotesRequest(parentId));
    yield put(actions.fetchDetailsRequest({ taskId: parentId }));
  } catch (error) {
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: { message: 'An error occurred while saving the Confirmation Photo.' },
    });
    // Do not pass error object in payload in order to copy old app behavour
    yield put(actions.uploadPhotoTasksFailure({ message: 'Request failed' }));
  }
}

function* removePhoto({ payload }) {
  try {
    yield call(deletePhoto, payload);
    yield put(actions.uploadPhotoTasksSuccess());
    yield put(actions.fetchNotesRequest(payload.parentId));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Alert'));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* uploadScanCode({ payload: { file, parentId } }) {
  const uploadedFile = file[0];
  try {
    const formData = new FormData();
    formData.append('file', uploadedFile);
    formData.append('id', parentId);
    yield call(verifyScanCodeTask, parentId, formData);
    yield put(actions.uploadScanCodeSuccess({ filename: uploadedFile.name, file: uploadedFile, success: true }));
  } catch (error) {
    const lastScanCode = { filename: uploadedFile.name, file: null, success: false };
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: { message: 'An error occurred during uploading/verifying scan code.' },
    });
    yield put(actions.uploadScanCodeFailure({ error, lastScanCode }));
  }
}

function* sendEmailWithTaskInfo({ payload: { taskId, recepientsId, messageText } }) {
  try {
    const params = { taskId, recepientsId, messageText };
    yield call(sendEmailAboutTask, params);
    yield put(actions.sendTaskInfoEmailSuccess());
  } catch (error) {
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: { message: error.response.data.error },
    });
    yield put(actions.sendTaskInfoEmailFailure(error));
  }
}

function* getReportPhoto({ payload }) {
  try {
    const response = yield call(fetchReportPhoto, payload);
    const base64ImageString = Buffer.from(response.data, 'binary').toString('base64');
    const srcValue = `data:image/png;base64,${base64ImageString}`;

    yield put(actions.fetchReportPhotoSuccess(srcValue));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Alert'));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* createReportPhoto({ payload: { file, assignedTaskId } }) {
  try {
    const payload = new FormData();
    payload.append('file', file);
    payload.append('assignedTaskId', assignedTaskId);
    yield call(uploadReportPhoto, payload);
    yield put(actions.getReportPhotosListRequest(assignedTaskId));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Alert'));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* removeReportPhoto({ payload: { AssignedTaskId, Id } }) {
  try {
    yield call(deleteReportPhoto, Id);
    yield put(actions.getReportPhotosListRequest(AssignedTaskId));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Alert'));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* fetchReportPhotosList({ payload }) {
  try {
    const { data } = yield call(getReportPhotosList, payload);
    yield put(actions.getReportPhotosListSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Alert'));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* editReportPhoto({ payload }) {
  try {
    const reportPhotos = yield select(state => state.tasks.reportPhotos);
    const { data } = yield call(changeReportPhoto, payload);
    const newReportPhotos = reportPhotos.map(photo =>
      photo.Id === data.entity.Id ? { ...photo, IsIncludedIntoReport: data.entity.IsIncludedIntoReport } : photo,
    );

    yield put(actions.getReportPhotosListSuccess(newReportPhotos));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Alert'));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

const tasksSagas = [
  takeEvery(actions.assignTaskRequest, assignTask),
  takeEvery(actions.fetchDetailsRequest, fetchTaskDetails),
  takeEvery(actions.getFilteredTaskCountRequest, getFilteredTaskCount),
  takeEvery(actions.fetchNotesRequest, fetchTaskNotes),
  takeEvery(actions.fetchPhotosRequest, fetchTaskPhotos),
  takeEvery(actions.fetchTasksRequest, fetchTasks),
  takeEvery(actions.fetchUserRequest, fetchUser),
  takeEvery(actions.usersListRequest, fetchUsersList),
  takeEvery(actions.releaseTaskRequest, releaseTask),
  takeEvery(actions.saveNoteRequest, saveNote),
  takeEvery(actions.saveTaskRequest, saveTask),
  takeEvery(actions.uploadPhotoTasksRequest, uploadPhoto),
  takeEvery(actions.uploadScanCodeRequest, uploadScanCode),
  takeEvery(actions.sendTaskInfoEmailRequest, sendEmailWithTaskInfo),
  takeEvery(actions.deletePhotoRequest, removePhoto),
  takeEvery(actions.uploadReportPhotoRequest, createReportPhoto),
  takeEvery(actions.getReportPhotosListRequest, fetchReportPhotosList),
  takeEvery(actions.fetchReportPhotoRequest, getReportPhoto),
  takeEvery(actions.deleteReportPhotoRequest, removeReportPhoto),
  takeEvery(actions.editReportPhotoRequest, editReportPhoto),
];

export default tasksSagas;
