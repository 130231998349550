import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Provider, ReactReduxContext, useDispatch, useSelector } from 'react-redux';

import { Dialog } from 'components/Dialogs/v1/Base';
import Loader from 'components/Loader';
import { actions as authActions } from 'redux/auth';

import theme from 'theme';

import InfoPage from './InfoPage';
import SelectSite from './SelectSite';
import UnitInfo from './UnitInfo';
import ItemInfo from './ItemInfo';
import SelectTasks from './SelectTasks';
import SearchListTemplate from './SearchListTemplate';
import ImportComplete from './ImportComplete';
import NewVersion from './NewVersion';
import ListTemplatePreview from './ListTemplatePreview';

import * as S from './styled';

const STEPS = {
  0: {
    Component: InfoPage,
  },
  1: {
    Component: SelectSite,
  },
  2: {
    Component: UnitInfo,
  },
  3: {
    Component: ItemInfo,
  },
  4: {
    Component: SelectTasks,
  },
};

const STEPS_IMPORT = {
  0: {
    Component: InfoPage,
  },
  1: {
    Component: SelectSite,
  },
  2: {
    Component: SearchListTemplate,
  },
  3: {
    Component: NewVersion,
  },
  4: {
    Component: ImportComplete,
  },
  5: {
    Component: ListTemplatePreview,
  },
};

const ListTemplate = ({
  open,
  handleClose,
  handleExit,
  openItemLimitDialog,
  firstTimeTemplate,
  anotherUnitTemplate,
  currentSite,
  setCurrentSite,
  listTemplateNames,
  openImportListTemplateDialog,
  openScheduleTaskOfferDialog,
  addAnotherItem,
}) => {
  const [step, setStep] = useState(0);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [isImport, setIsImport] = useState(false);
  const isLoading = useSelector(state => state.listTemplates.isLoading);
  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const stepAnotherUnit = anotherUnitTemplate ? 2 + step : 0 + step;
  const stepPlus = firstTimeTemplate ? 0 + stepAnotherUnit : 3 + stepAnotherUnit;
  const Step = isImport ? STEPS_IMPORT[stepPlus].Component : STEPS[stepPlus].Component;
  const newItemInfoText = 'An Item can be any object that you would like to assign tasks to.';
  const isNewItemComponent = stepPlus === 3;

  const onIsImportChange = () => {
    setIsImport(true);
  };

  const setIsNew = () => {
    setIsImport(false);
  };

  const onTemplateChange = template => {
    setCurrentTemplate(template);
  };

  const handlePrev = () => {
    setStep(prevState => prevState - 1);
  };

  const handleNext = () => {
    setStep(prevState => prevState + 1);
  };

  const getTitle = () => {
    if ((stepPlus === 3 || stepPlus === 4) && isImport) {
      return 'Alert';
    }
    if (step > 1) {
      return 'List Template Setup';
    }
    return 'Adding List Template(s)';
  };

  useEffect(() => {
    dispatch(authActions.fetchUserSitesRequest());
    setStep(0);
  }, [dispatch, open]);

  return (
    <Dialog
      open={open}
      onRequestClose={stepPlus > 1 ? handleClose : handleExit}
      title={getTitle()}
      titleColor={(isNewItemComponent || stepPlus === 4) && isImport ? theme.mainRed : theme.mainLightBlue}
      noCapitalize
    >
      <S.DialogContainer>
        <Loader isLoading={isLoading} size="large" />
        <Provider store={store}>
          <Step
            onNext={handleNext}
            onBack={handlePrev}
            onClose={handleClose}
            onExit={handleExit}
            setCurrentSite={setCurrentSite}
            currentSite={currentSite}
            listTemplateNames={listTemplateNames}
            currentTemplate={currentTemplate}
            setCurrentTemplate={onTemplateChange}
            setIsImport={onIsImportChange}
            setIsNew={setIsNew}
            addAnotherItem={addAnotherItem}
            openItemLimitDialog={openItemLimitDialog}
            firstTimeTemplate={firstTimeTemplate}
            openImportListTemplateDialog={openImportListTemplateDialog}
            openScheduleTaskOfferDialog={openScheduleTaskOfferDialog}
            anotherUnitTemplate={anotherUnitTemplate}
            tooltipText={isNewItemComponent && newItemInfoText}
          />
        </Provider>
      </S.DialogContainer>
    </Dialog>
  );
};

ListTemplate.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleExit: PropTypes.func.isRequired,
  addAnotherItem: PropTypes.func.isRequired,
  openScheduleTaskOfferDialog: PropTypes.func.isRequired,
  openImportListTemplateDialog: PropTypes.func.isRequired,
  openItemLimitDialog: PropTypes.func.isRequired,
  firstTimeTemplate: PropTypes.bool.isRequired,
  anotherUnitTemplate: PropTypes.bool.isRequired,
  currentSite: PropTypes.shape().isRequired,
  setCurrentSite: PropTypes.func.isRequired,
  listTemplateNames: PropTypes.array.isRequired,
};

export default ListTemplate;
