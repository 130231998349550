import React from 'react';
import PropTypes from 'prop-types';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const InfoPage = ({ onNext }) => (
  <S.DialogContainer>
    <S.SectionContainer>
      <S.Title>Purpose of this Action Card:</S.Title>
      <S.Text>Add or create List Templates, the basis for creating checklists</S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Preparation:</S.Title>
      <S.Text>
        A List template starts with recording what Unit the template is for. A Unit could be a room, a vehicle or a
        machine or any large object or space that contains smaller items that need to be checked.
      </S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Required information:</S.Title>
      <S.Text>
        <S.BoldText>Unit Name</S.BoldText>
      </S.Text>
      <S.Text>
        <S.BoldText>Item Name(s)</S.BoldText>
      </S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Text>
        List Templates can be reused partially or completely at any time to create checklists. Multiple checklists can
        contain one or more Items from the same List Template.
      </S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Result:</S.Title>
      <S.Text>This process will download or setup List Template(s) that can be used to create checklists</S.Text>
    </S.SectionContainer>
    <ActionsContainer>
      <ActionButton label="Next" onClick={onNext} isNext />
    </ActionsContainer>
  </S.DialogContainer>
);

InfoPage.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default InfoPage;
