export const validator = values => {
  const errors = {};

  if (!values.IndustryID) {
    errors.IndustryID = 'Required';
  }

  return errors;
};

export default validator;
