import http from '../index';

export const fetchSensors = dto => http.post('MasterSensor/List', { masterGatewayId: dto });

export const putSensor = dto => http.post('MasterSensor/Put', dto);

export const fetchSensorsForSite = () => http.post('MasterSensor/ListForSite');

export const fetchItemSensors = dto => http.post('MasterSensor/ListForItem', { itemId: dto });

export const fetchSensorTypes = () => http.post('MasterSensorType/List');

export const setMasterSensorType = payload =>
  http.post(`MasterSensorType/${payload.Id ? 'Put' : 'Post'}`, { id: payload.Id, dto: payload });

export const removeSensorType = dto => http.post('MasterSensorType/Delete', { id: dto });

export const getMasterSensorLevelList = sensorData =>
  http.post('MasterSensorLevel/List', { masterSensorTypeId: sensorData.Id });

export const deleteMasterSensorLevel = level => http.post('MasterSensorLevel/Delete', { id: level.deleteId });

export const changeMasterSensorLevel = level =>
  http.post(`MasterSensorLevel/${level.Id ? 'Put' : 'Post'}`, { id: level.Id, dto: level });

export const fetchLevelsForItemSensor = sensor => http.post('MasterSensor/GetWithInternalObjects', { id: sensor.Id });

export const setLevelsForItemMasterSensor = (newValues, sensorId) =>
  http.post('MasterSensor/PutWithInternalObjects', { id: sensorId, dto: { Levels: newValues } });
