import styled from 'styled-components';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';
import { ButtonIcon } from 'components/Layout/Buttons';

export const StyledActionsContainer = styled.div`
  div {
    & > button:first-child {
      margin-right: 10px !important;
    }

    & > button:last-child {
      margin-left: 10px !important;
    }
  }
`;

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const Block = styled(BorderRadiusContainer)``;

export const InputContainer = styled(InputWrapper)``;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
`;

export const SelectFieldWidth = {
  width: '100%',
};

export const radioStyle = {
  width: '20%',
  paddingLeft: '20px',
};

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 10px;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const IconButton = styled(ButtonIcon)`
  width: 35px !important;
  padding: 0 !important;
  margin-bottom: 8px !important;

  svg {
    color: ${({ theme }) => theme.primarySites} !important;
  }

  &:hover {
    transform: translateZ(0) scale(1);
  }
`;
