import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import { FieldCheckbox } from 'components/Layout/Inputs';

import * as S from './styled';

const InfoPage = ({ onNext, isDemoTaskMode }) => (
  <S.DialogContainer>
    <S.SectionContainer>
      <S.Text>
        The system is set to Demo mode.
        <br />
        All tasks will be deleted after Demo
        <br />
        mode is finished.
      </S.Text>
      <div data-tip="You can deactivate Demo mode in settings later">
        <FieldCheckbox
          checked={isDemoTaskMode}
          label="Demo mode activated"
          style={{ width: '35%', margin: '0 auto' }}
        />
      </div>
      <ReactTooltip place="bottom" />
    </S.SectionContainer>
    <ActionsContainer>
      <ActionButton label="Continue" onClick={onNext} isNext />
    </ActionsContainer>
  </S.DialogContainer>
);

InfoPage.propTypes = {
  isDemoTaskMode: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default InfoPage;
