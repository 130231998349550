import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import theme from 'theme';

import BaseDialog from 'components/Dialogs/Base';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { findSite } from 'helpers';
import { actions as scheduleActions } from 'redux/schedule';
import { schedulerStatusHandler } from '../../helpers';

import * as S from './styled';

export const DeleteQuickTaskDialog = NiceModal.create(({ quickTask }) => {
  const currentChecklistStatus = useSelector(state => state.schedule.currentChecklistStatus);
  const sitesAvailableToUserFullInfo = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const siteName = findSite(sitesAvailableToUserFullInfo, selectedSite);
  const status = schedulerStatusHandler(quickTask);
  const modal = useModal();
  const dispatch = useDispatch();

  const onSubmit = e => {
    e.preventDefault();
    modal.hide();
    dispatch(scheduleActions.setIsTasksModalOpened(true));
    dispatch(scheduleActions.setChecklistToEdit(quickTask));
  };

  return (
    <BaseDialog
      open={modal.visible}
      title="Quick Task Assignment"
      onRequestClose={modal.hide}
      titleColor={theme.mainRed}
      modalStyles={{ maxWidth: '600px' }}
    >
      <S.Container onSubmit={onSubmit}>
        <S.Title>Site: {siteName}</S.Title>
        <S.Text>{quickTask.UnitName}</S.Text>
        <S.TasksCountContainer>
          <S.TasksCount>{currentChecklistStatus.InProgressCount}</S.TasksCount>
        </S.TasksCountContainer>
        <S.Text>Tasks</S.Text>
        <S.Text>{moment(quickTask.CreatedOn).format('dddd - D MMMM YYYY')}</S.Text>

        <S.Section>
          <S.Label>Checklist Owner (Manager)</S.Label>
          <p>Checklist Owner: {quickTask.SupervisorName}</p>
        </S.Section>
        <S.Section>
          <S.Label>Assignment Selection</S.Label>
          <p>{quickTask.AssigneeName}</p>
        </S.Section>
        <S.Section>
          <S.Label>Reason</S.Label>
          <p>{quickTask.Reason}</p>
        </S.Section>
        <S.Status color={status.color}>Status: {status.title} - No Edits</S.Status>
        <ActionsContainer paddingValue={16}>
          <ActionButton type="submit" label="DELETE" />
        </ActionsContainer>
      </S.Container>
    </BaseDialog>
  );
});
