export const PEOPLE_RADIO_BUTTONS = [
  {
    label: 'Day before first date',
    value: 0,
  },
  {
    label: 'Week before',
    value: 1,
  },
  {
    label: 'Month before',
    value: 2,
  },
  {
    label: 'Year before first date',
    value: 4,
  },
];

export const TASKS_RADIO_BUTTONS = [
  {
    label: 'Day before first date',
    value: 0,
  },
  {
    label: 'Week before',
    value: 1,
  },
  {
    label: 'Month before',
    value: 2,
  },
  {
    label: 'Year before first date',
    value: 4,
  },
];
