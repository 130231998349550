import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import moment from 'moment';

import { getModuleTheme } from 'helpers';

import { actions as appActions } from 'redux/app';
import { actions as authActions } from 'redux/auth';

import Activator from 'modules/activator/SystemControllerActivator';
import GeneralActivator from 'modules/activator/GeneralActivator';
import Documents from 'modules/documents';
import Header from 'components/Header';
import ErrorRoutePage from 'components/ErrorRoutePage';
import Home from 'modules/home';
import Lists from 'modules/lists';
import Parts from 'modules/parts';
import SiteParts from 'modules/siteparts';
import People from 'modules/people';
import Queue from 'modules/queue';
import Scheduler from 'modules/scheduler';
import Settings from 'modules/settings';
import Sites from 'modules/sites';
import Task from 'modules/tasks';
import Tenants from 'modules/tenants';
import Exchange from 'modules/exchange';
import GlobalSettings from 'modules/globalsettings';
import Tutorials from 'modules/tutorials';
import Health from 'modules/health';
import Teams from 'modules/teams';
import Shortcuts from 'modules/shortcuts';
import TeamMembers from 'modules/teams/TeamMembers';
import TenantRequests from 'modules/tenantrequests';
import UpgradeRequests from 'modules/upgraderequests';

import { Content } from './styled';

const Root = () => <Redirect to="/home" />;

const SystemControllerRoutes = () => (
  <Switch>
    <Route exact path="/" component={Root} />
    <Route path="/home" component={Home} />
    <Route exact path="/activator" component={Activator} />
    <Route exact path="/tenantrequests" component={TenantRequests} />
    <Route exact path="/upgraderequests" component={UpgradeRequests} />
    <Route path="/tenants" component={Tenants} />
    <Route path="/exchange" component={Exchange} />
    <Route path="/globalsettings" component={GlobalSettings} />
    <Route path="/tutorials" component={Tutorials} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

const AdminRoutes = () => (
  <Switch>
    <Route exact path="/" component={Root} />
    <Route path="/health" component={Health} />
    <Route path="/sites" component={Sites} />
    <Route path="/documents" component={Documents} />
    <Route path="/home" component={Home} />
    <Route exact path="/activator" component={GeneralActivator} />
    <Route path="/people" component={People} />
    <Route path="/queue" component={Queue} />
    <Route path="/parts" component={Parts} />
    <Route path="/siteparts" component={SiteParts} />
    <Route path="/scheduler" component={Scheduler} />
    <Route path="/tasks" component={Task} />
    <Route path="/units" component={Lists} />
    <Route path="/settings" component={Settings} />
    <Route exact path="/teams" component={Teams} />
    <Route exact path="/teams/:teamId" component={TeamMembers} />
    <Route exact path="/shortcuts" component={Shortcuts} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

const ManagerRoutes = () => (
  <Switch>
    <Route exact path="/" component={Root} />
    <Route path="/home" component={Home} />
    <Route exact path="/activator" component={GeneralActivator} />
    <Route path="/documents" component={Documents} />
    <Route path="/people" component={People} />
    <Route path="/queue" component={Queue} />
    <Route path="/scheduler" component={Scheduler} />
    <Route path="/tasks" component={Task} />
    <Route path="/units" component={Lists} />
    <Route path="/parts" component={Parts} />
    <Route path="/siteparts" component={SiteParts} />
    <Route exact path="/shortcuts" component={Shortcuts} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

const OEMAdministrator = () => (
  <Switch>
    <Route exact path="/" component={Root} />
    <Route path="/home" component={Home} />
    <Route path="/sites" component={Sites} />
    <Route path="/people" component={People} />
    <Route path="/units" component={Lists} />
    <Route exact path="/shortcuts" component={Shortcuts} />
    <Route exact path="/activator" component={GeneralActivator} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

const OEMManager = () => (
  <Switch>
    <Route exact path="/" component={Root} />
    <Route path="/home" component={Home} />
    <Route path="/units" component={Lists} />
    <Route exact path="/activator" component={GeneralActivator} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

const DashboardUser = () => (
  <Switch>
    <Route exact path="/" component={Root} />
    <Route path="/health" component={Health} />
    <Route path="/home" component={Home} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

const SpecialistRoutes = ({ userId }) => {
  const MyTasks = () => <Redirect to={`/tasks/${userId}/${moment().format('YYYY-MM-DD')}`} exact />;

  return (
    <Switch>
      <Route exact path="/" component={Root} />
      <Route path="/home" component={Home} />
      <Route exact path="/tasks/:userId" component={MyTasks} />
      <Route path="/tasks" component={Task} />
      <Route path="/*" component={ErrorRoutePage} />
    </Switch>
  );
};

SpecialistRoutes.propTypes = {
  userId: PropTypes.string.isRequired,
};

const UnknownRoutes = () => null;

const Routers = {
  0: UnknownRoutes,
  1: SpecialistRoutes,
  2: ManagerRoutes,
  4: AdminRoutes,
  8: SystemControllerRoutes,
  16: OEMAdministrator,
  32: OEMManager,
  64: DashboardUser,
};

class Main extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userType: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    shouldRedirectToLogin: PropTypes.bool.isRequired,
    unsetShouldRedirectToLogin: PropTypes.func.isRequired,
    unauthenticate: PropTypes.func.isRequired,
    currentModule: PropTypes.string.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { location, history, userId, unauthenticate } = this.props;
    // Redirect to /home if System Controller has used LoginAs feature and was logged in under another user
    if (userId !== nextProps.userId) {
      history.replace('/home');
    }

    if (nextProps.shouldRedirectToLogin) {
      unauthenticate();
      history.push({ pathname: '/login', state: { from: location } });
    }
  }

  componentWillUnmount() {
    this.props.unsetShouldRedirectToLogin();
  }

  render() {
    const { currentModule, userId, userType } = this.props;

    const Routes = Routers[userType];
    const theme = getModuleTheme(currentModule);

    return [
      <Header userType={userType} key="Header" />,
      <Content key="Content">
        <MuiThemeProvider muiTheme={theme}>
          <Routes userId={userId} />
        </MuiThemeProvider>
      </Content>,
    ];
  }
}

const mapStateToProps = ({
  app: { currentModule, shouldRedirectToLogin },
  auth: {
    user: { userType, userId },
  },
}) => ({
  currentModule,
  shouldRedirectToLogin,
  userType,
  userId,
});

export default connect(mapStateToProps, {
  unsetShouldRedirectToLogin: appActions.shouldRedirectToLoginUnset,
  unauthenticate: authActions.setAuthenticatedToFalse,
})(Main);
