import styled, { css } from 'styled-components';
import mainTheme from 'theme';

import { ImageIcon } from 'components/Layout/Icons';

export const TaskWithPartDetails = styled.div`
  padding: 20px;
`;

export const Item = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

export const LocationItem = styled.li`
  margin-bottom: 15px;
`;

export const PartsItem = styled.div`
  margin-bottom: 10px;
  display: inline-flex;
  width: 50%;
`;

export const PartsValue = styled.div`
  color: ${({ theme }) => theme.primaryRed};
`;

const styleCss = css`
  margin: 0;
  padding: 0;

  & li {
    list-style-type: none;
  }
`;

export const List = styled.ul`
  ${styleCss}
`;

export const Head = styled(List)`
  margin-bottom: 20px; ;
`;

export const NotifyTitle = styled.h2`
  font-size: 20px;
  margin: 25px 0 15px;
  text-align: center;
  color: ${({ theme }) => theme.darkGrey};
`;

export const WarningText = styled.p`
  font-size: 20px;
  text-align: center;
  padding: 40px 0px;
`;

export const PhotosBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NoPhotoIcon = styled(ImageIcon).attrs({
  color: () => mainTheme.textGray,
})``;

export const PhotoIcon = styled(ImageIcon).attrs({
  color: () => mainTheme.primaryRed,
})``;

export const baseFlatButtonStyles = { fontWeight: 500 };
export const halfWidthFlatButtonStyles = { width: '50%', color: mainTheme.primary };
export const primaryStyles = { ...baseFlatButtonStyles, color: mainTheme.greenButton };
export const submitFlatButtonStyles = {
  ...baseFlatButtonStyles,
  ...halfWidthFlatButtonStyles,
  color: mainTheme.secondary,
};
