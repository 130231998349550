import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import * as S from 'components/Dialogs/styled';

import CheckboxList from './CheckboxList';

import theme from 'theme';

class AddTeamMembersDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onAdd: PropTypes.func.isRequired,
    teamId: PropTypes.string.isRequired,
  };

  state = {
    members: [],
    membersForSearch: [],
    checkedMembers: [],
    searchData: '',
  };

  componentDidUpdate(prevProps, prevState) {
    const { people } = this.props;
    const { searchData, checkedMembers } = this.state;

    if (people && people.length > 0 && prevState.members.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        members: people,
        membersForSearch: people,
      });
    }

    if (
      prevState.members.length !== people.length &&
      prevState.searchData === searchData &&
      prevState.checkedMembers.length === checkedMembers.length
    ) {
      this.setState({
        members: people,
      });
    }
  }

  clearCheckedMembers = () => this.setState({ checkedMembers: [] });
  fillCheckedMembers = checkedMembers => this.setState({ checkedMembers });
  setSearchedMembers = (members, searchData) => this.setState({ members, searchData });

  closeModal = () => {
    this.setState({
      members: [],
      membersForSearch: [],
      searchData: '',
    });
    this.clearCheckedMembers();
    this.props.handleClose();
  };

  addTeamMembers = () => {
    const { onAdd, teamId } = this.props;
    const { checkedMembers } = this.state;
    onAdd({ teamId, userIds: checkedMembers });
    this.closeModal();
  };

  render() {
    const { open, title } = this.props;
    const { members, checkedMembers, searchData, membersForSearch } = this.state;

    return (
      <>
        <Dialog open={open} onRequestClose={this.closeModal} title={title} titleColor={theme.primaryBlue}>
          <S.DialogContainer>
            <S.SearchContainer>
              <SearchField
                list={membersForSearch}
                filterBy={['Name', 'JobFunction']}
                searchData={searchData}
                placeholder="Filter people"
                setSearchedItems={this.setSearchedMembers}
              />
            </S.SearchContainer>
            <S.ListContainer>
              <CheckboxList
                list={members}
                checkedMembers={checkedMembers}
                fillCheckedMembers={this.fillCheckedMembers}
              />
            </S.ListContainer>
            <ActionsContainer>
              {!!checkedMembers.length && <ActionButton onClick={this.addTeamMembers} label="Save" isNext />}
            </ActionsContainer>
          </S.DialogContainer>
        </Dialog>
      </>
    );
  }
}

export default AddTeamMembersDialog;
