import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';
import { AntennaIcon } from 'components/Layout/Icons';

export const IconAntenna = styled(AntennaIcon).attrs({
  color: ({ isAssigned, theme }) => (isAssigned ? theme.primaryRed : theme.primaryGrey),
})``;

export const IconButton = styled(ButtonIcon)`
  display: ${({ hasSite }) => (hasSite ? 'inline-block' : 'none')} !important;
`;
