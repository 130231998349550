export const validator = values => {
  const errors = {};
  if (!values.industryId) {
    errors.industryId = 'Industry is required!';
  }
  if (!values.companySitesNumber) {
    errors.companySitesNumber = 'Quantity of Company Sites is required!';
  }
  return errors;
};

export default validator;
