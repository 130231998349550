import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Footer = ({ onNext, onPrev, label, isDisabled, iconColor }) => (
  <S.Container>
    <S.Button
      label={`Previous ${label}`}
      onClick={onPrev}
      icon={<S.Previous iconColor={iconColor} isDisabled={isDisabled} />}
      disabled={isDisabled}
    />
    <S.Button
      label={`Next ${label}`}
      labelPosition="before"
      onClick={onNext}
      icon={<S.Next iconColor={iconColor} isDisabled={isDisabled} />}
      disabled={isDisabled}
    />
  </S.Container>
);

Footer.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default Footer;
