import { bindActionCreators } from 'redux';

import { actions as appActions } from 'redux/app';
import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as newSitesActions } from 'redux/newSites';
import { actions as listTemplatesActions } from 'redux/listTemplates';
import { actions as settingsActions } from 'redux/settings';
import { actions as peopleActions } from 'redux/people';
import { actions as teamsActions } from 'redux/teams';
import { actions as sitesActions } from 'redux/sites';

const mapStateToProps = ({ auth: { user }, shortcuts, newSites, tenants, listTemplates, people, sites }) => ({
  userType: user.userType,
  shortcut: shortcuts.shortcut,
  isNewSiteAvailable: newSites.isNewSiteAvailable,
  industriesList: tenants.industryList,
  limit: listTemplates.limits.MaxItemsNumber,
  currentItemsNumber: listTemplates.limits.CurrentItemsNumber,
  user,
  peopleList: people.list,
  sitesList: sites.list.length === 0,
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      updateCurrentModule: appActions.updateCurrentModule,
      requestUpgradeInfo: settingsActions.upgradeInfoRequest,
      deleteShortcutRequest: shortcutActions.deleteShortcutRequest,
      checkSiteRequest: newSitesActions.checkSiteRequest,
      getLimitsRequest: listTemplatesActions.getLimitsRequest,
      requestGetSettings: settingsActions.getSettingsRequest,
      fetchSitesList: sitesActions.sitesListRequest,
      fetchPeople: peopleActions.peopleListRequest,
      fetchTeams: teamsActions.getTeamsRequest,
    },
    dispatch,
  ),
});

export { mapStateToProps, mapDispatchToProps };
