import http from '../index';

export const getCurrentVersion = () => http.post('VersionLegal/GetLatestVersionLegal');

export const getVersionLegalsList = () => http.post('/VersionLegal/ListVersionLegal');

export const createVersionLegal = versionLegalDto => http.post('VersionLegal/CreateVersionLegal', { versionLegalDto });

export const removeVersionLegal = dto => http.post('VersionLegal/DeleteVersionLegal', `versionLegalId=${dto}`);

export const editVersionLegalRequest = versionLegalDto =>
  http.post('VersionLegal/EditVersionLegal', { versionLegalDto });
