import http from '../index';

export const accountLogOn = (params, config) => http.post('/Account/LogOn', params, config);

export const authenticationStart = () => http.get('/Auth/Start');

export const getUserSites = dto => http.post('/Account/GetUserSites', dto);

export const accountForgotPassword = dto => http.post('/Account/ForgotPassword', `userName=${encodeURIComponent(dto)}`);

export const tenantManagementLoginAs = dto =>
  http.post('/TenantManagement/LoginAs', `userName=${encodeURIComponent(dto.user.Name)}&tenantId=${dto.tenant.Id}`);

export const accountLogOff = () => http.post('/Account/LogOff');

export const passwordReset = dto => http.post('/Account/PasswordReset', dto);

export const userNameExists = dto => http.post('/UserManagement/UserNameExists', dto);

export const tenantNameExists = dto => http.post('/TenantManagement/TenantNameExists', dto);

export const createTenantRequest = tenantRequestDto =>
  http.post('/TenantManagement/CreateTenantRequest', { tenantRequestDto });

export const verifyTenantRequestToken = dto => http.post('TenantManagement/VerifyTenantRequestToken', dto);

export const activateAccount = (tenantName, token, dto) =>
  http.post('/Account/ActivateAccount', { tenantName, token, dto });
