import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const buttonStyles = { padding: '5px 0px' };

const getLabel = num => {
  if (num === 1) return 'Every period';
  if (num === 7) return 'Skip 6 periods';

  return '';
};

const buttons = [1, 2, 3, 4, 5, 6, 7];

const RadioGroup = ({ input, meta, style }) => (
  <RadioButtonGroup
    name="taskFrequency"
    defaultSelected={input.value.length === 0 ? 1 : input.value}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    {buttons.map(num => (
      <RadioButton
        key={num}
        value={num}
        label={getLabel(num)}
        iconStyle={{
          fill: input.value.length === 0 || input.value === num ? theme.primaryScheduler : theme.darkGrey,
        }}
        style={buttonStyles}
      />
    ))}
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RadioGroup;
