import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/reducers';
import { useHistory, useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import { actions as inventoryActions } from 'redux/inventory';
import { partsToggle } from 'configs/toggles';
import { convertDateToThreeDigit, findSite, searchItemByKeyword } from 'helpers';
import { InventoryPartType, InventoryPeriodType, PartGroupsType } from 'types/inventory';

import TableList from 'components/TableList';
import Toggle from 'components/Toggle';
import Subheader from 'components/Subheader';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import DropdownMenu from 'components/DropdownMenu';
import { AddIcon } from 'components/Layout/Icons';
import { AddPartOrGroupDialog, PartProfileDialog, SetStatusForInventoryDialog } from '../../components/dialogs';

import { PART_TABLE_CONFIGS, PART_GROUPS_TABLE_CONFIGS, PERIOD_MENU_ITEMS } from './configs';
import * as S from './styled';

export const PeriodParts = () => {
  const [searchValue, setSearchValue] = useState('');
  const [type, setType] = useState(partsToggle[0].key);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sitesAvailableToUserFullInfo = useAppSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const selectedSite = useAppSelector(state => state.auth.selectedSite);
  const partsList: InventoryPartType[] = useAppSelector(state => state.inventory.partsList);
  const partGroupsList: PartGroupsType[] = useAppSelector(state => state.inventory.partGroupsList);
  const periodsList: InventoryPeriodType[] = useAppSelector(state => state.inventory.periodsList);

  const siteName = findSite(sitesAvailableToUserFullInfo, selectedSite);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const isPart = type === 'parts';

  const data = isPart ? partsList : partGroupsList;
  const tableConfigs = isPart ? PART_TABLE_CONFIGS : PART_GROUPS_TABLE_CONFIGS;
  const period = periodsList.find(p => p.Id === id);

  const searchInList = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.currentTarget.value);

  const goBack = () => history.push('/siteparts/partsInventory/periods');

  const showPeriodClosedConfirmationModal = () =>
    NiceModal.show(ConfirmationModal, {
      label: 'OK',
      isNext: true,
      isGreenLabel: true,
      text: (
        <>
          <S.ConfirmationText>The Period is closed.</S.ConfirmationText>
          <S.ConfirmationText>Parts cannot be added or changed.</S.ConfirmationText>
        </>
      ),
      maxWidth: '600px',
      onClick: () => NiceModal.hide(ConfirmationModal),
    });

  const goToDetailsPage = (itemId: string) => history.push(`/siteparts/partsInventory/periods/${id}/${type}/${itemId}`);

  const selectPartType = (e: React.MouseEvent, value: 'part' | 'group') =>
    NiceModal.show(AddPartOrGroupDialog, {
      type: value,
      periodId: id,
      siteName,
    });

  const renderRightControllCell = (item: InventoryPartType | PartGroupsType) => {
    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      goToDetailsPage(item.Id);
    };

    return (
      <td>
        <S.IconButton tooltip="Details" tooltipPosition="bottom-center" onClick={handleClick}>
          <S.IconForward />
        </S.IconButton>
      </td>
    );
  };

  const renderLeftControllCell = (item: InventoryPartType | PartGroupsType) => (
    <S.DataCell status={item.Status}>
      <S.Item>{item.Status}</S.Item>
    </S.DataCell>
  );

  const mapList = (item: InventoryPartType | PartGroupsType) => ({
    ...item,
    POAdvice: <S.BoldText>{item.PurchaseOrderAdvice}</S.BoldText>,
  });

  const leftButtons = [
    {
      icon: <S.Back />,
      handler: goBack,
      hint: 'Back',
    },
  ];

  const rightButtons =
    period?.Status === 'Open'
      ? [
          {
            component: (
              <DropdownMenu
                styles={S.menuItemStyles}
                onChange={selectPartType}
                headerText="Add period"
                items={PERIOD_MENU_ITEMS}
                icon={<AddIcon />}
              />
            ),
            isComponent: true,
            hint: 'Add Part/Group',
          },
        ]
      : [
          {
            icon: <AddIcon />,
            handler: showPeriodClosedConfirmationModal,
            hint: 'Add period part',
            tooltipStyles: { top: '24px' },
          },
        ];

  const onRowClick = (item: InventoryPartType | PartGroupsType) => {
    const props = { data: item, type, history, inventoryPeriodId: id };

    if (item.Status === 'Locked') {
      NiceModal.show(SetStatusForInventoryDialog, props);
    } else {
      NiceModal.show(PartProfileDialog, props);
    }
  };

  useEffect(() => {
    if (isPart) {
      dispatch(inventoryActions.getInventoryPartsListRequest(id));
    } else {
      dispatch(inventoryActions.getInventoryPartGroupsListRequest(id));
    }
  }, [dispatch, id, isPart]);

  useEffect(() => {
    if (!periodsList.length) {
      dispatch(inventoryActions.getInventoryPeriodsListRequest());
    }
  }, [dispatch, periodsList.length]);

  return (
    <>
      <Subheader
        leftButtons={leftButtons}
        rightButtons={rightButtons}
        title={
          <>
            <S.Title>Periods Parts</S.Title>
            <S.Subtitle>{siteName}</S.Subtitle>
            <S.Subtitle>Start Date: {convertDateToThreeDigit(period?.CreatedOn)}</S.Subtitle>
            <Toggle
              config={partsToggle}
              handler={(value: Record<'key' | 'title', string>) => setType(value.key)}
              selected={type}
              minWidth
            />
          </>
        }
        isSearch
        hintText="Inventory Part Search"
        searchData={searchValue}
        searchInList={searchInList}
      />
      {data.length ? (
        <S.TableContainer>
          <TableList
            list={data.map(mapList).filter(searchItemByKeyword(tableConfigs, searchValue))}
            tableColumns={tableConfigs}
            tableHeads={tableConfigs}
            renderRightControllCell={renderRightControllCell}
            renderLeftControllCell={renderLeftControllCell}
            renderLeftControllTableHead={() => (
              <S.Header>
                <S.HeaderText>Status</S.HeaderText>
              </S.Header>
            )}
            onRowClick={onRowClick}
          />
        </S.TableContainer>
      ) : (
        <S.EmptyList>
          <p>There are no data.</p>
        </S.EmptyList>
      )}
    </>
  );
};
