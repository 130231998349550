import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DocumentImageType, DocumentItem } from 'configs/propTypes';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as documentActions } from 'redux/document';

import withInputModal from 'components/Dialogs/withInputModal';
import Form from './Form';

const AddEditDocumentWithInput = withInputModal(Form);

class AddEditDocumentDialog extends Component {
  static defaultProps = {
    documentItem: {},
    handleDelete: () => {},
    withDelete: false,
  };

  static propTypes = {
    backgroundImage: DocumentImageType.isRequired,
    clearImagesState: PropTypes.func.isRequired,
    deletePhoto: PropTypes.func.isRequired,
    documentItem: DocumentItem,
    footerImage: DocumentImageType.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func,
    headerImage: DocumentImageType.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    requestUploadPhoto: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    withDelete: PropTypes.bool,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open && this.props.open) {
      this.props.clearImagesState();
    }
  }

  onImageDelete = (imageId, imageType) => {
    this.props.deletePhoto({ imageId, type: imageType });
  };

  onImageUpload = (event, type) => {
    if (event.target.files.length) {
      this.props.requestUploadPhoto({ files: event.target.files, imageType: type });
    }
  };

  deleteBackgroundImage = () => {
    const { backgroundImage, documentItem } = this.props;
    const id = backgroundImage.id || documentItem.BackgroundImageId;

    if (id) {
      this.props.openConfirmationDialog(
        'Are you sure you want to permanently delete background image for document? This cannot be undone!',
        () => this.onImageDelete(id, 'backgroundImage'),
        'Delete?',
      );
    }
  };

  deleteFooterImage = () => {
    const { footerImage, documentItem } = this.props;
    const id = footerImage.id || documentItem.FooterImageId;

    if (id) {
      this.props.openConfirmationDialog(
        'Are you sure you want to permanently delete footer image for document? This cannot be undone!',
        () => this.onImageDelete(id, 'footerImage'),
        'Delete?',
      );
    }
  };

  deleteHeaderImage = () => {
    const { headerImage, documentItem } = this.props;
    const id = headerImage.id || documentItem.HeaderImageId;

    if (id) {
      this.props.openConfirmationDialog(
        'Are you sure you want to permanently delete header image for document? This cannot be undone!',
        () => this.onImageDelete(id, 'headerImage'),
        'Delete?',
      );
    }
  };

  submit = values => {
    const { onSubmit, backgroundImage, footerImage, headerImage } = this.props;
    const params = {
      ...values,
      BackgroundImageId: backgroundImage.id || values.BackgroundImageId,
      BackgroundImageFileName: backgroundImage.name || values.BackgroundImageFileName,
      FooterImageId: footerImage.id || values.FooterImageId,
      FooterImageFileName: footerImage.name || values.FooterImageFileName,
      HeaderImageId: headerImage.id || values.HeaderImageId,
      HeaderImageFileName: headerImage.name || values.HeaderImageFileName,
    };

    onSubmit(params);
  };

  uploadBackgroundImage = event => {
    event.persist();

    this.onImageUpload(event, 'backgroundImage');
  };

  uploadFooterImage = event => {
    event.persist();

    this.onImageUpload(event, 'footerImage');
  };

  uploadHeaderImage = event => {
    event.persist();

    this.onImageUpload(event, 'headerImage');
  };

  render() {
    const { documentItem, ...rest } = this.props;

    return (
      <AddEditDocumentWithInput
        {...rest}
        deleteBackgroundImage={this.deleteBackgroundImage}
        deleteFooterImage={this.deleteFooterImage}
        deleteHeaderImage={this.deleteHeaderImage}
        initialValues={documentItem}
        onSubmit={this.submit}
        uploadBackgroundImage={this.uploadBackgroundImage}
        uploadFooterImage={this.uploadFooterImage}
        uploadHeaderImage={this.uploadHeaderImage}
      />
    );
  }
}

const mapStateToProps = ({ documentItem }) => ({
  backgroundImage: documentItem.backgroundImage,
  footerImage: documentItem.footerImage,
  headerImage: documentItem.headerImage,
});

const mapDispatchToProps = {
  clearImagesState: documentActions.imagesStateClear,
  deletePhoto: documentActions.deleteDocumentPictureRequest,
  openConfirmationDialog,
  requestUploadPhoto: documentActions.uploadPhotoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditDocumentDialog);
