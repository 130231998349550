import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import { TenantsModuleIcon, ResourceGroupsIcon, HubIcon, Location } from 'components/Layout/Icons';
import FormControls from 'components/Dialogs/withInputModal/Controls';

import { DeleteResourceGroupType } from 'configs/propTypes';

import theme from 'theme';

import Confirmation from './Confirmation';
import * as S from './styled';

const icons = {
  Tenant: <TenantsModuleIcon />,
  'Resource Group': <ResourceGroupsIcon />,
  Hubs: <HubIcon />,
  Site: <Location />,
};

class RemoveGroupsAndHubsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape(DeleteResourceGroupType)).isRequired,
    alertText: PropTypes.string.isRequired,
    warningText: PropTypes.string.isRequired,
  };

  state = {
    warningDialogOpen: false,
  };

  openWarningDialog = () => this.setState({ warningDialogOpen: true });
  closeWarningDialog = () => this.setState({ warningDialogOpen: false });
  submitDeleting = () => {
    this.props.handleDelete();
    this.closeWarningDialog();
  };

  render() {
    const { open, title, closeModal, data, alertText, warningText } = this.props;
    const { warningDialogOpen } = this.state;

    return (
      <>
        <BaseDialog open={open} onRequestClose={closeModal} title={title}>
          <S.DialogContainer>
            {!!data.length &&
              data.map(({ type, fields, isRoot }) => (
                <div key={type}>
                  <S.ModalCard>
                    <S.CardHeader>
                      <S.CardTitle>{type}</S.CardTitle>
                      <S.IconContainer>{icons[type]}</S.IconContainer>
                    </S.CardHeader>
                    {(type === 'Tenant' || type === 'Resource Group' || type === 'Site') && (
                      <S.CardSmallInfoContainer type={type}>
                        {!!fields.length &&
                          fields.map(({ fieldName, fieldData }) => (
                            <S.CardText key={fieldName}>
                              <S.CardBoldText>{`${fieldName}: `}</S.CardBoldText>
                              {fieldData}
                            </S.CardText>
                          ))}
                      </S.CardSmallInfoContainer>
                    )}
                    {type === 'Hubs' && (
                      <S.HubCardInfoContainer isRoot={isRoot}>
                        {!isRoot ? (
                          <>
                            <S.CurrentHubContainer>
                              <S.CardBoldText>Hub Name</S.CardBoldText>
                              <S.CardBoldText>Gateways</S.CardBoldText>
                            </S.CurrentHubContainer>
                            {!!fields.length &&
                              fields.map(({ Name, GatewaysInHubNumber }) => (
                                <S.CurrentHubContainer key={Name}>
                                  <S.CurrentHubValue>{Name}</S.CurrentHubValue>
                                  <S.CurrentHubValue>{GatewaysInHubNumber}</S.CurrentHubValue>
                                </S.CurrentHubContainer>
                              ))}
                          </>
                        ) : (
                          <S.CurrentHubValue>{fields.name}</S.CurrentHubValue>
                        )}
                      </S.HubCardInfoContainer>
                    )}
                  </S.ModalCard>
                  {type === 'Tenant' && <S.AlertText>{alertText}</S.AlertText>}
                </div>
              ))}
          </S.DialogContainer>
          <FormControls
            handleClose={closeModal}
            withCancel
            customColor={theme.primaryGreen}
            cancelLabel="Cancel"
            handleDelete={this.openWarningDialog}
            withDelete
          />
        </BaseDialog>
        <Confirmation
          open={warningDialogOpen}
          handleClose={this.closeWarningDialog}
          submit={this.submitDeleting}
          confirmationText={warningText}
        />
      </>
    );
  }
}

export default RemoveGroupsAndHubsDialog;
