import moment from 'moment';

export const returnAssignmentType = (type, assigneeId) => {
  if (type === 0) {
    return assigneeId ? 2 : 0;
  }
  return 1;
};

export const generateDate = date => {
  // const currentOffset = moment(new Date()).utcOffset() / 60;
  // const modifedDate = new Date(date.setHours(0, 0));
  const modifedDate = new Date(date);

  // if (time) {
  //   return moment(modifedDate)
  //     .add(currentOffset, 'h')
  //     .add(time.getHours(), 'h')
  //     .add(time.getMinutes(), 'm')
  //     .toDate();
  // }
  // return moment(modifedDate).add(currentOffset, 'h');
  const utcDate = Date.UTC(modifedDate.getFullYear(), modifedDate.getMonth(), modifedDate.getDate(), 0, 0, 0);
  const preparedDate = moment.utc(utcDate).startOf('day').toISOString();
  return preparedDate;
};

export const generateDataToSend = currentChecklist => {
  let ScheduleData = {};
  if (currentChecklist.RepeatCycle === -1) {
    ScheduleData = {
      ScheduleType: 'OneTime',
      ScheduleData: {
        ScheduledDate: generateDate(currentChecklist.StartDate, currentChecklist.startTime),
      },
    };
  }
  if (currentChecklist.RepeatCycle === 0) {
    ScheduleData = {
      ScheduleType: 'RecurringDaily',
      ScheduleData: {
        DailyRepeatInterval: currentChecklist.RepeatInterval,
        DailyRepeatUntil: generateDate(currentChecklist.EndDate, currentChecklist.endTime),
        DailyStartDate: generateDate(currentChecklist.StartDate, currentChecklist.startTime),
      },
    };
  }
  if (currentChecklist.RepeatCycle === 1) {
    ScheduleData = {
      ScheduleType: 'RecurringWeekly',
      ScheduleData: {
        WeeklyRepeatInterval: currentChecklist.RepeatInterval,
        WeeklyRepeatUntil: generateDate(currentChecklist.EndDate, currentChecklist.endTime),
        WeeklyStartDate: generateDate(currentChecklist.StartDate, currentChecklist.startTime),
      },
    };
  }
  if (currentChecklist.RepeatCycle === 2) {
    ScheduleData = {
      ScheduleType: 'RecurringMonthly',
      ScheduleData: {
        MonthlyRepeatInterval: currentChecklist.RepeatInterval,
        MonthlyRepeatUntil: generateDate(currentChecklist.EndDate, currentChecklist.endTime),
        MonthlyStartDate: generateDate(currentChecklist.StartDate, currentChecklist.startTime),
      },
    };
  }
  if (currentChecklist.RepeatCycle === 4) {
    ScheduleData = {
      ScheduleType: 'RecurringYearly',
      ScheduleData: {
        YearlyRepeatInterval: currentChecklist.RepeatInterval,
        YearlyRepeatUntil: generateDate(currentChecklist.EndDate, currentChecklist.endTime),
        YearlyStartDate: generateDate(currentChecklist.StartDate, currentChecklist.startTime),
      },
    };
  }
  return {
    Name: currentChecklist.Name,
    isDynamic: !!currentChecklist.Type,
    ChecklistAssignmentType: currentChecklist.AssignmentType === 1 ? currentChecklist.AssignmentType : 0,
    SupervisorId: currentChecklist.OwnerName,
    TeamId: currentChecklist.AssignmentType === 0 ? null : currentChecklist.TeamName,
    ScheduleDto: ScheduleData,
    ChecklistType: 'Manual',
  };
};

export const generateEditInitialValues = (checklistToEdit, dublicateChecklist) => {
  if (!checklistToEdit.Name) {
    return {};
  }
  const scheduleType = checklistToEdit.ScheduleDto.ScheduleType;
  let skip = '';
  let repeat = '';
  let RepeatCycle = 0;
  let endDate = null;
  let endTime = 0;
  let startTime = 0;
  let startDate = null;
  if (scheduleType === 'RecurringDaily') {
    skip = checklistToEdit.ScheduleDto.ScheduleData.DailyRepeatInterval;
    repeat = 'Daily';
    RepeatCycle = 0;
    endDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.DailyRepeatUntil);
    endTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.DailyRepeatUntil);
    startDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.DailyStartDate);
    startTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.DailyStartDate);
  }
  if (scheduleType === 'RecurringWeekly') {
    skip = checklistToEdit.ScheduleDto.ScheduleData.WeeklyRepeatInterval;
    repeat = 'Weekly';
    RepeatCycle = 1;
    endDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.WeeklyRepeatUntil);
    endTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.WeeklyRepeatUntil);
    startDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.WeeklyStartDate);
    startTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.WeeklyStartDate);
  }
  if (scheduleType === 'RecurringMonthly') {
    skip = checklistToEdit.ScheduleDto.ScheduleData.MonthlyRepeatInterval;
    repeat = 'Monthly';
    RepeatCycle = 2;
    endDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.MonthlyRepeatUntil);
    endTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.MonthlyRepeatUntil);
    startDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.MonthlyStartDate);
    startTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.MonthlyStartDate);
  }
  if (scheduleType === 'RecurringYearly') {
    skip = checklistToEdit.ScheduleDto.ScheduleData.YearlyRepeatInterval;
    repeat = 'Yearly';
    RepeatCycle = 4;
    endDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.YearlyRepeatUntil);
    endTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.YearlyRepeatUntil);
    startDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.YearlyStartDate);
    startTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.YearlyStartDate);
  }
  if (scheduleType === 'OneTime') {
    repeat = 'One Time';
    RepeatCycle = -1;
    startDate = new Date(checklistToEdit.ScheduleDto.ScheduleData.ScheduledDate);
    startTime = new Date(checklistToEdit.ScheduleDto.ScheduleData.ScheduledDate);
  }
  if (dublicateChecklist) {
    endDate = null;
    endTime = null;
    startDate = null;
    startTime = null;
  }
  return {
    Type: checklistToEdit.IsDynamic ? 1 : 0,
    Name: checklistToEdit.Name,
    Repeat: repeat,
    RepeatCycle,
    Skip: skip,
    OwnerName: checklistToEdit.SupervisorId,
    EndDate: endDate,
    currentEndDate: endDate,
    StartDate: startDate,
    startTime,
    endTime,
    TeamName: checklistToEdit.TeamId,
    AssignmentType: returnAssignmentType(checklistToEdit.ChecklistAssignmentType, checklistToEdit.AssigneeID),
    PersonName: checklistToEdit.AssigneeID,
  };
};
