import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Container = styled(Flex).attrs({
  column: true,
  align: 'center',
  pb: 2,
})``;

export const WarningModalText = styled.div`
  text-align: center;
`;
