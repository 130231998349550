import { createSelector } from 'reselect';

const getAllTenants = tenants => tenants.dataTenants;
const getSearchValue = tenants => tenants.searchField.trim();

export const filterTenants = createSelector([getAllTenants, getSearchValue], (items, value) =>
  items.map(({ ...rest }) => ({
    ...rest,
    isVisible: rest.tenantName.toLowerCase().includes(value),
  })),
);

export const blank = () => {};
