import React from 'react';
import PropTypes from 'prop-types';

import theme from 'theme';
import { QueueItem } from 'configs/propTypes';
import EmptyList from 'components/EmptyList';
import UnitListTable from 'components/UnitListTable';
import { ListContainer } from 'components/Layout/Containers';
import { ItemInfo, ConditionalItemTitle } from './styled';

const emptyListMessage = 'There are no task activities';

const getItemName = item => (
  <ConditionalItemTitle isAssigned={item.AssigneeId === ''}>{item.AssigneeName}</ConditionalItemTitle>
);

const getItemText = (item, isAttentionTasks) => {
  if (isAttentionTasks) {
    return `${item.TasksRequireAttentionPastDue} tasks are past due`;
  }
  return `${item.TasksComplete} of ${item.TasksTotal} tasks finished (${item.TaskCompletePercent}% complete)`;
};

const renderRightText = item =>
  item.TasksRequireAttention ? (
    <ItemInfo>
      {`${item.TasksRequireAttention} task${item.TasksRequireAttention > 1 ? 's' : ''} require attention`}
    </ItemInfo>
  ) : null;

const List = ({
  onCommitUnit,
  onExportUnit,
  onUnitItemClick,
  goToDetailsPage,
  units,
  unitsNames,
  isAttentionTasks,
}) => (
  <ListContainer>
    {unitsNames.length ? (
      unitsNames.map(unit => (
        <UnitListTable
          key={unit}
          getItemName={getItemName}
          getItemText={item => getItemText(item, isAttentionTasks)}
          onCommitUnit={onCommitUnit}
          onExportUnit={onExportUnit}
          onUnitItemClick={onUnitItemClick}
          goToDetailsPage={goToDetailsPage}
          renderRightText={renderRightText}
          unitColor={theme.secondaryGreen}
          showDetailsButton
          unitData={units[unit]}
          unitName={unit}
          withCommitButton={!isAttentionTasks}
          withUnitControls
          withExport
        />
      ))
    ) : (
      <EmptyList text={emptyListMessage} />
    )}
  </ListContainer>
);

List.propTypes = {
  onCommitUnit: PropTypes.func.isRequired,
  onExportUnit: PropTypes.func.isRequired,
  onUnitItemClick: PropTypes.func.isRequired,
  goToDetailsPage: PropTypes.func.isRequired,
  isAttentionTasks: PropTypes.bool.isRequired,
  units: PropTypes.objectOf(PropTypes.arrayOf(QueueItem)).isRequired,
  unitsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default List;
