import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import AutoCompleteAdapter from 'components/Forms/AutoCompleteAdapter';
import withInputModal from 'components/Dialogs/withInputModal';
import { Block } from './styled';

const CopyToTenantForm = ({
  checkedSite,
  checkedTenant,
  clearFieldsForCopy,
  list,
  loadSite,
  requestSites,
  searchSiteText,
  searchTenantText,
  sites,
}) => (
  <Block>
    <Field
      key="Tenant"
      component={AutoCompleteAdapter}
      placeholderText="Select Tenant"
      name="Tenant"
      list={list}
      changeFunction={requestSites}
      selectedValue={checkedTenant}
      clearFieldsForCopy={clearFieldsForCopy}
      searchText={searchTenantText}
    />

    <Field
      key="Site"
      component={AutoCompleteAdapter}
      placeholderText="Select Site"
      name="Site"
      list={sites}
      changeFunction={loadSite}
      selectedValue={checkedSite}
      searchText={searchSiteText}
      disabled={!checkedTenant}
    />
  </Block>
);

const CopyToTenantWithInput = withInputModal(CopyToTenantForm);

CopyToTenantForm.defaultProps = {
  checkedSite: null,
  checkedTenant: null,
};

CopyToTenantForm.propTypes = {
  list: PropTypes.array.isRequired,
  requestSites: PropTypes.func.isRequired,
  checkedTenant: PropTypes.object,
  sites: PropTypes.array.isRequired,
  checkedSite: PropTypes.object,
  loadSite: PropTypes.func.isRequired,
  clearFieldsForCopy: PropTypes.func.isRequired,
  searchSiteText: PropTypes.string.isRequired,
  searchTenantText: PropTypes.string.isRequired,
};

const CopyToTenantDialog = ({
  list,
  requestSites,
  loadSite,
  checkedTenant,
  checkedSite,
  sites,
  clearFieldsForCopy,
  searchSiteText,
  searchTenantText,
  ...rest
}) => [
  <CopyToTenantWithInput
    key="CopyToTenantWithInput"
    list={list}
    requestSites={requestSites}
    loadSite={loadSite}
    checkedTenant={checkedTenant}
    checkedSite={checkedSite}
    sites={sites}
    clearFieldsForCopy={clearFieldsForCopy}
    searchSiteText={searchSiteText}
    searchTenantText={searchTenantText}
    {...rest}
  />,
];

CopyToTenantDialog.defaultProps = {
  handleDelete: () => {},
  // withDelete: false,
  // withSubmit: false,
  // withOnClick: true,
  checkedSite: null,
  checkedTenant: null,
  withDelete: false,
  withSubmit: true,
};

CopyToTenantDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
  list: PropTypes.array.isRequired,
  requestSites: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  clearFieldsForCopy: PropTypes.func.isRequired,
  searchSiteText: PropTypes.string.isRequired,
  searchTenantText: PropTypes.string.isRequired,
};

export default CopyToTenantDialog;
