import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { UnitItemPictureType, UnitPictureType } from 'configs/propTypes';
import BaseDialog from 'components/Dialogs/Base';
import SimpleList from 'components/SimpleList';
import { ListContainer } from './styled';

const renderItemContent = photo => photo.Name || 'No name image';

class PhotosDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    photos: PropTypes.arrayOf(PropTypes.oneOfType([UnitItemPictureType, UnitPictureType])).isRequired,
  };

  render() {
    const { handleClose, open, photos } = this.props;

    return (
      <BaseDialog onRequestClose={handleClose} open={open} title="Uploaded Photos">
        <ListContainer>
          <SimpleList emptyListMessage="There are no photos." data={photos} renderItemContent={renderItemContent} />
        </ListContainer>
      </BaseDialog>
    );
  }
}

export default PhotosDialog;
