import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import {
  duplicateItem,
  fetchDefaultBarcode,
  fetchUnit,
  fetchUnitItemPdfs,
  fetchUnitItemPictures,
  fetchUnitItems,
  fetchUnitItemsTasks,
  fetchUnitItemTaskTemplates,
  fetchUnitPdfs,
  fetchUnitPictures,
  postUnitItem,
  postUnitItemPdf,
  postUnitItemPicture,
  postUnitPdf,
  postUnitPicture,
  putUnit,
  putUnitItem,
  removeTaskTemplate,
  removeUnit,
  removeUnitItem,
  removeUnitItemPdf,
  removeUnitItemPicture,
  removeUnitPdf,
  removeUnitPicture,
  updateUnitItemsSequence,
} from 'http/unit';
import { editTask, addTask } from 'http/item';
import { formatServerError } from 'helpers';

import { actions as itemActions } from '../item';
import { getSelectedSite } from '../../helpers/storage';
import { fetchUnitsList } from '../units/sagas';
import { formatUnitItem } from './formatters';
import { getSiteId, getUnitId } from './selectors';
import { actions } from './index';

function* getUnitPictures({ payload: { unitId } }) {
  try {
    const { data } = yield call(fetchUnitPictures, unitId);

    yield put(actions.deleteUnitPictureSuccess(data.root));
  } catch (error) {
    yield put(actions.fetchUnitItemsFailure(error));
  }
}

export function* fetchItemsForUnit({ payload: { unitId, SiteID } }) {
  try {
    let siteFromStore;
    if (!SiteID) {
      siteFromStore = yield select(getSiteId);
    }
    const { data } = yield call(fetchUnitItems, unitId, SiteID || siteFromStore);

    yield put(actions.fetchUnitItemsSuccess(data.root));
  } catch (error) {
    yield put(actions.fetchUnitItemsFailure(error));
  }
}

function* fetchUnitData({ payload: unitId }) {
  try {
    const SiteID = yield select(getSiteId);
    const unitDataRequests = {
      items: call(fetchUnitItems, unitId, SiteID),
      pdfs: call(fetchUnitPdfs, unitId),
      pictures: call(fetchUnitPictures, unitId),
      unit: call(fetchUnit, unitId),
    };
    const { items, pdfs, pictures, unit } = yield all(unitDataRequests);
    const payload = {
      unitData: unit.data.entity,
      unitItemsList: items.data.root,
      unitPdfsList: pdfs.data.root,
      unitPicturesList: pictures.data.root,
    };
    yield put(actions.unitDataFetchSuccess(payload));
  } catch (error) {
    yield put(actions.unitDataFetchFailure(error));
  }
}

function* addTaskTemplate({ payload: { itemId, taskData } }) {
  try {
    yield call(addTask, itemId, taskData);
    const { data } = yield call(fetchUnitItemTaskTemplates, itemId);
    yield put(actions.addUnitTaskSuccess({ itemId, tasks: data.root }));
  } catch (error) {
    yield put(actions.addUnitTaskFailure(error.response.data));
  }
}

function* addUnitItem({ payload }) {
  try {
    const unitId = yield select(getUnitId);
    const SiteID = yield select(getSiteId);
    const params = formatUnitItem(payload, unitId);
    const {
      data: { entity },
    } = yield call(postUnitItem, params);
    const { data } = yield call(fetchUnitItems, unitId, SiteID);
    yield put(actions.addUnitItemSuccess({ itemId: entity.Id, itemsList: data.root }));
  } catch (error) {
    yield put(
      actions.addUnitItemFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Item.'),
      }),
    );
  }
}

function* deleteTaskTemplate({ payload: { itemId, taskId } }) {
  try {
    yield call(removeTaskTemplate, taskId, itemId);
    const { data } = yield call(fetchUnitItemTaskTemplates, itemId);
    yield put(actions.deleteUnitTaskSuccess({ itemId, tasks: data.root }));
  } catch (error) {
    yield put(actions.deleteUnitTaskFailure(error.response.data));
  }
}

function* deleteUnit({ payload }) {
  try {
    yield call(removeUnit, payload);
    yield put(actions.deleteUnitSuccess());
    yield* fetchUnitsList({ payload: { filter: { SiteID: getSelectedSite() } } });
  } catch (error) {
    yield put(actions.deleteUnitFailure(error.response.data));
  }
}

function* deleteUnitItem({ payload }) {
  try {
    const unitId = yield select(getUnitId);
    const SiteID = yield select(getSiteId);
    yield call(removeUnitItem, payload, unitId);
    const { data } = yield call(fetchUnitItems, unitId, SiteID);
    yield put(actions.deleteUnitItemSuccess(data.root));
  } catch (error) {
    yield put(actions.deleteUnitItemFailure(error.response.data));
  }
}

function* deleteUnitItemPdf({ payload: { pdfId, pdfName, itemId } }) {
  try {
    yield call(removeUnitItemPdf, pdfId, pdfName, itemId);
    const { data } = yield call(fetchUnitItemPdfs, itemId);
    yield put(actions.deleteUnitItemPdfSuccess({ itemId, pdfs: data.root }));
  } catch (error) {
    yield put(actions.deleteUnitItemPdfFailure({ error, message: 'An error occurred while deleting the Item PDF.' }));
  }
}

function* deleteUnitItemPicture({ payload }) {
  try {
    const { itemId, pictureId, pictureName } = payload;
    yield call(removeUnitItemPicture, pictureId, pictureName, itemId);
    const { data } = yield call(fetchUnitItemPictures, itemId);
    yield put(actions.deleteUnitItemPictureSuccess({ itemId, pictures: data.root }));
  } catch (error) {
    yield put(
      actions.deleteUnitItemPictureFailure({ error, message: 'An error occurred while deleting the Item Picture.' }),
    );
  }
}

function* getUnitPdf({ payload: { unitId } }) {
  try {
    // const unitId = yield select(getUnitId);
    const { data } = yield call(fetchUnitPdfs, unitId);
    yield put(actions.deleteUnitPdfSuccess(data.root));
  } catch (error) {
    yield put(actions.deleteUnitPdfFailure({ error, message: 'An error occurred while deleting the Unit PDF.' }));
  }
}

function* deleteUnitPdf({ payload: { pdfId, pdfName } }) {
  try {
    const unitId = yield select(getUnitId);
    yield call(removeUnitPdf, pdfId, pdfName, unitId);
    const { data } = yield call(fetchUnitPdfs, unitId);
    yield put(actions.deleteUnitPdfSuccess(data.root));
  } catch (error) {
    yield put(actions.deleteUnitPdfFailure({ error, message: 'An error occurred while deleting the Unit PDF.' }));
  }
}

function* deleteUnitPicture({ payload: { pictureId, pictureName } }) {
  try {
    const unitId = yield select(getUnitId);
    yield call(removeUnitPicture, pictureId, pictureName, unitId);
    const { data } = yield call(fetchUnitPictures, unitId);
    yield put(actions.deleteUnitPictureSuccess(data.root));
  } catch (error) {
    yield put(
      actions.deleteUnitPictureFailure({ error, message: 'An error occurred while deleting the Unit Picture.' }),
    );
  }
}

function* duplicateUnitItem({ payload: { Id, UnitId, NewName, NewSequence = 0 } }) {
  try {
    const encodedName = encodeURIComponent(NewName);
    yield call(duplicateItem, Id, UnitId, encodedName, NewSequence);
    yield* fetchUnitData({ payload: UnitId });
  } catch (error) {
    yield put(
      actions.deleteUnitPictureFailure({
        error,
        message: error.response?.data?.error || 'An error occurred while duplicating the Item.',
      }),
    );
  }
}

function* editTaskTemplate({ payload: { itemId, taskData } }) {
  try {
    yield call(editTask, itemId, taskData);
    const { data } = yield call(fetchUnitItemTaskTemplates, itemId);
    yield put(actions.editUnitTaskSuccess({ itemId, tasks: data.root }));
  } catch (error) {
    yield put(actions.editUnitTaskFailure({ error, message: 'An error occurred while saving the Task.' }));
  }
}

function* fetchUnitSaga({ payload }) {
  try {
    const { data } = yield call(fetchUnit, payload);
    yield put(actions.fetchUnitSuccess(data.entity));
  } catch (err) {
    if (err.isAxiosError) {
      yield put(actions.fetchUnitFailure(err.response.data.error));
    } else {
      yield put(actions.fetchUnitFailure(err.message));
    }
  }
}

function* editUnit({ payload: { params, closeEditUnitDialog } }) {
  try {
    const { data } = yield call(putUnit, params);
    yield* fetchUnitsList({ payload: { filter: { SiteID: getSelectedSite() } } });
    yield put(actions.editUnitSuccess(data.entity));
    closeEditUnitDialog();
  } catch (error) {
    yield put(actions.editUnitFailure({ error, message: 'An error occurred while saving the Unit.' }));
  }
}

function* editUnitItem({ payload }) {
  try {
    const unitId = yield select(getUnitId);
    const SiteID = yield select(getSiteId);
    const params = formatUnitItem(payload, unitId);
    yield call(putUnitItem, payload, params);
    const { data } = yield call(fetchUnitItems, unitId, SiteID);
    yield put(actions.editUnitItemSuccess(data.root));
  } catch (error) {
    yield put(
      actions.editUnitItemFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Item.'),
      }),
    );
  }
}

function* uploadUnitItemPdf(payload) {
  const { files, itemId } = payload.payload;
  try {
    const params = new FormData();
    params.append('file', files[0]);
    params.append('parentId', itemId);
    params.append('dto.ItemId', itemId);
    yield call(postUnitItemPdf, itemId, params);
    const { data } = yield call(fetchUnitItemPdfs, itemId);
    yield put(actions.uploadUnitItemPdfSuccess({ itemId, pdfs: data.root }));
  } catch (error) {
    yield put(actions.uploadUnitItemPdfFailure({ error, message: 'An error occurred while saving the Unit Pdf.' }));
  }
}

function* uploadUnitItemPicture({ payload: { files, itemId } }) {
  try {
    const params = new FormData();
    params.append('file', files[0]);
    params.append('parentId', itemId);
    params.append('dto.ItemId', itemId);
    yield call(postUnitItemPicture, itemId, params);
    const { data } = yield call(fetchUnitItemPictures, itemId);
    yield put(actions.uploadUnitItemPictureSuccess({ itemId, pictures: data.root }));
  } catch (error) {
    yield put(
      actions.uploadUnitItemPictureFailure({ error, message: 'An error occurred while saving the Item Picture.' }),
    );
  }
}

function* uploadUnitPdf({ payload }) {
  try {
    const unitId = yield select(getUnitId);
    const params = new FormData();
    params.append('file', payload[0]);
    params.append('parentId', unitId);
    params.append('dto.UnitId', unitId);
    yield call(postUnitPdf, unitId, params);
    const { data } = yield call(fetchUnitPdfs, unitId);
    yield put(actions.uploadUnitPdfSuccess(data.root));
  } catch (error) {
    yield put(actions.uploadUnitPdfFailure({ error, message: 'An error occurred while saving the Unit Pdf.' }));
  }
}

function* uploadUnitPicture({ payload }) {
  try {
    const unitId = yield select(getUnitId);
    const params = new FormData();
    params.append('file', payload[0]);
    params.append('parentId', unitId);
    params.append('dto.UnitId', unitId);
    yield call(postUnitPicture, unitId, params);
    const { data } = yield call(fetchUnitPictures, unitId);
    yield put(actions.uploadUnitPictureSuccess(data.root));
  } catch (error) {
    yield put(actions.uploadUnitPictureFailure({ error, message: 'An error occurred while saving the Unit Picture.' }));
  }
}

function* getDefaultBarcode({ payload: { params, reset } }) {
  try {
    const { data } = yield call(fetchDefaultBarcode, params);
    yield put(actions.getDefaultBarcodeSuccess(data.entity));
    reset(data.entity);
  } catch (error) {
    yield put(actions.getDefaultBarcodeFailure(error));
  }
}

function* getUnitItemsTasks({ payload: { Id } }) {
  try {
    const { data } = yield call(fetchUnitItemsTasks, Id);
    yield put(actions.getUnitItemsTasksSuccess(data.root));
  } catch (error) {
    yield put(actions.getUnitItemsTasksFailure(error));
  }
}

function* updateUnitItemsTasks({ payload: { values, itemId } }) {
  try {
    const { data } = yield call(updateUnitItemsSequence, values);
    if (itemId) {
      yield put(itemActions.fetchItemTasksRequest(itemId));
    }
    yield put(actions.updateTasksListSuccess(data));
  } catch (error) {
    yield put(actions.updateTasksListFailure(error));
  }
}

const unitSagas = [
  takeEvery(actions.addUnitTaskRequest, addTaskTemplate),
  takeEvery(actions.fetchUnitRequest, fetchUnitSaga),
  takeEvery(actions.addUnitItemRequest, addUnitItem),
  takeEvery(actions.deleteUnitTaskRequest, deleteTaskTemplate),
  takeEvery(actions.deleteUnitRequest, deleteUnit),
  takeEvery(actions.deleteUnitItemRequest, deleteUnitItem),
  takeEvery(actions.deleteUnitItemPdfRequest, deleteUnitItemPdf),
  takeEvery(actions.deleteUnitItemPictureRequest, deleteUnitItemPicture),
  takeEvery(actions.deleteUnitPdfRequest, deleteUnitPdf),
  takeEvery(actions.deleteUnitPictureRequest, deleteUnitPicture),
  takeEvery(actions.duplicateUnitItemRequest, duplicateUnitItem),
  takeEvery(actions.editUnitTaskRequest, editTaskTemplate),
  takeEvery(actions.editUnitRequest, editUnit),
  takeEvery(actions.editUnitItemRequest, editUnitItem),
  takeEvery(actions.unitDataFetchRequest, fetchUnitData),
  takeEvery(actions.uploadUnitItemPdfRequest, uploadUnitItemPdf),
  takeEvery(actions.uploadUnitItemPictureRequest, uploadUnitItemPicture),
  takeEvery(actions.uploadUnitPdfRequest, uploadUnitPdf),
  takeEvery(actions.uploadUnitPictureRequest, uploadUnitPicture),
  takeEvery(actions.fetchUnitItemsRequest, fetchItemsForUnit),
  takeEvery(actions.getDefaultBarcodeRequest, getDefaultBarcode),
  takeEvery(actions.getUnitItemsTasksRequest, getUnitItemsTasks),
  takeEvery(actions.updateTasksListRequest, updateUnitItemsTasks),
  takeEvery(actions.getUnitPicturesRequest, getUnitPictures),
  takeEvery(actions.getUnitPdfRequest, getUnitPdf),
];

export default unitSagas;
