import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

import { REMOVAL_TYPES } from '../config';

const ManagerStatsDialog = ({
  open,
  handleClose,
  type,
  name,
  onSubmit,
  data: { SitesCount, TeamsCount, CompletedTasksCount, ActiveTasksCount, RequiredAttentionCount },
  teamData: { TeamLeadCount },
}) => {
  const handleSubmit = () => {
    onSubmit(TeamLeadCount);
  };
  return (
    <>
      <Dialog open={open} onRequestClose={handleClose} title="Alert" titleColor={theme.mainRed}>
        <S.Container>
          <S.WarningsContainer>
            <S.UserNameText>{name}</S.UserNameText>
            <S.WarningText>
              {type === REMOVAL_TYPES.REMOVE ? 'Removing' : 'Deactivating'} this person may disrupt tasks in progress!
            </S.WarningText>
          </S.WarningsContainer>
          <S.CardsContainer>
            <S.Card>
              <S.CardHeader>Status</S.CardHeader>
              <S.CardContent>
                <S.Row>
                  <S.Value>This user is part of:</S.Value>
                </S.Row>
                <S.Row>
                  <S.Value>{SitesCount}</S.Value>
                  <S.Label>Sites</S.Label>
                </S.Row>
                <S.Row>
                  <S.Value>{TeamsCount}</S.Value>
                  <S.Label>Teams</S.Label>
                </S.Row>
                <S.Row>
                  <S.Value>This user leads:</S.Value>
                </S.Row>
                <S.RedRow>
                  <S.Value>{TeamLeadCount}</S.Value>
                  <S.Label>Teams</S.Label>
                </S.RedRow>
              </S.CardContent>
            </S.Card>
            <S.Card>
              <S.CardHeader>Personal Tasks Status</S.CardHeader>
              <S.CardContent>
                <S.Row>
                  <S.Value>{ActiveTasksCount}</S.Value>
                  <S.Label>Personal tasks are in progress</S.Label>
                </S.Row>
                <S.Row>
                  <S.Value>{CompletedTasksCount}</S.Value>
                  <S.Label>Personal tasks are completed but not archived</S.Label>
                </S.Row>
                <S.RedRow>
                  <S.Value>{RequiredAttentionCount}</S.Value>
                  <S.Label>Personal tasks require attention</S.Label>
                </S.RedRow>
              </S.CardContent>
            </S.Card>
          </S.CardsContainer>
          <ActionsContainer>
            <ActionButton label="Next" isNext onClick={handleSubmit} />
          </ActionsContainer>
        </S.Container>
      </Dialog>
    </>
  );
};

ManagerStatsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(REMOVAL_TYPES)).isRequired,
  data: PropTypes.shape({
    SitesCount: PropTypes.number.isRequired,
    TeamsCount: PropTypes.number.isRequired,
    CompletedTasksCount: PropTypes.number.isRequired,
    ActiveTasksCount: PropTypes.number.isRequired,
    RequiredAttentionCount: PropTypes.number.isRequired,
  }).isRequired,
  teamData: PropTypes.shape({
    TeamLeadCount: PropTypes.number.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
};

export default ManagerStatsDialog;
