import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FlatButton } from 'material-ui';
import { Field, Form } from 'react-final-form';
import { useHistory, withRouter } from 'react-router-dom';

import BaseDialog from 'components/Dialogs/Base';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { UnfoldMoreIcon, ReplayIcon } from 'components/Layout/Icons';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { actions as tutorialsActions } from 'redux/tutorials';

import * as S from './styled';

const VimeoAccountSetupDialog = ({ open, handleClose }) => {
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const openConfirmationDialog = () => setIsConfirmationOpened(true);

  const closeConfirmationDialog = () => setIsConfirmationOpened(false);

  const refreshCache = () => {
    dispatch(tutorialsActions.refreshVimeoCacheRequest());
    openConfirmationDialog();
  };

  const goToTutorials = () => history.push('/tutorials');

  return (
    <BaseDialog
      open={open}
      onRequestClose={handleClose}
      title="Vimeo Account Setup"
      modalStyles={{ maxWidth: '550px' }}
    >
      <Form
        onSubmit={goToTutorials}
        render={({ handleSubmit }) => (
          <S.Form onSubmit={handleSubmit}>
            <p>
              Tutorial Videos will be made available <br /> through this Vimeo account:
            </p>
            <S.Container>
              <S.Label>API Cache Settings</S.Label>
              <S.Wrapper>
                <Field
                  component={S.Textarea}
                  name="cache"
                  defaultValue="24h"
                  disabled
                  floatingLabelText="Vimeo API Cache"
                />
                <S.SecondaryBlock>
                  <FlatButton
                    label="Hours"
                    labelPosition="after"
                    icon={<UnfoldMoreIcon />}
                    disabled
                    labelStyle={{
                      ...S.LabelStyle,
                      marginRight: '60px',
                    }}
                  />
                  <FlatButton
                    label="Refresh cache"
                    onClick={refreshCache}
                    labelPosition="after"
                    icon={<ReplayIcon />}
                    labelStyle={S.LabelStyle}
                  />
                </S.SecondaryBlock>
              </S.Wrapper>
            </S.Container>
            <ActionsContainer gapValue={10}>
              <ActionButton type="submit" isNext label="Go To Tutorials" />
            </ActionsContainer>
          </S.Form>
        )}
      />
      <ConfirmationDialog
        title="Alert"
        headingText="This operation might take a while and will be completed in the background."
        open={isConfirmationOpened}
        handleClose={closeConfirmationDialog}
      />
    </BaseDialog>
  );
};

VimeoAccountSetupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withRouter(VimeoAccountSetupDialog);
