import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as OneSignal from 'services/OneSignal';

import SiteSelector from 'components/SiteSelector';
import SiteSelectionDialog from 'components/Dialogs/SiteSelectionDialog';

import { actions as authActions } from 'redux/auth';
import { actions as tasksActions } from 'redux/tasks';
import { actions as queueActions } from 'redux/queue';
import { actions as healthActions } from 'redux/health';
import { actions as tenantsActions } from 'redux/tenants';
import { actions as settingsActions } from 'redux/settings';

import { USER_TYPES } from 'configs/enums';

import ColumnModules from './ColumnModules';
import RowModules from './RowModules';

import * as S from './styled';
import { FORCE_LOGOUT_KEY, getForceLogout } from '../../../../helpers/storage';

class Home extends Component {
  static defaultProps = {
    tenantName: '',
    attentionTasks: null,
  };

  state = {
    isSiteSelectionDialogOpen: false,
  };

  componentDidMount() {
    const { action, userType, userId } = this.props;

    if (process.env.REACT_APP_ONESIGNAL_APP_ID) {
      OneSignal.init(userId);
    }

    action.resetTasksRangeStart();
    action.requestTenantRequestsStatistics();

    if (userType !== USER_TYPES.SystemController) {
      action.fetchSites();
    }

    if (
      userType !== USER_TYPES.SystemController &&
      userType !== USER_TYPES.TaskSpecialist &&
      userType !== USER_TYPES.OEMAdministrator &&
      userType !== USER_TYPES.OEMManager &&
      userType !== USER_TYPES.DashboardUser
    ) {
      action.getItemsWithAlertRequest();
      action.requestAttentionTasks();
    }

    if (userType === USER_TYPES.DashboardUser) {
      action.getItemsWithAlertRequest();
    }

    window.addEventListener('storage', e => {
      if (e.key === FORCE_LOGOUT_KEY && getForceLogout()) {
        action.onLogout();
      }
    });
  }

  componentDidUpdate(prevprops) {
    const { action, selectedSite, userType } = this.props;
    if (
      prevprops.selectedSite !== selectedSite &&
      userType !== USER_TYPES.SystemController &&
      userType !== USER_TYPES.TaskSpecialist &&
      userType !== USER_TYPES.OEMAdministrator &&
      userType !== USER_TYPES.OEMManager
    ) {
      action.requestAttentionTasks();
    }
  }

  openSiteSelectionDialog = () => this.setState({ isSiteSelectionDialogOpen: true });

  closeSiteSelectionDialog = () => this.setState({ isSiteSelectionDialogOpen: false });

  toActivators = () => {
    this.props.history.push('/activator');
  };

  render() {
    const { selectedSite, siteName, tenantName, userType, totalCount, attentionTasks, tenantRequestsStatistics } =
      this.props;
    const { isSiteSelectionDialogOpen } = this.state;
    const isRoleWithoutActivatorPage = userType === USER_TYPES.TaskSpecialist;
    const isSystemController = userType === USER_TYPES.SystemController;

    return (
      <S.Container>
        <S.Header>{tenantName}</S.Header>
        <RowModules userType={userType} totalCount={totalCount} />
        {userType !== USER_TYPES.DashboardUser && <S.SiteTitle>{siteName}</S.SiteTitle>}
        {userType !== USER_TYPES.DashboardUser && (
          <S.ColumnContainer>
            <SiteSelector onClick={this.openSiteSelectionDialog} />
            <SiteSelectionDialog isOpen={isSiteSelectionDialogOpen} onClose={this.closeSiteSelectionDialog} />
            {selectedSite || isSystemController || isRoleWithoutActivatorPage ? (
              <ColumnModules
                userType={userType}
                attentionTasks={attentionTasks}
                tenantRequestsStatistics={tenantRequestsStatistics}
              />
            ) : (
              this.toActivators()
            )}
          </S.ColumnContainer>
        )}
      </S.Container>
    );
  }
}

const mapStateToProps = ({ auth, health, queue, tenants }) => ({
  selectedSite: auth.selectedSite,
  siteName: auth.siteName,
  tenantName: auth.user.tenantName,
  userType: auth.user.userType,
  user: auth.user,
  totalCount: health.notFavoriteAlerts,
  attentionTasks: queue.attentionTasks,
  userId: auth.user.userId,
  tenantRequestsStatistics: tenants.tenantRequestsStatistics,
  industryList: tenants.industryList,
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      fetchSites: authActions.fetchUserSitesRequest,
      resetTasksRangeStart: tasksActions.resetRangeStart,
      onLogout: authActions.logoutRequest,
      authenticate: authActions.authenticateRequest,
      getItemsWithAlertRequest: healthActions.getItemsWithAlertRequest,
      requestAttentionTasks: queueActions.fetchAttentionTasksRequest,
      requestTenantRequestsStatistics: tenantsActions.tenantRequestsStatisticsRequest,
      requestUpgradeInfo: settingsActions.upgradeInfoRequest,
      onChange: authActions.onSiteChange,
    },
    dispatch,
  ),
});

Home.propTypes = {
  action: PropTypes.shape({
    fetchSites: PropTypes.func.isRequired,
    resetTasksRangeStart: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    getItemsWithAlertRequest: PropTypes.func.isRequired,
    requestAttentionTasks: PropTypes.func.isRequired,
    requestTenantRequestsStatistics: PropTypes.func.isRequired,
    requestUpgradeInfo: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  selectedSite: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  userType: PropTypes.number.isRequired,
  tenantName: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  attentionTasks: PropTypes.number,
  userId: PropTypes.string.isRequired,
  tenantRequestsStatistics: PropTypes.shape({
    NewTenantRequestCount: PropTypes.number,
    UpgradeTenantRequestCount: PropTypes.number,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
