import queryString from 'query-string';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { capitalizeFirstLetter, parseServerError } from 'helpers';
import { ERROR_DIALOG_OPENED } from 'redux/errorHandler';
import {
  activateChecklistActions,
  checklistAssignEmployee,
  createSchedule,
  createTaskTemplate,
  deactivateChecklistActions,
  deleteTaskTemplate,
  fetchTaskStatus,
  fetchUserManagement,
  getChecklistActivation,
  getChecklistRunNextPeriod,
  getChecklistStatus,
  getCurrentUnit,
  getItemTemplates,
  getMissingTasks,
  getScheduleAssignableUsers,
  getScheduleDetails,
  getSchedulesList,
  getScheduleTemplates,
  getSwitchAutorun,
  getTaskAssignableUsers,
  getUnitItems,
  putChecklist,
  putChecklistSchedule,
  removeSchedule,
  setChecklistSupervisor,
  setTimeFrame,
  getSchedulerStatistics,
} from 'http/schedule';

import { actions } from './index';
import { divideTasksByUnitName, formatDateParams } from './formatters';
import { getSiteId } from '../unit/selectors';
import { getSchedulerType } from 'helpers/storage';

function* fetchSchedulesList(action) {
  try {
    const { unitId } = action.payload;
    const schedulerType = getSchedulerType();
    const checklistType = capitalizeFirstLetter(schedulerType);
    const filter = { checklistType };

    const { data } = yield call(getSchedulesList, { unitId, filter });

    const schedulesList = data.root;
    yield put(actions.schedulesListSuccess(schedulesList));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.schedulesListFailure(error));
  }
}

function* fetchCurrentUnit(action) {
  try {
    const { unitId } = action.payload;
    const { data } = yield call(getCurrentUnit, unitId);
    yield put(actions.fetchCurrentUnitSuccess(data.entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchCurrentUnitFailure(error));
  }
}

function* fetchChecklistStatus(action) {
  try {
    const { checklistId } = action.payload;
    const params = queryString.stringify({ checklistId });
    const { data } = yield call(getChecklistStatus, params);
    yield put(actions.checklistStatusSuccess(data.entity.Dto));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.checklistStatusFailure(error));
  }
}

function* setCheklistTimeFrame(action) {
  try {
    const { endDate, checklistId, ignoreLeadTime } = action.payload;
    const params = queryString.stringify({
      checklistId,
      endDate,
      ignoreLeadTime,
    });
    const { data } = yield call(setTimeFrame, params);
    yield put(actions.addChecklistTimeframeSuccess(data.entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.addChecklistTimeframeFailure(error));
  }
}

function* fetchMissingTasks(action) {
  try {
    const { checklistId, unitId } = action.payload;
    const params = queryString.stringify({ checklistId, unitId });
    const { data } = yield call(getMissingTasks, params);
    yield put(actions.missingTasksListSuccess(data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.missingTasksListFailure(error));
  }
}

function* fetchUnitItems(action) {
  try {
    const { unitId, siteId } = action.payload;
    const SiteID = yield select(getSiteId);
    const items = yield call(getUnitItems, unitId, siteId || SiteID);
    yield put(actions.fetchScheduleUnitItemsSuccess(items.data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchScheduleUnitItemsFailure(error));
  }
}

function* addSchedule(action) {
  try {
    const { dataToSend, unitId, taskTemplates, onSuccess, currentChecklist, ignoreLeadTime } = action.payload;
    const params = {
      assignee:
        currentChecklist.AssignmentType === 2
          ? {
              Id: currentChecklist.PersonName,
            }
          : null,
      dto: dataToSend,
      taskTemplates,
      unitId,
      ignoreLeadTime,
    };
    const { data } = yield call(createSchedule, params);
    yield put(actions.addScheduleSuccess(data.entity));
    onSuccess();
    yield put(actions.schedulesListRequest({ unitId }));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.addScheduleFailure(error));
  }
}

function* fetchScheduleDetails(action) {
  try {
    const { scheduleId } = action.payload;
    const { data } = yield call(getScheduleDetails, scheduleId);
    yield put(actions.fetchScheduleDetailsSuccess(data.entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchScheduleDetailsFailure(error));
  }
}

function* fetchChecklistActivation(action) {
  try {
    const { unitId, id, url } = action.payload;
    const { data } = yield call(getChecklistActivation, url, unitId, id);
    yield put(actions.fetchChecklistActivationSuccess(data.entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchChecklistActivationFailure(error));
  }
}

function* fetchSwitchAutorun(action) {
  try {
    const { isStart, scheduleId: checklistId, ignoreLeadTime } = action.payload;
    const { data } = yield call(getSwitchAutorun, checklistId, isStart, ignoreLeadTime);
    yield put(actions.fetchSwitchAutorunSuccess(data.entity));
  } catch (error) {
    yield put(actions.fetchSwitchAutorunFailure(error));
  }
}

function* fetchChecklistRunNextPeriod(action) {
  try {
    const { scheduleId: checklistId, ignoreLeadTime } = action.payload;
    const { data } = yield call(getChecklistRunNextPeriod, checklistId, ignoreLeadTime);
    yield put(actions.fetchChecklistRunNextPeriodSuccess(data.entity));
  } catch (error) {
    yield put(actions.fetchChecklistRunNextPeriodFailure(error));
  }
}

function* getUserManagement(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(fetchUserManagement, id);
    yield put(actions.getUserManagementSuccess(data));
  } catch (error) {
    yield put(actions.getUserManagementSuccess('none'));
    yield put(actions.getUserManagementFailure(error));
  }
}

function* fetchScheduleAssignableUsers() {
  try {
    const params = queryString.stringify({
      page: 1,
      start: 0,
      limit: 25,
    });
    const { data } = yield call(getScheduleAssignableUsers, params);
    yield put(actions.fetchScheduleAssignableUsersSuccess(data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchScheduleAssignableUsersFailure(error));
  }
}

function* setSupervisor(action) {
  try {
    const { unitId, userId, scheduleId } = action.payload;
    const params = queryString.stringify({ unitId, userId });
    const { data } = yield call(setChecklistSupervisor, scheduleId, params);
    yield put(actions.setSupervisorSuccess());
    yield put(actions.fetchScheduleDetailsSuccess(data.entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setSupervisorFailure(error));
  }
}

function* editSchedule(action) {
  try {
    const { dataToSend, unitId, taskTemplates, currentChecklist, ignoreLeadTime, isAssignmentChanged, onSuccess } =
      action.payload;

    const params = {
      assignee:
        currentChecklist?.AssignmentType === 2
          ? {
              Id: currentChecklist?.PersonName,
            }
          : null,
      dto: dataToSend,
      taskTemplates,
      unitId,
      isAssignmentChanged,
      ignoreLeadTime,
    };
    const { data } = yield call(putChecklist, dataToSend, params);
    yield put(actions.editScheduleSuccess(data.entity));
    onSuccess();
    yield put(actions.schedulesListRequest({ unitId }));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.editScheduleFailure(error));
  }
}

function* changeSchedule(action) {
  try {
    const { scheduleId, unitId, scheduleInfo, closeModal } = action.payload;
    const params = {
      unitId,
      checklistId: scheduleId,
      'dto.ScheduleType': scheduleInfo.ScheduleType,
    };
    const formattedScheduleDate = formatDateParams(scheduleInfo.ScheduleData);
    const formattedKeys = Object.keys(formattedScheduleDate);
    formattedKeys.forEach(item => {
      params[`dto.ScheduleData.${item}`] = formattedScheduleDate[item];
    });
    yield call(putChecklistSchedule, params);
    yield put(actions.changeScheduleSuccess());
    yield put(actions.fetchScheduleDetailsRequest({ scheduleId }));
    closeModal();
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.changeScheduleFailure(error));
  }
}

function* deleteSchedule(action) {
  try {
    const { scheduleId, unitId } = action.payload;
    const params = queryString.stringify({
      id: scheduleId,
      overrideSafetyGuard: true,
    });
    yield call(removeSchedule, params);
    yield put(actions.deleteScheduleSuccess());
    yield put(actions.schedulesListRequest({ unitId }));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.deleteScheduleFailure(error));
  }
}

function* changeScheduleStatus(action) {
  try {
    const { scheduleId, unitId, status } = action.payload;
    const params = queryString.stringify({ unitId });
    if (status === 'Active') {
      const { data } = yield call(deactivateChecklistActions, scheduleId, params);
      yield put(actions.changeScheduleStatusSuccess());
      yield put(actions.fetchScheduleDetailsSuccess(data.entity));
    }
    if (status === 'PendingActivation') {
      const { data } = yield call(activateChecklistActions, scheduleId, params);
      yield put(actions.changeScheduleStatusSuccess());
      yield put(actions.fetchScheduleDetailsSuccess(data.entity));
    }
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.changeScheduleStatusFailure(error));
  }
}

function* fetchTaskAssignableUsers() {
  try {
    const params = queryString.stringify({
      page: 1,
      start: 0,
      limit: 25,
    });
    const { data } = yield call(getTaskAssignableUsers, params);
    yield put(actions.fetchTaskAssignableUsersSuccess(data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchTaskAssignableUsersFailure(error));
  }
}

function* fetchScheduleTemplates(action) {
  try {
    const { scheduleId } = action.payload;
    const params = queryString.stringify({
      checklistId: scheduleId,
    });
    const { data } = yield call(getScheduleTemplates, params);
    const { tasksByUnitName, tasksIds } = divideTasksByUnitName(data.root);
    yield put(actions.fetchScheduleTemplatesSuccess(tasksByUnitName));
    yield put(actions.fetchScheduleTemplatesAmount(tasksIds));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchScheduleTemplatesFailure(error));
  }
}

function* fetchItemTemplates(action) {
  try {
    const { itemId, scheduleId } = action.payload;
    const params = queryString.stringify({
      itemId,
      'filter.ExcludeTasksFromChecklistId': scheduleId,
      page: 1,
      start: 0,
      limit: 25,
    });
    const { data } = yield call(getItemTemplates, params);
    yield put(actions.fetchItemTemplatesSuccess(data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.fetchItemTemplatesFailure(error));
  }
}

function* removeTaskTemplate(action) {
  try {
    const { templateIds, scheduleId } = action.payload;
    yield call(deleteTaskTemplate, templateIds);
    yield put(actions.removeTaskTemplateSuccess());
    yield put(actions.clearTaskStatus());
    yield put(actions.fetchScheduleTemplatesRequest({ scheduleId }));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.removeTaskTemplateFailure(error));
  }
}

function* getTaskStatus(action) {
  try {
    const { templateIds } = action.payload;
    const params = { ids: templateIds };
    const { data } = yield call(fetchTaskStatus, params);
    yield put(actions.taskStatusSuccess(data.entity.Dto));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.taskStatusFailure(error));
  }
}

function* addTaskTemplate(action) {
  try {
    const { templateId, scheduleId } = action.payload;
    const params = queryString.stringify({
      checklistId: scheduleId,
      taskTemplateId: templateId,
    });
    yield call(createTaskTemplate, params);
    yield put(actions.removeTaskTemplateSuccess());
    yield put(actions.fetchScheduleTemplatesRequest({ scheduleId }));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.removeTaskTemplateFailure(error));
  }
}

function* assignEmployee(action) {
  try {
    const { ids, scheduleId, userId, IsOpenStatus, shouldActiveteSchedule, unitId } = action.payload;
    const params = queryString.stringify({
      checklistId: scheduleId,
      idsJson: JSON.stringify(ids),
      userId,
      IsOpenStatus,
    });
    yield call(checklistAssignEmployee, params);
    if (shouldActiveteSchedule) {
      yield put(actions.changeScheduleStatusRequest({ status: 'PendingActivation', unitId, scheduleId }));
    }
    yield put(actions.assignEmployeeSuccess());
    yield put(actions.fetchScheduleTemplatesRequest({ scheduleId }));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.assignEmployeeFailure(error));
  }
}

function* fetchSchedulerStatistics({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(getSchedulerStatistics, payload);
    yield put(actions.getSchedulerStatisticsSuccess(entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.getSchedulerStatisticsFailure(error));
  }
}

const scheduleSagas = [
  takeEvery(actions.schedulesListRequest, fetchSchedulesList),
  takeEvery(actions.fetchCurrentUnitRequest, fetchCurrentUnit),
  takeEvery(actions.missingTasksListRequest, fetchMissingTasks),
  takeEvery(actions.checklistStatusRequest, fetchChecklistStatus),
  takeEvery(actions.fetchScheduleUnitItemsRequest, fetchUnitItems),
  takeEvery(actions.addScheduleRequest, addSchedule),
  takeEvery(actions.fetchScheduleDetailsRequest, fetchScheduleDetails),
  takeEvery(actions.fetchChecklistActivationRequest, fetchChecklistActivation),
  takeEvery(actions.fetchSwitchAutorunRequest, fetchSwitchAutorun),
  takeEvery(actions.fetchChecklistRunNextPeriodRequest, fetchChecklistRunNextPeriod),
  takeEvery(actions.getUserManagementRequest, getUserManagement),
  takeEvery(actions.fetchScheduleAssignableUsersRequest, fetchScheduleAssignableUsers),
  takeEvery(actions.setSupervisorRequest, setSupervisor),
  takeEvery(actions.editScheduleRequest, editSchedule),
  takeEvery(actions.changeScheduleRequest, changeSchedule),
  takeEvery(actions.deleteScheduleRequest, deleteSchedule),
  takeEvery(actions.changeScheduleStatusRequest, changeScheduleStatus),
  takeEvery(actions.fetchTaskAssignableUsersRequest, fetchTaskAssignableUsers),
  takeEvery(actions.fetchScheduleTemplatesRequest, fetchScheduleTemplates),
  takeEvery(actions.fetchItemTemplatesRequest, fetchItemTemplates),
  takeEvery(actions.removeTaskTemplateRequest, removeTaskTemplate),
  takeEvery(actions.taskStatusRequest, getTaskStatus),
  takeEvery(actions.addChecklistTimeframeRequest, setCheklistTimeFrame),
  takeEvery(actions.addTaskTemplateRequest, addTaskTemplate),
  takeEvery(actions.assignEmployeeRequest, assignEmployee),
  takeEvery(actions.getSchedulerStatisticsRequest, fetchSchedulerStatistics),
];

export default scheduleSagas;
