import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import { actions as listTemplatesActions } from 'redux/listTemplates';

import CheckboxList from './CheckboxList';
import * as S from './styled';

const SelectSite = ({ setIsNew, onNext, setIsImport, setCurrentSite }) => {
  const people = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const limit = useSelector(state => state.listTemplates.limits.MaxSitesNumber);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const currentSite = people.find(({ id }) => id === selectedSite);
  const dispatch = useDispatch();

  const [members, setMembers] = useState([]);
  const [checkedSite, setCheckedSite] = useState(currentSite);
  const [searchData, setSearchData] = useState('');

  const additionalIdSorter = ({ additionalId }) => additionalId.toLowerCase();
  const nameSorter = ({ name }) => name.toLowerCase();
  const sortedMembers = sortBy(members, [additionalIdSorter, nameSorter]);

  const onSiteChecked = newCheckedSite => setCheckedSite(newCheckedSite);

  const setSearchedMembers = (filteredMembers, searchValue) => {
    setMembers(filteredMembers);
    setSearchData(searchValue);
  };

  const onNextClick = () => {
    setIsNew();
    onNext();
    dispatch(listTemplatesActions.createListTemplateRequest({ isClear: true }));
  };

  const onImportClick = () => {
    setIsImport();
    onNext();
    dispatch(listTemplatesActions.createListTemplateRequest({ isClear: true }));
  };

  useEffect(() => {
    if (currentSite) {
      setCurrentSite(currentSite);
    }
  }, [currentSite, setCurrentSite]);

  useEffect(() => {
    if (people.length) {
      setMembers(people);
    }

    window.dispatchEvent(new Event('resize'));
  }, [people, selectedSite, setCurrentSite]);

  return (
    <S.Container>
      <S.TopInfo>
        <S.SubInfo>
          <S.BoldText>Current Limit: </S.BoldText>
        </S.SubInfo>
        <S.LimitCount>{limit}</S.LimitCount>
        <S.SubInfo>Sites</S.SubInfo>
      </S.TopInfo>
      <S.Block>
        <S.Label>Select Site From List</S.Label>
        <S.SearchContainer>
          <SearchField
            list={people}
            filterBy={['name']}
            searchData={searchData}
            placeholder="Filter Sites"
            setSearchedItems={setSearchedMembers}
          />
        </S.SearchContainer>
        <S.ListContainer>
          <CheckboxList
            list={sortedMembers}
            checkedSite={checkedSite}
            setCheckedSite={onSiteChecked}
            setCurrentSite={setCurrentSite}
            emptyText="There is no sites."
          />
        </S.ListContainer>
      </S.Block>
      <S.TextBlock>
        <S.Text>
          <S.BoldText>Import</S.BoldText> a List Template
        </S.Text>
        <S.Text>or</S.Text>
        <S.Text>Create your own List Template?</S.Text>
      </S.TextBlock>
      <ActionsContainer isColumn paddingValue={14}>
        <ActionButton
          type="button"
          label="MY OWN"
          withBorder
          onClick={onNextClick}
          isDisable={!selectedSite || !currentSite}
        />
        <ActionButton type="button" label="IMPORT" isDisable={!sortedMembers.length} isNext onClick={onImportClick} />
      </ActionsContainer>
    </S.Container>
  );
};

SelectSite.propTypes = {
  onNext: PropTypes.func.isRequired,
  setIsNew: PropTypes.func.isRequired,
  setIsImport: PropTypes.func.isRequired,
  setCurrentSite: PropTypes.func.isRequired,
};

export default SelectSite;
