import styled from 'styled-components';

import { HideOnTablets, ShowOnTablets } from 'components/Layout/Media';
import { ButtonIcon, ButtonRaised } from 'components/Layout/Buttons';
import { CloseIcon } from 'components/Layout/Icons';
import { Row } from 'components/Layout/Containers';

export const CloseButton = styled(ButtonIcon)`
  ${HideOnTablets}
`;

export const DeleteButton = styled(ButtonRaised).attrs({
  label: 'Delete',
  secondary: true,
})`
  height: 30px !important;
  border: unset !important;
  background: unset !important;

  ${ShowOnTablets}

  button {
    height: 30px !important;
    div {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      height: 30px !important;
    }
  }
`;

export const Container = styled(Row)`
  min-height: 50px;
  background: ${({ titleColor }) => titleColor} !important;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 0;
  text-transform: capitalize;

  @media only screen and (max-width: 768px) {
    padding-right: 15px;
  }
`;

export const IconClose = styled(CloseIcon)`
  color: ${({ theme }) => theme.primaryWhite} !important;
  transform: scale(1.3);
`;
