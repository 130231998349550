import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import SchedulesDetails from './pages/SchedulesDetails';
import SchedulesList from './pages/SchedulesList';
import UnitsList from './pages/UnitsList';
import SchedulerDashboard from './pages/Dashboard';

const Scheduler = () => (
  <Switch>
    <Route exact path="/scheduler" component={SchedulerDashboard} />
    <Route exact path="/scheduler/:type" component={UnitsList} />
    <Route exact path="/scheduler/list/:unitId" component={SchedulesList} />
    <Route exact path="/scheduler/list/:unitId/details/:scheduleId" component={SchedulesDetails} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Scheduler;
