import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const Info = styled.div`
  border-bottom: 1px solid grey;
  width: 100%;
  text-align: center;
  padding: 16px 0;
`;

export const Exports = styled.div`
  width: 100%;
  padding: 10px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  padding: 0 10px;
`;

export const Actions = styled.div``;

export const Details = styled.div`
  margin-right: 28px;
`;

export const NoExports = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  font-style: italic;
  margin-top: 26px;
`;

export const NewExport = styled.div`
  width: 96%;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const Loader = styled.div`
  margin-top: 10px;
`;
