import styled, { css } from 'styled-components';
import { DatePicker } from 'material-ui';

const Form = styled.form`
  min-width: 28vw;
  padding: 10px;
`;

const FieldWrapper = styled.div`
  padding: 0 10px;
  padding-bottom: 10px;
`;

const inputStyles = css`
  border: 1px solid ${({ theme }) => theme.primaryTasks};
  width: 30%;
  padding: 10px;
`;

const widerInputStyles = css`
  width: 60%;
`;

const inputWrapperStyles = css`
  justify-content: flex-start;
`;

const Label = styled.div`
  padding-bottom: 10px;
`;

const DatePickerAdapter = styled(DatePicker)`
  & > div:first-child {
    height: 40px !important;
    width: 130px !important;
    border: 1px solid ${({ theme }) => theme.primaryTasks};
    border-radius: 4px;

    & > input {
      padding-left: 10px !important;
    }

    & > div:first-child {
      padding-left: 10px;
      color: #808080 !important;
      bottom: 7px !important;
    }

    & > div:last-child {
      display: none;
    }
  }
`;

export { Form, FieldWrapper, Label, inputStyles, widerInputStyles, inputWrapperStyles, DatePickerAdapter };
