import styled from 'styled-components';
import { isIE11 } from 'helpers';

import { FieldText } from 'components/Layout/Inputs';
import { ButtonIcon } from 'components/Layout/Buttons';

const setFieldWidth = (isHelp, isReset) => {
  if (isHelp && isReset) {
    return '88%';
  } else if (isHelp || isReset) {
    return '92%';
  }

  return '100%';
};

const setFieldPaddingRight = (isHelp, isReset) => {
  if (isHelp && isReset) {
    return '12%';
  } else if (isHelp || isReset) {
    return '8%';
  }

  return 0;
};

export const InputToolTipWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const TextField = styled(FieldText)`
  width: ${({ isHelp, isReset }) => setFieldWidth(isHelp, isReset)} !important;
  padding-right: ${({ isHelp, isReset }) => setFieldPaddingRight(isHelp, isReset)};
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const IconButton = styled(ButtonIcon).attrs({
  tooltipStyles: props => ({
    width: `${isIE11 ? 'auto' : props.tooltipWidth}`,
    marginTop: -10,
  }),
})`
  width: 35px !important;
  padding: 0 !important;

  &:hover {
    transform: translateZ(0) scale(1);
  }
`;
