import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SignUpContainer = styled.div`
  padding: 0 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.signUpColor};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
  min-height: 430px;
`;

export const SignUpTitle = styled.h1`
  color: ${({ theme }) => theme.primaryWhite};
  text-shadow: ${({ theme }) => `-2px 2px 2px ${theme.primaryBlack}`};
  font-size: 20px;
  font-weight: bold;
  margin: 8px 0;
`;

export const AccountsInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const AccountsDataContainer = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-between;
`;

export const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AccountDataSquare = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  border: ${({ theme }) => `2px solid ${theme.primaryGrey}`};
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 16px;
`;

export const AccountDataLabel = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-top: 10px;
  text-align: center;
`;

export const AccountInfoTextContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const AccountInfoText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 5px;
`;

export const FormLabel = styled.h2`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 6px 0;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: fill-available;
`;

export const FieldContainer = styled.div`
  position: relative;
  width: fill-available;
`;

export const NextButtonContainer = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: ${({ theme }) => `3px solid ${theme.signUpColor}`};
  background: none;
  position: absolute;
  top: 4px;
  right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.signUpColor};
  font-size: 20px;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
`;

export const BottomBlock = styled.div`
  align-self: flex-end;
  padding: 28px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  position: absolute;
  background: #fa7747;
  display: inline-block;
  bottom: -66px;
  right: 0;

  ::after,
  &::before {
    content: '';
    position: absolute;
    bottom: 0.9em;
    height: 30px;
    width: 30px;
  }

  &::before {
    border-top-right-radius: 50%;
    z-index: 1;
    background: #eee;
    top: 9px;
    left: -30px;
  }

  &::after {
    background: #fa7747;
    top: 0;
    left: -20px;
  }
`;

export const Footer = styled.div`
  background: #fa7747;
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 200%;
    width: 200%;
    left: -100%;
    background: #eee;
    border-radius: 100%;
  }
`;

export const envelopeIconStyles = {
  width: '40px',
  height: '40px',
};

export const ForgotPasswordContainer = styled.div`
  max-width: 400px;
  position: absolute;
  bottom: -40px;
  left: 30px;
  z-index: 10;
`;

export const ForgotLink = styled(Link)`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const UpgradeAccountContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 400px;
  padding: 16px 0;
  z-index: 10;
  position: absolute;
  top: 100%;
  right: 20px;
`;

export const UpgradeAccountLink = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 300;
  margin-left: 7px;
  text-decoration: underline;
`;
