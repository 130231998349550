import styled from 'styled-components';
import { Item } from 'modules/globalsettings/pages/styled';

export const ContainerItem = styled.div`
  display: flex;
  width: 400px;
`;

export const RedItem = styled.div`
  color: #ff0000;
  width: 50px;
`;

export const FirstItem = styled.div`
  width: 100px;
`;

export const SpaceItem = styled.div`
  width: 50px;
`;

export const LastItem = styled(RedItem)`
  width: 80px;
`;

export const CustomItem = styled(Item)`
  width: 150px;
`;
