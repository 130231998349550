import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Levels = ({ isDown }) => (
  <S.Levels isDown={isDown}>
    <S.Normal />
    <S.Elevated />
    <S.Watch />
    <S.Warning />
    <S.Alert />
  </S.Levels>
);

Levels.propTypes = {
  isDown: PropTypes.bool,
};

Levels.defaultProps = {
  isDown: false,
};

export default Levels;
