import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

export const Gateway = styled(Flex).attrs({
  w: 1,
  justify: 'space-between',
})``;

export const TenantName = styled(Box).attrs({
  w: [0.4, 0.4, 0.4, 0.45],
})``;

export const SerialNumber = styled(Flex).attrs({
  column: true,
  w: [0.4, 0.4, 0.45, 0.45],
  mx: 1,
})``;

export const Text = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Sensors = styled(Flex).attrs({
  w: [0.2, 0.2, 0.15, 0.1],
  wrap: 'wrap',
  justify: 'flex-end',
})`
  @media only screen and (max-width: 34em) {
    justify-content: center;
  }
`;

export const SensorsTitle = styled.div``;

export const SensorsCount = styled.div`
  min-width: 27px;
  padding-left: 5px;
  text-align: center;
`;
