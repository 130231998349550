import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 40px 20px 100px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: ${({ authMode }) => (authMode === 'info' ? 1 : 0)};
  height: ${({ authMode }) => (authMode === 'info' ? '380px' : '100px')};
  margin-top: -12px;
  position: absolute;
  top: 163px;

  @media screen and (max-width: 370px) {
    padding: 40px 10px 100px;
  }
`;

export const TitleInformation = styled.div`
  color: ${({ theme }) => theme.mainGreen};
  font-weight: bold;
  text-align: center;
`;

export const TextInformation = styled.div`
  color: ${({ theme }) => theme.mainGreen};
  padding: 10px 0px;
  text-align: center;
`;

export const TextContainer = styled.div`
  margin: 0 auto;
  width: 85%;
`;
