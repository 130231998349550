import styled from 'styled-components';
import theme from 'theme';
import { Checkbox, RadioButtonGroup } from 'material-ui';

import { ContentContainer, ToolbarContainer } from 'components/Layout/Containers';
import { SearchIcon } from 'components/Layout/Icons';

export const Container = styled(ContentContainer)``;

export const SearchAction = styled(SearchIcon)``;

export const Toolbar = styled(ToolbarContainer)`
  padding: 0px 36px 0px 24px !important;
`;

export const CheckBox = styled(Checkbox).attrs({
  labelStyle: {
    width: '100%',
    color: theme.primaryGrey,
    marginRight: '10px',
  },
  iconStyle: {
    fill: theme.mainRed,
  },
})``;

export const RadioGroup = styled(RadioButtonGroup).attrs({
  style: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
})``;
