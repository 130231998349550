import React from 'react';
import * as S from './../styled';

export const renderConfirmationText = text => (
  <S.ConfirmationContainer>
    <S.ConfirmationText>{text}</S.ConfirmationText>
  </S.ConfirmationContainer>
);

export const renderConfirmationTextWithWarning = (text, warning) => (
  <S.ConfirmationContainer>
    <S.ConfirmationText>{text}</S.ConfirmationText>
    <S.ConfirmationWarning>{warning}</S.ConfirmationWarning>
  </S.ConfirmationContainer>
);

export const renderMappedConfirmationText = arr => (
  <S.ConfirmationContainer>
    {arr.map((el, i) => (
      <S.ConfirmationText key={i}>{el}</S.ConfirmationText>
    ))}
  </S.ConfirmationContainer>
);

export const renderInfoListTemplateConfirmationText = (listTemplateCurrentSite, listTemplateNames) => (
  <S.InfoConfirmationContainer>
    <S.ConfirmationTitle>List Template(s) have been added to</S.ConfirmationTitle>
    <S.ConfirmationTextContainer>
      <S.InfoPreviewText>
        {listTemplateCurrentSite !== null
          ? `${listTemplateCurrentSite?.additionalId.substring(0, 5)} 
          ${listTemplateCurrentSite?.name}`
          : null}
      </S.InfoPreviewText>
      {listTemplateNames.templateNames.length > 0
        ? listTemplateNames.templateNames.map(item => <S.InfoConfirmationText>{item}</S.InfoConfirmationText>)
        : null}
    </S.ConfirmationTextContainer>
  </S.InfoConfirmationContainer>
);
