import styled from 'styled-components';

export const Image = styled.img`
  width: ${({ isPDF }) => (isPDF ? '130px' : '60px')};
  height: 60px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
`;

export const Blank = () => {};
