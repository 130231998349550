import React from 'react';
import * as S from './styled';

const ROWS = [1, 2, 3, 4, 5, 6, 7];
const COLUMNS = [1, 2, 3, 4, 5, 6, 7, 8];

const IntervalTable = () => (
  <S.Container>
    {ROWS.map(row => (
      <S.RowContainer key={row}>
        {COLUMNS.map(item => (
          <S.ItemContainer key={item} green={item === 1} red={(item !== 1 && item % row === 1) || row === 1}>
            {((item !== 1 && item % row === 1) || (row === 1 && item !== 1)) && row}
            {item === 1 && 'start'}
          </S.ItemContainer>
        ))}
      </S.RowContainer>
    ))}
  </S.Container>
);

export default IntervalTable;
