export const validator = values => {
  const errors = {};

  if ((values['0IsTaskSpecialist'] || values['0IsAdminMngr'] || values['0IsOEMMngr']) && !values['0Link']) {
    errors['0Link'] = true;
  }

  if (values['0Link'] && !values['0IsTaskSpecialist'] && !values['0IsAdminMngr'] && !values['0IsOEMMngr']) {
    errors['0IsTaskSpecialist'] = 'Required';
    errors['0IsAdminMngr'] = 'Required';
    errors['0IsOEMMngr'] = 'Required';
  }
  if ((values['1IsTaskSpecialist'] || values['1IsAdminMngr'] || values['1IsOEMMngr']) && !values['1Link']) {
    errors['1Link'] = true;
  }

  if (values['1Link'] && !values['1IsTaskSpecialist'] && !values['1IsAdminMngr'] && !values['1IsOEMMngr']) {
    errors['1IsTaskSpecialist'] = 'Required';
    errors['1IsAdminMngr'] = 'Required';
    errors['1IsOEMMngr'] = 'Required';
  }
  if ((values['2IsTaskSpecialist'] || values['2IsAdminMngr'] || values['2IsOEMMngr']) && !values['2Link']) {
    errors['2Link'] = true;
  }

  if (values['2Link'] && !values['2IsTaskSpecialist'] && !values['2IsAdminMngr'] && !values['2IsOEMMngr']) {
    errors['2IsTaskSpecialist'] = 'Required';
    errors['2IsAdminMngr'] = 'Required';
    errors['2IsOEMMngr'] = 'Required';
  }
  if ((values['3IsTaskSpecialist'] || values['3IsAdminMngr'] || values['3IsOEMMngr']) && !values['3Link']) {
    errors['3Link'] = true;
  }

  if (values['3Link'] && !values['3IsTaskSpecialist'] && !values['3IsAdminMngr'] && !values['3IsOEMMngr']) {
    errors['3IsTaskSpecialist'] = 'Required';
    errors['3IsAdminMngr'] = 'Required';
    errors['3IsOEMMngr'] = 'Required';
  }
  if ((values['4IsTaskSpecialist'] || values['4IsAdminMngr'] || values['4IsOEMMngr']) && !values['4Link']) {
    errors['4Link'] = true;
  }

  if (values['4Link'] && !values['4IsTaskSpecialist'] && !values['4IsAdminMngr'] && !values['4IsOEMMngr']) {
    errors['4IsTaskSpecialist'] = 'Required';
    errors['4IsAdminMngr'] = 'Required';
    errors['4IsOEMMngr'] = 'Required';
  }
  return errors;
};

export default validator;
