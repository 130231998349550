import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete';

import { SelectorContainer, ScrollList } from './styled';

const AutoCompleteAdapter = ({ list, placeholderText, changeFunction, clearFieldsForCopy, searchText }) => (
  <SelectorContainer>
    <AutoComplete
      listStyle={ScrollList}
      hintText={placeholderText}
      searchText={searchText}
      filter={AutoComplete.caseInsensitiveFilter}
      dataSource={list}
      onUpdateInput={changeFunction}
      onNewRequest={clearFieldsForCopy}
      openOnFocus
      fullWidth
    />
  </SelectorContainer>
);

AutoCompleteAdapter.propTypes = {
  list: PropTypes.array.isRequired,
  changeFunction: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  clearFieldsForCopy: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default AutoCompleteAdapter;
