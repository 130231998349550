import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Item = ({ SensorTypeVariable, SerialNumber }) => (
  <S.Gateway>
    <S.SerialNumber>
      Serial Number: <b>{SerialNumber}</b>
    </S.SerialNumber>
    <S.Type>
      Reported Type: <S.TypeName>{SensorTypeVariable}</S.TypeName>
    </S.Type>
  </S.Gateway>
);

Item.propTypes = {
  SensorTypeVariable: PropTypes.oneOfType([PropTypes.string, null]).isRequired,
  SerialNumber: PropTypes.string.isRequired,
};

Item.defaultProps = {
  Notes: null,
};

export default Item;
