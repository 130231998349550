import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { exportUnitPdfToListExchange } from 'http/exportPdf';

import { actions as exchangeActions } from 'redux/exchange';

import Details from './Datails';
import ItemsList from './ItemsList';
import PdfsDialog from './PdfsDialog';
import PhotosDialog from './PhotosDialog';
import TopBar from './TopBar';
import { Content } from './styled';

const getNameAndModel = item =>
  `${item.Name} ${item.ModelNumber ? item.ModelNumber : ''} ${item.VendorName ? item.VendorName : ''}`;

class UnitDetails extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    filesList: PropTypes.array.isRequired,
    itemsList: PropTypes.array.isRequired,
    requestUnitVersion: PropTypes.func.isRequired,
    requestUnitFilesList: PropTypes.func.isRequired,
    requestUnitItemsList: PropTypes.func.isRequired,
  };

  state = {
    editUnitDialogOpened: false,
    uploadImageDialogOpened: false,
    uploadPdfDialogOpened: false,
  };

  componentDidMount() {
    this.props.requestUnitVersion(this.props.match.params.unitId);
    this.props.requestUnitFilesList({ parentId: this.props.match.params.unitId });
    this.props.requestUnitItemsList({ unitId: this.props.match.params.unitId });
  }

  closeEditUnitDialog = () => {
    this.setState({ editUnitDialogOpened: false });
  };

  closeUploadImageDialog = () => {
    this.setState({ uploadImageDialogOpened: false });
  };

  closeUploadPdfDialog = () => {
    this.setState({ uploadPdfDialogOpened: false });
  };

  exportUnitPdf = item => {
    exportUnitPdfToListExchange(item.Id, item.UnitId);
  };

  goBack = unit => {
    this.props.history.push(`/exchange/${unit.OriginalId}/versions`);
  };

  openUploadImageDialog = () => {
    this.setState({ uploadImageDialogOpened: true });
  };

  openUploadPdfDialog = () => {
    this.setState({ uploadPdfDialogOpened: true });
  };

  render() {
    const { uploadImageDialogOpened, uploadPdfDialogOpened } = this.state;
    const { itemsList, filesList, unit } = this.props;

    return [
      <TopBar
        key="Unit Details Bar"
        title={getNameAndModel(unit)}
        unitVersion={unit.ListExchangeVersion}
        goBack={() => this.goBack(unit)}
        openUploadImageDialog={this.openUploadImageDialog}
        openUploadPdfDialog={this.openUploadPdfDialog}
      />,
      <Content key="Unit Details Content">
        <Details pictures={filesList.filter(file => file.MimeType !== 'application/pdf')} unit={unit} />
        <ItemsList items={itemsList} />
      </Content>,
      <PhotosDialog
        key="Upload unit photos dialog"
        handleClose={this.closeUploadImageDialog}
        open={uploadImageDialogOpened}
        photos={filesList.filter(file => file.MimeType !== 'application/pdf')}
      />,
      <PdfsDialog
        key="Upload unit pdfs dialog"
        handleClose={this.closeUploadPdfDialog}
        handleItemClick={this.exportUnitPdf}
        open={uploadPdfDialogOpened}
        pdfs={filesList.filter(file => file.MimeType === 'application/pdf')}
      />,
    ];
  }
}

const mapStateToProps = ({ exchange }) => ({
  unit: exchange.unitVersion,
  filesList: exchange.unitFilesList,
  file: exchange.unitFile,
  itemsList: exchange.itemsList,
});

const mapDispatchToProps = {
  requestUnitVersion: exchangeActions.getUnitVersionRequest,
  requestUnitFilesList: exchangeActions.getUnitFilesListRequest,
  requestUnitItemsList: exchangeActions.getItemsListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetails);
