import React from 'react';
import PropTypes from 'prop-types';

import { SensorLevelColors } from 'theme';

import * as S from './styled';

const Title = ({ handleClose, status, title }) => (
  <S.Header>
    <S.Title>
      <S.IconWarning color={SensorLevelColors[status]} />
      <S.TitleText>{title}</S.TitleText>
    </S.Title>
    <S.IconButton onClick={handleClose}>
      <S.IconClose />
    </S.IconButton>
  </S.Header>
);

Title.propTypes = {
  handleClose: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Title;
