import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ListItemContainer } from 'components/Layout/Containers';
import { FieldCheckbox } from 'components/Layout/Inputs';
import RightControls from './RightControls';

const BASE_Z_INDEX = 1000;

class ListItem extends Component {
  static defaultProps = {
    onClick: null,
    userType: 0,
    tenantIsOEM: false,
    allowSubmitToListExchange: false,
    importTenantToExchangeList: () => {},
    openDragItemsListDialog: () => {},
    openPdfDialog: null,
    openDuplicateDialog: null,
    withoutControls: false,
    disabled: false,
  };

  static propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    renderItemContent: PropTypes.func.isRequired,
    renderRightControls: PropTypes.func.isRequired,
    withCheckbox: PropTypes.bool.isRequired,
    userType: PropTypes.number,
    tenantIsOEM: PropTypes.bool,
    allowSubmitToListExchange: PropTypes.bool,
    importTenantToExchangeList: PropTypes.func,
    openDragItemsListDialog: PropTypes.func,
    openPdfDialog: PropTypes.func,
    openDuplicateDialog: PropTypes.func,
    withoutControls: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  onItemClick = () => {
    const { item, onClick } = this.props;

    if (onClick) {
      onClick(item);
    }
  };

  render() {
    const {
      item,
      index,
      onClick,
      renderItemContent,
      renderRightControls,
      withCheckbox,
      userType,
      tenantIsOEM,
      allowSubmitToListExchange,
      importTenantToExchangeList,
      openDragItemsListDialog,
      openPdfDialog,
      openDuplicateDialog,
      withoutControls,
      disabled,
    } = this.props;

    return (
      <ListItemContainer
        disabled={!onClick || disabled}
        index={BASE_Z_INDEX - index}
        leftCheckbox={withCheckbox ? <FieldCheckbox checked={item.checked} onCheck={this.onItemClick} /> : null}
        onClick={this.onItemClick}
        primaryText={renderItemContent(item)}
        rightIconButton={
          !withoutControls && (userType || tenantIsOEM || allowSubmitToListExchange) ? (
            <RightControls
              unit={item}
              userType={userType}
              tenantIsOEM={tenantIsOEM}
              allowSubmitToListExchange={allowSubmitToListExchange}
              importTenantToExchangeList={importTenantToExchangeList}
              openDragItemsListDialog={openDragItemsListDialog}
              openPdfDialog={openPdfDialog}
              openDuplicateDialog={openDuplicateDialog}
            />
          ) : (
            renderRightControls(item)
          )
        }
      />
    );
  }
}

export default ListItem;
