const TABLE_CONFIGS = [
  { field: 'Reason', title: 'Reason' },
  { field: 'Quantity', title: 'Qty' },
  { field: 'UserFullName', title: 'Person' },
  { field: 'Comment', title: 'Comment' },
  { field: 'OrderReference', title: 'Reference' },
  { field: 'Date', title: 'Date' },
  { field: 'Time', title: 'Time' },
];

export { TABLE_CONFIGS };
