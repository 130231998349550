import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import orderBy from 'lodash/orderBy';

import theme from 'theme';
import { TaskNotesPhotos, TaskPhoto } from 'configs/propTypes';
import { queueTaskToggle } from 'configs/toggles';
import { getSelectedSite } from 'helpers/storage';

import { getPdfReport } from 'http/queue';

import { BackIcon, DeleteIcon, ImportIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';
import UnitListTable from 'components/UnitListTable';
import EmptyList from 'components/EmptyList';
import Details from './Details';
import PdfReportDialog from './PdfReportDialog';
import TaskInfoDialog from './TaskInfoDialog';
import Toggle from 'components/Toggle';
import { ButtonIcon } from 'components/Layout/Buttons';
import BaseDialog from 'components/Dialogs/Base';
import TaskWithPartsDetails from './TaskWithPartsDetails';

import { mapStateToProps, mapDispatchToProps } from './index.props';
import {
  getItemName,
  getItemText,
  getItemInstruction,
  renderLeftControls,
  renderRightText,
  calculateRanges,
} from './helpers';

import * as S from './styled';
import { Table } from './Table';

const UnitDetail = props => {
  const {
    loading,
    notesPhotos,
    error,
    selectedSite,
    photos,
    reportList,
    tasksList,
    unitName,
    taskDetails,
    taskParts,
    toggleType,
    history,
    location: { pathname },
    match: {
      params: { assigneeId, category, date, period, unitId },
    },
    requestTaskDetails,
    fetchTasks,
    fetchAssignedTasks,
    fetchDeletePdfReport,
    fetchCreatePdfReport,
    openErrorDialog,
    setToggleType,
    fetchReportList,
    sendEmailWithTaskInfoRequest,
    requestSaveTask,
    fetchUnitsList,
    fetchTasksPhotos,
    resetAssignedTasks,
    clearStateError,
    setSelectedTasksInfo,
  } = props;

  const [pdfReportDialogOpened, setPdfReportDialogOpened] = useState(false);
  const [requiresAttention, setRequiresAttention] = useState(false);
  const [taskInfoDialogOpened, setTaskInfoDialogOpened] = useState(false);
  const [taskPhotoDialogOpened, setTaskPhotoDialogOpened] = useState(false);
  const [viewedTask, setViewedTask] = useState(null);
  const [dateSortingValue, setDateSortingValue] = useState('asc');
  const [tasks, setTasks] = useState([]);
  const [isPartsViewDialogOpen, setIsPartsViewDialogOpen] = useState(false);
  const [isDialogWithParts, setIsDialogWithParts] = useState(false);

  const siteId = getSelectedSite();
  const isRequireAttentionTasks = period === 'all' || period === 'parts';
  const title = isRequireAttentionTasks ? 'Attention details' : 'Progress';
  const isUnassigned = category === 'All Tasks' ? null : category === 'Pick-Up' || category === 'Team';

  const defaultParams = {
    period,
    rangeStart: date,
    unitId,
    includeTeam: category === 'Team' || category === 'All Tasks',
    userId: assigneeId === 'allusers' ? '' : assigneeId,
    isTeamOnly: category === 'Team',
  };

  const onTaskClick = item => {
    if (item.TaskPartsRequireAttention) {
      setIsDialogWithParts(true);
      requestTaskDetails({ taskId: item.Id });
      openPartsViewDialog();
      return;
    }

    setIsDialogWithParts(false);
    requestTaskDetails({ taskId: item.Id });
    fetchTasks(item.Id);
    openPartsViewDialog();
  };

  const onToggle = () => setRequiresAttention(!requiresAttention);
  const getPdf = item => getPdfReport({ id: item.Id });

  const deletePdf = item => fetchDeletePdfReport({ id: item.Id });

  const goBack = () => {
    history.push(`/queue/${date}/${period}`);
    setToggleType('all');
  };

  const toggleOpenCompletedTask = ({ key }) => {
    switch (key) {
      case 'open':
        setToggleType('open');
        break;
      case 'completed':
        setToggleType('completed');
        break;
      case 'quicktask':
        setToggleType('quicktask');
        break;
      default: {
        setToggleType('all');
      }
    }
  };

  const rightControlsForPdfList = item => (
    <div>
      <ButtonIcon onClick={() => deletePdf(item)} tooltip="Delete">
        <DeleteIcon />
      </ButtonIcon>
      <ButtonIcon onClick={() => getPdf(item)} tooltip="Get">
        <ImportIcon />
      </ButtonIcon>
    </div>
  );

  const exportReport = () => {
    fetchCreatePdfReport({
      dates: calculateRanges(date, period),
      unitId,
      userId: assigneeId,
    });
    openErrorDialog(
      'We are generating your report. When it will be ready you will get email notification',
      'Pdf reports',
    );
    setPdfReportDialogOpened(false);
  };

  const openPdfReportDialog = () => {
    fetchReportList({ unitId });
    setPdfReportDialogOpened(true);
  };

  const closePdfReportDialog = () => setPdfReportDialogOpened(false);

  const closeTaskInfoDialog = () => {
    setTaskInfoDialogOpened(false);
    setViewedTask(null);
  };

  const openTaskInfoDialog = item => {
    fetchTasks(item.Id);
    setTaskInfoDialogOpened(true);
    setViewedTask(item);
    setTaskPhotoDialogOpened(false);
  };

  const closeTaskPhotoDialog = () => {
    setTaskInfoDialogOpened(true);
    setTaskPhotoDialogOpened(false);
  };

  const openTaskPhotoDialog = item => {
    fetchTasksPhotos(item.ItemId);
    setTaskPhotoDialogOpened(true);
  };

  const onChangeDateSorting = newDateSortingValue => {
    const sortedTasks = orderBy(tasks, task => moment(task.ExecutionDate).format('MM/DD/YYYY'), [dateSortingValue]);

    setDateSortingValue(newDateSortingValue);
    setTasks(sortedTasks);
  };

  const openPartsViewDialog = () => setIsPartsViewDialogOpen(true);

  const closePartsViewDialog = () => setIsPartsViewDialogOpen(false);

  const updateAssignedTaskList = () => {
    fetchAssignedTasks({
      ...defaultParams,
      isUnassigned,
      requiresAttention,
      requiredAttetionParts: period === 'parts' || null,
    });
    closePartsViewDialog();
  };

  useEffect(() => {
    const params = queryString.parse(pathname, { ignoreQueryPrefix: true });

    const allTasksParams = {
      ...defaultParams,
      includeCompleted: true,
      isUnassigned,
      requiresAttention,
      requiredAttetionParts: period === 'parts' || null,
    };

    const filters = {
      open: {
        ...defaultParams,
        isUnassigned,
        requiresAttention: requiresAttention || null,
      },
      completed: {
        ...defaultParams,
        isUnassigned: isUnassigned === true ? isUnassigned : false,
        includeAssigned: false,
        includeCompleted: true,
        requiresAttention: false,
        requireOnlyCompleted: isUnassigned === true ? isUnassigned : false,
      },
      all: allTasksParams,
      quicktask: allTasksParams,
    };

    // if (params.completed) {
    //   toggleOpenCompletedTask({ key: 'completed' });
    //   openPdfReportDialog();
    // } else {
    fetchUnitsList({ filter: { SiteID: selectedSite } });
    fetchAssignedTasks(filters[toggleType]);
    // }

    return () => {
      setTasks([]);
      setDateSortingValue('asc');
      resetAssignedTasks();
      setSelectedTasksInfo({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assigneeId,
    requiresAttention,
    category,
    date,
    fetchAssignedTasks,
    fetchUnitsList,
    pathname,
    period,
    selectedSite,
    unitId,
    toggleType,
  ]);

  useEffect(() => {
    if (period === 'all' || period === 'parts') {
      setTasks(tasksList);
    }
  }, [tasksList, period]);

  useEffect(() => {
    if (error) {
      openErrorDialog(error, 'Error');
      clearStateError();
    }
  }, [clearStateError, error, openErrorDialog]);

  return [
    <Subheader
      key="UnitDetail Subheader"
      title={title}
      titleStyle={S.TitleStyle}
      isSiteOnLeftSide
      leftButtons={[
        {
          icon: <BackIcon />,
          handler: goBack,
          hint: 'Back',
        },
      ]}
    />,
    // !isRequireAttentionTasks && (
    //   <S.ToggleContainer>
    //     <Toggle config={queueTaskToggle} selected={toggleType} handler={toggleOpenCompletedTask} minWidth />
    //   </S.ToggleContainer>
    // ),
    <Details
      key="Unit Details"
      data={{
        assigneeName: category,
        period,
        rangeStart: date,
        isRequireAttentionTasks,
        requireAttentionTasksTotal: tasks.length,
        // tasksTotal: isRequireAttentionTasks ? tasks.length : tasksList.length,
        dateSortingValue,
        unitName,
      }}
      toggleType={toggleType}
      onToggle={onToggle}
      onChangeDateSorting={onChangeDateSorting}
    />,
    <S.ListContainer key="Tasks list">
      {!loading && (!!tasks.length || !!tasksList.length) ? (
        <>
          {/* {isRequireAttentionTasks ? (
            <UnitListTable
              getItemName={getItemName}
              getItemText={getItemText}
              getItemInstruction={getItemInstruction}
              onUnitItemClick={onTaskClick}
              renderLeftControls={renderLeftControls}
              renderRightText={renderRightText}
              handleToggle={openTaskInfoDialog}
              unitColor={theme.primaryQueue}
              unitData={tasks}
              unitName={unitName}
              downloadPdf={toggleType === 'completed' ? openPdfReportDialog : null}
              withUnitControls
            />
          ) : ( */}
          <Table tasksList={tasksList} />
          {/* )} */}
        </>
      ) : (
        <EmptyList text="There are no tasks" />
      )}
    </S.ListContainer>,
    <TaskInfoDialog
      key="TaskInfoDialog"
      title={viewedTask && `Task Result Details: ${viewedTask.RuleType}`}
      item={viewedTask}
      open={taskInfoDialogOpened}
      handleClose={closeTaskInfoDialog}
      openDialog={taskPhotoDialogOpened}
      openPhotoDialog={openTaskPhotoDialog}
      closePhotoDialog={closeTaskPhotoDialog}
      notesPhotos={notesPhotos}
      photos={photos}
      siteId={siteId}
    />,
    <PdfReportDialog
      key="Date Range Dialog"
      title="PDF Reports"
      handleClose={closePdfReportDialog}
      onSubmit={exportReport}
      open={pdfReportDialogOpened}
      pdfList={reportList}
      rightControls={rightControlsForPdfList}
    />,
    <BaseDialog
      key="items part preview"
      open={isPartsViewDialogOpen}
      onRequestClose={closePartsViewDialog}
      title={isDialogWithParts ? 'Item Parts View' : 'Item View'}
    >
      <TaskWithPartsDetails
        {...taskDetails}
        isDialogWithParts={isDialogWithParts}
        taskParts={taskParts}
        sendEmailWithTaskInfoRequest={sendEmailWithTaskInfoRequest}
        requestSaveTask={requestSaveTask}
        notesPhotos={notesPhotos}
        updateAssignedTaskList={updateAssignedTaskList}
      />
    </BaseDialog>,
  ];
};

UnitDetail.propTypes = {
  clearStateError: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  fetchAssignedTasks: PropTypes.func.isRequired,
  fetchCreatePdfReport: PropTypes.func.isRequired,
  fetchDeletePdfReport: PropTypes.func.isRequired,
  fetchReportList: PropTypes.func.isRequired,
  fetchTasks: PropTypes.func.isRequired,
  fetchTasksPhotos: PropTypes.func.isRequired,
  fetchUnitsList: PropTypes.func.isRequired,
  resetAssignedTasks: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  notesPhotos: TaskNotesPhotos.isRequired,
  openErrorDialog: PropTypes.func.isRequired,
  photos: TaskPhoto.isRequired,
  reportList: PropTypes.array.isRequired,
  selectedSite: PropTypes.string.isRequired,
  tasksList: PropTypes.arrayOf(PropTypes.object).isRequired,
  unitName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  requestTaskDetails: PropTypes.func.isRequired,
  taskDetails: PropTypes.shape({}).isRequired,
  taskParts: PropTypes.array.isRequired,
  sendEmailWithTaskInfoRequest: PropTypes.func.isRequired,
  requestSaveTask: PropTypes.func.isRequired,
  setToggleType: PropTypes.func.isRequired,
  toggleType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetail);
