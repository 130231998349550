import styled from 'styled-components';

import { ItemName } from 'components/Dialogs/styled';
import { NotificationInfoIcon } from 'components/Layout/Icons';

export const List = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const ListItem = styled.li`
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const CheckboxesContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const CheckboxesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px 30px;
`;

export const SelectDeselectLabel = styled(ItemName)`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const GroupLabel = styled(ItemName)`
  color: ${({ theme }) => theme.primaryRed};
`;

export const HeaderCheckboxContainer = styled.div`
  height: 100px;
  max-width: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
`;

export const NotificationInfo = styled(NotificationInfoIcon)`
  cursor: pointer;
  align-items: center;
`;
