import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'material-ui/RadioButton';

import { resizeWindow } from 'helpers';

import Levels from './Levels';

import * as S from './styled';

const LevelsBoth = quantity => (
  <S.Container>
    {quantity} Alert Levels
    <S.LevelsImage>
      <Levels quantity={quantity} isUp />
      <S.DashedLine />
      <Levels quantity={quantity} />
    </S.LevelsImage>
  </S.Container>
);

const LevelsQuantityRadioGroup = ({ form, input: { onChange, value }, isBoth, values }) => (
  <S.RadioGroup
    name="quantity"
    valueSelected={value}
    onChange={(event, data) => {
      form.reset({
        ...values,
        options: data,
      });
      onChange(data);
      setTimeout(() => resizeWindow(), 0);
    }}
    isBoth={isBoth}
  >
    <RadioButton label={isBoth ? LevelsBoth(3) : '2 Alert Levels'} value="2" />
    <RadioButton label={isBoth ? LevelsBoth(5) : '4 Alert Levels'} value={isBoth ? '3' : '4'} />
    <RadioButton label={isBoth ? LevelsBoth(7) : '3 Alert Levels'} value={isBoth ? '4' : '3'} />
    <RadioButton label={isBoth ? LevelsBoth(9) : '5 Alert Levels'} value="5" />
  </S.RadioGroup>
);

LevelsQuantityRadioGroup.propTypes = {
  form: PropTypes.shape({
    reset: PropTypes.func.isRequired,
  }).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  isBoth: PropTypes.bool.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default LevelsQuantityRadioGroup;
