import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import difference from 'lodash/difference';

import { actions as authActions } from 'redux/auth';
import withDrawer from 'components/Dialogs/Drawer';
import CheckboxDrawerAdapter from 'components/Forms/CheckboxDrawerAdapter';
import { DrawerSaveButton } from 'components/Layout/Buttons';
import { ChevronRightIcon } from 'components/Layout/Icons';

import { assignSitesToUser } from './selectors';

import * as S from './styled';

class AssignSitesForm extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onSiteChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    sitesAssignedToUser: PropTypes.shape({
      siteIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    sitesAvailableToUser: PropTypes.object.isRequired,
    siteList: PropTypes.arrayOf(
      PropTypes.shape({
        siteName: PropTypes.string.isRequired,
        siteId: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  changeSite = siteName => {
    const { siteList } = this.props;

    this.props.onSiteChange({ checkedSite: siteName, sitesCollection: siteList });
    this.props.history.push('/home');
  };

  combineCheckedSites = (newSiteIds, siteIds) => {
    newSiteIds.forEach(item => {
      if (!siteIds.includes(item)) {
        siteIds.push(item);
      }
    });

    return siteIds;
  };

  checkAllSites = (checked, form, siteIds) => {
    const { siteList } = this.props;
    const newSiteIds = siteList.map(site => site.siteId);

    form.reset({
      siteIds: checked
        ? this.combineCheckedSites(newSiteIds, [...siteIds])
        : siteIds.filter(siteId => !newSiteIds.includes(siteId)),
    });
  };

  render() {
    const { onSubmit, siteList, sitesAssignedToUser, sitesAvailableToUser } = this.props;

    return (
      <S.FormContainer
        onSubmit={onSubmit}
        initialValues={sitesAssignedToUser}
        render={({ handleSubmit, form, values: { siteIds } }) => (
          <S.FormTag onSubmit={handleSubmit}>
            <S.SelectAllCheckboxes
              label="Select/Deselect All"
              checked={siteList.length > 0 && !siteList.some(item => !siteIds.includes(item.siteId))}
              onCheck={(event, checked) => this.checkAllSites(checked, form, siteIds)}
              disabled={!siteList.length}
            />
            <S.List>
              {siteList.map(site => (
                <S.NextButtonContainer key={site.siteId}>
                  <S.CheckboxContainer>
                    <CheckboxDrawerAdapter site={site} />
                  </S.CheckboxContainer>
                  <S.IconButton
                    onClick={() => this.changeSite(site.siteName)}
                    disabled={!sitesAvailableToUser[site.siteId]}
                  >
                    <ChevronRightIcon />
                  </S.IconButton>
                </S.NextButtonContainer>
              ))}
            </S.List>
            <S.Footer>
              <DrawerSaveButton
                disabled={
                  siteIds.length === sitesAssignedToUser.siteIds.length &&
                  difference(sitesAssignedToUser.siteIds, siteIds).length === 0
                }
              />
            </S.Footer>
          </S.FormTag>
        )}
      />
    );
  }
}

const mapStateToProps = (
  {
    auth: {
      user: { sitesAvailableToUser },
    },
    people,
  },
  { personId },
) => ({
  sitesAssignedToUser: assignSitesToUser(people, personId),
  sitesAvailableToUser,
});

const mapDispatchToProps = {
  onSiteChange: authActions.onSiteChange,
};

const AssignedSitesDialog = withDrawer(connect(mapStateToProps, mapDispatchToProps)(AssignSitesForm));

export default AssignedSitesDialog;
