import styled from 'styled-components';

export const Container = styled.div`
  &:last-child {
    padding: 20px 0px !important;
  }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 5px 0;
  font-size: 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
`;

export const PreviewItem = styled.div`
  display: flex;
`;

export const PreviewItemContainer = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  padding: 0 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PreviewItemSmall = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  display: flex;
  font-size: 12px;
`;

export const PreviewItemText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;
