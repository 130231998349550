import styled from 'styled-components';

export const DialogContainer = styled.div`
  padding: 25px 65px 10px;
`;

export const DialogText = styled.p`
  font-size: 20px;
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
  margin: 0px;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const Button = styled.button`
  display: block;
  margin: 0 auto;
  width: 190px;
  height: 50px;
  background-color: ${({ theme }) => theme.mainGreen};
  border: ${({ theme }) => `5px double ${theme.primaryWhite}`};
  color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
`;
