import { BackIcon } from 'components/Layout/Icons';
import { fixedTableHeadStyles } from 'components/TableList/styled';
import styled, { css } from 'styled-components';

const COLORS: Record<string, string> = {
  Add: 'mainDarkBlue',
  Sub: 'primaryRed',
};

const TableContainer = styled.div`
  ${fixedTableHeadStyles}

  & th:nth-child(-n + 3), td:nth-child(-n + 3) {
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
  }

  & table thead th {
    box-shadow: inset 0 0 0 #9c9c9c, inset 0 -1px 0 #9c9c9c;
  }

  th,
  td {
    padding: 0 20px !important;
  }

  & td:nth-child(2),
  & td:nth-child(3) {
    background-color: ${({ theme }) => theme.lightBlue};
  }
`;

const EmptyList = styled.div`
  margin: 0 auto;
  font-size: 16px;
  color: ${({ theme }) => theme.tenantNameColor};
  text-align: center;
`;

const Back = styled(BackIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

const DataCell = styled.td<{ type: string }>`
  background: ${({ theme, type }) => theme[COLORS[type]]};
`;

const Item = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
`;

const Header = styled.th`
  background-color: ${({ theme }) => theme.lightGrey};
  font-weight: normal;
`;

const HeaderText = styled.span`
  vertical-align: super;
`;

const List = styled.ul`
  padding-inline-start: 0;
  list-style-type: none;
  font-size: 1rem;
`;

const ListItem = styled.li`
  &:not(&:first-child) {
    margin-top: 4px;
  }
`;

const SiteName = styled(ListItem)`
  color: ${({ theme }) => theme.mainRed};
  font-size: 1.1rem;
`;

const inputStyles = css`
  border-bottom: 2px solid ${({ theme }) => theme.primaryGrey};
  border-radius: unset;
  padding: 0.4rem 1rem;
  background: ${({ theme }) => theme.lightGrey};

  &:focus {
    outline: none;
  }
`;

export { TableContainer, EmptyList, Back, DataCell, Item, Header, HeaderText, SiteName, List, ListItem, inputStyles };
