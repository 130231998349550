import { Paper } from 'material-ui';
import styled from 'styled-components';

export const Section = styled(Paper)`
  border-radius: 10px !important;
  overflow: hidden !important;
  margin-bottom: 15px;
`;

export const Title = styled.div`
  padding: 15px 10px;
  color: ${({ theme }) => theme.primaryWhite};
  background-color: ${({ warning, theme }) => (warning ? theme.primaryRed : theme.darkGrey)};
`;

export const Content = styled.div`
  padding: 20px 20px 20px 20px;
`;
