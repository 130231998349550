export const partCards = [
  {
    title: 'My Part Info',
    titleType: 'my',
    prefix: 'MyPart',
    buttons: [
      { buttonTitle: 'Copy\n OEM Data', copyValue: 'OEMPart' },
      { buttonTitle: 'Copy\n Suppl Data', copyValue: 'SupplierPart' },
    ],
  },
  {
    title: 'OEM Info:',
    titleType: 'oem',
    prefix: 'OEMPart',
    field: 'OEMID',
    buttons: [
      { buttonTitle: 'Copy\n My Data', copyValue: 'MyPart' },
      { buttonTitle: 'Copy\n Suppl Data', copyValue: 'SupplierPart' },
    ],
  },
  {
    title: 'Supplier Info',
    titleType: 'supplier',
    prefix: 'SupplierPart',
    field: 'SupplierID',
    buttons: [
      { buttonTitle: 'Copy\n My Data', copyValue: 'MyPart' },
      { buttonTitle: 'Copy\n OEM Data', copyValue: 'OEMPart' },
    ],
  },
];

export const myPartFields = [
  { name: 'ID', fieldType: 'text' },
  { name: 'Name', fieldType: 'text' },
  { name: 'CategoryID', fieldType: 'select', list: 'category' },
  { name: 'TypeID', fieldType: 'select', list: 'type' },
  { name: 'UnitOfMeasureID', fieldType: 'select', list: 'unitOfMeasure' },
];
export const morePartFields = [
  { name: 'Description', fieldType: 'text' },
  { name: 'SerialNumber', fieldType: 'text' },
  { name: 'RevisionNumber', fieldType: 'text' },
  { name: 'RevisionDate', fieldType: 'date' },
  { name: 'URL', fieldType: 'url' },
];

export const attributesFields = [
  { name: 'SizingStandardID', fieldType: 'select', list: 'sizingStandard' },
  { name: 'Width', fieldType: 'text', isNumber: true },
  { name: 'Height', fieldType: 'text', isNumber: true },
  { name: 'Depth', fieldType: 'text', isNumber: true },
  { name: 'Girth', fieldType: 'text', isNumber: true },
  { name: 'MaterialTypeID', fieldType: 'select', list: 'materialType' },
  { name: 'WeightStandardID', fieldType: 'select', list: 'weightStandard' },
  { name: 'Weight', fieldType: 'text', isNumber: true },
];

export const financialAttributesFields = [
  { name: 'Currency', fieldType: 'text' },
  { name: 'UOMTotal', fieldType: 'text', isNumber: true },
  { name: 'UOMIndividual', fieldType: 'text', isNumber: true },
  { name: 'Symbol', fieldType: 'text' },
  { name: 'HandlingCharge', fieldType: 'text', isNumber: true },
  { name: 'ProcessingCharge', fieldType: 'text', isNumber: true },
  { name: 'TotalPrice', fieldType: 'text', isNumber: true },
  { name: 'IsTaxable', fieldType: 'text' },
  { name: 'TaxRate', fieldType: 'text', isNumber: true },
  { name: 'TotalTax', fieldType: 'text', isNumber: true },
  { name: 'TotalWithTax', fieldType: 'text', isNumber: true },
];

export const fieldLabels = {
  MyPartID: { label: 'My Part ID' },
  MyPartName: { label: 'Part Name' },
  MyPartCategoryID: { label: 'Part Category' },
  MyPartTypeID: { label: 'Part Type' },
  MyPartUnitOfMeasureID: { label: 'Part Unit Of Measure' },
  MyPartDescription: { label: 'Part Description' },
  MyPartSerialNumber: { label: 'Part Serial Number' },
  MyPartRevisionNumber: { label: 'Part Revision Number' },
  MyPartRevisionDate: { label: 'Part Revision Date' },
  MyPartURL: { label: 'Part URL' },
  MyPartSizingStandardID: { label: 'Sizing Standard' },
  MyPartWidth: { label: 'Width' },
  MyPartHeight: { label: 'Height' },
  MyPartDepth: { label: 'Depth' },
  MyPartGirth: { label: 'Girth' },
  MyPartMaterialTypeID: { label: 'Material Type' },
  MyPartWeightStandardID: { label: 'Weight Standard' },
  MyPartWeight: { label: 'Weight' },
  OEMPartID: { label: 'OEM Part ID' },
  OEMPartName: { label: 'Part Name' },
  OEMPartCategoryID: { label: 'Part Category' },
  OEMPartTypeID: { label: 'Part Type' },
  OEMPartUnitOfMeasureID: { label: 'Part Unit Of Measure' },
  OEMPartDescription: { label: 'Part Description' },
  OEMPartSerialNumber: { label: 'Part Serial Number' },
  OEMPartRevisionNumber: { label: 'Part Revision Number' },
  OEMPartRevisionDate: { label: 'Part Revision Date' },
  OEMPartURL: { label: 'Part URL' },
  OEMPartSizingStandardID: { label: 'Sizing Standard' },
  OEMPartWidth: { label: 'Width' },
  OEMPartHeight: { label: 'Height' },
  OEMPartDepth: { label: 'Depth' },
  OEMPartGirth: { label: 'Girth' },
  OEMPartMaterialTypeID: { label: 'Material Type' },
  OEMPartWeightStandardID: { label: 'Weight Standard' },
  OEMPartWeight: { label: 'Weight' },
  SupplierPartID: { label: 'Suppl Part ID' },
  SupplierPartName: { label: 'Part Name' },
  SupplierPartCategoryID: { label: 'Part Category' },
  SupplierPartTypeID: { label: 'Part Type' },
  SupplierPartUnitOfMeasureID: { label: 'Part Unit Of Measure' },
  SupplierPartDescription: { label: 'Part Description' },
  SupplierPartSerialNumber: { label: 'Part Serial Number' },
  SupplierPartRevisionNumber: { label: 'Part Revision Number' },
  SupplierPartRevisionDate: { label: 'Part Revision Date' },
  SupplierPartURL: { label: 'Part URL' },
  SupplierPartSizingStandardID: { label: 'Sizing Standard' },
  SupplierPartWidth: { label: 'Width' },
  SupplierPartHeight: { label: 'Height' },
  SupplierPartDepth: { label: 'Depth' },
  SupplierPartGirth: { label: 'Girth' },
  SupplierPartMaterialTypeID: { label: 'Material Type' },
  SupplierPartWeightStandardID: { label: 'Weight Standard' },
  SupplierPartWeight: { label: 'Weight' },

  MyPartCurrency: { label: 'Currency' },
  MyPartUOMTotal: { label: 'Price Per Unit of Measure' },
  MyPartUOMIndividual: { label: 'Price Breakout Per UOM' },
  MyPartSymbol: { label: 'Price Symbol' },
  MyPartHandlingCharge: { label: 'Handling Charge' },
  MyPartProcessingCharge: { label: 'Processing Charge' },
  MyPartTotalPrice: { label: 'TOTAL PRICE' },
  MyPartIsTaxable: { label: 'Taxable' },
  MyPartTaxRate: { label: 'Tax Rate %' },
  MyPartTotalTax: { label: 'Tax Total' },
  MyPartTotalWithTax: { label: 'TOTAL WITH TAX' },

  OEMPartCurrency: { label: 'Currency' },
  OEMPartUOMTotal: { label: 'Price Per Unit of Measure' },
  OEMPartUOMIndividual: { label: 'Price Breakout Per UOM' },
  OEMPartSymbol: { label: 'Price Symbol' },
  OEMPartHandlingCharge: { label: 'Handling Charge' },
  OEMPartProcessingCharge: { label: 'Processing Charge' },
  OEMPartTotalPrice: { label: 'TOTAL PRICE' },
  OEMPartIsTaxable: { label: 'Taxable' },
  OEMPartTaxRate: { label: 'Tax Rate %' },
  OEMPartTotalTax: { label: 'Tax Total' },
  OEMPartTotalWithTax: { label: 'TOTAL WITH TAX' },

  SupplierPartCurrency: { label: 'Currency' },
  SupplierPartUOMTotal: { label: 'Price Per Unit of Measure' },
  SupplierPartUOMIndividual: { label: 'Price Breakout Per UOM' },
  SupplierPartSymbol: { label: 'Price Symbol' },
  SupplierPartHandlingCharge: { label: 'Handling Charge' },
  SupplierPartProcessingCharge: { label: 'Processing Charge' },
  SupplierPartTotalPrice: { label: 'TOTAL PRICE' },
  SupplierPartIsTaxable: { label: 'Taxable' },
  SupplierPartTaxRate: { label: 'Tax Rate %' },
  SupplierPartTotalTax: { label: 'Tax Total' },
  SupplierPartTotalWithTax: { label: 'TOTAL WITH TAX' },
};

export const editModalValues = {
  oems: {
    placeholder: 'filter OEMs',
    title: 'OEM Companies',
    deleteId: 'originalEquipmentManufacturerId',
    addItem: 'addOemRequest',
    editItem: 'editOemRequest',
    deleteItem: 'deleteOemRequest',
  },
  suppliers: {
    placeholder: 'filter Suppliers',
    title: 'Supplier Companies',
    deleteId: 'supplierId',
    addItem: 'addSupplierRequest',
    editItem: 'editSupplierRequest',
    deleteItem: 'deleteSupplierRequest',
  },
};
