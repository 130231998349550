import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Toggle from 'components/Toggle';
import Subheader from 'components/Subheader';
import TableList from 'components/TableList';
import { AddIcon } from 'components/Layout/Icons';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { actions as statusIndexActions } from 'redux/statusIndex';
import { searchItemByKeyword, sortObjectByKey, sortFormattedDate } from 'helpers';
import { IndexItemType } from 'configs/propTypes';

import { indexItemsToggle, tableConfigs } from './configs';
import IndexSetup from './IndexSetup';

import * as S from './styled';

class GlobalIndex extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      setIndexItemsStatus: PropTypes.func.isRequired,
      fetchAllIndexItemsRequest: PropTypes.func.isRequired,
      fetchIndexItemRequest: PropTypes.func.isRequired,
    }).isRequired,
    indexItems: PropTypes.arrayOf(IndexItemType).isRequired,
    status: PropTypes.string.isRequired,
  };

  state = {
    searchKeyword: '',
    mode: indexItemsToggle.find(el => el.key === this.props.match.params.type),
    isConfirmationDialogOpened: false,
    data: this.props.indexItems,
    isIndexSetupModalOpen: false,
    isEditIndexModalOpen: false,
    isCreateIndexModalOpen: false,
    nextSortBy: 'asc',
  };

  componentDidMount() {
    const {
      actions: { setIndexItemsStatus, fetchAllIndexItemsRequest },
    } = this.props;

    setIndexItemsStatus('pending');
    fetchAllIndexItemsRequest();
  }

  componentDidUpdate() {
    const { status, indexItems } = this.props;

    if (status === 'updated') {
      this.setState({ data: indexItems });
    }
  }

  goBack = () => this.props.history.push('/settings');

  goToDetailsPage = id => this.props.history.push(`/settings/global-index/${this.props.match.params.type}/${id}`);

  searchInList = e => this.setState({ searchKeyword: e.currentTarget.value });

  openIndexSetupModal = () => this.setState({ isIndexSetupModalOpen: true });

  closeIndexSetupModal = () =>
    this.setState({
      isIndexSetupModalOpen: false,
      isEditIndexModalOpen: false,
      isCreateIndexModalOpen: false,
    });

  renderRightControllCell = item => {
    const handleClick = e => {
      e.stopPropagation();
      this.goToDetailsPage(item.Id);
    };

    return (
      <td>
        <S.IconButton tooltip="Index Item Characteristics" tooltipPosition="top-left" onClick={handleClick}>
          <S.IconForward />
        </S.IconButton>
      </td>
    );
  };

  leftButtons = [
    {
      icon: <S.Back />,
      handler: this.goBack,
      hint: 'Back',
    },
  ];

  rightButtons = [
    {
      icon: <AddIcon />,
      handler: () => {
        this.openIndexSetupModal();
        this.setState({ isCreateIndexModalOpen: true });
      },
      hint: 'Add index item',
      tooltipStyles: { top: '24px' },
    },
  ];

  handleToggle = mode => {
    this.setState({ mode }, () => {
      this.props.history.push(`/settings/global-index/${mode.key}`);
      this.openConfirmationDialog();
    });
  };

  renderTitle = () => (
    <div>
      <h2>Index Items</h2>
      <Toggle config={indexItemsToggle} selected={this.state.mode?.key} handler={this.handleToggle} />
    </div>
  );

  // eslint-disable-next-line consistent-return
  onColumnHeaderClick = (headerName, label) => {
    const { data, nextSortBy } = this.state;
    let sortedData;

    const sort = isAsc => {
      if (headerName === 'State') {
        return sortFormattedDate(data, 'CreatedOn', isAsc);
      } else if (headerName === 'ID') {
        return sortObjectByKey(data, label, isAsc);
      }

      return sortFormattedDate(data, label, isAsc);
    };

    switch (nextSortBy) {
      case 'name': {
        sortedData = sortObjectByKey(data, 'Name');
        this.setState({ nextSortBy: 'asc' });
        break;
      }
      case 'asc': {
        sortedData = sort();
        this.setState({ nextSortBy: 'desc' });
        break;
      }
      case 'desc': {
        sortedData = sort(false);

        this.setState({ nextSortBy: 'name' });
        break;
      }

      default:
        return data;
    }

    this.setState({ data: sortedData });
  };

  openConfirmationDialog = () => {
    const { key } = this.state.mode || {};
    if (key === 'upload' || key === 'api') {
      this.setState({ isConfirmationDialogOpened: true });
    }
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationDialogOpened: false });
    this.props.history.push('/settings/global-index/manual');
    this.setState({ mode: indexItemsToggle[0] });
  };

  onRowClick = item => {
    this.setState({ isEditIndexModalOpen: true });
    this.props.actions.fetchIndexItemRequest(item.Id);
    this.openIndexSetupModal();
  };

  render() {
    const {
      searchKeyword,
      isConfirmationDialogOpened,
      isIndexSetupModalOpen,
      isEditIndexModalOpen,
      isCreateIndexModalOpen,
      data,
      mode,
    } = this.state;
    const tableList = data.filter(({ Type }) => Type === mode.title);

    return (
      <>
        <Subheader
          leftButtons={this.leftButtons}
          rightButtons={mode.key === 'manual' ? this.rightButtons : []}
          title={this.renderTitle()}
          isSearch
          searchData={searchKeyword}
          searchInList={this.searchInList}
        />
        {tableList.length ? (
          <S.TableContainer>
            <TableList
              list={tableList.filter(searchItemByKeyword(tableConfigs, searchKeyword))}
              tableColumns={tableConfigs}
              tableHeads={tableConfigs}
              renderRightControllCell={this.renderRightControllCell}
              onColumnHeaderClick={this.onColumnHeaderClick}
              onRowClick={this.onRowClick}
            />
          </S.TableContainer>
        ) : (
          <S.EmptyList>
            <p>There are no index items</p>
          </S.EmptyList>
        )}
        <IndexSetup
          isCreateIndexModalOpen={isCreateIndexModalOpen}
          isEditModal={isEditIndexModalOpen}
          open={isIndexSetupModalOpen}
          type={mode?.title ?? ''}
          handleClose={this.closeIndexSetupModal}
        />
        <ConfirmationDialog
          title="Warning"
          open={isConfirmationDialogOpened}
          handleClose={this.closeConfirmationDialog}
          text={
            <div>
              <S.ConfirmationText>
                This is a custom feature. Please contact the incheq team for assistance.
              </S.ConfirmationText>
              <ActionsContainer>
                <ActionButton isNext label="OK" onClick={this.closeConfirmationDialog} />
              </ActionsContainer>
            </div>
          }
        />
      </>
    );
  }
}

const mapStateToProps = ({ statusIndex: { indexItems, status } }) => ({
  indexItems,
  status,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...statusIndexActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalIndex);
