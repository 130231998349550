import styled from 'styled-components';

export const DialogTitle = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  margin: 0;
`;

export const DialogText = styled.p`
  font-size: 18px;
  margin: 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const DialogWarning = styled(DialogText)`
  color: ${({ theme }) => theme.primaryRed};
`;
