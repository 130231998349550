import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { actions as shortcutActions } from 'redux/shortcuts';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import { ScheduleDto } from 'configs/propTypes';

import { AssignmentType } from 'configs/types';

import AssignmentForm from './Form';
import * as S from './styled';

class Assignment extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editSiteScheduleRequest: PropTypes.func.isRequired,
    }).isRequired,
    currentSchedule: ScheduleDto.isRequired,
    teamsAreEmpty: PropTypes.bool.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  };

  getInitialValues = () => {
    const { currentSchedule } = this.props;

    return {
      ...currentSchedule,
      AssignmentType: currentSchedule?.AssignmentType ? currentSchedule.AssignmentType - 1 : 0,
    };
  };

  state = {
    initialValues: this.getInitialValues(),
  };

  onSubmitForm = ({ values }) => {
    const { currentSchedule, actions, onNext } = this.props;

    const valuesToSend = {
      AssignmentType: AssignmentType[values.AssignmentType],
      id: currentSchedule.Id,
      onNext,
    };

    actions.editSiteScheduleRequest(valuesToSend);
  };

  onPrevious = e => {
    e.preventDefault();
    this.props.onBack();
  };

  render() {
    const { teamsAreEmpty } = this.props;
    const { initialValues } = this.state;

    return (
      <S.Container>
        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AssignmentForm teamsAreEmpty={teamsAreEmpty} />
              <ActionsContainer>
                <ActionButton label="Previous" onClick={this.onPrevious} isNext />
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts, teams }) => ({
  currentSchedule: shortcuts.currentSchedule,
  teamsAreEmpty: teams.list.length === 0,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assignment);
