const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validator = values => {
  const errors = {};
  if (!values.requestorEmail) {
    errors.requestorEmail = 'Email is required!';
  } else if (!values.requestorEmail.match(EMAIL_REGEX)) {
    errors.requestorEmail = 'Please enter a valid e-mail address';
  }
  if (!values.requestorName) {
    errors.requestorName = 'Name is required!';
  }
  if (!values.requestorCompany) {
    errors.requestorCompany = 'Company name is required!';
  }
  if (!values.industryId) {
    errors.industryId = 'Industry is required!';
  }
  if (!values.sitesCount) {
    errors.sitesCount = 'Sites count is required';
  }
  if (!values.itemsCount) {
    errors.itemsCount = 'Items count is required';
  }
  if (!values.sensorsCount) {
    errors.sensorsCount = 'Sensors count is required';
  }
  return errors;
};

export default validator;
