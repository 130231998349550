import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from 'material-ui/Snackbar/Snackbar';
import IconButton from 'material-ui/IconButton';

import { IconClose } from './styled';

const SnackBar = ({ handleClose, open, message, autoHideDuration, anchorOrigin }) => (
  <Snackbar
    anchorOrigin={anchorOrigin}
    open={open}
    onRequestClose={handleClose}
    autoHideDuration={autoHideDuration}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{message}</span>}
    action={[
      <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
        <IconClose />
      </IconButton>,
    ]}
  />
);

SnackBar.defaultProps = {
  autoHideDuration: 2000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

SnackBar.propTypes = {
  handleClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  anchorOrigin: PropTypes.object,
};

export default SnackBar;
