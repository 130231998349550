import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'components/Dialogs/v1/Base';
import { ExitToAppIcon } from 'components/Layout/Icons';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import { rangeStart } from 'configs/routes';

import { ChildrenType } from 'configs/propTypes';

import theme from 'theme';

import * as S from './styled';

const requiredAttentionTasksLink = `${document.location.origin}/queue/${rangeStart()}/all`;

const Section = ({ title, children }) => (
  <S.SectionContainer>
    <S.SectionHeader>{title}</S.SectionHeader>
    <S.SectionContent>{children}</S.SectionContent>
  </S.SectionContainer>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: ChildrenType.isRequired,
};

class DeleteShortcutDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      ChecklistCount: PropTypes.number,
      CompletedCount: PropTypes.number,
      InProgressCount: PropTypes.number,
      RequireAttentionCount: PropTypes.number,
      RequireAttentionSites: PropTypes.arrayOf(
        PropTypes.shape({
          AdditionalSiteID: PropTypes.string,
          Name: PropTypes.string,
          Id: PropTypes.string,
        }),
      ),
      SiteCount: PropTypes.number,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
  };
  state = {
    isConfirmationDeleteOpened: false,
  };

  openConfirmationDeleteDialog = () => {
    const { data, handleDelete } = this.props;
    if (data.RequireAttentionCount === 0) {
      handleDelete();
    } else {
      this.setState({ isConfirmationDeleteOpened: true });
    }
  };

  closeConfirmationDeleteDialog = () => {
    this.setState({ isConfirmationDeleteOpened: false });
  };

  render() {
    const { open, handleClose, handleDelete, data } = this.props;
    const { isConfirmationDeleteOpened } = this.state;

    return (
      <Dialog open={open} onRequestClose={handleClose} title="Alert" titleColor={theme.primaryRed}>
        <S.Container>
          <S.Title>Deleting this shortcut may disrupt tasks in progress!</S.Title>
          <S.RedText>
            Completed tasks will be moved to the <b>archive</b>
          </S.RedText>
          <Section title="Shortcut Status">
            <S.Text>
              <b>This shortcut is part of:</b>
            </S.Text>
            <S.Text>
              <b>{data.SiteCount}</b> Sites
            </S.Text>
            <S.Text>
              <b>{data.ChecklistCount}</b> Checklists
            </S.Text>
          </Section>
          <Section title="Tasks Status">
            <S.Text>
              <b>{data.InProgressCount}</b> Tasks are in progress
            </S.Text>
            <S.Text>
              <b>{data.CompletedCount}</b> Tasks are completed but not archived
            </S.Text>
            <S.RedText>
              <b>{data.RequireAttentionCount}</b> Tasks require attention
            </S.RedText>
          </Section>
          <Section title="Requiring Attention">
            {data.RequireAttentionSites.map(({ AdditionalSiteID, Name, Id }) => (
              <S.SiteContainer key={Id} href={`${requiredAttentionTasksLink}?site=${Name}`} target="_blank">
                <ExitToAppIcon />
                <S.Text>
                  {AdditionalSiteID} <b>{Name}</b>
                </S.Text>
              </S.SiteContainer>
            ))}
          </Section>
          <S.DeleteButton onClick={this.openConfirmationDeleteDialog}>
            Delete Shortcut, Related Checklists and Tasks
          </S.DeleteButton>
        </S.Container>
        <ConfirmationDialog
          label="Delete Shortcut"
          text={<S.ConfirmationTitle>{data.RequireAttentionCount} Tasks Requiring Attention!</S.ConfirmationTitle>}
          headingText="Are your sure you want to delete this shortcut?"
          open={isConfirmationDeleteOpened}
          onClick={handleDelete}
          handleClose={this.closeConfirmationDeleteDialog}
        />
      </Dialog>
    );
  }
}

export default DeleteShortcutDialog;
