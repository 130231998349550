import http from '..';
import { formatUnitItem, formatTaskDuplicate } from 'redux/unit/formatters';

export const fetchItem = itemId => http.post('/Item/Get', `id=${itemId}`).then(({ data }) => data.entity);

export const fetchPictures = itemId =>
  http.post('/ItemPicture/List', `parentId=${itemId}`).then(({ data }) => data.root);

export const fetchPDFs = itemId => http.post('/ItemPdf/List', `parentId=${itemId}`).then(({ data }) => data.root);

export const fetchTaskTemplates = itemId =>
  http.post('/TaskTemplate/List', `itemId=${itemId}`).then(({ data }) => data.root);

export const deletePfd = (pdfId, pdfName, itemId) =>
  http.post('/ItemPdf/Delete', `id=${pdfId}&name=${pdfName}&parentId=${itemId}`);

export const uploadPdf = (itemId, params) => http.post(`/ItemPdf/Post/${itemId}`, params);

export const deletePicture = (pictureId, pictureName, itemId) =>
  http.post('/ItemPicture/Delete', `id=${pictureId}&name=${pictureName}&parentId=${itemId}`);

export const uploadPicture = (itemId, params) => http.post(`/ItemPicture/Post/${itemId}`, params);

export const deleteTask = (taskId, itemId) => http.post('/TaskTemplate/Delete', `id=${taskId}&itemId=${itemId}`);

export const deleteItem = (itemId, unitId) => http.post('/Item/Delete', `id=${itemId}&unitId=${unitId}`);

export const editItem = payload => {
  const { Id, UnitId } = payload;
  const dto = formatUnitItem(payload, UnitId);
  return http.post(`/Item/Put/${Id}`, { dto });
};

export const getBarcode = params => http.post('/Item/GetDefaultBarcodeValue/', params);

export const editTask = (itemId, dto) => http.post(`/TaskTemplate/Put/?id=${dto.Id}&itemId=${itemId}`, { dto });

export const addTask = (itemId, dto) => http.post(`/TaskTemplate/Post?itemId=${itemId}`, { dto });

export const duplicateTask = ({ itemId, taskId }) => {
  const params = formatTaskDuplicate(itemId, taskId);
  return http.post('/TaskTemplate/Copy', params);
};

export const getImageSource = (itemId, barcodeValue, initialBarcode) => {
  let barcode = '';
  let timestamp = '';

  if (barcodeValue === initialBarcode && barcodeValue !== barcode) {
    timestamp = Date.now();
    barcode = barcodeValue;
  }

  return (
    `${http.defaults.baseURL}/ItemBarcode/Get` +
    `?itemId=${itemId}&siteId=${http.defaults.siteId}&width=200&height=200&inline=true&_dc=${timestamp}`
  );
};
