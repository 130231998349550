import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';
import { Row } from 'components/Layout/Containers';

export const ChooseButton = styled.div`
  margin-right: 10px;
  padding: 2px 8px;
  color: ${({ theme }) => theme.primaryWhite};
  background-color: ${({ theme }) => theme.primaryDocuments};
  border-radius: 2px;
`;

export const Container = styled(Row)`
  overflow: hidden;
  padding: 5px 10px;
`;

export const CustomFileInput = styled.label`
  display: inline-flex;
  flex-wrap: wrap;
  cursor: pointer;
`;

export const DeleteButton = styled(ButtonIcon).attrs({
  style: ({ theme }) => ({
    backgroundColor: theme.primaryWhite,
  }),
})``;

export const FileInput = styled.input.attrs({
  type: 'file',
})`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  :focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  & + label:hover {
    opacity: 0.8;
  }
`;

export const FileName = styled.div`
  font-size: 14px;
  margin-top: 4px;
  min-width: 0;
  word-break: break-word;
  word-wrap: break-word;
`;
