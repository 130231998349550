import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentContainer, ListContainer } from 'components/Layout/Containers';
import LinksDialog from 'components/Dialogs/LinksDialog';
import LogoDialog from 'components/Dialogs/LogoDialog';
import BeaconDialog from 'components/Dialogs/BeaconDialog';
import ScanDialog from 'components/Dialogs/ScanDialog';
import TextDialog from 'components/Dialogs/TextDialog';
import WarningModal from 'components/Dialogs/Warning';
import EditableListDialog from 'components/Dialogs/EditableListDialog';
import SetFunctionsDialog from 'components/Dialogs/SetFunctionsDialog';

import DemoTasksDialog from './ui/dialogs/DemoTasksDialog';
import PdfReportDetailsDialog from './ui/dialogs/pdfReportDetailsDialog';
import SetTimezonesDialog from './ui/dialogs/SetTimezonesDialog';
import SetIoTNotificationsDialog from './ui/dialogs/SetIoTNotificationsDialog';
import SetNotificationsDialog from './ui/dialogs/SetNotificationsDialog';
import SelectUserRoleDialog from './ui/dialogs/PeopleNotificationDialog/SelectUserRoleDialog';
import OptionsListDialog from './ui/dialogs/OptionsListDialog';
import AvailabilityOptionsDialog from './ui/dialogs/AvailabilityOptionsDialog';
import GlobalScheduleTriggerDialog from './ui/dialogs/GlobalScheduleTriggerDialog';
import TaskArchivingSchedulesDialog from './ui/dialogs/TaskArchivingSchedulesDialog';
import PastDueBacklogDialog from './ui/dialogs/PastDueBacklogDialog';

import { General } from './components/General';
import { IoT } from './components/IoT';
import { Reports } from './components/Reports';
import { Verification } from './components/Verification';
import { Notifications } from './components/Notifications';
import { Scheduling } from './components/Scheduling';
import { Parts } from './components/Parts';
import { External } from './components/External';

import { actions as peopleActions } from 'redux/people';
import { actions as sitesActions } from 'redux/sites';
import { actions as settingsActions } from 'redux/settings';
import { actions as appActions } from 'redux/app';
import { actions as partsActions } from 'redux/parts';
import { openErrorDialog } from 'redux/errorHandler';

const SettingsList = () => {
  const [beaconOpened, setBeaconOpened] = useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [corporateLogoOpened, setCorporateLogoOpened] = useState(false);
  const [demoTasksOpened, setDemoTasksOpened] = useState(false);
  const [distance, setDistance] = useState(1);
  const [distanceItem, setDistanceItem] = useState(1);
  const [distanceUnit, setDistanceUnit] = useState(1);
  const [isResetLinks, setIsResetLinks] = useState(false);
  const [linksOpened, setLinksOpened] = useState(false);
  const [pdfReportDetailsOpened, setPdfReportDetailsOpened] = useState(false);
  const [resetDeleteButtonForDemoTasks, setResetDeleteButtonForDemoTasks] = useState(false);
  const [scanOpened, setScanOpened] = useState(false);
  const [textOpened, setTextOpened] = useState(false);
  const [warningOpened, setWarningOpened] = useState(false);
  const [timezonesDialogOpen, setTimezonesDialogOpen] = useState(false);
  const [functionsDialogOpen, setFunctionsDialogOpen] = useState(false);
  const [ioTNotificationDialogOpen, setIoTNotificationDialogOpen] = useState(false);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [peopleNotificationDialogOpen, setPeopleNotificationDialogOpen] = useState(false);
  const [editOEMListDialogOpen, setEditOEMListDialogOpen] = useState(false);
  const [editSuppliersDialogOpen, setEditSuppliersDialogOpen] = useState(false);
  const [optionsListDialogOpen, setOptionsListDialogOpen] = useState(false);
  const [availabilityOptionsDialogOpen, setAvailabilityOptionsDialogOpen] = useState(false);
  const [scheduleTriggersDialogOpen, setScheduleTriggersDialogOpen] = useState(false);
  const [tasksArchivingSchedulesDialogOpen, setTasksArchivingSchedulesDialogOpen] = useState(false);
  const [isPastDueBacklogDialogOpen, setIsPastDueBacklogDialogOpen] = useState(false);

  const error = useSelector(state => state.settings.error || state.parts.error);
  const isUpdated = useSelector(state => state.settings.isUpdated);
  const masterSystemLinks = useSelector(state => state.settings.masterSystemLinks);
  const settings = useSelector(state => state.settings.list);
  const systemLinks = useSelector(state => state.settings.systemLinks);
  const oemList = useSelector(state => state.parts.oemList);
  const suppliersList = useSelector(state => state.parts.suppliersList);
  const isDemoTasksMode = useSelector(state => (state.settings.list.IsDemoTasksMode ? 'DemoTasks' : 'LiveTasks'));

  const dispatch = useDispatch();

  const changeDistance = (event, index, value) => setDistance(value);

  const changeDistanceItem = (event, index, value) => setDistanceItem(value);

  const changeDistanceUnit = (event, index, value) => setDistanceUnit(value);

  const closeBeaconDialog = () => setBeaconOpened(false);
  const openBeaconDialog = () => setBeaconOpened(true);

  const closePdfReportDetailsDialog = () => setPdfReportDetailsOpened(false);

  const closeCorporateLogoDialog = () => setCorporateLogoOpened(false);

  const openPdfReportDetailsDialog = () => setPdfReportDetailsOpened(true);

  const openPastDueBacklogDialog = () => setIsPastDueBacklogDialogOpen(true);

  const closePastDueBacklogDialog = () => setIsPastDueBacklogDialogOpen(false);

  const openCorporateLogoDialog = () => {
    dispatch(settingsActions.getLogoRequest());
    dispatch(settingsActions.getLogoEmailRequest());
    setCorporateLogoOpened(true);
  };

  const closeDemoTasksDialog = () => setDemoTasksOpened(false);

  const openDemoTasksDialog = () => {
    dispatch(settingsActions.getDemoTasksCountRequest());
    setDemoTasksOpened(true);
  };

  const closeScanDialog = () => {
    dispatch(settingsActions.resetMasterSettingsForAdmin());
    setScanOpened(false);
  };

  const openScanDialog = () => setScanOpened(true);

  const closeTextDialog = () => {
    dispatch(settingsActions.resetMasterSettingsForAdmin());
    setTextOpened(false);
  };

  const openTextDialog = () => setTextOpened(true);

  const closeWarningDialog = () => setWarningOpened(false);

  const submitBeaconDialog = values => {
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: false,
        values: { ...settings, ...values },
      }),
    );
    closeBeaconDialog();
  };

  const submitPdfDialog = values => {
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: false,
        values: { ...settings, ...values },
      }),
    );
    closePdfReportDetailsDialog();
  };

  const submitDemoTasksDialog = ({ IsDemoTasksMode, DeleteDemoTasks }) => {
    if (DeleteDemoTasks) {
      setWarningOpened(true);
    } else {
      dispatch(
        settingsActions.setSettingsRequest({
          isMaster: false,
          values: { ...settings, IsDemoTasksMode: IsDemoTasksMode === 'DemoTasks' },
        }),
      );
      closeDemoTasksDialog();
    }
  };

  const submitScanDialog = values => {
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: false,
        values: { ...settings, ...values },
      }),
    );
    closeScanDialog();
  };

  const submitSchedulingDialog = values => {
    // TODO
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: false,
        values: { ...settings, ...values },
      }),
    );
  };

  const submitTextDialog = values => {
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: false,
        values: { ...settings, ...values },
      }),
    );
    closeTextDialog();
  };

  const closeLinksDialog = () => {
    dispatch(appActions.updateCurrentGlobalSettingsModule());
    dispatch(settingsActions.resetMasterSettingsForAdmin());
    setChangedValues({});
    setLinksOpened(false);
  };

  const submitLinksDialog = values => {
    dispatch(
      settingsActions.setSettingsRequest({
        isMaster: false,
        values: { ...settings, LinkIntroText: values.LinkIntroText },
      }),
    );

    dispatch(
      settingsActions.setSettingsLinksRequest({ requestValues: changedValues, isMaster: false, stateValues: values }),
    );
    closeLinksDialog();
  };

  const getChangedValues = (value, rest) => {
    setChangedValues({
      ...changedValues,
      [rest.link.Id]: rest.reset
        ? rest.link
        : {
            ...(changedValues[rest.link.Id] ? changedValues[rest.link.Id] : rest.link),
            [rest.name.substring(1)]: value,
          },
    });
  };

  const resetLinks = () => setIsResetLinks(true);

  const openLinksDialog = () => {
    dispatch(appActions.updateCurrentGlobalSettingsModule('systemLinks'));
    dispatch(settingsActions.getSettingsLinksListRequest({ isMaster: false }));
    setLinksOpened(true);
  };

  const openSetTimezonesDialog = () => setTimezonesDialogOpen(true);
  const closeSetTimezonesDialog = () => setTimezonesDialogOpen(false);

  const openFunctionsDialog = () => setFunctionsDialogOpen(true);
  const closeFunctionsDialog = () => setFunctionsDialogOpen(false);

  const openSetIoTNotificationsDialog = () => setIoTNotificationDialogOpen(true);
  const closeSetIoTNotificationsDialog = () => setIoTNotificationDialogOpen(false);

  const openSetNotificationDialog = () => setNotificationDialogOpen(true);
  const closeSetNotificationDialog = () => setNotificationDialogOpen(false);

  const openPeopleNotificationDialog = () => setPeopleNotificationDialogOpen(true);
  const closePeopleNotificationDialog = () => setPeopleNotificationDialogOpen(false);

  const openAvailabilityOptionsDialog = () => setAvailabilityOptionsDialogOpen(true);
  const closeAvailabilityOptionsDialog = () => setAvailabilityOptionsDialogOpen(false);

  const openScheduleTriggersDialog = () => setScheduleTriggersDialogOpen(true);
  const closeScheduleTriggersDialog = () => setScheduleTriggersDialogOpen(false);

  const openTasksArchivingSchedulesDialog = () => setTasksArchivingSchedulesDialogOpen(true);
  const closeTasksArchivingSchedulesDialog = () => setTasksArchivingSchedulesDialogOpen(false);

  const openEditOEMListDialog = () => {
    dispatch(partsActions.getOemListRequest());
    setEditOEMListDialogOpen(true);
  };

  const closeEditOEMListDialog = () => setEditOEMListDialogOpen(false);

  const openEditSuppliersListDialog = () => {
    dispatch(partsActions.getSuppliersListRequest());
    setEditSuppliersDialogOpen(true);
  };

  const closeEditSuppliersListDialog = () => setEditSuppliersDialogOpen(false);

  const openOptionsListDialog = () => setOptionsListDialogOpen(true);
  const closeOptionsListDialog = () => setOptionsListDialogOpen(false);

  useEffect(() => {
    dispatch(sitesActions.resetSitesList());
    dispatch(sitesActions.sitesListRequest());
    dispatch(settingsActions.getSettingsRequest());
    dispatch(settingsActions.resetMasterSettingsForAdmin());
    dispatch(peopleActions.peopleListRequest());
  }, [dispatch]);

  useEffect(() => {
    if (isUpdated) {
      closePdfReportDetailsDialog();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (error) {
      dispatch(openErrorDialog(error, 'Error'));
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (isResetLinks && masterSystemLinks.length > 0) {
      // eslint-disable-next-line no-shadow
      const changedValues = {};

      systemLinks.forEach((link, index) => {
        changedValues[link.Id] = masterSystemLinks[index];
      });

      setChangedValues(changedValues);
      setIsResetLinks(false);
    }
  }, [isResetLinks, masterSystemLinks, systemLinks]);

  return [
    <ContentContainer key="SettingsListContainer">
      <ListContainer>
        <General
          openCorporateLogoDialog={openCorporateLogoDialog}
          openDemoTasksDialog={openDemoTasksDialog}
          openFunctionsDialog={openFunctionsDialog}
        />
        <IoT />
        <Reports openPdfReportDetailsDialog={openPdfReportDetailsDialog} />
        <Verification openBeaconDialog={openBeaconDialog} openScanDialog={openScanDialog} />
        <Notifications
          openTextDialog={openTextDialog}
          openLinksDialog={openLinksDialog}
          openSetNotificationDialog={openSetNotificationDialog}
          openPeopleNotificationDialog={openPeopleNotificationDialog}
          openSetIoTNotificationsDialog={openSetIoTNotificationsDialog}
        />
        <Scheduling
          openSetTimezonesDialog={openSetTimezonesDialog}
          openScheduleTriggersDialog={openScheduleTriggersDialog}
          openAvailabilityOptionsDialog={openAvailabilityOptionsDialog}
          openTasksArchivingSchedulesDialog={openTasksArchivingSchedulesDialog}
          openPastDueBacklogDialog={openPastDueBacklogDialog}
        />
        <Parts
          openEditOEMListDialog={openEditOEMListDialog}
          openEditSuppliersListDialog={openEditSuppliersListDialog}
          openOptionsListDialog={openOptionsListDialog}
        />
        <External />
      </ListContainer>
    </ContentContainer>,
    <LogoDialog
      key="CorporateLogoDialog"
      handleClose={closeCorporateLogoDialog}
      title="Corporate Logo"
      open={corporateLogoOpened}
    />,
    <DemoTasksDialog
      key="DemoTasksDialog"
      handleClose={closeDemoTasksDialog}
      title="Demo Mode Settings"
      open={demoTasksOpened}
      onSubmit={submitDemoTasksDialog}
      resetDeleteButtonForDemoTasks={resetDeleteButtonForDemoTasks}
      initialValues={{ IsDemoTasksMode: isDemoTasksMode }}
      withoutTopBorder
    />,
    <PdfReportDetailsDialog
      key="PdfReportDetailsDialog"
      handleClose={closePdfReportDetailsDialog}
      title="PDF Report Details"
      open={pdfReportDetailsOpened}
      settings={settings}
      onSubmit={submitPdfDialog}
    />,
    <BeaconDialog
      key="BeaconDialog"
      handleClose={closeBeaconDialog}
      open={beaconOpened}
      title="Beacons"
      site={settings}
      changeDistance={changeDistance}
      changeDistanceItem={changeDistanceItem}
      changeDistanceUnit={changeDistanceUnit}
      distance={distance}
      distanceItem={distanceItem}
      distanceUnit={distanceUnit}
      onSubmit={submitBeaconDialog}
      withoutTopBorder
    />,
    <ScanDialog
      key="ScanDialog"
      handleClose={closeScanDialog}
      title="QR settings for Units and Items"
      open={scanOpened}
      onSubmit={submitScanDialog}
      withoutTopBorder
      BigButtons
    />,
    <TextDialog
      key="TextDialog"
      handleClose={closeTextDialog}
      title="Notifications Text"
      open={textOpened}
      onSubmit={submitTextDialog}
      BigButtons
    />,
    <LinksDialog
      key="LinksDialog"
      handleClose={closeLinksDialog}
      title="System Links"
      open={linksOpened}
      BigButtons
      onSubmit={submitLinksDialog}
      getChangedValues={getChangedValues}
      resetLinks={resetLinks}
    />,
    <WarningModal
      key="WarningModal"
      handleClose={closeWarningDialog}
      onSubmit={closeWarningDialog}
      open={warningOpened}
      text="Please delete all demo tasks, or uncheck the option."
      title="This change can not be saved!"
    />,
    <SetTimezonesDialog
      key="SitesTimezones"
      open={timezonesDialogOpen}
      handleClose={closeSetTimezonesDialog}
      title="Set Time Zone"
    />,
    <SetFunctionsDialog
      key="Functions"
      open={functionsDialogOpen}
      handleClose={closeFunctionsDialog}
      title="Job functions"
    />,
    <SetIoTNotificationsDialog
      key="IoTNotifications"
      open={ioTNotificationDialogOpen}
      handleClose={closeSetIoTNotificationsDialog}
      title="Set Notification Time"
    />,
    <SetNotificationsDialog
      key="NotificationTime"
      open={notificationDialogOpen}
      handleClose={closeSetNotificationDialog}
      title="Set Notification Time"
    />,
    <SelectUserRoleDialog
      key="PeopleNotification"
      open={peopleNotificationDialogOpen}
      handleClose={closePeopleNotificationDialog}
      title="Select User Role"
    />,
    <EditableListDialog
      key="EditOEMs"
      open={editOEMListDialogOpen}
      handleClose={closeEditOEMListDialog}
      title="OEM Companies"
      searchPlaceholder="filter OEMs"
      idTitle="originalEquipmentManufacturerId"
      defaultList={oemList}
      addItem={data => partsActions.addOemRequest(data)}
      editItem={(id, name) => partsActions.editOemRequest(id, name)}
      deleteItem={data => partsActions.deleteOemRequest(data)}
      error={error}
    />,
    <EditableListDialog
      key="EditSuppliers"
      open={editSuppliersDialogOpen}
      handleClose={closeEditSuppliersListDialog}
      title="Supplier Companies"
      searchPlaceholder="filter Suppliers"
      idTitle="supplierId"
      defaultList={suppliersList}
      addItem={data => partsActions.addSupplierRequest(data)}
      editItem={(id, name) => partsActions.editSupplierRequest(id, name)}
      deleteItem={data => partsActions.deleteSupplierRequest(data)}
      error={error}
    />,
    <OptionsListDialog
      key="OptionsDialog"
      open={optionsListDialogOpen}
      handleClose={closeOptionsListDialog}
      error={error}
    />,
    <AvailabilityOptionsDialog
      key="AvailabilityOptionsDialog"
      open={availabilityOptionsDialogOpen}
      handleClose={closeAvailabilityOptionsDialog}
    />,
    <GlobalScheduleTriggerDialog
      key="GlobalScheduleTriggerDialog"
      open={scheduleTriggersDialogOpen}
      handleClose={closeScheduleTriggersDialog}
    />,
    <TaskArchivingSchedulesDialog
      key="TaskArchivingSchedulesDialog"
      open={tasksArchivingSchedulesDialogOpen}
      handleClose={closeTasksArchivingSchedulesDialog}
    />,
    <PastDueBacklogDialog open={isPastDueBacklogDialogOpen} handleClose={closePastDueBacklogDialog} />,
  ];
};

export default SettingsList;
