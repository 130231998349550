import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useFormik } from 'formik';
import { omit } from 'lodash';

import theme from 'theme';
import { getEntityQrCode } from 'http/inventory';
import { actions as inventoryActions } from 'redux/inventory';
import { InventoryLevelType, InventoryPartType, PartGroupsType } from 'types/inventory';

import { Modal } from 'components/Modal';
import { TextInput } from 'components/Input';
import RadioGroup from 'components/RadioButtons';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

import { PartInventoryLevels } from '../../ui/PartInventoryLevels';
import { InventoryInfoDialog } from '../InventoryInfoDialog';
import { PART_GROUP_INFO_CONFIGS, PART_INFO_CONFIGS } from './configs';
import { getStatusOptions } from './helpers';
import * as S from './styled';

type PropsType = {
  type: 'parts' | 'groups';
  data: InventoryPartType | PartGroupsType;
  history: RouteComponentProps['history'];
  inventoryPeriodId: string;
};

export const PartProfileDialog = NiceModal.create(({ data, type, history, inventoryPeriodId }: PropsType) => {
  const isPartInventory = type === 'parts';
  const title = isPartInventory ? 'Part' : 'Group';
  const modal = useModal();
  const dispatch = useDispatch();
  const entityType = isPartInventory ? 'Part' : 'PartGroup';
  const dto = { entityId: data.Id, entityType };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    onSubmit: values => {
      // eslint-disable-next-line no-shadow
      const dto = omit(values, 'POAdvice');

      if (isPartInventory) {
        dispatch(inventoryActions.editInventoryPartRequest({ dto, inventoryPeriodId }));
      } else {
        dispatch(inventoryActions.editInventoryPartGroupRequest({ dto, inventoryPeriodId }));
      }

      modal.hide();
    },
  });

  const info = isPartInventory ? PART_INFO_CONFIGS : PART_GROUP_INFO_CONFIGS;

  const onEdit = (fieldName: string) =>
    NiceModal.show(InventoryInfoDialog, {
      currentEntity: formik.values,
      type: 'edit',
      editedField: fieldName,
      entityType,
      changeField: (value: number) => formik.setFieldValue(fieldName, value),
    });

  const goToPartDetail = () =>
    history.push(
      `/parts/${type}/${isPartInventory ? (data as InventoryPartType).PartId : (data as PartGroupsType).PartGroupId}`,
    );

  const handleClose = () => {
    NiceModal.hide(ConfirmationModal);
    NiceModal.hide(PartProfileDialog);
  };

  const openConfirmationModal = () =>
    NiceModal.show(ConfirmationModal, {
      label: 'OK',
      labelCancel: 'RETURN',
      isCancelNext: true,
      text: (
        <S.ConfirmationText>
          <b>Leaving Inventory.</b>
          <div>Are you sure?</div>
        </S.ConfirmationText>
      ),
      maxWidth: '600px',
      onCancelClick: () => NiceModal.hide(ConfirmationModal),
      onClick: () => {
        handleClose();
        goToPartDetail();
      },
    });

  const removeInventoryPart = () => dispatch(inventoryActions.deleteInventoryEntityRequest({ dto, inventoryPeriodId }));

  const confirmDeleteModal = () =>
    NiceModal.show(ConfirmationModal, {
      label: 'REMOVE',
      labelCancel: 'RETURN',
      isCancelNext: true,
      text: (
        <S.ConfirmationText>
          <b>Removing this part!</b>
          <p>
            This will remove this part from the <b>next</b>
            <br /> period.
          </p>
          <div>
            It will also <b>lock</b> this part. <br /> Preventing Purchase and Release Orders.
          </div>
        </S.ConfirmationText>
      ),
      maxWidth: '600px',
      onCancelClick: () => NiceModal.hide(ConfirmationModal),
      onClick: () => {
        handleClose();
        removeInventoryPart();
      },
    });

  const getInventoryLevels = () => {
    const { Allocated, OnHand, PurchaseTriggerCount, Available, BackOrder, BufferCount, PurchaseOrderAdvice } =
      formik.values;

    const inventoryLevels: InventoryLevelType[] = [
      { quantity: Allocated, name: 'Allocated / Requested' },
      {
        quantity: OnHand,
        name: 'On-Hand',
        secondaryQuantity: PurchaseTriggerCount,
        secondaryName: 'Purchase Trigger',
        isEditable: true,
        field: 'PurchaseTriggerCount',
      },
      { quantity: Available, name: 'Available' },
      { quantity: BackOrder, name: 'Back-Order', isRedBack: true },
      { quantity: BufferCount, name: 'Buffer Purchase Order', field: 'BufferCount', isEditable: true },
      { quantity: PurchaseOrderAdvice, name: 'Current Purchase Order Advice', isRedBack: true },
    ];

    return inventoryLevels;
  };

  return (
    <Modal
      onRequestClose={modal.hide}
      title={`${title} Profile`}
      titleColor={theme.primaryDark}
      isOpen={modal.visible}
      onAfterClose={modal.remove}
    >
      <S.RootContainer onSubmit={formik.handleSubmit}>
        <S.Container>
          <RadioGroup
            input={{ ...formik.getFieldProps('Status') }}
            meta={formik.getFieldMeta('Status')}
            style={S.radioGroupStyle}
            name="Status"
            items={getStatusOptions(title)}
            radioButtonStyle={{}}
            labelStyle={{}}
          />
          {formik.values.QrCode.Value && (
            <S.ScanCodeContainer>
              <S.Image src={getEntityQrCode(dto)} alt="qr code" />
            </S.ScanCodeContainer>
          )}
        </S.Container>
        <BorderRadiusContainer column>
          <S.Label name="info">My {title} Info</S.Label>
          <S.PartInfoContainer>
            {info.map(({ label, name }) => (
              <TextInput
                key={name}
                {...formik.getFieldProps(name)}
                meta={formik.getFieldMeta(name)}
                label={label}
                withFloatingLabel
                inputStyles={S.inputStyles}
                inputWrapperStyles={S.inputWrapperStyles}
                name={name}
                disabled
              />
            ))}
            <S.Wrapper onClick={openConfirmationModal}>
              <S.LinkIcon />
              <S.Text>Full {title} Detail</S.Text>
            </S.Wrapper>
          </S.PartInfoContainer>
        </BorderRadiusContainer>
        <BorderRadiusContainer column>
          <S.Label name="inventoryLevels">{title} Inventory Levels</S.Label>
          <PartInventoryLevels data={getInventoryLevels()} onEdit={onEdit} />
        </BorderRadiusContainer>
        <ActionsContainer paddingValue={14} gapValue={20}>
          <ActionButton type="button" label={`Remove ${title}`} labelFontSize="18px" onClick={confirmDeleteModal} />
          <ActionButton
            type="submit"
            isNext
            isGreenLabel
            label="Save"
            labelFontSize="18px"
            disabled={!formik.dirty}
            isDisable={!formik.dirty}
          />
        </ActionsContainer>
      </S.RootContainer>
    </Modal>
  );
});
export { getStatusOptions };
