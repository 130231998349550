import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ADD_UNIT_REQUEST: undefined,
  ADD_UNIT_SUCCESS: undefined,
  ADD_UNIT_FAILURE: undefined,

  DUPLICATE_UNIT_REQUEST: undefined,
  DUPLICATE_UNIT_FAILURE: undefined,

  UNITS_LIST_REQUEST: undefined,
  UNITS_LIST_SUCCESS: undefined,
  UNITS_LIST_FAILURE: undefined,

  IMPORT_TENANT_TO_LIST_REQUEST: undefined,
  IMPORT_TENANT_TO_LIST_FAILURE: undefined,

  GET_UNITS_WITH_ITEMS_REQUEST: undefined,
  GET_UNITS_WITH_ITEMS_SUCCESS: undefined,
  GET_UNITS_WITH_ITEMS_FAILURE: undefined,

  GET_LIST_UNITS_WITH_ITEMS_REQUEST: undefined,
  GET_LIST_UNITS_WITH_ITEMS_SUCCESS: undefined,
  GET_LIST_UNITS_WITH_ITEMS_FAILURE: undefined,

  UPDATE_ITEMS_LIST_REQUEST: undefined,
  UPDATE_ITEMS_LIST_FAILURE: undefined,

  FETCH_ASSET_CATEGORIES_LIST_REQUEST: undefined,
  FETCH_ASSET_CATEGORIES_LIST_SUCCESS: undefined,
  FETCH_ASSET_TYPES_LIST_REQUEST: undefined,
  FETCH_ASSET_TYPES_LIST_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.addUnitRequest, handlers.addUnitRequest],
    [actions.addUnitSuccess, handlers.addUnitSuccess],
    [actions.addUnitFailure, handlers.addUnitFailure],

    [actions.duplicateUnitRequest, handlers.duplicateUnitRequest],
    [actions.duplicateUnitFailure, handlers.duplicateUnitFailure],

    [actions.unitsListRequest, handlers.unitsListRequest],
    [actions.unitsListSuccess, handlers.unitsListSuccess],
    [actions.unitsListFailure, handlers.unitsListFailure],

    [actions.importTenantToListRequest, handlers.importTenantToListRequest],
    [actions.importTenantToListFailure, handlers.importTenantToListFailure],

    [actions.getUnitsWithItemsRequest, handlers.getUnitsWithItemsRequest],
    [actions.getUnitsWithItemsSuccess, handlers.getUnitsWithItemsSuccess],
    [actions.getUnitsWithItemsFailure, handlers.getUnitsWithItemsFailure],

    [actions.getListUnitsWithItemsRequest, handlers.getListUnitsWithItemsRequest],
    [actions.getListUnitsWithItemsSuccess, handlers.getListUnitsWithItemsSuccess],
    [actions.getListUnitsWithItemsFailure, handlers.getListUnitsWithItemsFailure],

    [actions.updateItemsListRequest, handlers.updateItemsListRequest],
    [actions.updateItemsListFailure, handlers.updateItemsListFailure],

    [actions.fetchAssetCategoriesListRequest, handlers.fetchAssetCategoriesListRequest],
    [actions.fetchAssetCategoriesListSuccess, handlers.fetchAssetCategoriesListSuccess],
    [actions.fetchAssetTypesListRequest, handlers.fetchAssetTypesListRequest],
    [actions.fetchAssetTypesListSuccess, handlers.fetchAssetTypesListSuccess],
  ]),
  initialState,
);

export default reducer;
