import { saveSelectedSite } from 'helpers/storage';
import http from '../../http/index';

const initialState = {
  authenticated: false,
  checked: false,
  error: '',
  loading: false,
  reseted: false,
  selectedSite: '',
  siteName: '',
  user: {},
};

export const authenticateRequest = state => ({
  ...state,
  checked: true,
  loading: false,
});
export const authenticateSuccess = (state, { payload }) => ({
  ...state,
  authenticated: true,
  checked: true,
  ...payload,
});
export const authenticateFailure = (state, { payload }) => ({
  ...state,
  checked: true,
  error: payload.error || payload.message,
});

export const authenticationCheckRequest = state => ({
  ...state,
  loading: false,
});
export const authenticationCheckSuccess = (state, { payload }) => ({
  ...state,
  authenticated: true,
  checked: true,
  ...payload,
});
export const authenticationCheckFailure = state => ({
  ...state,
  checked: true,
});

export const fetchUserSitesRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchUserSitesSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  ...payload,
});
export const fetchUserSitesFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const forgotPasswordRequest = state => ({
  ...state,
  error: '',
  loading: true,
  reseted: false,
});
export const forgotPasswordSuccess = state => ({
  ...state,
  loading: false,
  reseted: true,
});
export const forgotPasswordFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const logoutRequest = state => ({
  ...state,
  loading: true,
});
export const logoutSuccess = () => ({
  ...initialState,
  checked: true,
});
export const logoutFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const resetPasswordRequest = state => ({
  ...state,
  error: '',
  loading: true,
  reseted: false,
});
export const resetPasswordSuccess = state => ({
  ...state,
  loading: false,
  reseted: true,
});
export const resetPasswordFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const resetedUnset = state => ({
  ...state,
  reseted: false,
});

export const selectedSiteChange = (state, { payload }) => {
  saveSelectedSite(payload.siteId);
  http.defaults.siteId = payload.siteId;
  return { ...state, selectedSite: payload.siteId };
};

export const onSiteChange = (state, { payload }) => {
  const [selectedId] = payload.sitesCollection.filter(site => site.siteName === payload.checkedSite);
  if (selectedId) {
    saveSelectedSite(selectedId.siteId);
    http.defaults.siteId = selectedId.siteId;
    return { ...state, selectedSite: selectedId.siteId, siteName: selectedId.siteName };
  }
  return { ...state };
};

export const usersLoginasRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const usersLoginasSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  ...payload,
});
export const usersLoginasFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const checkIsDemoUserNameExistsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const checkIsDemoTenantNameExistsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const checkIsDemoNameExistsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  isDemoTenantNameExists: payload,
});
export const checkIsDemoNameExistsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const createDemoTenantRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const createDemoTenantSuccess = state => ({
  ...state,
  loading: false,
});
export const createDemoTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const verifyTenantRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const verifyTenantSuccess = state => ({
  ...state,
  loading: false,
});
export const verifyTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const activateAccountRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const activateAccountSuccess = state => ({
  ...state,
  loading: false,
});
export const activateAccountFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const setAuthenticatedToFalse = state => ({
  ...state,
  authenticated: false,
});

export default initialState;
