import moment from 'moment';

export const getTenantRequests = requests =>
  requests.length > 0
    ? requests.map(
        ({
          Id,
          TenantName,
          FullName,
          CreatedOn,
          IsVerificationReceived,
          IndustryId,
          Email,
          SentOn,
          CompanySitesNumber,
          VerifiedIndustryId,
          VerifiedOn,
          Notes,
        }) => ({
          id: Id,
          tenantName: TenantName,
          fullName: FullName,
          requestDate: moment(CreatedOn).format('MMM-DD-YYYY'),
          requestTime: moment(CreatedOn).format('hh:mm A'),
          isVerificationReceived: IsVerificationReceived,
          industryId: IndustryId,
          email: Email,
          sentDate: SentOn && moment(SentOn).format('MMM-DD-YYYY'),
          sentTime: SentOn && moment(SentOn).format('hh:mm A'),
          companySitesNumber: CompanySitesNumber,
          verifiedIndustryId: VerifiedIndustryId,
          verificationDate: VerifiedOn && moment(VerifiedOn).format('MMM-DD-YYYY'),
          verificationTime: VerifiedOn && moment(VerifiedOn).format('hh:mm A'),
          notes: Notes,
        }),
      )
    : [];
