import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import moment from 'moment';

import TableList from 'components/TableList';
import { actions as scheduleActions } from 'redux/schedule';

import { DeleteQuickTaskDialog } from './DeleteQuickTaskDialog';
import { tableColumns } from './configs';
import { schedulerStatusHandler } from '../helpers';

import * as S from './styled';

export const QuickTaskDetails = () => {
  const list = useSelector(state => state.schedule.schedulesList);
  const { unitId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const deleteQuickTaskDialog = useModal(DeleteQuickTaskDialog);

  const mapList = item => {
    const onDetailsClick = e => {
      e.stopPropagation();
      history.push(`/scheduler/list/${unitId}/details/${item.Id}`);
    };

    return {
      ...item,
      TasksDueDate: <S.BoldText>{moment(item.CreatedOn).format('ddd - D MMMM YYYY')}</S.BoldText>,
      Status: (
        <S.StatusRowContainer>
          <S.IconForward onClick={onDetailsClick} />
          <S.Status color={schedulerStatusHandler(item)?.color} />
        </S.StatusRowContainer>
      ),
    };
  };

  const onRowClick = item => {
    deleteQuickTaskDialog.show({ quickTask: item });
    dispatch(scheduleActions.checklistStatusRequest({ checklistId: item.Id }));
  };

  return (
    <div>
      {list.length ? (
        <S.TableContainer>
          <TableList
            list={list.map(mapList)}
            tableHeads={tableColumns}
            tableColumns={tableColumns}
            onRowClick={onRowClick}
          />
        </S.TableContainer>
      ) : (
        <S.EmptyList>
          <S.EmptyListText>There are no quick tasks.</S.EmptyListText>
        </S.EmptyList>
      )}
    </div>
  );
};
