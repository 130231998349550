import http, { baseURL } from '../index';

export const getPdfList = dto => http.post('ExportQrPdf/List', dto);

export const deletePdf = dto => http.post('ExportQrPdf/Delete', dto);

export const createPdf = dto => http.post('ExportQrPdf/Generate', dto);

export const exportSitePdf = pdf => {
  if (pdf.siteId) {
    window.open(`${baseURL}/ExportQrPdf/Get?siteId=${pdf.siteId}&id=${pdf.id}&height=&width=&inline=true`);
  } else {
    window.open(`${baseURL}/ExportQrPdf/Get?allSite=true&id=${pdf}&height=&width=&inline=true`);
  }
};

export const exportUnitPdfToListExchange = (id, unitId) => {
  window.open(`${baseURL}/MasterUnitFile/Get?id=${id}&parentId=${unitId}&siteId=${http.defaults.siteId}`);
};

export const exportUnitItemPdfToListExchange = (id, itemId) => {
  window.open(`${baseURL}/MasterItemFile/Get?id=${id}&parentId=${itemId}&siteId=${http.defaults.siteId}`);
};
