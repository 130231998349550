import styled from 'styled-components';

const RootContainer = styled.div`
  background: ${({ theme }) => theme.primaryHealth};
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
  width: 28vw;
  padding: 1rem;
`;

const InfoContainer = styled.div`
  min-width: 28vw;
  overflow: hidden;
  border-radius: 10px;
  background: ${({ theme }) => theme.primaryWhite};
  margin: 1rem 0;
  padding: 1.4rem 0;
`;

const NotesContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.primaryGrey};
  text-align: start;
  padding: 0px 28px;
`;

const Name = styled.div`
  padding: 6px 0;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.primaryGrey};
`;

const Note = styled.div`
  font-size: 0.8rem;
`;

export { RootContainer, InfoContainer, NotesContainer, Name, Note };
