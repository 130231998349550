import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import theme from 'theme';
import moment from 'moment';

import Loader from 'components/Loader';
import { ButtonIcon } from 'components/Layout/Buttons';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

import { ScheduleInfo } from 'configs/propTypes';

import { OVERRIDE_VALUES, returnAssignmentType } from '../helpers';

import TasksTable from '../TasksTable';

import { Section, SectionHeader, SectionBody } from '../styled';
import * as S from './styled';

const Content = ({
  people,
  scheduleInfo,
  tasksToAdd,
  tasksToSend,
  openConfirmationDialog,
  openOverviewDialog,
  resetState,
  onAddItem,
  onAddTask,
  onClose,
}) => {
  const currentUnit = useSelector(state => state.schedule.currentUnit);
  const details = useSelector(state => state.schedule.details);
  const schedulesTrigger = useSelector(state => state.settings.list.TasksSchedulesTrigger);

  const isScheduleLoading = useSelector(state => state.schedule.loading);
  const isUnitLoading = useSelector(state => state.unit.loading);
  const isMissedScheduleLoading = useSelector(state => state.schedule.missingLoading);
  const isLoading = isScheduleLoading || isUnitLoading || isMissedScheduleLoading;

  const openOverview = () => {
    const diff = moment
      .duration(moment(scheduleInfo.ScheduleDto.ScheduleData.DailyStartDate).diff(moment(new Date())))
      .asDays();

    if (diff < OVERRIDE_VALUES[schedulesTrigger]) {
      openConfirmationDialog();
    } else {
      openOverviewDialog();
    }
  };

  useEffect(() => {
    resetState();
  }, [isLoading]);

  return (
    <S.DialogContainer>
      <Loader isVisble={isLoading} size="large" />
      <S.Container>
        <S.MainTitle>
          {currentUnit.Name && `Site: ${currentUnit.AdditionalSiteId.substring(0, 5)} ${currentUnit.SiteName}`}
        </S.MainTitle>
        <S.MainTitle>
          List Template: <b>{currentUnit.Name && ` ${currentUnit.Name}`}</b>
        </S.MainTitle>
        <S.RedInfo>
          Checklist:&nbsp;<S.Info>{details.Name}</S.Info>
        </S.RedInfo>
        <S.RedInfo>
          Assigned to:&nbsp;
          <S.Info>{returnAssignmentType(details.ChecklistAssignmentType, details.AssigneeID)}</S.Info>
        </S.RedInfo>
        {details.ChecklistAssignmentType !== 0 ? (
          <S.RedInfo>
            Name:&nbsp;<S.Info>{details.TeamName}</S.Info>
          </S.RedInfo>
        ) : (
          <S.RedInfo>
            Name:&nbsp;
            <S.Info>
              {details.AssigneeID && people.length && people.find(item => item.Id === details.AssigneeID)
                ? people.find(item => item.Id === details.AssigneeID).Name
                : 'Anyone'}
            </S.Info>
          </S.RedInfo>
        )}
        <S.RedText>(Missing) Items and Tasks to include</S.RedText>
        <>
          {tasksToAdd?.map(
            (item, index) =>
              item.length > 0 && (
                <Section key={item.Id}>
                  <SectionHeader color={theme.primaryLists}>
                    {item[0].ItemName}
                    <ButtonIcon>
                      <S.IconDelete onClick={() => onAddItem(index)} />
                    </ButtonIcon>
                  </SectionHeader>
                  <SectionBody>
                    <TasksTable list={item} onAdd={onAddTask} itemIndex={index} />
                  </SectionBody>
                </Section>
              ),
          )}
        </>
        <S.FooterText>added tasks will disappear from this list</S.FooterText>
        <ActionsContainer>
          <ActionButton label="CANCEL/PREVIOUS" onClick={onClose} />
          <ActionButton disabled={tasksToSend?.length === 0} label="APPLY UPDATE" isNext onClick={openOverview} />
        </ActionsContainer>
      </S.Container>
    </S.DialogContainer>
  );
};

Content.propTypes = {
  people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  scheduleInfo: ScheduleInfo.isRequired,
  tasksToAdd: PropTypes.array.isRequired,
  tasksToSend: PropTypes.array.isRequired,
  openConfirmationDialog: PropTypes.func.isRequired,
  openOverviewDialog: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onAddTask: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Content;
