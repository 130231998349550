import React from 'react';
import { Field } from 'react-final-form';

import RadioButtons from 'components/RadioButtons';

import { options } from './constants';
import * as S from './styled';

const IndexTypeSelection = () => (
  <S.Section>
    <S.SectionHeader>Index Type Selection</S.SectionHeader>
    <S.SectionBody>
      <S.FieldBlockContainer>
        <S.FieldRadioContainer>
          <Field component={RadioButtons} items={options} name="index-Type" style={S.typeRadioStyle} />
        </S.FieldRadioContainer>
      </S.FieldBlockContainer>
    </S.SectionBody>
  </S.Section>
);

export default IndexTypeSelection;
