import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { isIE11 } from 'helpers';
import { ListContainer, ListItemContainer } from 'components/Layout/Containers';
import { CopyIcon } from 'components/Layout/Icons';

const IconCopy = styled(CopyIcon)`
  min-width: 24px;
`;

const List = styled(ListContainer)``;

const ListItem = styled(ListItemContainer)``;

const StatusBlock = styled(Flex)`
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
`;

const StatusText = styled(Flex)`
  padding-right: 10px;
  margin-left: 4px;
`;

const InfoText = styled(Flex)`
  font-weight: bold;
  font-size: 14px;
  padding-right: 10px;
  white-space: ${isIE11 ? 'pre-wrap' : 'nowrap'};
`;

const EmptyList = styled.div`
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  width: 220px;
  color: ${({ theme }) => theme.tenantNameColor};
`;

const EmptyListText = styled.p``;

export { IconCopy, List, ListItem, StatusText, InfoText, StatusBlock, EmptyList, EmptyListText };
