import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import SelectField from 'components/Forms/SelectFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';

import TypeRadioGroup from './TypeRadioGroup';
import { optionNames } from './constants';
import { BoldText } from '../styled';
import * as S from './styled';

const ReportGrouping = ({ values, initialValue }) => (
  <>
    <S.RedTitle>Report Grouping</S.RedTitle>
    <S.Section>
      <S.SectionHeader>Task Grouping</S.SectionHeader>
      <S.SectionBody>
        <Field
          name={'group-ignore'}
          label={
            <>
              Group by <BoldText>person who completed tasks</BoldText> only and then sort tasks by date (most recent
              first)
            </>
          }
          component={S.Checkbox}
        />
        {!values['group-ignore'] && (
          <S.FieldBlockContainer>
            <Field
              component={TypeRadioGroup}
              name="group-select"
              values={values}
              style={S.typeRadioStyle}
              reportValues={initialValue}
            />
          </S.FieldBlockContainer>
        )}
      </S.SectionBody>
    </S.Section>
    <S.Section>
      <S.SectionHeader>Task Sub-Grouping</S.SectionHeader>
      <S.SectionBody>
        {!!values['group-select'] && !values['group-ignore'] && (
          <>
            <Field
              name={'subgroup-ignore'}
              label={
                <>
                  Sub-group by <BoldText>Unit Name</BoldText> and then sort tasks by date(most recent first)
                </>
              }
              component={S.Checkbox}
            />
            {!values['subgroup-ignore'] && (
              <Field
                name={'subgroup-name'}
                component={SelectField}
                hintText={optionNames.subGroup[0].name}
                style={{ fontSize: '18px', marginLeft: '35px' }}
                disabled={!!values['subgroup-ignore']}
                initialValue={initialValue.SubGroupBy ? initialValue.SubGroupBy : optionNames.subGroup[0].value}
              >
                {optionNames.subGroup.map(({ name, value }) => (
                  <SelectItem key={name} value={value} primaryText={name} />
                ))}
              </Field>
            )}
          </>
        )}
      </S.SectionBody>
    </S.Section>
  </>
);

ReportGrouping.propTypes = {
  values: PropTypes.shape().isRequired,
  initialValue: PropTypes.shape({
    is: PropTypes.string,
    SubGroupBy: PropTypes.number,
  }),
};

ReportGrouping.defaultProps = {
  initialValue: {
    is: '',
  },
};

export default ReportGrouping;
