const validator = values => {
  const errors = {};

  if (!values.DeviceId) {
    errors.DeviceId = 'Required';
  }

  if (!values.IotHubRegion) {
    errors.IotHubRegion = 'Required';
  }

  if (!values.ResourceGroupName) {
    errors.ResourceGroupName = 'Required';
  }

  if (!values.IotHubName) {
    errors.IotHubName = 'Required';
  }

  return errors;
};

export default validator;
