import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import {
  BorderRadiusContainer,
  InputBlockLabel,
  ContentContainer,
  ListContainer,
  Row,
} from 'components/Layout/Containers';

import { DeleteIcon } from 'components/Layout/Icons';

export const Container = styled(ContentContainer)``;

export const ControlsBlock = styled(Row)``;

export const List = styled(ListContainer)`
  z-index: 2;
  padding-bottom: 52px;
`;

export const DeleteIconColored = styled(DeleteIcon)`
  color: ${({ theme }) => theme.primaryScheduler} !important;
`;

export const RedText = styled.div`
  color: ${({ theme }) => theme.mainRed};
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.textGray};
  text-transform: capitalize;
`;

export const Block = styled(BorderRadiusContainer)`
  padding-bottom: 15px;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ color }) => color};
  text-align: left;
`;

export const BlockTimeFrame = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 20px;

  & > div:first-child {
    input {
      color: ${({ theme }) => theme.mainRed} !important;
    }
    label {
      color: ${({ theme }) => theme.mainRed} !important;
    }
    hr {
      display: none !important;
    }
  }
  & > div:last-child {
    hr {
      display: none !important;
    }
    div {
      & > div:last-child {
        margin-left: -110% !important;
      }
    }
  }
`;

export const FieldBlock = styled.div`
  input {
    font-size: 15px !important;
  }

  width: 50%;
  margin: 0 15px !important;
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationText = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const ModalContainer = styled.div`
  & > div:last-child {
    padding: 15px !important;

    & > button:first-child {
      margin-right: 10px !important;
    }

    & > button:last-child {
      margin-left: 10px !important;
    }
  }
`;

export const Title = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  text-transform: capitalize;
  margin-bottom: 10px;
`;

export const ModalText = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 0;
`;

export const Tooltip = styled(ReactTooltip)`
  background-color: ${({ theme }) => theme.darkGrey} !important;
  text-align: center;
  padding: 0px 7px !important;
  opacity: 1 !important;
  margin-top: 0px !important;
  border-radius: 0px;
  font-size: 10px !important;
  &: after {
    display: none;
  }
`;
