import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as CS from 'components/Dialogs/styled';

import * as S from './styled';

import theme from 'theme';

const BASE_INDEX = 1000;

class CheckboxList extends Component {
  static propTypes = {
    checkedItem: PropTypes.shape().isRequired,
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setCheckedItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    checkedMembers: [],
  };

  onItemCheck = id => {
    const selectedItem = this.props.list.find(item => item.Id === id);
    this.props.setCheckedItem(selectedItem);
  };

  checkColor = id => {
    if (this.props.checkedItem.Id) {
      return this.props.checkedItem.Id === id;
    }
    return this.props.list[0].Id === id;
  };

  onCheckboxClick = e => {
    e.stopPropagation();
  };

  render() {
    const { checkedItem, list } = this.props;

    return (
      <S.Container>
        <S.ListContainer>
          {list.length > 0 &&
            list.map(({ Id, Name }, index) => (
              <S.ListItemContainer index={BASE_INDEX - index} key={Id}>
                <S.ListItem>
                  <S.ItemNameAndCheckbox>
                    <CS.ItemRadio
                      checked={checkedItem.Id === Id}
                      onCheck={(e, checked) => this.onItemCheck(Id, checked)}
                      onClick={this.onCheckboxClick}
                      iconStyle={{ fill: this.checkColor(Id) && theme.mainRed }}
                    />
                    <S.ItemName>
                      <CS.ItemName>{Name}</CS.ItemName>
                    </S.ItemName>
                  </S.ItemNameAndCheckbox>
                </S.ListItem>
                <CS.DividerLine />
              </S.ListItemContainer>
            ))}
        </S.ListContainer>
      </S.Container>
    );
  }
}

export default CheckboxList;
