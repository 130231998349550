import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import UnitsList from './pages/UnitsList';
import UnitVersionsList from './pages/UnitVersionsList';
import UnitDetails from './pages/UnitDetails';

const Exchange = () => (
  <Switch>
    <Route exact path="/exchange" component={UnitsList} />
    <Route exact path="/exchange/:originalId/versions" component={UnitVersionsList} />
    <Route exact path="/exchange/:unitId/version/:version" component={UnitDetails} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Exchange;
