import styled from 'styled-components';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import Checkbox from 'material-ui/Checkbox';
import { RadioButton } from 'material-ui/RadioButton';

export const FieldText = styled(TextField).attrs({
  fullWidth: true,
})``;

export const FieldToggle = styled(Toggle)``;

export const FieldCheckbox = styled(Checkbox)`
  div {
    align-items: center !important;
  }
`;

export const FieldRadio = styled(RadioButton)`
  max-width: 100% !important;
`;
