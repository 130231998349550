const isValidURL = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const validator = values => {
  const errors = {};

  if (!values.MyPartID) {
    errors.MyPartID = 'My Part ID is required!';
  }

  if (!values.MyPartName) {
    errors.MyPartName = 'Part Name is required!';
  }

  if (!values.OEMID) {
    errors.OEMID = 'OEM Name is required!';
  }

  if (!values.SupplierID) {
    errors.SupplierID = 'Supplier Name is required!';
  }

  if (values.MyPartURL && !isValidURL(values.MyPartURL)) {
    errors.MyPartURL = 'URL must be valid';
  }

  if (values.OEMPartURL && !isValidURL(values.OEMPartURL)) {
    errors.OEMPartURL = 'URL must be valid';
  }

  if (values.SupplierPartURL && !isValidURL(values.SupplierPartURL)) {
    errors.SupplierPartURL = 'URL must be valid';
  }

  return errors;
};

export default validator;
