import styled from 'styled-components';
import { Flex } from 'grid-styled';

const setBackgroundColor = (isUp, quantity, theme, value) =>
  quantity === value || isUp ? theme.darkGrey : theme.primaryHome;

export const Levels = styled(Flex).attrs({
  align: ({ isUp }) => `flex-${isUp ? 'end' : 'start'} !important`,
  justify: 'flex-end !important',
})`
  margin-right: 5px;
`;

export const Level = styled.div`
  width: 12px;
  margin-right: 1px;
`;

export const Normal = styled(Level)`
  height: 5px;
  background-color: ${({ theme }) => theme.darkGrey};
`;

export const Elevated = styled(Level)`
  height: 10px;
  background-color: ${({ isUp, quantity, theme }) => setBackgroundColor(isUp, quantity, theme, 7)};
`;

export const Watch = styled(Level)`
  height: 15px;
  background-color: ${({ isUp, quantity, theme }) => setBackgroundColor(isUp, quantity, theme, 5)};
`;

export const Warning = styled(Level)`
  height: 20px;
  background-color: ${({ isUp, quantity, theme }) => setBackgroundColor(isUp, quantity, theme, 3)};
`;

export const Alert = styled(Level)`
  height: 25px;
  background-color: ${({ isUp, theme }) => (isUp ? theme.darkGrey : theme.primaryHome)};
`;
