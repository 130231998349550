import React from 'react';
import { useHistory } from 'react-router-dom';

import { ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const ErrorRoutePage = () => {
  const history = useHistory();
  const goToHome = () => {
    history.push('/home');
  };

  return (
    <S.Container>
      <S.Logo />
      <S.Title>Oh wow! Something threw a wrench in the works.</S.Title>
      <S.Text>While we “cheq” this out...</S.Text>
      <S.Text>Please try again by clicking here.</S.Text>
      <S.HomeButtonContainer>
        <ActionButton type="button" label="Home" isGreenLabel isNext onClick={goToHome} />
      </S.HomeButtonContainer>
    </S.Container>
  );
};

export default ErrorRoutePage;
