import styled from 'styled-components';
import ReactModal from 'react-modal';
import { CloseIcon } from 'components/Layout/Icons';

const Modal = styled(ReactModal).attrs({
  style: ({ theme }) => ({
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.54)',
      zIndex: 1000,
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      maxWidth: '90%',
      maxHeight: '80%',
      overflow: 'auto',
      background: theme.primaryWhite,
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
    },
  }),
})`
  &.ReactModal__Content {
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Header = styled.div<{ titleColor: string }>`
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme, titleColor }) => titleColor || theme.headerColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  left: 12%;
  right: 12%;
  top: 0;
  z-index: 2000;
  padding-left: 14px;
`;

const IconClose = styled(CloseIcon)`
  color: ${({ theme }) => theme.primaryWhite} !important;
  transform: scale(1.3);
  cursor: pointer;
`;

const Content = styled.div`
  flex-grow: 1;
`;

export { Modal, Header, IconClose, Content };
