import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { FullHeightCenteredXYGreyContainer } from 'components/Layout/Containers';

import { actions as tenantsActions } from 'redux/tenants';
import { actions as authActions } from 'redux/auth';
import { openErrorDialog } from 'redux/errorHandler';

import * as CS from '../../styled';

import SuccessVerificationDialog from './SuccessVerificationDialog';
import validate from './validator';
import * as S from './styled';

class VerificationPage extends Component {
  static propTypes = {
    error: PropTypes.string.isRequired,
    industryList: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string,
        Name: PropTypes.string,
      }),
    ).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    requestTennantIndustryList: PropTypes.func.isRequired,
    requestVerifyTenant: PropTypes.func.isRequired,
  };

  state = {
    openSuccessDialog: false,
    userName: queryString.parse(this.props.location.search).userName,
  };

  componentDidMount() {
    this.props.requestTennantIndustryList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ openSuccessDialog: false });
      this.props.openErrorDialog(nextProps.error, 'Error');
    }
  }

  openSuccessDialog = () => this.setState({ openSuccessDialog: true });

  onSubmit = values => {
    const { industryId, companySitesNumber } = values;
    const token = queryString.parse(this.props.location.search).token;
    const verifyData = { token, industryId, companySitesNumber };

    this.props.requestVerifyTenant({ verifyData, openSuccessDialog: this.openSuccessDialog });
  };

  closeSuccessDialog = () => {
    this.setState({ openSuccessDialog: false });
    this.props.history.push('/login');
  };

  render() {
    const { industryList } = this.props;
    const { openSuccessDialog, userName } = this.state;

    return (
      <FullHeightCenteredXYGreyContainer>
        <CS.Content>
          <CS.Logo />
          <S.VerificationContainer>
            <S.Title>Account Verification</S.Title>
            <S.GreetingTitle>{userName}</S.GreetingTitle>
            <S.GreetingMessage>
              Thanks for signing up!
              <br />
              Please confirm the following information to complete
              <br />
              the sign-up process.
            </S.GreetingMessage>
            <Form
              onSubmit={this.onSubmit}
              validate={validate}
              render={({ handleSubmit, invalid }) => (
                <S.FormContainer onSubmit={handleSubmit} noValidate>
                  <S.Label>Please verify your Industry Type</S.Label>
                  <Field name="industryId" placeholder="Select Closest Industry Type">
                    {({ input, ...rest }) => (
                      <S.SelectField {...input} {...rest}>
                        <option value="" disabled hidden>
                          Select Closest Industry Type
                        </option>
                        {industryList.map(({ Id, Name }) => (
                          <option key={Id} value={Id}>
                            {Name}
                          </option>
                        ))}
                      </S.SelectField>
                    )}
                  </Field>
                  <S.Label>How many Sites does your company have?</S.Label>
                  <Field name="companySitesNumber" type="number">
                    {({ input, meta, ...rest }) => (
                      <S.TextInput
                        {...rest}
                        {...input}
                        meta={meta}
                        placeholder={meta.error && meta.touched ? meta.error : 'Qty of Company Sites'}
                      />
                    )}
                  </Field>
                  <CS.SubmitButton type="submit" disabled={invalid}>
                    SUBMIT
                  </CS.SubmitButton>
                </S.FormContainer>
              )}
            />
          </S.VerificationContainer>
        </CS.Content>
        <SuccessVerificationDialog open={openSuccessDialog} onClose={this.closeSuccessDialog} />
      </FullHeightCenteredXYGreyContainer>
    );
  }
}

const mapStateToProps = ({ tenants, auth }) => ({
  error: auth.error,
  industryList: tenants.industryList,
});

const mapDispatchToProps = {
  openErrorDialog,
  requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
  requestVerifyTenant: authActions.verifyTenantRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationPage);
