import { FEED_TYPE } from '../configs';

export const ERROR_MESSAGE = `All videos available will be added to the page.
Videos will not be shown until they are published.`;

export const feedType = [
  { value: FEED_TYPE.CHANNEL, name: 'Channel' },
  { value: FEED_TYPE.SHOWCASE, name: 'Showcase' },
  { value: FEED_TYPE.GROUP, name: 'Group' },
  { value: FEED_TYPE.TAG, name: 'Tag' },
];

export const validator = values => {
  const errors = {};

  if (!values.Header) {
    errors.Header = 'Required';
  }

  if (!values.Subtitle) {
    errors.Subtitle = 'Required';
  }

  return errors;
};
