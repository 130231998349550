import styled from 'styled-components';
import { Box } from 'grid-styled';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding-right: 100px;
`;

export const Cell = styled.div`
  flex-direction: column;
  flex: 1;
`;

export const Text = styled(Box).attrs({
  flex: 'auto',
  pb: 1,
})`
  align-self: flex-start;
  min-width: 0;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;
