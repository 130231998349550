const SCHEDULE_DATA = {
  '': [],
  OneTime: ['ScheduledDate'],
  RecurringDaily: ['DailyStartDate', 'DailyRepeatUntil'],
  RecurringWeekly: ['WeeklyStartDate', 'WeeklyRepeatUntil'],
  RecurringMonthly: ['MonthlyStartDate', 'MonthlyRepeatUntil'],
};

export const validator = values => {
  const errors = {};
  if (!values.ScheduleType) {
    errors.ScheduleType = 'Required';
  } else {
    SCHEDULE_DATA[values.ScheduleType].forEach(field => {
      if (!values.ScheduleData[field]) {
        errors.ScheduleData = { ...errors.ScheduleData };
        errors.ScheduleData[field] = 'Required';
      }
    });
  }

  return errors;
};

export default validator;
