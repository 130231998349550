import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import { Dialog, ActionButton } from 'components/Dialogs/v1/Base';
import InnerCard from 'components/Layout/InnerCard';
import { actions as settingsActions } from 'redux/settings';

import theme from 'theme';

import * as S from './styled';
import { QUICK_TASKS_RADIO_BUTTONS, COMPLETED_TASKS_RADIO_BUTTONS } from './config';

const STYLES = {
  radioIcon: {
    fill: theme.mainRed,
  },
  radioButtonGroup: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  radioLabel: {
    fontSize: 15,
  },
};

class TaskArchivingSchedulesDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      requestSetSettings: PropTypes.func.isRequired,
    }).isRequired,
    settings: PropTypes.shape({
      QuickTasksArchiving: PropTypes.number,
      CompletedTasksArchiving: PropTypes.number,
    }).isRequired,
  };

  state = {
    QuickTasksArchiving: 0,
    CompletedTasksArchiving: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { QuickTasksArchiving, CompletedTasksArchiving } = this.props.settings;

    if (nextProps.open && !this.props.open) {
      this.setState({ QuickTasksArchiving, CompletedTasksArchiving });
    }
  }

  handleRadioChange = type => (event, value) => this.setState({ [type]: value });
  handlePeopleRadioChange = this.handleRadioChange('QuickTasksArchiving');
  handleTasksRadioChange = this.handleRadioChange('CompletedTasksArchiving');

  handleSave = event => {
    event.preventDefault();
    const { settings, actions, handleClose } = this.props;
    const { CompletedTasksArchiving, QuickTasksArchiving } = this.state;

    actions.requestSetSettings({
      isMaster: false,
      values: { ...settings, CompletedTasksArchiving, QuickTasksArchiving },
    });
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { QuickTasksArchiving, CompletedTasksArchiving } = this.state;

    return (
      <Dialog
        open={open}
        onRequestClose={handleClose}
        title="Task Archiving Schedules"
        titleColor={theme.mainRed}
        modalStyles={{ maxWidth: '940px' }}
      >
        <S.Container>
          <S.Description>
            <S.DescriptionTextWithMarging>
              Select default global settings for all Sites in the organization
            </S.DescriptionTextWithMarging>
          </S.Description>
          <InnerCard title="Quick Tasks Archiving">
            <S.RadioGroup>
              <S.DescriptionTextWithMarging>
                All non pinned quick tasks will be moved to archive after:
              </S.DescriptionTextWithMarging>
              <RadioButtonGroup
                name="QuickTasksArchiving"
                onChange={this.handlePeopleRadioChange}
                valueSelected={QuickTasksArchiving}
                style={STYLES.radioButtonGroup}
              >
                {QUICK_TASKS_RADIO_BUTTONS.map(({ label, value }) => (
                  <RadioButton
                    key={value}
                    iconStyle={STYLES.radioIcon}
                    label={label}
                    value={value}
                    labelStyle={STYLES.radioLabel}
                  />
                ))}
              </RadioButtonGroup>
            </S.RadioGroup>
          </InnerCard>
          <S.Spacer />
          <InnerCard title="Completed Tasks Archiving">
            <S.RadioGroup>
              <S.DescriptionTextWithMarging>
                All completed tasks will be moved to archive after:
              </S.DescriptionTextWithMarging>
              <RadioButtonGroup
                name="CompletedTasksArchiving"
                onChange={this.handleTasksRadioChange}
                valueSelected={CompletedTasksArchiving}
                style={STYLES.radioButtonGroup}
              >
                {COMPLETED_TASKS_RADIO_BUTTONS.map(({ label, value }) => (
                  <RadioButton
                    key={value}
                    iconStyle={STYLES.radioIcon}
                    labelStyle={STYLES.radioLabel}
                    label={label}
                    value={value}
                  />
                ))}
              </RadioButtonGroup>
            </S.RadioGroup>
          </InnerCard>
          <S.Spacer />
          <ActionButton label="Save" isNext onClick={this.handleSave} />
        </S.Container>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ settings }) => ({
  settings: settings.list,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      requestSetSettings: settingsActions.setSettingsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskArchivingSchedulesDialog);
