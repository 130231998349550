import styled from 'styled-components';
import { RadioButtonGroup } from 'material-ui/RadioButton';
import { ButtonIcon } from 'components/Layout/Buttons';
import { AddCircleIcon, RemoveCircleIcon, DirectionRunsIcon } from 'components/Layout/Icons';

export const Container = styled.div`
  form  {
    & > div:last-child {
      padding: 20px 0 !important;

      & > button:first-child {
        margin-right: 10px !important;
      }
  
      & > button:last-child {
        margin-left: 10px !important;
      }
    }
`;

export const Step = styled.div`
  background: ${({ theme }) => theme.mainRed};
  color: ${({ theme }) => theme.primaryWhite};
  width: 60px;
  border-radius: 7px;
  margin-bottom: 6px;
  padding: 3px 0;
`;

export const RuleBlock = styled.div`
  border-bottom: ${({ theme }) => `2px solid ${theme.textGray}`}!important;
  margin-bottom: 30px;
`;

export const RuleText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 5px 0;
`;

export const RedText = styled(RuleText)`
  color: ${({ theme }) => theme.mainRed};
`;

export const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primaryGrey};
  font-weight: 300;
  max-width: 450px;
  margin: 0 auto;
`;

export const SecondaryTitle = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  max-width: 450px;
  margin: 0 auto;
`;

export const TeamTitle = styled(SecondaryTitle)`
  margin: 30px auto;
`;

export const MainTitle = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  margin: 0 auto;
`;

export const SiteBlock = styled.div`
  dispaly: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  border-top: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  margin: 15px 50px;
`;

export const Section = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: start;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.mainRed};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
`;

export const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export const MatchSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  span {
    display: inline-block;
    margin-right: 10px;
  }
`;

export const RuleContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
  grid-column-gap: 10px;
`;

export const RuleButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RadioGroup = styled(RadioButtonGroup)`
  display: flex;
  flex: 1;
`;

export const AddIcon = styled(AddCircleIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
  isScaling: false,
})``;

export const RemoveIcon = styled(RemoveCircleIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const IconButton = styled(ButtonIcon)`
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  transform: translate3d(0, 0, 0) !important;
`;

export const PreviewResultsContainer = styled.div`
  display: flex;
  padding: 30px 0 40px;
  align-items: flex-start;
  text-align: left;
  justify-content: space-between;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const PreviewResultsInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  p {
    margin-top: 0;
    user-select: none;

    span {
      color: ${({ theme }) => theme.primaryBlack};
      font-weight: 700;
    }
  }
`;

export const PreviewItem = styled.div`
  display: flex;
  margin: 10px 0;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  border-top: ${({ theme }) => `1px solid ${theme.textGray}`}!important;

  span {
    padding: 0 10px;
  }
`;

export const PreviewItemText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Text = styled.span`
  margin-left: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const TestRunIcon = styled(DirectionRunsIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px !important;
  background-color: ${({ theme }) => theme.mainRed};
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  button {
    margin: 0;
    margin-bottom: 4px;
  }
`;

export const WrapperText = styled(Wrapper)`
  font-size: 13px;
  padding-right: 5px;
`;

export const BoldText = styled.div`
  font-weight: bold;
`;

export const PrimaryText = styled.span`
  color: ${({ theme }) => theme.mainRed};
`;
