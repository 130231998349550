import styled, { css } from 'styled-components';
import { IconButton, Checkbox } from 'material-ui';
import { ChevronDown, ChevronUp, DeleteIcon, VisibilityIcon } from 'components/Layout/Icons';

const ReportPhotosContainer = styled.div`
  padding: 0.5rem;
`;

const IconWrapper = styled(IconButton)<{ hasMarginRight?: boolean }>`
  width: auto !important;
  height: auto !important;
  padding: 0px !important;

  ${({ hasMarginRight }) =>
    hasMarginRight &&
    css`
      margin-right: 16px !important;
    `}
`;

const iconStyles = css`
  color: ${({ theme }) => theme.primaryWhite} !important;
  width: 30px !important;
  height: 30px !important;
`;

const ArrowUpIcon = styled(ChevronUp)`
  ${iconStyles}
`;

const ArrowDownIcon = styled(ChevronDown)`
  ${iconStyles}
`;

const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-block-start: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

const PhotoName = styled.span`
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const actionIconStyles = css`
  color: ${({ theme }) => theme.primaryGrey} !important;
  width: 30px !important;
  height: 30px !important;
`;

const EyeIcon = styled(VisibilityIcon)`
  ${actionIconStyles}
`;

const TrashIcon = styled(DeleteIcon)`
  ${actionIconStyles}
`;

const EmptyText = styled.div`
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChooseFileButton = styled.label<{ disabled?: boolean }>`
  background-color: ${({ theme }) => theme.lightGreenButton};
  opacity: ${({ disabled }) => disabled && 0.7};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme }) => theme.greenButton};
  padding: 0.4rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
`;

const UploadPhotoButton = styled.button`
  background: #f19c38;
  outline: none;
  border: 0;
  color: ${({ theme }) => theme.primaryWhite};
  opacity: ${({ disabled }) => disabled && 0.7};
  padding: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
`;

const FileName = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBox = styled(Checkbox)`
  width: auto !important;
  display: inline-block !important;

  & > div > div > div > svg {
    fill: ${({ theme }) => theme.primaryGrey} !important;
  }
`;

export {
  IconWrapper,
  ArrowUpIcon,
  ArrowDownIcon,
  ReportPhotosContainer,
  List,
  ListItem,
  PhotoName,
  EyeIcon,
  TrashIcon,
  EmptyText,
  ButtonsContainer,
  ChooseFileButton,
  UploadPhotoButton,
  FileName,
  CheckBox,
  ActionsContainer,
};
