import styled from 'styled-components';
import Paper from 'material-ui/Paper';
import { ButtonIcon, ButtonFlat } from 'components/Layout/Buttons';
import { GetAppIcon } from 'components/Layout/Icons';

const getCardHeaderBg = ({ colored, theme, editable }) => {
  if (colored) return !editable ? theme.partDetailsRed : theme.partDetailsPink;
  return editable ? theme.primaryHome : theme.darkGrey;
};

const getCardHeaderColor = ({ colored, theme, editable }) => {
  if (colored) return theme.primaryWhite;
  return editable ? theme.primaryDark : theme.primaryWhite;
};

export const Card = styled(Paper)`
  border-radius: 10px !important;
  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => getCardHeaderBg(props)};
  color: ${props => getCardHeaderColor(props)};
  padding: 10px 15px;
  min-height: 40px;
`;

export const CardHeaderInner = styled.div`
  flex: 1;

  label {
    color: ${({ theme }) => theme.primaryBlack} !important;
  }
`;

export const HeaderEditBtn = styled.button`
  color: ${({ theme }) => theme.modalsContentColor};
  border: 0;
  background: none;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
`;

export const EditableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const CardHeaderTitle = styled.h3`
  font-size: 18px;
  font-weight: 300;
  margin: 0;
`;

export const CardHeaderDesc = styled.span`
  font-size: 12px;
`;

export const EditBtn = styled(ButtonIcon)`
  margin-right: -10px !important;
  padding-left: 3px !important;
  width: auto !important;
`;

export const CopyLinkBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: -5px;
  margin-bottom: 15px;

  & > span {
    color: ${({ theme }) => theme.primaryGrey};
    font-size: 14px;
  }
`;

export const GetUrlBtn = styled(ButtonIcon)`
  margin-left: 0 !important;
  padding: 0 !important;
  width: auto !important;
  height: auto !important;
`;

export const CardBody = styled.div`
  min-height: 100px;
  padding: 20px 15px 0;
`;

export const createTextFieldStyles = ({ type, theme, isEditable }) => ({
  height: 50,
  width: '100%',
  marginBottom: '5px',
  color: type === 'my' && !isEditable ? theme.partDetailsRed : theme.tenantNameColor,
  cursor: 'default !important',
});

export const createLabelStyles = ({ theme }) => ({
  top: '20px',
  fontSize: '14px',
  color: theme.primaryGrey,
});

export const createInputStyles = ({ theme, type, isEditable }) => ({
  marginTop: 0,
  color: type === 'my' && !isEditable ? theme.partDetailsRed : theme.tenantNameColor,
});

export const createErrorStyles = () => ({
  bottom: '3px',
});

export const createSelectFieldStyles = () => ({
  height: '50px',
  lineHeight: '50px',
  marginTop: 0,
});

export const createSelectMenuStyles = () => ({
  height: '50px',
  lineHeight: '50px',
  marginTop: 0,
});

export const createSelectHintStyles = ({ theme }) => ({ color: theme.darkGrey, bottom: 0 });

export const createDropDownStyles = ({ type, isEditable, theme }) => ({
  labelStyle: {
    color: type === 'my' && !isEditable ? theme.partDetailsRed : theme.tenantNameColor,
    paddingLeft: 0,
    height: '50px',
    lineHeight: '50px',
    cursor: 'default',
  },
  iconStyle: {
    width: '30px',
    height: '30px',
    padding: 0,
    top: '4px',
    right: '10px',
    fill: theme.darkGrey,
    display: isEditable ? 'inline-block' : 'none',
  },
});

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;

  button {
    flex: 1;
  }
`;

export const Button = styled(ButtonFlat)`
  height: 60px !important;
  border-top: 1px solid ${({ theme }) => theme.primaryGrey} !important;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey} !important;
  color: ${({ theme }) => theme.primaryRed} !important;

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.primaryGrey} !important;
  }

  & > div > span {
    font-size: 16px !important;
  }
`;

export const SaveButton = styled(Button)`
  border: none !important;
  color: ${({ theme }) => theme.greenButton}!important;
`;

export const DownloadIcon = styled(GetAppIcon)`
  fill: ${({ theme }) => theme.greenButton}!important;
`;
