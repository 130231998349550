import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import moment from 'moment';
import theme from 'theme';
import { Modal } from 'components/Modal';
import { useAppSelector } from 'redux/reducers';
import { PartType } from 'types/parts';

import * as S from './PartDetailsDialogStyles';

type PropsType = {
  partId: string;
  onAssignedTaskPartsChange: (partId: string, isChecked: boolean) => void;
};

export const PartDetailsDialog = NiceModal.create(({ partId, onAssignedTaskPartsChange }: PropsType) => {
  const parts: Array<PartType & { IsAssigned: boolean }> = useAppSelector(state => state.tasks.parts);
  const part = parts.find(p => p.Id === partId);
  const modal = useModal();

  const onCheckHandler = (event: React.MouseEvent, value: boolean) => onAssignedTaskPartsChange(partId, value);

  return (
    <Modal title="Part Details" titleColor={theme.primaryDark} isOpen={modal.visible} onRequestClose={modal.hide}>
      <S.Container>
        <S.InfoContainer>
          <S.CheckBox checked={part?.IsAssigned} onCheck={onCheckHandler} />
          <div>
            <S.Name>{part?.MyPartID}</S.Name>
            <p>{part?.MyPartName}</p>
          </div>
        </S.InfoContainer>

        <S.Wrapper column>
          <S.Label>Part Info</S.Label>
          <S.List>
            <S.Title>Part Category</S.Title>
            <S.Value>{part?.MyPartCategory}</S.Value>

            <S.Title>Part Type</S.Title>
            <S.Value>{part?.MyPartType}</S.Value>

            <S.Title>Part Unit Of Measure</S.Title>
            <S.Value>{part?.MyPartUnitOfMeasure}</S.Value>

            <S.Title>Part Serial Number</S.Title>
            <S.Value>{part?.OEMPartSerialNumber}</S.Value>

            <S.Title>Part Revision Number</S.Title>
            <S.Value>{part?.MyPartRevisionNumber}</S.Value>

            <S.Title>Part Revision Date</S.Title>
            <S.Value>{part?.MyPartRevisionDate && moment(part.MyPartRevisionDate).format('DD/MM/YYYY')}</S.Value>
          </S.List>
        </S.Wrapper>
      </S.Container>
    </Modal>
  );
});
