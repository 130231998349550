import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ADD_TENANT_REQUEST: undefined,
  ADD_TENANT_SUCCESS: undefined,
  ADD_TENANT_FAILURE: undefined,

  DELETE_TENANT_REQUEST: undefined,
  DELETE_TENANT_FAILURE: undefined,

  EDIT_TENANT_REQUEST: undefined,
  EDIT_TENANT_SUCCESS: undefined,
  EDIT_TENANT_FAILURE: undefined,

  DEACTIVATE_TENANT_REQUEST: undefined,
  DEACTIVATE_TENANT_SUCCESS: undefined,
  DEACTIVATE_TENANT_FAILURE: undefined,

  ACTIVATE_TENANT_REQUEST: undefined,
  ACTIVATE_TENANT_SUCCESS: undefined,
  ACTIVATE_TENANT_FAILURE: undefined,

  TENANTS_LIST_REQUEST: undefined,
  TENANTS_LIST_SUCCESS: undefined,
  TENANTS_LIST_FAILURE: undefined,

  USERS_LOGINAS_LIST_REQUEST: undefined,
  USERS_LOGINAS_LIST_SUCCESS: undefined,
  USERS_LOGINAS_LIST_FAILURE: undefined,

  TENANT_INDUSTRY_LIST_REQUEST: undefined,
  TENANT_INDUSTRY_LIST_SUCCESS: undefined,
  TENANT_INDUSTRY_LIST_FAILURE: undefined,

  ADD_TENANT_INDUSTRY_REQUEST: undefined,
  ADD_TENANT_INDUSTRY_SUCCESS: undefined,
  ADD_TENANT_INDUSTRY_FAILURE: undefined,

  EDIT_TENANT_INDUSTRY_REQUEST: undefined,
  EDIT_TENANT_INDUSTRY_SUCCESS: undefined,
  EDIT_TENANT_INDUSTRY_FAILURE: undefined,

  DELETE_TENANT_INDUSTRY_REQUEST: undefined,
  DELETE_TENANT_INDUSTRY_FAILURE: undefined,

  TENANT_REQUESTS_STATISTICS_REQUEST: undefined,
  TENANT_REQUESTS_STATISTICS_SUCCESS: undefined,
  TENANT_REQUESTS_STATISTICS_FAILURE: undefined,

  TENANT_REQUESTS_LIST_REQUEST: undefined,
  TENANT_REQUESTS_LIST_SUCCESS: undefined,
  TENANT_REQUESTS_LIST_FAILURE: undefined,

  APPROVE_DEMO_TENANT_REQUEST: undefined,
  APPROVE_DEMO_TENANT_SUCCESS: undefined,
  APPROVE_DEMO_TENANT_FAILURE: undefined,

  REMOVE_DEMO_TENANT_REQUEST: undefined,
  REMOVE_DEMO_TENANT_SUCCESS: undefined,
  REMOVE_DEMO_TENANT_FAILURE: undefined,

  BLOCK_DEMO_TENANT_REQUEST: undefined,
  BLOCK_DEMO_TENANT_SUCCESS: undefined,
  BLOCK_DEMO_TENANT_FAILURE: undefined,

  FETCH_TIERS_REQUEST: undefined,
  FETCH_TIERS_SUCCESS: undefined,
  FETCH_TIERS_FAILURE: undefined,

  GET_CURRENT_TENANTS_COUNT_REQUEST: undefined,
  GET_CURRENT_TENANTS_COUNT_SUCCESS: undefined,
  GET_CURRENT_TENANTS_COUNT_FAILURE: undefined,

  GET_TENANT_PRICING_VALUES_REQUEST: undefined,
  GET_TENANT_PRICING_VALUES_SUCCESS: undefined,
  GET_TENANT_PRICING_VALUES_FAILUR: undefined,

  OVERWRITE_PRICING_VALUES_REQUEST: undefined,
  OVERWRITE_PRICING_VALUES_SUCCESS: undefined,
  OVERWRITE_PRICING_VALUES_FAILURE: undefined,

  GET_UPGRADE_REQUESTS_REQUEST: undefined,
  GET_UPGRADE_REQUESTS_SUCCESS: undefined,
  GET_UPGRADE_REQUESTS_FAILURE: undefined,

  EDIT_UPGRADE_REQUEST_REQUEST: undefined,
  EDIT_UPGRADE_REQUEST_SUCCESS: undefined,
  EDIT_UPGRADE_REQUEST_FAILURE: undefined,

  DELETE_UPGRADE_REQUEST_REQUEST: undefined,
  DELETE_UPGRADE_REQUEST_SUCCESS: undefined,
  DELETE_UPGRADE_REQUEST_FAILURE: undefined,

  GET_RECENT_UPGRADE_REQUEST_REQUEST: undefined,
  GET_RECENT_UPGRADE_REQUEST_SUCCESS: undefined,
  GET_RECENT_UPGRADE_REQUEST_FAILURE: undefined,

  CLEAR_RECENT_UPGRADE_REQUEST: undefined,

  DELETE_SENT_REQUESTS_REQUEST: undefined,
  DELETE_SENT_REQUESTS_SUCCESS: undefined,
  DELETE_SENT_REQUESTS_FAILURE: undefined,

  CLEAR_TIERS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.addTenantRequest, handlers.addTenantRequest],
    [actions.addTenantSuccess, handlers.addTenantSuccess],
    [actions.addTenantFailure, handlers.addTenantFailure],

    [actions.deleteTenantRequest, handlers.deleteTenantRequest],
    [actions.deleteTenantFailure, handlers.deleteTenantFailure],

    [actions.editTenantRequest, handlers.editTenantRequest],
    [actions.editTenantSuccess, handlers.editTenantSuccess],
    [actions.editTenantFailure, handlers.editTenantFailure],

    [actions.deactivateTenantRequest, handlers.deactivateTenantRequest],
    [actions.deactivateTenantSuccess, handlers.deactivateTenantSuccess],
    [actions.deactivateTenantFailure, handlers.deactivateTenantFailure],

    [actions.activateTenantRequest, handlers.activateTenantRequest],
    [actions.activateTenantSuccess, handlers.activateTenantSuccess],
    [actions.activateTenantFailure, handlers.activateTenantFailure],

    [actions.tenantsListRequest, handlers.tenantsListRequest],
    [actions.tenantsListSuccess, handlers.tenantsListSuccess],
    [actions.tenantsListFailure, handlers.tenantsListFailure],

    [actions.usersLoginasListRequest, handlers.usersLoginasListRequest],
    [actions.usersLoginasListSuccess, handlers.usersLoginasListSuccess],
    [actions.usersLoginasListFailure, handlers.usersLoginasListFailure],

    [actions.tenantIndustryListRequest, handlers.tenantIndustryListRequest],
    [actions.tenantIndustryListSuccess, handlers.tenantIndustryListSuccess],
    [actions.tenantIndustryListFailure, handlers.tenantIndustryListFailure],

    [actions.addTenantIndustryRequest, handlers.addTenantIndustryRequest],
    [actions.addTenantIndustrySuccess, handlers.addTenantIndustrySuccess],
    [actions.addTenantIndustryFailure, handlers.addTenantIndustryFailure],

    [actions.editTenantIndustryRequest, handlers.editTenantIndustryRequest],
    [actions.editTenantIndustrySuccess, handlers.editTenantIndustrySuccess],
    [actions.editTenantIndustryFailure, handlers.editTenantIndustryFailure],

    [actions.deleteTenantIndustryRequest, handlers.deleteTenantIndustryRequest],
    [actions.deleteTenantIndustryFailure, handlers.deleteTenantIndustryFailure],

    [actions.tenantRequestsStatisticsRequest, handlers.tenantRequestsStatisticsRequest],
    [actions.tenantRequestsStatisticsSuccess, handlers.tenantRequestsStatisticsSuccess],
    [actions.tenantRequestsStatisticsFailure, handlers.tenantRequestsStatisticsFailure],

    [actions.tenantRequestsListRequest, handlers.tenantRequestsListRequest],
    [actions.tenantRequestsListSuccess, handlers.tenantRequestsListSuccess],
    [actions.tenantRequestsListFailure, handlers.tenantRequestsListFailure],

    [actions.approveDemoTenantRequest, handlers.approveDemoTenantRequest],
    [actions.approveDemoTenantSuccess, handlers.approveDemoTenantSuccess],
    [actions.approveDemoTenantFailure, handlers.approveDemoTenantFailure],

    [actions.removeDemoTenantRequest, handlers.removeDemoTenantRequest],
    [actions.removeDemoTenantSuccess, handlers.removeDemoTenantSuccess],
    [actions.removeDemoTenantFailure, handlers.removeDemoTenantFailure],

    [actions.blockDemoTenantRequest, handlers.blockDemoTenantRequest],
    [actions.blockDemoTenantSuccess, handlers.blockDemoTenantSuccess],
    [actions.blockDemoTenantFailure, handlers.blockDemoTenantFailure],

    [actions.getCurrentTenantsCountRequest, handlers.getCurrentTenantsCountRequest],
    [actions.getCurrentTenantsCountSuccess, handlers.getCurrentTenantsCountSuccess],
    [actions.getCurrentTenantsCountFailure, handlers.getCurrentTenantsCountFailure],

    [actions.getUpgradeRequestsRequest, handlers.getUpgradeRequestsRequest],
    [actions.getUpgradeRequestsSuccess, handlers.getUpgradeRequestsSuccess],
    [actions.getUpgradeRequestsFailure, handlers.getUpgradeRequestsFailure],

    [actions.editUpgradeRequestRequest, handlers.editUpgradeRequestRequest],
    [actions.editUpgradeRequestSuccess, handlers.editUpgradeRequestSuccess],
    [actions.editUpgradeRequestFailure, handlers.editUpgradeRequestFailure],

    [actions.deleteUpgradeRequestRequest, handlers.deleteUpgradeRequestRequest],
    [actions.deleteUpgradeRequestSuccess, handlers.deleteUpgradeRequestSuccess],
    [actions.deleteUpgradeRequestFailure, handlers.deleteUpgradeRequestFailure],

    [actions.getRecentUpgradeRequestRequest, handlers.getRecentUpgradeRequestRequest],
    [actions.getRecentUpgradeRequestSuccess, handlers.getRecentUpgradeRequestSuccess],
    [actions.getRecentUpgradeRequestFailure, handlers.getRecentUpgradeRequestFailure],

    [actions.clearRecentUpgradeRequest, handlers.clearRecentUpgradeRequest],

    [actions.fetchTiersRequest, handlers.fetchTiersRequest],
    [actions.fetchTiersSuccess, handlers.fetchTiersSuccess],
    [actions.fetchTiersFailure, handlers.fetchTiersFailure],

    [actions.getTenantPricingValuesRequest, handlers.getTenantPricingValuesRequest],
    [actions.getTenantPricingValuesSuccess, handlers.getTenantPricingValuesSuccess],
    [actions.getTenantPricingValuesFailure, handlers.getTenantPricingValuesFailure],

    [actions.overwritePricingValuesRequest, handlers.overwritePricingValuesRequest],
    [actions.overwritePricingValuesSuccess, handlers.overwritePricingValuesSuccess],
    [actions.overwritePricingValuesFailure, handlers.overwritePricingValuesFailure],

    [actions.deleteSentRequestsRequest, handlers.deleteSentRequestsRequest],
    [actions.deleteSentRequestsSuccess, handlers.deleteSentRequestsSuccess],
    [actions.deleteSentRequestsFailure, handlers.deleteSentRequestsFailure],

    [actions.clearTiers, handlers.clearTiers],
  ]),
  initialState,
);

export default reducer;
