import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { actions as partsActions } from 'redux/parts';
import { actions as queueActions } from 'redux/queue';
import Subheader from 'components/Subheader';
import { AddIcon, BackIcon } from 'components/Layout/Icons';
import EmptyList from 'components/EmptyList';
import { ButtonIcon } from 'components/Layout/Buttons';
import TableList from 'components/TableList';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { searchItemByKeyword, convertDateToThreeDigit } from 'helpers';
import AddReportModal from './ReportSetup';
import { tableColumns } from './constants';
import * as S from './styled';

const findSite = (sitesArray, selectedSite) => {
  const site = sitesArray.find(siteObject => siteObject.id === selectedSite);
  if (site.additionalId) {
    return `${site.additionalId.substring(0, 5)} ${site.name}`;
  }
  return `${site.name}`;
};

class Archive extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      fetchReportsList: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    selectedSite: PropTypes.string.isRequired,
    sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
    reportList: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
  };

  state = {
    searchData: '',
    siteSelected: this.props.selectedSite,
    isModalVisible: false,
    reportToEdit: {},
    isConfirmationDialogOpened: false,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.fetchReportsList();
  }

  searchInList = e => this.setState({ searchData: e.target.value });

  rightButtons = [
    {
      icon: <AddIcon />,
      handler: () => this.openModal(),
      hint: 'Add report',
    },
  ];

  goBack = () => this.props.history.push('/queue');

  goForward = item => {
    this.props.history.push({
      pathname: `/queue/export/${item.Id}`,
    });
  };

  renderRightControllCell = item => (
    <td>
      <ButtonIcon onClick={() => this.goForward(item)} tooltip="Report Details">
        <S.IconForward />
      </ButtonIcon>
    </td>
  );

  getTimeLastRun = value => moment(value).format('h:mm a');

  mapList = item => ({
    ...item,
    CreatedName: (
      <>
        Created By: <S.BoldText>{item.CreatedByName}</S.BoldText>
      </>
    ),
    Date: (
      <>
        Last Run: <S.BoldText>{convertDateToThreeDigit(item.LastExecutionDate)}</S.BoldText>
      </>
    ),
    Time: (
      <>
        Time: <S.BoldText>{this.getTimeLastRun(item.LastExecutionDate)}</S.BoldText>
      </>
    ),
  });

  openModal = () => this.setState({ isModalVisible: true });

  editReport = item => {
    this.setState({
      reportToEdit: item,
      isModalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      reportToEdit: {},
      isModalVisible: false,
    });
  };

  openConfirmationDialog = () => this.setState({ isConfirmationDialogOpened: true });

  closeConfirmationDialog = () => this.setState({ isConfirmationDialogOpened: false });

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.openConfirmationDialog();
    }
  }

  render() {
    const { searchData, reportToEdit, isConfirmationDialogOpened } = this.state;
    const { sitesAvailableToUserFullInfo, selectedSite, reportList, error } = this.props;

    return (
      <>
        <Subheader
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.goBack,
              hint: 'Back',
            },
          ]}
          rightButtons={this.rightButtons}
          title="Reports"
          isSearch
          searchData={searchData}
          isSiteOnLeftSide
          searchInList={this.searchInList}
        />
        {reportList && reportList.length ? (
          <S.TableContainer>
            <TableList
              list={reportList.map(this.mapList).filter(searchItemByKeyword(tableColumns, searchData))}
              tableColumns={tableColumns}
              onRowClick={this.editReport}
              isPointer
              renderRightControllCell={this.renderRightControllCell}
              handlers={{}}
            />
          </S.TableContainer>
        ) : (
          <EmptyList text="There are no reports" />
        )}
        <AddReportModal
          open={this.state.isModalVisible}
          handleClose={() => {
            this.setState({ isConfirmationDialogOpened: true });
          }}
          handleExit={this.closeModal}
          site={findSite(sitesAvailableToUserFullInfo, selectedSite)}
          reportToEdit={reportToEdit}
        />
        <ConfirmationDialog
          label="Alert"
          open={isConfirmationDialogOpened}
          handleClose={this.closeConfirmationDialog}
          text={
            <div>
              <S.ConfirmationText>{error}</S.ConfirmationText>
              <ActionsContainer>
                <ActionButton isNext label="OK" onClick={this.closeConfirmationDialog} />
              </ActionsContainer>
            </div>
          }
        />
      </>
    );
  }
}

const mapStateToProps = ({ queue: { reportList, error }, auth: { selectedSite, user } }) => ({
  sitesAvailableToUserFullInfo: user.sitesAvailableToUserFullInfo,
  selectedSite,
  reportList,
  error,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...partsActions,
      fetchReportsList: queueActions.fetchReportsListRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Archive);
