import React, { FC } from 'react';

import * as CS from 'components/Dialogs/styled';
import { ButtonIcon } from 'components/Layout/Buttons';
// eslint-disable-next-line max-len
import { separateRuleType } from 'modules/scheduler/pages/SchedulesList/AddChecklistModal/SelectTasks/TasksTable/helpers';

import * as S from './styled';
import { TaskTemplateType } from 'types/scheduler';

const BASE_INDEX = 1000;

type PropsType = {
  list: TaskTemplateType[];
  onAdd: (index: number, ItemId: string, itemIndex: number) => void;
  itemIndex: number;
};

const TasksTable: FC<PropsType> = ({ list, onAdd, itemIndex }) => (
  <S.Container>
    <S.ListContainer>
      {list.length > 0 &&
        list.map(({ RuleType, Name, ItemId }, index) => (
          <S.ListItemContainer index={BASE_INDEX - index} key={BASE_INDEX - index}>
            <S.ListItem>
              <S.ItemValue>{separateRuleType(RuleType)}</S.ItemValue>
              <S.ItemValue>{Name}</S.ItemValue>
              <ButtonIcon>
                <S.IconDelete onClick={() => onAdd(index, ItemId, itemIndex)} />
              </ButtonIcon>
            </S.ListItem>
            {index !== list.length - 1 && <CS.DividerLine />}
          </S.ListItemContainer>
        ))}
    </S.ListContainer>
  </S.Container>
);

export default TasksTable;
