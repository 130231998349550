import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Levels = ({ isUp, quantity }) => (
  <S.Levels isUp={isUp}>
    {quantity === 9 && <S.Normal isUp={isUp} />}
    {(quantity === 9 || quantity === 7) && <S.Elevated isUp={isUp} quantity={quantity} />}
    {(quantity === 9 || quantity === 7 || quantity === 5) && <S.Watch isUp={isUp} quantity={quantity} />}
    <S.Warning isUp={isUp} quantity={quantity} />
    <S.Alert isUp={isUp} />
  </S.Levels>
);

Levels.propTypes = {
  isUp: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
};

Levels.defaultProps = {
  isUp: false,
};

export default Levels;
