import styled from 'styled-components';

import { HideOnTablets } from 'components/Layout/Media';
import { ButtonIcon } from 'components/Layout/Buttons';
import { CloseIcon } from 'components/Layout/Icons';
import { Row } from 'components/Layout/Containers';

export const CloseButton = styled(ButtonIcon)`
  ${HideOnTablets}
`;

export const Container = styled(Row)`
  min-height: 50px;
  background: ${({ titleColor }) => titleColor} !important;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 0;
  text-transform: ${({ noCaptilize }) => !noCaptilize && 'captitalize'};

  @media only screen and (max-width: 768px) {
    padding-right: 15px;
  }
`;

export const IconClose = styled(CloseIcon)`
  color: ${({ theme }) => theme.primaryWhite} !important;
  transform: scale(1.3);
`;
