import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import theme from 'theme';
import { InventoryPartType, PartGroupsType, TransactionType } from 'types/inventory';
import { ActionButton } from 'components/Dialogs/v1/Base';
import { Modal } from 'components/Modal';
import { InventoryChanges } from '../../ui/InventoryChanges';

import * as S from './styled';

type PropsType = {
  currentEntity: InventoryPartType | PartGroupsType;
  type: string;
  transaction?: TransactionType;
};

export const TransactionsDialog = NiceModal.create(({ currentEntity, type, transaction }: PropsType) => {
  const modal = useModal();
  const isPartInventory = type === 'parts';
  const entityType = isPartInventory ? 'Part' : 'PartGroup';

  return (
    <Modal
      onRequestClose={modal.hide}
      title="Transactions"
      titleColor={theme.primaryDark}
      isOpen={modal.visible}
      onAfterClose={modal.remove}
    >
      <S.RootContainer>
        <S.List>
          {isPartInventory && (
            <li>
              <b>My Part ID: </b> {(currentEntity as InventoryPartType).MyPartID}
            </li>
          )}
          <li>
            <b>My {entityType} Name: </b>
            {isPartInventory
              ? (currentEntity as InventoryPartType).MyPartName
              : (currentEntity as PartGroupsType).MyGroupName}
          </li>
        </S.List>
        <p>
          <b>Record an Inventory Transaction.</b>
        </p>
        <InventoryChanges currentEntity={currentEntity} type={type} transaction={transaction} />
        <ActionButton type="button" label="CANCEL" isNext isGreenLabel onClick={modal.hide} />
      </S.RootContainer>
    </Modal>
  );
});
