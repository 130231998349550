import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';

import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as peopleActions } from 'redux/people';
import { actions as newSitesActions } from 'redux/newSites';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import { checkOptions, countUnitsAndItems, createId } from 'components/Layout/ShortcutRules/helpers';

import { ShortCutType, ShortcutSchedule, SiteType } from 'configs/propTypes';
import { AssignmentType } from 'configs/types';

import {
  generateManagementRules,
  generatePersonRules,
  generateTeamRules,
  getInitialRules,
  initialRule,
} from './helpers';
import PickupForm from './Form';
import * as S from './styled';

class Pickup extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      sendManagementOptionsRequest: PropTypes.func.isRequired,
      sendPersonOptionsRequest: PropTypes.func.isRequired,
      editSiteScheduleRequest: PropTypes.func.isRequired,
      sendTeamOptionsRequest: PropTypes.func.isRequired,
      getAvailabilityOptionsRequest: PropTypes.func.isRequired,
      getJobsListRequest: PropTypes.func.isRequired,
      listStatusRequest: PropTypes.func.isRequired,
    }).isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    teamsList: PropTypes.shape().isRequired,
    jobsList: PropTypes.shape().isRequired,
    listStatus: PropTypes.shape().isRequired,
    shortcut: ShortCutType.isRequired,
    currentSchedule: ShortcutSchedule.isRequired,
    currentSite: SiteType.isRequired,
    unitsAndItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  state = {
    managementRules: getInitialRules({ rules: this.props.currentSchedule?.ManagementRules, name: 'management' }),
    personRules: getInitialRules({ rules: this.props.currentSchedule?.PersonRules, name: 'person' }),
    teamRules: getInitialRules({ rules: this.props.currentSchedule?.TeamRules, name: 'team' }),
    isErrorShown: false,
    previewNames: [],
  };

  componentDidMount() {
    this.props.actions.getAvailabilityOptionsRequest();
    this.props.actions.getJobsListRequest();
    this.props.actions.listStatusRequest();
  }

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onSubmitForm = values => {
    const { currentSchedule, onNext, actions } = this.props;
    const { personRules, managementRules, teamRules } = this.state;

    const updatedValuesToSend = {
      ...generatePersonRules(values, personRules.rules),
      ...generateTeamRules(values, teamRules.rules),
      ...generateManagementRules(values, managementRules.rules),
      id: currentSchedule.Id,
      AssignmentType: AssignmentType[currentSchedule.AssignmentType - 1],
      onNext,
    };

    if (
      (currentSchedule.AssignmentType === 3 && !checkOptions(updatedValuesToSend.PersonRules)) ||
      (currentSchedule.AssignmentType === 2 && !checkOptions(updatedValuesToSend.TeamRules)) ||
      (currentSchedule.AssignmentType === 1 && !checkOptions(updatedValuesToSend.ManagementRules))
    ) {
      this.setState({ isErrorShown: true });
    } else {
      actions.editSiteScheduleRequest(updatedValuesToSend);
      this.setState({ isErrorShown: false, previewNames: [] });
    }
  };

  onPrevious = e => {
    e.preventDefault();
    this.setState({ previewNames: [] });
    this.props.onBack();
  };

  getPreviewResults = (values, stateKey, requestName) => {
    const { currentSchedule, actions, currentSite } = this.props;
    let valuesToSend;

    switch (requestName) {
      case 'person':
        valuesToSend = generatePersonRules(values, this.state[stateKey].rules);
        break;
      case 'management':
        valuesToSend = generateManagementRules(values, this.state[stateKey].rules);
        break;
      case 'team':
        valuesToSend = generateTeamRules(values, this.state[stateKey].rules);
        break;
      default:
        break;
    }

    const updatedValuesToSend = {
      ...valuesToSend,
      AssignmentType: AssignmentType[currentSchedule.AssignmentType - 1],
      id: currentSchedule.Id,
      SiteId: currentSite.Id,
    };
    let valueToCheck;

    switch (requestName) {
      case 'person':
        valueToCheck = valuesToSend.PersonRules;
        break;
      case 'management':
        valueToCheck = valuesToSend.ManagementRules;
        break;
      case 'team':
        valueToCheck = valuesToSend.TeamRules;
        break;
      default:
        break;
    }

    if (!checkOptions(valueToCheck)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState(state => ({ isErrorShown: false, previewNames: [...state.previewNames, requestName] }));
      if (requestName === 'management') {
        actions.sendManagementOptionsRequest(updatedValuesToSend);
      }
      if (requestName === 'team') {
        actions.sendTeamOptionsRequest(updatedValuesToSend);
      }
      if (requestName === 'person') {
        actions.sendPersonOptionsRequest(updatedValuesToSend);
      }
    }
  };

  render() {
    const { currentSite, shortcut, unitsAndItems, currentSchedule, teamsList, jobsList, listStatus, ...rest } =
      this.props;

    return (
      <S.Container>
        <S.MainTitle>{shortcut?.Name || ''}</S.MainTitle>
        <S.SiteBlock>
          <S.SecondaryTitle>
            {currentSite && `${currentSite.AdditionalSiteID.substring(0, 5)} ${currentSite.Name}`}
          </S.SecondaryTitle>
          <S.Title>{`${unitsAndItems[0]} Units`}</S.Title>
          <S.Title>{`${unitsAndItems[1]} Items`}</S.Title>
        </S.SiteBlock>
        <S.Step>5 of 5</S.Step>
        <Form
          onSubmit={this.onSubmitForm}
          render={({ handleSubmit, values, reset }) => (
            <form onSubmit={handleSubmit}>
              <PickupForm
                teamsAreEmpty={teamsList.length === 0}
                teamsList={teamsList}
                jobsList={jobsList}
                reset={reset}
                listStatus={listStatus}
                addOption={this.addOption}
                removeOption={this.removeOption}
                onChangeMatch={this.onChangeMatch}
                getPreviewResults={this.getPreviewResults}
                type={currentSchedule.AssignmentType}
                values={values}
                {...this.state}
                {...rest}
              />
              <ActionsContainer>
                <ActionButton label="Previous" isNext onClick={this.onPrevious} />
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts, people, teams, newSites }, { currentSite }) => ({
  manager: shortcuts.managementOptions,
  person: shortcuts.personOptions,
  team: shortcuts.teamOptions,
  shortcut: shortcuts.shortcut,
  currentSchedule: shortcuts.currentSchedule,
  error: shortcuts.error,
  unitsAndItems: countUnitsAndItems(shortcuts.listOfItems, currentSite?.Id),
  availabilityOptions: people.availabilityOptions,
  teamsList: teams.list,
  jobsList: newSites.jobsList,
  listStatus: shortcuts.listStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
      ...peopleActions,
      ...newSitesActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pickup);
