import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';
import { SettingsListType, SiteType } from 'configs/propTypes';

import withInputModal from 'components/Dialogs/withInputModal';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import { ResetButton } from 'components/Dialogs/withInputModal/Controls/styled';
import { RestoreIcon } from 'components/Layout/Icons';
import { getCurrentModule } from 'redux/app/selectors';

import * as S from './styled';

class BeaconDialogForm extends Component {
  static propTypes = {
    changeDistance: PropTypes.func.isRequired,
    changeDistanceItem: PropTypes.func.isRequired,
    changeDistanceUnit: PropTypes.func.isRequired,
    isSettings: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    distance: PropTypes.number.isRequired,
    distanceItem: PropTypes.number.isRequired,
    distanceUnit: PropTypes.number.isRequired,
    defaultSettings: SettingsListType,
    values: SiteType,
  };

  static defaultProps = {
    defaultSettings: {},
    values: {},
  };

  resetBeacons = () => {
    const { form, defaultSettings, values } = this.props;

    form.reset({
      ...values,
      BeaconDistance: defaultSettings.BeaconDistance,
      BeaconDistanceItem: defaultSettings.BeaconDistanceItem,
      BeaconDistanceUnit: defaultSettings.BeaconDistanceUnit,
    });
  };

  render() {
    const { changeDistance, changeDistanceItem, changeDistanceUnit, distance, distanceItem, distanceUnit, isSettings } =
      this.props;

    return (
      <S.FormContainer>
        <div>
          <Field
            name="BeaconDistance"
            component={SelectFieldAdapter}
            floatingLabelText="SITE Beacon Distance"
            value={distance}
            onChange={changeDistance}
            style={S.SelectFieldWidth}
          >
            <MenuItem primaryText="None" value={0} />
            <MenuItem primaryText={`Far${isSettings ? ' (default)' : ''}`} value={1} />
            <MenuItem primaryText="Near" value={2} />
            <MenuItem primaryText="Immediate" value={3} />
          </Field>
        </div>
        <div>
          <Field
            name="BeaconDistanceUnit"
            component={SelectFieldAdapter}
            floatingLabelText="UNITs Beacon Distance"
            value={distanceUnit}
            onChange={changeDistanceUnit}
            style={S.SelectFieldWidth}
          >
            <MenuItem primaryText="None" value={0} />
            <MenuItem primaryText="Far" value={1} />
            <MenuItem primaryText={`Near${isSettings ? ' (default)' : ''}`} value={2} />
            <MenuItem primaryText="Immediate" value={3} />
          </Field>
        </div>
        <div>
          <Field
            name="BeaconDistanceItem"
            component={SelectFieldAdapter}
            floatingLabelText="ITEMs Beacon Distance"
            value={distanceItem}
            onChange={changeDistanceItem}
            style={S.SelectFieldWidth}
          >
            <MenuItem primaryText="None" value={0} />
            <MenuItem primaryText="Far" value={1} />
            <MenuItem primaryText="Near" value={2} />
            <MenuItem primaryText={`Immediate${isSettings ? ' (default)' : ''}`} value={3} />
          </Field>
        </div>
        {!isSettings ? (
          <ResetButton
            type="button"
            label="Reset to company defaults for this Site"
            position
            onClick={this.resetBeacons}
          >
            <RestoreIcon />
          </ResetButton>
        ) : null}
      </S.FormContainer>
    );
  }
}

const BeaconDialogWithInput = withInputModal(BeaconDialogForm);

const BeaconDialog = ({ site, ...rest }) => <BeaconDialogWithInput {...rest} initialValues={site} />;

BeaconDialog.defaultProps = {
  site: {
    Address: {
      City: '',
      Country: '',
      State: '',
      Street: '',
      Street2: '',
      ZipCode: '',
    },
    AdministratorEmail: '',
    AdministratorName: '',
    AdministratorPhone: '',
    AdministratorTitle: '',
    BeaconDistance: 0,
    BeaconDistanceItem: 0,
    BeaconDistanceUnit: 0,
    BeaconId: '',
    Id: '',
    Name: '',
    Phone: '',
    AdditionalSiteID: '',
  },
};

BeaconDialog.propTypes = {
  isSettings: PropTypes.bool.isRequired,
  site: SiteType,
};

const mapStateToProps = ({ app }) => ({
  isSettings: getCurrentModule(app),
});

export default connect(mapStateToProps)(BeaconDialog);
