import { createSelector } from 'reselect';

export const getAssignableUsers = createSelector(
  schedule => schedule.taskAssignableUsers,
  (schedule, AllowUnassignedUserInSchedule) => AllowUnassignedUserInSchedule,
  (assignableUsers, isUnassignAllowed) => {
    if (isUnassignAllowed) {
      assignableUsers.push({ Key: 'OpenAssignmentKey', Value: 'Open Assignment' });
    }
    return assignableUsers;
  },
);

export const getUnitItems = createSelector(
  schedule => schedule.unitItems,
  schedule => Object.keys(schedule.taskTemplates),
  (items, tasksItems) => items.filter(item => tasksItems.includes(item.Name)).sort((a, b) => a.Sequence - b.Sequence),
);
