import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import InstructionsHandler from 'helpers/InstructionsHandler';

import * as S from './styled';

const TutorialVideoDialog = ({ open, videoContent, module, onRequestClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const backgroundColor = InstructionsHandler.MODULES[module].backgroundColor;
  const { Name, Video } = videoContent;

  const showLoading = () => setIsLoading(true);

  const hideVideoLoading = () => setIsLoading(false);

  const onModalClose = () => {
    onRequestClose();
    showLoading();
  };

  return (
    <S.Dialog title={Name} module={module} backgroundColor={backgroundColor} open={open} onRequestClose={onModalClose}>
      <Loader isLoading={isLoading} size="large" />
      <iframe
        title={Name}
        width="640px"
        height="360px"
        allow="autoplay"
        allowFullScreen
        frameBorder="0"
        referrerPolicy="strict-origin"
        src={Video}
        loading="eager"
        onLoad={hideVideoLoading}
      />
    </S.Dialog>
  );
};

TutorialVideoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  module: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  videoContent: PropTypes.shape({
    Name: PropTypes.string,
    Video: PropTypes.string,
  }).isRequired,
};

export default TutorialVideoDialog;
