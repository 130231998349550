import React, { ChangeEvent } from 'react';
import { TextInput } from 'components/Input';

import * as S from './styled';
import { FlattenInterpolation, ThemeProps } from 'styled-components';

const SearchField = <D,>({
  searchData,
  placeholder,
  inputStyle,
  name,
  list,
  filterBy,
  underlineShow,
  setSearchedItems,
  ...restProps
}: PropsType<D>) => {
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const searchQuery = value.toLowerCase();

    const filteredItems = list.filter((item: any) => {
      if (typeof filterBy === 'string') {
        return item[filterBy].toLowerCase().includes(searchQuery);
      }
      return filterBy.some(field => typeof item[field] === 'string' && item[field].toLowerCase().includes(searchQuery));
    });

    setSearchedItems(filteredItems, value);
  };

  return (
    <S.SearchContainer>
      <TextInput
        type={underlineShow ? 'text' : 'search'}
        name={name}
        onChange={onSearch}
        value={searchData}
        placeholder={placeholder}
        inputStyles={[...(underlineShow ? S.underLineStyle : S.inputStyles), ...(inputStyle || [])]}
        inputWrapperStyles={underlineShow ? S.underLineWrapperStyle : S.inputWrapperStyles}
        {...restProps}
      />
    </S.SearchContainer>
  );
};

type PropsType<D> = {
  list: Array<D>;
  filterBy: string | string[];
  searchData: string;
  placeholder: string;
  underlineShow?: boolean;
  inputStyle?: FlattenInterpolation<ThemeProps<any>>[];
  setSearchedItems: (filteredItems: D[], value: string) => void;
  name: string;
};

SearchField.defaultProps = {
  searchData: '',
  inputStyle: [],
  underlineShow: false,
  name: '',
};

export default SearchField;
