import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TeamType } from 'configs/propTypes';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { actions as scheduleActions } from 'redux/schedule';
import { ButtonIcon } from 'components/Layout/Buttons';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import TasksTable from './TasksTable';
import { generateDataToSend } from '../helpers';

import * as S from './styled';

import theme from 'theme';

const AssignmentType = {
  0: 'Pickup',
  1: 'Team',
  2: 'Person',
};

class SelectTasks extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      requestAddSchedule: PropTypes.func.isRequired,
      requestEditSchedule: PropTypes.func.isRequired,
      requestGetTaskStatus: PropTypes.func.isRequired,
      clearTaskStatus: PropTypes.func.isRequired,
    }).isRequired,
    currentUnit: PropTypes.shape().isRequired,
    currentChecklist: PropTypes.shape.isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    sitePeople: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    list: PropTypes.arrayOf(TeamType).isRequired,
    tasksList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onExit: PropTypes.func.isRequired,
    createdChecklist: PropTypes.shape.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    taskStatus: PropTypes.shape({
      CompletedCount: PropTypes.number.isRequired,
      InProgressCount: PropTypes.number.isRequired,
      RequireAttentionCount: PropTypes.number.isRequired,
    }).isRequired,
    onBack: PropTypes.func.isRequired,
    overrideTime: PropTypes.bool.isRequired,
    checklistToEdit: PropTypes.shape().isRequired,
    currentTasks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    dublicateChecklist: PropTypes.bool.isRequired,
  };

  state = {
    createChecklistDialogOpened: false,
    isOpenConfirmationDialog: false,
    duplicateItemsWithTasks: [],
    removeItems: [],
    itemsWithTasks:
      !this.props.dublicateChecklist && this.props.checklistToEdit.Name
        ? Object.values(this.props.currentTasks)
        : this.props.tasksList,
  };

  componentWillReceiveProps(nextProps) {
    const { duplicateItemsWithTasks, removeItemWithTasks, removeItems } = this.state;

    if (JSON.stringify(nextProps.taskStatus) !== JSON.stringify(this.props.taskStatus)) {
      if (
        nextProps.taskStatus.CompletedCount ||
        nextProps.taskStatus.InProgressCount ||
        nextProps.taskStatus.RequireAttentionCount
      ) {
        this.switchConfirmationDialog();
      }

      if (
        nextProps.taskStatus.CompletedCount === 0 &&
        nextProps.taskStatus.InProgressCount === 0 &&
        nextProps.taskStatus.RequireAttentionCount === 0
      ) {
        this.setState({
          itemsWithTasks: duplicateItemsWithTasks,
          removeItems: Array.isArray(removeItemWithTasks)
            ? [...removeItems, ...removeItemWithTasks]
            : [...removeItems, removeItemWithTasks],
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.actions.clearTaskStatus();
  }

  switchConfirmationDialog = () => {
    const { isOpenConfirmationDialog } = this.state;

    this.setState({ isOpenConfirmationDialog: !isOpenConfirmationDialog });
  };

  handleDelete = () => {
    const { duplicateItemsWithTasks, removeItemWithTasks, removeItems } = this.state;

    this.setState({
      itemsWithTasks: duplicateItemsWithTasks,
      removeItems: Array.isArray(removeItemWithTasks)
        ? [...removeItems, ...removeItemWithTasks]
        : [...removeItems, removeItemWithTasks],
    });
    this.switchConfirmationDialog();
  };

  handleDeleteEdit = (index, itemId) => {
    if (itemId) {
      const selectedTask =
        this.state.itemsWithTasks
          .find(item => item[0].ItemId === itemId)
          ?.filter((item, indexTask) => indexTask === index) || [];
      const ids = selectedTask.map(item => item.Id);

      this.onDeleteTaskEdit(index, itemId);
      this.props.actions.requestGetTaskStatus({
        templateIds: ids,
      });
    } else {
      const ids = this.state.itemsWithTasks[index].map(item => item.Id);

      this.onDeleteItemEdit(index);
      this.props.actions.requestGetTaskStatus({
        templateIds: ids,
      });
    }
  };

  onDeleteItem = index => {
    const array = this.state.itemsWithTasks.slice();
    array.splice(index, 1);
    this.setState({ itemsWithTasks: array });
  };

  onDeleteItemEdit = index => {
    const { itemsWithTasks } = this.state;
    const array = itemsWithTasks.slice();
    array.splice(index, 1);
    this.setState({ duplicateItemsWithTasks: array, removeItemWithTasks: itemsWithTasks[index] });
  };

  onDeleteTask = (index, itemId) => {
    const array = this.state.itemsWithTasks.slice();
    const tmpIndex = array.indexOf(array.find(item => item.Id === itemId));
    array[tmpIndex].TaskTemplates.splice(index, 1);
    if (array[tmpIndex].TaskTemplates.length === 0) {
      array.splice(tmpIndex, 1);
    }
    this.setState({ itemsWithTasks: array });
  };

  onDeleteTaskEdit = (index, itemId) => {
    const { itemsWithTasks } = this.state;
    const array = cloneDeep(itemsWithTasks);
    const tmpIndex = array.indexOf(array.find(item => item[0].ItemId === itemId));
    array[tmpIndex].splice(index, 1);
    if (array[tmpIndex].length === 0) {
      array.splice(tmpIndex, 1);
    }

    this.setState({ duplicateItemsWithTasks: array, removeItemWithTasks: itemsWithTasks[tmpIndex][index] });
  };

  onSuccess = () => {
    this.setState({ createChecklistDialogOpened: true });
  };

  getAssignmentType = (type, assigneeId) => {
    if (type === 0) {
      return assigneeId ? 2 : 0;
    }
    return 1;
  };

  onSend = () => {
    const { currentUnit, currentChecklist, actions, overrideTime, checklistToEdit, dublicateChecklist } = this.props;
    const { itemsWithTasks, removeItems } = this.state;
    const tasks = [];
    let isAssignmentChanged = false;

    if (!dublicateChecklist && checklistToEdit.Name) {
      isAssignmentChanged =
        currentChecklist.AssignmentType !==
        this.getAssignmentType(checklistToEdit.ChecklistAssignmentType, checklistToEdit.AssigneeID);
      removeItems.forEach(task => tasks.push({ id: task.TaskTemplateId }));
    } else {
      itemsWithTasks.forEach(item => {
        item.TaskTemplates.forEach(task => tasks.push(task));
      });
    }

    const sendData =
      !dublicateChecklist && checklistToEdit.Name ? actions.requestEditSchedule : actions.requestAddSchedule;

    sendData({
      dataToSend: checklistToEdit.Id
        ? { ...generateDataToSend(currentChecklist), Id: checklistToEdit.Id }
        : generateDataToSend(currentChecklist),
      currentChecklist,
      unitId: currentUnit.Id,
      ignoreLeadTime: overrideTime,
      taskTemplates: tasks,
      isAssignmentChanged:
        !isEqual(Object.values(this.props.currentTasks), this.state.itemsWithTasks) ||
        isAssignmentChanged ||
        checklistToEdit.TeamId !== currentChecklist.TeamName ||
        checklistToEdit.AssigneeID !== currentChecklist.PersonName ||
        currentChecklist.OwnerName !== checklistToEdit.SupervisorId,
      onSuccess: this.onSuccess,
    });
  };

  closeCreateChecklistDialog = () => {
    this.props.onExit();
    this.setState({ createChecklistDialogOpened: false });
  };

  getSearchName = () => {
    const { currentChecklist, people, sitePeople } = this.props;
    return currentChecklist.TeamName
      ? people.find(item => item.Id === currentChecklist.PersonName).Name
      : sitePeople.find(item => item.UserId === currentChecklist.PersonName).UserName;
  };

  render() {
    const {
      currentUnit,
      currentChecklist,
      createdChecklist,
      list,
      onBack,
      checklistToEdit,
      taskStatus,
      dublicateChecklist,
    } = this.props;
    const { itemsWithTasks } = this.state;

    const currentItemsNames = Object.keys(this.props.currentTasks);

    return (
      <S.Container>
        <S.MainTitle>
          {currentUnit && `Site: ${currentUnit.AdditionalSiteId.substring(0, 5)} ${currentUnit.SiteName}`}
        </S.MainTitle>
        <S.MainTitle>
          {'List Template:'} <b>{currentUnit && ` ${currentUnit.Name}`}</b>
        </S.MainTitle>
        <S.RedInfo>
          Checklist:&nbsp;<S.Info>{currentChecklist.Name}</S.Info>
        </S.RedInfo>
        <S.RedInfo>
          Assigned to:&nbsp;<S.Info>{AssignmentType[currentChecklist.AssignmentType]}</S.Info>
        </S.RedInfo>
        {currentChecklist.AssignmentType !== 0 ? (
          <S.RedInfo>
            Name:&nbsp;
            <S.Info>
              {currentChecklist.AssignmentType === 1
                ? list.find(item => item.Id === currentChecklist.TeamName).Name
                : this.getSearchName()}
            </S.Info>
          </S.RedInfo>
        ) : (
          <S.RedInfo>
            Name:&nbsp;<S.Info>Anyone</S.Info>
          </S.RedInfo>
        )}
        <S.RedText>Items and Tasks to include</S.RedText>
        <>
          {!dublicateChecklist && checklistToEdit.Name
            ? itemsWithTasks.map(
                (item, index) =>
                  item.length > 0 && (
                    <S.Section>
                      <S.SectionHeader color={theme.mainRed}>
                        {currentItemsNames[index]}
                        <ButtonIcon>
                          <S.IconDelete onClick={() => this.handleDeleteEdit(index)} />
                        </ButtonIcon>
                      </S.SectionHeader>
                      <S.SectionBody>
                        <TasksTable list={item} onDelete={this.handleDeleteEdit} />
                      </S.SectionBody>
                    </S.Section>
                  ),
              )
            : itemsWithTasks.map(
                (item, index) =>
                  item.TaskTemplates.length > 0 && (
                    <S.Section>
                      <S.SectionHeader color={theme.mainRed}>
                        {item.Name}
                        <ButtonIcon>
                          <S.IconDelete onClick={() => this.onDeleteItem(index)} />
                        </ButtonIcon>
                      </S.SectionHeader>
                      <S.SectionBody>
                        <TasksTable list={item.TaskTemplates} onDelete={this.onDeleteTask} />
                      </S.SectionBody>
                    </S.Section>
                  ),
              )}
        </>
        <ActionsContainer>
          <ActionButton label="CANCEL/PREVIOUS" onClick={onBack} />
          <ActionButton
            label={!dublicateChecklist && checklistToEdit.Name ? 'UPDATE CHECKLIST' : 'CREATE CHECKLIST'}
            isNext
            onClick={this.onSend}
          />
        </ActionsContainer>
        <ConfirmationDialog
          isNext
          handleClose={this.closeCreateChecklistDialog}
          title={'Alert'}
          titleColor={theme.mainRed}
          text={checklistToEdit.Name ? 'Checklist has been updated.' : 'Checklist has been created.'}
          labelCancel={'Close'}
          label={'View Result'}
          width={'40%'}
          onCancelClick={() => {
            this.closeCreateChecklistDialog();
          }}
          onClick={() => {
            this.closeCreateChecklistDialog();
            this.props.history.push(`/scheduler/list/${currentUnit.Id}/details/${createdChecklist.Id}`);
          }}
          open={this.state.createChecklistDialogOpened}
        />
        <ConfirmationDialog
          label="Remove"
          isNext
          width={'40%'}
          maxWidth={'600px'}
          text={
            <S.Container>
              <S.Title>Tasks in progress!</S.Title>
              <>
                <S.Text>
                  Completed: <b>{taskStatus.CompletedCount}</b>
                </S.Text>
                <S.Text>
                  Attention: <b>{taskStatus.RequireAttentionCount}</b>
                </S.Text>
                <S.Text>
                  Open: <b>{taskStatus.InProgressCount}</b>
                </S.Text>
                <p />
                <S.RedTextModal>Completed Tasks will be archived</S.RedTextModal>
                <S.RedTextModal>Attention Tasks will be deleted</S.RedTextModal>
              </>
              <ActionsContainer>
                <ActionButton label="CANCEL" onClick={this.switchConfirmationDialog} />
                <ActionButton label="ARCHIVE/REMOVE" isNext onClick={this.handleDelete} />
              </ActionsContainer>
            </S.Container>
          }
          title="Alert"
          open={this.state.isOpenConfirmationDialog}
          handleClose={this.switchConfirmationDialog}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ people, sites, unit, teams, schedule }) => ({
  isLoading: teams.isLoading || unit.isLoading,
  list: teams.list,
  people: people.list.filter(user => user.Status !== 'Inactive'),
  sitePeople: sites.assignedUsersFullInfo,
  tasksList: unit.unitWithItemsTasksList,
  createdChecklist: schedule.createdChecklist,
  taskStatus: schedule.taskStatus,
  currentTasks: schedule.taskTemplates,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      requestAddSchedule: scheduleActions.addScheduleRequest,
      requestEditSchedule: scheduleActions.editScheduleRequest,
      requestGetTaskStatus: scheduleActions.taskStatusRequest,
      clearTaskStatus: scheduleActions.clearTaskStatus,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectTasks);
