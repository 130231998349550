import { ButtonIcon } from 'components/Layout/Buttons';
import { QueueModuleIcon } from 'components/Layout/Icons';
import { ListItem } from 'components/Layout/Section';
import React, { FC } from 'react';

type PropsType = {
  openPdfReportDetailsDialog: () => void;
};

export const Reports: FC<PropsType> = ({ openPdfReportDetailsDialog }) => (
  <ListItem
    zIndex={6}
    key="Reports"
    name="Reports"
    content={
      <ButtonIcon onClick={openPdfReportDetailsDialog} tooltip="Report details">
        <QueueModuleIcon />
      </ButtonIcon>
    }
  />
);
