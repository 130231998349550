import moment from 'moment';

const isDate = date => date instanceof Date && !isNaN(new Date(date));

export const divideTasksByUnitName = tasks => {
  const tasksByUnitName = {};
  const tasksIds = [];

  tasks.forEach(item => {
    tasksIds.push(item.Id);
    if (!tasksByUnitName[item.ItemName]) {
      tasksByUnitName[item.ItemName] = [];
    }

    tasksByUnitName[item.ItemName].push(item);
  });

  return { tasksByUnitName, tasksIds };
};

export const returnIds = list => list.map(item => item.Id);

export const formatDateParams = params => {
  const formatted = {};
  const keys = Object.keys(params);

  keys.forEach(item => {
    if (isDate(params[item])) {
      formatted[item] = moment(params[item]).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      formatted[item] = params[item];
    }
  });

  return formatted;
};
