import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

const generatePreviewResults = entity => (
  <S.PreviewItemContainer>
    {entity.map(({ Name }) => (
      <S.PreviewItem>
        <S.PreviewItemText>{Name}</S.PreviewItemText>
      </S.PreviewItem>
    ))}
  </S.PreviewItemContainer>
);

const TestrunDialog = ({ open, handleClose, data, name }) => (
  <Dialog open={open} onRequestClose={handleClose} title="Testrun" titleColor={theme.darkGrey}>
    <S.Container>
      <S.PreviewText>Manual Status Index Item(s)</S.PreviewText>
      <S.PreviewTitle>{name}</S.PreviewTitle>
      <S.Section>
        <S.SectionHeader>
          Index Item(s) Result
          <S.IconContainerPreview>
            <S.TestRunIconPreview />
          </S.IconContainerPreview>
        </S.SectionHeader>
        <S.SectionBody>
          <S.CounterContainer>
            <S.ItemsCount>
              <S.BoltText>Items Found</S.BoltText>: {data.length}
            </S.ItemsCount>
          </S.CounterContainer>
          {generatePreviewResults(data)}
        </S.SectionBody>
      </S.Section>
    </S.Container>
  </Dialog>
);

TestrunDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

TestrunDialog.defaultProps = {
  withHeader: true,
  CustomHeaderComponent: null,
  shortcutName: '',
};

export default TestrunDialog;
