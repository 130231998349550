const placeholders = {
  name: 'A cool name, email or phone number',
  submittedPassword: 'Password: string, phrase or sentence',
  phone: 'Phone number for notifications',
  firstName: 'First Name',
  lastName: 'Last Name',
};

export const signUpInputFields = [
  {
    fieldName: 'name',
    nextField: false,
    placeholder: placeholders.name,
    label: 'Username (login name or number)',
    type: 'text',
  },
  {
    fieldName: 'submittedPassword',
    nextField: false,
    placeholder: placeholders.submittedPassword,
    label: 'Password',
    type: 'password',
  },
  { fieldName: 'firstName', nextField: true, placeholder: placeholders.firstName, label: 'First Name', type: 'text' },
  { fieldName: 'lastName', nextField: true, placeholder: placeholders.lastName, label: 'Last Name', type: 'text' },
  { fieldName: 'phone', nextField: true, placeholder: placeholders.phone, label: 'Phone', type: 'number' },
];

export const entryExists = 'Sorry, this entry already exists! Please try again or contact us for more information';
