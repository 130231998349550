export const validator = values => {
  const errors = {};

  if (!values.MyPartID) {
    errors.MyPartID = 'My Part ID is required!';
  }

  if (!values.MyPartName) {
    errors.MyPartName = 'Part Name is required!';
  }

  if (!values.OEMID) {
    errors.OEMID = 'OEM Name is required!';
  }

  if (!values.SupplierID) {
    errors.SupplierID = 'Supplier Name is required!';
  }

  return errors;
};

export default validator;
