import styled from 'styled-components';

export const FormContainer = styled.div`
  padding: 30px 20px;
`;

export const FormCardContainer = styled.div`
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  margin-bottom: 40px;
`;

export const DemoTenantsHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 15px 15px 0 0;
  background-color: ${({ theme }) => theme.signUpColor};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
`;

export const AllTenantsHeader = styled(DemoTenantsHeader)`
  background-color: ${({ theme }) => theme.primaryRed};
`;

export const CardContentContainer = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`;

export const AllTenantsContentContainer = styled(CardContentContainer)`
  padding: 20px 40px;
  justify-content: flex-start;
`;

export const CurrentCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AllTenantsCurrentCountContainer = styled(CurrentCountContainer)`
  margin-right: 125px;
`;

export const BoldLabel = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const CurrentCount = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryBlack};
  margin-top: 35px;
`;

export const TenantMaxAccountValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    margin-bottom: 15px;
  }
`;

export const Label = styled.label`
  margin-left: 50px;
`;
