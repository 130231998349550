import styled from 'styled-components';
import { FieldCheckbox } from 'components/Layout/Inputs';

export const Container = styled.div`
  padding: 15px 0 0;
  display: flex;
  flex-direction: column;
  max-height: 760px;
  box-sizing: border-box;
`;

export const Table = styled.div`
  margin: 10px 0 30px;
  flex: 1;
  overflow-y: auto;
  padding: 0 15px;
`;

export const SelectAllContainer = styled.div`
  padding: 0 15px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(3, 3fr);
  ${({ noborder }) => !noborder && 'border-bottom: 1px solid #ccc'};
  padding: 0 15px;
`;

export const Column = styled.div`
  min-height: 40px;
  padding: 5px;
  display: grid;
  justify-items: center;
  align-items: center;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  overflow: hidden;
`;

export const HeaderColumn = styled(Column)`
  font-weight: bold;
  font-size: 18px;
`;

export const ButtonContainer = styled.div``;

export const Checkbox = styled(FieldCheckbox).attrs({
  style: { marginBottom: '15px' },
  iconStyle: ({ theme, checked }) => ({ fill: checked && theme.primaryScheduler }),
})``;
