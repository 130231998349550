const validator = values => {
  const errors = {};

  if (!values.Type) {
    errors.Type = 'Required';
  }

  return errors;
};

export default validator;
