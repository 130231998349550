import styled from 'styled-components';

export const MessageContainer = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  padding: 12px;
  line-height: 24px;
  background: ${({ theme }) => theme.lightGrey};
  border: 1px solid ${({ theme }) => theme.darkGrey};
  border-radius: 10px;
  white-space: pre;
  &:nth-child(2) {
    min-height: 236px;
  }
  &:last-child {
    min-height: 92px;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
