import styled, { css } from 'styled-components';
import theme from 'theme';

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const inputWrapperStyles = css`
  width: 60%;
`;

const inputStyles = css<{ borderRadius?: string }>`
  padding: 4px 6px;
  border: medium double ${theme.primaryHome};
  background-color: ${theme.lightGrey};
  border-radius: 10px;

  &::placeholder {
    color: ${theme.primaryHome};
  }
`;

const underLineStyle = css`
  background: transparent;
  border: none;
  outline: none;

  &::placeholder {
    color: ${theme.primaryDark};
  }
`;

const underLineWrapperStyle = css`
  border-bottom: 1px solid #e0e0e0;
  width: 20%;

  &:hover {
    border-bottom: 2px solid #cc0000;
  }
`;

export { SearchContainer, inputWrapperStyles, inputStyles, underLineStyle, underLineWrapperStyle };
