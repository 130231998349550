import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box } from 'grid-styled';

import { PaperContainer } from 'components/Layout/Containers';

export const iconStyle = {
  color: '#ffffff',
  height: '50px',
  width: '50px',
};

export const LinkWrapper = styled(Link)`
  width: 100%;
  text-decoration: none;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 1045px) {
    flex-direction: column;
    margin-bottom: ${({ isParts }) => (isParts ? '15px' : '0px')};
  }
  @media only screen and (min-width: 1045px) {
    gap: 20px;
  }
`;

export const RowLinkWrapper = styled(LinkWrapper)`
  @media only screen and (min-width: 1045px) {
    &:not(:last-child) {
      margin-bottom: ${({ isGlobalSettings }) => (isGlobalSettings ? '0px' : '15px')};
    }
  }
`;

export const ModulePaper = styled(PaperContainer)`
  padding: 10px 15px;
  border-radius: 8px !important;
  background-color: ${({ color }) => color} !important;
  position: relative;
`;

export const ModuleLabel = styled(Box).attrs({
  mr: 1,
})`
  color: ${({ theme, type }) => (type === 'activator' ? theme.textActivator : theme.primaryWhite)};
  font-size: 30px;
  font-weight: 200;
  margin-left: 30px;
  @media only screen and (max-width: 330px) {
    margin-left: 15px;
  }
`;

export const TasksNumberContainer = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 60px;
  height: 35px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primaryRed};
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RequestsNumberContainer = styled.div`
  width: 65px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.requestsNumberColor};
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  position: absolute;
  right: 15px;
`;
