import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import validate from './validator';
import * as S from './styled';

const AddEditIndustryForm = () => (
  <S.FormContainer>
    <Field component={TextFieldAdapter} name="Name" placeholder="Industry Name" />
  </S.FormContainer>
);

const AddEditIndustry = withInputModal(AddEditIndustryForm);

const AddEditIndustryDialog = props => (
  <AddEditIndustry initialValues={props.industry} validate={validate} {...props} />
);

AddEditIndustryDialog.propTypes = {
  industry: PropTypes.shape({
    Name: PropTypes.string,
  }).isRequired,
};

AddEditIndustryDialog.defaultProps = {
  industry: {
    Name: '',
  },
};

export default AddEditIndustryDialog;
