import React from 'react';
import PropTypes from 'prop-types';

import { FieldSelect } from 'components/Layout/Selects';

const SelectFieldAdapter = ({ input, meta, onChangeControl, ...rest }) => (
  <FieldSelect
    {...input}
    {...rest}
    onChange={(event, index, value) => {
      onChangeControl(value);
      if (value !== 'Edit List') {
        input.onChange(value);
      }
    }}
    errorText={meta.visited || meta.touched || meta.error ? meta.error : ''}
  />
);
SelectFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeControl: PropTypes.func,
};

SelectFieldAdapter.defaultProps = {
  onChangeControl: () => {},
};

export default SelectFieldAdapter;
