import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CircularProgress from 'material-ui/CircularProgress';
import SnackBar from 'components/Snackbar';

import { ActionButton, ActionsContainer } from '../Dialogs/v1/Base';
import Item from './Item';
import ItemTitle from './ItemTitle';
import LockButton from './LockButton';
import { InfoIcon } from '../Layout/Icons';

import * as S from './styled';

const reorder = (list, startIndex, endIndex, key) => {
  const names = list[key].items.map(item => item.content);
  const ids = list[key].items.map(item => item.id);
  const tooltipTitles = list[key].items.map(item => item.tooltipTitle);
  const [removed] = names.splice(startIndex, 1);
  names.splice(endIndex, 0, removed);
  const [remove] = ids.splice(startIndex, 1);
  ids.splice(endIndex, 0, remove);
  const [removeTooltip] = tooltipTitles.splice(startIndex, 1);
  tooltipTitles.splice(endIndex, 0, removeTooltip);

  list[key].items = list[key].items.map((item, index) => ({
    id: ids[index],
    sequence: item.sequence,
    content: names[index],
    tooltipTitle: tooltipTitles[index],
  }));
  list[key].isChanged = true;
  return list;
};

const DndList = ({ data, commonLockHide, onSave, listId, isLoading, isNewSaveButton }) => {
  const [reorderedItems, setReorderItems] = useState([]); // data
  const [key, setKey] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [commonListLockState, setCommonListLockState] = useState(false);

  useEffect(() => {
    setReorderItems(data);
    setCommonListLockState(data.every(({ isStrictOrder }) => isStrictOrder));
  }, [data]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const items = reorder(reorderedItems, result.source.index, result.destination.index, key);

    setReorderItems(items);

    if (result.destination.index !== result.source.index) {
      setDisabled(false);
    }
  };

  const onPress = () => {
    setOpen(true);
    setDisabled(true);

    switch (listId) {
      case 'FullUnitItems':
        setReorderItems(
          reorderedItems.map(({ isChanged, ...item }) => {
            if (isChanged) {
              onSave(item.items);
            }
            return item;
          }),
        );
        break;
      case 'UnitItems':
        onSave(reorderedItems[0].items);
        break;
      case 'ItemsTasks':
        onSave(reorderedItems);
        break;
      default:
        break;
    }
  };

  const setKeyHandler = index => {
    setKey(index);
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const toggleItemStrictMode = item => {
    const newItemsList = reorderedItems.slice();
    newItemsList.splice(item.sequence - 1, 1, item);

    const listLockState = newItemsList.find(itemData => itemData.isStrictOrder === false);

    setReorderItems(newItemsList);
    setCommonListLockState(!listLockState);
    setDisabled(false);
    if (!item.isStrictOrder) {
      setCommonListLockState(false);
    }
  };

  const toggleListStrictMode = isStrictOrder => {
    const newItemsList = reorderedItems.map(item => ({ ...item, isStrictOrder }));

    setReorderItems(newItemsList);
    setCommonListLockState(isStrictOrder);
    setDisabled(false);
  };

  return (
    <S.Container>
      <S.DragDropContainer>
        {!commonLockHide && listId !== 'UnitItems' && listId !== 'FullUnitItems' ? (
          <Fragment>
            <S.ListLockContainer>
              <LockButton
                item={{ isStrictOrder: commonListLockState }}
                toggleItemStrictMode={() => toggleListStrictMode(!commonListLockState)}
              />
              <S.ListLockTitle>{'Set all to complete in strict order'}</S.ListLockTitle>
              <S.InfoIconContainer>
                <S.InfoTooltip tooltip={'Strict sequence means that user gets warned when completing out of order'}>
                  <InfoIcon />
                </S.InfoTooltip>
              </S.InfoIconContainer>
            </S.ListLockContainer>
            <S.SeparatorContainer>
              <S.Separator />
            </S.SeparatorContainer>
          </Fragment>
        ) : null}
        {isLoading ? (
          <S.Overlay>
            <CircularProgress size={50} />
          </S.Overlay>
        ) : (
          reorderedItems?.map((item, index) => (
            <DragDropContext key={index} onDragEnd={onDragEnd} onDragStart={() => setKeyHandler(index)}>
              <Droppable droppableId={listId}>
                {provided => (
                  <div ref={provided.innerRef}>
                    <S.ItemWrapper>
                      {listId !== 'UnitItems' && listId !== 'FullUnitItems' ? (
                        <Fragment>
                          <LockButton item={item} toggleItemStrictMode={toggleItemStrictMode} />
                          <S.EntryNumberItem>{item.sequence ? `(${item.sequence})` : '(1)'}</S.EntryNumberItem>
                        </Fragment>
                      ) : null}
                      <div>
                        <ItemTitle title={item.content} />
                        {item.items &&
                          item.items.map((task, ind) => (
                            <S.ItemContainer key={ind}>
                              <S.EntryNumberTask>{ind + 1}</S.EntryNumberTask>
                              <Draggable key={task.id} draggableId={task.id} index={ind}>
                                {(draggableProvided, draggableSnapshot) => (
                                  <Item
                                    item={task}
                                    provided={draggableProvided}
                                    snapshot={draggableSnapshot}
                                    tooltipData={task.tooltipTitle}
                                    id={task.id}
                                  />
                                )}
                              </Draggable>
                            </S.ItemContainer>
                          ))}
                      </div>
                    </S.ItemWrapper>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ))
        )}
      </S.DragDropContainer>
      {isNewSaveButton ? (
        <ActionsContainer>
          <ActionButton
            type="button"
            label="Save"
            onClick={onPress}
            disabled={disabled}
            isDisable={disabled}
            isNext
            isGreenLabel
          />
        </ActionsContainer>
      ) : (
        <S.ButtonContainer>
          <S.SaveButton disabled={disabled} onClick={onPress} />
        </S.ButtonContainer>
      )}
      <SnackBar handleClose={handleClose} open={open} message="Saved successfully!" />
    </S.Container>
  );
};

DndList.propTypes = {
  data: PropTypes.array.isRequired,
  listId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  commonLockHide: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNewSaveButton: PropTypes.bool,
};

DndList.defaultProps = {
  commonLockHide: false,
  isLoading: false,
  isNewSaveButton: false,
};

export default DndList;
