import styled from 'styled-components';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0;
  text-align: start;
  width: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
`;

export const FieldBlockContainer = styled.div`
  display: flex;
  jistify-content: start;
`;

export const FieldRadioContainer = styled.div`
  display: flex;
  width: 180%;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

export const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export const TimeBlockContainer = styled.div`
  display: flex;
  jistify-content: space-between;
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 20%;
  input {
    font-size: 13px !important;
  }
`;

export const TimeFieldBlock = styled(FieldBlock)`
  width: 100%;
  justify-content: center;
  margin-top: -14px;
  &>div: last-child {
    text-align: left;
    padding-left: 10px;
  }
`;

export const Checkbox = styled(CheckboxAdapter).attrs({
  style: { marginBottom: '15px' },
  iconStyle: ({ input, theme }) => ({ fill: input.value && theme.primaryScheduler }),
})``;

export const typeRadioStyle = {
  display: 'flex',
  margin: '-4px 0px 0px 10px',
  fontSize: '14px',
  width: '100%',
};
