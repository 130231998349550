import styled from 'styled-components';

const Content = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  height: 250px;

  img {
    width: 90%;
    height: 90%;
    border-radius: 10px;
    margin: 10px;
  }
`;

const CustomSwiperContainer = styled.div`
  position: relative;

  & > div > div {
    width: 100%;
  }

  .swiper-container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 5px 0;
    box-sizing: border-box;
    z-index: 0;
  }

  .custom-swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    box-sizing: initial;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-image: none;
    top: 47%;
    width: 36px;
    height: 36px;
  }

  .swiper-button-prev {
    left: 1%;
  }

  .swiper-button-next {
    right: 1%;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 16px;

  & > span {
    color: ${({ theme }) => theme.darkGrey};
  }

  & > svg {
    color: ${({ theme }) => theme.primaryTasks} !important;
    width: 40px !important;
    height: 40px !important;
  }
`;

const TaskNotesContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.primaryGrey};
  text-align: start;
  padding: 0px 28px 16px;
`;

const Name = styled.div`
  padding: 6px 0;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.primaryGrey};
`;

const Value = styled.div`
  font-size: 0.8rem;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.mainRed};
  padding-bottom: 10px;
`;

const EmptyText = styled.p``;

export {
  Content,
  ImageContainer,
  CustomSwiperContainer,
  ToggleContainer,
  TaskNotesContainer,
  Name,
  Value,
  Text,
  EmptyText,
};
