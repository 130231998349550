import React from 'react';
import * as S from './styled';

export const getModalContentByType = (formik: any, entityType: 'Part' | 'PartGroup') => ({
  add: {
    title: `Receive ${entityType === 'PartGroup' ? 'Group' : entityType} Quantity`,
    content: (
      <div>
        <S.Paragraph>
          The following quantity is received and <br /> placed in inventory:
        </S.Paragraph>
        <div>
          <S.Input type="number" name="newQuantity" {...formik.getFieldProps('newQuantity')} />
          <S.Label>enter new received</S.Label>
        </div>
      </div>
    ),
    negativeBtnLabel: 'ADD',
  },
  addOrRemove: {
    title: 'Alert',
    content: (
      <S.Container>
        <S.Text>
          <strong>Adjusting counts:</strong>
        </S.Text>
        <S.Question>Add or Remove?</S.Question>
        <S.Info>
          Count adjustments should only occur to <br />
          correct mistakes and may result in <br />
          unscheduled purchases.
        </S.Info>
      </S.Container>
    ),
    negativeBtnLabel: 'ADD',
  },
  edit: {
    title: 'Adjust Purchase Trigger',
    content: (
      <>
        <S.Paragraph>
          When inventory reaches this quantity, it <br /> will trigger a purchase advice.
        </S.Paragraph>
        <S.Wrapper>
          <div>
            <S.Input type="number" value={formik.values.editedField} isRedBack />
            <S.Label>Current</S.Label>
          </div>
          <div>
            <S.Input type="number" name="newQuantity" {...formik.getFieldProps('newQuantity')} />
            <S.Label>enter new qty</S.Label>
          </div>
        </S.Wrapper>
      </>
    ),
    negativeBtnLabel: 'CHANGE',
  },
});
