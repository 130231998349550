import styled from 'styled-components';
import { MenuItem as MuiMenuItem } from 'material-ui';

const HeaderMenuItem = styled(MuiMenuItem)`
  ${({ theme }) => `
      margin-top: -8px;
      color: ${theme.primaryWhite}!important;
      background-color: ${theme.headerColor};
  `}
`;

const MenuItem = styled(MuiMenuItem)`
  ${({ styles }) => styles}
`;

export { HeaderMenuItem, MenuItem };
