import styled from 'styled-components';

export const Container = styled.div`
  width: 93%;
  margin: 0 auto;
  & > div:last-child {
    padding: 20px 0px !important;
  }
`;

export const Block = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const BlockDate = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Column = styled.div``;

export const ItemsCircle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryWhite};
  background-color: ${({ theme }) => theme.mainDarkBlue};
  font-size: 35px;
`;

export const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 300;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
`;

export const SecondaryTitle = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  max-width: 450px;
  margin: 0 auto;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 5px 0;
`;

export const RedText = styled(Text)`
  color: ${({ theme }) => theme.mainRed};
  margin-top: 10px;
`;
