import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import AssignmentForm from 'components/Dialogs/Shortcut/Assignment/Form';
import PickupForm from 'components/Dialogs/Shortcut/Pickup/Form';

import { ShortcutSchedule } from 'configs/propTypes';
import { isDayInThePast } from 'helpers';

import TaskRadioGroup from './TaskFrequencyRadioGroup';
import IntervalRadioGroup from './RepeatIntervalRadioGroup';
import IntervalTable from './IntervalTable';
import { datePickers } from './constants';
import * as S from './styled';

const getInitialValueForDate = (name, currentSchedule) => {
  if (currentSchedule.Id) {
    return name === 'StartDate' || name === 'startTime'
      ? new Date(currentSchedule.StartDate)
      : new Date(currentSchedule.EndDate);
  }
  return name === 'StartDate' || name === 'EndDate' ? null : 0;
};

const ShortcutForm = ({
  appModule,
  values,
  isEdit,
  currentSchedule,
  reset,
  listStatus,
  teamsList,
  jobsList,
  initialValues,
  ...rest
}) => (
  <S.FormContainer>
    <S.Block>
      <S.Label>Task Frequency</S.Label>
      {!isEdit ? (
        <>
          <S.FieldLabel>Repeat Cycle</S.FieldLabel>
          <Field
            component={TaskRadioGroup}
            name="RepeatCycle"
            style={S.radioStyle}
            initialValue={currentSchedule.Id ? currentSchedule.RepeatCycle : 0}
          />
        </>
      ) : (
        <S.SmallInfoContainer>
          <S.RedInfoSmall>
            Repeat:&nbsp;<S.InfoSmall>{initialValues.repeat}</S.InfoSmall>
          </S.RedInfoSmall>
          <S.RedInfoSmall>
            Skip:&nbsp;<S.InfoSmall>{initialValues.RepeatInterval}</S.InfoSmall>
          </S.RedInfoSmall>
        </S.SmallInfoContainer>
      )}
      <S.FieldBlockContainer>
        {datePickers.map(({ name, Picker, label }) => (
          <S.FieldBlock key={name}>
            <Field
              name={name}
              component={Picker}
              shouldDisableDate={isDayInThePast}
              floatingLabelText={label}
              initialValue={getInitialValueForDate(name, currentSchedule) || initialValues[name]}
              disabled={isEdit}
            />
          </S.FieldBlock>
        ))}
      </S.FieldBlockContainer>
    </S.Block>
    {!isEdit && (
      <S.Block>
        <S.Label>Repeat Interval&nbsp;(next period to schedule tasks in)</S.Label>
        <S.SecondaryContainer>
          <Field
            component={IntervalRadioGroup}
            name="RepeatInterval"
            style={S.radioIntervalStyle}
            initialValue={currentSchedule.Id ? currentSchedule.RepeatInterval : 1}
          />
          <IntervalTable />
        </S.SecondaryContainer>
      </S.Block>
    )}
    {appModule === 'shortcuts' && (
      <>
        <S.AssignementBlock>
          <S.Label isRed>Assignment Type for this Site</S.Label>
          <AssignmentForm teamsAreEmpty={teamsList.length === 0} />
        </S.AssignementBlock>
        <PickupForm
          withDisabledFields
          type={values.AssignmentType + 1}
          listStatus={listStatus}
          teamsAreEmpty={teamsList.length === 0}
          jobsList={jobsList}
          teamsList={teamsList}
          values={values}
          reset={reset}
          {...rest}
        />
      </>
    )}
  </S.FormContainer>
);

ShortcutForm.propTypes = {
  appModule: PropTypes.string.isRequired,
  currentSchedule: ShortcutSchedule.isRequired,
  teamsList: PropTypes.shape().isRequired,
  jobsList: PropTypes.shape().isRequired,
  listStatus: PropTypes.shape().isRequired,
  reset: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    AssignmentType: PropTypes.number,
  }).isRequired,
  initialValues: PropTypes.shape({
    RepeatInterval: PropTypes.number,
    repeat: PropTypes.string,
  }).isRequired,
};

export default ShortcutForm;
