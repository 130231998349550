import styled from 'styled-components';

import BaseDialog from 'components/Dialogs/Base';
import { ButtonFlat } from 'components/Layout/Buttons';

export const DialogContainer = styled(BaseDialog).attrs({
  actionsContainerStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  title: 'Export CSV',
})``;

export const CancelButton = styled(ButtonFlat).attrs({
  label: 'Cancel',
})`
  min-width: 50% !important;

  span {
    color: ${({ theme }) => theme.modalCancelColor} !important;
  }
`;

export const CreateButton = styled(ButtonFlat).attrs({
  label: 'Create',
})`
  min-width: 50% !important;
  border-left: 1px solid ${({ theme }) => theme.lightGrey} !important;

  span {
    color: ${({ theme }) => theme.modalOkColor} !important;
  }
`;
