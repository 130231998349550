import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete';

import { FieldText } from 'components/Layout/Inputs';

const TextFieldAdapter = ({
  input,
  isDecimal,
  meta,
  onChangeControl,
  inputValue,
  withAutocomplete,
  autoCompleteOptions,
  inputStyle,
  floatingLabelStyle,
  type,
  ...rest
}) => {
  const onChangeHandler = value => {
    if (isDecimal && value) {
      value = value.match(/^-?\d*(\.(\d{0,4})?)?/)[0];
    }

    onChangeControl(value, rest);
    input.onChange(value);
  };

  const loseFocus = event => event.currentTarget.blur();

  return !withAutocomplete ? (
    <FieldText
      {...input}
      {...rest}
      onChange={(event, value) => onChangeHandler(value)}
      value={inputValue === '' ? inputValue : input.value}
      type={type}
      onWheel={rest.type === 'number' ? loseFocus : null}
      errorText={meta.visited || meta.touched || meta.error ? meta.error : ''}
      inputStyle={inputStyle}
      floatingLabelStyle={floatingLabelStyle}
    />
  ) : (
    <AutoComplete
      dataSource={autoCompleteOptions}
      {...input}
      {...rest}
      filter={(searchText, key) => key.toLowerCase().includes(searchText.toLowerCase())}
      onUpdateInput={onChangeHandler}
      menuStyle={{ height: '100%', maxHeight: '300px' }}
      value={input.value}
      errorText={meta.visited || meta.touched ? meta.error : ''}
      fullWidth
    />
  );
};

TextFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  isDecimal: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  onChangeControl: PropTypes.func,
  inputValue: PropTypes.string,
  withAutocomplete: PropTypes.bool,
  autoCompleteOptions: PropTypes.arrayOf(PropTypes.string),
  inputStyle: PropTypes.object,
  floatingLabelStyle: PropTypes.object,
  type: PropTypes.string,
};

TextFieldAdapter.defaultProps = {
  withAutocomplete: false,
  type: 'text',
  inputValue: null,
  isDecimal: false,
  onChangeControl: () => {},
  autoCompleteOptions: [],
  inputStyle: {},
  floatingLabelStyle: {},
};

export default TextFieldAdapter;
