import styled from 'styled-components';

const List = styled.div`
  max-height: 30vh;
  overflow-y: auto;
`;

const ListItem = styled.div`
  padding: 16px;
  font-size: 18px;
  color: ${({ theme }) => theme.primaryBlack};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

const Label = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.approxGrey};
  background: ${({ theme }) => theme.primaryHealth};
  width: 100%;
  font-size: 16px;
`;

const Item = styled.b`
  color: ${({ theme }) => theme.mainRed};
`;

export { List, ListItem, Label, Item };
