const TABLE_CONFIGS = [
  { title: 'Inventory Start Date', field: 'CreatedOn' },
  { title: 'Allocated', field: 'Allocated' },
  { title: 'On Hand', field: 'OnHand' },
  { title: 'Available', field: 'Available' },
  { title: 'Back-Order', field: 'BackOrder' },
  { title: 'PO Advice', field: 'PurchasOrderAdvice' },
  { title: 'Total On Hand Value', field: 'TotalOnHandValue' },
  { title: 'Closed/Verified By', field: 'ClosedBy' },
  { title: 'Part Details', field: '', colSpan: 3 },
];

const Z_INDEX_VALUE = 99;

export { TABLE_CONFIGS, Z_INDEX_VALUE };
