import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import TypeRadioGroup from './TypeRadioGroup';

import * as S from './styled';

const SiteForm = () => (
  <S.FormContainer>
    <S.FieldContainer>
      <Field component={TypeRadioGroup} name="Type" style={S.radioStyle} initialValue={0} />
    </S.FieldContainer>
  </S.FormContainer>
);

class Setup extends Component {
  static propTypes = {
    openCreateShortcutDialog: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
  };

  onSubmitForm = ({ values }) => {
    if (values.Type === 1) {
      this.props.openCreateShortcutDialog();
    } else {
      this.props.onNext();
    }
  };

  render() {
    return (
      <S.Container>
        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SiteForm values={values} />
              <ActionsContainer>
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

export default Setup;
