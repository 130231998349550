import React from 'react';
import PropTypes from 'prop-types';

import EmptyList from 'components/EmptyList';
import ListExchangeItem from './ListExchangeItem';
import { ListContainer } from 'components/Layout/Containers';

const emptyListMessage = 'There are no units';
const getNameAndModel = item => `${item.Name} ${item.ModelNumber}`;
const getCompanyAndSite = item =>
  `${
    item.ExportedFromSurveyProjects
      ? `${item.SurveyCompanyName} ${item.SurveySiteName ? '/' : ''} ${item.SurveySiteName}`
      : `${item.TenantName} ${item.SiteName ? '/' : ''} ${item.SiteName}`
  }`;
const getAddress = item =>
  `${item.SiteAddress.ZipCode || ''} ${item.SiteAddress.City || ''} ${item.SiteAddress.Street || ''}`;

const List = ({ data, onItemClick, onCheck, checked }) => (
  <ListContainer>
    {data && data.length ? (
      data.map((unit, index) => (
        <ListExchangeItem
          key={`Unit${index}`}
          getNameAndModel={getNameAndModel}
          getCompanyAndSite={getCompanyAndSite}
          getAddress={getAddress}
          item={unit}
          onItemClick={onItemClick}
          onCheck={onCheck}
          checked={checked}
        />
      ))
    ) : (
      <EmptyList text={emptyListMessage} />
    )}
  </ListContainer>
);

List.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default List;
