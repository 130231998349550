import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { exportTriggers } from 'http/queue';
import { actions as queueActions } from 'redux/queue';
import { actions as sitesActions } from 'redux/sites';
import { openErrorDialog } from 'redux/errorHandler';
import { ContentContainer } from 'components/Layout/Containers';
import Period from 'components/Period';
import Footer from 'components/Footer';
import { progressTasksToggle } from 'configs/toggles';

import List from './List';
import Toolbar from './Toolbar';

class Trigger extends Component {
  static propTypes = {
    clearStateError: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    triggers: PropTypes.shape({
      triggersByUserId: PropTypes.objectOf(PropTypes.objectOf(PropTypes.array)),
      userIds: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    match: PropTypes.object.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    requestSitesList: PropTypes.func.isRequired,
    requestTriggersQueue: PropTypes.func.isRequired,
  };

  state = {
    isExpanded: false,
    expandedId: '',
  };

  componentDidMount() {
    this.props.requestSitesList();
    this.props.requestTriggersQueue({ rangeStart: this.props.match.params.date });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.date !== nextProps.match.params.date) {
      this.props.requestTriggersQueue({ rangeStart: nextProps.match.params.date });
    }

    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
      this.props.clearStateError();
    }
  }

  onNext = () => {
    const { date } = this.props.match.params;
    const newDate = moment(date).add(1, 'd').format('YYYY-MM-DD');

    this.props.history.push(`./${newDate}`);
  };

  onPrev = () => {
    const { date } = this.props.match.params;
    const newDate = moment(date).subtract(1, 'd').format('YYYY-MM-DD');

    this.props.history.push(`./${newDate}`);
  };

  exportDayTriggers = () => {
    exportTriggers(this.props.match.params.date);
  };

  exportUserTriggers = userId => {
    exportTriggers(this.props.match.params.date, userId);
  };

  openCalendar = () => {
    this.setState({ expandedId: '' });
    document.getElementById('queue-day-picker').click();
  };

  periodHandler = (event, date) => {
    this.props.history.push(`./${moment(date).format('YYYY-MM-DD')}`);
  };

  expandedToggle = id => {
    if (id !== this.state.expandedId) {
      this.setState({ expandedId: id });
    } else {
      this.setState({ expandedId: '' });
    }
  };

  goBack = () => this.props.history.push('/queue');

  render() {
    const rangeStart = moment(this.props.match.params.date);

    return [
      <ContentContainer key="Queue Trigger">
        <Toolbar
          exportDayTriggers={this.exportDayTriggers}
          onDayChange={this.periodHandler}
          openCalendar={this.openCalendar}
          goBack={this.goBack}
        />
        <Period type="today" configs={progressTasksToggle} startRange={rangeStart} />
        <List
          onExport={this.exportUserTriggers}
          triggers={this.props.triggers}
          expandedToggle={this.expandedToggle}
          isExpanded={this.state.expandedId}
        />
      </ContentContainer>,
      <Footer key="Queue Footer" label="Day" onPrev={this.onPrev} onNext={this.onNext} />,
    ];
  }
}

const mapStateToProps = ({ queue }) => ({
  error: queue.error,
  triggers: queue.triggersQueue,
});

const mapDispatchToProps = {
  clearStateError: queueActions.stateErrorClear,
  openErrorDialog,
  requestSitesList: sitesActions.sitesListRequest,
  requestTriggersQueue: queueActions.fetchTriggersQueueRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trigger);
