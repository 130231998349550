import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';

import { actions as itemActions } from 'redux/item';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import TextTooltipFieldAdapter from 'components/Forms/TextTooltipFieldAdapter';
import { UnitItemType } from 'configs/propTypes';
import { getImageSource } from 'http/item';

import * as S from './styled';
import { compose } from 'redux';

const tooltipText = {
  scanCode: <p>If none available enter unique item/part name or other identifier (numbers or text)</p>,
};

class AddEditForm extends PureComponent {
  static propTypes = {
    isDefaultBarcode: PropTypes.bool,
    form: PropTypes.object.isRequired,
    initialValues: UnitItemType.isRequired,
    itemID: PropTypes.string,
    getDefaultBarcodeRequest: PropTypes.func.isRequired,
    setDefaultBarcode: PropTypes.func,
    title: PropTypes.string.isRequired,
    unitId: PropTypes.string.isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    isDefaultBarcode: false,
    itemID: null,
    setDefaultBarcode: () => {},
    values: {},
    initialValues: {
      Barcode: { Value: '' },
      BeaconDistance: 3,
    },
  };

  resetForm = defaultBarcode => {
    const { form, values } = this.props;

    form.reset({
      ...values,
      Barcode: {
        ...values.Barcode,
        Value: defaultBarcode,
      },
    });

    this.props.setDefaultBarcode(true);
  };

  resetBarcode = () => {
    const { itemID, unitId } = this.props;
    const params = { id: itemID, unitId };

    this.props.getDefaultBarcodeRequest({ params, reset: this.resetForm });
  };

  render() {
    const { initialValues, isDefaultBarcode, setDefaultBarcode, title } = this.props;

    return (
      <S.Container>
        {initialValues.Id && (
          <Field disabled component={TextFieldAdapter} floatingLabelText="Sequence" name="Sequence" type="number" />
        )}
        <Field component={TextFieldAdapter} floatingLabelText="Item Name" name="Name" maxLength="255" />
        <TextTooltipFieldAdapter
          name="Barcode.Value"
          labelText="Scan/QR code"
          tooltip={tooltipText.scanCode}
          tooltipWidth="380px"
          handleReset={this.resetBarcode}
          setDefaultBarcode={setDefaultBarcode}
          isDefaultBarcode={isDefaultBarcode}
          isHelp
          isReset={title === 'Edit Item'}
        />
        <S.WrapperBarcode valueLength={initialValues.Barcode.Value.length}>
          <FormSpy
            subscription={{ values: true }}
            initialBarcode={initialValues.Barcode && initialValues.Barcode.Value}
          >
            {({ initialBarcode, values }) =>
              values.Barcode && values.Id && initialValues.Barcode ? (
                <img src={getImageSource(values.Id, values.Barcode.Value, initialBarcode)} alt={values.Barcode.Value} />
              ) : null
            }
          </FormSpy>
        </S.WrapperBarcode>
        <S.Block>
          <S.Label>Optional Internal Info</S.Label>
          <S.InputContainer>
            <Field
              component={TextFieldAdapter}
              floatingLabelText="Item Description"
              name="Description"
              maxLength="255"
            />
            <Field
              component={TextFieldAdapter}
              name="Instructions"
              floatingLabelText="Item Instructions"
              maxLength="255"
            />
            <Field component={TextFieldAdapter} floatingLabelText="Item Notes" name="Notes" maxLength="255" />
          </S.InputContainer>
        </S.Block>
      </S.Container>
    );
  }
}

const mapStateToProps = ({ item: { details }, unit: { unitData } }) => ({
  itemID: details.Id,
  unitId: unitData.Id,
});

const mapDispatchToProps = {
  ...itemActions,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withInputModal)(AddEditForm);
