const updateSystemLinks = (links, state) => {
  const linkArray = state.systemLinks.map((link, index) => ({
    ...state.systemLinks[index],
    IsTaskSpecialist: links[index] ? links[index].IsTaskSpecialist : false,
    IsAdminMngr: links[index] ? links[index].IsAdminMngr : false,
    IsOEMMngr: links[index] ? links[index].IsAdminMngr.IsOEMMngr : false,
    Title: links[index] ? links[index].Title : '',
    Subtext: links[index] ? links[index].Subtext : '',
    Link: links[index] ? links[index].Link : '',
  }));

  return {
    ...state,
    systemLinks: [...linkArray],
    masterSettings: {},
    masterSystemLinks: [],
    error: '',
    loading: false,
  };
};

export const transformSystemLinksList = (state, stateValues) => {
  const updatedSystemLinks = [];

  Object.entries(stateValues).reduce((link, value, index) => {
    link[value[0].substring(1)] = value[1];
    if ((index + 1) % 6 === 0) {
      updatedSystemLinks.push(link);
      link = {};
    }
    return link;
  }, {});

  return updateSystemLinks(updatedSystemLinks, state);
};

export const addSpacingForNotifications = (values, prevSpace, nextSpace) => {
  const newValues = { ...values };

  Object.keys(values).forEach(key => {
    if (values[key] && (key.includes('alutation') || key.includes('ntroText') || key.includes('losingText'))) {
      newValues[key] = newValues[key].replace(prevSpace, nextSpace);
    }
  });

  return newValues;
};
