import axios from 'axios';
import queryString from 'query-string';

export const baseURL = process.env.REACT_APP_BASE_API;

const urlWithoutSiteId = [
  /SiteUserManagement/,
  /GetUserSites/,
  /MasterSettings/,
  /Unit\/List/,
  /Item\/List/,
  /VersionLegal/,
  /StatusIndex/,
  /Checklist\/GetSchedulerStatistics/,
];

const http = axios.create({
  baseURL,
  siteId: '',
  withCredentials: true,
});

http.interceptors.request.use(
  request => {
    const newRequest = { ...request };

    const sign = request.url.split('?')[1] ? '&' : '?';
    const dc = Date.now();
    if (urlWithoutSiteId.some(url => url.test(request.url))) {
      newRequest.url = request.url + sign + queryString.stringify({ _dc: dc });
    } else if (request.url === 'Team/List' && request.data?.siteId) {
      newRequest.url = request.url + sign + queryString.stringify({ _dc: dc, siteId: request.data.siteId });
    } else {
      newRequest.url = request.url + sign + queryString.stringify({ _dc: dc, siteId: request.siteId });
    }

    return newRequest;
  },
  error => Promise.reject(error),
);

http.interceptors.response.use(
  response => response,
  error => {
    const newError = { ...error };

    if (error?.response?.status === 403 && error.response?.data?.type === 'DEMO_TIER_EXPIRED') {
      newError.response.data.error = error.response?.data?.error;
    }

    if (error?.response?.status === 404) {
      window.location.replace(`${window.location.origin}/errorpage`);
      newError.response.data.error = error.response?.data?.error;
    }

    return Promise.reject(newError);
  },
);

export default http;
