import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import { TeamType } from 'configs/propTypes';
import { actions as teamsActions } from 'redux/teams';
import Subheader from 'components/Subheader';
import SimpleList from 'components/SimpleList';
import { ButtonIcon } from 'components/Layout/Buttons';
import { BackIcon, AddIcon } from 'components/Layout/Icons';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import AddEditTeamDialog from './AddEditTeamDialog';

import Item from './Item';

import * as S from './styled';

import theme from 'theme';

class Teams extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      getTeamsRequest: PropTypes.func.isRequired,
      createTeamRequest: PropTypes.func.isRequired,
      updateTeamRequest: PropTypes.func.isRequired,
      deleteTeamRequest: PropTypes.func.isRequired,
      getSiteManagersRequest: PropTypes.func.isRequired,
    }).isRequired,
    list: PropTypes.arrayOf(TeamType).isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    searchData: '',
    addTeamDialogOpened: false,
    teamToEdit: null,
    confirmationDeleteDialogOpened: false,
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getSiteManagersRequest({ siteId: null });
    actions.getTeamsRequest();
  }

  filterTeams = team => {
    const filterBy = ['Name', 'Location', 'Assistant.Name', 'Manager.Name'];
    const query = this.state.searchData.toLowerCase();
    return filterBy.some(field => get(team, field).toLowerCase().includes(query));
  };

  backHome = () => {
    this.props.history.push('/home');
  };

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  closeAddTeamDialog = () => {
    this.setState({ addTeamDialogOpened: false });
  };

  openAddTeamDialog = team => {
    const { list } = this.props;
    if (team.Name) {
      this.setState({
        addTeamDialogOpened: true,
        teamToEdit: list.find(item => item.Id === team.Id),
      });
    } else {
      this.setState({
        addTeamDialogOpened: true,
        teamToEdit: null,
      });
    }
  };

  onDeleteTeam = () => {
    const { actions } = this.props;
    actions.deleteTeamRequest({ Id: this.state.teamToEdit.Id });
    this.closeAddTeamDialog();
    this.closeCloseConfiramtionDeleteDialog();
    this.setState({
      teamToEdit: null,
    });
  };

  openConfiramtionDeleteDialog = () => this.setState({ confirmationDeleteDialogOpened: true });
  closeCloseConfiramtionDeleteDialog = () => this.setState({ confirmationDeleteDialogOpened: false });
  handleSubmitAddEditTeamDialog = item => {
    const { teamToEdit } = this.state;
    const { updateTeamRequest, createTeamRequest } = this.props.actions;
    if (teamToEdit) {
      updateTeamRequest({ team: item, ignoreSecurityRestriction: false });
    } else {
      createTeamRequest({ item });
    }
  };

  renderRightControls = id => (
    <div>
      <Link to={`/teams/${id}`}>
        <ButtonIcon>
          <S.IconForward />
        </ButtonIcon>
      </Link>
    </div>
  );

  render() {
    const { list, people } = this.props;
    const { searchData, teamToEdit } = this.state;

    return (
      <>
        <Subheader
          title="Teams"
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backHome,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              icon: <AddIcon />,
              handler: this.openAddTeamDialog,
              hint: 'Add',
            },
          ]}
          hintText="Search"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          isSiteOnLeftSide
        />
        <SimpleList
          data={list.filter(this.filterTeams)}
          emptyListMessage="There are no Teams available"
          renderItemContent={item => <Item item={item} onClick={this.openAddTeamDialog} />}
          renderRightControls={item => this.renderRightControls(item.Id)}
        />
        <AddEditTeamDialog
          teamToEdit={teamToEdit}
          peopleList={people}
          handleClose={this.closeAddTeamDialog}
          onSubmit={this.handleSubmitAddEditTeamDialog}
          onDelete={this.openConfiramtionDeleteDialog}
          open={this.state.addTeamDialogOpened}
          title={teamToEdit ? 'Edit Team Setup' : 'Team Setup'}
        />
        <ConfirmationDialog
          isNext={false}
          handleClose={this.closeCloseConfiramtionDeleteDialog}
          title={'Alert'}
          titleColor={theme.redButton}
          text={'Are you sure you want to remove this team?'}
          label={'Remove'}
          onClick={this.onDeleteTeam}
          open={this.state.confirmationDeleteDialogOpened}
        />
      </>
    );
  }
}

const mapStateToProps = ({ teams }) => ({
  list: teams.list,
  people: teams.siteManagers,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...teamsActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
