import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import EditableListDialog from 'components/Dialogs/EditableListDialog';
import { Modal } from 'components/Modal';
import theme from 'theme';
import { actions as partOptionsActions } from 'redux/partOptions';
import { actions as partsActions } from 'redux/parts';

import { newPartCards, editModalValues, fieldLabels, textFields, selectFields } from './constants';
import validate from './validator';
import * as S from './styled';

export const NewPartDialog = ({ handleClose, open }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validate,
    onSubmit: values => {
      dispatch(partsActions.addPartRequest(values));
      handleClose();
      formik.resetForm();
    },
  });

  const [editableValue, setEditableValue] = useState('oems');
  const [editModalOpen, setEditModalOpen] = useState(false);

  const options = useSelector(state => state.partOptions.options);
  const oems = useSelector(state => state.parts.oemList);
  const suppliers = useSelector(state => state.parts.suppliersList);
  const dispatch = useDispatch();
  const data = { oems, suppliers };

  // eslint-disable-next-line no-shadow
  const onEdit = editableValue => {
    setEditableValue(editableValue);
    setEditModalOpen(true);
  };

  const closeEditModal = () => setEditModalOpen(false);

  const copyValues = (from, to) => {
    const sourceValues = Object.entries(formik.values).filter(([key]) => key.startsWith(from));

    sourceValues.forEach(([key, value]) => {
      const target = `${to}${key.replace(from, '')}`;

      if (target !== 'SupplierPartCategoryID' && target !== 'SupplierPartTypeID') {
        formik.change(`${to}${key.replace(from, '')}`, value);
      }
    });
  };

  const onModalClose = () => {
    handleClose();
    formik.resetForm();
  };

  useEffect(() => {
    dispatch(partOptionsActions.getPartOptionsRequest());
    dispatch(partsActions.getOemListRequest());
    dispatch(partsActions.getSuppliersListRequest());
  }, []);

  return (
    <Modal isOpen={open} onRequestClose={onModalClose} title="New Part" titleColor={theme.primaryDark}>
      <S.DialogContainer>
        {newPartCards.map(({ title, titleType, prefix, buttons, field }) => (
          <S.FormCardContainer key={title}>
            <S.CardHeader titleType={titleType}>
              {titleType === 'my' ? (
                title
              ) : (
                <>
                  <SelectFieldAdapter
                    name={field}
                    meta={formik.getFieldMeta(field)}
                    input={formik.getFieldProps(field)}
                    floatingLabelText={title}
                    onChangeControl={value => formik.setFieldValue(field, value)}
                    underlineStyle={{ display: 'none' }}
                  >
                    {data[`${titleType}s`].length > 0
                      ? data[`${titleType}s`].map(({ Id, Name }) => (
                          <SelectItem key={Id} primaryText={Name} value={Id} />
                        ))
                      : []}
                  </SelectFieldAdapter>
                  <S.EditButton type="button" onClick={() => onEdit(`${titleType}s`)}>
                    Edit
                  </S.EditButton>
                </>
              )}
            </S.CardHeader>
            <S.CardContent>
              {textFields.map(name => (
                <TextFieldAdapter
                  name={`${prefix}${name}`}
                  key={`${prefix}${name}`}
                  meta={formik.getFieldMeta(`${prefix}${name}`)}
                  input={formik.getFieldProps(`${prefix}${name}`)}
                  onChangeControl={value => formik.setFieldValue(`${prefix}${name}`, value)}
                  floatingLabelText={fieldLabels[`${prefix}${name}`].label}
                  floatingLabelFixed
                  floatingLabelStyle={{ color: theme.tenantNameColor, fontWeight: 'bold' }}
                  placeholder={fieldLabels[`${prefix}${name}`].placeholder}
                />
              ))}
              {selectFields.map(({ name, list }) => (
                <SelectFieldAdapter
                  key={`${prefix}${name}`}
                  name={`${prefix}${name}`}
                  meta={formik.getFieldMeta(`${prefix}${name}`)}
                  input={formik.getFieldProps(`${prefix}${name}`)}
                  floatingLabelText={fieldLabels[`${prefix}${name}`].label}
                  onChangeControl={value => formik.setFieldValue(`${prefix}${name}`, value)}
                  floatingLabelFixed
                  floatingLabelStyle={{ color: theme.tenantNameColor, fontWeight: 'bold' }}
                  disabled={!options[list].length}
                  hintText={fieldLabels[`${prefix}${name}`].placeholder}
                >
                  {options[list].length > 0
                    ? options[list].map(({ Id, Name }) => <SelectItem key={Id} primaryText={Name} value={Id} />)
                    : []}
                </SelectFieldAdapter>
              ))}
            </S.CardContent>
            <S.CardButtonContainer>
              {buttons.map(({ buttonTitle, copyValue }) => (
                <S.CardButton type="button" key={buttonTitle} onClick={() => copyValues(copyValue, prefix)}>
                  {buttonTitle}
                </S.CardButton>
              ))}
            </S.CardButtonContainer>
          </S.FormCardContainer>
        ))}
        <EditableListDialog
          defaultList={data[editableValue]}
          open={editModalOpen}
          searchPlaceholder={editModalValues[editableValue].placeholder}
          title={editModalValues[editableValue].title}
          idTitle={editModalValues[editableValue].deleteId}
          handleClose={closeEditModal}
          addItem={partsActions[editModalValues[editableValue].addItem]}
          editItem={partsActions[editModalValues[editableValue].editItem]}
          deleteItem={partsActions[editModalValues[editableValue].deleteItem]}
        />
      </S.DialogContainer>
      <S.SaveButton label="SAVE" fullWidth type="button" onClick={formik.handleSubmit} />
    </Modal>
  );
};

NewPartDialog.propTypes = {
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
