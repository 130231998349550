import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { VictoryChart, VictoryAxis } from 'victory';

import { Dashboard } from 'components/Dashboard';
import { InfoIcon } from 'components/Layout/Icons';

import { generateModules } from 'configs/routes';
import { getSiteStatistics } from 'helpers';

import { actions as schedulerActions } from 'redux/schedule';

import { statisticsLabels, updatedSchedulerModulesData, getStatisticsGraphData } from './helpers';
import * as S from './styled';

const SchedulerDashboard = () => {
  const [isStaticticsShown, setIsStaticticsShown] = useState(true);

  const statistics = useSelector(state => state.schedule.statistics);
  const selectedSite = useSelector(state => state.auth.selectedSite);

  const taskStatistics = getSiteStatistics(statistics.TodayTotal, statisticsLabels);
  const SCHEDULER_MODULES = generateModules(updatedSchedulerModulesData(statistics));
  const { data, dates, tickFormat } = getStatisticsGraphData(statistics.DailyTotalTaskCount || []);
  const dispatch = useDispatch();

  const toggleIsStaticticsShown = () => setIsStaticticsShown(!isStaticticsShown);

  useEffect(() => {
    dispatch(schedulerActions.getSchedulerStatisticsRequest(selectedSite));
  }, [dispatch, selectedSite]);

  return (
    <Dashboard
      showTaskCount
      modules={SCHEDULER_MODULES}
      topContent={
        <>
          <div>
            <VictoryChart domainPadding={28} height={200}>
              <VictoryAxis tickValues={statistics.DailyTotalTaskCount} tickFormat={dates} />
              <VictoryAxis dependentAxis tickFormat={tickFormat} />
              <S.VictoryBarContainer barWidth={36} data={data} x="date" y="tasksCount" />
            </VictoryChart>
          </div>
          <S.StatisticsContainer>
            <S.StaticticsHeaderContainer>
              <S.Button onClick={toggleIsStaticticsShown}>
                {isStaticticsShown ? <S.ChevronDownIcon /> : <S.ChevronUpIcon />}
              </S.Button>
              <S.StaticticsHeader>What is assigned for today?</S.StaticticsHeader>
            </S.StaticticsHeaderContainer>
            {isStaticticsShown && (
              <>
                <S.InfoIcon tooltip="Total assigned tasks for today, regardless of status.">
                  <InfoIcon />
                </S.InfoIcon>
                <S.StatisticsModules>
                  {taskStatistics?.map(({ value, label }) => (
                    <S.StatisticsModuleContainer key={label}>
                      <S.StatisticsValue label={label}>{value}</S.StatisticsValue>
                      <S.StatisticsLabel>{label}</S.StatisticsLabel>
                    </S.StatisticsModuleContainer>
                  ))}
                </S.StatisticsModules>
              </>
            )}
          </S.StatisticsContainer>
        </>
      }
    />
  );
};

export default SchedulerDashboard;
