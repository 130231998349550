import React, { FC } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { useAppSelector } from 'redux/reducers';
import { Link } from 'react-router-dom';
import { InventoryActivationDialog } from 'components/Dialogs/InventoryActivationDialog';
import { ListItem } from 'components/Layout/Section';
import { ButtonIcon } from 'components/Layout/Buttons';
import {
  DeleteForeverIcon,
  DistributionIcon,
  OEMIcon,
  OptionsIcon,
  SuppliersIcon,
  WarehouseIcon,
} from 'components/Layout/Icons';
import { SettingsType } from 'types/settings';

type PropsType = {
  openEditOEMListDialog: () => void;
  openEditSuppliersListDialog: () => void;
  openOptionsListDialog: () => void;
};

export const Parts: FC<PropsType> = ({ openEditOEMListDialog, openEditSuppliersListDialog, openOptionsListDialog }) => {
  const siteSettings = useAppSelector(state => state.settings.list as SettingsType);
  const inventoryActivationDialog = useModal(InventoryActivationDialog);

  const onInventoryIconClick = () => inventoryActivationDialog.show();

  return (
    <ListItem
      key="Parts"
      name="Parts"
      zIndex={2}
      content={
        <>
          <ButtonIcon onClick={openEditOEMListDialog} tooltip="OEMs">
            <OEMIcon />
          </ButtonIcon>
          <ButtonIcon onClick={openEditSuppliersListDialog} tooltip="Suppliers">
            <SuppliersIcon />
          </ButtonIcon>
          <Link to="/settings/distribution/parts">
            <ButtonIcon tooltip="Parts Distro">
              <DistributionIcon />
            </ButtonIcon>
          </Link>
          <Link to="/settings/purgeparts/parts">
            <ButtonIcon tooltip="Purge Parts">
              <DeleteForeverIcon />
            </ButtonIcon>
          </Link>
          <ButtonIcon onClick={openOptionsListDialog} tooltip="Options Lists">
            <OptionsIcon />
          </ButtonIcon>
          {siteSettings.IsInventoryActivated && (
            <ButtonIcon onClick={onInventoryIconClick} tooltip="Site Inventory Activation">
              <WarehouseIcon />
            </ButtonIcon>
          )}
        </>
      }
    />
  );
};
