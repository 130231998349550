import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useModal } from '@ebay/nice-modal-react';

import { BorderRadiusContainer } from 'components/Layout/Containers';
import { CommitUnitIcon, ExportCSVIcon } from 'components/Layout/Icons';
import { actions as queueActions } from 'redux/queue';
import { ArchivingDialog } from '../../Overview/ArchivingDialog';
import { SpreadsheetDownloadDialog } from '../../Overview/SpreadsheetDownloadDialog';
import * as S from './styled';

const Details = ({ data: { assigneeName, period, rangeStart, unitName }, onToggle }) => {
  let selectedTasksInfo = useSelector(state => state.queue.selectedTasksInfo);
  const queueList = useSelector(state => state.queue.queueList);

  const dispatch = useDispatch();
  if (isEmpty(selectedTasksInfo) && !isEmpty(queueList[period].entities)) {
    selectedTasksInfo = queueList[period].entities[unitName]?.find(el => el.AssigneeName === assigneeName);
  }
  const { UnitId, UnitName, AssigneeName, TasksComplete, TasksTotal, TaskCompletePercent } = selectedTasksInfo;

  const archivingModal = useModal(ArchivingDialog);
  const spreadsheetDownloadDialog = useModal(SpreadsheetDownloadDialog);

  const onCommit = () => {
    archivingModal.show({ unitId: UnitId, date: rangeStart });
  };
  const onExport = () => {
    spreadsheetDownloadDialog.show({
      selectedUnit: selectedTasksInfo,
      date: rangeStart,
      period,
    });
  };

  useEffect(() => {
    if (isEmpty(selectedTasksInfo)) {
      dispatch(queueActions.fetchQueueListRequest({ rangeStart }));
    }
  }, []);

  return (
    <S.Container>
      <BorderRadiusContainer>
        <S.Label>
          <S.UnitName>{UnitName}</S.UnitName>
          <S.IconButton onClick={onCommit} tooltip="Archive Completed Tasks">
            <CommitUnitIcon />
          </S.IconButton>
          <S.IconButton onClick={onExport} tooltip="CSV download">
            <ExportCSVIcon />
          </S.IconButton>
        </S.Label>
        <S.TextContainer>
          <S.AssigneeName>{AssigneeName}</S.AssigneeName>
          <S.Progress>
            {TasksComplete} of {TasksTotal} tasks finished ({TaskCompletePercent}% completed)
          </S.Progress>
        </S.TextContainer>
        <S.CheckBoxButton onClick={onToggle} label="Attention View Only" />
      </BorderRadiusContainer>
    </S.Container>
  );
};

Details.propTypes = {
  data: PropTypes.shape({
    assigneeName: PropTypes.string,
    period: PropTypes.string,
    rangeStart: PropTypes.string,
    unitName: PropTypes.string,
    dateSortingValue: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default Details;
