import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import FlatButton from 'material-ui/FlatButton';

import { TaskNotesPhotos } from 'configs/propTypes';
import { ButtonIcon } from 'components/Layout/Buttons';
import ConfirmDialog from 'components/Dialogs/Confirm';
import PhotosModal from './PhotosModal';
import Section from './Section/';
import NotifyForm from './NotifyForm';
import BaseDialog from 'components/Dialogs/Base';
import EmailDialog from './EmailDialog';

import theme from 'theme';
import * as S from './styled';

class TaskWithPartsDetails extends Component {
  static propTypes = {
    OriginatingTaskName: PropTypes.string,
    AssigneeName: PropTypes.string,
    Manager: PropTypes.shape({ Id: PropTypes.string, Name: PropTypes.string }),
    UnitName: PropTypes.string,
    SiteName: PropTypes.string,
    InstructionalNotes: PropTypes.string,
    ItemName: PropTypes.string,
    taskParts: PropTypes.array,
    PartResolution: PropTypes.string,
    Exceptions: PropTypes.string,
    sendEmailWithTaskInfoRequest: PropTypes.func.isRequired,
    AssigneeId: PropTypes.string,
    Id: PropTypes.string.isRequired,
    requestSaveTask: PropTypes.func.isRequired,
    updateAssignedTaskList: PropTypes.func.isRequired,
    RuleType: PropTypes.string.isRequired,
    TeamName: PropTypes.string,
    isDialogWithParts: PropTypes.bool.isRequired,
    notesPhotos: TaskNotesPhotos.isRequired,
  };

  static defaultProps = {
    OriginatingTaskName: '',
    AssigneeName: '',
    Manager: null,
    SiteName: '',
    ItemName: '',
    UnitName: '',
    taskParts: [],
    PartResolution: '',
    AssigneeId: '',
    Exceptions: '',
    RuleType: '',
    TeamName: '',
    InstructionalNotes: '',
  };

  state = {
    isEmailDialogOpen: false,
    isWarningDialogOpened: false,
    isCompletionDialogOpened: false,
    isPhotosModalVisible: false,
    message: '',
    selectedManager: {},
    selectedAssignee: {},
  };

  openWarningDialog = () => {
    this.setState({ isWarningDialogOpened: true });
  };

  closeWarningDialog = () => {
    this.setState({ isWarningDialogOpened: false });
  };

  openEmailDialog = ({ manager, assignee, message }) => {
    this.setState({
      isEmailDialogOpen: true,
      message,
      selectedManager: manager,
      selectedAssignee: assignee,
    });
  };

  closeEmailDialog = () => {
    this.setState({ isEmailDialogOpen: false });
  };

  viewPhotos = () => {
    this.setState({ isPhotosModalVisible: true });
  };

  closePhotosModals = () => {
    this.setState({ isPhotosModalVisible: false });
  };

  openCompletionDialog = () => {
    this.setState({ isCompletionDialogOpened: true });
  };

  closeCompletionDialog = () => {
    this.setState({ isCompletionDialogOpened: false });
  };

  onSubmit = values => {
    const { Manager: manager, AssigneeName: assigneeName, AssigneeId: assigneeId } = this.props;

    if (!values.manager && !values.assignee) {
      this.openWarningDialog();
      return;
    }

    const managerValue = values.manager ? { name: manager?.Name, managerId: manager?.Id } : {};
    const assigneeValue = values.assignee ? { name: assigneeName, assigneeId } : {};

    this.openEmailDialog({
      manager: managerValue,
      assignee: assigneeValue,
      message: values.message,
    });
  };

  sendEmail = () => {
    const { Id, sendEmailWithTaskInfoRequest } = this.props;
    const {
      selectedAssignee: { assigneeId },
      selectedManager: { managerId },
      message,
    } = this.state;

    sendEmailWithTaskInfoRequest({
      taskId: Id,
      recepientsId: assigneeId && managerId ? [assigneeId, managerId] : [assigneeId || managerId],
      messageText: message,
    });

    this.closeEmailDialog();
  };

  completeTask = () => {
    const { requestSaveTask, Id: id, updateAssignedTaskList } = this.props;

    requestSaveTask({
      id,
      ruleType: 'ForceComplete',
    });
    this.closeCompletionDialog();
    updateAssignedTaskList();
  };

  render() {
    const {
      OriginatingTaskName: originatingTaskName,
      AssigneeName: assigneeName,
      Manager: manager,
      SiteName: siteName,
      UnitName: unitName,
      ItemName: itemName,
      TeamName: teamName,
      InstructionalNotes: instructionalNotes,
      Id: id,
      taskParts,
      PartResolution: partResolution,
      RuleType: ruleType,
      Exceptions: exceptions,
      isDialogWithParts,
      notesPhotos,
    } = this.props;

    const {
      isEmailDialogOpen,
      isWarningDialogOpened,
      message,
      selectedAssignee,
      selectedManager,
      isCompletionDialogOpened,
      isPhotosModalVisible,
    } = this.state;

    return (
      <>
        <S.TaskWithPartDetails>
          <S.Head>
            <li>Task: {ruleType}</li>
            <li>Responsible: {teamName || assigneeName || 'Pick-Up'}</li>
            <li>Manager: {manager?.Name}</li>
          </S.Head>
          <Section title="Location Info">
            <S.List>
              <S.LocationItem>
                <S.Item>Site:</S.Item>
                {siteName}
              </S.LocationItem>
              <S.LocationItem>
                <S.Item>Unit:</S.Item>
                {unitName}
              </S.LocationItem>
              <S.LocationItem>
                <S.Item>Item:</S.Item>
                {itemName}
              </S.LocationItem>
            </S.List>
          </Section>
          {!isDialogWithParts && (
            <>
              <Section title="Instructions/Notes">
                <S.List>
                  <S.LocationItem>
                    <S.Item>Task Instructions:</S.Item>
                    {originatingTaskName}
                  </S.LocationItem>
                  <S.LocationItem>
                    <S.Item>Task Notes:</S.Item>
                    {instructionalNotes}
                  </S.LocationItem>
                </S.List>
              </Section>
            </>
          )}
          {isDialogWithParts && (
            <>
              <Section warning={1} title="Parts checked for Attention">
                <S.PartsItem>Part ID</S.PartsItem>
                <S.PartsItem>Part Name</S.PartsItem>
                {taskParts.map(({ myPartId, myPartName }, index) => (
                  <Fragment key={`${myPartId}-${index}`}>
                    <S.PartsItem>
                      <S.PartsValue>{myPartId}</S.PartsValue>
                    </S.PartsItem>
                    <S.PartsItem>
                      <S.PartsValue>{myPartName}</S.PartsValue>
                    </S.PartsItem>
                  </Fragment>
                ))}
              </Section>
              <Section warning={1} title="Part issue">
                <p>{partResolution}</p>
              </Section>
            </>
          )}
          <Section warning={1} title="Attention Issue">
            <p>{exceptions}</p>
          </Section>
          {!isDialogWithParts && (
            <S.PhotosBlock>
              {notesPhotos.length ? (
                <>
                  Photos:
                  <ButtonIcon onClick={this.viewPhotos}>
                    <S.PhotoIcon />
                  </ButtonIcon>
                </>
              ) : (
                <>
                  Photos:
                  <ButtonIcon tooltip="No Photos" onClick={this.viewPhotos}>
                    <S.NoPhotoIcon />
                  </ButtonIcon>
                </>
              )}
            </S.PhotosBlock>
          )}
          <S.NotifyTitle>Notify</S.NotifyTitle>
          <Section title="Message to Assignee(s) ">
            <NotifyForm onSubmit={this.onSubmit} manager={manager} assignee={assigneeName} />
          </Section>
        </S.TaskWithPartDetails>
        <FlatButton fullWidth onClick={this.openCompletionDialog} style={S.primaryStyles}>
          Close and Complete Task
        </FlatButton>
        <BaseDialog open={isEmailDialogOpen} onRequestClose={this.closeEmailDialog} title="Message">
          <EmailDialog
            {...this.props}
            message={message}
            selectedAssignee={selectedAssignee}
            selectedManager={selectedManager}
            handleClose={this.closeEmailDialog}
            handleSend={this.sendEmail}
          />
        </BaseDialog>
        <BaseDialog
          open={isWarningDialogOpened}
          onRequestClose={this.closeWarningDialog}
          title="Warning"
          modalStyles={{ maxWidth: '400px' }}
          titleColor={theme.primaryRed}
        >
          <S.WarningText>Please select someone!</S.WarningText>
          <FlatButton fullWidth onClick={this.closeWarningDialog} style={S.primaryStyles}>
            OK
          </FlatButton>
        </BaseDialog>

        <ConfirmDialog
          open={isCompletionDialogOpened}
          title="Warning"
          text={
            <S.WarningText>
              This will <strong>remove</strong> and complete the Task
              <br />
              Are you sure ?
            </S.WarningText>
          }
          confirmText="Yes"
          cancelText="Cancel"
          onApprove={this.completeTask}
          onReject={this.closeCompletionDialog}
        />
        {isPhotosModalVisible && (
          <PhotosModal
            photos={notesPhotos}
            open={isPhotosModalVisible}
            handleClose={this.closePhotosModals}
            parentId={id}
          />
        )}
      </>
    );
  }
}

export default TaskWithPartsDetails;
