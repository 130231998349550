import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { DeleteIcon } from 'components/Layout/Icons';
import WarningModal from 'components/Dialogs/Warning';

import * as S from './styled';

const checkboxLabels = [
  `Task
  Speclst`,
  `Admin
  Mngr`,
  `OEM
  Mngr`,
];

const fieldLabels = ['Title', 'Subtext', 'Link: www.incheq.com'];

class LinkForm extends Component {
  static propTypes = {
    resetForm: PropTypes.func.isRequired,
    linkValues: PropTypes.array,
    formValues: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    getChangedValues: PropTypes.func.isRequired,
    linkNumber: PropTypes.number.isRequired,
  };

  static defaultProps = {
    linkValues: [],
  };

  state = {
    changedValues: {},
    warningModalOpened: false,
  };

  closeWarningModal = () => {
    this.setState({ warningModalOpened: false });
  };

  openWarningModal = () => {
    this.setState({ warningModalOpened: true });
  };

  submitWarningModal = () => {
    const { formValues, getChangedValues, link, linkValues, resetForm } = this.props;

    const valuesForClear = {
      ...formValues,
    };

    const clearedValues = {
      link,
      reset: true,
    };

    linkValues.forEach(value => {
      valuesForClear[value] = '';
      clearedValues.link[value.substring(1)] = '';
    });

    getChangedValues('', clearedValues);

    resetForm(valuesForClear);
    this.closeWarningModal();
  };

  disableButton = () => {
    const { formValues, linkNumber } = this.props;
    const values = Object.keys(formValues).reduce((arr, value) => {
      if (value.startsWith(linkNumber)) {
        arr.push(formValues[value]);
      }
      return arr;
    }, []);

    return values.every(value => !!value === false);
  };

  render() {
    const { link, linkValues, formValues, getChangedValues, linkNumber } = this.props;
    const { warningModalOpened } = this.state;
    return [
      <S.LinkContainer key={`${link.Id}link`}>
        <S.Roles>
          <S.CheckboxesContainer>
            {linkValues.map((name, index) => {
              if (index < 3) {
                return (
                  <S.ChekboxContainer key={`${name}${index}`}>
                    <Field component={S.Checkbox} name={name} onChangeControl={getChangedValues} link={link} />
                    <S.CheckboxLabel>{checkboxLabels[index]}</S.CheckboxLabel>
                  </S.ChekboxContainer>
                );
              }
              return false;
            })}
          </S.CheckboxesContainer>
          {!formValues[`${linkNumber}IsTaskSpecialist`] &&
          !formValues[`${linkNumber}IsAdminMngr`] &&
          !formValues[`${linkNumber}IsOEMMngr`] &&
          formValues[`${linkNumber}Link`] ? (
            <S.ErrorMessage>Role is required!</S.ErrorMessage>
          ) : null}
        </S.Roles>
        <S.TextFieldsContainer>
          {linkValues.map((name, index) => {
            if (index >= 3) {
              return (
                <Field
                  key={`${name}${index}`}
                  component={S.TextField}
                  name={name}
                  underlineShow={false}
                  hintText={fieldLabels[index - 3]}
                  onChangeControl={getChangedValues}
                  disabled={this.disableButton()}
                  link={link}
                />
              );
            }
            return false;
          })}
        </S.TextFieldsContainer>
        <S.DeleteButton disabled={this.disableButton()} onClick={this.openWarningModal}>
          <DeleteIcon />
        </S.DeleteButton>
      </S.LinkContainer>,
      <WarningModal
        key={`${link.Id}delete`}
        handleClose={this.closeWarningModal}
        onSubmit={this.submitWarningModal}
        open={warningModalOpened}
        text="Clear All Link Information?"
        title="Delete"
      />,
    ];
  }
}

export default LinkForm;
