import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  DELETE_UNIT_REQUEST: undefined,
  DELETE_UNIT_SUCCESS: undefined,
  DELETE_UNIT_FAILURE: undefined,

  DELETE_UNIT_PDF_REQUEST: undefined,
  DELETE_UNIT_PDF_SUCCESS: undefined,
  DELETE_UNIT_PDF_FAILURE: undefined,

  DELETE_UNIT_PICTURE_REQUEST: undefined,
  DELETE_UNIT_PICTURE_SUCCESS: undefined,
  DELETE_UNIT_PICTURE_FAILURE: undefined,

  FETCH_UNIT_REQUEST: undefined,
  FETCH_UNIT_SUCCESS: undefined,
  FETCH_UNIT_FAILURE: undefined,

  EDIT_UNIT_REQUEST: undefined,
  EDIT_UNIT_SUCCESS: undefined,
  EDIT_UNIT_FAILURE: undefined,

  UPLOAD_UNIT_PDF_REQUEST: undefined,
  UPLOAD_UNIT_PDF_SUCCESS: undefined,
  UPLOAD_UNIT_PDF_FAILURE: undefined,

  UPLOAD_UNIT_PICTURE_REQUEST: undefined,
  UPLOAD_UNIT_PICTURE_SUCCESS: undefined,
  UPLOAD_UNIT_PICTURE_FAILURE: undefined,

  ADD_UNIT_ITEM_REQUEST: undefined,
  ADD_UNIT_ITEM_SUCCESS: undefined,
  ADD_UNIT_ITEM_FAILURE: undefined,

  CLEAR_ITEM_ADDED: undefined,
  CLEAR_ADDED_TASK: undefined,

  DELETE_UNIT_ITEM_REQUEST: undefined,
  DELETE_UNIT_ITEM_SUCCESS: undefined,
  DELETE_UNIT_ITEM_FAILURE: undefined,

  DELETE_UNIT_ITEM_PDF_REQUEST: undefined,
  DELETE_UNIT_ITEM_PDF_SUCCESS: undefined,
  DELETE_UNIT_ITEM_PDF_FAILURE: undefined,

  DELETE_UNIT_ITEM_PICTURE_REQUEST: undefined,
  DELETE_UNIT_ITEM_PICTURE_SUCCESS: undefined,
  DELETE_UNIT_ITEM_PICTURE_FAILURE: undefined,

  DUPLICATE_UNIT_ITEM_REQUEST: undefined,
  DUPLICATE_UNIT_ITEM_FAILURE: undefined,

  EDIT_UNIT_ITEM_REQUEST: undefined,
  EDIT_UNIT_ITEM_SUCCESS: undefined,
  EDIT_UNIT_ITEM_FAILURE: undefined,

  UPLOAD_UNIT_ITEM_PDF_REQUEST: undefined,
  UPLOAD_UNIT_ITEM_PDF_SUCCESS: undefined,
  UPLOAD_UNIT_ITEM_PDF_FAILURE: undefined,

  UPLOAD_UNIT_ITEM_PICTURE_REQUEST: undefined,
  UPLOAD_UNIT_ITEM_PICTURE_SUCCESS: undefined,
  UPLOAD_UNIT_ITEM_PICTURE_FAILURE: undefined,

  ADD_UNIT_TASK_REQUEST: undefined,
  ADD_UNIT_TASK_SUCCESS: undefined,
  ADD_UNIT_TASK_FAILURE: undefined,

  DELETE_UNIT_TASK_REQUEST: undefined,
  DELETE_UNIT_TASK_SUCCESS: undefined,
  DELETE_UNIT_TASK_FAILURE: undefined,

  EDIT_UNIT_TASK_REQUEST: undefined,
  EDIT_UNIT_TASK_SUCCESS: undefined,
  EDIT_UNIT_TASK_FAILURE: undefined,

  UNIT_DATA_FETCH_REQUEST: undefined,
  UNIT_DATA_FETCH_SUCCESS: undefined,
  UNIT_DATA_FETCH_FAILURE: undefined,

  ITEM_PDF_UPLOADED_FLAG_UNSET: undefined,
  ITEM_PICTURE_UPLOADED_FLAG_UNSET: undefined,
  UNIT_PDF_UPLOADED_FLAG_UNSET: undefined,
  UNIT_PICTURE_UPLOADED_FLAG_UNSET: undefined,

  FETCH_UNIT_ITEMS_REQUEST: undefined,
  FETCH_UNIT_ITEMS_SUCCESS: undefined,
  FETCH_UNIT_ITEMS_FAILURE: undefined,

  GET_DEFAULT_BARCODE_REQUEST: undefined,
  GET_DEFAULT_BARCODE_SUCCESS: undefined,
  GET_DEFAULT_BARCODE_FAILURE: undefined,

  GET_UNIT_ITEMS_TASKS_REQUEST: undefined,
  GET_UNIT_ITEMS_TASKS_SUCCESS: undefined,
  GET_UNIT_ITEMS_TASKS_FAILURE: undefined,

  UPDATE_TASKS_LIST_REQUEST: undefined,
  UPDATE_TASKS_LIST_SUCCESS: undefined,
  UPDATE_TASKS_LIST_FAILURE: undefined,
  GET_UNIT_PICTURES_REQUEST: undefined,
  GET_UNIT_PDF_REQUEST: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.deleteUnitRequest, handlers.deleteUnitRequest],
    [actions.deleteUnitSuccess, handlers.deleteUnitSuccess],
    [actions.deleteUnitFailure, handlers.deleteUnitFailure],

    [actions.deleteUnitPdfRequest, handlers.deleteUnitPdfRequest],
    [actions.deleteUnitPdfSuccess, handlers.deleteUnitPdfSuccess],
    [actions.deleteUnitPdfFailure, handlers.deleteUnitPdfFailure],

    [actions.deleteUnitPictureRequest, handlers.deleteUnitPictureRequest],
    [actions.deleteUnitPictureSuccess, handlers.deleteUnitPictureSuccess],
    [actions.deleteUnitPictureFailure, handlers.deleteUnitPictureFailure],

    [actions.fetchUnitRequest, handlers.fetchUnitRequest],
    [actions.fetchUnitSuccess, handlers.fetchUnitSuccess],
    [actions.fetchUnitFailure, handlers.fetchUnitFailure],

    [actions.editUnitRequest, handlers.editUnitRequest],
    [actions.editUnitSuccess, handlers.editUnitSuccess],
    [actions.editUnitFailure, handlers.editUnitFailure],

    [actions.uploadUnitPdfRequest, handlers.uploadUnitPdfRequest],
    [actions.uploadUnitPdfSuccess, handlers.uploadUnitPdfSuccess],
    [actions.uploadUnitPdfFailure, handlers.uploadUnitPdfFailure],

    [actions.uploadUnitPictureRequest, handlers.uploadUnitPictureRequest],
    [actions.uploadUnitPictureSuccess, handlers.uploadUnitPictureSuccess],
    [actions.uploadUnitPictureFailure, handlers.uploadUnitPictureFailure],

    [actions.addUnitItemRequest, handlers.addUnitItemRequest],
    [actions.addUnitItemSuccess, handlers.addUnitItemSuccess],
    [actions.addUnitItemFailure, handlers.addUnitItemFailure],

    [actions.deleteUnitItemRequest, handlers.deleteUnitItemRequest],
    [actions.deleteUnitItemSuccess, handlers.deleteUnitItemSuccess],
    [actions.deleteUnitItemFailure, handlers.deleteUnitItemFailure],

    [actions.deleteUnitItemPdfRequest, handlers.deleteUnitItemPdfRequest],
    [actions.deleteUnitItemPdfSuccess, handlers.deleteUnitItemPdfSuccess],
    [actions.deleteUnitItemPdfFailure, handlers.deleteUnitItemPdfFailure],

    [actions.deleteUnitItemPictureRequest, handlers.deleteUnitItemPictureRequest],
    [actions.deleteUnitItemPictureSuccess, handlers.deleteUnitItemPictureSuccess],
    [actions.deleteUnitItemPictureFailure, handlers.deleteUnitItemPictureFailure],

    [actions.duplicateUnitItemRequest, handlers.duplicateUnitItemRequest],
    [actions.duplicateUnitItemFailure, handlers.duplicateUnitItemFailure],

    [actions.editUnitItemRequest, handlers.editUnitItemRequest],
    [actions.editUnitItemSuccess, handlers.editUnitItemSuccess],
    [actions.editUnitItemFailure, handlers.editUnitItemFailure],

    [actions.uploadUnitItemPdfRequest, handlers.uploadUnitItemPdfRequest],
    [actions.uploadUnitItemPdfSuccess, handlers.uploadUnitItemPdfSuccess],
    [actions.uploadUnitItemPdfFailure, handlers.uploadUnitItemPdfFailure],

    [actions.uploadUnitItemPictureRequest, handlers.uploadUnitItemPictureRequest],
    [actions.uploadUnitItemPictureSuccess, handlers.uploadUnitItemPictureSuccess],
    [actions.uploadUnitItemPictureFailure, handlers.uploadUnitItemPictureFailure],

    [actions.addUnitTaskRequest, handlers.addUnitTaskRequest],
    [actions.addUnitTaskSuccess, handlers.addUnitTaskSuccess],
    [actions.addUnitTaskFailure, handlers.addUnitTaskFailure],

    [actions.deleteUnitTaskRequest, handlers.deleteUnitTaskRequest],
    [actions.deleteUnitTaskSuccess, handlers.deleteUnitTaskSuccess],
    [actions.deleteUnitTaskFailure, handlers.deleteUnitTaskFailure],

    [actions.editUnitTaskRequest, handlers.editUnitTaskRequest],
    [actions.editUnitTaskSuccess, handlers.editUnitTaskSuccess],
    [actions.editUnitTaskFailure, handlers.editUnitTaskFailure],

    [actions.unitDataFetchRequest, handlers.unitDataFetchRequest],
    [actions.unitDataFetchSuccess, handlers.unitDataFetchSuccess],
    [actions.unitDataFetchFailure, handlers.unitDataFetchFailure],

    [actions.itemPdfUploadedFlagUnset, handlers.itemPdfUploadedFlagUnset],
    [actions.itemPictureUploadedFlagUnset, handlers.itemPictureUploadedFlagUnset],
    [actions.unitPdfUploadedFlagUnset, handlers.unitPdfUploadedFlagUnset],
    [actions.unitPictureUploadedFlagUnset, handlers.unitPictureUploadedFlagUnset],

    [actions.clearItemAdded, handlers.clearItemAdded],
    [actions.clearAddedTask, handlers.clearAddedTask],

    [actions.fetchUnitItemsRequest, handlers.fetchUnitItemsRequest],
    [actions.fetchUnitItemsSuccess, handlers.fetchUnitItemsSuccess],
    [actions.fetchUnitItemsFailure, handlers.fetchUnitItemsFailure],

    [actions.getDefaultBarcodeRequest, handlers.getDefaultBarcodeRequest],
    [actions.getDefaultBarcodeSuccess, handlers.getDefaultBarcodeSuccess],
    [actions.getDefaultBarcodeFailure, handlers.getDefaultBarcodeFailure],

    [actions.getUnitItemsTasksRequest, handlers.getUnitItemsTasksRequest],
    [actions.getUnitItemsTasksSuccess, handlers.getUnitItemsTasksSuccess],
    [actions.getUnitItemsTasksFailure, handlers.getUnitItemsTasksFailure],

    [actions.updateTasksListRequest, handlers.updateTasksListRequest],
    [actions.updateTasksListSuccess, handlers.updateTasksListSuccess],
    [actions.updateTasksListFailure, handlers.updateTasksListFailure],
    [actions.getUnitPicturesRequest, handlers.getUnitPicturesRequest],
    [actions.getUnitPdfRequest, handlers.getUnitPdfRequest],
  ]),
  initialState,
);

export default reducer;
