import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import IconMenu from 'material-ui/IconMenu';

import { ButtonIcon } from 'components/Layout/Buttons';
import { HideOnPhones, ShowOnPhones } from 'components/Layout/Media';

export const ActionsContainer = styled(Flex)`
  ${HideOnPhones}
`;

export const CardContent = styled(CardText)`
  display: flex;
`;

export const Container = styled(Card)`
  border-radius: 0 !important;
  border-bottom: 1px solid ${({ theme }) => theme.primaryWhite} !important;

  :last-of-type {
    border-bottom: none !important;
  }
`;

export const Header = styled(CardHeader)`
  background: ${({ theme }) => theme.primaryExchange} !important;
  font-weight: 500 !important;
  padding: 10px !important;
  position: sticky !important;
  top: 0;
  z-index: 2;

  svg {
    color: ${({ theme }) => theme.primaryWhite} !important;
  }

  & > div {
    align-items: center !important;
    display: flex !important;
    justify-content: space-between !important;
    padding-right: 34px !important;

    span {
      color: ${({ theme }) => theme.primaryWhite} !important;
    }

    span:first-child {
      min-width: 0;
      word-break: break-word;
    }
  }
`;

export const IconButton = styled(ButtonIcon).attrs({
  iconStyle: ({ theme }) => ({
    color: theme.primaryWhite,
  }),
  tooltipStyles: {
    top: '24px',
    whiteSpace: 'nowrap',
  },
})``;

export const MenuActions = styled(IconMenu).attrs({
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
  clickCloseDelay: 10,
  targetOrigin: { horizontal: 'right', vertical: 'top' },
  useLayerForClickAway: true,
})`
  ${ShowOnPhones}
`;
