import styled, { css } from 'styled-components';
import { fixedTableHeadStyles } from 'components/TableList/styled';

const partStyles = css`
  & td:nth-child(-n + 3),
  th:nth-child(-n + 3) {
    border-right: 1px solid ${({ theme }) => theme.primaryGrey};
  }

  & td:nth-child(2),
  td:nth-child(3) {
    background-color: ${({ theme }) => theme.fieldsBorderColor};
  }
`;

const groupStyles = css`
  & td:nth-child(-n + 2),
  th:nth-child(-n + 2) {
    border-right: 1px solid ${({ theme }) => theme.primaryGrey};
  }

  & td:nth-child(2) {
    background-color: ${({ theme }) => theme.fieldsBorderColor};
  }

  & td:nth-child(3) {
    width: 60%;
  }
`;

export const TableContainer = styled.div`
  ${fixedTableHeadStyles}

  & td:first-child {
    width: 50px;
  }

  & th:not(:first-child) {
    padding-left: 10px;
  }

  & th:first-child {
    font-weight: 300;
  }

  ${({ mode }) => (mode === 'parts' ? partStyles : groupStyles)};

  & td:first-child,
  th:first-child {
    text-align: center;
  }

  & tbody button[data-icon]:disabled {
    cursor: not-allowed !important;

    & svg {
      fill: ${({ theme }) => theme.primaryGrey} !important;
      cursor: not-allowed !important;
    }
  }

  & tbody button[data-icon='0'] {
    & svg {
      fill: ${({ theme }) => theme.modalsContentColor} !important;
    }
  }
`;
