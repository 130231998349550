import styled from 'styled-components';

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
  margin: 10px 0;
`;

export const FormContainer = styled.div`
  margin: 0 20px 20px;
  text-align: start;
`;

export const DateBlock = styled.div`
  display: flex;
  & > div {
    width: 50% !important;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 15px 0;
  justify-content: center;
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 10px 15px 0;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const SmallText = styled.div`
  font-size: 12px;
  margin: 15px 30px -20px;
  font-weight: bold;
  color: ${({ theme }) => theme.textGray};
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;

  form {
    & > div:nth-child(2) {
      justify-content: center;
    }

    & > div:last-child {
      padding: 20px 0 !important;
    }
  }
`;

export const radioStyle = {
  width: '100%',
  display: 'flex',
};
