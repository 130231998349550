import styled from 'styled-components';
import MuiPopover from 'material-ui/Popover';

export const Popover = styled(MuiPopover)`
  border-radius: 10px !important;
`;

export const Container = styled.div`
  margin: 16px;
`;

export const Title = styled.div`
  padding-bottom: 20px;
`;
