import styled from 'styled-components';

export const TitleText = styled.h3`
  color: ${({ theme }) => theme.modalsContentColor};
  display: flex;
  flex: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
  min-width: 0;
  max-width: 450px;
  padding: 15px 0px 0px 10px;
  word-break: break-word;
  word-wrap: break-word;
  font-weight: bold;
`;
