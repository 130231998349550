import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { actions as partsActions } from 'redux/parts';
import moment from 'moment';
import { EditIcon, CloseIcon, LaunchIcon } from 'components/Layout/Icons';
import { fieldLabels, attributesFields, myPartFields, morePartFields, financialAttributesFields } from '../constants';
import { createUniqKey, getDateValue } from '../helpers';
import { Field } from 'react-final-form';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import DatePicker from 'material-ui/DatePicker';
import { SelectItem } from 'components/Layout/Selects';

import * as S from './styled';
import theme from 'theme';

const Card = ({ fieldsType, field, openEditModal, form, title, type, buttons, prefix }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const options = useSelector(state => state.partOptions.options);
  const oems = useSelector(state => state.parts.oemList);
  const suppliers = useSelector(state => state.parts.suppliersList);
  const currentPart = useSelector(state => state.parts.currentPart);
  const dispatch = useDispatch();

  const getActualFields = () => {
    if (fieldsType === 'part') return myPartFields;
    if (fieldsType === 'morePart') return morePartFields;
    if (fieldsType === 'financial') return financialAttributesFields;
    return attributesFields;
  };

  const actualFields = getActualFields();
  const data = { oems, suppliers };

  const onEditClickHandler = () => {
    // eslint-disable-next-line no-shadow
    const actualFields = getActualFields();

    if (isEditable) {
      const cardFieldNames = actualFields.map(({ name }) => `${prefix}${name}`);
      cardFieldNames.map(fieldName => {
        form.change(fieldName, currentPart[fieldName]);
        return fieldName;
      });
    }
    setIsEditable(prevState => !prevState);
  };

  const copyToClipboard = text => {
    const temp = document.createElement('input');
    temp.value = text;
    document.body.appendChild(temp);
    temp.select();

    document.execCommand('copy');
    document.body.removeChild(temp);
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 3000);
  };

  const copyValues = (from, to) => {
    const { values } = form.getState();

    // eslint-disable-next-line no-shadow
    const typeValues = getActualFields().map(field => field.name);

    const sourceValues = Object.entries(values).filter(
      ([key]) => key.startsWith(from) && typeValues.includes(key.replace(from, '')),
    );

    sourceValues.forEach(([key, value]) => {
      form.change(`${to}${key.replace(from, '')}`, value);
    });
  };

  const getCardName = () => {
    const id = form.getState().values[field];

    if (id) {
      if (data[`${type}s`]?.length) {
        return data[`${type}s`].find(item => item.Id === id)?.Name || '';
      }
    }

    return '';
  };

  const submitCardValues = () => {
    const { values, valid } = form.getState();

    // eslint-disable-next-line no-shadow
    const actualFields = getActualFields();
    const cardValues = {};
    actualFields.map(({ name }) => {
      cardValues[`${prefix}${name}`] = values[`${prefix}${name}`];
      return name;
    });

    if (!valid) return false;
    dispatch(partsActions.editPartRequest({ ...currentPart, ...cardValues }));
    return setIsEditable(false);
  };

  return (
    <S.Card>
      <S.CardHeader colored={type === 'my'} editable={isEditable}>
        <S.CardHeaderInner>
          {type === 'my' ? (
            <S.CardHeaderTitle>{title}</S.CardHeaderTitle>
          ) : (
            <>
              {fieldsType !== 'part' ? (
                <>
                  <S.CardHeaderTitle>{title}</S.CardHeaderTitle>
                  <S.CardHeaderDesc>Name: {getCardName()}</S.CardHeaderDesc>
                </>
              ) : (
                <>
                  {!isEditable ? (
                    <>
                      <S.CardHeaderTitle>{title}</S.CardHeaderTitle>
                      <S.CardHeaderDesc>Name: {getCardName()}</S.CardHeaderDesc>
                    </>
                  ) : (
                    <S.EditableHeaderContainer>
                      <Field
                        name={field}
                        component={SelectFieldAdapter}
                        floatingLabelText={type === 'oem' ? 'OEM Name:' : 'Supplier Name:'}
                        underlineStyle={{ display: 'none' }}
                      >
                        {data[`${type}s`].length > 0
                          ? data[`${type}s`].map(({ Id, Name }) => (
                              <SelectItem key={Id} primaryText={Name} value={Id} />
                            ))
                          : []}
                      </Field>
                      <S.HeaderEditBtn onClick={() => openEditModal(`${type}s`)}>Edit</S.HeaderEditBtn>
                    </S.EditableHeaderContainer>
                  )}
                </>
              )}
            </>
          )}
        </S.CardHeaderInner>
        <S.EditBtn tooltip={isEditable ? 'Close' : 'Edit'} onClick={onEditClickHandler}>
          {isEditable ? (
            <CloseIcon color={type === 'my' ? theme.primaryWhite : theme.darkGrey} />
          ) : (
            <EditIcon color={theme.primaryWhite} />
          )}
        </S.EditBtn>
      </S.CardHeader>
      <S.CardBody>
        {actualFields.map(({ name, fieldType, list, isNumber }) => (
          <>
            {fieldType === 'text' ? (
              <Field
                key={`${prefix}${name}`}
                name={`${prefix}${name}`}
                component={TextFieldAdapter}
                type={isNumber ? 'number' : 'text'}
                floatingLabelText={fieldLabels[`${prefix}${name}`].label}
                floatingLabelFixed
                placeholder="&mdash;"
                disabled={!isEditable}
                underlineStyle={!isEditable ? { display: 'none' } : {}}
                style={S.createTextFieldStyles({ type, theme, isEditable })}
                errorStyle={S.createErrorStyles()}
                floatingLabelShrinkStyle={S.createLabelStyles({ theme })}
                inputStyle={S.createInputStyles({ theme, type, isEditable })}
              />
            ) : null}
            {fieldType === 'select' ? (
              <Field
                key={`${prefix}${name}`}
                component={SelectFieldAdapter}
                name={`${prefix}${name}`}
                floatingLabelText={fieldLabels[`${prefix}${name}`].label}
                floatingLabelShrinkStyle={S.createLabelStyles({ theme })}
                floatingLabelFixed
                hintText="&mdash;"
                disabled={!isEditable}
                underlineStyle={!isEditable ? { display: 'none' } : {}}
                style={S.createSelectFieldStyles()}
                menuStyle={S.createSelectMenuStyles()}
                hintStyle={S.createSelectHintStyles({ theme })}
                dropDownMenuProps={S.createDropDownStyles({ type, isEditable, theme })}
              >
                {options[list].length > 0
                  ? options[list].map(({ Id, Name }) => <SelectItem key={Id} primaryText={Name} value={Id} />)
                  : []}
              </Field>
            ) : null}
            {fieldType === 'date' ? (
              <Field
                key={`${prefix}${name}`}
                name={`${prefix}${name}`}
                floatingLabelText={fieldLabels[`${prefix}${name}`].label}
                floatingLabelFixed
                placeholder="&mdash;"
                disabled={!isEditable}
                underlineStyle={!isEditable ? { display: 'none' } : {}}
              >
                {props => (
                  <DatePicker
                    {...props}
                    formatDate={date => moment(date).format('MMM-DD-YYYY')}
                    onChange={(e, value) => props.input.onChange(moment.parseZone(value).utc(true).format())}
                    value={getDateValue(props.input.value)}
                    autoOk
                    textFieldStyle={S.createTextFieldStyles({ theme, type })}
                    floatingLabelShrinkStyle={S.createLabelStyles({ theme })}
                    inputStyle={S.createInputStyles({ theme, type })}
                    errorText={props.meta.visited || props.meta.touched ? props.meta.error : ''}
                  />
                )}
              </Field>
            ) : null}
            {fieldType === 'url' ? (
              <Field
                key={`${prefix}${name}`}
                name={`${prefix}${name}`}
                component={TextFieldAdapter}
                floatingLabelText={fieldLabels[`${prefix}${name}`].label}
                floatingLabelFixed
                placeholder="&mdash;"
                disabled={!isEditable}
                underlineStyle={!isEditable ? { display: 'none' } : {}}
                style={S.createTextFieldStyles({ type, theme })}
                floatingLabelShrinkStyle={S.createLabelStyles({ theme })}
                inputStyle={S.createInputStyles({ theme, type })}
                errorStyle={S.createErrorStyles()}
              >
                {props => (
                  <>
                    <TextFieldAdapter {...props} />
                    <S.CopyLinkBlock>
                      <S.GetUrlBtn
                        tooltip={props.input.value ? 'Copy URL' : ''}
                        tooltipPosition="top-center"
                        onClick={() => copyToClipboard(props.input.value)}
                        disabled={!props.input.value}
                      >
                        <LaunchIcon />
                      </S.GetUrlBtn>
                      {isCopied ? <span>URL was copied to clipboard</span> : null}
                    </S.CopyLinkBlock>
                  </>
                )}
              </Field>
            ) : null}
          </>
        ))}
        {isEditable ? (
          <S.ButtonGroup>
            {buttons.map(({ buttonTitle, copyValue }) => (
              <S.Button
                label={buttonTitle}
                key={createUniqKey(`${prefix}`)}
                onClick={() => copyValues(copyValue, prefix)}
              />
            ))}
            <S.SaveButton
              label="Save"
              primary
              labelPosition="after"
              fullWidth
              icon={<S.DownloadIcon />}
              onClick={submitCardValues}
              disabled={!form.getState().valid}
            />
          </S.ButtonGroup>
        ) : null}
      </S.CardBody>
    </S.Card>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['my', 'oem', 'supplier']).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonTitle: PropTypes.string,
      copyValue: PropTypes.string,
    }),
  ).isRequired,
  prefix: PropTypes.string.isRequired,
  fieldsType: PropTypes.oneOf(['part', 'morePart', 'attributes', 'financial']).isRequired,
  field: PropTypes.string,
  form: PropTypes.shape({
    getState: PropTypes.func,
    change: PropTypes.func,
  }).isRequired,
  openEditModal: PropTypes.func,
  editPart: PropTypes.func.isRequired,
  currentPart: PropTypes.shape({}).isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

Card.defaultProps = {
  colored: false,
  desc: '',
  fields: [],
  field: '',
  openEditModal: () => {},
};

export default Card;
