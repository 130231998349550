import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';

import theme from 'theme';

import * as S from './styled';

const SuccessDialog = ({ open, onClose }) => (
  <BaseDialog
    open={open}
    title="Thank You!"
    onRequestClose={false}
    withCloseButton={false}
    titleColor={theme.mainGreen}
    modalStyles={{ maxWidth: '600px' }}
  >
    <S.DialogContainer>
      <S.DialogText>Thanks for activating your account!</S.DialogText>
      <S.DialogText>
        Your can use your login for the mobile <S.BoldText>TaskApp</S.BoldText> or you can login here if you are a
        manager or administrator.{' '}
      </S.DialogText>
      <S.Button onClick={() => onClose()}>OK</S.Button>
    </S.DialogContainer>
  </BaseDialog>
);

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessDialog;
