import addAssignedSitesToPerson from './helper';

const initialState = {
  createdEditedPersonId: '',
  emailContent: {
    closingText: '',
    introText: '',
    salutation: '',
    sendDefaultMessages: true,
    sendLinks: true,
    sendLocations: true,
  },
  error: '',
  list: [],
  loading: false,
  availabilityOptions: [],
  jobsOptions: [],
  canDelete: {},
  isAvailabilityOptionsLoading: false,
};

export const addPersonRequest = state => ({
  ...state,
  createdEditedPersonId: '',
  error: '',
  loading: true,
});

export const addPersonSuccess = (state, { payload }) => ({
  ...state,
  createdEditedPersonId: payload.Id,
});

export const addPersonFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deletePersonRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deletePersonFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const editPersonRequest = state => ({
  ...state,
  createdEditedPersonId: '',
  error: '',
  loading: true,
});

export const editPersonSuccess = (state, { payload }) => ({
  ...state,
  createdEditedPersonId: payload.Id,
});

export const editPersonFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const peopleListRequest = state => ({
  ...state,
  error: '',
  loading: false,
});

export const peopleListSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
  loading: false,
});

export const peopleListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const multipleEditPersonRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const multipleEditPersonSuccess = state => ({
  ...state,
  loading: false,
});

export const multipleEditPersonFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const sendEmailContentRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const sendEmailContentSuccess = state => ({
  ...state,
  loading: false,
});

export const sendEmailContentFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const getAvailabilityOptionsRequest = state => ({
  ...state,
  error: '',
  loading: true,
  isAvailabilityOptionsLoading: true,
});

export const getAvailabilityOptionsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  isAvailabilityOptionsLoading: false,
  availabilityOptions: payload,
});

export const getAvailabilityOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
  isAvailabilityOptionsLoading: false,
});

export const getJobsOptionsRequest = state => ({
  ...state,
  error: '',
  loading: true,
  isJobsOptionsLoading: true,
});

export const getJobsOptionsSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  isJobsOptionsLoading: false,
  jobsOptions: payload,
});

export const getJobsOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
  isJobsOptionsLoading: false,
});

export const getCanDeleteOptionRequest = state => ({
  ...state,
  error: '',
  loading: true,
  isCanDeleteOptionLoading: true,
});

export const getCanDeleteOptionSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  isCanDeleteOptionLoading: false,
  canDeleteOption: payload,
});

export const getCanDeleteOptionFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
  isCanDeleteOptionLoading: false,
});

export const assignedSitesRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const assignedSitesSuccess = (state, { payload }) => addAssignedSitesToPerson(state, payload);

export const assignedSitesFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const updateAssignedSitesRequest = state => ({ ...state, error: '' });

export const unassignedManagerRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const unassignedManagerFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export default initialState;
