import styled from 'styled-components';

const Container = styled.div`
  &:not(:last-child) {
    padding: 10px 0;
  }
`;

const Label = styled.div`
  background: ${({ theme }) => theme.primaryTasks};
  color: ${({ theme }) => theme.primaryWhite};
  text-align: start;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-left: 10px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.span`
  flex: 1;
  padding: 0 4px;
  font-weight: 600;
  font-size: 0.8rem;
`;

const Section = styled.div`
  background: ${({ theme }) => theme.primaryWhite};
  padding: 8px;
  text-align: start;
  -webkit-box-shadow: -1px 7px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px 7px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 7px 8px 0px rgba(0, 0, 0, 0.2);
`;

export { Container, Label, Name, Section };
