import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { Modal } from 'components/Modal';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import { actions as scheduleActions } from 'redux/schedule';
import { ScheduleInfo } from 'configs/propTypes';
import { generateDate } from '../../../SchedulesList/AddChecklistModal/helpers';

import Content from './Content';
import OverviewDialog from './OverviewDialog';

import theme from 'theme';

import { returnAssignmentType, getFilteredItemsWithTasks } from './helpers';
import * as S from './styled';

const AddMissingTasksModal = ({ unitId, scheduleId, open, handleClose, people, scheduleInfo }) => {
  const [tasksToAdd, setTasksToAdd] = useState([]);
  const [tasksToSend, setTasksToSend] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isConfirmationOverrideOpened, setIsConfirmationOverrideOpened] = useState(false);
  const [overrideTime, setOverrideTime] = useState(false);

  const missingTasks = useSelector(state => state.schedule.missingTasks);
  const unitTasks = useSelector(state => state.unit.unitWithItemsTasksList);
  const details = useSelector(state => state.schedule.details);
  const currentChecklistStatus = useSelector(state => state.schedule.currentChecklistStatus);

  const dispatch = useDispatch();

  const onAddTask = (index, itemId, itemIndex) => {
    const array = tasksToAdd.slice();
    const tmpArr = tasksToSend.slice();
    tmpArr.push(tasksToAdd[itemIndex][index]);
    setTasksToSend(tmpArr);
    array[itemIndex].splice(index, 1);
    setTasksToAdd(array);
  };

  const onAddItem = index => {
    const array = tasksToAdd.slice();
    const tmpArr = tasksToSend.slice();
    const arrToConcat = tmpArr.concat(array[index]);
    setTasksToSend(arrToConcat);
    array.splice(index, 1);
    setTasksToAdd(array);
  };

  const onSubmit = () => {
    const clone = cloneDeep(scheduleInfo);

    const taskTemplates = tasksToSend.map(item => ({ id: item.Id }));

    const payload = {
      dataToSend: {
        ...clone,
        ScheduleDto: {
          ...clone.ScheduleDto,
          ScheduleData: {
            ...clone.ScheduleDto.ScheduleData,
            DailyRepeatUntil: generateDate(clone.ScheduleDto.ScheduleData.DailyRepeatUntil),
            DailyStartDate: generateDate(clone.ScheduleDto.ScheduleData.DailyStartDate),
          },
        },
      },
      ignoreLeadTime: overrideTime,
      unitId,
      currentChecklist: {
        PersonName: details?.AssigneeID ? details.AssigneeID : null,
        AssignmentType: details?.AssigneeID ? 2 : 0,
      },
      taskTemplates,
      isAssignmentChanged: true,
      onSuccess: () => {
        handleOverviewClose();
        onClose();
      },
    };

    dispatch(scheduleActions.editScheduleRequest(payload));
  };

  const openConfirmationDialog = () => setIsConfirmationOverrideOpened(true);
  const closeConfirmationDialog = () => setIsConfirmationOverrideOpened(false);

  const openOverviewDialog = () => setIsConfirmOpen(true);
  const closeOverviewDialog = () => setIsConfirmOpen(false);

  const confirmConfirmationDialog = isConfirm => {
    setIsConfirmationOverrideOpened(false);
    setIsConfirmOpen(true);
    setOverrideTime(isConfirm);
  };

  const handleOverviewClose = () => {
    dispatch(scheduleActions.missingTasksListRequest({ checklistId: scheduleId, unitId }));

    dispatch(scheduleActions.fetchScheduleTemplatesRequest({ scheduleId }));
    closeOverviewDialog();
  };

  const resetState = () => {
    setTasksToAdd(getFilteredItemsWithTasks(unitTasks, missingTasks));
    setTasksToSend([]);
  };

  const onClose = () => {
    resetState();
    handleClose();
  };

  useEffect(() => {
    dispatch(scheduleActions.fetchCurrentUnitRequest({ unitId }));
    dispatch(scheduleActions.fetchScheduleUnitItemsRequest({ unitId }));
    dispatch(scheduleActions.checklistStatusRequest({ checklistId: scheduleId }));
  }, [dispatch, scheduleId, unitId]);

  return (
    <Modal isOpen={open} onRequestClose={onClose} title="Add Items and Tasks" titleColor={theme.primaryBlue}>
      <Content
        scheduleInfo={scheduleInfo}
        people={people}
        openConfirmationDialog={openConfirmationDialog}
        openOverviewDialog={openOverviewDialog}
        onAddTask={onAddTask}
        onAddItem={onAddItem}
        resetState={resetState}
        onClose={onClose}
        tasksToAdd={tasksToAdd}
        tasksToSend={tasksToSend}
      />

      <ConfirmationDialog
        label="YES - OVERRIDE"
        labelCancel="NO - start next period"
        width={'40%'}
        maxWidth={'400px'}
        isCancelNext
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationText>Start date is sooner than lead time for creating tasks!</S.ConfirmationText>
            <S.ConfirmationText>
              <b>Override the lead time?</b>
            </S.ConfirmationText>
          </S.ConfirmationContainer>
        }
        open={isConfirmationOverrideOpened}
        onClick={() => confirmConfirmationDialog(true)}
        handleClose={closeConfirmationDialog}
        onCancelClick={() => confirmConfirmationDialog(false)}
      />
      <OverviewDialog
        open={isConfirmOpen}
        handleClose={handleOverviewClose}
        details={details}
        currentChecklistStatus={currentChecklistStatus}
        newTasksCount={tasksToSend.length}
        people={people}
        onSubmit={onSubmit}
        returnAssignmentType={returnAssignmentType}
      />
    </Modal>
  );
};

AddMissingTasksModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  scheduleInfo: ScheduleInfo.isRequired,
  unitId: PropTypes.string.isRequired,
  scheduleId: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default AddMissingTasksModal;
