import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { BorderRadiusContainer, InputWrapper } from 'components/Layout/Containers';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 90px;
`;

export const Sensor = styled(BorderRadiusContainer)``;

export const Label = styled(Flex).attrs({
  p: 2,
  justify: 'space-between',
  align: 'center',
})`
  background: ${({ theme }) => theme.primaryLists};
  color: ${({ theme }) => theme.primaryWhite};
`;

export const InputContainer = styled(InputWrapper)``;

export const Type = styled.div`
  margin-top: 10px;
  text-transform: capitalize;
`;

export const SelectField = styled(SelectFieldAdapter).attrs({
  selectedMenuItemStyle: ({ theme }) => ({
    color: theme.primary,
  }),
  maxHeight: window.innerHeight / 3,
})``;
