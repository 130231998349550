/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import theme from 'theme';

import { Dashboard } from 'components/Dashboard';
import { actions as sitesActions } from 'redux/sites';
import { actions as inventoryActions } from 'redux/inventory';
import { PARTS_MODULES } from 'configs/routes';

import * as S from './styled';

const statisticsLabels = {
  PartsInUse: 'parts in use',
  PartsInInventory: 'parts inventory',
  PartsRequested: 'parts requested',
  PartsBackordered: 'parts backorder',
  UsersRequesting: 'users requesting',
  InventoryReleaseOrders: 'release orders',
};

const getSiteStatistics = (statistics, statisticsLabels) =>
  statistics ? Object.entries(statistics).map(([key, value]) => ({ value, label: statisticsLabels[key] })) : [];

const PartsDashboard = () => {
  const dashboardInfo = useSelector(state => state.inventory.dashboardInfo);
  const siteSettingsList = useSelector(state => state.sites.siteSettingsList);
  const selectedSite = useSelector(state => state.auth.selectedSite);

  const taskStatistics = getSiteStatistics(dashboardInfo, statisticsLabels);
  const dispatch = useDispatch();
  const history = useHistory();

  const checkCircleColor = label => {
    if (label === 'parts requested') {
      return '#f67110';
    }

    if (label === 'parts backorder') {
      return '#f54141';
    }

    return theme.primaryLists;
  };

  useEffect(() => {
    dispatch(inventoryActions.getInventoryDashboardInfoRequest());
    dispatch(sitesActions.getSiteSettingsListRequest());
  }, [dispatch]);

  useEffect(() => {
    const currentSiteSettings = siteSettingsList.find(site => site.Site.Id === selectedSite) || {};
    const shouldWeSkipTheDashboard = !isEmpty(currentSiteSettings) && !currentSiteSettings.IsInventoryActivated;

    if (shouldWeSkipTheDashboard) {
      history.push('/siteparts/list');
    }
  }, [history, selectedSite, siteSettingsList]);

  return (
    <Dashboard
      topContent={
        <S.StatisticsModules>
          {taskStatistics?.map(({ value, label }) => (
            <S.StatisticsModuleContainer key={label}>
              <S.StatisticsValue color={checkCircleColor(label)}>{value}</S.StatisticsValue>
              <S.StatisticsLabel>{label}</S.StatisticsLabel>
            </S.StatisticsModuleContainer>
          ))}
        </S.StatisticsModules>
      }
      modules={PARTS_MODULES}
    />
  );
};

export default PartsDashboard;
