import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Container = styled.div`
  width: 100%;
  min-height: 292px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  margin-bottom: 5px;
`;

export const DataInfo = styled(Flex).attrs({
  justify: 'space-between',
  w: 1,
  p: [1, 1, 0, 1],
})``;

export const HighLow = styled(Flex).attrs({
  column: true,
  align: 'flex-end',
})``;

export const Text = styled(Flex)`
  font-size: 12px;
`;

export const Value = styled.div`
  color: ${({ theme, val }) => (val < 0 ? theme.primaryScheduler : theme.darkGrey)};
  margin-left: 5px;
`;
