import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import { getModuleThemeForGlobalSettings } from 'helpers';
import ErrorRoutePage from 'components/ErrorRoutePage';

import GlobalSettingsList from './pages/GlobalSettingsList';
import Gateways from './pages/Gateways';
import Tasks from './pages/Tasks';
import ResourceGroups from './pages/ResourceGroups';
import IoTHubs from './pages/IoTHubs';
import TenantHubs from './pages/TenantHubs';
import SiteHubs from './pages/SiteHubs';
import VersionLegalsList from './pages/VersionLegals';
import TiersList from './pages/Tiers';
import MeteringGroups from './pages/Metering';
import Industries from './pages/Industries';
import AssetCategories from './pages/AssetCategories';
import Pricing from './pages/Pricing';

class Settings extends Component {
  static propTypes = {
    globalSettingsModule: PropTypes.string.isRequired,
  };

  getTheme = () => {
    const { globalSettingsModule } = this.props;
    return getMuiTheme(getModuleThemeForGlobalSettings(globalSettingsModule));
  };

  render() {
    return (
      <MuiThemeProvider muiTheme={this.getTheme()}>
        <Switch>
          <Route exact path="/globalsettings/tenant/:tenantId/site/:siteId/hubs" component={SiteHubs} />
          <Route exact path="/globalsettings/tenant/:tenantId/hubs" component={TenantHubs} />
          <Route exact path="/globalsettings/hubs" component={IoTHubs} />
          <Route exact path="/globalsettings/resourcegroups" component={ResourceGroups} />
          <Route exact path="/globalsettings/gateways" component={Gateways} />
          <Route exact path="/globalsettings/pages" component={Tasks} />
          <Route exact path="/globalsettings/versionlegals" component={VersionLegalsList} />
          <Route exact path="/globalsettings/metering/:meeteringGroupId/tiers" component={TiersList} />
          <Route exact path="/globalsettings/metering" component={MeteringGroups} />
          <Route exact path="/globalsettings/industries" component={Industries} />
          <Route exact path="/globalsettings/asset" component={AssetCategories} />
          <Route exact path="/globalsettings/pricing" component={Pricing} />
          <Route exact path="/globalsettings" component={GlobalSettingsList} />
          <Route path="/*" component={ErrorRoutePage} />
        </Switch>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  globalSettingsModule: app.globalSettingsModule,
});

export default connect(mapStateToProps)(Settings);
