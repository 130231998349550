import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';

import { ButtonIcon } from 'components/Layout/Buttons';
import { CalendarBusyIcon, CalendarIcon } from 'components/Layout/Icons';
import { findSite } from 'helpers';
import Controls from './Controls';
import { padding } from './helpers';

import theme from 'theme';
import * as S from './styled';

const Subheader = ({
  assignedSite,
  backgroundColor,
  centeredTitle,
  centeredText,
  hintText,
  isSearch,
  isSiteCentered,
  isSiteOnLeftSide,
  leftButtons,
  position,
  rightButtons,
  searchData,
  searchInList,
  selectedSite,
  sitesAvailableToUserFullInfo,
  subTitleIcon,
  title,
  top,
  unitVersion,
  zIndex,
  rightContent,
  withAllSites,
  isSort,
  sortList,
  redSubtitle,
  withBorder,
  titleStyle,
}) => (
  <S.Container
    backgroundColor={backgroundColor}
    position={position}
    showMenuIconButton={false}
    top={top}
    zindex={zIndex}
  >
    <Controls buttons={leftButtons} />
    {(isSiteCentered && (
      <S.Title padding={padding(leftButtons, rightButtons)}>
        {unitVersion && <S.ListVersionBlock>{unitVersion}</S.ListVersionBlock>}
        <S.SubTitle>{title}</S.SubTitle>
        {selectedSite && (
          <S.SubTitle>
            <S.SiteCentered>{findSite(sitesAvailableToUserFullInfo, selectedSite)}</S.SiteCentered>
          </S.SubTitle>
        )}
      </S.Title>
    )) || (
      <S.SubTitle centeredTitle={centeredTitle}>
        <S.SubTitle>
          {subTitleIcon && (
            <S.SubTitleIcon>{React.cloneElement(subTitleIcon, { style: S.SubTitleIconStyle })}</S.SubTitleIcon>
          )}
          <S.TitleWrapper style={titleStyle}>{title}</S.TitleWrapper>
          {selectedSite && isSiteOnLeftSide && (
            <S.SiteOnLeft>{assignedSite || findSite(sitesAvailableToUserFullInfo, selectedSite)}</S.SiteOnLeft>
          )}
          {redSubtitle && <S.RedSubtitle>{redSubtitle}</S.RedSubtitle>}
          {withAllSites && <S.SiteOnLeft>All Sites</S.SiteOnLeft>}
        </S.SubTitle>
        {centeredText && <S.SubTitle>{centeredText}</S.SubTitle>}
        {isSearch && (
          <S.SearchField>
            <TextField
              hintText={hintText}
              value={searchData}
              onChange={searchInList}
              underlineStyle={S.PlaceholderStyle}
              underlineFocusStyle={S.UnderlineStyle}
              hintStyle={S.PlaceholderStyle}
            />
          </S.SearchField>
        )}
        {rightContent}
      </S.SubTitle>
    )}
    {(isSort || sortList) && (
      <ButtonIcon onClick={sortList} tooltip={isSort ? 'sort' : 'unsort'} style={{ paddingRight: '100px' }}>
        {isSort ? <CalendarIcon color={theme.textGray} /> : <CalendarBusyIcon color={theme.mainRed} />}
      </ButtonIcon>
    )}
    <Controls buttons={rightButtons} withBorder={withBorder} />
  </S.Container>
);

Subheader.propTypes = {
  assignedSite: PropTypes.string,
  backgroundColor: PropTypes.string,
  centeredTitle: PropTypes.bool,
  centeredText: PropTypes.string,
  hintText: PropTypes.string,
  isSearch: PropTypes.bool,
  isSiteCentered: PropTypes.bool,
  isSiteOnLeftSide: PropTypes.bool,
  leftButtons: PropTypes.array,
  position: PropTypes.string,
  rightButtons: PropTypes.array,
  searchData: PropTypes.string,
  searchInList: PropTypes.func,
  selectedSite: PropTypes.string.isRequired,
  sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
  subTitleIcon: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  top: PropTypes.string,
  unitVersion: PropTypes.string,
  zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  withAllSites: PropTypes.bool,
  isSort: PropTypes.bool,
  sortList: PropTypes.func,
  redSubtitle: PropTypes.string,
  withBorder: PropTypes.bool,
  titleStyle: PropTypes.object,
};

Subheader.defaultProps = {
  assignedSite: '',
  backgroundColor: '',
  centeredTitle: false,
  centeredText: '',
  hintText: 'Search',
  isSearch: false,
  isSiteCentered: false,
  isSiteOnLeftSide: false,
  leftButtons: [],
  position: 'inherit',
  rightButtons: [],
  searchData: '',
  searchInList: undefined,
  subTitleIcon: null,
  title: '',
  top: '',
  unitVersion: '',
  zIndex: 2,
  rightContent: null,
  withAllSites: false,
  redSubtitle: '',
  withBorder: false,
  sortList: null,
  isSort: false,
  titleStyle: {},
};

const mapStateToProps = ({
  auth: {
    selectedSite,
    user: { sitesAvailableToUserFullInfo },
  },
}) => ({
  selectedSite,
  sitesAvailableToUserFullInfo,
});

export default connect(mapStateToProps)(Subheader);
