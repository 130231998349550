// set padding depending on buttons is Subheader
export const padding = (left, right) => {
  if (left && left.length && right && right.length) {
    // both buttons
    return 0;
  }
  if (left && left.length && !(right && right.length)) {
    // only left buttons
    return '170px';
  }
  if (!(left && left.length) && right && right.length) {
    // only right buttons
    return '30px';
  }
  // none buttons
  return '90px';
};
