import styled, { css } from 'styled-components';
import {
  GateWayIcon,
  LockIcon,
  DateRangeIcon,
  VisibilityIcon,
  OfflineBoltWhite,
  SitesModuleIcon,
  PortraitIcon,
  ListsModuleIcon,
  ElectricalServices,
} from 'components/Layout/Icons';

import theme from 'theme';

export const searchInputStyles = css`
  background-color: ${theme.lightGrey};
  height: 35px;
  padding: 6px 10px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageTitle = styled.h1`
  font-size: 36px;
  font-weight: 200;
  color: ${() => theme.textGray};
`;

export const ActivatorsContainer = styled.ul`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const SitesPrimaryIcon = styled(SitesModuleIcon).attrs({
  color: () => theme.primaryWhite,
})`
  transform: scale(1.3);
`;

export const ListsPrimaryIcon = styled(ListsModuleIcon).attrs({
  color: () => theme.primaryWhite,
})`
  transform: scale(1.3);
`;

export const SitesSecondaryIcon = styled(PortraitIcon).attrs({
  color: () => theme.primaryWhite,
})``;

export const CardPrimaryIcon = styled(GateWayIcon).attrs({
  color: () => theme.primaryWhite,
})`
  transform: scale(1.3);
`;

export const ChecklistPrimaryIcon = styled(DateRangeIcon).attrs({
  color: () => theme.primaryWhite,
})`
  transform: scale(1.3);
`;

export const CardSecondaryIcon = styled(LockIcon).attrs({
  color: () => theme.primaryWhite,
})`
  transform: scale(0.8);
`;

export const GeneralIcon = styled(VisibilityIcon).attrs({
  color: () => theme.primaryWhite,
})`
  transform: scale(0.8);
`;

export const ShortCutIcon = styled(OfflineBoltWhite).attrs({
  color: () => theme.primaryWhite,
})`
  transform: scale(1.3);
`;

export const FirstTimeSetupIcon = styled(ElectricalServices)`
  transform: scale(1.3);
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationText = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const ConfirmationTextContainer = styled.div`
  border-bottom: ${() => `1px solid ${theme.textGray}`}!important;
  padding: 4px 10px;
`;

export const ConfirmationTitle = styled.div`
  font-size: 20px;
  color: ${() => theme.primaryBlack};
  padding: 5px 0;
  border-bottom: ${() => `1px solid ${theme.textGray}`}!important;
`;

export const InfoConfirmationText = styled.div`
  color: ${() => theme.primaryBlack};
  font-size: 12px;
`;

export const InfoPreviewText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${() => theme.primaryBlack};
`;

export const InfoConfirmationContainer = styled.div`
  margin: 0 auto;
  width: 90%;
`;

export const ConfirmationWarning = styled.div`
  font-size: 18px;
`;
