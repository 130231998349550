import styled from 'styled-components';

export const LoginButton = styled.button`
  width: 130px;
  height: 50px;
  background-color: ${({ theme }) => theme.loginButtonColor};
  border: ${({ theme }) => `5px double ${theme.primaryWhite}`};
  color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  align-self: flex-end;
  cursor: pointer;
`;
