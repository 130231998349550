export const UserTypes = {
  0: 'Unknown',
  1: 'Task Specialist',
  2: 'Manager',
  4: 'System Administrator',
  8: 'System Controller',
  16: 'OEM Administrator',
  32: 'OEM Manager',
  64: 'Dashboard User',
};

export const AssignmentType = {
  0: 'PICKUP',
  1: 'TEAM',
  2: 'PERSON',
};

export const shortcutType = {
  all: -1,
  manual: 0,
  dynamic: 1,
};
