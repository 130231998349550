import React, { FC } from 'react';
import { PrimaryIconButton, SecondaryLinkButton, ListItem } from 'components/Layout/Section';
import { DialpadIcon, Vimeo } from 'components/Layout/Icons';

type PropsType = {
  openVimeoAccountSetupDialog: () => void;
};

export const Technical: FC<PropsType> = ({ openVimeoAccountSetupDialog }) => (
  <ListItem
    key="Technical"
    name="Technical"
    zIndex={3}
    content={
      <>
        <SecondaryLinkButton path="/globalsettings/versionlegals" icon={<DialpadIcon />} label="Versions" />
        <PrimaryIconButton onClick={openVimeoAccountSetupDialog} icon={<Vimeo />} label="Vimeo" />
      </>
    }
  />
);
