import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseDialog from 'components/Dialogs/Base';
import CheckboxList from 'components/CheckboxList';
import SearchField from 'components/Layout/Search';
import * as S from 'components/Dialogs/styled';

import { actions as sitesActions } from 'redux/sites';
import { actions as appActions } from 'redux/app';

import SetNotificationFrequency from './SetIoTNotificationFrequency';
import { addNotificationsToSites } from './helpers';
import { getSitesList } from './selectors';

class SetIoTNotificationsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    sitesList: PropTypes.arrayOf(
      PropTypes.shape({
        SiteId: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
        TimeZoneIanaId: PropTypes.string,
        offset: PropTypes.string,
      }),
    ).isRequired,
    selectedSite: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    multipleEdit: PropTypes.func.isRequired,
    updateCurrentModule: PropTypes.func.isRequired,
  };

  state = {
    sites: [],
    sitesForSearch: [],
    checkedSites: [],
    searchData: '',
    IoTNotificationOpen: false,
    NotificationIoTFrequency: '',
    currentSite: {},
    notificationIoTFrequencySettings: [],
  };

  componentDidMount() {
    this.props.updateCurrentModule('globalsettings');
  }

  componentDidUpdate(prevProps, prevState) {
    const { sitesList } = this.props;
    const { searchData } = this.state;

    if (sitesList && sitesList.length > 0 && prevState.sites.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sites: sitesList, sitesForSearch: sitesList });
    }
  }

  clearCheckedSites = () => this.setState({ checkedSites: [] });
  closeNotificationDialog = () => this.setState({ IoTNotificationOpen: false, NotificationIoTFrequency: '' });
  fillCheckedSites = checkedSites => this.setState({ checkedSites });
  openSetTimezoneDialog = currentSite =>
    this.setState({
      IoTNotificationOpen: true,
      currentSite,
      NotificationIoTFrequency: currentSite.NotificationIoTFrequency,
    });
  selectNotification = NotificationIoTFrequency => this.setState({ NotificationIoTFrequency });
  setSearchedSites = (sites, searchData) => this.setState({ sites, searchData });

  closeModal = () => {
    this.setState({ notificationIoTFrequencySettings: [], sites: [], sitesForSearch: [], searchData: '' });
    this.clearCheckedSites();
    this.props.handleClose();
  };

  applyToCheckedSelections = () => {
    const {
      checkedSites,
      sites,
      NotificationIoTFrequency,
      sitesForSearch,
      notificationIoTFrequencySettings: oldIds,
    } = this.state;

    const updatedCurrentSites = addNotificationsToSites(sites, checkedSites, NotificationIoTFrequency);
    const updatedSitesForSearch = addNotificationsToSites(sitesForSearch, checkedSites, NotificationIoTFrequency);
    const idsToSave = [
      ...oldIds.filter(value => !checkedSites.includes(value.SiteId)),
      ...checkedSites.map(SiteId => ({ SiteId, NotificationIoTFrequency })),
    ];

    this.setState({
      notificationIoTFrequencySettings: idsToSave,
      checkedSites: [],
      sites: updatedCurrentSites,
      sitesForSearch: updatedSitesForSearch,
    });
    this.closeNotificationDialog();
  };

  applyToCurrentSite = () => {
    const {
      currentSite,
      sites,
      NotificationIoTFrequency,
      sitesForSearch,
      notificationIoTFrequencySettings: oldIds,
    } = this.state;

    const updatedCurrentSites = addNotificationsToSites(sites, [currentSite.SiteId], NotificationIoTFrequency);
    const updatedSearchSites = addNotificationsToSites(sitesForSearch, [currentSite.SiteId], NotificationIoTFrequency);
    const notificationIoTFrequencySettings = [
      ...oldIds.filter(value => value.SiteId !== currentSite.SiteId),
      { SiteId: currentSite.SiteId, NotificationIoTFrequency },
    ];

    this.setState({
      notificationIoTFrequencySettings,
      checkedSites: [],
      sites: updatedCurrentSites,
      sitesForSearch: updatedSearchSites,
    });
    this.closeNotificationDialog();
  };

  saveNotifications = () => {
    const { notificationIoTFrequencySettings } = this.state;
    const { multipleEdit, selectedSite } = this.props;

    if (notificationIoTFrequencySettings.length === 0) return;

    multipleEdit({
      selectedSite,
      requestBody: { settings: notificationIoTFrequencySettings, setingsName: 'notificationIoTFrequencySettings' },
      requestName: 'SetNotificationIoTFrequency',
    });
    this.closeModal();
  };

  render() {
    const { open, title } = this.props;
    const {
      sites,
      checkedSites,
      searchData,
      IoTNotificationOpen,
      currentSite,
      NotificationIoTFrequency,
      sitesForSearch,
      notificationIoTFrequencySettings,
    } = this.state;

    const disableSaving = notificationIoTFrequencySettings.length === 0;

    return (
      <>
        <BaseDialog open={open} onRequestClose={this.closeModal} title={title}>
          <S.DialogContainer>
            <S.SearchContainer>
              <SearchField
                list={sitesForSearch}
                filterBy="Name"
                searchData={searchData}
                placeholder="filter Sites"
                setSearchedItems={this.setSearchedSites}
                inputStyle={S.inputStyle}
              />
            </S.SearchContainer>
            <S.ListContainer>
              <CheckboxList
                list={sites}
                checkedSites={checkedSites}
                onItemClick={this.openSetTimezoneDialog}
                clearCheckedSites={this.clearCheckedSites}
                fillCheckedSites={this.fillCheckedSites}
                displayValue="notifications"
              />
            </S.ListContainer>
            <S.SaveButtonContainer onClick={this.saveNotifications}>
              <S.SaveButton disabled={disableSaving} />
            </S.SaveButtonContainer>
          </S.DialogContainer>
        </BaseDialog>
        <SetNotificationFrequency
          open={IoTNotificationOpen}
          title="IoT Notifications Frequency"
          handleClose={this.closeNotificationDialog}
          currentSite={currentSite}
          checkedSitesNumber={checkedSites.length}
          NotificationIoTFrequency={NotificationIoTFrequency}
          applyToCheckedSelections={this.applyToCheckedSelections}
          applyToCurrentSite={this.applyToCurrentSite}
          selectNotification={this.selectNotification}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, sites }) => ({
  selectedSite: auth.selectedSite,
  sitesList: getSitesList(sites.list),
});

const mapDispatchToProps = {
  updateCurrentModule: appActions.updateCurrentModule,
  multipleEdit: sitesActions.multipleSiteEditRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetIoTNotificationsDialog);
