import { SCHEDULER_MODULES_DATA } from 'configs/routes';

const statisticsLabels = {
  CompletedTaskCount: 'completed',
  PersonalTaskCount: 'personal',
  PickupTaskCount: 'pickup',
  TeamTaskCount: 'team',
  TotalTaskCount: 'total tasks',
};

const updatedSchedulerModulesData = statistics =>
  SCHEDULER_MODULES_DATA.map(moduleData => {
    const updatedModuleData = { ...moduleData };
    switch (moduleData.name) {
      case 'Checklist Tasks':
        updatedModuleData.taskCount = statistics.ChecklistTaskCount;
        break;
      case 'ShortCut Tasks':
        updatedModuleData.taskCount = statistics.ShortcutTaskCount;
        break;
      case 'QuickTasks (one day)':
        updatedModuleData.taskCount = statistics.QuickTaskCount;
        break;
      default:
        break;
    }
    return updatedModuleData;
  });

function getDatesStartingFromToday() {
  const oneWeek = 7;
  const oneDay = 24;
  const oneHour = 60;
  const oneMinute = 60;
  const oneSecond = 1000;
  const today = new Date();
  const nextWeek = new Date(today.getTime() + oneWeek * oneDay * oneHour * oneMinute * oneSecond);

  const dateArray = Array.from(
    { length: (nextWeek.getTime() - today.getTime()) / (oneDay * oneHour * oneMinute * oneSecond) },
    (_, i) => new Date(today.getTime() + i * oneDay * oneHour * oneMinute * oneSecond),
  ).map(date => (date.getDate() === today.getDate() ? 'Today' : date.getDate().toString()));

  return dateArray;
}

const getStatisticsGraphData = dailyTotalTaskCount => {
  const dates = getDatesStartingFromToday();
  const data = dailyTotalTaskCount?.map((tasksCount, i) => ({
    tasksCount,
    date: dates[i],
  }));
  let tickFormat = [];

  if (dailyTotalTaskCount.length) {
    const highestValue = Math.max(...dailyTotalTaskCount);
    const lowestValue = Math.min(...dailyTotalTaskCount);

    const midPoint = (highestValue + lowestValue) / 2;

    tickFormat = [lowestValue, midPoint, highestValue];
  }

  return { data, dates, tickFormat };
};

export { statisticsLabels, updatedSchedulerModulesData, getStatisticsGraphData };
