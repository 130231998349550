import styled from 'styled-components';

const DialogContainer = styled.div`
  padding: 10px 20px;
  text-align: center;
  & > div:last-child {
    padding: 0 !important;
  }
`;

const Paragraph = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 16px;
  text-align: center;
  margin: 0px;
`;

const RedText = styled.div`
  color: ${({ theme }) => theme.mainRed};
  font-size: 14px;
  text-align: center;
  margin: 0px auto 20px auto;
`;

const RedTitle = styled(RedText)`
  margin: 10px auto 0 auto;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
`;

const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0;
  text-align: start;
  width: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
`;

const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.headerBlue};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

const SectionBody = styled.div`
  padding: 15px 0px 15px 20px;
  overflow-y: auto;
  max-height: 22vh;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack}
  padding: 5px;
`;

const TaskText = styled(Text)`
  min-width: 90px;
`;

const NameText = styled(Text)`
  word-break: break-word;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

export {
  DialogContainer,
  Paragraph,
  RedText,
  RedTitle,
  Section,
  SectionHeader,
  SectionBody,
  Text,
  TaskText,
  NameText,
  Row,
  BoldText,
};
