import styled from 'styled-components';

export const FormContainer = styled.div`
  padding: 30px 20px;
`;

export const FormCardContainer = styled.div`
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  margin-bottom: 40px;
`;

export const CardHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 15px 15px 0 0;
  background-color: ${({ theme }) => theme.signUpColor};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
`;

export const NotesHeader = styled(CardHeader)`
  background-color: ${({ theme }) => theme.primaryDark};
`;

export const CardContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  & > div:last-child {
    margin-top: 20px;
  }
`;

export const TenantValuesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 15px;
  }
`;

export const Label = styled.label`
  margin-left: 20px;
  color: ${({ theme }) => theme.primaryRed};
`;
