const OVERRIDE_VALUES = {
  0: 1,
  1: 7,
  2: 31,
  4: 365,
};

const returnAssignmentType = (type, assigneeId) => {
  if (type === 0) {
    return assigneeId ? 'Person' : 'Open Assignment';
  }
  return 'Team';
};

const getFilteredItemsWithTasks = (itemsWithTasks, missingTasks) => {
  const tmpArr = itemsWithTasks.slice();
  const missingArr = [];
  tmpArr.forEach(item => {
    const tmpTasks = item.TaskTemplates.slice();
    const filteredArr = tmpTasks.filter(task => missingTasks.find(missingTask => missingTask.Id === task.Id));

    if (filteredArr.length) {
      filteredArr.forEach(itemInside => {
        itemInside.ItemName = item.Name;
        itemInside.Instructions = item.Instructions;
      });
    }
    missingArr.push(filteredArr);
  });
  return missingArr;
};

export { OVERRIDE_VALUES, returnAssignmentType, getFilteredItemsWithTasks };
