import styled from 'styled-components';
import { ButtonIcon } from 'components/Layout/Buttons';

export const Container = styled.div``;

export const Navigation = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;
  min-height: 40px;
`;

export const IconButton = styled(ButtonIcon)`
  padding: 0 10px 0 0 !important;
  margin-left: -10px !important;

  & + span {
    font-weight: 500;
    font-size: 18px;
    color: ${({ theme }) => theme.primaryBlack};
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
`;

export const Block = styled.div`
  flex: 1;
`;

export const TextRow = styled.p`
  text-align: ${({ align }) => align || 'left'};
  margin: 0 0 5px;
`;

export const Text = styled.span`
  display: inline-block;
  font-size: 18px;
  padding: ${({ pl, pr, pt, pb }) => `${pt || 0}px ${pr || 0}px ${pb || 0}px ${pl || 0}px`};
`;

export const RedText = styled(Text)`
  color: ${({ theme }) => theme.partDetailsRed};
`;

export const BoldText = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const LightText = styled(Text)`
  font-weight: 300;
`;
