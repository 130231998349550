import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Container, Item, Label, Button } from './styled';

class Toggle extends Component {
  state = {
    value: this.props.config.find(item => item.key === this.props.selected).key,
  };

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (prevProps.selected !== selected) {
      this.setState({ value: selected });
    }
  }

  handleChange = value => {
    this.props.handler(value);
    this.setState({ value: value.key });
  };

  render() {
    const { config, minWidth } = this.props;

    return (
      <Container>
        {config.map((item, index) => (
          <Item isActive={this.state.value === item.key} key={index}>
            {item.total ? <Label>{item.total}</Label> : null}
            <Button
              label={item.title}
              labelPosition="after"
              icon={item.icon}
              onClick={() => this.handleChange(item)}
              data-isactive={this.state.value === item.key}
              data-isfirst={!index}
              data-islast={index === config.length - 1}
              disabled={item.disabled}
              minWidth={minWidth}
            />
          </Item>
        ))}
      </Container>
    );
  }
}

Toggle.propTypes = {
  config: PropTypes.array.isRequired,
  handler: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  minWidth: PropTypes.bool,
};

Toggle.defaultProps = {
  minWidth: false,
};

export default Toggle;
