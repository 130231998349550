import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { TeamType, ShortCutType } from 'configs/propTypes';
import { shortcutType } from 'configs/types';

import { getSearchParam } from 'helpers';

import { actions as shortcutsActions } from 'redux/shortcuts';
import { actions as settingsActions } from 'redux/settings';
import { actions as teamsActions } from 'redux/teams';

import Subheader from 'components/Subheader';
import SimpleList from 'components/SimpleList';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { BackIcon, AddIcon } from 'components/Layout/Icons';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

import Item from './Item';
import ShortcutCreating from './ShortcutCreating';
import { ConfirmationDeleteDialogContent } from './helpers';
import * as S from './styled';

const filterBy = ['Name', 'OwnerName'];

class ShortcutList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      getShortcutsRequest: PropTypes.func.isRequired,
      createShortcutRequest: PropTypes.func.isRequired,
      deleteShortcutRequest: PropTypes.func.isRequired,
      deleteShortcutSuccess: PropTypes.func.isRequired,
      getSwitchAutorunRequest: PropTypes.func.isRequired,
      getRunNextPeriodRequest: PropTypes.func.isRequired,
      getSingleShortcutRequest: PropTypes.func.isRequired,
      requestGetSettings: PropTypes.func.isRequired,
      fetchTeams: PropTypes.func.isRequired,
    }).isRequired,
    list: PropTypes.arrayOf(TeamType).isRequired,
    switchAutorun: PropTypes.shape().isRequired,
    runNextPeriod: PropTypes.shape().isRequired,
    errorAutorun: PropTypes.shape().isRequired,
    shortcut: ShortCutType.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    searchData: '',
    isSort: true,
    isAddEditShortcutDialogOpen: false,
    isConfirmationDeleteOpened: false,
    isAutorunAlertOpened: false,
    isConfirmationOverrideOpened: false,
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getShortcutsRequest({ filter: { type: shortcutType.all } });
    actions.requestGetSettings();
    actions.fetchTeams();

    if (getSearchParam('id')) {
      this.openShortcut(getSearchParam('id'));
    }
  }

  componentDidUpdate(prevProps) {
    const { switchAutorun, runNextPeriod, errorAutorun, actions } = this.props;
    if (prevProps.switchAutorun !== switchAutorun) {
      actions.getShortcutsRequest({ filter: { type: shortcutType.all } });
    }
    if (prevProps.runNextPeriod !== runNextPeriod) {
      actions.getShortcutsRequest({ filter: { type: shortcutType.all } });
    }
    if (prevProps.errorAutorun !== errorAutorun && errorAutorun.status === 400) {
      this.setState({ isAutorunAlertOpened: true });
    }
  }

  onClickStatusButton = ({ shortcutId, isStart, isNextPeriod }) => {
    if (isStart || isNextPeriod) {
      this.setState({ isConfirmationOverrideOpened: true, isStart, isNextPeriod, shortcutId });
    } else {
      this.handleStatus({ isStart, shortcutId });
    }
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationOverrideOpened: false });
  };

  confirmConfirmationDialog = isConfirm => {
    const { shortcutId, isStart, isNextPeriod } = this.state;
    this.handleStatus({ isStart, isNextPeriod, ignoreLeadTime: isConfirm, shortcutId });
    this.setState({ isConfirmationOverrideOpened: false });
  };

  handleStatus = ({ isStart, isNextPeriod, ignoreLeadTime, shortcutId }) => {
    const { actions } = this.props;
    if (isNextPeriod) {
      actions.getRunNextPeriodRequest({ shortcutId, ignoreLeadTime });
    } else {
      actions.getSwitchAutorunRequest({ shortcutId, isStart, ignoreLeadTime });
    }
  };

  filterShortcuts = team => {
    const query = this.state.searchData.toLowerCase();
    return filterBy.some(field => get(team, field).toLowerCase().includes(query));
  };

  backHome = () => {
    this.props.history.push('/home');
  };

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  sortList = () => {
    this.setState(state => ({ isSort: !state.isSort }));
  };

  sortShortcuts = () => {
    const { list } = this.props;

    const filteredShortcuts = list.filter(this.filterShortcuts);

    if (this.state.isSort) {
      return filteredShortcuts.sort((a, b) => new Date(b.LastExecutionDate) - new Date(a.LastExecutionDate));
    }

    return filteredShortcuts;
  };

  openAddEditShortcutDialog = () => {
    this.props.actions.deleteShortcutSuccess(); // clear shortcuts state
    this.setState({ isAddEditShortcutDialogOpen: true });
  };

  openShortcut = id => {
    if (id) {
      this.props.actions.getSingleShortcutRequest({
        shortcutId: id,
        onNext: () => this.setState({ isAddEditShortcutDialogOpen: true }),
      });
    }
  };

  closeAddEditShortcutDialog = withOnClose => {
    if (withOnClose === 'delete') {
      this.setState({ isConfirmationDeleteOpened: true });
    } else {
      this.setState({ isAddEditShortcutDialogOpen: false });
      this.props.history.push('/shortcuts');
      this.exitAddEditShortcutDialog();
      this.props.actions.deleteShortcutSuccess();
    }
  };

  exitAddEditShortcutDialog = () => {
    this.setState({ isAddEditShortcutDialogOpen: false });
  };

  closeConfirmationDeleteDialog = () => {
    this.setState({ isConfirmationDeleteOpened: false });
    this.props.history.push('/shortcuts');
  };

  handleDeleteShortcutApproval = () => {
    const { shortcut, actions } = this.props;

    actions.deleteShortcutRequest({ shortcutId: shortcut.Id });
    this.closeConfirmationDeleteDialog();
    this.closeAddEditShortcutDialog();
  };

  submitShortcut = values => {
    const { actions } = this.props;

    const payload = {
      ...values,
      onNext: () => {
        actions.getShortcutsRequest({ filter: { type: shortcutType.all } });
        this.closeAddEditShortcutDialog();
      },
    };

    actions.createShortcutRequest(payload);
  };

  render() {
    const {
      searchData,
      isAddEditShortcutDialogOpen,
      isSort,
      isConfirmationDeleteOpened,
      isAutorunAlertOpened,
      isConfirmationOverrideOpened,
    } = this.state;

    return (
      <>
        <Subheader
          title="Shortcut List"
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backHome,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              icon: <AddIcon />,
              handler: this.openAddEditShortcutDialog,
              hint: 'Add',
            },
          ]}
          hintText="Search"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          withAllSites
          isSort={isSort}
          sortList={this.sortList}
        />
        <SimpleList
          data={this.sortShortcuts()}
          emptyListMessage="There are no Shortcuts available"
          renderItemContent={item => (
            <Item
              item={item}
              isConfirmationOverrideOpened={isConfirmationOverrideOpened}
              onClick={this.openShortcut}
              onClickStatusButton={this.onClickStatusButton}
            />
          )}
        />
        <ShortcutCreating
          open={isAddEditShortcutDialogOpen}
          handleClose={this.closeAddEditShortcutDialog}
          handleExit={this.exitAddEditShortcutDialog}
        />
        <ConfirmationDialog
          label="Delete Shortcut"
          text={<ConfirmationDeleteDialogContent />}
          open={isConfirmationDeleteOpened}
          onClick={this.handleDeleteShortcutApproval}
          handleClose={this.closeConfirmationDeleteDialog}
        />
        <ConfirmationDialog
          label="YES - OVERRIDE"
          labelCancel="NO - start next period"
          width={'40%'}
          maxWidth={'400px'}
          isCancelNext
          zIndex={2500}
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Start date is sooner than lead time for creating tasks!</S.ConfirmationText>
              <S.ConfirmationText>
                <b>Override the lead time?</b>
              </S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={this.state.isConfirmationOverrideOpened}
          onClick={() => this.confirmConfirmationDialog(true)}
          handleClose={this.closeConfirmationDialog}
          onCancelClick={() => this.confirmConfirmationDialog(false)}
        />
        ,
        <ConfirmationDialog
          label="Alert"
          text={
            <>
              <S.ConfirmationText>
                You cannot start or stop autorun as all Checklists of the Shortcut are closed.
              </S.ConfirmationText>
              <ActionsContainer>
                <ActionButton
                  label="CANCEL"
                  onClick={() =>
                    this.setState({
                      isAutorunAlertOpened: false,
                    })
                  }
                />
              </ActionsContainer>
            </>
          }
          open={isAutorunAlertOpened}
          handleClose={() =>
            this.setState({
              isAutorunAlertOpened: false,
            })
          }
        />
      </>
    );
  }
}

const mapStateToProps = ({ shortcuts }) => ({
  list: shortcuts.shortcutsList,
  shortcut: shortcuts.shortcut,
  switchAutorun: shortcuts.switchAutorun,
  errorAutorun: shortcuts.errorAutorun,
  runNextPeriod: shortcuts.runNextPeriod,
  shortcutToPreview: shortcuts.shortcutToPreview,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutsActions,
      requestGetSettings: settingsActions.getSettingsRequest,
      fetchTeams: teamsActions.getTeamsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutList);
