import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { BackIcon, LinkIcon } from 'components/Layout/Icons';
import LinkItemsDialog from 'components/Dialogs/LinkItemsDialog';
import ItemPartsViewDialog from 'components/Dialogs/ItemPartsViewDialog';
import SelectDialog from '../../components/dialogs/SelectDialog';
import { ButtonIcon } from 'components/Layout/Buttons';

import { actions as unitsActions } from 'redux/units';
import { actions as partActions } from 'redux/parts';
import { useHistory } from 'react-router-dom';

const renderItemContent = item => `${item.Name} - (#${item.ModelNumber})`;

const NewParts = () => {
  const [searchData, setSearchData] = useState('');
  const [linkItemsDialogOpened, setLinkItemsDialogOpened] = useState(false);
  const [itemPartsViewDialogOpened, setItemPartsViewDialogOpened] = useState(false);
  const [selectDialogOpened, setSelectDialogOpened] = useState(false);
  const [isSelectGroup, setIsSelectGroup] = useState(false);
  const [unitToLink, setUnitToLink] = useState(null);
  const [itemToLink, setItemToLink] = useState(null);
  const [currentUnitId, setCurrentUnitId] = useState('');

  const unitsList = useSelector(state => state.units.unitsList);
  const listUnitsWithItems = useSelector(state => state.units.listUnitsWithItems);
  const isListUnitsWithItemsLoading = useSelector(state => state.units.isListUnitsWithItemsLoading);
  const userType = useSelector(state => state.auth.user.userType);
  const tenantIsOEM = useSelector(state => state.auth.user.tenantIsOEM);
  const selectedSite = useSelector(state => state.auth.selectedSite);

  const searchInList = e => setSearchData(e.target.value);

  const dispatch = useDispatch();
  const history = useHistory();

  const filterList = item => item.Name.toLowerCase().includes(searchData.toLowerCase());

  const openLinkItemsDialog = (siteId, unitId, isMulti) => {
    dispatch(unitsActions.getListUnitsWithItemsRequest(siteId));
    setLinkItemsDialogOpened(true);

    if (isMulti) {
      setCurrentUnitId('');
      return;
    }

    if (unitId) {
      setCurrentUnitId(unitId);
    }
  };

  const closeLinkItemsDialog = () => setLinkItemsDialogOpened(false);

  const openItemPartsViewDialog = (unit, item) => {
    setItemPartsViewDialogOpened(true);
    setUnitToLink(unit);
    setItemToLink(item);
  };

  const closeItemPartsViewDialog = () => setItemPartsViewDialogOpened(false);

  const openSelectPartsDialog = () => {
    setSelectDialogOpened(true);
    setIsSelectGroup(false);
  };

  const closeSelectDialog = () => setSelectDialogOpened(false);

  const openSelectGroupsDialog = () => {
    setSelectDialogOpened(true);
    setIsSelectGroup(true);
  };

  const handleLinkItemWithParts = parts => {
    const payload = {
      itemId: itemToLink.Id,
      siteId: unitToLink.SiteId,
      partIDs: parts,
    };
    dispatch(partActions.createPartItemLinkRequest(payload));
    openLinkItemsDialog(selectedSite);
    setSelectDialogOpened(false);
  };

  const backHome = () => history.push('/home');

  const handleLinkItemWithGroups = groups => {
    const payload = {
      itemId: itemToLink.Id,
      siteId: unitToLink.SiteId,
      groupIDs: groups,
    };

    dispatch(partActions.createPartGroupItemLinkRequest(payload));
    openLinkItemsDialog(selectedSite);
    setSelectDialogOpened(false);
  };

  const filterUnitList = list => {
    if (currentUnitId) {
      return list.filter(item => item.Id === currentUnitId);
    }

    return list;
  };

  const renderRightControls = unit => (
    <ButtonIcon tooltip="Link items" style={{ marginRight: '20px' }}>
      <LinkIcon onClick={() => openLinkItemsDialog(selectedSite, unit.Id)} />
    </ButtonIcon>
  );

  useEffect(() => {
    dispatch(unitsActions.unitsListRequest({ filter: { SiteID: selectedSite } }));
  }, []);

  return (
    <>
      <Subheader
        title="List Template"
        isSiteOnLeftSide
        isSearch
        searchData={searchData}
        searchInList={searchInList}
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: backHome,
            hint: 'Back',
          },
        ]}
        rightButtons={[
          {
            icon: <LinkIcon />,
            handler: () => openLinkItemsDialog(selectedSite, null, true),
            hint: 'Link items',
          },
        ]}
      />
      <SimpleList
        data={unitsList.filter(filterList)}
        emptyListMessage="There are no List Templates available"
        onItemClick={() => {}}
        renderItemContent={renderItemContent}
        userType={userType}
        tenantIsOEM={tenantIsOEM}
        renderRightControls={renderRightControls}
        withoutControls
      />
      <LinkItemsDialog
        handleClose={closeLinkItemsDialog}
        open={linkItemsDialogOpened}
        units={filterUnitList(listUnitsWithItems)}
        isLoading={isListUnitsWithItemsLoading}
        commonLockHide
        openItemPartsViewDialog={openItemPartsViewDialog}
      />
      <ItemPartsViewDialog
        open={itemPartsViewDialogOpened}
        onRequestClose={closeItemPartsViewDialog}
        title="Item Parts View"
        unitToLink={unitToLink}
        itemToLink={itemToLink}
        units={listUnitsWithItems}
        openSelectPartsDialog={openSelectPartsDialog}
        openSelectGroupsDialog={openSelectGroupsDialog}
      />
      <SelectDialog
        onRequestClose={closeSelectDialog}
        onSubmit={isSelectGroup ? handleLinkItemWithGroups : handleLinkItemWithParts}
        open={selectDialogOpened}
        title={`Select ${isSelectGroup ? 'Group' : 'Part'}`}
        isSelectGroup={isSelectGroup}
      />
    </>
  );
};

export default NewParts;
