import styled from 'styled-components';
import Toggle from 'material-ui/Toggle';

import { TextareaField } from 'components/Layout/Textarea';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const TextField = styled(TextareaField)`
  div > textarea {
    border: 1px solid ${({ theme }) => theme.primaryRed} !important;
    background-color: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 10px;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const ToggleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-top: 10px;
`;

export const ToggleLabel = styled.span`
  font-size: 12px;
  margin-left: 10px;
`;

export const SmallText = styled.div`
  font-size: 12px;
  margin-top: 15px;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;
`;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 14px;
`;

export const ShowAllToggle = styled(Toggle).attrs({
  iconStyle: ({ theme, toggled }) => ({
    borderRadius: '24px',
    backgroundColor: `${toggled ? theme.loginButtonColor : theme.mainRed}`,
    width: '45px',
  }),
  labelStyle: {
    width: 'inherit',
    top: '19px',
    left: '46px',
    fontSize: '10px',
  },
  thumbStyle: ({ theme }) => ({
    top: '3px',
    left: '2px',
    width: '25px',
    height: '25px',
    backgroundColor: theme.primaryWhite,
  }),
  thumbSwitchedStyle: ({ theme }) => ({
    left: '19px',
    backgroundColor: theme.primaryWhite,
  }),
  trackStyle: ({ theme }) => ({
    backgroundColor: theme.mainRed,
    height: '20px',
    borderRadius: '50px',
  }),
  trackSwitchedStyle: ({ theme }) => ({
    backgroundColor: theme.loginButtonColor,
    height: '20px',
    borderRadius: '50px',
  }),
})`
  width: auto !important;
  height: 30px !important;
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 12px;
  margin-top: -20px;
  margin-bottom: 20px;
`;
