import styled from 'styled-components';

import BaseDialog from 'components/Dialogs/Base';
import { ButtonFlat } from 'components/Layout/Buttons';

export const CancelButton = styled(ButtonFlat).attrs({
  label: 'Cancel',
})`
  min-width: 50% !important;

  span {
    color: ${({ theme }) => theme.modalCancelColor} !important;
  }
`;

export const DialogContainer = styled(BaseDialog).attrs({
  actionsContainerStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  title: 'Export CSV',
})`
  display: flex;
  justify-content: center;

  & div > button:first-child {
    margin-right: 10px !important;
  }
`;

export const DownloadButton = styled(ButtonFlat).attrs({
  label: 'Download',
})`
  min-width: 50% !important;
  border-left: 1px solid ${({ theme }) => theme.lightGrey} !important;

  span {
    color: ${({ theme }) => theme.greenButton} !important;
  }
`;

export const Title = styled.p`
  margin: 20px 0 0 0;
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.3px;
`;

export const RedTitle = styled.p`
  margin: 10px 0 0 0;
  color: ${({ theme }) => theme.mainRed};
  text-align: center;
  font-weight: 900;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextDate = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 20px 25px 20px 30px;
  font-size: 16px;
  letter-spacing: 0.3px;
`;
