import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

const generatePreviewResults = value => (
  <S.PreviewItemContainer>
    {value.map(({ HasMatch, IndexItemName }, i) => (
      <S.PreviewItem key={`${i}-${IndexItemName}`}>
        <S.PreviewItemText>{IndexItemName}</S.PreviewItemText>
        <S.CheckboxContainer HasMatch={HasMatch} />
      </S.PreviewItem>
    ))}
  </S.PreviewItemContainer>
);

const getCenteredTitle = () => (
  <S.IconContainerPreview>
    <S.TestRunIconPreview />
  </S.IconContainerPreview>
);

const TestrunDialog = ({ open, handleClose, data }) => (
  <Dialog
    open={open}
    onRequestClose={handleClose}
    title="Testrun"
    centeredTitle={getCenteredTitle()}
    titleColor={theme.darkGrey}
  >
    <S.Container>
      <S.PreviewText>Current Result</S.PreviewText>
      <S.SectionBody>
        <S.CounterContainer>
          <S.ItemsCount>Current Match</S.ItemsCount>
        </S.CounterContainer>
        {generatePreviewResults(data)}
      </S.SectionBody>
      <S.PreviewBottomText>
        Future Index Item data changes may create a match to trigger task creation (Rules do not require changes to
        trigger task creation)
      </S.PreviewBottomText>
    </S.Container>
  </Dialog>
);

TestrunDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

TestrunDialog.defaultProps = {
  withHeader: true,
  CustomHeaderComponent: null,
  shortcutName: '',
};

export default TestrunDialog;
