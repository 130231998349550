import styled from 'styled-components';

const RootContainer = styled.div`
  min-width: 40vw;
  text-align: center;
`;

const Content = styled.div`
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;

  & > img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const HiddenImage = styled.img`
  display: none;
`;

export { RootContainer, Content, HiddenImage };
