import styled from 'styled-components';
import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

import { TasksModuleIcon, SettingsIcon } from 'components/Layout/Icons';
import { ButtonIcon } from '../../../Layout/Buttons';

export const Settings = styled(SettingsIcon)`
  color: ${({ theme }) => theme.textGray} !important;
  cursor: ${({ onClick }) => onClick && 'pointer'};
`;

export const NotScheduled = styled(TasksModuleIcon)`
  color: ${({ theme }) => theme.textGray} !important;
`;

export const Scheduled = styled(TasksModuleIcon)`
  color: ${({ theme }) => theme.mainRed} !important;
`;

export const AddPeopleDialogContainer = styled.div`
  padding: 40px 40px 0;
  text-align: center;
`;

export const Container = styled.div`
  padding: 0px 30px 15px;
`;

export const TitleBlock = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  padding: 20px 15px;
  margin: 0 10px;
  text-align: left;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
`;

export const ItemContainer = styled(TitleBlock)`
  font-size: 14px;
  padding: 5px 15px;
`;

export const LargeBox = styled.p`
  width: 60%;
  margin: 0;
  align-self: center;
`;

export const MediumBox = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
`;

export const SmallBox = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.primaryGrey};
  font-weight: 300;
  max-width: 450px;
  margin: 10px auto;
`;

export const MainTitle = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  margin: 0px auto;
`;

export const Block = styled(BorderRadiusContainer)`
  padding-bottom: 15px;
  margin-top: 35px;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.mainRed};
  text-align: left;
`;

export const Button = styled(ButtonIcon)`
  padding: 0 !important;
  height: 24px !important;
  width: 15% !important;
`;
