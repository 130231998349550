import http from '../index';

export const getAllIndexItems = ({ siteId }) => http.post(`StatusIndex/ListIndexItem?siteId=${siteId}`);

export const getIndexItem = ({ siteId, id }) => http.post(`StatusIndex/GetIndexItem?siteId=${siteId}`, { id });

export const createIndexItem = ({ siteId, dto }) => http.post(`StatusIndex/CreateIndexItem?siteId=${siteId}`, { dto });

export const editIndexItem = ({ siteId, dto }) => http.post(`StatusIndex/EditIndexItem?siteId=${siteId}`, { dto });

export const deleteIndexItem = ({ siteId, id }) => http.post(`StatusIndex/DeleteIndexItem?siteId=${siteId}`, { id });

export const getIndexCharacteristicList = indexItemId => http.post('StatusIndex/ListCharacteristic', { indexItemId });

export const getHeadersConfig = indexItemId => http.post('StatusIndex/GetHeadersConfig', { indexItemId });

export const getCharacteristicsConfig = indexItemId =>
  http.post('StatusIndex/GetCharacteristicsConfig', { indexItemId });

export const editIndexItemConfig = dto => http.post('StatusIndex/EditIndexItemConfig', { dto });

export const getIndexCharacteristic = id => http.post('StatusIndex/GetCharacteristic', { id });

export const createIndexCharacteristic = dto => http.post('StatusIndex/CreateCharacteristic', { dto });

export const editIndexCharacteristic = dto => http.post('StatusIndex/EditCharacteristic', { dto });

export const deleteIndexCharacteristic = id => http.post('StatusIndex/DeleteCharacteristic', { id });

export const getListIndexRule = ({ siteId }) => http.post(`StatusIndex/ListIndexRule?siteId=${siteId}`);

export const getIndexItemPreview = dto => http.post('StatusIndex/IndexItemPreview', { dto });

export const getIndexItemCharacteristicPreview = dto =>
  http.post('StatusIndex/IndexItemCharacteristicPreview', { dto });

export const getShortcutPreview = dto => http.post('StatusIndex/ShortcutPreview', { dto });

export const addIndexRule = dto => http.post('StatusIndex/CreateIndexRule', { dto });

export const putIndexRule = dto => http.post('StatusIndex/EditIndexRule', { dto });

export const removeIndexRule = id => http.post(`StatusIndex/DeleteIndexRule?id=${id}`);
