import styled from 'styled-components';

import { DirectionRunsIcon } from 'components/Layout/Icons';

export const Section = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: start;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
`;

export const SectionBody = styled.div`
  padding: 50px 50px 15px 30px;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PreviewItem = styled.div`
  display: flex;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
`;

export const PreviewItemContainer = styled.div`
  margin: 15px 0;
  div:first-child {
    border-top: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  }
`;

export const PreviewItemText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  overflow: hidden;
  padding: 15px 30px 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PreviewText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 18px;
`;

export const PreviewTitle = styled(PreviewText)`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  padding: 12px 0px 30px;
`;

export const ItemsCount = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  white-space: nowrap;
`;

export const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  padding: 40px 50px 0;
  text-align: center;
`;

export const TestRunIconPreview = styled(DirectionRunsIcon).attrs({
  color: ({ theme }) => theme.textGray,
})``;

export const IconContainerPreview = styled.div`
  width: 40px;
  height: 40px !important;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 20px);
`;

export const BoltText = styled.span`
  font-weight: bold;
`;
