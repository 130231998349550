import React from 'react';

import { Information, Text } from './styled';

const Item = task => (
  <Information>
    <Text>{task.Name}</Text>
  </Information>
);

export default Item;
