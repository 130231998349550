import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as partsActions } from 'redux/parts';
import { actions as distributionActions } from 'redux/distributions';
import { actions as unitsActions } from 'redux/units';
import theme from 'theme';

import Subheader from 'components/Subheader';
import Toggle from 'components/Toggle';
import { AddIcon, BackIcon, LinkIcon, UpDownArrowIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import TableList from 'components/TableList';
import DropdownMenu from 'components/DropdownMenu';
import PartSelectionDialog from 'components/Dialogs/PartSelectionDialog';
import ConfirmDialog from 'components/Dialogs/Confirm';
import LinkItemsDialog from 'components/Dialogs/LinkItemsDialog';
import ItemPartsViewDialog from 'components/Dialogs/ItemPartsViewDialog';

import { partsToggle } from 'configs/toggles';

import DownloadPartsListDialog from './DownloadPartsListDialog';
import DownloadTemplateDialog from './DownloadTemplateDialog';
import UploadPartsDialog from './UploadPartsDialog';
import { NewPartDialog } from './NewPartDialog';
import { NewGroupDialog } from './NewGroupDialog';
import DistributionDialog from './DistributionDialog';
import DistributionPreviewDrawer from './DistributionPreviewDrawer';
import ConfirmPartRemovalText from './ConfirmPartRemovalText';
import ConfirmGroupRemovalText from './ConfirmGroupRemovalText';
import { PartManagementDialog } from './PartManagementDialog';
import { tableHeads, tableColumns, addMenuItems, exportMenuItems } from './constants';
import { firstLetterUpperCase, isDistributionFormValid, generateDistributionRules } from './helpers';
import * as S from './styled';

const Parts = () => {
  const [searchData, setSearchData] = useState('');
  const [newPartDialogOpen, setNewPartDialogOpen] = useState(false);
  const [newGroupDialogOpen, setNewGroupDialogOpen] = useState(false);
  const [partSelectionDialogOpen, setPartSelectionDialogOpen] = useState(false);
  const [newGroup, setNewGroup] = useState({});
  const [openedExportsDialogs, setOpenedExportsDialogs] = useState({});
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    type: '',
    entityToRemove: {},
  });
  const [distributionDialogOpen, setDistributionDialogOpen] = useState(false);
  const [distributionPreviewDrawerOpen, setDistributionPreviewDrawerOpen] = useState(false);
  const [distributionRules, setDistributionRules] = useState({});
  const [itemForLinking, setItemForLinking] = useState({});
  const [linkItemsDialogOpened, setLinkItemsDialogOpened] = useState(false);
  const [itemPartsViewDialogOpened, setItemPartsViewDialogOpened] = useState(false);
  const [unitToView, setUnitToView] = useState(null);
  const [itemToView, setItemToView] = useState(null);
  const [siteSelected, setSiteSelected] = useState(null);
  const [isPartManagementDialogOpen, setIsPartManagementDialogOpen] = useState(false);
  const [selectedPartId, setSelectedPartId] = useState(null);

  const error = useSelector(state => state.parts.error);
  const partsList = useSelector(state => state.parts.list);
  const groupsList = useSelector(state => state.parts.groupsList);
  const distributionPreviewList = useSelector(state => state.distributions.previewList);
  const distributionDetails = useSelector(state => state.distributions.details);
  const listUnitsWithItems = useSelector(state => state.units.listUnitsWithItems);
  const sites = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);

  const { type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const list = type === 'parts' ? partsList : groupsList;
  const sitesList = sites.map(site => ({ value: site.id, label: site.name }));

  const modeHandler = mode => {
    setConfirmDialog({ open: false, type: '', entityToRemove: {} });
    history.push(`/parts/${mode.key}`);
  };

  const onAddPart = (e, value) => {
    if (value === 'newPartDialogOpen') {
      setNewPartDialogOpen(true);
    } else {
      setNewGroupDialogOpen(true);
    }
  };

  const closeNewPartDialog = () => setNewPartDialogOpen(false);

  const addNewGroup = newGroupValue => {
    setNewGroup(newGroupValue);

    if (newGroupValue.Id) {
      setPartSelectionDialogOpen(true);
      closeNewGroupDialog();
    }
  };

  const closeNewGroupDialog = () => setNewGroupDialogOpen(false);

  const closePartsSelectionDialog = () => setPartSelectionDialogOpen(false);

  const submitPartSelection = values => {
    const partIDs = Object.entries(values)
      .filter(([, value]) => value)
      .map(([key]) => key);

    dispatch(partsActions.addPartGroupLinkRequest({ partGroupDto: newGroup, partIDs }));

    setNewGroup({});
    closePartsSelectionDialog();
  };

  const searchInList = e => setSearchData(e.target.value);

  const handleExportsMenuPressed = (event, value) => {
    setOpenedExportsDialogs(prevState => ({ ...prevState, [value]: true }));
  };

  // eslint-disable-next-line no-shadow
  const handleExportsModalClose = type => () => {
    setOpenedExportsDialogs(prevState => ({ ...prevState, [type]: false }));
  };

  const handlePartRemovePress = part => {
    setConfirmDialog(prevState => ({ ...prevState, open: true, entityToRemove: part, type: 'part' }));
  };

  const handleGroupRemovePress = group => {
    setConfirmDialog(prevState => ({ ...prevState, open: true, entityToRemove: group, type: 'group' }));
  };

  const handleRemoveReject = () => {
    setConfirmDialog(prevState => ({ ...prevState, open: false, entityToRemove: {} }));
  };

  const handleRemoveApprove = () => {
    const { entityToRemove } = confirmDialog;
    if (!entityToRemove.Id) {
      return;
    }

    if (type === 'parts') {
      dispatch(partsActions.deletePartRequest(entityToRemove.Id));
    } else {
      dispatch(partsActions.deletePartGroupRequest(entityToRemove.Id));
    }

    handleRemoveReject();
  };

  const handleOpenDistributionDialog = item => {
    if (type === 'parts') {
      dispatch(
        distributionActions.getDistributionDetailsRequest({
          partId: item.Id,
          onNext: () => {
            setDistributionDialogOpen(true);
            setItemForLinking(item);
          },
        }),
      );
    } else {
      dispatch(
        distributionActions.getDistributionGroupDetailsRequest({
          partGroupId: item.Id,
          onNext: () => {
            setDistributionDialogOpen(true);
            setItemForLinking(item);
          },
        }),
      );
    }
  };

  const handleCloseDistributionDialog = () => {
    setDistributionDialogOpen(false);
    dispatch(distributionActions.setDistributionDetails({}));
  };

  const openLinkItemsDialog = siteId => {
    dispatch(unitsActions.getListUnitsWithItemsRequest(siteId));
    setLinkItemsDialogOpened(true);
  };

  const closeLinkItemsDialog = () => setLinkItemsDialogOpened(false);

  const openItemPartsViewDialog = (unit, item) => {
    setUnitToView(unit);
    setItemToView(item);
    setItemPartsViewDialogOpened(true);
  };

  const closeItemPartsViewDialog = () => setItemPartsViewDialogOpened(false);

  const handleSitesDropdownChange = (e, key, value) => {
    openLinkItemsDialog(value);
    setSiteSelected(value);
  };

  const rightButtons = [
    { icon: <LinkIcon />, handler: () => openLinkItemsDialog(siteSelected), hint: 'part links' },
    {
      component: (
        <DropdownMenu
          onChange={handleExportsMenuPressed}
          headerText="Export/Import"
          items={exportMenuItems}
          icon={<UpDownArrowIcon />}
        />
      ),
      isComponent: true,
      hint: 'Import/Export',
    },
    {
      component: <DropdownMenu onChange={onAddPart} headerText="New" items={addMenuItems} icon={<AddIcon />} />,
      isComponent: true,
      hint: 'Add Part/Group',
    },
  ];

  const goBack = () => history.push('/home');

  const goForward = item => {
    if (type === 'groups') {
      history.push({
        pathname: `/parts/groups/${item.Id}`,
        state: { group: item },
      });
    } else {
      history.push({ pathname: `/parts/parts/${item.Id}` });
    }
  };

  const filterList = item =>
    tableColumns[type]
      .map(({ field }) => field)
      .some(
        field =>
          item[field] &&
          typeof item[field] === 'string' &&
          item[field].toLowerCase().includes(searchData.toLowerCase()),
      );

  const renderRightControllCell = item => (
    <td>
      <ButtonIcon onClick={() => goForward(item)} tooltip="Parts Detail">
        <S.IconForward />
      </ButtonIcon>
    </td>
  );

  const openDistributionPreviewDrawer = () => setDistributionPreviewDrawerOpen(true);

  const closeDistributionPreviewDrawer = () => setDistributionPreviewDrawerOpen(false);

  const onSubmitDistributionRules = needOpenDrawer => values => {
    // eslint-disable-next-line no-shadow
    const distributionRules = generateDistributionRules(values, {
      [type === 'parts' ? 'PartID' : 'PartGroupID']: itemForLinking.Id,
    });

    const errors = isDistributionFormValid(distributionRules);

    if (errors.length) {
      return dispatch(
        openErrorDialog(
          `Please add at least one rule for ${errors.join(', ')} 
      section${errors.length > 1 ? "'s" : ''} or select "Ignore rules" to disable rules`,
          'Validation Error',
          theme.greenButton,
        ),
      );
    }

    dispatch(distributionActions.getDistributionListPreviewRequest(distributionRules));

    if (needOpenDrawer) openDistributionPreviewDrawer();

    return setDistributionRules(distributionRules);
  };

  const handleLinkParts = () => {
    const distributionList = distributionRules;
    const itemIds = [];
    distributionPreviewList.map(unit => {
      unit.Items.map(item => itemIds.push(item.Id));
      return unit;
    });

    const distributionDetailsWithoutRules = omit(distributionDetails, ['LocationRules', 'UnitRules', 'ItemRules']);
    const payload = { ...distributionList, ...distributionDetailsWithoutRules };
    const isDistributionDetailsEmpty = isEmpty(distributionDetails);

    if (type === 'groups') {
      distributionList.partGroupName = itemForLinking.Name;

      if (isDistributionDetailsEmpty) {
        dispatch(partsActions.createGroupLinksRequest(distributionList));
      } else {
        dispatch(distributionActions.editDistributionListGroupsRequest(payload));
      }
    } else if (isDistributionDetailsEmpty) {
      dispatch(partsActions.createPartLinksRequest(distributionList));
    } else {
      dispatch(distributionActions.editDistributionListRequest(payload));
    }

    closeDistributionPreviewDrawer();
    handleCloseDistributionDialog();
  };

  const onRowClick = item => {
    if (type === 'parts') {
      setIsPartManagementDialogOpen(true);
      setSelectedPartId(item.Id);
    }
  };

  const closePartManagementDialog = () => setIsPartManagementDialogOpen(false);

  useEffect(() => {
    dispatch(partsActions.getPartsRequest());
    dispatch(partsActions.getPartGroupsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(openErrorDialog(error, 'Error'));
    }
  }, [dispatch, error]);

  return (
    <>
      <Subheader
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: goBack,
            hint: 'Back',
          },
        ]}
        rightButtons={rightButtons}
        title={
          <div>
            <h2>Parts Master</h2>
            <Toggle config={partsToggle} selected={type} handler={modeHandler} />
          </div>
        }
        isSearch
        searchData={searchData}
        searchInList={searchInList}
      />
      <S.TableContainer mode={type}>
        <TableList
          tableHeads={tableHeads[type]}
          list={list.filter(filterList)}
          tableColumns={tableColumns[type]}
          renderRightControllCell={renderRightControllCell}
          onRowClick={onRowClick}
          handlers={{
            onPartRemove: handlePartRemovePress,
            openDistributionDialog: handleOpenDistributionDialog,
            onGroupRemove: handleGroupRemovePress,
          }}
        />
      </S.TableContainer>
      <NewPartDialog open={newPartDialogOpen} handleClose={closeNewPartDialog} />
      <NewGroupDialog
        open={newGroupDialogOpen}
        handleClose={closeNewGroupDialog}
        addNewGroup={addNewGroup}
        newGroup={newGroup}
      />
      <PartSelectionDialog
        open={partSelectionDialogOpen}
        title="Select Part"
        submitLabel="Add Selection to Group"
        handleClose={closePartsSelectionDialog}
        onSubmit={submitPartSelection}
        parts={partsList}
      />
      <DownloadPartsListDialog open={openedExportsDialogs.parts} onRequestClose={handleExportsModalClose('parts')} />
      <DownloadTemplateDialog
        open={openedExportsDialogs.partsTemplate}
        onRequestClose={handleExportsModalClose('partsTemplate')}
      />
      <UploadPartsDialog
        open={openedExportsDialogs.uploadParts}
        onRequestClose={handleExportsModalClose('uploadParts')}
      />
      <ConfirmDialog
        open={confirmDialog.open}
        title="Warning"
        text={
          confirmDialog.type === 'part' ? (
            <ConfirmPartRemovalText linksCount={confirmDialog.entityToRemove.LinksCount} />
          ) : (
            <ConfirmGroupRemovalText linksCount={confirmDialog.entityToRemove.LinksCount} />
          )
        }
        confirmText="Delete"
        cancelText="Cancel"
        onApprove={handleRemoveApprove}
        onReject={handleRemoveReject}
      />
      <DistributionDialog
        open={distributionDialogOpen}
        handleClose={handleCloseDistributionDialog}
        title={`${firstLetterUpperCase(type)} Distribution`}
        type={type}
        selectedItem={itemForLinking}
        onSubmit={onSubmitDistributionRules(true)}
        onOpenPreviewInfo={onSubmitDistributionRules(false)}
        submitLabel="Save Next"
        previewList={distributionPreviewList}
      />
      <DistributionPreviewDrawer
        open={distributionPreviewDrawerOpen}
        handleClose={closeDistributionPreviewDrawer}
        commonLockHide
        handleLinkParts={handleLinkParts}
      />
      <LinkItemsDialog
        handleClose={closeLinkItemsDialog}
        open={linkItemsDialogOpened}
        units={listUnitsWithItems}
        commonLockHide
        openItemPartsViewDialog={openItemPartsViewDialog}
        isView
        withSiteDropdown
        dropdownList={sitesList}
        dropdownLabel="Select Site"
        handleDropdownChange={handleSitesDropdownChange}
        dropdownValue={siteSelected}
      />
      <ItemPartsViewDialog
        open={itemPartsViewDialogOpened}
        onRequestClose={closeItemPartsViewDialog}
        title="Item Parts View"
        units={listUnitsWithItems}
        unitToLink={unitToView}
        itemToLink={itemToView}
        isView
      />
      {isPartManagementDialogOpen && (
        <PartManagementDialog
          isOpen={isPartManagementDialogOpen}
          handleClose={closePartManagementDialog}
          id={selectedPartId}
          type={type}
        />
      )}
    </>
  );
};

export default Parts;
