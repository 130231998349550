import React from 'react';
import { Field } from 'react-final-form';

import RadioButtons from 'components/RadioButtons';

import { options } from './constants';
import * as S from './styled';

const TaskCreation = () => (
  <S.Section>
    <S.SectionHeader>Task Creation Lead Time</S.SectionHeader>
    <S.SectionBody>
      <S.FieldBlockContainer>
        <S.Text>
          Current Lead Time for tasks is: <S.RedText>One Week</S.RedText>
        </S.Text>
        <S.FieldRadioContainer>
          <Field component={RadioButtons} items={options} name="ignore-Lead-Time" style={S.typeRadioStyle} />
        </S.FieldRadioContainer>
      </S.FieldBlockContainer>
    </S.SectionBody>
  </S.Section>
);

export default TaskCreation;
