import React from 'react';
import PropTypes from 'prop-types';

import { TeamType } from 'configs/propTypes';

import * as S from './styled';

const Item = ({
  item: { Assistant, Manager, AssistantID, ManagerID, Name, IsActive, Location, MembersCount, Id, Notes },
  onClick,
}) => (
  <S.Gateway onClick={() => onClick({ AssistantID, ManagerID, Name, IsActive, Location, Id, Notes })}>
    {!IsActive && <S.InactiveMarker />}
    <S.InfoBlock>
      <S.Text>Status: {IsActive ? 'Active' : 'Inactive'}</S.Text>
      <S.Text>{Name}</S.Text>
    </S.InfoBlock>
    <S.InfoBlock>
      <S.Text>Assistant: {Assistant.Name}</S.Text>
      <S.Text>Manager: {Manager.Name}</S.Text>
    </S.InfoBlock>
    <S.InfoBlock>
      <S.Text>Location: {Location}</S.Text>
    </S.InfoBlock>
    <S.InfoBlock>
      <S.Text>Members: {MembersCount}</S.Text>
    </S.InfoBlock>
  </S.Gateway>
);

Item.propTypes = {
  item: TeamType.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Item;
