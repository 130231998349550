import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { exportSitePdf } from 'http/exportPdf';
import { UnitType } from 'configs/propTypes';

import TableList from 'components/TableList';
import { AddIcon, BackIcon, DeleteIcon, ExportQRIcon, ImportIcon, LowPriorityIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import RightControls from 'components/SimpleList/ListItem/RightControls';
import Subheader from 'components/Subheader';
import PDFDialog from 'components/Dialogs/PdfDialog';
import PreviewDuplicateDialog from 'components/Dialogs/DuplicateDialog';

import DuplicateDialog from './DuplicateDialog';
import DragFullListDialog from './DragFullListDialog';
import AddEditUnitDialog from '../AddEditUnitDialog';
import ListExchangeUploadDialog from './ListExchangeUploadDialog';

import theme from 'theme';
import { tableConfigs } from './configs';
import { mapDispatchToProps, mapStateToProps } from './index.props';

import * as S from './styled';

class UnitsList extends PureComponent {
  state = {
    addUnitDialogOpened: false,
    editUnitDialogOpened: false,
    isDefaultBarcode: this.props.IsGeneratedDefault,
    dragFullListDialogOpened: false,
    duplicateDialogOpened: false,
    pdfDialogOpened: false,
    isListExchangeUploadDialogOpen: false,
    qrCodeSize: 2.0,
    searchData: '',
    unitToDuplicate: null,
    unitWithPDF: null,
    isListDeleteModalOpened: false,
    isChecklistsDeletedInfoModalOpened: false,
    isTasksDeletedInfoModalOpened: false,
    isPreviewDuplicateDialogVisible: false,
  };

  componentDidMount() {
    const { fetchUnits, getUserType, selectedSite, fetchAssetCategoriesListRequest } = this.props;

    fetchUnits({ filter: { SiteID: selectedSite } });
    getUserType();
    fetchAssetCategoriesListRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error || 'An error occured while saving the Unit', 'Error');
    }

    if (nextProps.createdUnitId) {
      this.closeAddUnitDialog();
    }

    if (this.props.IsGeneratedDefault !== nextProps.IsGeneratedDefault) {
      this.setState({ isDefaultBarcode: nextProps.IsGeneratedDefault });
    }
  }

  componentWillUnmount() {
    this.props.requestRefreshPdf();
  }

  getPdf = pdf => {
    if (this.state.unitWithPDF) {
      exportSitePdf({
        siteId: this.state.unitWithPDF.SiteId,
        id: pdf.Id,
      });
    } else {
      exportSitePdf(pdf.Id);
    }
  };

  deletePdf = pdf => {
    if (this.state.unitWithPDF) {
      this.props.requestDeletePdf({
        siteId: this.state.unitWithPDF.SiteId,
        id: pdf.Id,
      });
    } else {
      this.props.requestDeletePdf({
        allSite: true,
        id: pdf.Id,
      });
    }
  };

  onDeleteUnitApprove = () => {
    const {
      deleteUnit,
      unit: { Id },
    } = this.props;

    this.setState({
      isListDeleteModalOpened: false,
      isTasksDeletedInfoModalOpened: false,
      editUnitDialogOpened: false,
    });

    deleteUnit(Id);
  };

  onDeleteUnit = () => {
    const {
      getUnitTaskStatus,
      unit: { Id },
    } = this.props;

    getUnitTaskStatus(Id);

    this.setState({ isListDeleteModalOpened: true });
  };

  setDefaultBarcode = value => this.setState({ isDefaultBarcode: value });

  closeAddUnitDialog = () => this.setState({ addUnitDialogOpened: false });

  closePdfDialog = () => this.setState({ pdfDialogOpened: false, unitWithPDF: null });

  closeDragFullListDialog = () => this.setState({ dragFullListDialogOpened: false });

  openListExchangeUploadDialog = () => this.setState({ isListExchangeUploadDialogOpen: true });

  closeListExchangeUploadDialog = () => this.setState({ isListExchangeUploadDialogOpen: false });

  openPreviewDuplicateDialog = unitToDuplicate =>
    this.setState({ isPreviewDuplicateDialogVisible: true, unitToDuplicate });

  closePreviewDuplicateDialog = () => this.setState({ isPreviewDuplicateDialogVisible: false });

  openDuplicateDialog = () => {
    this.setState({ duplicateDialogOpened: true });
    this.closePreviewDuplicateDialog();
  };

  closeDuplicateDialog = () => this.setState({ duplicateDialogOpened: false, unitToDuplicate: null });

  duplicateUnit = unitData => {
    this.closeDuplicateDialog();
    this.props.requestDuplicateUnit(unitData);
  };

  goToUnitDetails = item => {
    this.props.history.push(`/units/items/${item.Id}`);
  };

  openAddUnitDialog = () => {
    this.props.requestRefreshPdf();
    this.setState({ addUnitDialogOpened: true });
  };

  openEditUnitDialog = () => this.setState({ editUnitDialogOpened: true });

  closeEditUnitDialog = () => {
    const { IsGeneratedDefault } = this.props;

    this.setState({
      editUnitDialogOpened: false,
      isDefaultBarcode: IsGeneratedDefault,
    });
  };

  handleClickChecklistDeletedInfoModal = () =>
    this.setState({
      isChecklistsDeletedInfoModalOpened: false,
      isTasksDeletedInfoModalOpened: true,
    });

  handleCloseTasksDeletedInfoModal = () => this.setState({ isTasksDeletedInfoModalOpened: false });

  handleCloseChecklistsDeletedInfoModal = () => this.setState({ isChecklistsDeletedInfoModalOpened: false });

  handleClickListDeleteModal = () => this.setState({ isChecklistsDeletedInfoModalOpened: true });

  handleCloseListDeleteModal = () => this.setState({ isListDeleteModalOpened: false });

  openPdfDialog = unit => {
    if (unit && unit.Id) {
      this.props.requestPdfList({
        siteId: unit.SiteId,
        unitId: unit.Id,
      });
      this.setState({ unitWithPDF: unit });
    } else {
      this.props.requestPdfList({ siteId: unit });
    }
    this.setState({ pdfDialogOpened: true });
  };

  openDragFullListDialog = siteId => {
    this.props.requestUnitsWithItems({ siteId });
    this.setState({ dragFullListDialogOpened: true });
  };

  saveNewUnit = items => this.props.requestUpdateItems(items);

  changeSize = (event, index, value) => this.setState({ qrCodeSize: value });

  backHome = () => this.props.history.push('/home');

  createQrCode = (size, siteId) => {
    if (this.state.unitWithPDF) {
      this.props.requestCreatePdf({
        siteId: this.state.unitWithPDF.SiteId,
        unitId: this.state.unitWithPDF.Id,
        size,
      });
    } else {
      this.props.requestCreatePdf({
        siteId,
        size,
      });
    }
  };

  itemsForUnitReorder = items =>
    items.map(item => ({
      id: item.id,
      content: item.itemName,
      sequence: item.sequence,
    }));

  unitsWithItems = list =>
    list.map(item => ({
      id: item.UnitId,
      content: item.UnitName,
      items: this.itemsForUnitReorder(item.Items),
    }));

  searchInList = event => this.setState({ searchData: event.target.value });

  filterList = item => item.Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  submitUnit = unit => {
    this.props.addUnit(unit);
  };

  submitEditUnit = values => {
    const { isDefaultBarcode } = this.state;
    const { editUnit } = this.props;

    editUnit({
      params: {
        ...values,
        Barcode: {
          ...values.Barcode,
          IsGeneratedDefault: isDefaultBarcode,
        },
      },
      closeEditUnitDialog: this.closeEditUnitDialog,
    });
  };

  rightButtons = [
    {
      icon: <LowPriorityIcon />,
      handler: () => this.openDragFullListDialog(this.props.selectedSite),
      hint: 'Sequence setup',
    },
    {
      icon: <ExportQRIcon />,
      handler: () => this.openPdfDialog(this.props.selectedSite),
      hint: "QR pdf's",
    },
    {
      icon: <AddIcon />,
      handler: this.openAddUnitDialog,
      hint: 'Add',
    },
  ];

  rightControls = item => (
    <div>
      <ButtonIcon onClick={() => this.deletePdf(item)} tooltip="Delete">
        <DeleteIcon />
      </ButtonIcon>
      <ButtonIcon onClick={() => this.getPdf(item)} tooltip="Get">
        <ImportIcon />
      </ButtonIcon>
    </div>
  );

  mapList = item => ({
    ...item,
    ModelNumber: (
      <>
        <S.BoldText>Model</S.BoldText>: {item.ModelNumber}
      </>
    ),
    Items: (
      <>
        <S.BoldText>Items</S.BoldText>: {item.ItemCount}
      </>
    ),
  });

  onRowClick = item => {
    const { fetchUnit } = this.props;
    fetchUnit(item.Id);

    this.openEditUnitDialog();
  };

  renderRightControllCell = item => {
    const { userType, tenantIsOEM, allowSubmitToListExchange, fetchUnit } = this.props;

    const handleClick = e => {
      e.stopPropagation();
      this.goToUnitDetails(item);
    };

    const onUploadButtonClick = e => {
      e.stopPropagation();
      fetchUnit(item.Id);
      this.openListExchangeUploadDialog();
    };

    return (
      <S.TableCell>
        <RightControls
          unit={item}
          userType={userType}
          tenantIsOEM={tenantIsOEM}
          allowSubmitToListExchange={allowSubmitToListExchange}
          onUploadButtonClick={onUploadButtonClick}
          openDragItemsListDialog={this.openDragItemsListDialog}
          openPdfDialog={this.openPdfDialog}
          openDuplicateDialog={this.openPreviewDuplicateDialog}
        />
        <ButtonIcon onClick={handleClick} tooltip="List template Details" tooltipStyles={{ marginTop: -14 }}>
          <S.IconForward />
        </ButtonIcon>
      </S.TableCell>
    );
  };

  render() {
    const {
      pdfList,
      selectedSite,
      unitsList,
      unitWithItemsList,
      isUnitsLoading,
      unit,
      history,
      requestTenantToList,
      editUnit,
    } = this.props;
    const {
      addUnitDialogOpened,
      editUnitDialogOpened,
      dragFullListDialogOpened,
      duplicateDialogOpened,
      pdfDialogOpened,
      qrCodeSize,
      searchData,
      unitToDuplicate,
      isDefaultBarcode,
      isListDeleteModalOpened,
      isChecklistsDeletedInfoModalOpened,
      isTasksDeletedInfoModalOpened,
      isListExchangeUploadDialogOpen,
      isPreviewDuplicateDialogVisible,
    } = this.state;

    return (
      <>
        <Subheader
          key="Units subheader"
          title="List Templates"
          isSiteOnLeftSide
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backHome,
              hint: 'Back',
            },
          ]}
          rightButtons={this.rightButtons}
        />
        {!unitsList?.length ? (
          <S.EmptyList>
            <S.EmptyListText>There are no List Templates available.</S.EmptyListText>
          </S.EmptyList>
        ) : (
          <S.TableContainer>
            <TableList
              list={unitsList.map(this.mapList).filter(this.filterList)}
              tableColumns={tableConfigs}
              renderRightControllCell={this.renderRightControllCell}
              onRowClick={this.onRowClick}
            />
          </S.TableContainer>
        )}
        <PreviewDuplicateDialog
          open={isPreviewDuplicateDialogVisible}
          name={unitToDuplicate?.Name}
          handleClose={this.closePreviewDuplicateDialog}
          type="unit"
          onPositiveHandler={this.openDuplicateDialog}
        />
        <DuplicateDialog
          key="Duplicate Dialog"
          handleClose={this.closeDuplicateDialog}
          unitToDuplicate={unitToDuplicate}
          onSubmit={this.duplicateUnit}
          open={duplicateDialogOpened}
          customColor={theme.primaryGreen}
        />
        {dragFullListDialogOpened && (
          <DragFullListDialog
            key="DragFullList Dialog"
            handleClose={this.closeDragFullListDialog}
            units={this.unitsWithItems(unitWithItemsList)}
            isLoading={isUnitsLoading}
            onSubmit={this.saveNewUnit}
            open={dragFullListDialogOpened}
          />
        )}
        <AddEditUnitDialog
          key="Add Unit Dialog"
          handleClose={this.closeAddUnitDialog}
          onSubmit={this.submitUnit}
          open={addUnitDialogOpened}
          title="Add List Template"
          isNewSubmitButton
          withSubmit={false}
          hasPaddingBottom={false}
          withoutTopBorder
          hasPadding
        />
        <AddEditUnitDialog
          key="Edit Unit Dialog"
          isDefaultBarcode={isDefaultBarcode}
          setDefaultBarcode={this.setDefaultBarcode}
          handleClose={this.closeEditUnitDialog}
          handleDelete={this.onDeleteUnit}
          onSubmit={this.submitEditUnit}
          open={editUnitDialogOpened}
          title="Edit Template Info"
          unit={unit}
          isNewDeleteButton
          isNewSubmitButton
          withSubmit={false}
          hasPadding
          gapValue="20px"
          hasPaddingBottom={false}
          withoutTopBorder
          isListDeleteModalOpened={isListDeleteModalOpened}
          isChecklistsDeletedInfoModalOpened={isChecklistsDeletedInfoModalOpened}
          isTasksDeletedInfoModalOpened={isTasksDeletedInfoModalOpened}
          history={history}
          handleClickChecklistDeletedInfoModal={this.handleClickChecklistDeletedInfoModal}
          handleCloseTasksDeletedInfoModal={this.handleCloseTasksDeletedInfoModal}
          handleCloseChecklistsDeletedInfoModal={this.handleCloseChecklistsDeletedInfoModal}
          handleClickListDeleteModal={this.handleClickListDeleteModal}
          handleCloseListDeleteModal={this.handleCloseListDeleteModal}
          onDeleteUnitApprove={this.onDeleteUnitApprove}
        />
        <PDFDialog
          key="PDF"
          title="PDF"
          handleClose={this.closePdfDialog}
          pdfList={pdfList}
          qrCodeSize={qrCodeSize}
          changeSize={this.changeSize}
          isSearch={false}
          createQR
          handleClick={() => this.createQrCode(qrCodeSize, selectedSite)}
          open={pdfDialogOpened}
          rightControls={this.rightControls}
        />
        <ListExchangeUploadDialog
          unit={unit}
          requestTenantToList={requestTenantToList}
          open={isListExchangeUploadDialogOpen}
          handleClose={this.closeListExchangeUploadDialog}
          editUnit={editUnit}
        />
      </>
    );
  }
}

UnitsList.propTypes = {
  addUnit: PropTypes.func.isRequired,
  allowSubmitToListExchange: PropTypes.bool.isRequired,
  createdUnitId: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  fetchUnits: PropTypes.func.isRequired,
  getUserType: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  openErrorDialog: PropTypes.func.isRequired,
  pdfList: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  fetchUnit: PropTypes.func.isRequired,
  editUnit: PropTypes.func.isRequired,
  deleteUnit: PropTypes.func.isRequired,
  requestCreatePdf: PropTypes.func.isRequired,
  requestDeletePdf: PropTypes.func.isRequired,
  requestDuplicateUnit: PropTypes.func.isRequired,
  requestPdfList: PropTypes.func.isRequired,
  requestRefreshPdf: PropTypes.func.isRequired,
  requestTenantToList: PropTypes.func.isRequired,
  requestUnitsWithItems: PropTypes.func.isRequired,
  requestUpdateItems: PropTypes.func.isRequired,
  fetchAssetCategoriesListRequest: PropTypes.func.isRequired,
  selectedSite: PropTypes.string.isRequired,
  tenantIsOEM: PropTypes.bool.isRequired,
  unitsList: PropTypes.arrayOf(UnitType).isRequired,
  unitWithItemsList: PropTypes.array.isRequired,
  userType: PropTypes.number.isRequired,
  isUnitsLoading: PropTypes.bool.isRequired,
  IsGeneratedDefault: PropTypes.bool.isRequired,
  unit: UnitType.isRequired,
  getUnitTaskStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitsList);
