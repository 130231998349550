import { getTimezoneOffset, stringComparator } from 'helpers';

export const getListByMode = payload => {
  const { [payload.mode]: list, mode } = payload;

  if (mode === 'hub') {
    return list
      .map(({ Name, GatewaysInHubNumber }) => ({ value: Name, label: Name, additionalInfo: GatewaysInHubNumber }))
      .sort((a, b) => stringComparator(a.label, b.label, true));
  }

  if (mode === 'gateway') {
    return list
      .map(({ DeviceId, SensorsCount }) => ({ value: DeviceId, label: DeviceId, additionalInfo: SensorsCount }))
      .sort((a, b) => stringComparator(a.label, b.label, true));
  }

  return list.map(({ Id, Name, AdditionalSiteID }) => ({
    value: Id,
    label: AdditionalSiteID ? `${AdditionalSiteID} ${Name}` : Name,
  }));
};

export const getTenantLocationsByName = (tenants, name) => {
  const tenant = tenants.find(({ Name }) => Name === name);

  if (tenant) {
    return tenant.Location;
  }

  return '';
};

export const getSiteLocationByName = (sites, name) => {
  if (name) {
    const site = sites.find(({ AdditionalSiteID, Name }) => `${AdditionalSiteID} ${Name}`.trim() === name.trim());

    if (site) {
      return {
        offset: getTimezoneOffset(site.TimeZoneIanaId),
        city: site.Address.City,
      };
    }
  }
  return null;
};

export const itemLabels = {
  tenant: { label: 'Tenant (Client business) name', additionalInfo: '' },
  site: { label: 'Site ID and Name', additionalInfo: '' },
  hub: { label: 'Hub Name', additionalInfo: 'Gateways' },
  gateway: { label: 'Gateway Name', additionalInfo: 'Sensors' },
};

export const selectedItem = {
  tenant: 'selectedTenant',
  site: 'selectedSite',
  hub: 'currentHub',
  gateway: 'currentGateway',
};

export const resourceCards = {
  connection: [{ type: 'connection', title: 'Gateway Connection' }],
  gateway: [{ type: 'name', title: 'Gateway Name' }],
  hub: [
    { type: 'location', title: 'Hub Location Selection' },
    { type: 'tier', title: 'Hub Pricing and Scale Tier' },
    { type: 'name', title: 'New Hub' },
  ],
  resourcegroup: [
    { type: 'location', title: 'Server Location Selection' },
    { type: 'name', title: 'New Resource Group' },
  ],
};

export const notificationTitle = {
  resourcegroup: 'What server location is closest to this Tenant ?',
  hub: 'What server location is closest to this Site ?',
  gateway: 'Please note the name of your new Gateway Device!',
  connection: 'Retrieve Gateway Device Connection String',
};

export const modalTitles = {
  setup: 'New IoT Setup',
  add: 'New Gateway',
  remove: 'Remove Gateway',
};
