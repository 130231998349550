import queryString from 'query-string';

import http from '../index';

export const getSchedulesList = dto => http.post(`Checklist/List`, dto);

export const getCurrentUnit = unitId => http.post('Unit/Get/', `id=${unitId}`);

export const getChecklistStatus = params => http.post('Checklist/GetTaskStatus', params);

export const setTimeFrame = params => http.post('ChecklistActions/SetTimeframe', params);

export const getMissingTasks = params => http.post('ChecklistTaskTemplate/ListMissing', params);

export const getUnitItems = (unitId, siteId) => http.post(`/Item/List?siteId=${siteId}`, { unitId });

export const createSchedule = params => http.post('Checklist/Post', params);

export const getScheduleDetails = id => http.post('Checklist/Get/', { id });

export const getChecklistActivation = (unitId, id, url) => http.post(url, { unitId, id });

export const getSwitchAutorun = (checklistId, isStart, ignoreLeadTime) =>
  http.post('ChecklistActions/SwitchAutorun', { checklistId, isStart, ignoreLeadTime });

export const getChecklistRunNextPeriod = (checklistId, ignoreLeadTime) =>
  http.post('ChecklistActions/RunNextPeriod', { checklistId, ignoreLeadTime });

export const fetchUserManagement = id => http.post('UserManagement/Get', { id });

export const getScheduleAssignableUsers = params => http.post('ValueList/GetChecklistAssignableUsers', params);

export const setChecklistSupervisor = (scheduleId, params) =>
  http.post(`ChecklistActions/SetSupervisor/${scheduleId}`, params);

export const putChecklist = (dataToSend, params) => http.post(`Checklist/Put/${dataToSend.Id}`, params);

export const putChecklistSchedule = params => http.post('ChecklistSchedule/Put', queryString.stringify(params));

export const removeSchedule = params => http.post('/Checklist/Delete', params);

export const deactivateChecklistActions = (scheduleId, params) =>
  http.post(`ChecklistActions/Deactivate/${scheduleId}`, params);

export const activateChecklistActions = (scheduleId, params) =>
  http.post(`ChecklistActions/Activate/${scheduleId}`, params);

export const getTaskAssignableUsers = params => http.post('ValueList/GetTaskAssignableUsers', params);

export const getScheduleTemplates = params => http.post('ChecklistTaskTemplate/List', params);

export const getItemTemplates = params => http.post('TaskTemplate/List', params);

export const deleteTaskTemplate = ids => http.post('ChecklistTaskTemplate/Delete', { ids });

export const fetchTaskStatus = params => http.post('ChecklistTaskTemplate/GetTaskStatus', params);

export const createTaskTemplate = params => http.post('ChecklistTaskTemplate/Post', params);

export const checklistAssignEmployee = params => http.post('ChecklistTaskTemplateActions/AssignEmployee', params);

export const getSchedulerStatistics = siteId => http.post(`Checklist/GetSchedulerStatistics?siteId=${siteId}`);
