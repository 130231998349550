import React from 'react';
import moment from 'moment';

import * as S from './styled';

export const overrideValues = {
  0: 1,
  1: 7,
  2: 31,
  4: 365,
};

export const initialValues = {
  RepeatCycle: 0,
  RepeatInterval: 1,
  EndDate: null,
  endTime: null,
  startTime: null,
  StartDate: null,
};

export const generateDate = date => {
  // const currentOffset = moment(new Date()).utcOffset() / 60;
  // const modifedDate = new Date(date.setHours(0, 0));

  // if (time) {
  //   return moment(modifedDate)
  //     .add(currentOffset, 'h')
  //     .add(time.getHours(), 'h')
  //     .add(time.getMinutes(), 'm')
  //     .toDate();
  // }

  // return moment(modifedDate).add(currentOffset, 'h');
  const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  const preparedDate = moment.utc(utcDate).startOf('day').toISOString();
  return preparedDate;
};

export const generateEditInitialValues = siteSchedule => {
  let repeat;

  if (siteSchedule.RepeatCycle === 0) {
    repeat = 'Daily';
  }
  if (siteSchedule.RepeatCycle === 1) {
    repeat = 'Weekly';
  }
  if (siteSchedule.RepeatCycle === 2) {
    repeat = 'Monthly';
  }
  if (siteSchedule.RepeatCycle === 4) {
    repeat = 'Yearly';
  }

  return {
    Name: siteSchedule.Name,
    Repeat: siteSchedule.RepeatInterval,
    RepeatCycle: siteSchedule.RepeatCycle,
    RepeatInterval: siteSchedule.RepeatInterval,
    EndDate: new Date(siteSchedule.EndDate),
    endTime: new Date(siteSchedule.EndDate),
    startTime: new Date(siteSchedule.StartDate),
    StartDate: new Date(siteSchedule.StartDate),
    AssignmentType: siteSchedule.AssignmentType - 1,
    ManagementRules: siteSchedule.ManagementRules,
    PersonRules: siteSchedule.PersonRules,
    TeamRules: siteSchedule.TeamRules,
    SiteID: siteSchedule.SiteID,
    repeat,
  };
};

export const ConfirmationDialogContent = () => (
  <>
    <S.ConfirmationText>Start date is sooner than lead time for creating tasks!</S.ConfirmationText>
    <S.ConfirmationText>
      <b>Override the lead time?</b>
    </S.ConfirmationText>
  </>
);

export const optionRequestByType = {
  0: 'sendManagementOptionsRequest',
  1: 'sendTeamOptionsRequest',
  2: 'sendPersonOptionsRequest',
};
