export const formatTriggers = list => {
  const triggersByUserId = {};

  list.forEach(trigger => {
    if (!triggersByUserId[trigger.TriggeredByUserId]) {
      triggersByUserId[trigger.TriggeredByUserId] = {};
    }

    if (!triggersByUserId[trigger.TriggeredByUserId][trigger.EventSourceType]) {
      triggersByUserId[trigger.TriggeredByUserId][trigger.EventSourceType] = [];
    }

    triggersByUserId[trigger.TriggeredByUserId][trigger.EventSourceType].push(trigger);
  });

  const userIds = Object.keys(triggersByUserId);

  return { triggersByUserId, userIds };
};

export const normalizeByUnitName = list => {
  const entities = {};
  const ids = [];

  list.forEach(item => {
    if (item.Id) {
      ids.push(item.Id);
    } else if (item.AssigneeName === 'All Tasks') {
      ids.push(item.TasksTotal);
    }
    if (!entities[item.UnitName]) {
      entities[item.UnitName] = [];
    }

    entities[item.UnitName].push(item);
  });

  const keys = Object.keys(entities);

  return { entities, keys, ids };
};

export const changeAssigneeName = list =>
  list.map(item => {
    if (item.AssigneeName === 'Unassigned') {
      return { ...item, AssigneeName: 'Pick-Up' };
    }

    return item;
  });
