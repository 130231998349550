import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { SiteType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import validate from './validator';
import { CopyIcon } from 'components/Layout/Icons';

import * as S from './styled';

class AddEditForm extends PureComponent {
  static propTypes = {
    changeDistance: PropTypes.func,
    distance: PropTypes.number,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    site: SiteType,
    values: SiteType.isRequired,
    withDelete: PropTypes.bool,
    copyHexToClipboard: PropTypes.func,
    isCopyButtonVisible: PropTypes.bool,
  };
  static defaultProps = {
    changeDistance: () => {},
    distance: 0,
    handleDelete: () => {},
    copyHexToClipboard: () => {},
    site: {
      Address: {
        City: '',
        Country: '',
        State: '',
        Street: '',
        Street2: '',
        ZipCode: '',
      },
      AdministratorEmail: '',
      AdministratorName: '',
      AdministratorPhone: '',
      AdministratorTitle: '',
      BeaconDistance: 0,
      BeaconDistanceItem: 0,
      BeaconDistanceUnit: 0,
      BeaconId: '',
      Id: '',
      Name: '',
      Phone: '',
      AdditionalSiteID: '',
    },
    withDelete: false,
    isCopyButtonVisible: false,
  };

  render() {
    const { copyHexToClipboard, isCopyButtonVisible, values } = this.props;
    return (
      <S.FormContainer>
        <Field component={TextFieldAdapter} floatingLabelText="Name" name="Name" />
        <Field component={TextFieldAdapter} floatingLabelText="Site ID" name="AdditionalSiteID" />
        <Field component={TextFieldAdapter} floatingLabelText="Phone" name="Phone" />
        <S.InputContainer>
          <Field disabled component={TextFieldAdapter} floatingLabelText="Beacon Id" name="BeaconId" />
          <S.CopyButtonIcon
            style={{ display: isCopyButtonVisible ? 'inline-block' : 'none' }}
            onClick={e => {
              e.stopPropagation();
              copyHexToClipboard(values);
            }}
            tooltip="Hex to Clipboard"
          >
            <CopyIcon />
          </S.CopyButtonIcon>
        </S.InputContainer>

        <S.Block>
          <S.Label>Administrator</S.Label>
          <S.InputContainer>
            <Field component={TextFieldAdapter} floatingLabelText="Name" name="AdministratorName" />
            <Field component={TextFieldAdapter} floatingLabelText="Title" name="AdministratorTitle" />
            <Field component={TextFieldAdapter} floatingLabelText="Email" name="AdministratorEmail" />
            <Field component={TextFieldAdapter} floatingLabelText="Phone" name="AdministratorPhone" />
          </S.InputContainer>
        </S.Block>

        <S.Block>
          <S.Label>Address</S.Label>
          <S.InputContainer>
            <Field component={TextFieldAdapter} floatingLabelText="Street" name="Address.Street" />
            <Field component={TextFieldAdapter} floatingLabelText="Street2" name="Address.Street2" />
            <Field component={TextFieldAdapter} floatingLabelText="City" name="Address.City" />
            <Field component={TextFieldAdapter} floatingLabelText="State" name="Address.State" />
            <Field component={TextFieldAdapter} floatingLabelText="Zip Code" name="Address.ZipCode" />
            <Field component={TextFieldAdapter} floatingLabelText="Country" name="Address.Country" />
          </S.InputContainer>
        </S.Block>
      </S.FormContainer>
    );
  }
}

const AddEditWithInput = withInputModal(AddEditForm);

const AddEditDialog = ({ site, changeDistance, distance, copyHexToClipboard, isCopyButtonVisible, ...rest }) => (
  <AddEditWithInput
    {...rest}
    initialValues={site}
    validate={validate}
    changeDistance={changeDistance}
    distance={distance}
    copyHexToClipboard={copyHexToClipboard}
    isCopyButtonVisible={isCopyButtonVisible}
  />
);

AddEditDialog.defaultProps = {
  changeDistance: () => {},
  distance: 0,
  handleDelete: () => {},
  copyHexToClipboard: () => {},
  isCopyButtonVisible: false,
  site: {
    Address: {
      City: '',
      Country: '',
      State: '',
      Street: '',
      Street2: '',
      ZipCode: '',
    },
    AdministratorEmail: '',
    AdministratorName: '',
    AdministratorPhone: '',
    AdministratorTitle: '',
    BeaconDistance: 0,
    BeaconDistanceItem: 0,
    BeaconDistanceUnit: 0,
    BeaconId: '',
    Id: '',
    Name: '',
    Phone: '',
    AdditionalSiteID: '',
  },
  withDelete: false,
};

AddEditDialog.propTypes = {
  changeDistance: PropTypes.func,
  distance: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  site: SiteType,
  withDelete: PropTypes.bool,
  copyHexToClipboard: PropTypes.func,
  isCopyButtonVisible: PropTypes.bool,
};

export default AddEditDialog;
