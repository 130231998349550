const theme = {
  mainRed: '#D50000',
  mainDarkBlue: '#02488F',
  mainLightBlue: '#678FCA',
  mainGreen: '#0a786e',

  breakpoints: [32, 48, 64, 80],
  primaryWhite: '#ffffff',
  lightGrey: '#eeeeee',
  primaryGrey: '#9c9c9c',
  darkGrey: '#666666',
  approxGrey: '#777777',
  primaryDark: '#323232',
  primaryBlack: '#000000',
  primaryYellow: '#F7C800',
  primary: '#00BCD4',
  secondary: '#ff4081',
  primaryRed: '#f00f00',
  primaryGreen: '#60BB78',
  darkRed: '#900900',
  primaryPink: '#ff6464',
  textGray: '#999999',
  primaryHealth: '#f0f0f0',
  primarySites: '#666666',
  primaryLists: '#678fca',
  primaryPeople: '#02488F',
  primarySettings: '#797979',
  primaryScheduler: '#d50000',
  primaryTasks: '#ef6c00',
  primaryTenants: '#795548',
  primaryQueue: '#0097a7',
  primaryDocuments: '#009488',
  primaryHome: '#c2c2c2',
  primaryExchange: '#0407af',
  primaryActivator: '#ececec',
  primaryTenantRequests: '#CC8519',
  textActivator: '#575757',
  headerColor: '#333333',
  modalOkColor: '#00BCD4',
  modalCancelColor: '#ff4081',
  disabledColor: '#d2d2d2',
  fieldsBorderColor: '#c8d8e5',
  tenantNameColor: '#7A7A7A',
  secondaryYellow: '#FFB300',
  requestsNumberColor: '#FCD295',
  loginButtonColor: '#0A786E',
  signUpColor: '#FA7747',
  modalsContentColor: 'rgba(0, 0, 0, 0.87)',
  popoverBackgroundColor: 'rgba(0, 0, 0, 0.7)',
  demoTier: '#FFB3B3',
  tiersColor: '#0265C2',
  upgradeRequestsColor: '#A47449',
  primaryParts: '#678FCA',
  overlay: 'rgba(255,255,255, .4)',
  lightGreen: '#73c0a6',
  lightSteelBlue: '#c8d8e5',
  partDetailsRed: '#d50000',
  partDetailsPink: '#ff7f7f',
  greenButton: '#2b8402',
  lightGreenButton: '#cee9e7',
  redButton: '#d50000',
  primaryBlue: '#02488f',
  secondaryRed: '#FFBFBF',
  headerRed: '#FF4040',
  secondaryGrey: '#3c3c3c',
  cardText: '#575757',
  archiveNameColor: '#DADFE2',
  archiveLineColor: '#8D8D8D',
  darkGreen: '#006064',
  secondaryGreen: '#4295A4',
  lightBlue: '#C8D8E5',
  secondaryOrange: '#DD732C',
  headerBlue: '#708EC5',
  tertiaryOrange: '#FF6500',
  orange: '#DE732C',
  line: '#807F7F',
  lavenderBlush: '#FFF1F2',
};

export const SensorLevelColors = {
  Normal: '#cee9e7',
  Elevated: '#0CB05E',
  Watch: '#ff7800',
  Warning: '#ff4040',
  Alert: '#d50000',
  Disconnect: '#999',
};

export default theme;
