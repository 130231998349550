import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { Link } from 'react-router-dom';

import { ArrowBackIcon } from 'components/Layout/Icons';
import { ButtonFlat } from 'components/Layout/Buttons';
import { LoginButton } from 'components/Buttons/LoginButton/styled';

export const BackButton = styled(ButtonFlat).attrs({
  style: { height: '100%' },
  fullWidth: true,
  label: 'Go to Login',
  type: 'button',
})`
  && {
    justify-content: center;
    align-items: center;
  }
`;

export const BackLink = styled(Link)`
  display: flex;
  justify-content: center;
  min-width: 50%;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
`;

export const ButtonsWrapper = styled(Flex).attrs({
  direction: ['column-reverse', 'row'],
  justify: 'space-between',
})`
  margin-top: 20px;
`;

export const IconArrowBack = styled(ArrowBackIcon)`
  width: 18px !important;
  height: 18px !important;
  margin-left: 0 !important;
`;

export const SendButton = styled(LoginButton)`
  min-width: 50% !important;
  justify-content: center;

  @media only screen and (max-width: 576px) {
    align-self: center;
    margin-bottom: 20px;
  }
`;
