import styled from 'styled-components';
import { CenteredXYGreyContainer } from 'components/Layout/Containers';

export const Container = styled(CenteredXYGreyContainer)`
  min-height: 60px;
`;

export const Text = styled.span`
  font-size: 19px;
  color: ${({ theme }) => theme.darkGrey};
`;
