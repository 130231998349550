import { actions as globalSettingsActions } from 'redux/globalSettings';

const mapStateToProps = ({ globalSettings: { assetCategory, assetTypeList } }) => ({
  assetCategory,
  assetTypeList,
});

const mapDispatchToProps = {
  ...globalSettingsActions,
};

export { mapStateToProps, mapDispatchToProps };
