import styled from 'styled-components';

export const TableContainer = styled.div`
  & th:not(:first-child) {
    padding-left: 10px;
  }

  & td:nth-child(-n + 2),
  th:nth-child(-n + 2) {
    border-right: 1px solid ${({ theme }) => theme.primaryGrey};
  }

  & td:nth-child(-n + 2) {
    background-color: ${({ theme }) => theme.fieldsBorderColor};
  }

  & td:first-child,
  th:first-child {
    padding-left: 10px;
  }
`;

export const GroupName = styled.h2`
  margin: 0;
`;

export const GroupDescription = styled.p`
  color: ${({ theme }) => theme.darkGrey};
  font-weight: bold;
  margin: 0;
`;
