import React from 'react';
import PropTypes from 'prop-types';

import { CommitUnitIcon, ExportCSVIcon, DeleteIcon, PdfIcon } from 'components/Layout/Icons';
import { Container, IconButton } from './styled';

const Controls = ({ onCommit, onExport, onDelete, withCommit, withDelete, withExport, downloadPdf }) => (
  <Container withDelete={withDelete}>
    {withCommit && (
      <IconButton onClick={onCommit} tooltip="Archive Completed Tasks">
        <CommitUnitIcon />
      </IconButton>
    )}
    {withDelete && (
      <IconButton data-tip data-for="version" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    )}
    {withExport && (
      <IconButton onClick={onExport} tooltip="CSV download">
        <ExportCSVIcon />
      </IconButton>
    )}
    {downloadPdf && (
      <IconButton onClick={downloadPdf} tooltip="Get report">
        <PdfIcon />
      </IconButton>
    )}
  </Container>
);

Controls.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  downloadPdf: PropTypes.func,
  withCommit: PropTypes.bool.isRequired,
  withDelete: PropTypes.bool.isRequired,
  withExport: PropTypes.bool.isRequired,
};

Controls.defaultProps = {
  downloadPdf: null,
};

export default Controls;
