import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { actions as authActions } from 'redux/auth';
import { openErrorDialog } from 'redux/errorHandler';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import InputForm from 'components/Forms';
import { emailValidator, newPasswordValidator } from './validator';
import Controls from './Controls';
import { Content, Title } from './styled';

class ForgotPage extends Component {
  static propTypes = {
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    reseted: PropTypes.bool.isRequired,
    requestForgotPassword: PropTypes.func.isRequired,
    requestResetPassword: PropTypes.func.isRequired,
  };

  state = {
    token: queryString.parse(this.props.location.search).token,
    username: queryString.parse(this.props.location.search).userName,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }
  }

  onSendPassword = values => {
    this.props.requestForgotPassword(values.username);
  };

  onSendNewPassword = values => {
    const { username, token } = this.state;
    const params = {
      userName: username,
      token,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    };

    this.props.requestResetPassword(params);
  };

  render() {
    const { loading, reseted } = this.props;
    const { token } = this.state;

    if (reseted) {
      return <Redirect to="/login" />;
    }

    return (
      <Content>
        <Title>Reset password</Title>
        {token ? (
          <InputForm
            controls={<Controls loading={loading} onSubmit={this.onSendNewPassword} title="Set password" />}
            onSubmit={this.onSendNewPassword}
            validate={newPasswordValidator}
          >
            <Field
              component={TextFieldAdapter}
              disabled={loading}
              floatingLabelText="New password"
              name="newPassword"
              type="password"
            />
            <Field
              component={TextFieldAdapter}
              disabled={loading}
              floatingLabelText="Confirm password"
              name="confirmPassword"
              type="password"
            />
          </InputForm>
        ) : (
          <InputForm
            controls={<Controls loading={loading} onSubmit={this.onSendPassword} title="RESET PASSWORD" />}
            onSubmit={this.onSendPassword}
            validate={emailValidator}
          >
            <Field component={TextFieldAdapter} disabled={loading} floatingLabelText="Username" name="username" />
          </InputForm>
        )}
      </Content>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  error: auth.error,
  loading: auth.loading,
  reseted: auth.reseted,
});

const mapDispatchToProps = {
  requestForgotPassword: authActions.forgotPasswordRequest,
  requestResetPassword: authActions.resetPasswordRequest,
  openErrorDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPage);
