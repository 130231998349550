import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as settingsActions } from 'redux/settings';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import withInputModal from 'components/Dialogs/withInputModal';
import LinkIntro from './LinkIntro';
import LinkForm from './LinkForm';
import { ResetButton } from 'components/Dialogs/withInputModal/Controls/styled';
import { RestoreIcon } from 'components/Layout/Icons';
import validate from './validator';
import { transformSystemLinksList } from './helpers';

import * as S from './styled';

class SystemLinks extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    form: PropTypes.shape({
      reset: PropTypes.func.isRequired,
    }).isRequired,
    getChangedValues: PropTypes.func.isRequired,
    getMasterLinks: PropTypes.func.isRequired,
    getMasterSettings: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    resetLinks: PropTypes.func.isRequired,
    systemLinks: PropTypes.array.isRequired,
    values: PropTypes.object,
    userType: PropTypes.number.isRequired,
  };

  static defaultProps = {
    initialValues: {},
    values: {},
  };

  openWarningModal = () => {
    this.props.openConfirmationDialog('This will delete all current data!', () => this.resetLinks(), 'Warning');
  };

  resetLinks = () => {
    const { initialValues, form, getMasterLinks, getMasterSettings, resetLinks } = this.props;

    getMasterLinks();
    getMasterSettings();
    form.reset(initialValues);
    resetLinks();
  };

  render() {
    const { initialValues, form, getChangedValues, systemLinks, values, userType } = this.props;

    return (
      <>
        <S.FormContainer>
          <LinkIntro />
          <S.LinksInstruction>Select visibility to important links</S.LinksInstruction>
          {systemLinks.map((link, index) => {
            const linkValues = Object.keys(initialValues).filter(value => value.startsWith(index));
            return (
              <LinkForm
                key={link.Id}
                linkValues={linkValues}
                linkNumber={index}
                link={link}
                formValues={values}
                resetForm={form.reset}
                getChangedValues={getChangedValues}
              />
            );
          })}
        </S.FormContainer>
        {userType === 4 && (
          <ResetButton type="button" label="Reset to System" position onClick={this.openWarningModal}>
            <RestoreIcon />
          </ResetButton>
        )}
      </>
    );
  }
}

const WrappedSystemLinks = withInputModal(SystemLinks);

const LinksDialog = props => <WrappedSystemLinks validate={validate} {...props} />;

LinksDialog.propTypes = {
  initialValues: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  settings: { list, masterSettings, masterSystemLinks, systemLinks },
  auth: {
    user: { userType },
  },
}) => ({
  initialValues: transformSystemLinksList(
    masterSystemLinks.length ? masterSystemLinks : systemLinks,
    Object.keys(masterSettings).length ? masterSettings.LinkIntroText : list.LinkIntroText,
  ),
  systemLinks,
  userType,
});

const mapDispatchToProps = {
  getMasterLinks: settingsActions.getMasterSettingsLinksForAdminRequest,
  getMasterSettings: settingsActions.getMasterSettingsForAdminRequest,
  openConfirmationDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinksDialog);
