const initialState = {
  error: '',
  isLoading: false,
  periodsList: [],
  partsList: [],
  partGroupsList: [],
  partTransactionsList: [],
  parts: [],
  partGroups: [],
  dashboardInfo: {
    PartsInUse: 0,
    PartsInInventory: 0,
    PartsRequested: 0,
    PartsBackordered: 0,
    UsersRequesting: 0,
    InventoryReleaseOrders: 0,
  },
};

export const activateTenantInventory = state => ({
  ...state,
  isLoading: true,
});

export const setInventoryError = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const setIsLoading = (state, { payload }) => ({
  ...state,
  isLoading: payload,
});

export const getInventoryPeriodsListRequest = state => ({
  ...state,
  isLoading: true,
});

export const getInventoryPeriodsListSuccess = (state, { payload }) => ({
  ...state,
  periodsList: payload,
  isLoading: false,
});

export const createInventoryPeriodRequest = state => ({
  ...state,
  isLoading: true,
});

export const getInventoryPartsListRequest = state => ({
  ...state,
  isLoading: true,
});

export const getInventoryPartsListSuccess = (state, { payload }) => ({
  ...state,
  partsList: payload,
  isLoading: false,
});

export const getInventoryPartGroupsListRequest = state => ({
  ...state,
  isLoading: true,
});

export const getInventoryPartGroupsListSuccess = (state, { payload }) => ({
  ...state,
  partGroupsList: payload,
  isLoading: false,
});

export const createInventoryEntitiesRequest = state => ({
  ...state,
  isLoading: true,
});

export const createPartTransactionRequest = state => ({
  ...state,
  isLoading: true,
});

export const fetchPartTransactionsListRequest = state => ({
  ...state,
  isLoading: true,
});

export const fetchPartTransactionsListSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  partTransactionsList: payload,
});

export const getPartsByParamRequest = state => ({
  ...state,
  isLoading: true,
});

export const getPartsByParamSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  parts: payload,
});

export const getPartGroupsByParamRequest = state => ({
  ...state,
  isLoading: true,
});

export const getPartGroupsByParamSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  partGroups: payload,
});

export const editInventoryPartRequest = state => ({
  ...state,
  isLoading: true,
});

export const editInventoryPartGroupRequest = state => ({
  ...state,
  isLoading: true,
});

export const deleteInventoryEntityRequest = state => ({
  ...state,
  isLoading: true,
});

export const getInventoryDashboardInfoRequest = state => ({
  ...state,
  isLoading: true,
});

export const getInventoryDashboardInfoSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  dashboardInfo: payload,
});

export default initialState;
