import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/reducers';
import { useHistory, useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import { AddIcon } from 'components/Layout/Icons';
import TableList from 'components/TableList';
import { Subheader } from 'components/v10/Subheader/Subheader';

import { InventoryPartType, PartGroupsType, TransactionType } from 'types/inventory';
import { actions as inventoryActions } from 'redux/inventory';
import { convertDateToFourDigit, findSite, getHourFromDate, searchItemByKeyword } from 'helpers';

import { TransactionsDialog } from 'modules/siteparts/components/dialogs/TransactionsDialog'; // fix

import { TABLE_CONFIGS } from './configs';
import * as S from './styled';

const Transactions = () => {
  const { id, entityId, type } = useParams<Record<'id' | 'entityId' | 'type', string>>();
  const [searchValue, setSearchValue] = useState('');
  const partTransactionsList: TransactionType[] = useAppSelector(state => state.inventory.partTransactionsList);
  const partsList: InventoryPartType[] = useAppSelector(state => state.inventory.partsList);
  const partGroupsList: PartGroupsType[] = useAppSelector(state => state.inventory.partGroupsList);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sitesAvailableToUserFullInfo = useAppSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const selectedSite = useAppSelector(state => state.auth.selectedSite);

  const isParts = type === 'parts';
  const data = isParts ? partsList : partGroupsList;
  const currentEntity = [...data]?.find(d => d.Id === entityId) || {}; // FIXME

  const history = useHistory();
  const dispatch = useDispatch();

  const name = isParts ? 'Part' : 'Group';
  const entityType = isParts ? 'Part' : 'PartGroup';

  const siteName = findSite(sitesAvailableToUserFullInfo, selectedSite);

  const goBack = () => history.goBack();

  const renderLeftControllCell = (item: TransactionType) => (
    <S.DataCell type={item.Type}>
      <S.Item>{item.Type}</S.Item>
    </S.DataCell>
  );

  const onRowClick = (item: TransactionType) => {
    NiceModal.show(TransactionsDialog, { currentEntity, type, transaction: item });
  };

  const mapList = (item: TransactionType) => ({
    ...item,
    Date: convertDateToFourDigit(item.CreatedOn),
    Time: getHourFromDate(item.CreatedOn),
  });

  const leftButtons = [
    {
      icon: <S.Back />,
      handler: goBack,
      hint: 'Back',
    },
  ];

  const rightButtons = [
    {
      icon: <AddIcon />,
      handler: () => NiceModal.show(TransactionsDialog, { currentEntity, type }),
      hint: 'Add period part',
    },
  ];

  useEffect(() => {
    dispatch(inventoryActions.fetchPartTransactionsListRequest({ inventoryEntityId: entityId, entityType }));
  }, [dispatch, entityId, entityType]);

  useEffect(() => {
    if (isParts) {
      dispatch(inventoryActions.getInventoryPartsListRequest(id));
    } else {
      dispatch(inventoryActions.getInventoryPartGroupsListRequest(id));
    }
  }, [dispatch, id, isParts]);

  return (
    <>
      <Subheader
        leftButtons={leftButtons}
        rightButtons={rightButtons}
        isSearch
        leftContent={
          <S.List>
            <S.ListItem>
              <b>{name} Transactions</b>
            </S.ListItem>
            <S.SiteName>{siteName}</S.SiteName>
            {isParts && (
              <S.ListItem>
                <b>My {name} ID:</b> {(currentEntity as InventoryPartType).MyPartID}
              </S.ListItem>
            )}
            <S.ListItem>
              <b>My {name} Name:</b>{' '}
              {isParts
                ? (currentEntity as InventoryPartType).MyPartName
                : (currentEntity as PartGroupsType).MyGroupName}
            </S.ListItem>
          </S.List>
        }
        inputProps={{
          placeholder: 'Inventory Part Search',
          inputStyles: S.inputStyles,
          meta: {},
          value: searchValue,
          onChange: (e: any) => setSearchValue(e.currentTarget.value),
        }}
      />
      {partTransactionsList.length ? (
        <S.TableContainer>
          <TableList
            list={partTransactionsList.map(mapList).filter(searchItemByKeyword(TABLE_CONFIGS, searchValue))}
            tableColumns={TABLE_CONFIGS}
            tableHeads={TABLE_CONFIGS}
            renderLeftControllCell={renderLeftControllCell}
            renderLeftControllTableHead={() => (
              <S.Header>
                <S.HeaderText>Type</S.HeaderText>
              </S.Header>
            )}
            onRowClick={onRowClick}
          />
        </S.TableContainer>
      ) : (
        <S.EmptyList>
          <p>There are no data.</p>
        </S.EmptyList>
      )}
    </>
  );
};

export default Transactions;
