import React from 'react';

import { Group } from 'modules/globalsettings/pages/styled';

import * as S from './styled';

const fields = [
  { field: 'Name', title: '', StyledComponent: S.GroupItem },
  { field: 'Notes', title: 'Notes:', StyledComponent: S.GroupItem },
  { field: 'IndustryName', title: 'Industry:', StyledComponent: S.GroupItem },
  { field: 'TiersCount', title: 'Tiers:', StyledComponent: S.TiersCount },
];

const Item = group => (
  <Group>
    {fields.map(({ field, title, StyledComponent }) => (
      <StyledComponent key={field}>
        {title} {group[field]}
      </StyledComponent>
    ))}
  </Group>
);

export default Item;
