import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

import { ReactComponent as ElectricBoltIcon } from 'components/Layout/Images/bolt.svg';

const TitleStyle = {
  color: '#d50000',
};

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const ItemInfo = styled(Box).attrs({})`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 5px;
  box-sizing: border-box;
  font-size: 12px;
  font-style: italic;
  height: auto !important;
`;

const Status = styled.div`
  padding-right: 15px;
`;

const ListContainer = styled(Flex).attrs({
  column: true,
  flex: '1',
})`
  background-color: ${({ theme }) => theme.lightGrey};
  overflow-y: auto;
`;

const ElectricBolt = styled(ElectricBoltIcon)`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.darkGrey};
`;

const Tooltip = styled(ReactTooltip)`
  background-color: ${({ theme }) => theme.primaryGrey} !important;
  text-align: center;
  word-break: keep-all;
  z-index: 1000;

  &.__react_component_tooltip::before,
  &.__react_component_tooltip::after {
    content: none !important;
  }
`;

const TooltipContent = styled.div`
  padding: 0 20px;
`;

export { TitleStyle, ItemInfo, Status, ListContainer, ToggleContainer, ElectricBolt, Tooltip, TooltipContent };
