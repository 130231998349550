/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import SiteSelector from 'components/SiteSelector';
import SiteSelectionDialog from 'components/Dialogs/SiteSelectionDialog';

import * as S from './styled';

export const Dashboard = ({ topContent, modules, attentionModuleBadge, attentionModule, showTaskCount }) => {
  const [isSiteSelectionDialogOpen, setIsSiteSelectionDialogOpen] = useState(false);
  const tenantName = useSelector(state => state.auth.user.tenantName);
  const userType = useSelector(state => state.auth.user.userType);

  const openSiteSelectionDialog = () => setIsSiteSelectionDialogOpen(true);

  const closeSiteSelectionDialog = () => setIsSiteSelectionDialogOpen(false);

  const disableClick = event => event.preventDefault();

  return (
    <S.Container>
      <S.DashboardContainer>
        <S.Header>{tenantName}</S.Header>
        <S.ColumnContainer>
          <SiteSelector onClick={openSiteSelectionDialog} />
          <SiteSelectionDialog isOpen={isSiteSelectionDialogOpen} onClose={closeSiteSelectionDialog} />
          {topContent}
          {Object.values(modules[userType]).map(module => (
            <S.LinkWrapper
              key={module.name}
              to={module.path}
              onClick={module.isDisableClick ? disableClick : null}
              data-tip
              data-for={module.name}
            >
              <S.ModulePaper color={module.color}>
                {module.name === attentionModule && attentionModuleBadge}
                <S.ModuleIconContainer
                  backgroundColor={module.iconBackgroundColor}
                  color={module.iconColor || module.color}
                >
                  <module.icon />
                </S.ModuleIconContainer>
                <S.ModuleLabel>{module.name}</S.ModuleLabel>
                {showTaskCount && (
                  <>
                    <S.Tooltip id="tooltip" place="bottom" effect="solid">
                      {module.tooltip}
                    </S.Tooltip>
                    <S.TotalTaskCountContainer data-tip data-for="tooltip">
                      {module.taskCount}
                    </S.TotalTaskCountContainer>
                  </>
                )}
              </S.ModulePaper>
              {module.primaryTooltip && (
                <S.PrimaryTooltip id={module.name} place="right" effect="solid">
                  {module.primaryTooltip}
                </S.PrimaryTooltip>
              )}
            </S.LinkWrapper>
          ))}
        </S.ColumnContainer>
      </S.DashboardContainer>
    </S.Container>
  );
};

Dashboard.defaultProps = {
  attentionModule: '',
  showTaskCount: false,
};
