import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const ManagerName = styled.span`
  display: inline-block;
  padding: 20px 0 0 25px;
  font-weight: 300;
`;

const ViewDescription = styled.span`
  display: inline-block;
  font-size: 12px;
  padding: 20px 0 0 25px;
`;

const RedText = styled.span`
  color: ${({ theme }) => theme.primaryRed};
`;

const List = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
`;

const Unit = styled.div`
  padding-left: 15px;
`;

const UnitTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  padding: 0 0 0 10px;
`;

const Items = styled.ul`
  list-style: none;
`;

const Item = styled.li`
  display: flex;
  cursor: pointer;
  padding: 5px;
  align-items: center;

  span {
    &:first-child {
      display: inline-block;
      padding-right: 10px;
    }

    &:last-child {
      display: inline-block;
      padding-left: 10px;
      flex: 1;
    }
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const EmptyTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const EmptyText = styled.p`
  color: ${({ theme }) => theme.primaryGrey};
  font-size: 20px;
`;

const Link = styled(NavLink)`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export {
  Title,
  ManagerName,
  ViewDescription,
  RedText,
  List,
  Unit,
  UnitTitle,
  Items,
  Item,
  ProgressContainer,
  EmptyTextContainer,
  EmptyText,
  Link,
};
