import React from 'react';
import PropTypes from 'prop-types';

import { RetrieveIcon, CopyIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

const ConnectionField = ({ onRetrieve, onCopy, connectionString, isCopied, gateway }) => (
  <S.ConnectionContainer>
    <S.ConnectionButtonContainer>
      <ButtonIcon disabled={!gateway} onClick={onRetrieve}>
        <S.ConnectionButtonsContainer>
          <RetrieveIcon />
        </S.ConnectionButtonsContainer>
      </ButtonIcon>
      <S.ConnectionButtonsLabels>Retrieve</S.ConnectionButtonsLabels>
    </S.ConnectionButtonContainer>
    <S.ConnectionString value={connectionString} disabled />
    <S.ConnectionButtonContainer>
      <ButtonIcon disabled={!connectionString} onClick={onCopy}>
        <S.ConnectionButtonsContainer>
          <CopyIcon />
        </S.ConnectionButtonsContainer>
      </ButtonIcon>
      <S.ConnectionButtonsLabels isCopied={isCopied}>{isCopied ? 'Copied' : 'Copy'}</S.ConnectionButtonsLabels>
    </S.ConnectionButtonContainer>
  </S.ConnectionContainer>
);

ConnectionField.propTypes = {
  connectionString: PropTypes.string.isRequired,
  isCopied: PropTypes.bool.isRequired,
  gateway: PropTypes.string.isRequired,
  onRetrieve: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
};

export default ConnectionField;
