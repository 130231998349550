import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';

import theme from 'theme';

import BaseDialog from 'components/Dialogs/Base';

import * as S from './styled';

const BlockDialog = ({ open, onClose, note, onNoteChange, onBlock }) => (
  <BaseDialog open={open} onRequestClose={onClose} title="Alert" titleColor={theme.primaryRed}>
    <S.DialogContainer>
      <S.DialogText>
        Are you sure you want to block?
        <br />
        You can write a note or just block the tenant request
      </S.DialogText>
      <TextField fullWidth value={note} onChange={onNoteChange} placeholder="Note" />
    </S.DialogContainer>
    <S.DialogControlsContainer>
      <S.DialogControlButton type="button" onClick={onClose}>
        Cancel
      </S.DialogControlButton>
      <S.DialogControlButton type="button" isApprove onClick={onBlock}>
        Block and send notes
      </S.DialogControlButton>
    </S.DialogControlsContainer>
  </BaseDialog>
);

BlockDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  note: PropTypes.string.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
};

export default BlockDialog;
