import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';

import AddEditDeleteIndustryDialog from '../AddEditDeleteIndustryDialog';
import { TenantType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import validate from './validator';
import { Block, FormContainer, Label, InputContainer } from './styled';

const AddEditTenantForm = ({ form, industries, tiers, enabledIsOEM, onIndustryChange }) => (
  <FormContainer>
    <Field component={TextFieldAdapter} floatingLabelText="Name" name="Name" maxLength="255" />
    <Field component={TextFieldAdapter} floatingLabelText="Notes" name="Notes" maxLength="255" />
    <Field component={TextFieldAdapter} floatingLabelText="Location" name="Location" maxLength="255" />
    <Field
      component={SelectFieldAdapter}
      floatingLabelText="Industry"
      name="IndustryId"
      disabled={false}
      onChangeControl={value => {
        form.change('TierId', '');
        onIndustryChange(value);
      }}
    >
      {industries.map(industry => (
        <MenuItem primaryText={industry.Name} value={industry.Id} key={industry.Id} />
      ))}
    </Field>
    <Field component={SelectFieldAdapter} floatingLabelText="Tier" name="TierId" disabled={!tiers.length}>
      {tiers.map(tier => (
        <MenuItem primaryText={tier.Name} value={tier.Id} key={tier.Id} />
      ))}
    </Field>
    <Block>
      <Label>Administrator</Label>
      <InputContainer>
        <Field component={TextFieldAdapter} floatingLabelText="Username" name="DefaultAdminName" maxLength="255" />
        <Field
          component={TextFieldAdapter}
          floatingLabelText="First Name"
          name="DefaultAdminFirstName"
          maxLength="255"
        />
        <Field component={TextFieldAdapter} floatingLabelText="Last Name" name="DefaultAdminLastName" maxLength="255" />
        <Field
          component={TextFieldAdapter}
          floatingLabelText="Password"
          name="DefaultAdminPassword"
          type="password"
          maxLength="16"
        />
      </InputContainer>
    </Block>
    <Field component={CheckboxAdapter} label="Allow on List Exchange" name="AllowSubmitToListExchange" />
    <Field component={CheckboxAdapter} label="Tenant for OEMs" name="IsOEM" disabled={!enabledIsOEM} />
  </FormContainer>
);

const AddEditTenantWithInput = withInputModal(AddEditTenantForm);

AddEditTenantForm.propTypes = {
  industries: PropTypes.arrayOf(PropTypes.object).isRequired,
  tiers: PropTypes.arrayOf(PropTypes.object).isRequired,
  enabledIsOEM: PropTypes.bool.isRequired,
  onIndustryChange: PropTypes.func.isRequired,
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
  }).isRequired,
};

const AddEditTenantDialog = ({
  tenant,
  dataIndustry,
  tiers,
  handleAddIndustry,
  handleDeleteIndustry,
  handleEditIndustry,
  openIndustryDialog,
  onItemClick,
  onCloseDialog,
  enableField,
  onEnableEditIndustry,
  handleChange,
  newIndustryName,
  editIndustryName,
  enabledIsOEM,
  ...rest
}) => [
  <AddEditTenantWithInput
    {...rest}
    key="Add Edit Tenant With Input"
    initialValues={tenant}
    validate={validate}
    industries={dataIndustry}
    onItemClick={onItemClick}
    enabledIsOEM={enabledIsOEM}
    tiers={tiers}
  />,
  <AddEditDeleteIndustryDialog
    key="Add edit delete industry dialog"
    handleClose={onCloseDialog}
    handleAddIndustry={handleAddIndustry}
    handleDeleteIndustry={handleDeleteIndustry}
    handleEditIndustry={handleEditIndustry}
    enableField={enableField}
    onEnableEditIndustry={onEnableEditIndustry}
    open={openIndustryDialog}
    title="Edit Industry"
    industries={dataIndustry}
    handleChange={handleChange}
    newIndustryName={newIndustryName}
    editIndustryName={editIndustryName}
    onSubmit={() => {}}
    withSubmit={false}
  />,
];

AddEditTenantDialog.defaultProps = {
  handleDelete: () => {},
  tenant: {},
  withDelete: false,
  enabledIsOEM: true,
};

AddEditTenantDialog.propTypes = {
  tiers: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataIndustry: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tenant: TenantType,
  title: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
  enabledIsOEM: PropTypes.bool,
};

export default AddEditTenantDialog;
