import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_ITEMS_WITH_ALERT_REQUEST: undefined,
  GET_ITEMS_WITH_ALERT_SUCCESS: undefined,
  GET_ITEMS_WITH_ALERT_FAILURE: undefined,

  GET_FAVORITE_SITES_REQUEST: undefined,
  GET_FAVORITE_SITES_SUCCESS: undefined,
  GET_FAVORITE_SITES_FAILURE: undefined,

  SET_FAVORITE_SITES_REQUEST: undefined,
  SET_FAVORITE_SITES_SUCCESS: undefined,
  SET_FAVORITE_SITES_FAILURE: undefined,

  GET_ALERT_TRENDS_REQUEST: undefined,
  GET_ALERT_TRENDS_SUCCESS: undefined,
  GET_ALERT_TRENDS_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getItemsWithAlertRequest, handlers.getItemsWithAlertRequest],
    [actions.getItemsWithAlertSuccess, handlers.getItemsWithAlertSuccess],
    [actions.getItemsWithAlertFailure, handlers.getItemsWithAlertFailure],

    [actions.setFavoriteSitesRequest, handlers.setFavoriteSitesRequest],
    [actions.setFavoriteSitesSuccess, handlers.setFavoriteSitesSuccess],
    [actions.setFavoriteSitesFailure, handlers.setFavoriteSitesFailure],

    [actions.getFavoriteSitesRequest, handlers.getFavoriteSitesRequest],
    [actions.getFavoriteSitesSuccess, handlers.getFavoriteSitesSuccess],
    [actions.getFavoriteSitesFailure, handlers.getFavoriteSitesFailure],

    [actions.getAlertTrendsRequest, handlers.getAlertTrendsRequest],
    [actions.getAlertTrendsSuccess, handlers.getAlertTrendsSuccess],
    [actions.getAlertTrendsFailure, handlers.getAlertTrendsFailure],
  ]),
  initialState,
);

export default reducer;
