export const validator = values => {
  const errors = {};

  if (!values.UserName) {
    errors.UserName = 'Required';
  }
  if (!values.FirstName) {
    errors.FirstName = 'Required';
  }
  if (!values.LastName) {
    errors.LastName = 'Required';
  }
  if (!values.Email) {
    errors.Email = 'Required';
  } else if (!/[\w_-]+@[\w_\-.]+\.([A-Za-z]{2,10})$/.test(values.Email.trim())) {
    errors.Email = 'Please enter a valid email address';
  }

  return errors;
};

export default validator;
