import http from '../index';

export const getPeopleList = () => http.post('/UserManagement/List');

export const createPerson = params => http.post('UserManagement/Post', params);

export const removePerson = (personId, type) =>
  http.post('UserManagement/Delete', `id=${personId}&additionalAction=${type}`);

export const editUser = params => http.post('UserManagement/Put', params);

export const setNotificationStatus = (selectedSite, notificationStatusUsers) =>
  http.post(`UserManagement/SetNotificationStatus/${selectedSite}`, { notificationStatusUsers });

export const getAssignedSites = userId => http.post('SiteUserManagement/GetUserSites', `userId=${userId}`);

export const editUserSites = dto => http.post('SiteUserManagement/EditUserSites', dto);

export const sendLoginEmail = dto => http.post('Account/SendLoginEmail', dto);
