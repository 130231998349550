import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';

import theme from 'theme';

import BaseDialog from 'components/Dialogs/Base';

import * as S from './styled';

const NoteDialog = ({ open, onClose, note, onNoteChange, onSaveNote }) => (
  <BaseDialog open={open} onRequestClose={onClose} title="Note" titleColor={theme.signUpColor}>
    <S.DialogContainer>
      <TextField fullWidth value={note} onChange={onNoteChange} placeholder="Note" />
    </S.DialogContainer>
    <S.DialogControlsContainer>
      <S.DialogControlButton type="button" onClick={onClose}>
        Cancel
      </S.DialogControlButton>
      <S.DialogControlButton type="button" isApprove onClick={onSaveNote}>
        Enter
      </S.DialogControlButton>
    </S.DialogControlsContainer>
  </BaseDialog>
);

NoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  note: PropTypes.string.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveNote: PropTypes.func.isRequired,
};

export default NoteDialog;
