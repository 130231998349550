import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import BaseDialog from 'components/Dialogs/Base';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import { TextInput } from 'components/Input';
import { actions as partsActions } from 'redux/parts';
import QRCode from 'components/Layout/Images/qrcode.png';
import * as S from './styled';
import { useModal } from '@ebay/nice-modal-react';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';

const PART_INFO = [
  { label: 'My Part ID', name: 'MyPartID' },
  { label: 'Part Name', name: 'MyPartName' },
  { label: 'Part Category', name: 'MyPartCategory' },
  { label: 'Part Serial Number', name: 'MyPartSerialNumber' },
  { label: 'Part Unit Of Measure', name: 'MyPartUnitOfMeasure' },
  { label: 'Part Price (w/o tax or fees)', name: 'MyPartTotalPrice' },
];

export const PartManagementDialog = ({ isOpen, handleClose, id, type }) => {
  const currentPart = useSelector(state => state.parts.currentPart);
  const dispatch = useDispatch();
  const confirmationModal = useModal(ConfirmationModal);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: currentPart,
    onSubmit: values => {
      dispatch(partsActions.editPartRequest(values));
      dispatch(partsActions.getPartsRequest());
    },
  });

  const deletePart = () => {
    dispatch(partsActions.deletePartRequest(id));
    dispatch(partsActions.getPartsRequest());
    confirmationModal.hide();
    handleClose();
  };

  const openWarningModal = () =>
    confirmationModal.show({
      title: 'Warning',
      text: (
        <S.Text>
          Are you sure you want to delete? <br />{' '}
          <b>
            This part is Actively linked! <br /> All data will be lost.
          </b>
        </S.Text>
      ),
      label: 'Cancel',
      labelCancel: 'Delete Part',
      onClick: confirmationModal.hide,
      onCancelClick: deletePart,
      isNext: true,
      maxWidth: '650px',
    });

  useEffect(() => {
    if (type === 'groups') {
      dispatch(partsActions.getPartsByGroupIdRequest({ groupId: id }));
    } else {
      dispatch(partsActions.getPartByIdRequest({ partId: id }));
    }
  }, [dispatch, id, type]);

  return (
    <BaseDialog
      open={isOpen}
      onRequestClose={handleClose}
      title="Edit Basic Part Info"
      bodyStyle={{ display: 'flex', flex: 1, padding: 0 }}
    >
      <S.RootContainer onSubmit={formik.handleSubmit}>
        <BorderRadiusContainer>
          <S.Label>My Part Info</S.Label>
          <S.Container>
            <S.InputContainer>
              {PART_INFO.map(({ label, name }, index) => (
                <TextInput
                  key={index}
                  meta={formik.getFieldMeta(name)}
                  {...formik.getFieldProps(name)}
                  label={label}
                  name={name}
                  withFloatingLabel
                  inputStyles={S.inputStyles}
                  inputWrapperStyles={S.inputWrapperStyles}
                />
              ))}
            </S.InputContainer>
            <div>
              <S.Image src={QRCode} alt="qr-code" />
              <S.Wrapper>
                <p>view all detail</p>
                <S.LinkIcon />
              </S.Wrapper>
            </div>
          </S.Container>
        </BorderRadiusContainer>

        <BorderRadiusContainer>
          <S.SecondaryLabel>
            <span>Part Photo’s</span>
            <S.Edit />
          </S.SecondaryLabel>
        </BorderRadiusContainer>
        <ActionsContainer paddingValue={16} gapValue={20}>
          <ActionButton label="Delete Part" type="button" labelFontSize="18px" onClick={openWarningModal} />
          <ActionButton label="Save" type="submit" labelFontSize="18px" isNext isGreenLabel />
        </ActionsContainer>
      </S.RootContainer>
    </BaseDialog>
  );
};

PartManagementDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
