import styled from 'styled-components';
import { InputBlockLabel } from 'components/Layout/Containers';
import { CheckIcon, DeleteIcon, EditIcon } from 'components/Layout/Icons';
import { ButtonFlat } from 'components/Layout/Buttons';

export const DialogContainer = styled.div`
  padding: 40px 16px 0;
  text-align: center;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
  padding: 16px;
  text-align: left;
`;

export const FormContainer = styled.div`
  margin: 0 16px;
`;

export const Edit = styled(EditIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const Delete = styled(DeleteIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const Check = styled(CheckIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const ActionButton = styled(ButtonFlat)`
  width: 100% !important;
  background-color: ${({ theme }) => theme.lightGreenButton} !important;
  background-color: ${({ theme, isDisable }) => isDisable && theme.lightGrey} !important;
  border-radius: 6px !important;

  span {
    color: ${({ theme }) => theme.greenButton} !important;
    color: ${({ theme, isDisable }) => isDisable && theme.textGray} !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TextFieldContainer = styled.div`
  flex: 1;
  padding: 16px;
  padding-right: 0;
`;

export const TitleContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin-bottom: 50px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding-top: 16px;
`;
