import { call, put, takeEvery } from 'redux-saga/effects';

import {
  bindMeeteringAndIndustry,
  createMeeteringGroup,
  deleteMeeteringGroup,
  editMeeteringGroup,
  fetchGroup,
  fetchGroups,
} from 'http/meteringGroups';
import { formatServerError } from 'helpers';

import { actions as tenantsActions } from '../tenants';
import { actions } from './index';

function* getGroups() {
  try {
    const { data } = yield call(fetchGroups);
    yield put(actions.getGroupsSuccess(data.root));
  } catch (error) {
    yield put(actions.getGroupsFailure(formatServerError(error, 'An error occurred while fetching metering groups.')));
  }
}

function* getGroup(payload) {
  try {
    const { data } = yield call(fetchGroup, payload);
    yield put(actions.getGroupSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.getGroupFailure(formatServerError(error, 'An error occurred while fetching the metering group.')),
    );
  }
}

function* addGroup({ payload }) {
  const { Name, Notes, cb } = payload;
  try {
    const { data } = yield call(createMeeteringGroup, Name, Notes);
    yield put(actions.addGroupSuccess());
    yield* getGroups();
    if (cb) {
      cb(data.entity.Id);
    }
  } catch (error) {
    yield put(actions.addGroupFailure(formatServerError(error, 'An error occurred while adding a metering group.')));
  }
}

function* editGroup({ payload }) {
  const { Id, Name, Notes, cb } = payload;
  try {
    yield call(editMeeteringGroup, Id, Name, Notes);
    yield put(actions.editGroupSuccess());
    yield* getGroups();
    if (cb) {
      cb(null);
    }
  } catch (error) {
    yield put(actions.editGroupFailure(formatServerError(error, 'An error occurred while editing a metering group.')));
  }
}

function* addIndustryToGroup({ payload }) {
  try {
    yield call(bindMeeteringAndIndustry, payload);
    yield put(actions.addIndustryToGroupSuccess());
    yield* getGroups();
    yield put(tenantsActions.tenantIndustryListRequest());
  } catch (error) {
    yield put(
      actions.addIndustryToGroupFailure(
        formatServerError(error, 'An error occurred while adding an industry to a metering group.'),
      ),
    );
  }
}

function* deleteGroup({ payload }) {
  try {
    yield call(deleteMeeteringGroup, payload);
    yield put(actions.deleteGroupSuccess());
    yield* getGroups();
  } catch (error) {
    yield put(
      actions.deleteGroupFailure(formatServerError(error, 'An error occurred while deleting a metering group.')),
    );
  }
}

const meteringGroupsSagas = [
  takeEvery(actions.getGroupsRequest, getGroups),
  takeEvery(actions.getGroupRequest, getGroup),
  takeEvery(actions.addGroupRequest, addGroup),
  takeEvery(actions.editGroupRequest, editGroup),
  takeEvery(actions.addIndustryToGroupRequest, addIndustryToGroup),
  takeEvery(actions.deleteGroupRequest, deleteGroup),
];

export default meteringGroupsSagas;
