import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import * as S from 'components/Dialogs/v1/Base/Confirmation/styled';
import theme from 'theme';

type PropsType = {
  onClick?: () => void;
  onCancelClick?: () => void;
  handleClose?: () => void;
  title?: string;
  label?: string;
  labelCancel?: string;
  text: string | React.ReactNode;
  headingText: string;
  descriptionText: string;
  titleColor?: string;
  width?: string;
  maxWidth?: string;
  zIndex?: number;
  isColumn?: boolean;
  withBorder?: boolean;
  isNext?: boolean;
  isCancelNext?: boolean;
  isGreenLabel?: boolean;
  centeredTitle?: string;
  noCapitalize?: boolean;
  labelFontSize?: string;
};

const ConfirmationModal = NiceModal.create(
  ({
    title = 'Alert',
    label = 'Yes',
    labelCancel = 'Previous',
    isNext = false,
    isGreenLabel = false,
    isCancelNext = false,
    titleColor = theme.mainRed,
    onClick,
    onCancelClick,
    handleClose,
    text,
    labelFontSize,
    width = '30%',
    isColumn = false,
    withBorder = false,
    maxWidth = '350px',
    headingText = '',
    zIndex = 1500,
    descriptionText = '',
    centeredTitle = '',
    noCapitalize = false,
  }: PropsType) => {
    const modal = useModal();

    return (
      <Dialog
        open={modal.visible}
        onRequestClose={handleClose || modal.hide}
        title={title}
        titleColor={titleColor}
        width={width}
        maxWidth={maxWidth}
        zIndex={zIndex}
        noCapitalize={noCapitalize}
        centeredTitle={centeredTitle}
      >
        <S.DialogContainer>
          {text}
          {headingText ? <S.ConfirmationText>{headingText}</S.ConfirmationText> : null}
          {descriptionText ? <S.ConfirmationWarning>{descriptionText}</S.ConfirmationWarning> : null}
        </S.DialogContainer>
        <ActionsContainer>
          <S.Container isColumn={isColumn}>
            {onCancelClick && (
              <ActionButton
                withBorder={withBorder}
                label={labelCancel}
                onClick={onCancelClick}
                labelFontSize={labelFontSize}
                // isNext={isNext}
                isCancelNext={isCancelNext}
              />
            )}
            {onClick && (
              <ActionButton
                label={label}
                onClick={onClick}
                isNext={isNext}
                isGreenLabel={isGreenLabel}
                labelFontSize={labelFontSize}
              />
            )}
          </S.Container>
        </ActionsContainer>
      </Dialog>
    );
  },
);

export default ConfirmationModal;
