import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_SENSORS_REQUEST: undefined,
  GET_SENSORS_SUCCESS: undefined,
  GET_SENSORS_FAILURE: undefined,

  EDIT_SENSOR_REQUEST: undefined,
  EDIT_SENSOR_SUCCESS: undefined,
  EDIT_SENSOR_FAILURE: undefined,

  GET_SENSORS_FOR_SITE_REQUEST: undefined,
  GET_SENSORS_FOR_SITE_SUCCESS: undefined,
  GET_SENSORS_FOR_SITE_FAILURE: undefined,

  GET_ITEM_SENSORS_REQUEST: undefined,
  GET_ITEM_SENSORS_SUCCESS: undefined,
  GET_ITEM_SENSORS_FAILURE: undefined,

  GET_SENSOR_TYPES_REQUEST: undefined,
  GET_SENSOR_TYPES_SUCCESS: undefined,
  GET_SENSOR_TYPES_FAILURE: undefined,

  SET_SENSOR_TYPE_REQUEST: undefined,
  SET_SENSOR_TYPE_SUCCESS: undefined,
  SET_SENSOR_TYPE_FAILURE: undefined,

  DELETE_SENSOR_TYPE_REQUEST: undefined,
  DELETE_SENSOR_TYPE_SUCCESS: undefined,
  DELETE_SENSOR_TYPE_FAILURE: undefined,

  GET_SENSOR_LEVELS_SETTINGS_REQUEST: undefined,
  GET_SENSOR_LEVELS_SETTINGS_SUCCESS: undefined,
  GET_SENSOR_LEVELS_SETTINGS_FAILURE: undefined,

  SET_SENSOR_LEVELS_SETTINGS_REQUEST: undefined,
  SET_SENSOR_LEVELS_SETTINGS_SUCCESS: undefined,
  SET_SENSOR_LEVELS_SETTINGS_FAILURE: undefined,

  GET_LEVELS_FOR_ITEM_SENSOR_REQUEST: undefined,
  GET_LEVELS_FOR_ITEM_SENSOR_SUCCESS: undefined,
  GET_LEVELS_FOR_ITEM_SENSOR_FAILURE: undefined,

  SET_LEVELS_FOR_ITEM_SENSOR_REQUEST: undefined,
  SET_LEVELS_FOR_ITEM_SENSOR_SUCCESS: undefined,
  SET_LEVELS_FOR_ITEM_SENSOR_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getSensorsRequest, handlers.getSensorsRequest],
    [actions.getSensorsSuccess, handlers.getSensorsSuccess],
    [actions.getSensorsFailure, handlers.getSensorsFailure],

    [actions.editSensorRequest, handlers.editSensorRequest],
    [actions.editSensorSuccess, handlers.editSensorSuccess],
    [actions.editSensorFailure, handlers.editSensorFailure],

    [actions.getSensorsForSiteRequest, handlers.getSensorsForSiteRequest],
    [actions.getSensorsForSiteSuccess, handlers.getSensorsForSiteSuccess],
    [actions.getSensorsForSiteFailure, handlers.getSensorsForSiteFailure],

    [actions.getItemSensorsRequest, handlers.getItemSensorsRequest],
    [actions.getItemSensorsSuccess, handlers.getItemSensorsSuccess],
    [actions.getItemSensorsFailure, handlers.getItemSensorsFailure],

    [actions.getSensorTypesRequest, handlers.getSensorTypesRequest],
    [actions.getSensorTypesSuccess, handlers.getSensorTypesSuccess],
    [actions.getSensorTypesFailure, handlers.getSensorTypesFailure],

    [actions.setSensorTypeRequest, handlers.setSensorTypeRequest],
    [actions.setSensorTypeSuccess, handlers.setSensorTypeSuccess],
    [actions.setSensorTypeFailure, handlers.setSensorTypeFailure],

    [actions.deleteSensorTypeRequest, handlers.deleteSensorTypeRequest],
    [actions.deleteSensorTypeSuccess, handlers.deleteSensorTypeSuccess],
    [actions.deleteSensorTypeFailure, handlers.deleteSensorTypeFailure],

    [actions.getSensorLevelsSettingsRequest, handlers.getSensorLevelsSettingsRequest],
    [actions.getSensorLevelsSettingsSuccess, handlers.getSensorLevelsSettingsSuccess],
    [actions.getSensorLevelsSettingsFailure, handlers.getSensorLevelsSettingsFailure],

    [actions.setSensorLevelsSettingsRequest, handlers.setSensorLevelsSettingsRequest],
    [actions.setSensorLevelsSettingsSuccess, handlers.setSensorLevelsSettingsSuccess],
    [actions.setSensorLevelsSettingsFailure, handlers.setSensorLevelsSettingsFailure],

    [actions.getLevelsForItemSensorRequest, handlers.getLevelsForItemSensorRequest],
    [actions.getLevelsForItemSensorSuccess, handlers.getLevelsForItemSensorSuccess],
    [actions.getLevelsForItemSensorFailure, handlers.getLevelsForItemSensorFailure],

    [actions.setLevelsForItemSensorRequest, handlers.setLevelsForItemSensorRequest],
    [actions.setLevelsForItemSensorSuccess, handlers.setLevelsForItemSensorSuccess],
    [actions.setLevelsForItemSensorFailure, handlers.setLevelsForItemSensorFailure],
  ]),
  initialState,
);

export default reducer;
