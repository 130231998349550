import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  COMMIT_UNIT_REQUEST: undefined,
  COMMIT_UNIT_SUCCESS: undefined,
  COMMIT_UNIT_FAILURE: undefined,

  FETCH_ASSIGNED_TASKS_REQUEST: undefined,
  FETCH_ASSIGNED_TASKS_SUCCESS: undefined,
  FETCH_ASSIGNED_TASKS_FAILURE: undefined,

  RESET_ASSIGNED_TASKS: undefined,

  FETCH_QUEUE_LIST_REQUEST: undefined,
  FETCH_QUEUE_LIST_SUCCESS: undefined,
  FETCH_QUEUE_LIST_FAILURE: undefined,

  FETCH_TRIGGERS_QUEUE_REQUEST: undefined,
  FETCH_TRIGGERS_QUEUE_SUCCESS: undefined,
  FETCH_TRIGGERS_QUEUE_FAILURE: undefined,

  FETCH_ATTENTION_TASKS_REQUEST: undefined,
  FETCH_ATTENTION_TASKS_SUCCESS: undefined,
  FETCH_ATTENTION_TASKS_FAILURE: undefined,

  FETCH_SITE_STATISTICS_REQUEST: undefined,
  FETCH_SITE_STATISTICS_SUCCESS: undefined,
  FETCH_SITE_STATISTICS_FAILURE: undefined,

  FETCH_REPORTS_LIST_REQUEST: undefined,
  FETCH_REPORTS_LIST_SUCCESS: undefined,
  FETCH_REPORTS_LIST_FAILURE: undefined,

  CREATE_REPORT_REQUEST: undefined,
  CREATE_REPORT_SUCCESS: undefined,
  CREATE_REPORT_FAILURE: undefined,

  EDIT_REPORT_REQUEST: undefined,
  EDIT_REPORT_SUCCESS: undefined,
  EDIT_REPORT_FAILURE: undefined,

  DELETE_REPORT_REQUEST: undefined,
  DELETE_REPORT_SUCCESS: undefined,
  DELETE_REPORT_FAILURE: undefined,

  FETCH_REPORT_PREVIEW_REQUEST: undefined,
  FETCH_REPORT_PREVIEW_SUCCESS: undefined,
  FETCH_REPORT_PREVIEW_FAILURE: undefined,

  FETCH_REPORT_REQUEST: undefined,
  FETCH_REPORT_SUCCESS: undefined,
  FETCH_REPORT_FAILURE: undefined,

  GENERATE_AND_DOWNLOAD_REPORT_FILE_REQUEST: undefined,
  GENERATE_AND_DOWNLOAD_REPORT_FILE_SUCCESS: undefined,
  GENERATE_AND_DOWNLOAD_REPORT_FILE_FAILURE: undefined,

  DOWNLOAD_REPORT_FILE_REQUEST: undefined,
  DOWNLOAD_REPORT_FILE_SUCCESS: undefined,
  DOWNLOAD_REPORT_FILE_FAILURE: undefined,

  FETCH_LIST_FILE_REPORTS_REQUEST: undefined,
  FETCH_LIST_FILE_REPORTS_SUCCESS: undefined,
  FETCH_LIST_FILE_REPORTS_FAILURE: undefined,

  DELETE_REPORT_FILE_REQUEST: undefined,
  DELETE_REPORT_FILE_SUCCESS: undefined,
  DELETE_REPORT_FILE_FAILURE: undefined,

  STATE_ERROR_CLEAR: undefined,
  SET_TOGGLE_TYPE: undefined,
  SET_SELECTED_TASKS_INFO: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.commitUnitRequest, handlers.commitUnitRequest],
    [actions.commitUnitSuccess, handlers.commitUnitSuccess],
    [actions.commitUnitFailure, handlers.commitUnitFailure],

    [actions.fetchAssignedTasksRequest, handlers.fetchAssignedTasksRequest],
    [actions.fetchAssignedTasksSuccess, handlers.fetchAssignedTasksSuccess],
    [actions.fetchAssignedTasksFailure, handlers.fetchAssignedTasksFailure],

    [actions.resetAssignedTasks, handlers.resetAssignedTasks],

    [actions.fetchQueueListRequest, handlers.fetchQueueListRequest],
    [actions.fetchQueueListSuccess, handlers.fetchQueueListSuccess],
    [actions.fetchQueueListFailure, handlers.fetchQueueListFailure],

    [actions.fetchTriggersQueueRequest, handlers.fetchTriggersQueueRequest],
    [actions.fetchTriggersQueueSuccess, handlers.fetchTriggersQueueSuccess],
    [actions.fetchTriggersQueueFailure, handlers.fetchTriggersQueueFailure],

    [actions.fetchAttentionTasksRequest, handlers.fetchAttentionTasksRequest],
    [actions.fetchAttentionTasksSuccess, handlers.fetchAttentionTasksSuccess],
    [actions.fetchAttentionTasksFailure, handlers.fetchAttentionTasksFailure],

    [actions.fetchSiteStatisticsRequest, handlers.fetchSiteStatisticsRequest],
    [actions.fetchSiteStatisticsSuccess, handlers.fetchSiteStatisticsSuccess],
    [actions.fetchSiteStatisticsFailure, handlers.fetchSiteStatisticsFailure],

    [actions.fetchReportsListRequest, handlers.fetchReportsListRequest],
    [actions.fetchReportsListSuccess, handlers.fetchReportsListSuccess],
    [actions.fetchReportsListFailure, handlers.fetchReportsListFailure],

    [actions.createReportRequest, handlers.createReportRequest],
    [actions.createReportSuccess, handlers.createReportSuccess],
    [actions.createReportFailure, handlers.createReportFailure],

    [actions.editReportRequest, handlers.editReportRequest],
    [actions.editReportSuccess, handlers.editReportSuccess],
    [actions.editReportFailure, handlers.editReportFailure],

    [actions.deleteReportRequest, handlers.deleteReportRequest],
    [actions.deleteReportSuccess, handlers.deleteReportSuccess],
    [actions.deleteReportFailure, handlers.deleteReportFailure],

    [actions.fetchReportPreviewRequest, handlers.fetchReportPreviewRequest],
    [actions.fetchReportPreviewSuccess, handlers.fetchReportPreviewSuccess],
    [actions.fetchReportPreviewFailure, handlers.fetchReportPreviewFailure],

    [actions.fetchReportRequest, handlers.fetchReportRequest],
    [actions.fetchReportSuccess, handlers.fetchReportSuccess],
    [actions.fetchReportFailure, handlers.fetchReportFailure],

    [actions.generateAndDownloadReportFileRequest, handlers.generateAndDownloadReportFileRequest],
    [actions.generateAndDownloadReportFileSuccess, handlers.generateAndDownloadReportFileSuccess],
    [actions.generateAndDownloadReportFileFailure, handlers.generateAndDownloadReportFileFailure],

    [actions.downloadReportFileRequest, handlers.downloadReportFileRequest],
    [actions.downloadReportFileFailure, handlers.downloadReportFileFailure],

    [actions.fetchListFileReportsRequest, handlers.fetchListFileReportsRequest],
    [actions.fetchListFileReportsSuccess, handlers.fetchListFileReportsSuccess],
    [actions.fetchListFileReportsFailure, handlers.fetchListFileReportsFailure],

    [actions.deleteReportFileRequest, handlers.deleteReportFileRequest],
    [actions.deleteReportFileSuccess, handlers.deleteReportFileSuccess],
    [actions.deleteReportFileFailure, handlers.deleteReportFileFailure],

    [actions.stateErrorClear, handlers.stateErrorClear],
    [actions.setToggleType, handlers.setToggleType],
    [actions.setSelectedTasksInfo, handlers.setSelectedTasksInfo],
  ]),
  initialState,
);

export default reducer;
