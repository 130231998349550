import http from '../index';

const BASE_PART_OPTIONS_URL = 'PartOptions/';

export const fetchOptions = url => http.post(`${BASE_PART_OPTIONS_URL}${url.list}`);

export const fetchSingleOptionsType = (urlTypes, payload) =>
  http.post(`${BASE_PART_OPTIONS_URL}${urlTypes[payload].list}`);

export const createPartOption = (urlTypes, optionType, values, valuesType) =>
  http.post(`${BASE_PART_OPTIONS_URL}${urlTypes[optionType].create}`, { [valuesType[optionType]]: values });

export const changePartOption = (urlTypes, optionType, values, valuesType) =>
  http.post(`${BASE_PART_OPTIONS_URL}${urlTypes[optionType].edit}`, { [valuesType[optionType]]: values });

export const removePartOption = (urlTypes, optionType, values) =>
  http.post(`${BASE_PART_OPTIONS_URL}${urlTypes[optionType].delete}`, values);
