import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { AddIcon, BackIcon } from 'components/Layout/Icons';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import Subheader from 'components/Subheader';
import TableList from 'components/TableList';
import EmptyList from 'components/EmptyList';
import { searchItemByKeyword } from 'helpers';
import { AssetCategoryType, AssetType } from 'configs/propTypes';

import AddEditAssetsDialog from './AddEditAssetsDialog';
import { mapDispatchToProps, mapStateToProps } from './index.props';
import { tableColumns } from './configs';

import * as S from './styled';

const AssetCategories = ({
  history,
  assetCategoryList,
  assetCategory,
  assetTypeList,
  actions: {
    fetchAssetCategoryListRequest,
    fetchAssetTypeListRequest,
    fetchAssetCategoryRequest,
    createAssetCategoryRequest,
    updateAssetCategoryRequest,
    deleteAssetCategoryRequest,
  },
}) => {
  const [search, setSearch] = useState('');
  const [isAddAssetCategoryModalOpened, setIsAddAssetCategoryModalOpened] = useState(false);
  const [isEditAssetCategoryModalOpened, setIsEditAssetCategoryModalOpened] = useState(false);
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);

  const onSearch = e => setSearch(e.currentTarget.value);

  const goBack = () => history.push('/globalsettings');

  const openAddAssetModal = () => setIsAddAssetCategoryModalOpened(true);

  const closeAddAssetModal = () => setIsAddAssetCategoryModalOpened(false);

  const openEditAssetModal = () => setIsEditAssetCategoryModalOpened(true);

  const closeEditAssetModal = () => setIsEditAssetCategoryModalOpened(false);

  const openConfirmationModel = () => setIsConfirmationModelOpen(true);

  const closeConfirmationModel = () => setIsConfirmationModelOpen(false);

  const onRowClick = item => {
    openEditAssetModal();
    fetchAssetTypeListRequest(item.Id);
    fetchAssetCategoryRequest(item.Id);
  };

  const addNewAssetCategory = values => {
    const payload = {
      name: values.Name,
      description: values.Description,
    };

    createAssetCategoryRequest(payload);
    closeAddAssetModal();
  };

  const editAssetCategory = values => {
    const payload = {
      name: values.Name,
      description: values.Description,
      id: values.Id,
    };

    updateAssetCategoryRequest(payload);
    closeEditAssetModal();
  };

  const deleteAssetCategory = () => {
    deleteAssetCategoryRequest(assetCategory.Id);
    closeEditAssetModal();
    closeConfirmationModel();
  };

  const rightButtons = [
    {
      icon: <AddIcon />,
      handler: openAddAssetModal,
      hint: 'Add asset categories',
    },
  ];

  const leftButtons = [
    {
      icon: <BackIcon />,
      handler: goBack,
      hint: 'Back',
    },
  ];

  useEffect(() => {
    fetchAssetCategoryListRequest();
  }, [fetchAssetCategoryListRequest]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <>
      <Subheader
        leftButtons={leftButtons}
        rightButtons={rightButtons}
        title="Asset Categories"
        hintText="Filter Categories"
        isSearch
        searchData={search}
        isSiteOnLeftSide
        searchInList={onSearch}
      />
      {assetCategoryList?.length ? (
        <S.TableContainer>
          <TableList
            list={assetCategoryList.filter(searchItemByKeyword(tableColumns, search))}
            tableColumns={tableColumns}
            onRowClick={onRowClick}
            isPointer
          />
        </S.TableContainer>
      ) : (
        <EmptyList text="There are no assets" />
      )}
      <AddEditAssetsDialog
        key="New Assets Record"
        title="New Assets Record"
        open={isAddAssetCategoryModalOpened}
        handleClose={closeAddAssetModal}
        isNewSubmitButton
        withSubmit={false}
        withoutTopBorder
        onSubmit={addNewAssetCategory}
        hasPadding
        modalStyles={{ maxWidth: '650px' }}
      />
      <AddEditAssetsDialog
        key="Edit Assets Record"
        title="Edit Assets Record"
        open={isEditAssetCategoryModalOpened}
        handleClose={closeEditAssetModal}
        isNewSubmitButton
        isNewDeleteButton
        withSubmit={false}
        withoutTopBorder
        onSubmit={editAssetCategory}
        handleDelete={openConfirmationModel}
        hasPadding
        gapValue="20px"
        isEditModal
        modalStyles={{ maxWidth: '650px' }}
      />
      <ConfirmationDialog
        open={isConfirmationModelOpen}
        handleClose={closeConfirmationModel}
        onClick={deleteAssetCategory}
        onCancelClick={closeConfirmationModel}
        text={
          <S.ConfirmationTextContainer>
            <h3>Warning</h3>
            All {assetTypeList.length} <b>Types</b> will be replaced with:
            <p>&quot;Undefined Type&quot;</p>
          </S.ConfirmationTextContainer>
        }
        maxWidth="80%"
        isCancelNext
        label="Replace"
        labelCancel="Cancel/Return"
      />
    </>
  );
};

AssetCategories.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  assetCategoryList: PropTypes.arrayOf(AssetCategoryType).isRequired,
  assetCategory: AssetCategoryType.isRequired,
  assetTypeList: PropTypes.arrayOf(AssetType).isRequired,
  actions: PropTypes.shape({
    fetchAssetCategoryListRequest: PropTypes.func.isRequired,
    fetchAssetTypeListRequest: PropTypes.func.isRequired,
    fetchAssetCategoryRequest: PropTypes.func.isRequired,
    updateAssetCategoryRequest: PropTypes.func.isRequired,
    deleteAssetCategoryRequest: PropTypes.func.isRequired,
    createAssetCategoryRequest: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetCategories));
