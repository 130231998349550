import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ADD_TEAM_MEMBERS_REQUEST: undefined,
  ADD_TEAM_MEMBERS_SUCCESS: undefined,
  ADD_TEAM_MEMBERS_FAILURE: undefined,

  GET_MEMBERS_FOR_ASSIGMENT_REQUEST: undefined,
  GET_MEMBERS_FOR_ASSIGMENT_SUCCESS: undefined,
  GET_MEMBERS_FOR_ASSIGMENT_FAILURE: undefined,

  GET_TEAM_MEMBERS_REQUEST: undefined,
  GET_TEAM_MEMBERS_SUCCESS: undefined,
  GET_TEAM_MEMBERS_FAILURE: undefined,

  GET_TEAMS_REQUEST: undefined,
  GET_TEAMS_SUCCESS: undefined,
  GET_TEAMS_FAILURE: undefined,

  CREATE_TEAM_REQUEST: undefined,
  CREATE_TEAM_SUCCESS: undefined,
  CREATE_TEAM_FAILURE: undefined,

  UPDATE_TEAM_REQUEST: undefined,
  UPDATE_TEAM_SUCCESS: undefined,
  UPDATE_TEAM_FAILURE: undefined,

  DELETE_TEAM_REQUEST: undefined,
  DELETE_TEAM_SUCCESS: undefined,
  DELETE_TEAM_FAILURE: undefined,

  DELETE_TEAM_MEMBER_REQUEST: undefined,
  DELETE_TEAM_MEMBER_SUCCESS: undefined,
  DELETE_TEAM_MEMBER_FAILURE: undefined,

  GET_SITE_MANAGERS_REQUEST: undefined,
  GET_SITE_MANAGERS_SUCCESS: undefined,
  GET_SITE_MANAGERS_FAILURE: undefined,

  GET_TEAM_LIST_BY_SITE_REQUEST: undefined,
  GET_TEAM_LIST_BY_SITE_SUCCESS: undefined,
  GET_TEAM_LIST_BY_SITE_FAILURE: undefined,

  GET_FILTERED_TEAM_MEMBERS_REQUEST: undefined,
  GET_FILTERED_TEAM_MEMBERS_SUCCESS: undefined,
  GET_FILTERED_TEAM_MEMBERS_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.addTeamMembersRequest, handlers.addTeamMembersRequest],
    [actions.addTeamMembersSuccess, handlers.addTeamMembersSuccess],
    [actions.addTeamMembersFailure, handlers.addTeamMembersFailure],

    [actions.getMembersForAssigmentRequest, handlers.getMembersForAssigmentRequest],
    [actions.getMembersForAssigmentSuccess, handlers.getMembersForAssigmentSuccess],
    [actions.getMembersForAssigmentFailure, handlers.getMembersForAssigmentFailure],

    [actions.getTeamMembersRequest, handlers.getTeamMembersRequest],
    [actions.getTeamMembersSuccess, handlers.getTeamMembersSuccess],
    [actions.getTeamMembersFailure, handlers.getTeamMembersFailure],

    [actions.getTeamsRequest, handlers.getTeamsRequest],
    [actions.getTeamsSuccess, handlers.getTeamsSuccess],
    [actions.getTeamsFailure, handlers.getTeamsFailure],

    [actions.getSiteManagersRequest, handlers.getSiteManagersRequest],
    [actions.getSiteManagersSuccess, handlers.getSiteManagersSuccess],
    [actions.getSiteManagersFailure, handlers.getSiteManagersFailure],

    [actions.createTeamRequest, handlers.createTeamRequest],
    [actions.createTeamSuccess, handlers.createTeamSuccess],
    [actions.createTeamFailure, handlers.createTeamFailure],

    [actions.updateTeamRequest, handlers.updateTeamRequest],
    [actions.updateTeamSuccess, handlers.updateTeamSuccess],
    [actions.updateTeamFailure, handlers.updateTeamFailure],

    [actions.deleteTeamRequest, handlers.deleteTeamRequest],
    [actions.deleteTeamSuccess, handlers.deleteTeamSuccess],
    [actions.deleteTeamFailure, handlers.deleteTeamFailure],

    [actions.deleteTeamMemberRequest, handlers.deleteTeamMemberRequest],
    [actions.deleteMemberTeamSuccess, handlers.deleteTeamMemberSuccess],
    [actions.deleteTeamMemberFailure, handlers.deleteTeamMemberFailure],

    [actions.getTeamListBySiteRequest, handlers.getTeamListBySiteRequest],
    [actions.getTeamListBySiteSuccess, handlers.getTeamListBySiteSuccess],
    [actions.getTeamListBySiteFailure, handlers.getTeamListBySiteFailure],

    [actions.getFilteredTeamMembersRequest, handlers.getFilteredTeamMembersRequest],
    [actions.getFilteredTeamMembersSuccess, handlers.getFilteredTeamMembersSuccess],
    [actions.getFilteredTeamMembersFailure, handlers.getFilteredTeamMembersFailure],
  ]),
  initialState,
);

export default reducer;
