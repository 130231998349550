import React from 'react';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

const overrideValues = {
  0: 1,
  1: 7,
  2: 31,
  4: 365,
};

const accessWarning =
  'Changes to this activated schedule are not possible, please, ' +
  'create a new schedule to add tasks or add additional days to the tasks.';

const getItemName = item => item.RuleType;

const getItemInstruction = item => item.TaskTemplateName;

const renderRightControls = (item, checked, onDelete, props) => (
  <S.ControlsBlock>
    <ButtonIcon data-tip data-for="version" onClick={() => onDelete(props, item.Id, checked)}>
      <S.DeleteIconColored />
    </ButtonIcon>
    <S.Tooltip id="version" place="bottom" effect="solid">
      <div>Delete</div>
    </S.Tooltip>
  </S.ControlsBlock>
);

const getInitialDates = scheduleDto => {
  let dates = {};
  if (scheduleDto.ScheduleType === 'RecurringDaily') {
    dates = {
      startDate: scheduleDto.ScheduleData.DailyStartDate,
      endDate: scheduleDto.ScheduleData.DailyRepeatUntil,
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringWeekly') {
    dates = {
      startDate: scheduleDto.ScheduleData.WeeklyStartDate,
      endDate: scheduleDto.ScheduleData.WeeklyRepeatUntil,
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringMonthly') {
    dates = {
      startDate: scheduleDto.ScheduleData.MonthlyStartDate,
      endDate: scheduleDto.ScheduleData.MonthlyRepeatUntil,
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringYearly') {
    dates = {
      startDate: scheduleDto.ScheduleData.YearlyStartDate,
      endDate: scheduleDto.ScheduleData.YearlyRepeatUntil,
    };
  }

  if (scheduleDto.ScheduleType === 'OneTime') {
    dates = {
      startDate: scheduleDto.ScheduleData.ScheduledDate,
      endDate: null,
    };
  }

  return dates;
};

export { overrideValues, accessWarning, getItemName, getItemInstruction, renderRightControls, getInitialDates };
