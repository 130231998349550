import styled from 'styled-components';

const RootContainer = styled.div`
  text-align: center;
  font-size: 1.2rem;
  padding: 1rem;
  min-width: 40vw;
`;

const List = styled.ul`
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export { RootContainer, List };
