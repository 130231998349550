import styled from 'styled-components';

const TableContainer = styled.div`
  th,
  td {
    padding: 0 24px !important;
  }

  th,
  td:not(:last-child) {
    max-width: 20vw;
  }

  & td:nth-child(1) {
    color: ${({ theme }) => theme.mainRed};
  }

  & td:last-child {
    text-align: center;
    width: 48px;
  }
`;

const ConfirmationTextContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 20px;

  & > p {
    color: ${({ theme }) => theme.mainRed};
  }
`;

export { TableContainer, ConfirmationTextContainer };
