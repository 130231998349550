import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import { TeamType, SiteUserManagerType } from 'configs/propTypes';
import { HelpIcon } from 'components/Layout/Icons';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import RadioGroup from 'components/Forms/RadioButtonGroup/TeamRadioGroup';
import validate from './validator';
import * as S from './styled';

import theme from 'theme';

const AddEditForm = ({ peopleList, values }) => (
  <S.FormContainer>
    <S.FieldBlock>
      <Field component={TextFieldAdapter} floatingLabelText="Team Name" name="Name" />
      <Field component={RadioGroup} name="IsActive" style={S.radioStyle} />
      <S.IconButton tooltip={'selecting Inactive will eliminate this team from scheduling'}>
        <HelpIcon />
      </S.IconButton>
    </S.FieldBlock>
    <S.Block>
      <S.Label>Team Lead</S.Label>
      <S.InputContainer>
        <Field component={SelectFieldAdapter} floatingLabelText="Manager Name" name="ManagerID">
          {peopleList
            .filter(
              ({ Status, UserId, IsHidden }) => !IsHidden && Status === 'Active' && UserId !== values?.AssistantID,
            )
            .map(item => (
              <MenuItem key={item.Id} primaryText={item.UserName} value={item.UserId} />
            ))}
          {values?.Manager?.Status === 'Unknown' && (
            <MenuItem key={values?.Manager?.Id} primaryText={values?.Manager?.Name} value={values?.Manager?.Id} />
          )}
        </Field>
        <Field component={SelectFieldAdapter} floatingLabelText="Assistant Name" name="AssistantID">
          <MenuItem key="default" primaryText={null} value={null} />
          {peopleList
            .filter(({ Status, UserId, IsHidden }) => !IsHidden && Status === 'Active' && UserId !== values?.ManagerID)
            .map(item => (
              <MenuItem key={item.Id} primaryText={item.UserName} value={item.UserId} />
            ))}
          {values?.Assistant?.Status === 'Unknown' && (
            <MenuItem key={values?.Assistant?.Id} primaryText={values?.Assistant?.Name} value={values?.Assistant?.Id} />
          )}
        </Field>
      </S.InputContainer>
    </S.Block>

    <S.Block>
      <S.Label>Team Info (Optional)</S.Label>
      <S.InputContainer>
        <Field component={TextFieldAdapter} floatingLabelText="Team Location" name="Location" />
        <Field component={TextFieldAdapter} floatingLabelText="Team Notes" name="Notes" />
      </S.InputContainer>
    </S.Block>
  </S.FormContainer>
);

const onSubmitForm = ({ values, onSubmit, handleClose, teamToEdit }) => {
  if (!teamToEdit) {
    const valuesToAdd = {
      ...values,
      IsActive: values.IsActive === undefined ? true : !!values.IsActive,
    };
    onSubmit(valuesToAdd);
  } else {
    const valuesToEdit = {
      ...values,
      IsActive: !!values.IsActive,
    };
    if (values.Assistant === null) {
      delete valuesToEdit.Assistant;
    }
    onSubmit(valuesToEdit);
  }
  handleClose();
};

const onDeleteClick = ({ e, onDelete }) => {
  e.preventDefault();
  onDelete();
};

const AddEditDialog = ({ handleClose, open, title, onSubmit, onDelete, peopleList, teamToEdit }) => (
  <Dialog open={open} onRequestClose={handleClose} title={title} titleColor={theme.primaryBlue}>
    <Form
      onSubmit={values => onSubmitForm({ values, onSubmit, handleClose, teamToEdit })}
      initialValues={
        teamToEdit
          ? {
              ...teamToEdit,
              IsActive: teamToEdit.IsActive ? 1 : 0,
            }
          : null
      }
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <AddEditForm peopleList={peopleList} values={values} />
          <S.StyledActionsContainer>
            <ActionsContainer>
              {title !== 'Team Assignments' && teamToEdit && (
                <ActionButton type="test" onClick={e => onDeleteClick({ e, onDelete })} label="Delete" />
              )}
              <ActionButton type="submit" label="Save" isNext />
            </ActionsContainer>
          </S.StyledActionsContainer>
        </form>
      )}
    />
  </Dialog>
);

AddEditForm.propTypes = {
  peopleList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  values: PropTypes.object.isRequired,
};

AddEditDialog.defaultProps = {
  teamToEdit: {
    ManagerID: '',
    AssistantID: '',
    Notes: '',
    Location: '',
    Name: '',
    IsActive: 1,
  },
};

AddEditDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  teamToEdit: TeamType,
  peopleList: PropTypes.arrayOf(SiteUserManagerType).isRequired,
};

export default AddEditDialog;
