import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_GROUPS_REQUEST: undefined,
  GET_GROUPS_SUCCESS: undefined,
  GET_GROUPS_FAILURE: undefined,
  GET_GROUP_REQUEST: undefined,
  GET_GROUP_SUCCESS: undefined,
  GET_GROUP_FAILURE: undefined,
  ADD_GROUP_REQUEST: undefined,
  ADD_GROUP_SUCCESS: undefined,
  ADD_GROUP_FAILURE: undefined,
  EDIT_GROUP_REQUEST: undefined,
  EDIT_GROUP_SUCCESS: undefined,
  EDIT_GROUP_FAILURE: undefined,
  ADD_INDUSTRY_TO_GROUP_REQUEST: undefined,
  ADD_INDUSTRY_TO_GROUP_SUCCESS: undefined,
  ADD_INDUSTRY_TO_GROUP_FAILURE: undefined,
  DELETE_GROUP_REQUEST: undefined,
  DELETE_GROUP_SUCCESS: undefined,
  DELETE_GROUP_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getGroupsRequest, handlers.getGroupsRequest],
    [actions.getGroupsSuccess, handlers.getGroupsSuccess],
    [actions.getGroupsFailure, handlers.getGroupsFailure],
    [actions.getGroupRequest, handlers.getGroupRequest],
    [actions.getGroupSuccess, handlers.getGroupSuccess],
    [actions.getGroupFailure, handlers.getGroupFailure],
    [actions.addGroupRequest, handlers.addGroupRequest],
    [actions.addGroupSuccess, handlers.addGroupSuccess],
    [actions.addGroupFailure, handlers.addGroupFailure],
    [actions.editGroupRequest, handlers.editGroupRequest],
    [actions.editGroupSuccess, handlers.editGroupSuccess],
    [actions.editGroupFailure, handlers.editGroupFailure],
    [actions.addIndustryToGroupRequest, handlers.addIndustryToGroupRequest],
    [actions.addIndustryToGroupSuccess, handlers.addIndustryToGroupSuccess],
    [actions.addIndustryToGroupFailure, handlers.addIndustryToGroupFailure],
    [actions.deleteGroupRequest, handlers.deleteGroupRequest],
    [actions.deleteGroupSuccess, handlers.deleteGroupSuccess],
    [actions.deleteGroupFailure, handlers.deleteGroupFailure],
  ]),
  initialState,
);

export default reducer;
