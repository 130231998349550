import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserType } from 'configs/propTypes';
import { actions as sitesActions } from 'redux/sites';
import { getAssignedList } from 'redux/sites/selectors';
import Dialog from 'components/Dialogs/Base';
import SimpleList from 'components/SimpleList';

const renderItemContent = item => `${item.Name} (${item.UserType})`;

class AssignDialog extends PureComponent {
  static propTypes = {
    assignSite: PropTypes.func.isRequired,
    fetchAssigned: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(UserType).isRequired,
    open: PropTypes.bool.isRequired,
    siteIdToAssign: PropTypes.string.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.siteIdToAssign !== this.props.siteIdToAssign) {
      this.props.fetchAssigned({ siteId: nextProps.siteIdToAssign });
    }
  }

  onItemClick = user => {
    const { assignSite, siteIdToAssign } = this.props;

    assignSite({ siteId: siteIdToAssign, userIds: [user.Id], mode: user.checked ? 'Delete' : 'Post' });
  };

  render() {
    const { handleClose, list, open } = this.props;

    return (
      <Dialog onRequestClose={handleClose} defaultCancel defaultCancelText="Close" title="Assign Users" open={open}>
        <SimpleList data={list} onItemClick={this.onItemClick} renderItemContent={renderItemContent} withCheckbox />
      </Dialog>
    );
  }
}

const mapStateToProps = ({ people, sites }) => ({
  list: getAssignedList(people, sites),
});

const mapDispatchToProps = {
  assignSite: sitesActions.assignSiteRequest,
  fetchAssigned: sitesActions.assignedUsersRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignDialog);
