import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import Archive from './pages/Archive';
import Overview from './pages/Overview';
import Trigger from './pages/Trigger';
import UnitDetail from './pages/UnitDetail';
import QueueDashboard from './pages/Dashboard';
import ReportPreview from './pages/Archive/ReportPreview';

const Queue = () => (
  <Switch>
    <Route exact path="/queue" component={QueueDashboard} />
    <Route exact path="/queue/export" component={Archive} />
    <Route exact path="/queue/export/:reportId" component={ReportPreview} />
    <Route exact path="/queue/trigger/:date" component={Trigger} />
    <Route exact path="/queue/:date/:period" component={Overview} />
    <Route exact path="/queue/:date/:period/:unitId/category/:category/:assigneeId" component={UnitDetail} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Queue;
