import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { RadioButton } from 'material-ui';

import { ActionButton, ActionsContainer, Dialog } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';

import theme from 'theme';
import { UnitType } from 'types/unit';

import * as S from './styled';
import { isEqual } from 'lodash';

type PropsType = {
  isLoading: boolean;
  onChange: (name: string, value: string) => void;
  unitsList: UnitType[];
  unitId: string;
};

const SelectListTemplate = NiceModal.create(({ unitsList, unitId, onChange }: PropsType) => {
  const [search, setSearch] = useState('');
  const [filteredUnitsList, setFilteredUnitsList] = useState<UnitType[]>(unitsList);
  const [selectedUnitId, setSelectedUnitId] = useState(unitId);

  const modal = useModal();

  const onSearch = (list: UnitType[], searchData: string) => {
    setSearch(searchData);
    setFilteredUnitsList(list);
  };

  const onUnitChange = (e: ChangeEvent, newValue: string) => setSelectedUnitId(newValue);

  const onListTemplateSelect = () => {
    const unit = unitsList.find(({ Id }) => Id === selectedUnitId) || ({} as UnitType);
    onChange('UnitName', unit.Name);
    onChange('UnitId', selectedUnitId);
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      title="Select List Template"
      noCapitalize
      onRequestClose={modal.hide}
      centeredTitle={null}
      maxWidth="40%"
      zIndex={2000}
      titleColor={theme.mainLightBlue}
    >
      <S.DialogContainer>
        <S.SearchContainer>
          <SearchField
            list={unitsList}
            filterBy={['Name']}
            searchData={search}
            placeholder="Filter List Templates"
            setSearchedItems={onSearch}
          />
        </S.SearchContainer>
        <S.GroupRadioButton name="units" valueSelected={selectedUnitId} onChange={onUnitChange}>
          {filteredUnitsList.map(({ Id, Name }) => (
            <RadioButton
              key={Id}
              value={Id}
              label={Name}
              iconStyle={{ fill: Id === selectedUnitId ? theme.primaryScheduler : theme.darkGrey }}
              style={S.radioButtonStyle}
            />
          ))}
        </S.GroupRadioButton>
        <ActionsContainer>
          <ActionButton
            type="button"
            label="Select"
            isNext
            labelFontSize="20px"
            onClick={onListTemplateSelect}
            isDisable={isEqual(unitId, selectedUnitId)}
            disabled={isEqual(unitId, selectedUnitId)}
          />
        </ActionsContainer>
      </S.DialogContainer>
    </Dialog>
  );
});

type MapStateToPropsType = {
  units: { unitsList: UnitType[] };
};

const mapStateToProps = ({ units: { unitsList } }: MapStateToPropsType) => ({
  unitsList,
});

export default connect(mapStateToProps, null)(SelectListTemplate);
