import styled from 'styled-components';

export const DialogContainer = styled.div`
  text-align: center;

  & > div:last-child {
    padding: 20px 0 !important;
  }
`;

export const SectionContainer = styled.div`
  margin-bottom: 30px;
`;

export const Text = styled.p`
  margin: 0 0 10px;
  font-size: 22px;
  font-weight: 300;
  color: ${({ theme }) => theme.primaryBlack};
`;
