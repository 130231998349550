import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import theme from 'theme';

import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { Modal } from 'components/Modal';

import { actions as partsActions } from 'redux/parts';

import validate from './validator';
import * as S from './styled';

export const NewGroupDialog = ({ handleClose, open, addNewGroup }) => {
  const [isSaveAndAddPartsButtonSelected, setIsSaveAndAddPartsButtonSelected] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {},
    validate,
    onSubmit: values => {
      dispatch(
        partsActions.addPartGroupRequest({ values, cb: isSaveAndAddPartsButtonSelected ? addNewGroup : undefined }),
      );
      history.push('/parts/groups');
      setIsSaveAndAddPartsButtonSelected(false);
      onModalClose();
    },
  });

  const onModalClose = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <Modal isOpen={open} onRequestClose={onModalClose} title="New Group" titleColor={theme.primaryDark}>
      <S.DialogContainer onSubmit={formik.handleSubmit}>
        <S.NameContainer>
          <TextFieldAdapter
            name="Name"
            input={formik.getFieldProps('Name')}
            meta={formik.getFieldMeta('Name')}
            onChangeControl={value => formik.setFieldValue('Name', value)}
            placeholder="Group Name"
          />
        </S.NameContainer>
        <S.DescriptionCardContainer>
          <S.DescriptionHeader>Optional Data</S.DescriptionHeader>
          <S.DescriptionFieldContainer>
            <TextFieldAdapter
              name="Description"
              input={formik.getFieldProps('Description')}
              meta={formik.getFieldMeta('Description')}
              onChangeControl={value => formik.setFieldValue('Description', value)}
              placeholder="Group Description"
            />
          </S.DescriptionFieldContainer>
        </S.DescriptionCardContainer>
        <ActionsContainer isColumn gapValue={10}>
          <ActionButton
            label="Save And Add Parts"
            type="button"
            onClick={() => {
              setIsSaveAndAddPartsButtonSelected(true);
              formik.handleSubmit();
            }}
          />
          <ActionButton label="Save" type="submit" isNext />
        </ActionsContainer>
      </S.DialogContainer>
    </Modal>
  );
};

NewGroupDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  addNewGroup: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
