import styled from 'styled-components';
import Toggle from 'material-ui/Toggle';

import { BackIcon, PinDropIcon } from 'components/Layout/Icons';

export const IconBack = styled(BackIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})``;

export const ShowAllToggle = styled(Toggle).attrs({
  iconStyle: ({ theme, toggled }) => ({
    borderRadius: '12px',
    backgroundColor: `${toggled ? '#2C8500' : theme.primaryRed}`,
  }),
  labelStyle: {
    width: 'inherit',
    top: '19px',
    left: '46px',
    fontSize: '10px',
  },
  thumbStyle: ({ theme }) => ({
    top: '2px',
    left: '2px',
    backgroundColor: theme.primaryWhite,
  }),
  thumbSwitchedStyle: ({ theme }) => ({
    left: '36px',
    backgroundColor: theme.fieldsBorderColor,
  }),
  trackStyle: ({ theme }) => ({
    backgroundColor: theme.primaryRed,
  }),
  trackSwitchedStyle: {
    backgroundColor: '#2C8500',
  },
})``;

export const IconPinDrop = styled(PinDropIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})`
  transform: scale(1.5);
`;

export const TitleText = styled.h3`
  margin: 0;
  text-align: center;
  font-weight: normal;
`;
