import styled from 'styled-components';

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: ${({ inside }) => (inside ? '15px 16px 20px' : '30px 16px 30px')};
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.primaryWhite};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-left: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
`;

export const SectionBody = styled.div`
  padding-top: 15px;
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationText = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  padding: 0px 20px;
`;
