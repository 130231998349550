import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

export const InformationContainer = styled(Flex).attrs({
  column: true,
  flex: 'auto',
})``;

export const Label = styled(Box).attrs({
  is: 'p',
  m: 0,
  mt: 2,
})`
  font-size: 16px !important;
`;

export const Text = styled(Box).attrs({
  is: 'p',
  m: 0,
})`
  font-size: 16px !important;
`;
