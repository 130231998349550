import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Container, Value } from './styled';

const Period = ({ configs, type, startRange }) => {
  if (configs.every(({ key }) => key !== type)) {
    return null;
  }

  const formattedStart = moment(startRange).format('dddd MMM D');
  const formattedEnd = moment(startRange).add(6, 'day').format('dddd MMM D, YYYY');

  const formattedRange = `${formattedStart} - ${formattedEnd}`;

  return (
    <Container>
      {(type === 'today' || type === 'day' || type === 'all') && (
        <Value>{startRange.format('dddd | MMMM D, YYYY')}</Value>
      )}
      {(type === 'days' || type === 'week') && <Value>{formattedRange}</Value>}
    </Container>
  );
};

Period.propTypes = {
  configs: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  startRange: PropTypes.object.isRequired,
};

export default Period;
