import React from 'react';
import { Divider } from 'material-ui';
import { InventoryLevelType } from 'types/inventory';

import * as S from './styled';

type PropsType = {
  data: InventoryLevelType[];
  onEdit?: (field: string) => void;
};

export const PartInventoryLevels = ({ data, onEdit }: PropsType) => (
  <S.List>
    {data.map((el, index) => {
      if (el.secondaryName) {
        return (
          <React.Fragment key={index}>
            <S.ListItem>
              <S.Quantity isRedBack={el.isRedBack}>{el.quantity}</S.Quantity>
              <S.Text>{el.name}</S.Text>
              <S.Quantity isRedBack={el.isRedBack}>{el.secondaryQuantity}</S.Quantity>
              <S.Text>{el.secondaryName}</S.Text>
              {el.isEditable && (
                <S.EditButton type="button" label="Edit" onClick={() => onEdit?.(el.field as string)} />
              )}
            </S.ListItem>
            <Divider />
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={index}>
          <S.ListItem>
            <S.Quantity isRedBack={el.isRedBack}>{el.quantity}</S.Quantity>
            <S.Text>{el.name}</S.Text>
            {el.price && <div>{el.price}</div>}
            {el.isEditable && <S.EditButton type="button" label="Edit" onClick={() => onEdit?.(el.field as string)} />}
          </S.ListItem>
          <Divider />
        </React.Fragment>
      );
    })}
  </S.List>
);

PartInventoryLevels.defaultProps = {
  onEdit: null,
};
