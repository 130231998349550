/* eslint-disable no-tabs */
import styled from 'styled-components';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { ButtonIcon } from 'components/Layout/Buttons';
import { isIE11 } from 'helpers';

export const LinkContainer = styled.div`
  padding: 20px 0 10px 20px;
  border: 2px solid ${({ theme }) => theme.primarySetting};
  box-shadow: 0 8px 6px -6px black;
  border-radius: 12px;
  display: flex;
  margin: 20px;
`;

export const CheckboxesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-right: 35px;
`;

export const Checkbox = styled(CheckboxAdapter).attrs({
  name: props => props.input.name,
  id: props => props.input.id,
})`
  max-width: 25px;
  max-height: 25px;
`;

export const TextFieldsContainer = styled.div`
  margin-right: 35px;
`;

export const TextField = styled(TextFieldAdapter).attrs({
  name: props => props.input.name,
  id: props => props.input.id,
  inputStyle: ({ hintText, theme }) => ({
    color: hintText === 'Subtext' ? theme.darkGrey : '',
  }),
})`
  padding: 0 15px;
  margin-bottom: 10px;
  border-radius: 12px;
  background-color: ${({ disabled }) => (disabled ? '#eeeeee' : 'none')} !important;
  border: 1px solid
    ${({ meta, theme }) => {
      if (meta.invalid && (meta.touched || meta.submitFailed)) {
        return theme.primaryRed;
      }
      return theme.primaryHome;
    }};
`;

export const DeleteButton = styled(ButtonIcon)`
  align-self: flex-end;
  ${isIE11 ? 'right: 13px' : ''};
`;

export const ChekboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 45px;
  margin-right: 10px;
`;

export const CheckboxLabel = styled.div`
  text-align: center;
`;

export const ErrorMessage = styled.span`
  margin: 30px 0 0 30px;
  color: ${({ theme }) => theme.primaryRed};
`;

export const Roles = styled.div`
  display: flex;
  flex-direction: column;
`;
