import { call, put, select, takeEvery } from 'redux-saga/effects';
import { chain } from 'lodash';

import {
  createLandingPageSection,
  deleteLandingPageSection,
  getLandingPageList,
  getLandingPageSection,
  getLandingPageSectionList,
  getVideosBySection,
  getVideosCount,
  putLandingPage,
  updateLandingPageSection,
  updateLandingPageSectionSequence,
  updateLandingPageSequence,
} from 'http/tutorials';

import { actions } from './index';

import { ERROR_DIALOG_OPENED } from '../errorHandler';
import { DEFAULT_ERROR_MESSAGE, parseServerError } from '../../helpers';
import { getLandingPageSectionListSelector } from './selectors';
import { refreshVimeoCache } from '../../http/tutorials';

function* fetchLandingPageList() {
  try {
    const { data } = yield call(getLandingPageList);

    yield put(actions.fetchLandingPageListSuccess(data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* editLandingPage({ payload }) {
  try {
    yield call(putLandingPage, payload);
    yield* fetchLandingPageList();
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* updateSequence({ payload }) {
  try {
    yield call(updateLandingPageSequence, payload.items);
    yield* fetchLandingPageList();
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* fetchLandingPageSectionList({ payload }) {
  try {
    const { data } = yield call(getLandingPageSectionList, { pageId: payload });

    yield put(actions.fetchLandingPageSectionListSuccess(data.root));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* addLandingPageSection({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(createLandingPageSection, payload);

    yield* fetchLandingPageSectionList({ payload: entity.LandingPageID });
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* fetchLandingPageSection({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(getLandingPageSection, payload);

    yield put(actions.fetchLandingPageSectionSuccess(entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* editLandingPageSection({ payload }) {
  try {
    let dto = payload;
    if (typeof payload === 'string') {
      const list = yield select(getLandingPageSectionListSelector);
      const landingPageSection = list.find(({ Id }) => Id === payload);
      dto = { ...landingPageSection, IsVisible: !landingPageSection.IsVisible };
    }

    const {
      data: { entity },
    } = yield call(updateLandingPageSection, dto);

    yield put(actions.updateLandingPageSectionSuccess(entity));
    yield* fetchLandingPageSectionList({ payload: entity.LandingPageID });
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* removeLandingPageSection({ payload }) {
  try {
    yield call(deleteLandingPageSection, payload.id);

    yield put(actions.fetchLandingPageSectionListRequest(payload.landingPageID));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* updateSectionSequence({ payload }) {
  try {
    yield call(updateLandingPageSectionSequence, payload.items);

    yield put(actions.fetchLandingPageSectionListRequest(payload.id));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* fetchVideosCount({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(getVideosCount, payload);

    yield put(actions.fetchVideosCountSuccess(entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* fetchVideosBySection({ payload }) {
  try {
    const {
      data: { root },
    } = yield call(getVideosBySection, payload);
    const videos = chain(root)
      .groupBy('Header')
      .map((value, key) => {
        const [first] = value;
        const { Subtitle } = first || {};

        return { header: key, subtitle: Subtitle, data: value };
      })
      .value();

    yield put(actions.fetchVideosBySectionSuccess(videos));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

function* refreshVimeoCacheSaga() {
  try {
    yield call(refreshVimeoCache);

    yield put(actions.refreshVimeoCacheSuccess());
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(actions.setTutorialPageError(error.response?.data.error || DEFAULT_ERROR_MESSAGE));
  }
}

const tutorialsSagas = [
  takeEvery(actions.fetchLandingPageListRequest, fetchLandingPageList),
  takeEvery(actions.editLandingPageRequest, editLandingPage),
  takeEvery(actions.updateLandingPageSequenceRequest, updateSequence),
  takeEvery(actions.fetchLandingPageSectionListRequest, fetchLandingPageSectionList),
  takeEvery(actions.createLandingPageSectionRequest, addLandingPageSection),
  takeEvery(actions.fetchLandingPageSectionRequest, fetchLandingPageSection),
  takeEvery(actions.updateLandingPageSectionRequest, editLandingPageSection),
  takeEvery(actions.deleteLandingPageSectionRequest, removeLandingPageSection),
  takeEvery(actions.updateLandingPageSectionSequenceRequest, updateSectionSequence),
  takeEvery(actions.fetchVideosCountRequest, fetchVideosCount),
  takeEvery(actions.fetchVideosBySectionRequest, fetchVideosBySection),
  takeEvery(actions.refreshVimeoCacheRequest, refreshVimeoCacheSaga),
];

export default tutorialsSagas;
