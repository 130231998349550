import styled from 'styled-components';

export const DialogContainer = styled.div`
  padding: 20px 20px 0;
  text-align: center;
`;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin: 8px 0;
`;

export const ConfirmationWarning = styled.p`
  font-size: 18px;
  margin: 8px 0;
`;

export const Container = styled.div<{ isColumn: boolean }>`
  display: flex;
  width: 100%;
  button {
    margin: 5px !important;
  }

  flex-direction: ${({ isColumn }) => isColumn && 'column'} !important;
`;
