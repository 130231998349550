import React from 'react';
import PropTypes from 'prop-types';

import withInputModal from 'components/Dialogs/withInputModal';

import * as S from './styled';

const NotAssignedTasksModalForm = ({ handleChange, handleContinue }) => (
  <S.Container>
    <S.ButtonChange onClick={handleChange}>Change to Open Task</S.ButtonChange>
    <S.ButtonContinue onClick={handleContinue}>Continue without change</S.ButtonContinue>
  </S.Container>
);

NotAssignedTasksModalForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

const NotAssignedTasksModalWithInput = withInputModal(NotAssignedTasksModalForm);

const NotAssignedTasksModal = props => <NotAssignedTasksModalWithInput {...props} />;

export default NotAssignedTasksModal;
