import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'components/Layout/Progresses';

import * as S from './styled';

const PopoverMenu = ({
  onItemClick,
  data,
  emptyText,
  keyName,
  menuTitle,
  getMenuItemStyle,
  menuStyle,
  handleClose,
  anchorEl,
  isOpen,
  isLoading,
}) => {
  const renderMenuItems = () => {
    if (isLoading) {
      return <Loader isCentered />;
    } else if (!data.length) {
      return <S.EmptyItem>{emptyText}</S.EmptyItem>;
    }

    return (
      <S.MenuContainer styles={menuStyle}>
        {data.map((item, index) => (
          <S.Item
            key={item.Id || index}
            primaryText={item[keyName]}
            styles={getMenuItemStyle(item)}
            onClick={() => onItemClick(item)}
          />
        ))}
      </S.MenuContainer>
    );
  };

  return (
    <S.Popover
      anchorEl={anchorEl}
      onRequestClose={handleClose}
      open={isOpen}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      targetOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <S.MenuTitle key={menuTitle}>{menuTitle}</S.MenuTitle>
      {renderMenuItems()}
    </S.Popover>
  );
};

PopoverMenu.propTypes = {
  data: PropTypes.array.isRequired,
  emptyText: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  menuTitle: PropTypes.string.isRequired,
  getMenuItemStyle: PropTypes.func,
  menuStyle: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object.isRequired,
};

PopoverMenu.defaultProps = {
  emptyText: 'No an assigned user',
  getMenuItemStyle: () => {},
  menuStyle: [],
};

export default memo(PopoverMenu);
