import React, { FC } from 'react';
import { AddSignUpNotification, LinkIcon, TextIcon } from 'components/Layout/Icons';
import { PrimaryIconButton, SecondaryIconButton, ListItem } from 'components/Layout/Section';
import theme from 'theme';

type PropsType = {
  openTextDialog: () => void;
  openLinksDialog: () => void;
  openSignUpNotificationDialog: () => void;
};

export const Notifications: FC<PropsType> = ({ openTextDialog, openLinksDialog, openSignUpNotificationDialog }) => (
  <ListItem
    key="Notifications"
    name="Notifications"
    zIndex={6}
    content={
      <>
        <PrimaryIconButton onClick={openTextDialog} icon={<TextIcon />} label="Text" />
        <SecondaryIconButton onClick={openLinksDialog} icon={<LinkIcon color={theme.primaryBlack} />} label="Links" />
        <SecondaryIconButton onClick={openSignUpNotificationDialog} icon={<AddSignUpNotification />} label="Signups" />
      </>
    }
  />
);
