export const validator = values => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }

  return errors;
};

export default validator;
