import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { Field } from 'react-final-form';

import { SensorLevelColors } from 'theme';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

export const Container = styled.div`
  width: 100%;
`;

export const Level = styled(Flex).attrs({
  justify: 'space-between',
  w: 1,
})`
  min-height: 67px;
  margin: 16px 0;
  border-radius: 3px;
  background: ${({ theme }) => theme.primaryWhite};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

export const LevelLabel = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
})`
  min-width: 80px;
  width: 200px;
  padding: 0 10px;
  background: ${({ name }) => SensorLevelColors[name]};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
`;

export const TextField = styled(TextFieldAdapter).attrs({
  errorStyle: {
    bottom: -6,
    fontSize: '10px',
  },
  floatingLabelShrinkStyle: {
    top: 16,
  },
  floatingLabelStyle: {
    top: 12,
  },
  underlineStyle: {
    bottom: -10,
  },
})`
  height: auto !important;
  max-height: 30px;
  margin-bottom: 15px;

  div > input {
    margin: 0 !important;
  }

  &:first-child {
    margin-right: 20px;
  }
`;

export const Parameters = styled(Flex).attrs({})`
  width: 100%;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AlertCheckboxField = styled(Field)`
  width: unset !important;
`;
