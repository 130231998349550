import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { UnitItemPdfType } from 'configs/propTypes';
import BaseDialog from 'components/Dialogs/Base';
import SimpleList from 'components/SimpleList';
import { ListContainer } from './styled';

const renderItemContent = pdf => pdf.Name || 'No pdf image';

class PdfsDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleItemClick: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    pdfs: PropTypes.arrayOf(UnitItemPdfType).isRequired,
  };

  render() {
    const { handleItemClick, handleClose, open, pdfs } = this.props;

    return (
      <BaseDialog onRequestClose={handleClose} open={open} title="Uploaded PDFs">
        <ListContainer>
          <SimpleList
            emptyListMessage="There are no PDFs"
            data={pdfs}
            onItemClick={handleItemClick}
            renderItemContent={renderItemContent}
          />
        </ListContainer>
      </BaseDialog>
    );
  }
}

export default PdfsDialog;
