/* eslint-disable no-tabs */
import styled from 'styled-components';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

export const IntroContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: 50px;
  margin-bottom: 50px;
`;

export const IntroLabel = styled.div`
  width: 40%;
  margin-left: 20px;
`;

export const TextField = styled(TextFieldAdapter).attrs({
  hintStyle: {
    top: 10,
  },
})`
  padding: 0 15px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.primaryHome};
`;
