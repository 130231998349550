import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { isEmpty } from 'lodash';
import theme from 'theme';
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';
import { actions as partsActions } from 'redux/parts';

import { ButtonRaised } from 'components/Layout/Buttons';
import { InfoIcon, FindReplaceIcon } from 'components/Layout/Icons';
import withInputModal from 'components/Dialogs/withInputModal';
import { generateInitialRules } from 'modules/queue/pages/Archive/ReportSetup/ReportRules/helpers';
import { formatExecutionDate, createId } from '../helpers';
import SectionRules from './SectionRules';
import { types, initialRule, distributionRules } from './constants';

import * as S from './styled';

const generatePreviewInfo = previewList => {
  const uniqLocationNames = [];
  let itemsCount = 0;

  previewList.map(location => {
    if (uniqLocationNames.indexOf(location.SiteName) === -1) {
      uniqLocationNames.push(location.SiteName);
    }
    itemsCount += location.Items.length;

    return location;
  });

  return (
    <>
      <p>
        Locations Included: <span>{uniqLocationNames.length}</span>
      </p>
      <p>
        Units Included: <span>{previewList.length}</span>
      </p>
      <p>
        Items Included: <span>{itemsCount}</span>
      </p>
    </>
  );
};

class DistributionForm extends React.PureComponent {
  static propTypes = {
    selectedItem: PropTypes.shape({
      MyPartID: PropTypes.string,
      Id: PropTypes.string,
      Name: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape().isRequired,
    previewList: PropTypes.array.isRequired,
    onOpenPreviewInfo: PropTypes.func.isRequired,
    getExecutionDate: PropTypes.func.isRequired,
    executionDate: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['parts', 'groups']).isRequired,
    getExecutionGroupDate: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
  };

  state = {
    distributionType: this.props.details.Type?.toString(),
    isPreviewResultsVisible: false,
    LocationRules: generateInitialRules({ rules: this.props.details.LocationRules, flag: 'Location' }),
    UnitRules: generateInitialRules({ rules: this.props.details.UnitRules, flag: 'Unit' }),
    ItemRules: generateInitialRules({ rules: this.props.details.ItemRules, flag: 'Item' }),
  };

  componentDidMount() {
    const {
      selectedItem: { Id },
      getExecutionDate,
      type,
      getExecutionGroupDate,
    } = this.props;
    if (type === 'groups') {
      getExecutionGroupDate({ partGroupId: Id });
      return;
    }

    getExecutionDate({ partId: Id });
  }

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onChangeDistributionType = value => this.setState({ distributionType: value.toString() });

  setPreviewResultsVisible = () => {
    this.props.onOpenPreviewInfo(this.props.form.getState().values);
    this.setState({ isPreviewResultsVisible: true });
  };

  onChangeIgnoreRules = flag => value => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], isRulesIgnored: value },
    });
  };

  render() {
    const { selectedItem, previewList, executionDate, type: currentType, details } = this.props;
    const isCurrentTypeParts = currentType === 'parts';
    const { distributionType, isPreviewResultsVisible } = this.state;
    const initialValueForDistributionType = !isEmpty(details) ? distributionType : '0';

    return (
      <S.Container>
        <S.InfoBlock>
          <div>
            {isCurrentTypeParts && (
              <p>
                My Part ID: <span>{selectedItem.MyPartID}</span>
              </p>
            )}
            {!isCurrentTypeParts && (
              <p>
                Group Name: <span>{selectedItem.Name}</span>
              </p>
            )}
            {executionDate ? <p>Last Run: {formatExecutionDate(executionDate)}</p> : null}
          </div>
          <S.IconButton
            tooltip={
              <S.Tooltip>
                <S.TooltipText>Distribute this part to any item in the entire organization.</S.TooltipText>
                <S.TooltipText>
                  Managers can add and remove parts under their own location (per site part management).
                </S.TooltipText>
              </S.Tooltip>
            }
            tooltipPosition="bottom-left"
          >
            <InfoIcon />
          </S.IconButton>
        </S.InfoBlock>
        <S.Title>
          {`Set up rules to distribute and link this 
          ${isCurrentTypeParts ? 'part' : 'group'} 
          to multiple locations and items`}
        </S.Title>
        <Field name="distribution-type" defaultValue={distributionType} initialValue={initialValueForDistributionType}>
          {({ input }) => (
            <RadioButtonGroup
              name={input.name}
              selectedValue={initialValueForDistributionType}
              defaultSelected={initialValueForDistributionType}
              onChange={(e, value) => {
                this.onChangeDistributionType(value);
                input.onChange(value);
              }}
            >
              {Object.keys(types).map(type => (
                <RadioButton
                  value={type}
                  label={types[type]}
                  labelStyle={{ color: '#000', fontWeight: 700 }}
                  style={{ marginBottom: '10px' }}
                  iconStyle={{ fill: input.value === type && theme.primaryScheduler }}
                />
              ))}
            </RadioButtonGroup>
          )}
        </Field>
        {distributionRules.map(rule => (
          <SectionRules
            key={rule}
            title={`${rule} Rules`}
            isInitialValues={!isEmpty(details[`${rule}Rules`])}
            rules={this.state[`${rule}Rules`]}
            addOption={this.addOption}
            removeOption={this.removeOption}
            onChangeMatch={this.onChangeMatch}
            onChangeIgnoreRules={this.onChangeIgnoreRules}
          />
        ))}
        <S.PreviewResultsContainer>
          <div />
          <ButtonRaised
            label="Preview Results"
            labelPosition="after"
            icon={<FindReplaceIcon color="#fff" />}
            labelColor="#fff"
            backgroundColor={theme.greenButton}
            onClick={this.setPreviewResultsVisible}
          />
          <S.PreviewResultsInfo isVisible={isPreviewResultsVisible.toString()}>
            {generatePreviewInfo(previewList)}
          </S.PreviewResultsInfo>
        </S.PreviewResultsContainer>
      </S.Container>
    );
  }
}

const Distribution = withInputModal(DistributionForm);

const DistributionDialog = props => <Distribution {...props} initialValues={{}} destroyOnUnregister withGreenButton />;

const mapStateToProps = ({ parts, distributions }) => ({
  previewList: distributions.previewList,
  executionDate: parts.lastExecutionDate,
  details: distributions.details,
});

const mapDispatchToProps = {
  getExecutionDate: partsActions.getLastExecutionDateRequest,
  getExecutionGroupDate: partsActions.getLastExecutionGroupDateRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributionDialog);
