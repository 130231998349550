import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  & > div:first-child {
    font-size: 20px !important;
    margin-top: 20px;
  }

  & > div:last-child {
    padding: 15px !important;

    & > button:first-child {
      margin-right: 10px !important;
    }

    & > button:last-child {
      margin-left: 10px !important;
    }
  }
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const RedInfo = styled(Info)`
  color: ${({ theme }) => theme.mainRed};
  display: flex;
  margin: 10px 25px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 10px 25px;
`;

export const RedText = styled(Text)`
  color: ${({ theme }) => theme.mainRed} !important;
`;
