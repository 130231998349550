import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BaseDialog from 'components/Dialogs/Base';

import IOT_HUBS from 'configs/iotHubs';
import { resourceIcons } from 'configs/enums';

import { actions as gatewaysActions } from 'redux/gateways';
import { getRegionsList } from 'redux/gateways/selectors';

import theme from 'theme';

import ConnectionField from '../ConnectionField';
import {
  getTenantLocationsByName,
  getSiteLocationByName,
  resourceCards,
  notificationTitle,
  modalTitles,
} from '../helpers';
import * as CS from '../styled';

import * as S from './styled';

class UniqueResourceDialog extends Component {
  static propTypes = {
    action: PropTypes.shape({
      getRegions: PropTypes.func.isRequired,
      getConnectionString: PropTypes.func.isRequired,
    }).isRequired,
    newResource: PropTypes.shape({
      IsUnique: PropTypes.bool,
      Name: PropTypes.string,
    }).isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    onStateChange: PropTypes.func.isRequired,
    selectedTenant: PropTypes.string.isRequired,
    currentTenantLocation: PropTypes.string,
    selectedSite: PropTypes.string.isRequired,
    selectedHub: PropTypes.string,
    currentSiteLocation: PropTypes.shape({
      offset: PropTypes.string,
      city: PropTypes.string,
    }),
    currentGateway: PropTypes.string.isRequired,
    regionsList: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentRegion: PropTypes.string,
    SKU: PropTypes.shape({
      SKUName: PropTypes.string,
      SKUTier: PropTypes.string,
      Capacity: PropTypes.number,
    }).isRequired,
    activatorType: PropTypes.oneOf(['setup', 'add', 'remove', '']),
    resourceGroupName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    selectedTenant: null,
    currentSiteLocation: null,
    currentTenantLocation: '',
    selectedHub: '',
    currentRegion: '',
    activatorType: '',
  };

  state = {
    iteration: 'resourcegroup',
    connectionString: '',
    isCopied: false,
  };

  componentDidMount() {
    this.props.action.getRegions();
  }

  componentDidUpdate(prevProps) {
    const { regionsList, selectedSite, selectedHub, currentGateway, onStateChange } = this.props;
    const { iteration } = this.state;

    if (prevProps.currentRegion.length === 0 && regionsList.length > 0) {
      onStateChange('region', regionsList[0]);
    }

    if (
      (selectedSite.length === 0 && iteration === 'hub') ||
      (selectedHub.length === 0 && iteration === 'gateway') ||
      (currentGateway.length === 0 && iteration === 'connection')
    ) {
      this.setState({ iteration: 'resourcegroup' });
    }

    if (selectedSite.length > 0 && iteration === 'resourcegroup') {
      this.setState({ iteration: 'hub' });
      onStateChange('SKU', { SKUName: 'F1', SKUTier: 'Free', Capacity: 1 });
    }

    if (selectedHub.length > 0 && iteration === 'hub') {
      this.setState({ iteration: 'gateway' });
    }

    if (currentGateway.length > 0 && iteration === 'gateway') {
      this.setState({ iteration: 'connection' });
    }
  }

  setConnectionString = connectionString => this.setState({ connectionString });

  onChangeTier = value => {
    const { onStateChange, SKU } = this.props;
    const { skuTier } = IOT_HUBS.find(({ skuName }) => skuName === value);

    onStateChange('SKU', { ...SKU, SKUName: value, SKUTier: skuTier, Capacity: 1 });
  };

  onRetrieve = () => {
    const { action, selectedHub, currentGateway, resourceGroupName } = this.props;

    action.getConnectionString({
      resourceGroupName,
      iotHubName: selectedHub,
      deviceId: currentGateway,
      cb: this.setConnectionString,
    });
  };

  onCopy = () =>
    navigator.clipboard.writeText(this.state.connectionString).then(() => this.setState({ isCopied: true }));

  render() {
    const {
      open,
      handleClose,
      onStateChange,
      selectedTenant,
      regionsList,
      newResource,
      handleNext,
      selectedSite,
      selectedHub,
      activatorType,
      currentGateway,
      currentTenantLocation,
      currentSiteLocation,
      currentRegion,
      SKU,
    } = this.props;
    const { region, iteration, connectionString, isCopied } = this.state;

    const hubTier = IOT_HUBS.find(({ skuName }) => skuName === SKU.SKUName);

    return (
      <BaseDialog
        open={open}
        onRequestClose={handleClose}
        title={modalTitles[activatorType]}
        titleColor={theme.darkGrey}
      >
        <CS.DialogContainer>
          <CS.NotificationText>{notificationTitle[iteration]}</CS.NotificationText>
          <CS.CurrentTenant>{`Tenant: ${selectedTenant}`}</CS.CurrentTenant>
          {selectedSite ? (
            <>
              <CS.CurrentTenant>{`Site: ${selectedSite}`}</CS.CurrentTenant>
              {selectedHub.length === 0 && (
                <S.SiteInfoContainer>
                  <S.SiteInfoText>
                    <S.BoldText>City: </S.BoldText>
                    {currentSiteLocation ? currentSiteLocation.city : ''}
                  </S.SiteInfoText>
                  <S.SiteInfoText>
                    <S.BoldText>Time Zone: </S.BoldText>
                    {currentSiteLocation ? currentSiteLocation.offset : ''}
                  </S.SiteInfoText>
                </S.SiteInfoContainer>
              )}
            </>
          ) : (
            <S.TenantLocation>
              Tenant Location: <S.BoldText>{currentTenantLocation}</S.BoldText>
            </S.TenantLocation>
          )}
          {selectedHub.length > 0 && <CS.CurrentTenant>{selectedHub}</CS.CurrentTenant>}
          {currentGateway.length > 0 && <CS.CurrentTenant gateway>{currentGateway}</CS.CurrentTenant>}
          {resourceCards[iteration].map(({ type, title }) => (
            <CS.CardContainer key={type}>
              <CS.CardHeader>
                <CS.CardHeaderTitle>{title}</CS.CardHeaderTitle>
                <CS.CardHeaderIconContainer>{resourceIcons[iteration]}</CS.CardHeaderIconContainer>
              </CS.CardHeader>
              <S.CardContentContainer>
                {type === 'location' && (
                  <S.Select value={currentRegion} onChange={(e, i, value) => onStateChange('region', value)}>
                    {!!regionsList.length &&
                      regionsList.map(regionName => (
                        <S.Item key={regionName} value={regionName} primaryText={regionName} />
                      ))}
                  </S.Select>
                )}
                {type === 'name' && newResource.Name && <CS.CurrentTenant>{newResource.Name}</CS.CurrentTenant>}
                {type === 'tier' && (
                  <S.TierContainer>
                    <S.Select value={SKU.SKUName} onChange={(e, i, value) => this.onChangeTier(value)}>
                      {IOT_HUBS.map(({ skuName, skuTier }) => (
                        <S.Item key={skuName} primaryText={`${skuName}: ${skuTier} tier`} value={skuName} />
                      ))}
                    </S.Select>
                    <S.SliderContainer>
                      <S.SliderLabel>Number of {SKU.SKUName} IoT Hub Units</S.SliderLabel>
                      <S.SliderField
                        min={1}
                        max={hubTier ? hubTier.capacityMax : 2}
                        step={1}
                        value={SKU.Capacity}
                        onChange={(e, value) => onStateChange('SKU', { ...SKU, Capacity: value })}
                        disabled={hubTier && hubTier.capacityMax === 2}
                      />
                    </S.SliderContainer>
                    <S.Capacity>{SKU.Capacity}</S.Capacity>
                  </S.TierContainer>
                )}
                {type === 'connection' && currentGateway.length > 0 && (
                  <ConnectionField
                    onRetrieve={this.onRetrieve}
                    onCopy={this.onCopy}
                    connectionString={connectionString}
                    isCopied={isCopied}
                    gateway={currentGateway}
                  />
                )}
              </S.CardContentContainer>
            </CS.CardContainer>
          ))}
        </CS.DialogContainer>
        <CS.NextButton
          onClick={() => handleNext(iteration)}
          disabled={!newResource.Name && !region}
          isNext
          label={iteration !== 'connection' ? 'Save / Next' : 'Close / Finish'}
        />
      </BaseDialog>
    );
  }
}

const mapStateToProps = ({ gateways, tenants, sites }, { selectedTenant, selectedSite }) => ({
  regionsList: getRegionsList(gateways.regionsList),
  currentTenantLocation: getTenantLocationsByName(tenants.list, selectedTenant),
  currentSiteLocation: getSiteLocationByName(sites.list, selectedSite),
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      getRegions: gatewaysActions.getRegionsRequest,
      getConnectionString: gatewaysActions.getDeviceConnectionStringRequest,
    },
    dispatch,
  ),
});

const UniqueResource = connect(mapStateToProps, mapDispatchToProps)(UniqueResourceDialog);

export { UniqueResource };
