import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, Provider, ReactReduxContext } from 'react-redux';
import moment from 'moment';

import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as teamsActions } from 'redux/teams';
import { actions as sitesActions } from 'redux/sites';

import { Dialog } from 'components/Dialogs/v1/Base';
import AddPeople from 'modules/activator/dialogs/NewSite/AddPeople';
import MissingResultsDialog from 'modules/activator/dialogs/NewSite/AddPeople/MissingResultsDialog';

import theme from 'theme';
import { resizeWindow } from 'helpers';

import * as S from './styled';

const SiteSelection = ({ isActivator, onSiteSelect, onNext }) => {
  const [missingDialogOpen, setMissingDialogOpen] = useState(false);
  const [addPeopleDialogOpen, setAddPeopleDialogOpen] = useState(false);
  const [currentSite, setCurrentSite] = useState({});
  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const list = useSelector(state => state.shortcuts.listOfSites);
  const sitesInfo = useSelector(state => state.shortcuts.shortcutSitesInfo);
  const shortcut = useSelector(state => state.shortcuts.shortcut);
  const shortcutToPreview = useSelector(state => state.shortcuts.shortcutToPreview);
  const siteSchedule = useSelector(state => state.shortcuts.siteSchedule);
  const listSchedule = useSelector(state => state.shortcuts.listSchedule)?.map(item => ({
    ...item,
    SiteId: item.SiteID,
  }));
  const prevProps = useRef({
    LocationRules: shortcut.LocationRules,
    ItemRules: shortcut.ItemRules,
    UnitRules: shortcut.UnitRules,
  }).current;

  const info = shortcutToPreview?.Id && !isActivator ? listSchedule : sitesInfo;

  const handleNext = item => {
    onSiteSelect(item);
    onNext();
  };

  const onSettingsClick = item => {
    setCurrentSite(item);
    setAddPeopleDialogOpen(false);
    const diff = moment.duration(moment(siteSchedule.EndDate).diff(moment(new Date()))).asDays();

    if (diff >= 0) {
      dispatch(teamsActions.getTeamsRequest({ siteId: item.Id }));
      dispatch(
        sitesActions.assignedUsersRequest({
          siteId: item.Id,
          onNext: sitePeople => checkIfSiteHasManagerOrTasksSpecialist(sitePeople, item),
        }),
      );
    }
  };

  const openMissingResultsDialog = () => {
    setMissingDialogOpen(true);
  };

  const closeMissingResultsDialog = () => {
    setMissingDialogOpen(false);
  };

  const openAddPeopleDialog = () => {
    closeMissingResultsDialog();
    setAddPeopleDialogOpen(true);
  };

  const closeAddPeopleDialog = () => {
    setAddPeopleDialogOpen(false);
    closeMissingResultsDialog();
  };

  const checkIfSiteHasManagerOrTasksSpecialist = (people, site) => {
    const hasManager = people.some(({ UserType }) => UserType === 'Manager');
    const hasTasksSpecialist = people.some(({ UserType }) => UserType === 'TaskSpecialist');

    if (!hasManager || !hasTasksSpecialist) {
      openMissingResultsDialog();
    } else {
      handleNext(site);
    }
  };

  const findSite = (data, id) => data?.find(site => site?.SiteId === id);

  const handleClick = item => {
    const isFindSite = findSite(shortcutToPreview.Id && !isActivator ? listSchedule : sitesInfo, item.Id);

    if (isFindSite && !isFindSite.IsScheduled) {
      onSettingsClick(item);
    }

    if (isFindSite && isFindSite.IsScheduled && !isActivator) {
      dispatch(
        shortcutActions.getSiteScheduleRequest({
          shortcutId: shortcut.Id,
          siteId: isFindSite.SiteId,
          onNext: () => onSettingsClick(item),
        }),
      );
    }
  };

  useEffect(() => {
    if (
      (shortcut.Name &&
        prevProps.LocationRules !== shortcut.LocationRules &&
        prevProps.ItemRules !== shortcut.ItemRules &&
        prevProps.UnitRules !== shortcut.UnitRules) ||
      !sitesInfo?.length
    ) {
      dispatch(shortcutActions.getShortcutSitesRequest({ shortcut }));
    }

    dispatch(shortcutActions.deleteSiteSchedule());
    const id = setTimeout(() => resizeWindow(), 0);

    return () => clearTimeout(id);
  }, [dispatch, prevProps, shortcut, sitesInfo?.length]);

  return (
    <>
      <S.Container>
        <S.Title>Shortcut Name</S.Title>
        <S.MainTitle>{shortcut && shortcut.Name}</S.MainTitle>
        <S.Block>
          <S.Label>Checklist Creation</S.Label>
          <S.TitleBlock>
            <S.LargeBox>Site Name</S.LargeBox>
            <S.MediumBox>Scheduled</S.MediumBox>
            <S.SmallBox>{shortcutToPreview.Id && !isActivator ? 'Edit' : 'Create'}</S.SmallBox>
          </S.TitleBlock>
          {Array.isArray(list) &&
            list.map(item => {
              const site = findSite(info, item.Id);
              const isScheduled = site && site.IsScheduled;

              return (
                site && (
                  <S.ItemContainer key={item.Id}>
                    <S.LargeBox>
                      {item.AdditionalSiteID ? `${item.AdditionalSiteID.substring(0, 5)} ${item.Name}` : item.Name}
                    </S.LargeBox>
                    <S.MediumBox>{isScheduled ? <S.Scheduled /> : <S.NotScheduled />}</S.MediumBox>
                    <S.Button disabled={isScheduled} onClick={() => handleClick(item)}>
                      <S.Settings />
                    </S.Button>
                  </S.ItemContainer>
                )
              );
            })}
        </S.Block>
      </S.Container>
      <MissingResultsDialog
        open={missingDialogOpen}
        label="ADD PEOPLE"
        handleClose={closeMissingResultsDialog}
        confirm={openAddPeopleDialog}
      />

      <Dialog
        open={addPeopleDialogOpen}
        title="Site Setup"
        titleColor={theme.primarySites}
        onRequestClose={closeAddPeopleDialog}
      >
        <Provider store={store}>
          <S.AddPeopleDialogContainer>
            <AddPeople
              onExit={onSettingsClick}
              addAnotherSite={closeAddPeopleDialog}
              currentSite={currentSite}
              isDemoMode={false}
              isEditMode
            />
          </S.AddPeopleDialogContainer>
        </Provider>
      </Dialog>
    </>
  );
};

SiteSelection.propTypes = {
  isActivator: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onSiteSelect: PropTypes.func.isRequired,
};

export default SiteSelection;
