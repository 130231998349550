import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { actions as scheduleActions } from 'redux/schedule';

import validate from './validator';
import * as S from './styled';

const DuplicateScheduleForm = ({ errorName }) => (
  <S.FormContainer>
    <Field component={TextFieldAdapter} type="text" name="OldName" disabled floatingLabelText="Current Name" />
    <Field component={TextFieldAdapter} type="text" name="name" floatingLabelText="New Name" />
    {errorName && <S.ErrorContainer>Checklist with name &apos;{errorName}&apos; already exists</S.ErrorContainer>}
  </S.FormContainer>
);

const DuplicateScheduleWithInput = withInputModal(DuplicateScheduleForm);

const DuplicateScheduleModal = props => {
  const isCopyModalVisible = useSelector(state => state.schedule.isCopyModalVisible);
  const duplicatedSchedule = useSelector(state => state.schedule.duplicatedSchedule);
  const list = useSelector(state => state.schedule.schedulesList);
  const [notUniqueChecklistName, setNotUniqueChecklistName] = useState('');
  const dispatch = useDispatch();

  const checkChecklistName = name => list.find(el => el.Name === name)?.Name;

  const openModal = () => dispatch(scheduleActions.setIsModalVisible(true));

  const handleCopyModalClose = () => {
    dispatch(scheduleActions.setIsCopyModalVisible(false));
    dispatch(scheduleActions.setDuplicateChecklist(false));
    dispatch(scheduleActions.setDuplicatedSchedule({}));
  };

  const submitDuplicateModal = (name, item) => {
    if (checkChecklistName(name)) {
      setNotUniqueChecklistName(name);
    } else {
      handleCopyModalClose();
      setNotUniqueChecklistName('');
      dispatch(scheduleActions.setChecklistToEdit({ ...item, Name: name }));
      dispatch(scheduleActions.setDuplicateChecklist(true));
      openModal();
    }
  };

  return (
    <DuplicateScheduleWithInput
      {...props}
      initialValues={{ OldName: duplicatedSchedule.Name }}
      title="Duplicate Schedule"
      onSubmit={values => submitDuplicateModal(values.name, duplicatedSchedule)}
      errorName={notUniqueChecklistName}
      validate={validate}
      open={isCopyModalVisible}
      handleClose={handleCopyModalClose}
    />
  );
};

DuplicateScheduleModal.propTypes = {
  submitDuplicateModal: PropTypes.func.isRequired,
};

DuplicateScheduleForm.propTypes = {
  errorName: PropTypes.string.isRequired,
};

export default DuplicateScheduleModal;
