import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import { actions as unitsActions } from 'redux/units';
import { actions as authActions } from 'redux/auth';
import { actions as scheduleActions } from 'redux/schedule';
import { setSchedulerType } from 'helpers/storage';

import * as S from './styled';

class Final extends React.PureComponent {
  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onExit: PropTypes.func.isRequired,
    checklistsList: PropTypes.shape().isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    action: PropTypes.shape({
      fetchSites: PropTypes.func.isRequired,
      requestUnitsList: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    selectedSite: PropTypes.string.isRequired,
  };

  state = {
    currentSite: {},
    opacity: 1,
    isErrorShown: false,
  };

  typeHandler = () => {
    const {
      selectedSite,
      action: { requestUnitsList },
    } = this.props;
    requestUnitsList({
      includeChecklistData: true,
      filter: {
        SiteID: selectedSite,
        checklistType: 'Shortcut',
      },
    });
    setSchedulerType('Shortcut');
  };

  onViewChecklist = () => {
    const {
      checklistsList,
      history,
      list,
      action: { onChange },
    } = this.props;
    const { currentSite } = this.state;
    const updatedSites = list.map(item => ({
      siteName: item.SiteName,
      siteId: item.SiteId,
    }));
    if (!currentSite.SiteName) {
      this.setState({ isErrorShown: true });
    } else {
      this.typeHandler();
      this.setState({ isErrorShown: false });
      const checklist = checklistsList[currentSite.SiteId][0];
      onChange({ checkedSite: currentSite.SiteName, sitesCollection: updatedSites });
      this.props.action.fetchSites();

      history.push(`/scheduler/list/${checklist.UnitId}`, { siteId: currentSite.SiteId });
    }
  };

  handleChange = value => {
    this.setState({ currentSite: value, opacity: 0 });
  };

  onViewShortcuts = () => {
    const { history } = this.props;
    history.push('/shortcuts');
  };

  render() {
    const { list, onExit } = this.props;
    return (
      <S.Container>
        <S.Text>Shortcut is completed!</S.Text>
        <S.Select
          hintText="Select Site Name"
          hintStyle={{ opacity: this.state.opacity }}
          value={this.state.currentSite}
          onChange={(e, i, value) => this.handleChange(value)}
        >
          {!!list.length && list.map(site => <S.Item key={site.Id} value={site} primaryText={site.SiteName} />)}
        </S.Select>
        {this.state.isErrorShown && <S.ErrorContainer>Please select the Site</S.ErrorContainer>}
        <ActionsContainer isColumn marginValue={5}>
          <ActionButton isColumn label="View Shortcuts" isNext onClick={this.onViewShortcuts} />
          <ActionButton isColumn label="View Checklist" isNext onClick={this.onViewChecklist} />
          <ActionButton isColumn label="Exit" onClick={onExit} />
        </ActionsContainer>
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts, auth }) => ({
  selectedSite: auth.selectedSite,
  list: shortcuts.shortcutSitesInfo,
  listOfUnits: shortcuts.listOfUnits,
  checklistsList: shortcuts.checklistsList,
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      fetchSites: authActions.fetchUserSitesRequest,
      onChange: authActions.onSiteChange,
      requestTemplates: scheduleActions.fetchScheduleTemplatesRequest,
      requestUnitsList: unitsActions.unitsListRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Final);
