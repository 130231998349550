import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';

import theme from 'theme';

import * as S from './styled';

const SuccessDialog = ({ open, onClose }) => (
  <BaseDialog open={open} onRequestClose={onClose} title="Success" titleColor={theme.signUpColor}>
    <S.DialogContainer>
      <S.DialogText>Thank you!</S.DialogText>
      <S.DialogText>Your request is successfully submitted.</S.DialogText>
      <S.DialogText bold>You will receive a validation e-Mail shortly.</S.DialogText>
      <S.DialogText>Please make sure you respond to this Email in order for us to activate your account.</S.DialogText>
    </S.DialogContainer>
  </BaseDialog>
);

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessDialog;
