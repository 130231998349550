import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import { ButtonFlat } from 'components/Layout/Buttons';
import { SchedullingTriggers } from 'components/Layout/Icons';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

import { TASK_CREATION_OPTIONS } from '../constants';
import * as S from '../styled';

export const TaskCreationInfo = () => {
  const tasksSchedulesTrigger = useSelector(state => state.settings.list.TasksSchedulesTrigger);
  const settingLabel = TASK_CREATION_OPTIONS.find(({ value }) => value === tasksSchedulesTrigger)?.label;
  const confirmationModal = useModal(ConfirmationModal);

  const closeAlertDialog = () => confirmationModal.hide();

  const openAlertDialog = () =>
    confirmationModal.show({
      text: (
        <div>
          <SchedullingTriggers />
          <S.ConfirmationText>
            Task creation lead days are globally set
            <br />
            by an administrator.
          </S.ConfirmationText>
          <ActionsContainer>
            <ActionButton isNext label="OK" onClick={closeAlertDialog} />
          </ActionsContainer>
        </div>
      ),
      maxWidth: '450px',
    });

  return (
    <ButtonFlat
      onClick={openAlertDialog}
      icon={<SchedullingTriggers />}
      labelPosition="before"
      label={
        <>
          <S.RedText>Task Creation:</S.RedText>
          <S.BoldText>{settingLabel}</S.BoldText>
        </>
      }
    />
  );
};
