import { HTMLInputTypeAttribute } from 'react';
import styled, { FlattenInterpolation, ThemeProps } from 'styled-components';

const Wrapper = styled.div<{ type?: HTMLInputTypeAttribute; styles?: FlattenInterpolation<ThemeProps<any>>[] }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ styles }) => styles}

  ${({ type }) =>
    type === 'search' &&
    `
      gap: 10px;
    `}
`;

const TextInput = styled.input<{
  styles?: FlattenInterpolation<ThemeProps<any>>[];
}>`
  width: 100%;
  border-radius: 4px;
  padding: 1rem 1.2rem;
  border: 2px solid ${({ theme }) => theme.lightGrey};
  font-size: 1em;

  &:not(:placeholder-shown) + span,
  &:focus + span {
    transform: translateX(10px) translateY(-25px);
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0 6px;
    background-color: ${({ theme }) => theme.primaryWhite};
  }

  &:not(:focus) + span {
    color: #808080;
  }

  ${({ styles }) => styles}
`;

const Label = styled.span<{ styles?: FlattenInterpolation<ThemeProps<any>>[] }>`
  position: absolute;
  left: 0;
  padding-left: 1.2rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.primaryGrey};
  pointer-events: none;
  transition: 0.6s;

  ${({ styles }) => styles}
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.mainRed};
  margin-top: 10px;
`;

export { Wrapper, TextInput, Label, ErrorMessage };
