import React from 'react';
import styled from 'styled-components';

import BaseDialog from 'components/Dialogs/Base';
import { ButtonFlat } from 'components/Layout/Buttons';
import { PowerIcon } from 'components/Layout/Icons';

export const CancelButton = styled(ButtonFlat).attrs({
  label: 'Close',
  secondary: true,
})`
  min-width: 50% !important;
`;

export const DialogContainer = styled(BaseDialog).attrs({
  actionsContainerStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  title: 'User Profile',
})``;

export const LogOutButton = styled(ButtonFlat).attrs({
  icon: <PowerIcon />,
  label: 'Logout',
  primary: true,
})`
  min-width: 50% !important;
  border-left: 1px solid ${({ theme }) => theme.lightGrey} !important;
`;
