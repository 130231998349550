import styled, { css } from 'styled-components';
import { InputBlockLabel } from 'components/Layout/Containers';
import { LinkExternalIcon } from 'components/Layout/Icons';

const BACKGROUNDS: Record<string, string> = {
  info: 'mainRed',
  inventoryLevels: 'primaryLists',
};

const RootContainer = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

const LinkIcon = styled(LinkExternalIcon)`
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.primaryBlack};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
`;

const Label = styled(InputBlockLabel)<{ name: string }>`
  background: ${({ theme, name }) => theme[BACKGROUNDS[name]]};
  text-align: start;
  padding: 14px;
`;

const PartInfoContainer = styled.div`
  padding: 20px;
`;

const inputStyles = css`
  border: none;
  border-radius: 0;
  padding: 0.6rem 1rem;

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.darkGrey};
  }

  :disabled {
    background-color: ${({ theme }) => theme.primaryWhite};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px;
`;

const Image = styled.img`
  width: 85px;
  height: 85px;
`;

const ScanCodeContainer = styled.div`
  border: 1px dashed ${({ theme }) => theme.primaryGrey};
  padding: 20px;
  border-radius: 10px;
`;

const ConfirmationText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 1.2rem;
  margin: 10px 0;

  & > p,
  div {
    font-weight: 300;
  }
`;

const inputWrapperStyles = css`
  margin: 16px 0;
  border-bottom: 1.6px solid ${({ theme }) => theme.textGray};
`;

const radioGroupStyle = { alignSelf: 'center', width: '40%' };

export {
  RootContainer,
  Container,
  Label,
  Text,
  Image,
  Wrapper,
  LinkIcon,
  PartInfoContainer,
  ScanCodeContainer,
  ConfirmationText,
  inputStyles,
  inputWrapperStyles,
  radioGroupStyle,
};
