export const validateHour = Hour => {
  if (Hour && (!Number(Hour) || Number(Hour) < 0 || Number(Hour) > 12)) {
    return true;
  }
  return false;
};

export const validateMinute = Minute => {
  if (Minute && (!Number(Minute + 1) || Number(Minute) < 0 || Number(Minute) > 59)) {
    return true;
  }
  return false;
};

export const periods = ['am', 'pm'];
