import { Field } from 'react-final-form';
import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const ShortcutForm = ({ toggled, onToggle, error, values, submitSucceeded, modifiedSinceLastSubmit }) => (
  <S.FormContainer>
    <S.FieldBlock>
      <Field component={S.TextField} name="Name" floatingLabelText="Shortcut Name" />
    </S.FieldBlock>
    {!!error.length && values.Name && submitSucceeded && !modifiedSinceLastSubmit && (
      <S.ErrorContainer>{error}</S.ErrorContainer>
    )}
    <S.Title>Shortcut Automation</S.Title>
    <S.ToggleBlock>
      <S.ToggleLabel>Fixed</S.ToggleLabel>
      <S.ShowAllToggle defaultToggled toggled={toggled} onToggle={onToggle} />
      <S.ToggleLabel>Dynamic</S.ToggleLabel>
    </S.ToggleBlock>
    <S.SmallText>
      Fixed: Run the action one time and it will create checklists for the next scheduled period only and stop again
    </S.SmallText>
    <S.SmallText>
      Dynamic: Checklists are being created at the begging of every period(day, week, month etc) until the last period
      is reached
    </S.SmallText>
  </S.FormContainer>
);

ShortcutForm.propTypes = {
  toggled: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  values: PropTypes.shape().isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  modifiedSinceLastSubmit: PropTypes.bool.isRequired,
};

export default ShortcutForm;
