import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { InputBlockLabel } from 'components/Layout/Containers';

export const FieldBlockContainer = styled.div`
  display: flex;
`;

export const FieldBlock = styled(FieldBlockContainer)`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 10px;
  width: 20%;

  input {
    font-size: 13px !important;
  }
`;

export const SecondaryContainer = styled(FieldBlockContainer)`
  display: flex;
  justify-content: flex-end;
`;

export const FieldLabel = styled.p`
  color: ${({ theme }) => theme.primaryGrey};
  font-weight: 300;
  padding: 13px 0 5px 13px;
  text-align: left;
  margin: 0;
`;

export const Block = styled(Flex).attrs({
  column: true,
  justify: 'space-between',
})`
  background: ${({ theme }) => theme.primaryWhite};
  border-radius: 5px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  padding-bottom: 15px;
  margin-top: 35px;
  overflow: hidden;
`;

export const AssignementBlock = styled(Block)`
  margin-bottom: 30px;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme, isRed }) => (!isRed ? theme.primarySites : theme.mainRed)};
  background: ${({ theme, isDisabled }) => isDisabled && theme.textGray};
  text-align: left;
  margin-bottom: 15px;
`;

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const radioStyle = {
  display: 'flex',
};

export const radioIntervalStyle = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  width: '100%',
};

export const InfoSmall = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const RedInfoSmall = styled(InfoSmall)`
  color: ${({ theme }) => theme.mainRed};
  display: flex;
  :last-child {
    margin-left: 17px;
  }
`;

export const SmallInfoContainer = styled.div`
  display: flex;
  margin: 10px 0 0 10px;
`;
