import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from '../../v1/Base';

import * as S from './styled';

const FormControls = ({
  BigButtons,
  cancelLabel,
  deleteLabel,
  createQR,
  customColor,
  disableButtons,
  handleClose,
  handleClick,
  handleDelete,
  handleSaveContinue,
  icon,
  isCopy,
  isSearch,
  isSearchAll,
  saveContinueLabel,
  submitLabel,
  withCancel,
  withDelete,
  withOnClick,
  withoutTopBorder,
  withReset,
  withSaveContinue,
  withSubmit,
  withGreenButton,
  isNewSubmitButton,
  isNewDeleteButton,
  withGreenBorder,
  hasPadding,
  gapValue,
}) => (
  <S.ButtonsBlock
    withSaveContinue={withSaveContinue}
    BigButtons={BigButtons}
    withoutTopBorder={withoutTopBorder}
    hasPadding={hasPadding}
    gapValue={gapValue}
  >
    {withReset && (
      <S.SaveContinueButton
        label={saveContinueLabel}
        type="submit"
        onClick={handleSaveContinue}
        disabled={disableButtons}
      />
    )}
    {withDelete && <S.DeleteButton type="button" onClick={handleDelete} disabled={disableButtons} />}
    {withSaveContinue && !isCopy && withSubmit && !isSearch && (
      <S.SaveContinueButton
        label={saveContinueLabel}
        type="submit"
        onClick={handleSaveContinue}
        disabled={disableButtons}
        green={withGreenButton ? 1 : 0}
        border={withGreenBorder ? 1 : 0}
      />
    )}
    {icon && withSubmit && <S.SubmitIconButton icon={icon} type="submit" />}
    {!icon && !isCopy && withSubmit && !isSearch && (
      <S.SubmitButton
        type="submit"
        green={withGreenButton ? 1 : 0}
        border={withGreenBorder ? 1 : 0}
        label={submitLabel}
        disabled={disableButtons}
      />
    )}
    {isCopy && !isSearch && !createQR && <S.CopyButton type="submit" disabled={disableButtons} />}
    {withSubmit && isSearch && <S.SearchButton type="submit" disabled={disableButtons} />}
    {isSearchAll && <S.SearchAllButton type="button" onClick={handleClick} disabled={disableButtons} />}
    {withOnClick && createQR && <S.CreateButton type="button" onClick={handleClick} disabled={disableButtons} />}
    {withCancel && (
      <S.CancelCustomButton
        type="button"
        label={cancelLabel}
        customColor={customColor}
        onClick={handleClose}
        disabled={disableButtons}
      />
    )}
    {isNewDeleteButton && (
      <ActionButton
        type="button"
        label={deleteLabel}
        onClick={handleDelete}
        disabled={disableButtons}
        labelFontSize="18px"
      />
    )}
    {isNewSubmitButton && (
      <ActionButton
        type="submit"
        label={submitLabel || 'Save'}
        isGreenLabel
        isNext
        disabled={disableButtons}
        labelFontSize="18px"
      />
    )}
  </S.ButtonsBlock>
);

FormControls.propTypes = {
  BigButtons: PropTypes.bool,
  cancelLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
  createQR: PropTypes.bool,
  disableButtons: PropTypes.bool,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSaveContinue: PropTypes.func,
  icon: PropTypes.node,
  isCopy: PropTypes.bool,
  isSearch: PropTypes.bool,
  isSearchAll: PropTypes.bool,
  saveContinueLabel: PropTypes.string,
  submitLabel: PropTypes.string.isRequired,
  withCancel: PropTypes.bool,
  withDelete: PropTypes.bool,
  withOnClick: PropTypes.bool,
  withoutTopBorder: PropTypes.bool,
  withReset: PropTypes.bool,
  withSaveContinue: PropTypes.bool,
  withSubmit: PropTypes.bool.isRequired,
  customColor: PropTypes.string.isRequired,
  withGreenButton: PropTypes.bool.isRequired,
  isNewSubmitButton: PropTypes.bool,
  isNewDeleteButton: PropTypes.bool,
  hasPadding: PropTypes.bool,
  gapValue: PropTypes.string,
  withGreenBorder: PropTypes.bool,
};

FormControls.defaultProps = {
  BigButtons: false,
  createQR: false,
  disableButtons: false,
  handleClick: () => {},
  handleDelete: () => {},
  handleSaveContinue: () => {},
  handleClose: () => {},
  icon: null,
  isCopy: false,
  isSearch: false,
  isSearchAll: false,
  withoutTopBorder: false,
  withSaveContinue: false,
  withCancel: false,
  withDelete: false,
  withOnClick: false,
  withReset: false,
  cancelLabel: '',
  deleteLabel: 'Delete',
  saveContinueLabel: '',
  isNewSubmitButton: false,
  isNewDeleteButton: false,
  withGreenBorder: false,
  hasPadding: false,
  gapValue: '',
};

export default FormControls;
