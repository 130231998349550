import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/Layout/Progresses';
import { TutorialsIcon } from '../../../../Layout/Icons';
import * as G from '../InstructionBlock/styled';

import * as S from './styled';

const TutorialBlock = ({ data, isHome, onNext, videosCount, isLoading }) => (
  <G.Instruction isHome={isHome}>
    <G.ModuleIcon color={data.color}>
      <TutorialsIcon style={G.IconStyle} module={data.title} />
      <G.Title title="Tutorials" module={data.title}>
        Tutorials
      </G.Title>
    </G.ModuleIcon>
    <S.InfoBlockContainer>
      <G.Purpose>
        <S.PurposeBold>Tutorial Videos</S.PurposeBold>
      </G.Purpose>
      <G.SpecificIcon tooltip={data.tooltip}>
        <data.tooltipIcon />
      </G.SpecificIcon>
      <S.BottomContainer>
        <S.ActionButton type="button" label="Click here to view videos" disabled={!videosCount} onClick={onNext} />
        <S.AvailableInfoBlock>
          Available: {isLoading ? <Loader size={20} isCentered={false} /> : videosCount}
        </S.AvailableInfoBlock>
      </S.BottomContainer>
    </S.InfoBlockContainer>
  </G.Instruction>
);

TutorialBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    purpose: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.func.isRequired,
      }),
    ).isRequired,
    tooltipIcon: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  isHome: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  videosCount: PropTypes.number.isRequired,
};

TutorialBlock.defaultProps = {
  isHome: true,
};

export default TutorialBlock;
