import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { InfoIcon } from 'components/Layout/Icons';
import validate from './validator';

import * as S from './styled';

const SiteForm = () => (
  <S.FormContainer>
    <S.Section>
      <S.SectionHeader>New Item Info</S.SectionHeader>
      <S.SectionBody>
        <S.FieldBlock>
          <Field component={TextFieldAdapter} name="Name" floatingLabelText="Item Name" />
        </S.FieldBlock>
      </S.SectionBody>
    </S.Section>
  </S.FormContainer>
);

class Setup extends Component {
  static propTypes = {
    setCurrentTemplate: PropTypes.func.isRequired,
    currentTemplate: PropTypes.shape().isRequired,
    currentSite: PropTypes.shape().isRequired,
    onNext: PropTypes.func.isRequired,
    openItemLimitDialog: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    currentItemsNumber: PropTypes.number.isRequired,
    tooltipText: PropTypes.string,
  };

  static defaultProps = {
    tooltipText: '',
  };

  onSubmitForm = ({ values, onNext }) => {
    const { onExit, currentItemsNumber, limit, openItemLimitDialog } = this.props;
    if (currentItemsNumber >= limit) {
      onExit();
      openItemLimitDialog();
    } else {
      this.props.setCurrentTemplate({ ...this.props.currentTemplate, ItemName: values.Name });
      onNext();
    }
  };

  render() {
    const { currentTemplate, currentSite, onNext, tooltipText } = this.props;

    return (
      <S.Container>
        <S.TopInfo>
          <S.MainInfoContainer>
            <S.Title>
              {currentSite?.additionalId.length > 0
                ? `${currentSite?.additionalId.substring(0, 5)} ${currentSite?.name}`
                : currentSite?.name}
            </S.Title>
            {tooltipText && (
              <S.InfoTooltip tooltip={tooltipText} tooltipPosition="bottom-left">
                <InfoIcon />
              </S.InfoTooltip>
            )}
          </S.MainInfoContainer>
          <S.Title>
            Template: <S.BoldText>{currentTemplate.Name}</S.BoldText>
          </S.Title>
          <S.SubInfo>Fill in some basic Item information.</S.SubInfo>
          <S.SubInfo>More details can be added later</S.SubInfo>
        </S.TopInfo>

        <Form
          onSubmit={values => this.onSubmitForm({ values, onNext })}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SiteForm values={values} />
              <ActionsContainer paddingValue={18}>
                <ActionButton type="submit" label="Next: Add tasks to this item" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ listTemplates }) => ({
  limit: listTemplates.limits.MaxItemsNumber,
  currentItemsNumber: listTemplates.limits.CurrentItemsNumber,
});

export default connect(mapStateToProps, null)(Setup);
