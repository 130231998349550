import http from '../index';

export const getTaskTypes = () => http.post('/MasterTaskType/List');

export const putTaskTypes = (id, value) => http.post(`/MasterTaskType/Put?id=${id}`, { dto: { value } });

export const getAssetCategoryList = () => http.post('MasterAssetCategory/List');

export const getAssetCategory = id => http.post('MasterAssetCategory/Get', { id });

export const createAssetCategory = dto => http.post('MasterAssetCategory/Post', { dto });

export const updateAssetCategory = dto => http.post('MasterAssetCategory/Put', { dto });

export const deleteAssetCategory = id => http.post('MasterAssetCategory/Delete', { id });

export const getAssetTypeList = filter => http.post('MasterAssetType/List', { filter });

export const getAssetType = id => http.post('MasterAssetType/Get', { id });

export const createAssetType = dto => http.post('MasterAssetType/Post', { dto });

export const updateAssetType = dto => http.post('MasterAssetType/Put', { dto });

export const deleteAssetType = id => http.post('MasterAssetType/Delete', { id });
