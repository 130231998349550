import React from 'react';
import { PropTypes } from 'prop-types';

import * as S from './styled';

const TestrunButton = ({ onClick, isCentered, disabled, label, containerStyles }) => (
  <S.ButtonContainer
    disabled={disabled}
    onClick={onClick}
    isCentered={isCentered}
    type="button"
    styles={containerStyles}
  >
    <S.IconContainer disabled={disabled}>
      <S.TestRunIcon />
    </S.IconContainer>
    <S.Text>{label}</S.Text>
  </S.ButtonContainer>
);

TestrunButton.propTypes = {
  isCentered: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  containerStyles: PropTypes.array,
};

TestrunButton.defaultProps = {
  isCentered: false,
  disabled: false,
  label: 'Testrun results',
  containerStyles: [],
};

export default TestrunButton;
