import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

const UpgradeInfoSuccessDialog = NiceModal.create(({ isActivator }) => {
  const modal = useModal();

  return (
    <BaseDialog
      open={modal.visible}
      onRequestClose={modal.hide}
      title={isActivator ? 'Confirmation' : 'Success'}
      titleColor={isActivator ? theme.mainGreen : theme.signUpColor}
    >
      <S.DialogContainer>
        {!isActivator ? (
          <>
            <S.DialogText>Thank you!</S.DialogText>
            <S.DialogText>Your request is successfully submitted.</S.DialogText>
            <S.DialogText>We&apos;ll notify you when it&apos;s processed.</S.DialogText>
          </>
        ) : (
          <>
            <S.DialogText>Your upgrade request has been sent!</S.DialogText>
            <ActionsContainer>
              <ActionButton label="Next" isNext onClick={modal.hide} />
            </ActionsContainer>
          </>
        )}
      </S.DialogContainer>
    </BaseDialog>
  );
});

UpgradeInfoSuccessDialog.propTypes = {
  isActivator: PropTypes.bool,
};

UpgradeInfoSuccessDialog.defaultProps = {
  isActivator: false,
};

export default UpgradeInfoSuccessDialog;
