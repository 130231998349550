import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import List from './pages/List';

const Sites = () => (
  <Switch>
    <Route exact path="/sites" component={List} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Sites;
