import { call, put, takeEvery } from 'redux-saga/effects';

import { addDistributionListsGroupLinks, addDistributionListPartItemLinks } from 'http/distributions';
import {
  addPartGroupItemLink,
  addPartItemLink,
  createOEM,
  createPart,
  createPartGroup,
  createPartGroupLink,
  createSupplier,
  deletePartFromItem,
  deletePartGroupFromItem,
  fetchLastExecutionDate,
  fetchLastExecutionGroupDate,
  fetchListPartGroupsByItem,
  fetchListPartsByItem,
  fetchOEMs,
  fetchPartById,
  fetchPartGroups,
  fetchParts,
  fetchPartsByGroupId,
  fetchSuppliers,
  OEMEdit,
  partEdit,
  removeOEM,
  removePart,
  removePartFromGroup,
  removePartGroup,
  removePartGroups,
  removeParts,
  removePartsGroupLink,
  removeSupplier,
  supplierEdit,
} from 'http/parts';
import { actions as unitsActions } from 'redux/units';
import { formatServerError } from 'helpers';

import { actions } from './index';

function* getParts() {
  try {
    const { data } = yield call(fetchParts);
    yield put(actions.getPartsSuccess(data.root));
  } catch (error) {
    yield put(actions.getPartsFailure(formatServerError(error, 'An error occurred while fetching parts.')));
  }
}

function* getPartById({ payload }) {
  try {
    const { data } = yield call(fetchPartById, payload);
    yield put(actions.getPartByIdSuccess(data.entity));
  } catch (error) {
    yield put(actions.getPartByIdFailure(formatServerError(error, 'An error occurred while getting part by id')));
  }
}

function* getPartsByGroupId({ payload }) {
  try {
    const { data } = yield call(fetchPartsByGroupId, payload);
    yield put(actions.getPartsByGroupIdSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getPartsByGroupIdFailure(formatServerError(error, 'An error occurred while fetching group parts.')),
    );
  }
}

function* addPart({ payload }) {
  try {
    yield call(createPart, payload);
    yield put(actions.addPartSuccess());
    yield* getParts();
  } catch (error) {
    yield put(actions.addPartFailure(formatServerError(error, 'An error occurred while adding a part.')));
  }
}

function* editPart({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(partEdit, payload);
    yield put(actions.editPartSuccess(entity));
  } catch (error) {
    yield put(actions.editPartFailure(formatServerError(error, 'An error occurred while editing part')));
  }
}

function* getOEMs() {
  try {
    const { data } = yield call(fetchOEMs);
    yield put(actions.getOemListSuccess(data.root));
  } catch (error) {
    yield put(actions.getOemListFailure(formatServerError(error, 'An error occurred while fetching OEMs.')));
  }
}

function* addOEM({ payload }) {
  try {
    yield call(createOEM, payload);
    yield put(actions.addOemSuccess());
    yield* getOEMs();
  } catch (error) {
    yield put(actions.addOemFailure(formatServerError(error, 'An error occurred while adding an OEM.')));
  }
}

function* editOEM({ payload }) {
  try {
    yield call(OEMEdit, payload);
    yield put(actions.editOemSuccess());
    yield* getOEMs();
  } catch (error) {
    yield put(actions.editOemFailure(formatServerError(error, 'An error occurred while edititng an OEM.')));
  }
}

function* deleteOEM({ payload }) {
  try {
    yield call(removeOEM, payload);
    yield put(actions.deleteOemSuccess());
    yield* getOEMs();
  } catch (error) {
    yield put(actions.deleteOemFailure(formatServerError(error, 'An error occurred while edititng an OEM.')));
  }
}

function* getSuppliers() {
  try {
    const { data } = yield call(fetchSuppliers);
    yield put(actions.getSuppliersListSuccess(data.root));
  } catch (error) {
    yield put(actions.getSuppliersListFailure(formatServerError(error, 'An error occurred while fetching Supplies.')));
  }
}

function* addSupplier({ payload }) {
  try {
    yield call(createSupplier, payload);
    yield put(actions.addSupplierSuccess());
    yield* getSuppliers();
  } catch (error) {
    yield put(actions.addSupplierFailure(formatServerError(error, 'An error occurred while adding an Supplier.')));
  }
}

function* editSupplier({ payload }) {
  try {
    yield call(supplierEdit, payload);
    yield put(actions.editSupplierSuccess());
    yield* getSuppliers();
  } catch (error) {
    yield put(actions.editSupplierFailure(formatServerError(error, 'An error occurred while edititng an Supplier.')));
  }
}

function* deleteSupplier({ payload }) {
  try {
    yield call(removeSupplier, payload);
    yield put(actions.deleteSupplierSuccess());
    yield* getSuppliers();
  } catch (error) {
    yield put(actions.deleteSupplierFailure(formatServerError(error, 'An error occurred while deleting an Supplier.')));
  }
}

function* getPartGroups() {
  try {
    const { data } = yield call(fetchPartGroups);
    yield put(actions.getPartGroupsSuccess(data.root));
  } catch (error) {
    yield put(actions.getPartGroupsFailure(formatServerError(error, 'An error occurred while fetching part Groups.')));
  }
}

function* addPartGroup({ payload }) {
  const { values, cb } = payload;
  try {
    const { data } = yield call(createPartGroup, values);
    yield put(actions.addPartGroupSuccess());
    yield* getPartGroups();
    if (cb) {
      cb(data.entity);
    }
  } catch (error) {
    yield put(actions.addPartGroupFailure(formatServerError(error, 'An error occurred while adding a part group.')));
  }
}

function* addPartGroupLink({ payload }) {
  try {
    yield call(createPartGroupLink, payload);
    yield put(actions.addPartGroupLinkSuccess());
    yield* getPartGroups();
    yield* getPartsByGroupId({ payload: { groupId: payload.partGroupDto.Id } });
  } catch (error) {
    yield put(
      actions.addPartGroupLinkFailure(formatServerError(error, 'An error occurred while adding a part group link.')),
    );
  }
}

function* deletePartsGroupLink({ payload }) {
  try {
    yield call(removePartsGroupLink, payload);
    yield put(actions.deletePartsGroupLinkSuccess());
    yield* getPartGroups();
    yield* getPartsByGroupId({ payload: { groupId: payload.partGroupId } });
  } catch (error) {
    yield put(
      actions.deletePartsGroupLinkFailure(
        formatServerError(error, 'An error occurred while deleting parts from group.'),
      ),
    );
  }
}

function* getListPartsByItem({ payload }) {
  try {
    const { data } = yield call(fetchListPartsByItem, payload);
    yield put(actions.getListPartsByItemSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getListPartsByItemFailure(
        formatServerError(error, 'An error occured while getting list of parts by item'),
      ),
    );
  }
}

function* createPartItemLink({ payload }) {
  try {
    yield call(addPartItemLink, payload);
    yield put(actions.createPartItemLinkSuccess());
    yield* getListPartsByItem({ payload: { itemId: payload.itemId } });
    yield put(unitsActions.getListUnitsWithItemsRequest(payload.siteId));
  } catch (error) {
    yield put(
      actions.createPartItemLinkFailure(formatServerError(error, 'An error occurred while creating part item link')),
    );
  }
}

function* removePartFromItem({ payload }) {
  try {
    yield call(deletePartFromItem, payload);
    yield put(actions.removePartFromItemSuccess());
    yield* getListPartsByItem({ payload: { itemId: payload.itemId } });
    yield put(unitsActions.getListUnitsWithItemsRequest(payload.siteId));
  } catch (error) {
    yield put(
      actions.removePartFromItemFailure(formatServerError(error, 'An error occurred while remove part from item')),
    );
  }
}

function* getListPartGroupsByItem({ payload }) {
  try {
    const { data } = yield call(fetchListPartGroupsByItem, payload);
    yield put(actions.getListPartGroupsByItemSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getListPartGroupsByItemFailure(
        formatServerError(error, 'An error occured while getting list groups of part by item'),
      ),
    );
  }
}

function* createPartGroupItemLink({ payload }) {
  try {
    yield call(addPartGroupItemLink, payload);
    yield put(actions.createPartGroupItemLinkSuccess());
    yield* getListPartGroupsByItem({ payload: { itemId: payload.itemId } });
    yield put(unitsActions.getListUnitsWithItemsRequest(payload.siteId));
  } catch (error) {
    yield put(
      actions.createPartGroupItemLinkFailure(
        formatServerError(error, 'An error occurred while creating item link with part group'),
      ),
    );
  }
}

function* removePartGroupFromItem({ payload }) {
  try {
    yield call(deletePartGroupFromItem, payload);
    yield put(actions.removePartGroupFromItemSuccess());
    yield* getListPartGroupsByItem({ payload: { itemId: payload.itemId } });
    yield put(unitsActions.getListUnitsWithItemsRequest(payload.siteId));
  } catch (error) {
    yield put(
      actions.removePartGroupFromItemFailure(
        formatServerError(error, 'An error occurred while remove part group from item'),
      ),
    );
  }
}

function* deletePart({ payload }) {
  try {
    yield call(removePart, payload);
    yield put(actions.deletePartSuccess());
    yield* getParts();
  } catch (error) {
    yield put(
      actions.deletePartsGroupLinkFailure(formatServerError(error, 'An error occurred while deleting a part.')),
    );
  }
}

function* deleteParts({ payload }) {
  try {
    yield call(removeParts, payload);
    yield put(actions.deletePartsSuccess());
    yield* getParts();
  } catch (error) {
    yield put(actions.deletePartsFailure(formatServerError(error, 'An error occurred while deleting a parts')));
  }
}

function* deletePartGroup({ payload }) {
  try {
    yield call(removePartGroup, payload);
    yield put(actions.deletePartSuccess());
    yield put(actions.getPartGroupsRequest());
  } catch (error) {
    yield put(
      actions.deletePartsGroupLinkFailure(formatServerError(error, 'An error occurred while deleting a part.')),
    );
  }
}

function* deletePartGroups({ payload }) {
  try {
    yield call(removePartGroups, payload);
    yield put(actions.deletePartGroupsSuccess());
    yield* getPartGroups();
  } catch (error) {
    yield put(
      actions.deletePartGroupsFailure(formatServerError(error, 'An error occurred while deleting part groups')),
    );
  }
}

function* deletePartFromGroup({ payload }) {
  try {
    yield call(removePartFromGroup, payload);
    yield put(actions.deletePartFromGroupSuccess());
    yield* getPartsByGroupId({ payload: { groupId: payload.partGroupId } });
  } catch (error) {
    yield put(
      actions.deletePartFromGroupFailure(
        formatServerError(error, 'An error occurred while deleting a part from group.'),
      ),
    );
  }
}

function* createPartLinks({ payload }) {
  try {
    yield call(addDistributionListPartItemLinks, payload);
    yield put(actions.createPartLinksSuccess());
    yield* getParts();
    yield* getPartGroups();
  } catch (error) {
    yield put(actions.createPartLinksFailure(formatServerError(error, 'An error occurred while creating part links')));
  }
}

function* getLastExecutionDate({ payload }) {
  try {
    const {
      data: {
        entity: { LastExecutionDate },
      },
    } = yield call(fetchLastExecutionDate, payload);
    yield put(actions.getLastExecutionDateSuccess(LastExecutionDate));
  } catch (error) {
    yield put(
      actions.getLastExecutionDateFailure(
        formatServerError(error, 'An error occurred while getting last execution date of part'),
      ),
    );
  }
}

function* createGroupLinks({ payload }) {
  try {
    yield call(addDistributionListsGroupLinks, payload);
    yield put(actions.createGroupLinksSuccess());
    yield* getParts();
    yield* getPartGroups();
  } catch (error) {
    yield put(
      actions.createGroupLinksFailure(formatServerError(error, 'An error occurred while creating group links')),
    );
  }
}

function* getLastExecutionGroupDate({ payload }) {
  try {
    const {
      data: {
        entity: { LastExecutionDate },
      },
    } = yield call(fetchLastExecutionGroupDate, payload);
    yield put(actions.getLastExecutionGroupDateSuccess(LastExecutionDate));
  } catch (error) {
    yield put(
      actions.getLastExecutionGroupDateFailure(
        formatServerError(error, 'An error occurred while getting last execution date of group'),
      ),
    );
  }
}

const partsSagas = [
  takeEvery(actions.getPartsRequest, getParts),
  takeEvery(actions.getPartByIdRequest, getPartById),
  takeEvery(actions.getPartsByGroupIdRequest, getPartsByGroupId),
  takeEvery(actions.getPartGroupsRequest, getPartGroups),
  takeEvery(actions.addPartRequest, addPart),
  takeEvery(actions.editPartRequest, editPart),
  takeEvery(actions.getOemListRequest, getOEMs),
  takeEvery(actions.addOemRequest, addOEM),
  takeEvery(actions.editOemRequest, editOEM),
  takeEvery(actions.deleteOemRequest, deleteOEM),
  takeEvery(actions.getSuppliersListRequest, getSuppliers),
  takeEvery(actions.addSupplierRequest, addSupplier),
  takeEvery(actions.editSupplierRequest, editSupplier),
  takeEvery(actions.deleteSupplierRequest, deleteSupplier),
  takeEvery(actions.addPartGroupRequest, addPartGroup),
  takeEvery(actions.addPartGroupLinkRequest, addPartGroupLink),
  takeEvery(actions.deletePartsGroupLinkRequest, deletePartsGroupLink),
  takeEvery(actions.createPartItemLinkRequest, createPartItemLink),
  takeEvery(actions.getListPartsByItemRequest, getListPartsByItem),
  takeEvery(actions.removePartFromItemRequest, removePartFromItem),
  takeEvery(actions.getListPartGroupsByItemRequest, getListPartGroupsByItem),
  takeEvery(actions.createPartGroupItemLinkRequest, createPartGroupItemLink),
  takeEvery(actions.removePartGroupFromItemRequest, removePartGroupFromItem),
  takeEvery(actions.deletePartRequest, deletePart),
  takeEvery(actions.deletePartsRequest, deleteParts),
  takeEvery(actions.deletePartGroupRequest, deletePartGroup),
  takeEvery(actions.deletePartGroupsRequest, deletePartGroups),
  takeEvery(actions.deletePartFromGroupRequest, deletePartFromGroup),
  takeEvery(actions.createPartLinksRequest, createPartLinks),
  takeEvery(actions.getLastExecutionDateRequest, getLastExecutionDate),
  takeEvery(actions.createGroupLinksRequest, createGroupLinks),
  takeEvery(actions.getLastExecutionGroupDateRequest, getLastExecutionGroupDate),
];

export default partsSagas;
