import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin: 20px;
`;

export const fullWidth = {
  width: '100%',
};
