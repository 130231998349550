export const newPartCards = [
  {
    title: 'My Part Info',
    titleType: 'my',
    prefix: 'MyPart',
    buttons: [
      { buttonTitle: 'Copy\n OEM Data', copyValue: 'OEMPart' },
      { buttonTitle: 'Copy\n Suppl Data', copyValue: 'SupplierPart' },
    ],
  },
  {
    title: 'OEM Name:',
    titleType: 'oem',
    prefix: 'OEMPart',
    field: 'OEMID',
    buttons: [
      { buttonTitle: 'Copy\n My Data', copyValue: 'MyPart' },
      { buttonTitle: 'Copy\n Suppl Data', copyValue: 'SupplierPart' },
    ],
  },
  {
    title: 'Supplier Name:',
    titleType: 'supplier',
    prefix: 'SupplierPart',
    field: 'SupplierID',
    buttons: [
      { buttonTitle: 'Copy\n My Data', copyValue: 'MyPart' },
      { buttonTitle: 'Copy\n OEM Data', copyValue: 'OEMPart' },
    ],
  },
];

export const textFields = ['ID', 'Name'];
export const selectFields = [
  { name: 'CategoryID', list: 'category' },
  { name: 'TypeID', list: 'type' },
  { name: 'UnitOfMeasureID', list: 'unitOfMeasure' },
];

export const fieldLabels = {
  MyPartID: { label: 'My Part ID', placeholder: 'New Part ID' },
  MyPartName: { label: 'Part Name', placeholder: 'New Part Name' },
  MyPartCategoryID: { label: 'Part Category', placeholder: 'New Part Category' },
  MyPartTypeID: { label: 'My Part Type', placeholder: 'New Part Type' },
  MyPartUnitOfMeasureID: { label: 'My Part Unit Of Measure', placeholder: 'New Part Unit Of Measure' },
  OEMPartID: { label: 'OEM Part ID', placeholder: 'New Part OEM ID' },
  OEMPartName: { label: 'Part Name', placeholder: 'New Part OEM Name' },
  OEMPartCategoryID: { label: 'Part Category', placeholder: 'New Part OEM Category' },
  OEMPartTypeID: { label: 'Part Type', placeholder: 'New Part OEM Type' },
  OEMPartUnitOfMeasureID: { label: 'Part Unit Of Measure', placeholder: 'New Part OEM Unit Of Measure' },
  SupplierPartID: { label: 'Suppl Part ID', placeholder: 'New Part Supplier ID' },
  SupplierPartName: { label: 'Part Name', placeholder: 'New Part Supplier Name' },
  SupplierPartCategoryID: { label: 'Part Category', placeholder: 'New Part Supplier Category' },
  SupplierPartTypeID: { label: 'Part Type', placeholder: 'New Part Supplier Type' },
  SupplierPartUnitOfMeasureID: { label: 'Part Unit Of Measure', placeholder: 'New Part Supplier UOM' },
};

export const editModalValues = {
  oems: {
    placeholder: 'filter OEMs',
    title: 'OEM Companies',
    deleteId: 'originalEquipmentManufacturerId',
    addItem: 'addOemRequest',
    editItem: 'editOemRequest',
    deleteItem: 'deleteOemRequest',
  },
  suppliers: {
    placeholder: 'filter Suppliers',
    title: 'Supplier Companies',
    deleteId: 'supplierId',
    addItem: 'addSupplierRequest',
    editItem: 'editSupplierRequest',
    deleteItem: 'deleteSupplierRequest',
  },
};
