import React from 'react';
import styled from 'styled-components';
import Dialog from 'material-ui/Dialog';
import ReactTooltip from 'react-tooltip';

import { isIE11 } from 'helpers';
import { ButtonFlat } from 'components/Layout/Buttons';
import {
  PlayIconFilled,
  PlayIconCircleOutline,
  AlarmOnIcon,
  PauseCircleOutlineIcon,
  ChecklistIcon,
} from 'components/Layout/Icons';
import theme from 'theme';

export const Modal = styled(Dialog).attrs({
  titleStyle: {
    padding: 0,
  },
})`
  z-index: 1500 !important;
  padding-top: 142px !important;
  padding-right: 12px;

  & > div > div {
    width: 50px !important;
    margin: 0 10px auto auto !important;
    & > div > div {
      padding: 0 !important;
      max-height: 670px !important;
    }
  }
`;

export const Triangle = styled.div`
  position: absolute;
  top: -20px !important;
  left: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 20px 10px;
  border-color: transparent transparent ${props => props.theme.primaryScheduler} transparent;
`;

export const Button = styled(ButtonFlat)`
  min-width: 0 !important;
  width: 100% !important;
  height: auto !important;
  line-height: ${isIE11 ? '12px' : 'normal'} !important;
  padding: 3px 0 !important;
  border-radius: 0 !important;
  text-align: center !important;

  & > div {
    display: ${isIE11 ? 'block' : 'flex'};
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${isIE11 ? '0 4px' : 0};
    max-width: ${isIE11 ? '83.6%' : 'auto'};
    & > svg {
      margin: 0 !important;
    }
    & > span {
      display: ${isIE11 ? 'block' : 'inline'} !important;
      padding: 0 !important;
      font-weight: 300 !important;
      font-size: 10px !important;
      text-transform: lowercase !important;
    }
  }
`;

export const CalendarButton = styled(Button).attrs({
  icon: <ChecklistIcon style={{ margin: 0 }} />,
  label: 'checklist',
  backgroundColor: props => props.theme.primaryScheduler,
  hoverColor: props => props.theme.primaryRed,
})`
  color: white !important;
`;

export const SetTimeFrameButton = styled(Button).attrs({
  style: { paddingTop: '10px' },
  icon: props => (props.activeStatus === 'Inactive' ? <AlarmOnIcon color={theme.primaryGrey} /> : <AlarmOnIcon />),
  label: 'set timeframe',
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;
`;

export const StatusButtonDynamic = styled(Button).attrs({
  icon: <PlayIconCircleOutline color={theme.primaryRed} />,
  label: 'start autorun',
  labelStyle: { width: '70%' },
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;
`;

export const StatusButtonManual = styled(Button).attrs({
  label: 'run next',
  labelStyle: { width: '70%' },
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;
`;

export const StatusButtonPaused = styled(Button).attrs({
  icon: <PauseCircleOutlineIcon color={theme.primaryRed} />,
  label: 'stop autorun',
  labelStyle: { width: '70%' },
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;
`;

export const StatusButtonInactiveFilled = styled(Button).attrs({
  icon: <PlayIconFilled color={theme.primaryGrey} />,
  label: 'run next',
  labelStyle: { width: '70%', color: theme.primaryGray },
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;
`;

export const StatusButtonInactiveOutline = styled(Button).attrs({
  icon: <PlayIconCircleOutline color={theme.primaryGrey} />,
  label: 'start autorun',
  labelStyle: { width: '70%', color: theme.primaryGray },
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;
`;

export const Tooltip = styled(ReactTooltip)`
  background-color: ${theme.secondaryGray} !important;
  max-width: 200px;
  min-width: 60px;
  text-align: center;
  max-height: 100px;
  padding: 8px 10px;
  border-radius: 0px;
  font-size: 12px;
  letter-spacing: 0.2px;
  &:after {
    display: none;
  }
`;
