import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { isEmpty } from 'lodash';
import { actions as statusIndexActions } from 'redux/statusIndex';

import { InfoIcon } from 'components/Layout/Icons';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import TestrunButton from 'components/Buttons/TestrunButton';
import Loader from 'components/Loader';

import TestrunDialog from './TestrunDialog';
import ItemRules from './ItemRules';
import { createId, initialRule, generateInitialRules, generateItemRules, checkOptions } from './ItemRules/helpers';

import validate from './validator';
import * as S from './styled';

const KEY_ENTER = 13;

const CharacteristicsRules = ({
  indexItemConfig,
  itemRuleToEdit,
  indexRule,
  loadingConfig,
  indexItemCharacteristicPreview,
  actions,
  indexItemPreview,
  listIndexRule,
  openErrorNameDialog,
  setIndexRule,
  onNext,
  onExit,
  onBack,
}) => {
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);
  const [activePair, setActivePair] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [dataCharacteristics, setDataCharacteristics] = useState([]);
  const [indexItemCharacteristicRules, setIndexItemCharacteristicRules] = useState({});

  useEffect(() => {
    indexItemPreview.forEach(item => {
      actions.fetchIndexItemCharacteristicConfigRequest(item.Id);
    });
  }, [actions, indexItemPreview]);

  useEffect(() => {
    if (!isEmpty(indexItemConfig.DataColumns)) {
      setDataCharacteristics(prevDataCharacteristics => [...prevDataCharacteristics, indexItemConfig]);
      reloadState([...dataCharacteristics, indexItemConfig]);
    }

    return () => {
      actions.setIndexItemConfigSuccess({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexItemConfig.DataColumns]);

  const reloadState = useCallback(
    array => {
      setIndexItemCharacteristicRules(
        generateInitialRules({
          rules: indexRule.IndexItemCharacteristicRules || itemRuleToEdit?.IndexItemCharacteristicRules,
          indexItemPreview: array,
        }),
      );
    },
    [indexRule.IndexItemCharacteristicRules, itemRuleToEdit?.IndexItemCharacteristicRules],
  );

  const onKeyDownHandler = e => {
    if (e.keyCode === KEY_ENTER) {
      e.preventDefault();
    }
  };

  const onChangeHandler = (e, value, name, input) => input.onChange(value);

  const addOption = flag => {
    const currentId = createId(flag);

    setIndexItemCharacteristicRules([
      ...indexItemCharacteristicRules.map(value => {
        if (value.name === flag) {
          value.rules.push({ id: currentId, name: flag, ...initialRule });
        }
        return value;
      }),
    ]);
  };

  const removeOption = (flag, id) => {
    setIndexItemCharacteristicRules([
      ...indexItemCharacteristicRules.map(item => {
        const rules = item.rules.filter(rule => rule.id !== id);
        return { ...item, rules };
      }),
    ]);
  };

  const onSubmitForm = values => {
    const itemValues = generateItemRules(values, indexItemCharacteristicRules, dataCharacteristics);
    const rulesToSend = { ...itemValues };

    const valuesToSend = {
      Name: values.Name,
      ...rulesToSend,
    };

    let isError = false;
    listIndexRule.forEach(item => {
      if (item.Name === valuesToSend.Name) {
        if (itemRuleToEdit?.Name !== valuesToSend.Name) {
          openErrorNameDialog();
          isError = true;
        }
      }
    });

    if (activePair + 2 < dataCharacteristics.length) {
      if (!checkOptions(valuesToSend.IndexItemCharacteristicRules.slice(activePair, activePair + 2)) || isError) {
        setIsErrorShown(true);
      } else {
        setActivePair(activePair + 2);
        setIsErrorShown(false);
      }
    } else {
      setIndexRule(valuesToSend);
      if (!checkOptions(valuesToSend.IndexItemCharacteristicRules) || isError) {
        setIsErrorShown(true);
      } else {
        setIsErrorShown(false);
        onNext();
      }
    }
  };

  const closeDeleteDialog = () => setIsConfirmationDialogOpened(false);

  const onClickDialogHandler = () => {
    setIsConfirmationDialogOpened(false);
    reloadState();
    onExit();
  };

  const getPreviewResults = values => {
    const itemValues = generateItemRules(values, indexItemCharacteristicRules, dataCharacteristics);
    const rulesToSend = { ...itemValues };
    const valuesToSend = {
      Name: values.Name,
      ...rulesToSend,
    };

    setIndexRule(valuesToSend);
    if (!checkOptions(valuesToSend.IndexItemCharacteristicRules)) {
      setIsErrorShown(true);
    } else {
      setIsErrorShown(false);
      actions.fetchIndexItemCharacteristicPreviewRequest({
        IndexItemCharacteristicRules: valuesToSend.IndexItemCharacteristicRules.slice(activePair, activePair + 2),
      });
      setPreviewOpen(true);
    }
  };

  const closePreview = () => setPreviewOpen({ previewOpen: false });

  const previousModal = values => {
    if (activePair > 0) {
      setActivePair(activePair - 2);
    } else {
      const itemValues = generateItemRules(values, indexItemCharacteristicRules, dataCharacteristics);
      const rulesToSend = { ...itemValues };
      const valuesToSend = {
        Name: values.Name,
        ...rulesToSend,
      };
      setIndexRule(valuesToSend);
      onBack();
    }
  };

  return (
    <S.DialogContainer>
      <Loader isLoading={loadingConfig} size="large" />
      <S.FormContainer>
        <Form
          onSubmit={onSubmitForm}
          validate={validate}
          initialValues={itemRuleToEdit?.Name}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <S.FieldBlock>
                <Field
                  component={S.TextField}
                  name="Name"
                  floatingLabelText="Index Rule Name"
                  initialValue={indexRule.Name || itemRuleToEdit?.Name}
                />
              </S.FieldBlock>
              <S.Icon
                tooltip="Selections will determine what will be true to trigger the creation of one or more tasks"
                tooltipStyles={{ width: 120 }}
                tooltipPosition="bottom-left"
              >
                <InfoIcon />
              </S.Icon>
              <S.RedInfo> Set up rules to create tasks when something changes in the Index</S.RedInfo>
              {dataCharacteristics.length ? (
                dataCharacteristics
                  .slice(activePair, activePair + 2)
                  .map((rule, index) => (
                    <ItemRules
                      key={rule.IndexItemId}
                      title={rule.IndexItemName}
                      rules={indexItemCharacteristicRules[activePair + index]}
                      firstColumnOptions={Object.keys(dataCharacteristics[activePair + index].DataColumns)}
                      addOption={addOption}
                      removeOption={removeOption}
                      isInitialValues={!!itemRuleToEdit.Name || !!indexRule.IndexItemCharacteristicRules}
                      values={values}
                      onKeyDownHandler={onKeyDownHandler}
                      onChangeHandler={onChangeHandler}
                    />
                  ))
              ) : (
                <S.EmptyText>This index item has no characteristics.</S.EmptyText>
              )}
              {isErrorShown && <S.ErrorContainer>Please add at least one rule or select</S.ErrorContainer>}
              <TestrunButton isCentered onClick={() => getPreviewResults(values)} />
              <ActionsContainer>
                <ActionButton type="button" label={'Previous'} onClick={() => previousModal(values)} />
                <ActionButton type="submit" isNext label={'Next'} />
              </ActionsContainer>
              <ConfirmationDialog
                labelCancel="Return"
                label="Delete"
                title="Warning"
                maxWidth={'80%'}
                text={
                  <S.ConfirmationContainer>
                    <S.ConfirmationWarning>
                      Are you sure you want to delete?
                      <br /> All data will be removed
                    </S.ConfirmationWarning>
                  </S.ConfirmationContainer>
                }
                open={isConfirmationDialogOpened}
                onClick={onClickDialogHandler}
                handleClose={closeDeleteDialog}
                onCancelClick={closeDeleteDialog}
                isCancelNext
              />
              <TestrunDialog open={previewOpen} handleClose={closePreview} data={indexItemCharacteristicPreview} />
            </form>
          )}
        />
      </S.FormContainer>
    </S.DialogContainer>
  );
};

CharacteristicsRules.propTypes = {
  actions: PropTypes.shape({
    fetchIndexItemCharacteristicPreviewRequest: PropTypes.func.isRequired,
    fetchIndexItemCharacteristicConfigRequest: PropTypes.func.isRequired,
    setIndexItemConfigSuccess: PropTypes.func.isRequired,
  }).isRequired,
  onExit: PropTypes.func.isRequired,
  itemRuleToEdit: PropTypes.shape().isRequired,
  setIndexRule: PropTypes.func.isRequired,
  indexRule: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  indexItemPreview: PropTypes.array.isRequired,
  listIndexRule: PropTypes.array.isRequired,
  openErrorNameDialog: PropTypes.func.isRequired,
  indexItemConfig: PropTypes.array.isRequired,
  indexItemCharacteristicPreview: PropTypes.array.isRequired,
  loadingConfig: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  statusIndex: { indexItemPreview, indexItemCharacteristicPreview, indexItemConfig, loadingConfig },
}) => ({
  indexItemPreview,
  indexItemCharacteristicPreview,
  indexItemConfig,
  loadingConfig,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...statusIndexActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CharacteristicsRules);
