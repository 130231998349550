import React from 'react';
import PropTypes from 'prop-types';

import { DeleteIcon } from 'components/Layout/Icons';
import { ChooseButton, Container, CustomFileInput, DeleteButton, FileInput, FileName } from './styled';

const noNameMessage = 'No file chosen';

const UploadImageRow = ({ fileName, imageType, onDelete, onUpload }) => (
  <Container>
    <div>
      <FileInput id={imageType} onChange={onUpload} />
      <CustomFileInput htmlFor={imageType}>
        <ChooseButton>Choose File</ChooseButton>
        <FileName>{fileName || noNameMessage}</FileName>
      </CustomFileInput>
    </div>
    <DeleteButton disabled={!fileName} onClick={onDelete}>
      <DeleteIcon />
    </DeleteButton>
  </Container>
);

UploadImageRow.defaultProps = {
  fileName: null,
};

UploadImageRow.propTypes = {
  fileName: PropTypes.string,
  imageType: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default UploadImageRow;
