import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import moment from 'moment';

import { TenantType, TiersListType, TierType } from 'configs/propTypes';

import withInputModal from 'components/Dialogs/withInputModal';
import { TextareaField } from 'components/Layout/Textarea';
import { FieldSelect, SelectItem } from 'components/Layout/Selects';
import { RestoreIcon, LaunchIcon } from 'components/Layout/Icons';
import CustomNumberInputField from 'components/Forms/CustomNumberInputField';

import { actions as tenantsActions } from 'redux/tenants';
import { openConfirmationDialog } from 'redux/confirmationHandler';

import theme from 'theme';

import {
  additionalFields,
  defaultAdditionalValues,
  tierSettingsFields,
  recentRequestFields,
  recentRequestInfo,
} from './constants';
import * as S from './styled';

class EditPricingForm extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      change: PropTypes.func.isRequired,
      initialize: PropTypes.func.isRequired,
    }).isRequired,
    tenant: TenantType.isRequired,
    tiers: TiersListType.isRequired,
    values: PropTypes.shape(TierType).isRequired,
    recentRequest: PropTypes.shape(TierType).isRequired,
    requestTenantPricingValues: PropTypes.func.isRequired,
    requestDeleteUpgradeRequest: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    clearRecentUpgradeRequest: PropTypes.func.isRequired,
  };

  state = {
    tier: {},
  };

  componentDidMount() {
    this.props.requestTenantPricingValues({ tenantId: this.props.tenant.Id });
  }

  componentDidUpdate(prevProps) {
    const { tiers, tenant, form } = this.props;

    if (prevProps.tiers.length === 0 && tiers.length > 0) {
      const tier = tiers.find(({ Id }) => Id === tenant.TierId);
      this.setState({ tier: tier || {} });
      form.change('MasterTierId', tenant.TierId);
    }
  }

  handleChangeTier = (e, i, value) => {
    const { tiers, form } = this.props;

    const tier = tiers.find(({ Id }) => Id === value);
    this.setState({ tier });
    form.change('MasterTierId', value);
  };

  handleResetToTier = () => {
    const { tiers, form, values } = this.props;
    const { tier } = this.state;

    const tierValues = tiers.find(({ Id }) => Id === tier.Id);

    form.initialize({ ...values, ...tierValues });
  };

  calculateTotalValue = operator => {
    const { form, values } = this.props;

    form.change('TotalCharge', (Number(values.IotCharge) + Number(values.TasksCharge) + operator).toFixed(2));
  };

  calculateAdditionalTotalValue = operator => {
    const { form, values } = this.props;

    form.change(
      'TotalAdditionalCharges',
      (Number(values.CustomModulesCharges) + Number(values.OtherCharges) + operator).toFixed(2),
    );
  };

  openEmailClient = e => {
    window.location = `mailto:${this.props.recentRequest.RequestorEmail}`;
    e.preventDefault();
  };

  deleteUpgradeRequest = () => {
    this.props.requestDeleteUpgradeRequest({ upgradeTenantRequestId: this.props.recentRequest.Id });
    this.props.clearRecentUpgradeRequest();
  };

  openConfirmationDeleteUpgradeRequest = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Request? This cannot be undone!',
      () => this.deleteUpgradeRequest(),
      'Delete?',
    );
  };

  render() {
    const { form, tenant, tiers, values, recentRequest } = this.props;
    const { tier } = this.state;

    return (
      <S.FormContainer>
        <S.TenantName>{tenant.Name}</S.TenantName>
        <S.IndustryName>Industry: {tenant.IndustryName}</S.IndustryName>
        <S.SelectContainer>
          <S.SelectTierLabel>Limits and Pricing Tier Selection</S.SelectTierLabel>
          <FieldSelect floatingLabelText="Select Tier (name)" value={tier.Id} onChange={this.handleChangeTier}>
            {tiers.length > 0 ? tiers.map(({ Id, Name }) => <SelectItem key={Id} value={Id} primaryText={Name} />) : []}
          </FieldSelect>
        </S.SelectContainer>
        <S.TiersSettingContainer>
          {recentRequest && (
            <S.FormCardContainer>
              <S.RecentRequestHeader>Recent Request</S.RecentRequestHeader>
              <S.CardContentContainer>
                <S.CardContentTitle>Submitted Request</S.CardContentTitle>
                <S.TiersSettingsList>
                  {recentRequestFields.map(({ field, label }) => (
                    <S.TierSettingsFieldContainer key={field} field={field}>
                      <S.TierSettingsField field={field}>{recentRequest[field]}</S.TierSettingsField>
                      {label}
                    </S.TierSettingsFieldContainer>
                  ))}
                </S.TiersSettingsList>
                {recentRequestInfo.map(({ field, title }) => (
                  <S.RecentInfo key={field || 'role'}>
                    {title}
                    {field === 'CreatedOn' ? moment(recentRequest[field]).format('MMM-DD-YYYY') : recentRequest[field]}
                    {field === 'RequestorEmail' && (
                      <S.EmailButton onClick={this.openEmailClient}>
                        <LaunchIcon />
                      </S.EmailButton>
                    )}
                  </S.RecentInfo>
                ))}
                <S.DeleteRequestButton type="button" onClick={this.openConfirmationDeleteUpgradeRequest}>
                  Delete Request
                </S.DeleteRequestButton>
              </S.CardContentContainer>
            </S.FormCardContainer>
          )}
          <S.FormCardContainer>
            <S.CardHeader>Tier Settings</S.CardHeader>
            <S.CardContentContainer>
              <S.CardContentTitle>{`Tier Name: ${tier.Name}`}</S.CardContentTitle>
              <S.TiersSettingsList>
                {tierSettingsFields.map(({ field, label }) => (
                  <S.TierSettingsFieldContainer key={field} field={field}>
                    <S.TierSettingsField field={field}>{tier[field]}</S.TierSettingsField>
                    {field === 'TotalCharge' ? (
                      <S.TierFieldLabel>
                        <b>Total Charge</b>
                      </S.TierFieldLabel>
                    ) : (
                      label
                    )}
                  </S.TierSettingsFieldContainer>
                ))}
              </S.TiersSettingsList>
            </S.CardContentContainer>
          </S.FormCardContainer>
          <S.FormCardContainer>
            <S.CardHeader>Actual Settings</S.CardHeader>
            <S.CardContentContainer>
              <S.CardContentTitle>
                Tenant Actual
                <S.ResetToTierContainer onClick={this.handleResetToTier}>
                  <S.ResetButton>
                    <RestoreIcon />
                  </S.ResetButton>
                  Reset to Tier
                </S.ResetToTierContainer>
              </S.CardContentTitle>
              <S.TiersSettingsList>
                {tierSettingsFields.map(({ field, label, readOnly }) => (
                  <S.TierSettingsFieldContainer key={field} field={field}>
                    <CustomNumberInputField
                      name={field}
                      change={form.change}
                      labelComponent={label}
                      readOnly={readOnly}
                      customChange={field === 'TasksCharge' || field === 'IotCharge' ? this.calculateTotalValue : null}
                      background={field === 'TotalCharge' ? `${theme.primaryLists}80` : theme.lightGrey}
                    />
                  </S.TierSettingsFieldContainer>
                ))}
              </S.TiersSettingsList>
            </S.CardContentContainer>
          </S.FormCardContainer>
        </S.TiersSettingContainer>
        <S.FormCardContainer fullWidth>
          <S.CardHeader>Additional Services</S.CardHeader>
          <S.CardContentContainer>
            {additionalFields.map(({ field, label, textField, textFieldLabel }) => (
              <S.AdditionalServicesFieldContainer key={field}>
                <CustomNumberInputField
                  name={field}
                  change={form.change}
                  labelComponent={label}
                  customChange={this.calculateAdditionalTotalValue}
                />
                <Field component={TextareaField} name={textField} floatingLabelText={textFieldLabel} rows={3} />
              </S.AdditionalServicesFieldContainer>
            ))}
            <CustomNumberInputField
              name="TotalAdditionalCharges"
              change={form.change}
              labelComponent={
                <S.TierFieldLabel>
                  <b>Total Additional Charges (mo)</b>
                </S.TierFieldLabel>
              }
              readOnly
              background={`${theme.primaryLists}80`}
            />
          </S.CardContentContainer>
        </S.FormCardContainer>
        <S.CommonTotalValueContainer>
          <S.CommonTotalFieldValue>
            {Number(values.TotalCharge) + Number(values.TotalAdditionalCharges)}
          </S.CommonTotalFieldValue>
          <S.TierFieldLabel>
            <b>Total Charges (mo)</b>
          </S.TierFieldLabel>
        </S.CommonTotalValueContainer>
      </S.FormContainer>
    );
  }
}

const EditPricing = withInputModal(EditPricingForm);

const EditPricingDialog = props => (
  <EditPricing
    initialValues={props.pricingValues || { ...props.tenant.TierInfo, ...defaultAdditionalValues }}
    {...props}
  />
);

EditPricingDialog.propTypes = {
  tenant: PropTypes.shape({
    TierInfo: PropTypes.shape(TierType),
  }).isRequired,
  pricingValues: PropTypes.oneOfType([PropTypes.shape(TierType), null]).isRequired,
};

const mapStateToProps = ({ tenants }) => ({
  pricingValues: tenants.currentTenantPricingValues,
  tiers: tenants.tiersList,
  recentRequest: tenants.currentTenantRecentUpgrade,
});

const mapDispatchToProps = {
  requestTenantPricingValues: tenantsActions.getTenantPricingValuesRequest,
  requestDeleteUpgradeRequest: tenantsActions.deleteUpgradeRequestRequest,
  openConfirmationDialog,
  clearRecentUpgradeRequest: tenantsActions.clearRecentUpgradeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPricingDialog);
