import styled from 'styled-components';

export const ConfirmationText = styled.p`
  font-size: 18px;
  width: 40%;
  margin: 40px auto;
  text-align: center;
`;

export const BoldText = styled.b`
  font-weight: bold;
  margin: 0 auto 20px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ModalButtonsContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
`;
