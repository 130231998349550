import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowBackIcon, InfoIcon } from 'components/Layout/Icons';

export const envelopeIconStyles = {
  width: '40px',
  height: '40px',
};

export const IconArrowBack = styled(ArrowBackIcon)`
  width: 24px !important;
  height: 24px !important;
  margin-left: 0 !important;
  margin-bottom: -7px;
`;

export const IconInfo = styled(InfoIcon)`
  color: ${({ theme }) => theme.primaryWhite} !important;
  background-color: ${({ theme }) => theme.mainGreen} !important;
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
  margin-bottom: -3px;
  border-radius: 100px;
`;

export const SignInText = styled.span`
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.mainGreen};
  margin: -30px 0 0 71px;
  display: inline-block;

  @media screen and (max-width: 600px) {
    margin-left: 43px;
  }
`;

export const SignInBookmark = styled.div`
  width: 280px;
  height: 55px;
  background-color: ${({ theme }) => theme.primaryWhite};
  position: relative;
  top: 15px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 210px;
  }

  @media screen and (max-width: 385px) {
    width: 190px;
  }

  &::before {
    content: ' ';
    border: 0;
    position: absolute;
    right: 30px;
    top: -30px;
    width: 250px;
    height: 30px;
    border-radius: 30px 30px 0 0;
    background: white;
    z-index: 1;

    @media screen and (max-width: 600px) {
      width: 180px;
    }

    @media screen and (max-width: 385px) {
      width: 160px;
    }
  }

  &::after {
    content: ' ';
    border: 0;
    position: absolute;
    right: 0;
    bottom: 27px;
    width: 30px;
    height: 55px;
    border-radius: 0 0 0 30px;
    background: ${({ theme }) => theme.lightGrey};
  }
`;

export const SignUpBookmark = styled.div`
  width: 280px;
  height: 55px;
  background-color: ${({ theme }) => theme.mainGreen};
  position: relative;
  right: 0px;
  top: -28px;
  cursor: pointer;
  align-self: flex-end;

  @media screen and (max-width: 600px) {
    width: 210px;
  }

  @media screen and (max-width: 385px) {
    width: 190px;
  }

  &::before {
    content: ' ';
    border: 0;
    position: absolute;
    top: 28px;
    left: -1px;
    width: 30px;
    height: 55px;
    border-top-right-radius: 30px;
    background: ${({ theme }) => theme.lightGrey};
    z-index: 1;
  }

  &::after {
    content: ' ';
    border: 0;
    position: absolute;
    left: 29px;
    bottom: -29px;
    width: 251px;
    height: 30px;
    border-radius: 0 0 30px 30px;
    background: ${({ theme }) => theme.mainGreen};

    @media screen and (max-width: 600px) {
      width: 181px;
    }

    @media screen and (max-width: 385px) {
      width: 161px;
    }
  }
`;

export const SignUpText = styled(SignInText)`
  color: ${({ theme }) => theme.primaryWhite};
  margin: 35px 0 0 0px;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 600px) {
    padding-left: 10px;
  }
`;

export const LoginLinkContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 20px;

  @media screen and (max-width: 400px) {
    left: 10px;
  }

  @media screen and (max-width: 355px) {
    left: 0px;
  }
`;

export const LoginLink = styled(Link)`
  color: ${({ theme }) => theme.primaryBlack};
  text-decoration: none;
  position: relative;
  z-index: 2;
`;
