import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import Loader from 'components/Loader';
import * as S from './styled';

const DeleteConfirmationDialog = ({
  isListDeleteModalOpened,
  isChecklistsDeletedInfoModalOpened,
  isTasksDeletedInfoModalOpened,
  listTaskStatus,
  isLoading,
  sitesAvailableToUserFullInfo,
  selectedSite,
  history,
  handleCloseListDeleteModal,
  handleClickListDeleteModal,
  handleCloseChecklistsDeletedInfoModal,
  handleClickChecklistDeletedInfoModal,
  handleCloseTasksDeletedInfoModal,
  onDeleteUnitApprove,
}) => {
  const getSiteWithId = sitesArray => {
    const site = sitesArray.find(siteObject => siteObject.id === selectedSite);

    return (
      <>
        {site.additionalId ? site.additionalId.substring(0, 5) : 'Site ID'} <S.BoldText>{site.name}</S.BoldText>
      </>
    );
  };

  const goToAttention = () => history.push(`/queue/${moment().format('YYYY-MM-DD')}/all`);

  return (
    <>
      <ConfirmationDialog
        label="Alert"
        open={isListDeleteModalOpened}
        width={'80%'}
        maxWidth={'850px'}
        handleClose={handleCloseListDeleteModal}
        text={
          <S.ConfirmationContainer>
            {/* <Overlay show={isLoading}> */}
            <Loader isLoading={isLoading} />
            {/* </Overlay> */}
            <S.RedTitleText>Deleting this List Template may disrupt tasks in progress!</S.RedTitleText>
            <S.RedSubTitleText>
              Completed tasks will be moved to the <S.BoldText>archive</S.BoldText>
            </S.RedSubTitleText>
            <S.Section>
              <S.SectionHeader>Checklist Status</S.SectionHeader>
              <S.SectionBody>
                <S.TaskStatusText>
                  <S.BoldText>This List Template is used by:</S.BoldText>
                </S.TaskStatusText>
                <S.TaskStatusText>
                  <S.BoldText>{listTaskStatus.ChecklistCount}</S.BoldText> Checklists
                </S.TaskStatusText>
              </S.SectionBody>
            </S.Section>
            <S.Section>
              <S.SectionHeader>Task Status</S.SectionHeader>
              <S.SectionBody>
                <S.TaskStatusText>
                  <S.BoldText>{listTaskStatus.InProgressCount}</S.BoldText> Task are in progress
                </S.TaskStatusText>
                <S.TaskStatusText>
                  <S.BoldText>{listTaskStatus.CompletedCount}</S.BoldText> Tasks are completed but not archived
                </S.TaskStatusText>
                <S.RedTaskStatusText>
                  <S.BoldText>{listTaskStatus.RequireAttentionCount}</S.BoldText> Tasks require attention
                </S.RedTaskStatusText>
              </S.SectionBody>
            </S.Section>
            <S.Section>
              <S.SectionHeader>Requiring Attention</S.SectionHeader>
              <S.SectionBody>
                {listTaskStatus.RequireAttentionCount > 0 && (
                  <S.SiteRow>
                    <S.IconButton onClick={goToAttention}>
                      <S.ExitIcon />
                    </S.IconButton>
                    <S.SiteText>{getSiteWithId(sitesAvailableToUserFullInfo)}</S.SiteText>
                  </S.SiteRow>
                )}
              </S.SectionBody>
            </S.Section>
            <S.RedSubTitleText>
              Tasks requiring attention will be deleted if not resolved and completed
            </S.RedSubTitleText>
            <ActionsContainer>
              <ActionButton label="Delete Template" onClick={handleClickListDeleteModal} />
            </ActionsContainer>
          </S.ConfirmationContainer>
        }
      />
      <ConfirmationDialog
        label="Alert"
        open={isChecklistsDeletedInfoModalOpened}
        width={'50%'}
        maxWidth={'350px'}
        handleClose={handleCloseChecklistsDeletedInfoModal}
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationText>
              <S.BoldText>There are</S.BoldText>
              <br />
              {listTaskStatus.ChecklistCount}
              <br />
              <S.BoldText>checklists that will be deleted.</S.BoldText>
            </S.ConfirmationText>
            <S.ConfirmationWarningText>
              Are you sure you want to remove this List?
              <br />
              This action can be undone!
            </S.ConfirmationWarningText>
            <ActionsContainer>
              <ActionButton isNext label="Cancel" onClick={handleCloseChecklistsDeletedInfoModal} />
              <ActionButton label="Remove List" onClick={handleClickChecklistDeletedInfoModal} />
            </ActionsContainer>
          </S.ConfirmationContainer>
        }
      />
      <ConfirmationDialog
        label="Alert"
        open={isTasksDeletedInfoModalOpened}
        width={'50%'}
        maxWidth={'350px'}
        handleClose={handleCloseTasksDeletedInfoModal}
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationBoldText>
              <S.ConfirmationText>All none completed tasks will be deleted</S.ConfirmationText>
            </S.ConfirmationBoldText>
            <S.ConfirmationWarningText>Are you sure you want to remove this List?</S.ConfirmationWarningText>
            <S.ConfirmationWarningText>This action cannot be undone!</S.ConfirmationWarningText>
            <ActionsContainer>
              <ActionButton isNext label="Cancel" onClick={handleCloseTasksDeletedInfoModal} />
              <ActionButton label="Remove List" onClick={onDeleteUnitApprove} />
            </ActionsContainer>
          </S.ConfirmationContainer>
        }
      />
    </>
  );
};

DeleteConfirmationDialog.propTypes = {
  isListDeleteModalOpened: PropTypes.bool.isRequired,
  isChecklistsDeletedInfoModalOpened: PropTypes.bool.isRequired,
  isTasksDeletedInfoModalOpened: PropTypes.bool.isRequired,
  sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
  listTaskStatus: PropTypes.object.isRequired,
  selectedSite: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  handleCloseListDeleteModal: PropTypes.func.isRequired,
  handleClickListDeleteModal: PropTypes.func.isRequired,
  handleCloseChecklistsDeletedInfoModal: PropTypes.func.isRequired,
  handleClickChecklistDeletedInfoModal: PropTypes.func.isRequired,
  handleCloseTasksDeletedInfoModal: PropTypes.func.isRequired,
  onDeleteUnitApprove: PropTypes.func.isRequired,
};

export default DeleteConfirmationDialog;
