import { createActions, handleActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  FETCH_LANDING_PAGE_LIST_REQUEST: undefined,
  FETCH_LANDING_PAGE_LIST_SUCCESS: undefined,
  EDIT_LANDING_PAGE_REQUEST: undefined,
  UPDATE_LANDING_PAGE_SEQUENCE_REQUEST: undefined,
  FETCH_LANDING_PAGE_SECTION_LIST_REQUEST: undefined,
  FETCH_LANDING_PAGE_SECTION_LIST_SUCCESS: undefined,
  CREATE_LANDING_PAGE_SECTION_REQUEST: undefined,
  FETCH_LANDING_PAGE_SECTION_REQUEST: undefined,
  FETCH_LANDING_PAGE_SECTION_SUCCESS: undefined,
  UPDATE_LANDING_PAGE_SECTION_REQUEST: undefined,
  UPDATE_LANDING_PAGE_SECTION_SUCCESS: undefined,
  DELETE_LANDING_PAGE_SECTION_REQUEST: undefined,
  UPDATE_LANDING_PAGE_SECTION_SEQUENCE_REQUEST: undefined,
  FETCH_VIDEOS_COUNT_REQUEST: undefined,
  FETCH_VIDEOS_COUNT_SUCCESS: undefined,
  FETCH_VIDEOS_BY_SECTION_REQUEST: undefined,
  FETCH_VIDEOS_BY_SECTION_SUCCESS: undefined,
  RESET_VIDEOS_COUNT: undefined,
  REFRESH_VIMEO_CACHE_REQUEST: undefined,
  REFRESH_VIMEO_CACHE_SUCCESS: undefined,
  SET_TUTORIAL_PAGE_ERROR: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.fetchLandingPageListRequest, handlers.fetchLandingPageListRequest],
    [actions.fetchLandingPageListSuccess, handlers.fetchLandingPageListSuccess],
    [actions.editLandingPageRequest, handlers.editLandingPageRequest],
    [actions.updateLandingPageSequenceRequest, handlers.updateLandingPageSequenceRequest],
    [actions.fetchLandingPageSectionListRequest, handlers.fetchLandingPageSectionListRequest],
    [actions.fetchLandingPageSectionListSuccess, handlers.fetchLandingPageSectionListSuccess],
    [actions.createLandingPageSectionRequest, handlers.createLandingPageSectionRequest],
    [actions.fetchLandingPageSectionRequest, handlers.fetchLandingPageSectionRequest],
    [actions.fetchLandingPageSectionSuccess, handlers.fetchLandingPageSectionSuccess],
    [actions.updateLandingPageSectionRequest, handlers.updateLandingPageSectionRequest],
    [actions.updateLandingPageSectionSuccess, handlers.updateLandingPageSectionSuccess],
    [actions.deleteLandingPageSectionRequest, handlers.deleteLandingPageSectionRequest],
    [actions.updateLandingPageSectionSequenceRequest, handlers.updateLandingPageSectionSequenceRequest],
    [actions.fetchVideosCountRequest, handlers.fetchVideosCountRequest],
    [actions.fetchVideosCountSuccess, handlers.fetchVideosCountSuccess],
    [actions.fetchVideosBySectionRequest, handlers.fetchVideosBySectionRequest],
    [actions.fetchVideosBySectionSuccess, handlers.fetchVideosBySectionSuccess],
    [actions.resetVideosCount, handlers.resetVideosCount],
    [actions.refreshVimeoCacheRequest, handlers.refreshVimeoCacheRequest],
    [actions.refreshVimeoCacheSuccess, handlers.refreshVimeoCacheSuccess],
    [actions.setTutorialPageError, handlers.setTutorialPageError],
  ]),
  initialState,
);

export default reducer;
