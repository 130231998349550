/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import theme from 'theme';

import { getSchedulerType } from 'helpers/storage';
import { actions as scheduleActions } from 'redux/schedule';

import { SchedulerIndicator } from 'components/Layout/Indicators';
import { ChevronRightIcon } from 'components/Layout/Icons';
import { formatScheduleInfo } from '../formatters';
import { schedulerStatusHandler } from '../helpers';

import * as S from './styled';

export const List = ({ searchData }) => {
  const list = useSelector(state => state.schedule.schedulesList);
  const type = getSchedulerType();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const filterList = item => item.Name?.toLowerCase().includes(searchData.toLowerCase());

  const openModal = () => dispatch(scheduleActions.setIsModalVisible(true));

  const openChecklistInfo = () => dispatch(scheduleActions.setIsOpenChecklistInfo(true));

  const onChecklistClick = item => {
    if (item.ActiveStatus === 'PendingActivation') {
      dispatch(scheduleActions.setIsConfirmationOpened(true));
      dispatch(scheduleActions.setSchedule(item));
      dispatch(scheduleActions.setEditChecklist(true));
    } else if (item.ActiveStatus === 'Inactive') {
      dispatch(scheduleActions.setIsStatusInactive(true));
      dispatch(scheduleActions.setChecklistToEdit(item));
    } else {
      dispatch(scheduleActions.setChecklistToEdit(item));
      openModal();
    }
  };

  const toSchedulesDetails = item => {
    if (item.ActiveStatus === 'PendingActivation') {
      dispatch(scheduleActions.setIsConfirmationOpened(true));
      dispatch(scheduleActions.setSchedule(item));
    } else if (type === 'shortcut') {
      dispatch(scheduleActions.fetchScheduleDetailsRequest({ scheduleId: item.Id }));
      dispatch(scheduleActions.fetchScheduleTemplatesRequest({ scheduleId: item.Id }));
      openChecklistInfo();
    } else {
      history.push(`${location.pathname}/details/${item.Id}`);
    }
  };

  const handleCopyClick = (event, item) => {
    event.stopPropagation();
    dispatch(scheduleActions.setIsCopyModalVisible(true));
    dispatch(scheduleActions.setDuplicatedSchedule(item));
  };

  return (
    <>
      {list.length ? (
        <S.List>
          {list.filter(filterList).map(item => {
            const onItemClick = () => {
              if (type === 'shortcut') {
                toSchedulesDetails(item);
              } else {
                onChecklistClick(item);
              }
            };

            const onDetailsClick = e => {
              e.stopPropagation();
              toSchedulesDetails(item);
            };

            return (
              <S.ListItem key={item.Id} onClick={onItemClick} primaryText={item.Name}>
                <S.StatusBlock>
                  {type === 'manual' && (
                    <ChevronRightIcon
                      color={theme.primaryGrey}
                      style={{ transform: 'scale(1.7)' }}
                      onClick={onDetailsClick}
                    />
                  )}
                  <S.StatusText>{schedulerStatusHandler(item)?.title}</S.StatusText>
                  {type === 'manual' && <S.IconCopy onClick={event => handleCopyClick(event, item)} />}
                  <S.InfoText>{formatScheduleInfo(item)}</S.InfoText>
                  <SchedulerIndicator color={schedulerStatusHandler(item)?.color} />
                </S.StatusBlock>
              </S.ListItem>
            );
          })}
        </S.List>
      ) : (
        <S.EmptyList>
          {type === 'manual' ? (
            <>
              <S.EmptyListText>No Checklists have been created yet.</S.EmptyListText>
              <S.EmptyListText>You can create one by selecting + in the upper right corner</S.EmptyListText>
            </>
          ) : (
            <S.EmptyListText>There are no schedules</S.EmptyListText>
          )}
        </S.EmptyList>
      )}
    </>
  );
};
