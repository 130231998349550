const initialState = {
  error: '',
  isLoading: false,
  options: {
    category: [],
    unitOfMeasure: [],
    weightStandard: [],
    type: [],
    sizingStandard: [],
    materialType: [],
  },
};

export const getPartOptionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getPartOptionsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  options: payload,
});

export const getPartOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const getSinglePartOptionsTypeRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSinglePartOptionsTypeSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  options: {
    ...state.options,
    [payload.type]: payload.options,
  },
});

export const getSinglePartOptionsTypeFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addPartOptionRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addPartOptionSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const addPartOptionFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editPartOptionRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editPartOptionSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const editPartOptionFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePartOptionRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePartOptionSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deletePartOptionFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default initialState;
