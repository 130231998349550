import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseDialog from 'components/Dialogs/Base';
import { getStandartMessage } from './selectors';

import * as S from './styled';

const StandartMessageDialog = ({ handleClose, message, open, title }) => (
  <BaseDialog open={open} onRequestClose={handleClose} title={title}>
    <S.MessageContainer>
      {message.map(text => (
        <S.Item key={text}>{text}</S.Item>
      ))}
    </S.MessageContainer>
  </BaseDialog>
);

StandartMessageDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = ({ settings: { list, masterSettings } }, { userType }) => ({
  message: getStandartMessage(list, masterSettings, userType),
});

export default connect(mapStateToProps)(StandartMessageDialog);
