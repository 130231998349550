import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ButtonIcon } from 'components/Layout/Buttons';
import { DeleteIcon, SettingsIcon } from 'components/Layout/Icons';

import * as S from './styled';

class ListExchangeItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onItemClick: PropTypes.func.isRequired,
    onDeleteVersion: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  render() {
    const { index, item, onDeleteVersion, onItemClick, openModal } = this.props;

    return (
      <S.Container>
        <S.ListVersionBlock>
          <S.Version>{item.ListExchangeVersion}</S.Version>
        </S.ListVersionBlock>
        <S.Line>
          <S.Fields onClick={() => onItemClick(item.Id, item.ListExchangeVersion)}>
            <S.TextBlock>{item.SubmittedByName}</S.TextBlock>
            <S.TextBlock>{moment(item.CreatedOn).format('DD MMM YYYY')}</S.TextBlock>
            <S.Notes>{item.ExchangeNotes}</S.Notes>
          </S.Fields>
          <S.ButtonsBlock>
            <ButtonIcon onClick={() => openModal(item.Id, item.ExchangeNotes)}>
              <SettingsIcon />
            </ButtonIcon>
            <ButtonIcon onClick={() => onDeleteVersion(item.Id, index)}>
              <DeleteIcon />
            </ButtonIcon>
          </S.ButtonsBlock>
        </S.Line>
      </S.Container>
    );
  }
}

export default ListExchangeItem;
