import { createSelector } from 'reselect';
import { stringComparator } from 'helpers';

const getSiteId = siteId => siteId;
const getSiteList = (siteId, sites) => sites;

export const getSiteHubs = createSelector([getSiteId, getSiteList], (siteId, sites) => {
  const currentSite = sites.find(site => site.Id === siteId);
  if (currentSite) {
    return currentSite.Hubs.map(({ GatewaysInHubNumber, Name: hubName, Id }) => ({
      hubs: { name: hubName, gatewaysNumber: GatewaysInHubNumber, id: Id, isRoot: true },
      site: { name: currentSite.Name, city: currentSite.City },
      tenant: {
        name: currentSite.Tenant.Name,
        industry: currentSite.Tenant.IndustryName,
        adminName: currentSite.Tenant.DefaultAdminName,
      },
    })).sort((a, b) => stringComparator(a.hubs.name, b.hubs.name, true));
  }
  return [];
});

export const getRegionsList = createSelector([list => list], list => list.sort((a, b) => stringComparator(a, b, true)));
