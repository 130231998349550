import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ContentContainer } from 'components/Layout/Containers';

import { getSchedulerType } from 'helpers/storage';
import { actions as scheduleActions } from 'redux/schedule';
import { actions as teamActions } from 'redux/teams';

import AddChecklistModal from './AddChecklistModal';
import DuplicateScheduleModal from './DuplicateScheduleModal';
import { List } from './List';
import { DeleteChecklistDialog } from './DeleteChecklistDialog';
import { DeleteChecklistConfirmation } from './DeleteChecklistConfirmation';
import { ChecklistInfoDialog } from './ChecklistInfoDialog';
import { QuickTaskDetails } from './QuickTaskDetails';
import { Header } from './Header';

const SchedulesList = () => {
  const [searchData, setSearchData] = useState('');
  const location = useLocation();
  const history = useHistory();
  const { unitId } = useParams();
  const type = getSchedulerType();

  const checklistToEdit = useSelector(state => state.schedule.checklistToEdit);
  const scheduleInfo = useSelector(state => state.schedule.details);
  const isStatusInactive = useSelector(state => state.schedule.isStatusInactive);
  const duplicateChecklist = useSelector(state => state.schedule.duplicateChecklist);
  const isModalVisible = useSelector(state => state.schedule.isModalVisible);

  const editChecklist = useSelector(state => state.schedule.editChecklist);
  const list = useSelector(state => state.schedule.schedulesList);
  const detailsActivation = useSelector(state => state.schedule.detailsActivation);

  const currentUnit = useSelector(state => state.schedule.currentUnit);

  const dispatch = useDispatch();

  const openModal = () => dispatch(scheduleActions.setIsModalVisible(true));

  const checkChecklistName = name => list.find(el => el.Name === name)?.Name;

  const closeModal = () => {
    dispatch(scheduleActions.setDuplicateChecklist(false));
    dispatch(scheduleActions.setChecklistToEdit({}));
    dispatch(scheduleActions.setIsModalVisible(false));
  };

  const searchInList = event => setSearchData(event.target.value);

  const selectActivation = () => {
    if (editChecklist) {
      dispatch(scheduleActions.setEditChecklist(false));
      if (detailsActivation.ActiveStatus === 'Inactive') {
        dispatch(scheduleActions.setIsStatusInactive(true));
      } else {
        dispatch(scheduleActions.setChecklistToEdit(detailsActivation));
        openModal();
      }
    } else if (type === 'shortcut') {
      dispatch(scheduleActions.fetchScheduleDetailsRequest({ scheduleId: detailsActivation.Id }));
      dispatch(scheduleActions.fetchScheduleTemplatesRequest({ scheduleId: detailsActivation.Id }));
      dispatch(scheduleActions.openChecklistInfo());
    } else {
      history.push(`${location.pathname}/details/${detailsActivation.Id}`);
    }
  };

  const openTaskModal = () => {
    dispatch(scheduleActions.setIsTasksModalOpened(true));
    if (isStatusInactive) {
      dispatch(scheduleActions.setIsStatusInactive(false));
    }
    dispatch(scheduleActions.checklistStatusRequest({ checklistId: checklistToEdit.Id }));
  };

  useEffect(() => {
    dispatch(scheduleActions.schedulesListRequest({ unitId }));
    dispatch(scheduleActions.fetchCurrentUnitRequest({ unitId }));
    dispatch(teamActions.getTeamsRequest());
  }, [dispatch, unitId]);

  useEffect(() => {
    if (scheduleInfo.ChecklistAssignmentType === 0 && scheduleInfo.AssigneeID) {
      dispatch(scheduleActions.getUserManagementRequest({ id: scheduleInfo.AssigneeID }));
    }
  }, [dispatch, scheduleInfo.AssigneeID, scheduleInfo.ChecklistAssignmentType]);

  return (
    <ContentContainer>
      <Header searchInList={searchInList} searchData={searchData} unitName={currentUnit.Name} />
      {type === 'quickTask' ? <QuickTaskDetails /> : <List searchData={searchData} />}
      <AddChecklistModal
        open={isModalVisible}
        handleClose={() => dispatch(scheduleActions.setIsConfirmationDialogOpened(true))}
        handleExit={closeModal}
        currentUnit={currentUnit}
        openTaskModal={openTaskModal}
        checklistToEdit={checklistToEdit}
        dublicateChecklist={duplicateChecklist}
        checkChecklistName={checkChecklistName}
      />
      <DuplicateScheduleModal />
      <DeleteChecklistDialog />
      <DeleteChecklistConfirmation unitId={unitId} openTaskModal={openTaskModal} />
      <ChecklistInfoDialog />
    </ContentContainer>
  );
};

export default SchedulesList;
