export const initialRule = {
  optionName: '',
  optionConstraint: 0,
  optionValue: '',
  matchOperator: 0,
};

export const createId = (flag = '') => `${flag}${new Date().getTime()}`;

export const changeOptionNameControl = (values, rule) => {
  values[`optionValue-${rule.id}`] = '';
  values[`constraint-${rule.id}`] = '';
  return values;
};

const generateRulesFromArrayOfKeys = (localValues, globalValues) => {
  const optionNames = localValues.filter(item => item.includes('optionName'));
  const optionConstraints = localValues.filter(item => item.includes('constraint'));
  const optionValues = localValues.filter(item => item.includes('optionValue'));
  const matchOperator = localValues.find(item => item.includes('match'));
  const rules = optionNames.map((optionNameKey, index) => {
    const item = {};

    item.OptionName = 'CharacteristicName';
    item.ColumnName = globalValues[optionNameKey];
    item.OptionConstraint = parseInt(globalValues[optionConstraints[index]], 10);
    item.OptionValue = globalValues[optionValues[index]];
    item.MatchOperator = parseInt(globalValues[matchOperator], 10);

    return item;
  });

  return rules;
};

export const generateItemRules = (values, rules, indexItem) => {
  const arr = [];
  indexItem.forEach((value, index) => {
    const itemValues = Object.keys(values).filter(item => item.includes(value.IndexItemId));
    const updatedItemValues = itemValues
      .map(item => {
        if (
          item === `match-${value.IndexItemId}` ||
          rules[index].rules.find(rule => rule.id === item.split('-').slice(1, item.split('-').length).join('-'))
        ) {
          return null;
        }
        return item;
      })
      .filter(item => item !== null);
    updatedItemValues.forEach(item => delete values[item]);
    const fixedItemValues = itemValues.filter(item => !updatedItemValues.find(newItem => newItem === item));
    const data = generateRulesFromArrayOfKeys(fixedItemValues, values);
    arr.push({ IndexItemId: value.IndexItemId, Rules: data });
  });
  return { IndexItemCharacteristicRules: arr };
};

export const checkOptions = options => {
  let res = true;
  options.forEach(value => {
    if (value.Rules.length === 0) {
      res = false;
    }
    res = value.Rules.every(item => {
      if (
        !item.OptionName ||
        (!item.OptionValue && item.OptionValue !== 0) ||
        (!item.OptionConstraint && item.OptionConstraint !== 0)
      ) {
        return false;
      }
      return true;
    });
  });

  return res;
};

export const generateInitialRules = ({ rules, indexItemPreview }) => {
  const arr = [];
  if (!rules) {
    indexItemPreview.forEach(item => {
      arr.push({
        name: item?.IndexItemId || item?.Id,
        match: 0,
        rules: [{ id: createId(item?.IndexItemId || item?.Id), name: item?.IndexItemId || item?.Id, ...initialRule }],
      });
    });
    return arr;
  }

  if (rules[0]?.MatchOperator === -1) {
    indexItemPreview.forEach(item => {
      arr.push({
        name: item?.IndexItemId || item?.Id,
        match: 0,
        rules: [{ id: createId(item?.IndexItemId || item?.Id), name: item?.IndexItemId || item?.Id, ...initialRule }],
      });
    });
    return arr;
  }

  indexItemPreview.forEach(item => {
    const characteristicRule = rules.find(rule => rule.IndexItemId === item.IndexItemId);

    const indexItemCharacteristicRule =
      characteristicRule && characteristicRule.Rules.length
        ? {
            name: characteristicRule.IndexItemId || item?.Id,
            match: characteristicRule.Rules[0].MatchOperator,
            rules: characteristicRule.Rules.map((item2, index2) => {
              const tmpId = `${createId(item?.IndexItemId || item?.Id)}${index2}`;
              const fieldNames = [`optionName-${tmpId}`, `constraint-${tmpId}`, `optionValue-${tmpId}`];
              return {
                id: tmpId,
                name: characteristicRule.IndexItemId || item?.Id,
                [fieldNames[0]]: item2.ColumnName,
                [fieldNames[1]]: item2.OptionConstraint,
                [fieldNames[2]]: item2.OptionValue,
                matchOperator: item2.MatchOperator,
              };
            }),
          }
        : {
            name: item?.IndexItemId || item?.Id,
            match: 0,
            rules: [
              { id: createId(item?.IndexItemId || item?.Id), name: item?.IndexItemId || item?.Id, ...initialRule },
            ],
          };
    arr.push(indexItemCharacteristicRule);
  });

  return arr;
};

export const indexItemRules = ['item'];
