import styled from 'styled-components';
import { Box } from 'grid-styled';

const StatisticsModules = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
`;

const StatisticsModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatisticsValue = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 50% !important;
  background-color: ${({ theme, color }) => color || theme.primaryBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, label }) => (label === 'completed' ? theme.primaryBlack : theme.primaryWhite)};
  font-weight: 200;
  font-size: 24px;
`;

const StatisticsLabel = styled(Box)`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.darkGrey};
  text-transform: uppercase;
`;

export { StatisticsModules, StatisticsModuleContainer, StatisticsValue, StatisticsLabel };
