import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';
import { PauseCircleOutlineIcon, OfflineBoltWhite, LoopIcon, PlayIconCircleOutline } from 'components/Layout/Icons';
import { isIE11 } from 'helpers';
import { ButtonFlat } from 'components/Layout/Buttons';

import theme from 'theme';
import ReactTooltip from 'react-tooltip';

export const Button = styled(ButtonFlat)`
  min-width: 0 !important;
  width: 100% !important;
  height: auto !important;
  line-height: ${isIE11 ? '12px' : 'normal'} !important;
  padding: 3px 0 !important;
  border-radius: 0 !important;
  text-align: center !important;

  & > div {
    display: ${isIE11 ? 'block' : 'flex'};
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${isIE11 ? '0 4px' : 0};
    max-width: ${isIE11 ? '83.6%' : 'auto'};
    & > svg {
      margin: 0 !important;
    }
    & > span {
      display: ${isIE11 ? 'block' : 'inline'} !important;
      padding: 0 !important;
      font-weight: 300 !important;
      font-size: 10px !important;
      text-transform: lowercase !important;
    }
  }
`;

export const Gateway = styled(Flex).attrs({
  w: 1,
})`
  cursor: pointer;
  position: relative;
`;

export const InfoBlock = styled(Flex).attrs({
  direction: 'column',
  justify: 'flex-start',
  w: [0.33],
})`
  align-items: flex-start !important;
`;

export const InfoBlockDate = styled(InfoBlock)`
  align-items: flex-end !important;
`;

export const Text = styled(Box).attrs({})``;

export const ShortcutButton = styled(Button).attrs({
  icon: <OfflineBoltWhite style={{ margin: 0 }} />,
  label: 'shortcut',
  backgroundColor: props => props.theme.primaryRed,
  hoverColor: props => props.theme.primaryRed,
})`
  color: white !important;
`;

export const StopAutorunButton = styled(Button).attrs({
  icon: <PauseCircleOutlineIcon color={theme.primaryRed} />,
  label: 'stop autorun',
  labelStyle: { width: '70%' },
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;
`;

export const StartAutorunButton = styled(Button).attrs({
  icon: <LoopIcon color={theme.primaryRed} />,
  label: 'start autorun',
  labelStyle: { width: '70%' },
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;
`;

export const NextPeriodButton = styled(Button).attrs({
  icon: <PlayIconCircleOutline />,
  label: 'next period',
  labelStyle: { width: '70%' },
  hoverColor: props => props.theme.secondaryRed,
})`
  padding-top: 5px !important;

  & > svg {
    margin: 0 !important;
  }
  & > span {
    display: block !important;
    margin: 0 !important;
    padding: 0 7px !important;
    font-weight: 300 !important;
    font-size: 10px !important;
    text-transform: lowercase !important;
  }
`;

export const Tooltip = styled(ReactTooltip)`
  background-color: ${theme.secondaryGrey} !important;
  max-width: 50px;
  text-align: center;
  max-height: 150px;
  padding: 10.5px 0px;
  border-radius: 0px;
  font-size: 10px;
  letter-spacing: 0.2px;
  &: after {
    display: none;
  }
`;
