import React from 'react';
import PropTypes from 'prop-types';

import { FieldCheckbox } from 'components/Layout/Inputs';

const CheckboxAdapter = ({ input: { onChange, value }, onChangeControl, style, ...rest }) => (
  <FieldCheckbox
    {...rest}
    style={style}
    onCheck={(event, isChecked) => {
      onChangeControl(isChecked, rest);
      onChange(isChecked);
    }}
    checked={value}
  />
);

CheckboxAdapter.propTypes = {
  style: PropTypes.object,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeControl: PropTypes.func,
};

CheckboxAdapter.defaultProps = {
  style: { marginTop: '20px' },
  onChangeControl: () => {},
};

export default CheckboxAdapter;
