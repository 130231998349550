import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { ActionButton } from 'components/Dialogs/v1/Base';
import { BorderRadiusContainer } from 'components/Layout/Containers';
import { AddIcon, RemoveIcon } from 'components/Layout/Icons';

import UserTypeRadioGroup from './UserTypeRadioGroup';

import * as S from './styled';

const fields = [
  { name: 'UserName', label: 'UserName' },
  { name: 'FirstName', label: 'First Name' },
  { name: 'LastName', label: 'Last Name' },
  { name: 'Email', label: 'e-Mail' },
  { name: 'Phone', label: 'Phone' },
];

const SiteForm = ({ isFormShown, onAddClick, onCloseClick, form }) => {
  const onIconClickHandler = () => {
    if (isFormShown) {
      onCloseClick();
    } else {
      onAddClick(form);
    }
  };

  const { dirty } = form.getState();

  return (
    <BorderRadiusContainer>
      <S.Label>New Person</S.Label>
      {isFormShown && (
        <S.FormContainer>
          <S.FieldContainer>
            <S.FieldLabel>Role:</S.FieldLabel>
            <Field component={UserTypeRadioGroup} name="UserType" style={S.radioStyle} initialValue={3} />
          </S.FieldContainer>
          {fields.map(({ name, label }) => (
            <S.FieldBlock key={name}>
              <Field
                component={TextFieldAdapter}
                name={name}
                type={label === 'Phone' ? 'number' : 'text'}
                floatingLabelText={label}
              />
            </S.FieldBlock>
          ))}
          <ActionButton type="submit" isNext isDisable={!dirty} label="Add Person" />
        </S.FormContainer>
      )}
      <S.AddBlock>
        <S.ContentBlock>
          <S.IconContainer onClick={onIconClickHandler}>{isFormShown ? <RemoveIcon /> : <AddIcon />}</S.IconContainer>
          {isFormShown && <S.SubText>close</S.SubText>}
        </S.ContentBlock>
      </S.AddBlock>
    </BorderRadiusContainer>
  );
};

SiteForm.propTypes = {
  isFormShown: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    getState: PropTypes.func.isRequired,
  }).isRequired,
  onAddClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default SiteForm;
