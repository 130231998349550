import styled from 'styled-components';

export const Container = styled.span`
  font-size: 12px;
  font-weight: 500;
  display: flex !important;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const RedInfo = styled(Info)`
  color: ${({ theme }) => theme.mainRed};
  display: flex !important;
  margin-left: 25px;
`;
