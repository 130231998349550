import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { CloseIcon } from 'components/Layout/Icons';

export const Container = styled(Flex)`
  align-items: center;
  ${({ titleColor }) => titleColor && { backgroundColor: titleColor }};
  padding-left: 24px;
  margin-bottom: 30px;
`;

export const TitleText = styled.h3`
  color: ${({ theme, isError }) => (!isError ? theme.modalsContentColor : theme.primaryWhite)};
  display: flex;
  flex: auto;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  min-width: 0;
  word-break: break-word;
  word-wrap: break-word;
`;

export const IconClose = styled(CloseIcon).attrs({
  color: ({ theme, isError }) => (!isError ? theme.darkGrey : theme.primaryWhite),
})`
  transform: scale(1.2);
`;
