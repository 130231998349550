import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Content = styled(Flex).attrs({
  column: true,
  py: 1,
  w: [0.8, 0.65, 0.5, 0.4, 0.3],
})`
  flex: 1;
  justify-content: center;
`;

export const Title = styled.h1`
  text-align: center;
`;
