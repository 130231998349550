import styled, { css } from 'styled-components';
import { Flex } from 'grid-styled';
import { Toolbar } from 'material-ui/Toolbar';

import { LowPriorityIcon, SwapVerticalCircleIcon } from 'components/Layout/Icons';
import { isSafari } from 'helpers';

const iconsStyles = css`
  width: 24px !important;
  height: 24px !important;
`;

export const Content = styled(Flex).attrs({
  column: true,
  flex: '1',
})`
  background-color: ${({ theme }) => theme.lightGrey};
  padding-bottom: 60px;
  ${!isSafari() ? 'overflow-y: auto' : ''}
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.lightGrey} !important;
  height: 100%;
`;

export const ToolbarContainer = styled(Toolbar)`
  border-top: 1px solid ${({ theme }) => theme.primaryGrey} !important;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey} !important;
  align-items: center;
  background-color: ${({ theme }) => theme.lightGrey} !important;
  flex-shrink: 0 !important;
  padding: 0 22px 0 74px !important;
`;

export const RedTitle = styled.div`
  font-size: 18px !important;
  color: ${({ theme }) => theme.mainRed};
`;

export const ItemsIcon = styled(LowPriorityIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})`
  ${iconsStyles}
`;

export const SequenceSetupIcon = styled(SwapVerticalCircleIcon).attrs({
  color: ({ theme }) => theme.primaryLists,
})`
  ${iconsStyles}
`;
