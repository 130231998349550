import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_LIMITS_REQUEST: undefined,
  GET_LIMITS_SUCCESS: undefined,
  GET_LIMITS_FAILURE: undefined,

  COPY_UNITS_REQUEST: undefined,
  COPY_UNITS_SUCCESS: undefined,
  COPY_UNITS_FAILURE: undefined,

  GET_ALL_UNITS_REQUEST: undefined,
  GET_ALL_UNITS_SUCCESS: undefined,
  GET_ALL_UNITS_FAILURE: undefined,

  GET_ITEMS_REQUEST: undefined,
  GET_ITEMS_SUCCESS: undefined,
  GET_ITEMS_FAILURE: undefined,

  CREATE_LIST_TEMPLATE_REQUEST: undefined,
  CREATE_LIST_TEMPLATE_SUCCESS: undefined,
  CREATE_LIST_TEMPLATE_CLEAR: undefined,
  CREATE_LIST_TEMPLATE_FAILURE: undefined,

  CREATE_LIST_TEMPLATE_ITEM_REQUEST: undefined,
  CREATE_LIST_TEMPLATE_ITEM_SUCCESS: undefined,
  CREATE_LIST_TEMPLATE_ITEM_FAILURE: undefined,

  SEARCH_LIST_TEMPLATE_REQUEST: undefined,
  SEARCH_LIST_TEMPLATE_SUCCESS: undefined,
  SEARCH_LIST_TEMPLATE_FAILURE: undefined,

  CHECK_MATCH_IMPORT_LIST_REQUEST: undefined,
  CHECK_MATCH_IMPORT_LIST_SUCCESS: undefined,
  CHECK_MATCH_IMPORT_LIST_FAILURE: undefined,

  GET_UNIT_TASK_STATUS_REQUEST: undefined,
  GET_UNIT_TASK_STATUS_SUCCESS: undefined,
  GET_UNIT_TASK_STATUS_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.searchListTemplateRequest, handlers.searchListTemplateRequest],
    [actions.searchListTemplateSuccess, handlers.searchListTemplateSuccess],
    [actions.searchListTemplateFailure, handlers.searchListTemplateFailure],

    [actions.createListTemplateRequest, handlers.createListTemplateRequest],
    [actions.createListTemplateSuccess, handlers.createListTemplateSuccess],
    [actions.createListTemplateClear, handlers.createListTemplateClear],
    [actions.createListTemplateFailure, handlers.createListTemplateFailure],

    [actions.createListTemplateItemRequest, handlers.createListTemplateItemRequest],
    [actions.createListTemplateItemSuccess, handlers.createListTemplateItemSuccess],
    [actions.createListTemplateItemFailure, handlers.createListTemplateItemFailure],

    [actions.getLimitsRequest, handlers.getLimitsRequest],
    [actions.getLimitsSuccess, handlers.getLimitsSuccess],
    [actions.getLimitsFailure, handlers.getLimitsFailure],

    [actions.copyUnitsRequest, handlers.copyUnitsRequest],
    [actions.copyUnitsSuccess, handlers.copyUnitsSuccess],
    [actions.copyUnitsFailure, handlers.copyUnitsFailure],

    [actions.getAllUnitsRequest, handlers.getAllUnitsRequest],
    [actions.getAllUnitsSuccess, handlers.getAllUnitsSuccess],
    [actions.getAllUnitsFailure, handlers.getAllUnitsFailure],

    [actions.getItemsRequest, handlers.getItemsRequest],
    [actions.getItemsSuccess, handlers.getItemsSuccess],
    [actions.getItemsFailure, handlers.getItemsFailure],

    [actions.checkMatchImportListRequest, handlers.checkMatchImportListRequest],
    [actions.checkMatchImportListSuccess, handlers.checkMatchImportListSuccess],
    [actions.checkMatchImportListFailure, handlers.checkMatchImportListFailure],

    [actions.getUnitTaskStatusFailure, handlers.getUnitTaskStatusFailure],
    [actions.getUnitTaskStatusSuccess, handlers.getUnitTaskStatusSuccess],
    [actions.getUnitTaskStatusRequest, handlers.getUnitTaskStatusRequest],
  ]),
  initialState,
);

export default reducer;
