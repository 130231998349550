import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

import { Container, Message, MessageContainer } from './styled';

const SimpleList = ({
  data,
  emptyListMessage,
  onItemClick,
  renderItemContent,
  renderRightControls,
  withCheckbox,
  userType,
  tenantIsOEM,
  allowSubmitToListExchange,
  importTenantToExchangeList,
  openDragItemsListDialog,
  openPdfDialog,
  openDuplicateDialog,
  withoutControls,
  disabled,
}) =>
  data.length ? (
    <Container>
      {data.map((item, index) => (
        <ListItem
          key={`${index}-${item.Id}` || index}
          index={index}
          item={item}
          onClick={onItemClick}
          renderItemContent={renderItemContent}
          renderRightControls={renderRightControls}
          withCheckbox={withCheckbox}
          userType={userType}
          tenantIsOEM={tenantIsOEM}
          allowSubmitToListExchange={allowSubmitToListExchange}
          importTenantToExchangeList={importTenantToExchangeList}
          openDragItemsListDialog={openDragItemsListDialog}
          openPdfDialog={openPdfDialog}
          openDuplicateDialog={openDuplicateDialog}
          withoutControls={withoutControls}
          disabled={disabled}
        />
      ))}
    </Container>
  ) : (
    <MessageContainer>
      <Message>{emptyListMessage}</Message>
    </MessageContainer>
  );

SimpleList.defaultProps = {
  emptyListMessage: 'This list is empty.',
  onItemClick: null,
  renderRightControls: () => null,
  withCheckbox: false,
  userType: 0,
  tenantIsOEM: false,
  allowSubmitToListExchange: false,
  importTenantToExchangeList: () => {},
  openDragItemsListDialog: () => {},
  openPdfDialog: null,
  openDuplicateDialog: null,
  withoutControls: false,
  disabled: false,
};

SimpleList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  emptyListMessage: PropTypes.string,
  onItemClick: PropTypes.func,
  renderItemContent: PropTypes.func.isRequired,
  renderRightControls: PropTypes.func,
  withCheckbox: PropTypes.bool.isRequired,
  userType: PropTypes.number,
  tenantIsOEM: PropTypes.bool,
  allowSubmitToListExchange: PropTypes.bool,
  importTenantToExchangeList: PropTypes.func,
  openDragItemsListDialog: PropTypes.func,
  openPdfDialog: PropTypes.func,
  openDuplicateDialog: PropTypes.func,
  withoutControls: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SimpleList;
