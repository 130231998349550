import React from 'react';
import PropTypes from 'prop-types';

import { getTaskName } from 'helpers';
import { UnitItemTaskTemplateType } from 'configs/propTypes';
import { AddIcon, SettingsIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import Subheader from 'components/Subheader';

import { Container, Message, Name, TaskContainer } from './styled';

const Tasks = ({ tasks }) => (
  <Container>
    <Subheader
      rightButtons={[
        {
          icon: <AddIcon />,
          disabled: true,
        },
      ]}
      title="Tasks"
      zIndex={1}
    />
    {tasks.length ? (
      tasks.map(task => (
        <TaskContainer key={task.Id}>
          <Name>
            <b>{getTaskName('', task.RuleType)}</b> {task.Name}
          </Name>
          <ButtonIcon disabled>
            <SettingsIcon />
          </ButtonIcon>
        </TaskContainer>
      ))
    ) : (
      <Message>This item has no tasks.</Message>
    )}
  </Container>
);

Tasks.propTypes = {
  tasks: PropTypes.arrayOf(UnitItemTaskTemplateType).isRequired,
};

export default Tasks;
