/* eslint-disable no-shadow */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { UnitItemType } from 'configs/propTypes';

import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as unitActions } from 'redux/unit';
import AddEditForm from './Form';
import validate from './validator';

const AddEditItemDialog = ({ item, handleClose, ...rest }) => {
  const dispatch = useDispatch();

  const onDeleteItemApprove = () => {
    handleClose();
    dispatch(unitActions.deleteUnitItemRequest(item.Id));
  };

  const onDeleteItem = () => {
    dispatch(
      openConfirmationDialog(
        'Are you sure you want to permanently delete this Item? This cannot be undone!',
        onDeleteItemApprove,
        'Delete?',
      ),
    );
  };

  const addItemSubmitHandler = item => dispatch(unitActions.addUnitItemRequest(item));

  const editItemSubmitHandler = item => dispatch(unitActions.editUnitItemRequest(item));

  return (
    <AddEditForm
      {...rest}
      initialValues={item}
      handleClose={handleClose}
      validate={validate}
      handleDelete={onDeleteItem}
      onSubmit={item ? editItemSubmitHandler : addItemSubmitHandler}
      title={item ? 'Edit Item' : 'Add Item'}
      // handleSaveContinue={handleSaveContinue}
      withSaveContinue={!item}
      saveContinueLabel="Save and Add a Task"
      BigButtons
      withGreenButton={!item}
      withGreenBorder={!item}
      withSubmit={!item}
      isNewDeleteButton={!!item}
      isNewSubmitButton={!!item}
      gapValue={item ? '20px' : '5px'}
      hasPadding
    />
  );
};

AddEditItemDialog.defaultProps = {
  withDelete: false,
};

AddEditItemDialog.propTypes = {
  item: UnitItemType.isRequired,
  withDelete: PropTypes.bool,
  handleClose: PropTypes.bool.isRequired,
  handleSaveContinue: PropTypes.bool.isRequired,
};

export default AddEditItemDialog;
