import React from 'react';
import PropTypes from 'prop-types';

import { TierFullType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import CustomNumberInputField from 'components/Forms/CustomNumberInputField';
import * as S from './styled';
import validate from './validator';
import theme from 'theme';

const demoTenantsMaxValues = [
  {
    name: 'TenantMaxSitesEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Sites </S.BoldLabel>each
      </S.Label>
    ),
  },
  {
    name: 'TenantMaxItemsEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Items </S.BoldLabel>each
      </S.Label>
    ),
  },
  {
    name: 'TenantMaxSensorsEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Sensors </S.BoldLabel>each
      </S.Label>
    ),
  },
];

const AddEditTierForm = ({ form, values }) => {
  const customChange = operator =>
    form.change('TotalCharge', (Number(values.IotCharge) + Number(values.TasksCharge) + operator).toFixed(2));

  return (
    <S.FormContainer>
      <S.CenterContainer>
        <S.MargingField component={TextFieldAdapter} floatingLabelText="Tier Name" name="Name" maxLength="50" />
      </S.CenterContainer>

      <S.CenterContainer>
        <S.TenantMaxAccountValuesContainer>
          {demoTenantsMaxValues.map(({ name, label }) => (
            <CustomNumberInputField key={name} name={name} change={form.change} labelComponent={label} />
          ))}
          <CustomNumberInputField
            key="TasksCharge"
            name="TasksCharge"
            change={form.change}
            customChange={customChange}
            labelComponent={<S.Label>Tasks Charge</S.Label>}
          />
          <CustomNumberInputField
            key="IotCharge"
            name="IotCharge"
            change={form.change}
            customChange={customChange}
            labelComponent={<S.Label>Iot Charge</S.Label>}
          />
          <CustomNumberInputField
            key="TotalCharge"
            name="TotalCharge"
            readOnly
            labelComponent={
              <S.Label>
                <S.BoldLabel>Total Charge</S.BoldLabel>
              </S.Label>
            }
          />
        </S.TenantMaxAccountValuesContainer>
      </S.CenterContainer>
    </S.FormContainer>
  );
};

AddEditTierForm.propTypes = {
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
  }).isRequired,
  values: PropTypes.array.isRequired,
};

const AddEditTierWithInput = withInputModal(AddEditTierForm);

const AddEditTierDialog = props => (
  <AddEditTierWithInput
    {...props}
    key="Add Edit Tier With Input"
    initialValues={props.tier}
    titleColor={theme.darkGrey}
    validate={validate}
  />
);

AddEditTierDialog.defaultProps = {
  tier: {
    TenantMaxSitesEach: 1,
    TenantMaxItemsEach: 100,
    TenantMaxSensorsEach: 1,
    TasksCharge: 45,
    IotCharge: 45,
    TotalCharge: 90,
  },
};

AddEditTierDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tier: TierFullType,
  title: PropTypes.string.isRequired,
};

export default AddEditTierDialog;
