import { GroupOptionType, PartOptionType } from 'types/inventory';

const PART_HEADER = ['My Part Name', 'My Part ID', 'OEM Part ID'];

const PART_GROUP_HEADER = ['My Group Name', 'Group Description', 'Parts'];

const PART_OPTIONS: PartOptionType[] = [
  { name: 'My Part Name', value: 'MyPartName' },
  { name: 'My Part Category', value: 'MyPartCategory' },
  { name: 'My Part ID', value: 'MyPartID' },
  { name: 'OEM Part ID', value: 'OEMPartID' },
];

const GROUP_OPTIONS: GroupOptionType[] = [
  { name: 'My Group Name', value: 'MyGroupName' },
  { name: 'My Group Description', value: 'MyGroupDescription' },
];

export { PART_HEADER, PART_GROUP_HEADER, PART_OPTIONS, GROUP_OPTIONS };
