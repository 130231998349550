import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import theme from 'theme';

import { useAppSelector } from 'redux/reducers';

import { Modal } from 'components/Modal';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

import * as S from './ReportPhotoDialogStyles';

type PropsType = {
  name: string;
};

export const ReportPhotoDialog = NiceModal.create(({ name }: PropsType) => {
  const modal = useModal();
  const reportPhoto = useAppSelector(state => state.tasks.reportPhoto);

  return (
    <Modal
      isOpen={modal.visible}
      title="Report Photo"
      titleColor={theme.primaryDark}
      onRequestClose={modal.hide}
      onAfterClose={modal.remove}
    >
      <S.RootContainer>
        <h4>{name}</h4>
        <S.Content>
          <img src={reportPhoto} alt={name} />
        </S.Content>
        <ActionsContainer paddingValue={20}>
          <ActionButton type="button" isNext isGreenLabel label="Close" onClick={modal.hide} />
        </ActionsContainer>
      </S.RootContainer>
    </Modal>
  );
});
