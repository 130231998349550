const IOT_HUBS = [
  {
    skuName: 'F1',
    skuTier: 'Free',
    capacityMax: 2,
  },
  {
    skuName: 'B1',
    skuTier: 'Basic',
    capacityMax: 200,
  },
  {
    skuName: 'B2',
    skuTier: 'Basic',
    capacityMax: 200,
  },
  {
    skuName: 'B3',
    skuTier: 'Basic',
    capacityMax: 10,
  },
  {
    skuName: 'S1',
    skuTier: 'Standard',
    capacityMax: 200,
  },
  {
    skuName: 'S2',
    skuTier: 'Standard',
    capacityMax: 200,
  },
  {
    skuName: 'S3',
    skuTier: 'Standard',
    capacityMax: 10,
  },
];

export default IOT_HUBS;
