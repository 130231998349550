import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 15px 0;
  justify-content: center;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;

  form {
    & > div:last-child {
      padding: 20px 0 !important;
    }
  }
`;

export const radioStyle = {
  width: '50%',
};
