import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';

import withDrawer from 'components/Dialogs/Drawer';
import { LinkIcon } from 'components/Layout/Icons';

import * as S from './styled';

const UnitsList = ({ units, openItemPartsViewDialog, isView, isLoading }) => (
  <S.List>
    {isView ? <S.ViewDescription>Click on link to view detail</S.ViewDescription> : null}
    {isLoading ? (
      <S.ProgressContainer>
        <CircularProgress />
      </S.ProgressContainer>
    ) : (
      units?.map(unit => (
        <S.Unit key={unit.Id}>
          <S.UnitTitle>{unit.Name}</S.UnitTitle>
          <S.Items>
            {unit.Items?.map(item => (
              <S.Item key={item.Id} onClick={() => openItemPartsViewDialog(unit, item)}>
                <span>{item.TotalLinksCountWithGroups}</span>
                <LinkIcon colored={item.HasLinks.toString()} />
                <span>{item.Name}</span>
              </S.Item>
            ))}
          </S.Items>
        </S.Unit>
      ))
    )}
  </S.List>
);

UnitsList.defaultProps = {
  units: [],
  openItemPartsViewDialog: () => {},
  isView: false,
  isLoading: false,
};

UnitsList.propTypes = {
  units: PropTypes.array,
  openItemPartsViewDialog: PropTypes.func,
  isView: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const LinkItemsInput = withDrawer(UnitsList);

const LinkItemsDialog = props => <LinkItemsInput title={<S.Title>Part link to items</S.Title>} {...props} />;

export default LinkItemsDialog;
