import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Footer = ({ onNext, onPrev, period }) => {
  let prev;
  let next;
  const isVisible = period !== 'past';

  if (period === 'today') {
    prev = 'Previous Day';
    next = 'Next Day';
  }
  if (period === 'days') {
    prev = 'Previous Week';
    next = 'Next Week';
  }

  return isVisible ? (
    <S.Container>
      <S.Button label={prev} icon={<S.PreviousTemplateIcon />} onClick={onPrev} />
      <S.Button label={next} icon={<S.NextTemplateIcon />} labelPosition="before" onClick={onNext} />
    </S.Container>
  ) : null;
};

Footer.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  period: PropTypes.string.isRequired,
};

export default Footer;
