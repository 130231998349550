import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { actions as shortcutActions } from 'redux/shortcuts';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { countUnitsAndItems } from 'components/Layout/ShortcutRules/helpers';
import { ASSIGNMENT_TYPE } from '../constants';

import * as S from './styled';

class Overview extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      sendManagementOptionsRequest: PropTypes.func.isRequired,
      sendPersonOptionsRequest: PropTypes.func.isRequired,
      sendTeamOptionsRequest: PropTypes.func.isRequired,
      deleteShortcutRequest: PropTypes.func.isRequired,
      createChecklistsRequest: PropTypes.func.isRequired,
    }).isRequired,
    shortcut: PropTypes.shape().isRequired,
    currentSite: PropTypes.shape().isRequired,
    currentSchedule: PropTypes.shape().isRequired,
    manager: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    team: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    person: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onNext: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    checklistsList: PropTypes.shape().isRequired,
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    listOfItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    overrideTime: PropTypes.bool.isRequired,
  };

  state = {
    isConfirmationDialogOpened: false,
  };

  componentDidMount() {
    const { currentSchedule, manager, person, team } = this.props;
    const updatedValuesToSend = {
      ManagementRules: currentSchedule.ManagementRules,
      PersonRules: currentSchedule.PersonRules,
      TeamRules: currentSchedule.TeamRules,
      id: currentSchedule.Id,
      siteId: currentSchedule.SiteID,
      AssignmentType: currentSchedule.AssignmentType,
    };

    if (
      manager === 'empty' &&
      currentSchedule.AssignmentType === ASSIGNMENT_TYPE.MANAGER &&
      currentSchedule.ManagementRules
    ) {
      this.props.actions.sendManagementOptionsRequest(updatedValuesToSend);
    }

    if (
      person === 'empty' &&
      currentSchedule.AssignmentType === ASSIGNMENT_TYPE.PERSON &&
      currentSchedule.PersonRules
    ) {
      this.props.actions.sendPersonOptionsRequest(updatedValuesToSend);
    }

    if (team === 'empty' && currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM) {
      this.props.actions.sendTeamOptionsRequest(updatedValuesToSend);
    }
  }

  componentDidUpdate() {
    const { currentSchedule, manager, person, team } = this.props;
    const updatedValuesToSend = {
      ManagementRules: currentSchedule.ManagementRules,
      PersonRules: currentSchedule.PersonRules,
      TeamRules: currentSchedule.TeamRules,
      id: currentSchedule.Id,
    };

    if (
      manager === 'empty' &&
      currentSchedule.AssignmentType === ASSIGNMENT_TYPE.MANAGER &&
      currentSchedule.ManagementRules
    ) {
      this.props.actions.sendManagementOptionsRequest(updatedValuesToSend);
    }

    if (
      person === 'empty' &&
      currentSchedule.AssignmentType === ASSIGNMENT_TYPE.PERSON &&
      currentSchedule.PersonRules
    ) {
      this.props.actions.sendPersonOptionsRequest(updatedValuesToSend);
    }

    if (team === 'empty' && currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM) {
      this.props.actions.sendTeamOptionsRequest(updatedValuesToSend);
    }
  }

  handleDeleteShortcutApproval = () => {
    const { shortcut, onExit } = this.props;
    this.props.actions.deleteShortcutRequest({ shortcutId: shortcut.Id });
    this.setState({ isConfirmationDialogOpened: false });
    onExit();
  };

  handleDeleteShortcutReject = () => {
    this.setState({ isConfirmationDialogOpened: false });
  };

  onDelete = () => {
    this.setState({ isConfirmationDialogOpened: true });
  };

  onContinue = () => {
    const {
      currentSite,
      shortcut,
      onNext,
      checklistsList,
      list,
      overrideTime,
      actions: { createChecklistsRequest },
    } = this.props;
    const isFinal = Object.keys(checklistsList).length === list.length - 1;
    createChecklistsRequest({
      siteId: currentSite.Id,
      shortcutId: shortcut.Id,
      ignoreLeadTime: overrideTime,
      onNext,
      isFinal,
    });
  };

  render() {
    const { currentSite, shortcut, manager, person, team, error, currentSchedule, listOfItems } = this.props;
    const unitsAndItems = countUnitsAndItems(listOfItems, currentSite.Id);

    return (
      <S.Container>
        <S.Block>
          <S.MainTitle>{shortcut && shortcut.Name}</S.MainTitle>
          <S.RedText>Checklists will be created for: </S.RedText>
        </S.Block>
        <S.Block>
          <S.SecondaryTitle>
            {currentSite && `${currentSite.AdditionalSiteID.substring(0, 5)} ${currentSite.Name}`}
          </S.SecondaryTitle>
          <S.Title>{`${unitsAndItems[0]} Units`}</S.Title>
        </S.Block>
        <S.Block>
          <S.RedText>Selected Items with tasks:</S.RedText>
          <S.ItemsCircle>{unitsAndItems[1]}</S.ItemsCircle>
          <S.RedText>Tasks will be assigned to:</S.RedText>
        </S.Block>
        <S.Block>
          <S.Title>
            Manager:
            <S.SecondaryTitle>
              &nbsp;
              {(manager !== 'empty' && manager.Name && `${manager.FirstName} ${manager.LastName}`) ||
                (currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM &&
                  team !== 'empty' &&
                  team.Name &&
                  `${team?.Manager?.FirstName} ${team?.Manager?.LastName}`)}
            </S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Team:
            <S.SecondaryTitle>
              &nbsp;
              {currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM && team !== 'empty' && team.Name
                ? team.Name
                : 'All'}
            </S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Team Member:
            <S.SecondaryTitle>
              &nbsp;{person !== 'empty' && person.Name ? `${person.FirstName} ${person.LastName}` : 'Open Assignment'}
            </S.SecondaryTitle>
          </S.Title>
        </S.Block>
        {!!error.length && <S.ErrorContainer>{error}</S.ErrorContainer>}
        <ActionsContainer>
          <ActionButton label="Delete Shortcut" onClick={this.onDelete} />
          <ActionButton label="Create Checklists" isNext onClick={this.onContinue} />
        </ActionsContainer>
        <ConfirmationDialog
          label="Delete Shortcut"
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>
                Aborting the process will delete this shortcut and cannot be undone!
              </S.ConfirmationText>
              <S.ConfirmationWarning>Are you sure?</S.ConfirmationWarning>
            </S.ConfirmationContainer>
          }
          open={this.state.isConfirmationDialogOpened}
          onClick={this.handleDeleteShortcutApproval}
          handleClose={this.handleDeleteShortcutReject}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts }) => ({
  shortcut: shortcuts.shortcut,
  currentSchedule: shortcuts.currentSchedule,
  manager: shortcuts.managementOptions,
  team: shortcuts.teamOptions,
  person: shortcuts.personOptions,
  error: shortcuts.error,
  isLoading: shortcuts.isLoading,
  checklistsList: shortcuts.checklistsList,
  list: shortcuts.shortcutSitesInfo,
  listOfItems: shortcuts.listOfItems,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
