import styled from 'styled-components';
import { ButtonIcon } from 'components/Layout/Buttons';

export const ListItem = styled.p`
  width: ${({ itemsCount }) => `calc(100% / ${itemsCount})`};
  margin: 0;
  padding: 16px 0;
`;

export const ItemValue = styled.span`
  font-weight: bold;
`;

export const NotesContainer = styled.div`
  width: calc(100% / 5);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EditNoteButton = styled(ButtonIcon)`
  & svg {
    fill: ${({ theme }) => theme.primaryGrey} !important;
  }
`;

export const NoteFieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 84%;
`;

export const Note = styled(ItemValue)`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
  margin-left: 5px;
`;
