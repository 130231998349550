import { call, put, takeEvery } from 'redux-saga/effects';

import { formatServerError } from 'helpers';
import { createTier, editMasterTier, getTiersList } from 'http/tiers';
import { actions } from './index';

function* fetchTiersList({ payload }) {
  try {
    const { data } = yield call(getTiersList, payload);
    yield put(actions.tiersListSuccess(data.root));
  } catch (error) {
    yield put(actions.tiersListFailure(formatServerError(error, 'An error occurred while fetching tiers list.')));
  }
}

function* addTier({ payload }) {
  try {
    const { data } = yield call(createTier, payload);
    yield put(actions.addTierSuccess(data.entity));
    const { MeeteringGroupID } = payload;
    yield* fetchTiersList({ payload: { meeteringGroupId: MeeteringGroupID } });
  } catch (error) {
    yield put(actions.addTierFailure(formatServerError(error, 'An error occurred while saving the tier.')));
  }
}

function* editTier({ payload }) {
  try {
    const { data } = yield call(editMasterTier, payload);
    yield put(actions.editTierSuccess(data.entity));
    const { MeeteringGroupID } = payload;
    yield* fetchTiersList({ payload: { meeteringGroupId: MeeteringGroupID } });
  } catch (error) {
    yield put(actions.editTierFailure(formatServerError(error, 'An error occurred while saving the tier.')));
  }
}

const tiersSagas = [
  takeEvery(actions.addTierRequest, addTier),
  takeEvery(actions.editTierRequest, editTier),
  takeEvery(actions.tiersListRequest, fetchTiersList),
];

export default tiersSagas;
