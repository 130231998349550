import React from 'react';
import PropTypes from 'prop-types';

import BaseSystemDialog from 'components/Dialogs/BaseSystem';

import * as S from './styled';

import theme from 'theme';

const ConfirmDialog = ({ text, onApprove, onReject, confirmText, cancelText, ...rest }) => {
  const actions = [
    <S.CancelButton primary label={cancelText} onClick={onReject} />,
    <S.RedButton secondary label={confirmText} onClick={onApprove} />,
  ];

  return (
    <BaseSystemDialog actions={actions} handleClose={onReject} titleColor={theme.primaryRed} isError {...rest}>
      {text}
    </BaseSystemDialog>
  );
};

ConfirmDialog.defaultProps = {
  confirmationMessage: '',
  title: '',
};

ConfirmDialog.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
};

export default ConfirmDialog;
