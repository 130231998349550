import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Levels = ({ elevated, escalation, isSelected, watch, warning }) => (
  <S.Container>
    {(escalation === 'both' || escalation === 'up') && (
      <>
        <S.Level name="Alert" isSelected={isSelected}>
          Alert
        </S.Level>
        {warning && (
          <S.Level name="Warning" isSelected={isSelected}>
            Warning
          </S.Level>
        )}
        {watch && (
          <S.Level name="Watch" isSelected={isSelected}>
            Watch
          </S.Level>
        )}
        {elevated && (
          <S.Level name="Elevated" isSelected={isSelected}>
            Elevated
          </S.Level>
        )}
      </>
    )}

    <S.Level name="Normal" isSelected={isSelected}>
      Normal
    </S.Level>

    {(escalation === 'both' || escalation === 'down') && (
      <>
        {elevated && (
          <S.Level name="Elevated" isSelected={isSelected}>
            Elevated
          </S.Level>
        )}
        {watch && (
          <S.Level name="Watch" isSelected={isSelected}>
            Watch
          </S.Level>
        )}
        {warning && (
          <S.Level name="Warning" isSelected={isSelected}>
            Warning
          </S.Level>
        )}
        <S.Level name="Alert" isSelected={isSelected}>
          Alert
        </S.Level>
      </>
    )}
  </S.Container>
);

Levels.propTypes = {
  elevated: PropTypes.bool,
  escalation: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  watch: PropTypes.bool,
  warning: PropTypes.bool,
};

Levels.defaultProps = {
  elevated: false,
  isSelected: true,
  watch: false,
  warning: false,
};

export default Levels;
