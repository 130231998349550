import http from '../index';

export const getAssignedForSite = (payload, isShowOnlyActiveUsers) =>
  http.post('/SiteUserManagement/List', `filter.SiteId=${payload.siteId}&${isShowOnlyActiveUsers}`);

export const getSitesList = () => http.post('/SiteManagement/List');

export const getSitesListByTenant = dto => http.post('SiteManagement/ListByTenant', dto);

export const createSite = dto => http.post('SiteManagement/POST', dto);

export const setSite = (mode, siteId, userIds) => http.post(`SiteUserManagement/${mode}`, { siteId, userIds });

export const removeSite = dto => http.post('SiteManagement/Delete', `id=${dto}`);

export const putSite = (payload, params) => http.post(`SiteManagement/Put/${payload.Id}`, params);

export const numerousEdit = (requestName, selectedSite, setingsName, settings) =>
  http.post(`SiteManagement/${requestName}/${selectedSite}`, { [setingsName]: settings });

export const editPastDueLimit = dto => http.post('SiteManagement/SetPastDueLimit', dto);

export const fetchSiteSettingsList = () => http.post('SiteManagement/ListSiteSettings');

export const getPastDueLimit = siteId => http.post('SiteManagement/GetPastDueLimit', { siteId });

export const setSiteInventory = siteSettings => http.post('SiteManagement/SetInventoryActivation', { siteSettings });
