import styled from 'styled-components';
import { FieldSelect } from 'components/Layout/Selects';
import { ButtonIcon } from 'components/Layout/Buttons';

import MenuItem from 'material-ui/MenuItem';
import theme from 'theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
`;

export const Text = styled.div`
  position: relative;
  margin: 10px;
`;

const selectStyle = {
  width: 50,
  height: 28,
  border: `1px solid ${theme.primaryGrey}`,
};

export const Select = styled(FieldSelect).attrs({
  style: selectStyle,
  underlineStyle: { display: 'none' },
  autoWidth: true,
  labelStyle: { left: '5px', top: '-13px', fontSize: '14px', maxWidth: '30px' },
  hintStyle: { left: '30px' },
  iconStyle: { top: '-10px', fill: theme.darkGrey, width: '24px', right: '10px' },
})``;

export const IconButton = styled(ButtonIcon)`
  height: 48px !important;
  min-width: 48px !important;
  padding: unset !important;
`;

export const Option = styled(MenuItem)``;
