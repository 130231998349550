import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import BaseDialog from 'components/Dialogs/Base';
import { actions as partsActions } from 'redux/parts';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { NullOrObject } from 'configs/propTypes';

import theme from 'theme';

import * as S from './styled';

const ItemPartsViewDialog = ({
  isView,
  units,
  unitToLink,
  itemToLink,
  openSelectPartsDialog,
  openSelectGroupsDialog,
  ...rest
}) => {
  const listPartsByItem = useSelector(state => state.parts.listPartsByItem);
  const listPartGroupsByItem = useSelector(state => state.parts.listPartGroupsByItem);
  const dispatch = useDispatch();

  const removePartFromItem = partId => () => {
    const payload = {
      partId,
      siteId: unitToLink.SiteId,
      itemId: itemToLink.Id,
    };

    dispatch(
      openConfirmationDialog(
        'Are you sure you want to remove this part?',
        () => dispatch(partsActions.removePartFromItemRequest(payload)),
        'Warning',
        'Cancel',
        'Delete',
        theme.redButton,
      ),
    );
  };

  const removeGroupFromItem = groupId => () => {
    const payload = {
      partGroupId: groupId,
      siteId: unitToLink.SiteId,
      itemId: itemToLink.Id,
    };

    dispatch(
      openConfirmationDialog(
        'Are you sure you want to remove this group?',
        () => dispatch(partsActions.removePartGroupFromItemRequest(payload)),
        'Warning',
        'Cancel',
        'Delete',
        theme.redButton,
      ),
    );
  };

  const getActualTotalLinksCount = () => {
    if (unitToLink && itemToLink) {
      const unit = units.find(item => item.Id === unitToLink?.Id);
      const item = unit?.Items.find(unitItem => unitItem.Id === itemToLink.Id);
      return item?.TotalLinksCountWithGroups || 0;
    }

    return 0;
  };

  useEffect(() => {
    if (itemToLink?.Id) {
      const payload = { itemId: itemToLink.Id };
      dispatch(partsActions.getListPartsByItemRequest(payload));
      dispatch(partsActions.getListPartGroupsByItemRequest(payload));
    }
  }, [dispatch, itemToLink?.Id]);

  return (
    <BaseDialog {...rest} modalStyles={{ maxWidth: '480px' }}>
      <S.DialogContent>
        <S.LocationInfo>
          <S.LocationInfoHeader>Location Info</S.LocationInfoHeader>
          <S.LocationInfoContent>
            <p>
              <span>Site: </span> {unitToLink?.SiteName}
            </p>
            <p>
              <span>Unit: </span> {unitToLink?.Name}
            </p>
            <p>
              <span>Item: </span> {itemToLink?.Name}
            </p>
          </S.LocationInfoContent>
        </S.LocationInfo>
        <S.TotalPartsCount>
          <p>Total Parts Count (incl. Groups)</p>
          <p>{getActualTotalLinksCount()}</p>
        </S.TotalPartsCount>
        <S.PartsInfo>
          <S.PartsInfoHeader view={isView}>
            <S.Col>Parts Info</S.Col>
            <S.Col textCenter="true">Qty</S.Col>
            {!isView ? <S.Col /> : null}
          </S.PartsInfoHeader>
          <S.PartsInfoContent>
            {listPartsByItem.map(part => (
              <S.PartsInfoContentRow key={part.Id} view={isView}>
                <S.Col>{part.MyPartID}</S.Col>
                <S.Col>{part.MyPartName}</S.Col>
                <S.Col textCenter="true">{part.LinksCount}</S.Col>
                {!isView ? (
                  <S.Col textCenter="true">
                    <S.DeleteBtn onClick={removePartFromItem(part.Id)} />
                  </S.Col>
                ) : null}
              </S.PartsInfoContentRow>
            ))}
          </S.PartsInfoContent>
          {openSelectPartsDialog ? (
            <S.AddBtnContainer>
              <S.AddIcon onClick={openSelectPartsDialog} />
            </S.AddBtnContainer>
          ) : null}
        </S.PartsInfo>
        <S.GroupInfo>
          <S.GroupInfoHeader view={isView}>
            <S.Col>Groups Info</S.Col>
            <S.Col textCenter="true">Qty</S.Col>
            {!isView ? <S.Col /> : null}
          </S.GroupInfoHeader>
          <S.GroupInfoContent>
            {listPartGroupsByItem.map(group => (
              <S.GroupInfoContentRow key={group.Id} view={isView}>
                <S.Col>{group.Name}</S.Col>
                <S.Col textCenter="true">{group.LinksCount}</S.Col>
                {!isView ? (
                  <S.Col textCenter="true">
                    <S.DeleteBtn onClick={removeGroupFromItem(group.Id)} />
                  </S.Col>
                ) : null}
              </S.GroupInfoContentRow>
            ))}
          </S.GroupInfoContent>
          {openSelectGroupsDialog ? (
            <S.AddBtnContainer>
              <S.AddIcon onClick={openSelectGroupsDialog} />
            </S.AddBtnContainer>
          ) : null}
        </S.GroupInfo>
      </S.DialogContent>
    </BaseDialog>
  );
};

ItemPartsViewDialog.propTypes = {
  unitToLink: NullOrObject,
  itemToLink: NullOrObject,
  units: PropTypes.array.isRequired,
  openSelectPartsDialog: PropTypes.func,
  openSelectGroupsDialog: PropTypes.func,
  isView: PropTypes.bool,
};

ItemPartsViewDialog.defaultProps = {
  unitToLink: null,
  itemToLink: null,
  openSelectPartsDialog: null,
  openSelectGroupsDialog: null,
  removeHandler: null,
  isView: false,
};

export default ItemPartsViewDialog;
