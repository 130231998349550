import { LinkIcon, NotificationOn, PersonIcon, SitesNotificationIcon, TextIcon } from 'components/Layout/Icons';
import { ListItem } from 'components/Layout/Section';
import React, { FC } from 'react';
import { ButtonIcon } from 'components/Layout/Buttons';
import theme from 'theme';

type PropsType = {
  openTextDialog: () => void;
  openLinksDialog: () => void;
  openSetNotificationDialog: () => void;
  openPeopleNotificationDialog: () => void;
  openSetIoTNotificationsDialog: () => void;
};

export const Notifications: FC<PropsType> = ({
  openTextDialog,
  openLinksDialog,
  openSetNotificationDialog,
  openPeopleNotificationDialog,
  openSetIoTNotificationsDialog,
}) => (
  <ListItem
    zIndex={4}
    key="Notifications"
    name="Notifications"
    content={
      <>
        <ButtonIcon onClick={openTextDialog} tooltip="Text">
          <TextIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openLinksDialog} tooltip="Links">
          <LinkIcon color={theme.primaryBlack} />
        </ButtonIcon>
        <ButtonIcon onClick={openSetNotificationDialog} tooltip="Sites">
          <SitesNotificationIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openPeopleNotificationDialog} tooltip="People">
          <PersonIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openSetIoTNotificationsDialog} tooltip="IoT">
          <NotificationOn color={theme.primaryBlack} />
        </ButtonIcon>
      </>
    }
  />
);
