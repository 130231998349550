import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import theme from 'theme';

import { ScheduleInfo, UnitItemType } from 'configs/propTypes';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as settingsActions } from 'redux/settings';
import { actions as scheduleActions } from 'redux/schedule';
import { actions as peopleActions } from 'redux/people';
import { getAssignableUsers, getUnitItems } from 'redux/schedule/selectors';
import UnitListTable from 'components/UnitListTable';
import Footer from 'components/Footer';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { generateDate } from '../SchedulesList/AddChecklistModal/helpers';

import AssignToolbar from './AssignToolbar';
import Navbar from './Navbar';
import SupervisorToolbar from './SupervisorToolbar';
import TimeFrameForm from './TimeFrameForm';

import {
  overrideValues,
  accessWarning,
  getItemName,
  getItemInstruction,
  renderRightControls,
  getInitialDates,
} from './helpers';
import * as S from './styled';

class SchedulesDetails extends Component {
  state = {
    checked: [],
    templateIds: [],
    timeframe: {},
    overrideTime: false,
    isStatusInactive: false,
    isSetTimeFrameDialogOpened: false,
    isOpenConfirmationDialog: false,
    isConfirmationOpened: false,
  };

  componentWillReceiveProps(nextProps) {
    const { templateIds } = this.state;

    if (JSON.stringify(nextProps.taskStatus) !== JSON.stringify(this.props.taskStatus)) {
      if (
        nextProps.taskStatus.CompletedCount ||
        nextProps.taskStatus.InProgressCount ||
        nextProps.taskStatus.RequireAttentionCount
      ) {
        this.switchConfirmationDialog();
      }

      if (
        nextProps.taskStatus.CompletedCount === 0 &&
        nextProps.taskStatus.InProgressCount === 0 &&
        nextProps.taskStatus.RequireAttentionCount === 0
      ) {
        this.removeTemplate(templateIds);
      }
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { scheduleId, unitId },
      },
      history: { location },
      requestGetSettings,
      requestPeopleList,
      requestScheduleDetails,
      requestScheduleTemplates,
      requestSchedulesList,
      requestUnitItems,
      scheduleListIds,
    } = this.props;

    requestGetSettings();
    requestPeopleList();
    requestScheduleDetails({ scheduleId });
    requestScheduleTemplates({ scheduleId });
    if (!scheduleListIds.length) {
      requestSchedulesList({ unitId });
    }
    const siteId = location.state?.siteId;
    if (siteId) {
      requestUnitItems({ unitId, siteId });
    }
  }

  componentWillUnmount() {
    this.props.clearTaskStatus();
  }

  setTimeFrame = () => {
    this.setState({ isSetTimeFrameDialogOpened: true });
  };

  closeSetTimeFrameDialog = () => {
    this.setState({ isSetTimeFrameDialogOpened: false });
  };

  onSend = value => {
    const { scheduleId } = this.props.match.params;
    const { overrideTime } = this.state;

    this.props.requestSetChecklistTimeFrame({
      checklistId: scheduleId,
      endDate: generateDate(value.EndDate, null),
      ignoreLeadTime: overrideTime,
    });
    this.closeSetTimeFrameDialog();
  };

  switchConfirmationDialog = () => {
    const { isOpenConfirmationDialog } = this.state;

    this.setState({ isOpenConfirmationDialog: !isOpenConfirmationDialog });
  };

  onClickStatus = async ({ isStart, isNextPeriod, ignoreLeadTime }) => {
    const { scheduleId } = this.props.match.params;
    if (isNextPeriod) {
      this.props.requestChecklistRunNextPeriod({ scheduleId, ignoreLeadTime });
    } else {
      this.props.requestSwitchAutorun({ scheduleId, isStart, ignoreLeadTime });
    }
  };

  onSubmitForm = ({ values }) => {
    const { schedulesTrigger, scheduleInfo } = this.props;
    const endDate = getInitialDates(scheduleInfo.ScheduleDto).endDate;
    const diff = moment.duration(moment(endDate).diff(moment(new Date()))).asDays();
    if (diff < overrideValues[schedulesTrigger]) {
      this.setState({ isConfirmationOpened: true, timeframe: values });
    } else {
      this.onSend(values);
    }
  };

  onPrev = () => {
    const { scheduleId } = this.props.match.params;
    let prevScheduleId;

    this.props.scheduleListIds.some((item, index) => {
      if (item !== scheduleId) {
        return false;
      }

      prevScheduleId = this.props.scheduleListIds[index - 1]
        ? this.props.scheduleListIds[index - 1]
        : this.props.scheduleListIds[this.props.scheduleListIds.length - 1];

      return true;
    });

    this.reload(prevScheduleId);
    this.props.requestChecklistStatus({ checklistId: prevScheduleId });
  };

  onNext = () => {
    const scheduleId = this.props.match.params.scheduleId;
    let nextScheduleId;

    this.props.scheduleListIds.some((item, index) => {
      if (item !== scheduleId) {
        return false;
      }

      nextScheduleId = this.props.scheduleListIds[index + 1]
        ? this.props.scheduleListIds[index + 1]
        : this.props.scheduleListIds[0];

      return true;
    });

    this.reload(nextScheduleId);
    this.props.requestChecklistStatus({ checklistId: nextScheduleId });
  };

  removeTemplate = templateIds => {
    this.props.requestRemoveTemplate({
      templateIds,
      scheduleId: this.props.match.params.scheduleId,
    });
  };

  handleDelete = () => {
    const { templateIds } = this.state;

    this.removeTemplate(templateIds);
    this.switchConfirmationDialog();
  };

  onDeleteTask = (props, id) => {
    const { scheduleInfo } = this.props;
    if (scheduleInfo.ActiveStatus === 'Inactive') {
      this.setState({
        isStatusInactive: true,
      });
    } else {
      this.setState({ templateIds: [id] });

      this.props.requestGetTaskStatus({
        templateIds: [id],
      });
    }
  };

  onDeleteItem = items => {
    const { scheduleInfo } = this.props;

    if (scheduleInfo.ActiveStatus === 'Inactive') {
      this.setState({
        isStatusInactive: true,
      });
    } else {
      const ids = items.map(item => item.Id);

      this.setState({ templateIds: ids });
      this.props.requestGetTaskStatus({
        templateIds: ids,
      });
    }
  };

  reload = scheduleId => {
    if (!scheduleId) {
      return;
    }

    this.props.history.push(`./${scheduleId}`);
    this.props.requestScheduleDetails({ scheduleId });
    this.props.requestScheduleTemplates({ scheduleId });
  };

  cancelConfirmationDialog = () => {
    const { timeframe } = this.state;

    this.setState({ isConfirmationOpened: false, overrideTime: false }, () => this.onSend(timeframe));
  };

  confirmConfirmationDialog = () => {
    const { timeframe } = this.state;

    this.setState({ isConfirmationOpened: false, overrideTime: true }, () => this.onSend(timeframe));
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationOpened: false });
  };

  render() {
    const {
      scheduleDeleted,
      scheduleListIds,
      scheduleInfo,
      taskTemplates,
      unitItems,
      people,
      taskStatus,
      match: {
        params: { unitId },
      },
    } = this.props;
    const { checked, isSetTimeFrameDialogOpened, isOpenConfirmationDialog, isStatusInactive, isConfirmationOpened } =
      this.state;
    const hasOnlyOneOrLessUnit = scheduleListIds.length <= 1;

    if (scheduleDeleted) {
      return <Redirect to={`/scheduler/list/${unitId}`} />;
    }

    return [
      <S.Container key="Schedules List">
        <Navbar title={scheduleInfo.UnitName} unitId={unitId} />
        <SupervisorToolbar
          accessWarning={accessWarning}
          unitInfo={scheduleInfo}
          onClickStatus={this.onClickStatus}
          setTimeFrame={this.setTimeFrame}
          people={people}
        />
        <AssignToolbar
          accessWarning={accessWarning}
          checkedTasks={checked}
          scheduleDto={scheduleInfo.ScheduleDto}
          scheduleInfo={scheduleInfo}
          people={people}
        />

        <S.List>
          {unitItems.map(unit => (
            <UnitListTable
              key={unit.Id}
              getItemName={getItemName}
              getItemInstruction={getItemInstruction}
              onUnitItemClick={this.onRowClick}
              unitColor={theme.primaryScheduler}
              unitData={taskTemplates[unit.Name]}
              withUnitControls
              withDelete
              onDeleteUnit={this.onDeleteItem}
              renderRightControls={item => renderRightControls(item, checked, this.onDeleteTask, this.props)}
              unitName={unit.Name}
            />
          ))}
        </S.List>
      </S.Container>,
      <Footer
        key="Schedules footer"
        onPrev={this.onPrev}
        onNext={this.onNext}
        label="Checklist"
        isDisabled={hasOnlyOneOrLessUnit}
        iconColor={theme.mainRed}
      />,
      <ConfirmationDialog
        label="Remove"
        isNext
        width={'40%'}
        maxWidth={'600px'}
        text={
          <S.ModalContainer>
            <S.Title>Tasks in progress!</S.Title>
            <>
              <S.ModalText>
                Completed: <b>{taskStatus.CompletedCount}</b>
              </S.ModalText>
              <S.ModalText>
                Attention: <b>{taskStatus.RequireAttentionCount}</b>
              </S.ModalText>
              <S.ModalText>
                Open: <b>{taskStatus.InProgressCount}</b>
              </S.ModalText>
              <p />
              <S.RedText>Completed Tasks will be archived</S.RedText>
              <S.RedText>Attention Tasks will be deleted</S.RedText>
            </>
            <ActionsContainer>
              <ActionButton label="CANCEL" onClick={this.switchConfirmationDialog} />
              <ActionButton label="ARCHIVE/REMOVE" isNext onClick={this.handleDelete} />
            </ActionsContainer>
          </S.ModalContainer>
        }
        title="Alert"
        open={isOpenConfirmationDialog}
        handleClose={this.switchConfirmationDialog}
      />,
      <ConfirmationDialog
        label="Save"
        isNext
        width={'40%'}
        maxWidth={'600px'}
        text={
          <TimeFrameForm
            onSubmitForm={this.onSubmitForm}
            dates={getInitialDates(scheduleInfo.ScheduleDto)}
            disableEndDate={scheduleInfo.ScheduleDto.ScheduleType === 'OneTime'}
          />
        }
        title="Set Timeframe"
        open={isSetTimeFrameDialogOpened}
        handleClose={this.closeSetTimeFrameDialog}
      />,
      <ConfirmationDialog
        label="Alert"
        text={
          <>
            <S.ConfirmationText>You cannot edit Checklist as end date is already reached.</S.ConfirmationText>
            <ActionsContainer>
              <ActionButton
                label="CANCEL"
                onClick={() =>
                  this.setState({
                    isStatusInactive: false,
                  })
                }
              />
            </ActionsContainer>
          </>
        }
        open={isStatusInactive}
        handleClose={() =>
          this.setState({
            isStatusInactive: false,
          })
        }
      />,
      <ConfirmationDialog
        label="YES - OVERRIDE"
        labelCancel="NO - start next period"
        width={'40%'}
        isCancelNext
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationText>Start date is sooner than lead time for creating tasks!</S.ConfirmationText>
            <S.ConfirmationText>
              <b>Override the lead time?</b>
            </S.ConfirmationText>
          </S.ConfirmationContainer>
        }
        open={isConfirmationOpened}
        onClick={() => this.confirmConfirmationDialog()}
        handleClose={this.closeConfirmationDialog}
        onCancelClick={() => this.cancelConfirmationDialog()}
      />,
    ];
  }
}

SchedulesDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  requestGetSettings: PropTypes.func.isRequired,
  requestSchedulesList: PropTypes.func.isRequired,
  requestScheduleDetails: PropTypes.func.isRequired,
  clearTaskStatus: PropTypes.func.isRequired,
  requestGetTaskStatus: PropTypes.func.isRequired,
  requestScheduleTemplates: PropTypes.func.isRequired,
  requestRemoveTemplate: PropTypes.func.isRequired,
  requestChecklistStatus: PropTypes.func.isRequired,
  requestSwitchAutorun: PropTypes.func.isRequired,
  requestChecklistRunNextPeriod: PropTypes.func.isRequired,
  requestSetChecklistTimeFrame: PropTypes.func.isRequired,
  requestUnitItems: PropTypes.func.isRequired,
  scheduleDeleted: PropTypes.bool.isRequired,
  schedulesTrigger: PropTypes.number.isRequired,
  scheduleInfo: ScheduleInfo.isRequired,
  requestPeopleList: PropTypes.func.isRequired,
  scheduleListIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  taskTemplates: PropTypes.object.isRequired, // has dynamic keys
  unitItems: PropTypes.arrayOf(UnitItemType).isRequired,
  taskStatus: PropTypes.shape({
    CompletedCount: PropTypes.number.isRequired,
    InProgressCount: PropTypes.number.isRequired,
    RequireAttentionCount: PropTypes.number.isRequired,
  }).isRequired,
  unitId: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = ({
  people,
  schedule,
  settings: {
    list: { AllowUnassignedUserInSchedule, TasksSchedulesTrigger },
  },
}) => ({
  assignableUsers: getAssignableUsers(schedule, AllowUnassignedUserInSchedule),
  scheduleDeleted: schedule.scheduleDeleted,
  scheduleInfo: schedule.details,
  schedulesTrigger: TasksSchedulesTrigger,
  scheduleListIds: schedule.schedulesListIds,
  taskTemplates: schedule.taskTemplates,
  unitItems: getUnitItems(schedule),
  currentUnit: schedule.currentUnit,
  taskStatus: schedule.taskStatus,
  unitId: schedule.details.UnitId,
  people: people.list.filter(user => user.Status !== 'Inactive'),
});

export default connect(mapStateToProps, {
  openConfirmationDialog,
  openErrorDialog,
  requestAssignEmployee: scheduleActions.assignEmployeeRequest,
  requestDeleteSchedule: scheduleActions.deleteScheduleRequest,
  requestGetSettings: settingsActions.getSettingsRequest,
  requestRemoveTemplate: scheduleActions.removeTaskTemplateRequest,
  requestGetTaskStatus: scheduleActions.taskStatusRequest,
  clearTaskStatus: scheduleActions.clearTaskStatus,
  requestScheduleDetails: scheduleActions.fetchScheduleDetailsRequest,
  requestSwitchAutorun: scheduleActions.fetchSwitchAutorunRequest,
  requestChecklistRunNextPeriod: scheduleActions.fetchChecklistRunNextPeriodRequest,
  requestSchedulesList: scheduleActions.schedulesListRequest,
  requestScheduleTemplates: scheduleActions.fetchScheduleTemplatesRequest,
  requestTaskAssignableUsers: scheduleActions.fetchTaskAssignableUsersRequest,
  requestUnitItems: scheduleActions.fetchScheduleUnitItemsRequest,
  requestChecklistStatus: scheduleActions.checklistStatusRequest,
  requestSetChecklistTimeFrame: scheduleActions.addChecklistTimeframeRequest,
  requestPeopleList: peopleActions.peopleListRequest,
})(SchedulesDetails);
