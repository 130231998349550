import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import ReactTooltip from 'react-tooltip';

import withInputModal from 'components/Dialogs/withInputModal';
import SimpleList from 'components/SimpleList';
import PdfItem from './PdfItem';
import { Container, Dropdown, FormContainer, SelectFieldWidth, TooltipList, TooltipListItem } from './styled';

const PDFForm = ({ changeSize, pdfList, qrCodeSize, rightControls }) => (
  <FormContainer>
    <Dropdown>
      <SelectField
        name="Size"
        floatingLabelText="Size"
        value={qrCodeSize}
        onChange={changeSize}
        style={SelectFieldWidth}
      >
        <MenuItem data-tip data-for="max_picture" primaryText="2.00 x 2.00 inch" value={2} />
        <ReactTooltip place="left" id="max_picture">
          <TooltipList>
            <TooltipListItem>{'Page Size 2.25" x 2.25"'}</TooltipListItem>
            <TooltipListItem>{'Cut Size 2.00" x 2.00"'}</TooltipListItem>
            <TooltipListItem>{'Safe Size 1.75" x 1.75"'}</TooltipListItem>
          </TooltipList>
        </ReactTooltip>
        <MenuItem data-tip data-for="medium_picture" primaryText="1.75 x 1.75 inch" value={1.75} />
        <ReactTooltip place="left" id="medium_picture">
          <TooltipList>
            <TooltipListItem>{'Page Size 2.00" x 2.00"'}</TooltipListItem>
            <TooltipListItem>{'Cut Size 1.75" x 1.75"'}</TooltipListItem>
            <TooltipListItem>{'Safe Size 1.50" x 1.50"'}</TooltipListItem>
          </TooltipList>
        </ReactTooltip>
        <MenuItem data-tip data-for="min_picture" primaryText="1.50 x 1.50 inch" value={1.5} />
        <ReactTooltip place="left" id="min_picture">
          <TooltipList>
            <TooltipListItem>{'Page Size 1.75" x 1.75"'}</TooltipListItem>
            <TooltipListItem>{'Cut Size 1.50" x 1.50"'}</TooltipListItem>
            <TooltipListItem>{'Safe Size 1.25" x 1.25"'}</TooltipListItem>
          </TooltipList>
        </ReactTooltip>
      </SelectField>
    </Dropdown>

    <Container>
      <SimpleList key="Sites List" data={pdfList} renderItemContent={PdfItem} renderRightControls={rightControls} />
    </Container>
  </FormContainer>
);

PDFForm.propTypes = {
  changeSize: PropTypes.func.isRequired,
  qrCodeSize: PropTypes.number.isRequired,
  pdfList: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  rightControls: PropTypes.func.isRequired,
};

const PDFWithInput = withInputModal(PDFForm);

const PDFDialog = ({ pdfList, rightControls, qrCodeSize, changeSize, ...rest }) => (
  <PDFWithInput
    rightControls={rightControls}
    pdfList={pdfList}
    qrCodeSize={qrCodeSize}
    changeSize={changeSize}
    {...rest}
  />
);

PDFDialog.defaultProps = {
  handleDelete: () => {},
  onSubmit: () => {},
  withDelete: false,
  withSubmit: false,
  withOnClick: true,
};

PDFDialog.propTypes = {
  changeSize: PropTypes.func.isRequired,
  qrCodeSize: PropTypes.number.isRequired,
  pdfList: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  rightControls: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  withDelete: PropTypes.bool,
};

export default PDFDialog;
