import styled from 'styled-components';

import { OfflineBoltBlue } from 'components/Layout/Icons';

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;
`;

export const TopInfo = styled.div``;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 14px;
`;

export const SubInfo = styled.div`
  margin-bottom: 16px;
`;

export const ShortCutIcon = styled(OfflineBoltBlue)`
  transform: scale(1.3);
`;
