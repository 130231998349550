import styled from 'styled-components';

const Container = styled.div<{ styles?: any }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};

  ${({ styles }) => styles}
`;

const Box = styled.div`
  flex: 1;
`;

const OrderList = styled.ol`
  list-style: none;
  padding-inline-start: 0;
`;

const List = styled.li`
  padding-inline-start: 0;
  white-space: pre-wrap;
  overflow: hidden;
  font-size: 1rem;
  margin: 0;
`;

export { Container, Box, OrderList, List };
