import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as tutorialsActions } from 'redux/tutorials';

import TableList from 'components/TableList';
import { AddIcon, BackIcon, LowPriorityIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import Subheader from 'components/Subheader';
import SequenceSetupDialog from 'components/Dialogs/SequenceSetupDialog';

import { tableConfigs, tableHeads } from './configs';
import PageSelectionDialog from './PageSelectionDialog';
import EditLandingPageDialog from './EditLandingPageDialog';
import { UserTypes } from './EditLandingPageDialog/constants';

import * as S from './styled';

class VideoCategory extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    landingPageList: PropTypes.array.isRequired,
    fetchLandingPageListRequest: PropTypes.func.isRequired,
    editLandingPageRequest: PropTypes.func.isRequired,
    updateLandingPageSequenceRequest: PropTypes.func.isRequired,
  };

  state = {
    searchData: '',
    selectedPage: {},
    pageSelectionDialogOpened: false,
    editLandingPageDialogOpened: false,
    pageIsEdit: false,
    isSequenceSetupDialogOpen: false,
  };

  componentDidMount() {
    const { fetchLandingPageListRequest } = this.props;
    fetchLandingPageListRequest();
  }

  setSelectedPage = selectedPage => {
    this.setState({ selectedPage });
  };

  openPageSelectionDialog = () => {
    this.setState({ pageSelectionDialogOpened: true });
  };

  closePageSelectionDialog = () => {
    this.setState({ pageSelectionDialogOpened: false, selectedPage: {} });
  };

  openEditLandingPageDialog = () => {
    const { selectedPage } = this.state;
    if (selectedPage.Name) {
      this.setState({ editLandingPageDialogOpened: true });
    }
  };

  closeEditLandingPageDialog = () => {
    this.setState({
      editLandingPageDialogOpened: false,
      selectedPage: {},
      pageSelectionDialogOpened: false,
      pageIsEdit: false,
    });
  };

  closeOnlyEditLandingPageDialog = () => {
    this.setState({ editLandingPageDialogOpened: false });
  };

  goToSections = item => {
    this.props.history.push(`/tutorials/${item.Id}/${item.Name}`);
  };

  backHome = () => {
    this.props.history.push('/home');
  };

  searchInList = event => {
    this.setState({
      searchData: event.target.value,
    });
  };

  filterList = item => item?.Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  openSequenceSetupDialog = () => this.setState({ isSequenceSetupDialogOpen: true });

  closeSequenceSetupDialog = () => this.setState({ isSequenceSetupDialogOpen: false });

  itemsForReorder = items => {
    if (!items.length) {
      return [];
    }

    return [
      {
        items: items.map(el => ({
          id: el.Id,
          content: el.Name,
          sequence: el.Sequence,
        })),
        content: '',
      },
    ];
  };

  onSubmit = items => {
    const { updateLandingPageSequenceRequest } = this.props;
    updateLandingPageSequenceRequest({ items });
    this.closeSequenceSetupDialog();
  };

  rightButtons = [
    {
      icon: <LowPriorityIcon />,
      handler: this.openSequenceSetupDialog,
      hint: 'Sequence setup',
      tooltipStyles: { marginTop: -10 },
    },
    {
      icon: <AddIcon />,
      handler: this.openPageSelectionDialog,
      hint: 'Add',
      tooltipStyles: { marginTop: -10 },
    },
  ];

  mapList = item =>
    item.PublishedName
      ? {
          ...item,
          RolesIcon: (
            <>
              <S.IconButton data-tip data-for={item.Id}>
                <S.RolesIcon />
              </S.IconButton>
              <S.Tooltip id={item.Id} place="bottom" effect="solid">
                <S.TooltipBlock>
                  <S.WhiteRolesIcon />
                  <S.RolesTooltipBlock>
                    <S.TooltipTitle>Access roles</S.TooltipTitle>
                    {item.Roles.map(roleValue => (
                      <S.RolesTooltipItem>{UserTypes.find(role => role.value === roleValue)?.name}</S.RolesTooltipItem>
                    ))}
                  </S.RolesTooltipBlock>
                </S.TooltipBlock>
              </S.Tooltip>
            </>
          ),
        }
      : null;

  onRowClick = item => {
    this.setState({ selectedPage: item, editLandingPageDialogOpened: true, pageIsEdit: true });
  };

  renderRightControllCell = item => {
    const handleClick = e => {
      e.stopPropagation();
      this.goToSections(item);
    };

    return (
      <S.TableCell>
        <ButtonIcon onClick={handleClick} tooltip="Tutorials Details">
          <S.IconForward />
        </ButtonIcon>
      </S.TableCell>
    );
  };

  render() {
    const { landingPageList, editLandingPageRequest } = this.props;
    const {
      searchData,
      pageSelectionDialogOpened,
      selectedPage,
      editLandingPageDialogOpened,
      pageIsEdit,
      isSequenceSetupDialogOpen,
    } = this.state;

    return (
      <>
        <Subheader
          key="Landing Pages"
          title="Landing Pages"
          isSiteOnLeftSide
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          zIndex={5}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backHome,
              hint: 'Back',
              tooltipStyles: { marginTop: -10 },
            },
          ]}
          rightButtons={this.rightButtons}
        />
        {!landingPageList?.length ? (
          <S.EmptyList>
            <S.EmptyListText>There are no Tutorials available.</S.EmptyListText>
          </S.EmptyList>
        ) : (
          <S.TableContainer>
            <TableList
              isPointer
              list={landingPageList.map(this.mapList).filter(this.filterList)}
              tableHeads={tableHeads}
              tableColumns={tableConfigs}
              renderRightControllCell={this.renderRightControllCell}
              onRowClick={this.onRowClick}
            />
          </S.TableContainer>
        )}
        <PageSelectionDialog
          landingPageList={landingPageList}
          isOpen={pageSelectionDialogOpened}
          onClose={this.closePageSelectionDialog}
          setSelectedPage={this.setSelectedPage}
          selectedPage={selectedPage}
          openEditLandingPageDialog={this.openEditLandingPageDialog}
        />
        <EditLandingPageDialog
          open={editLandingPageDialogOpened}
          handleClose={this.closeOnlyEditLandingPageDialog}
          handleExit={this.closeEditLandingPageDialog}
          pageToEdit={selectedPage}
          editLandingPage={editLandingPageRequest}
          pageIsEdit={pageIsEdit}
        />
        {isSequenceSetupDialogOpen && (
          <SequenceSetupDialog
            key="Sequence Setup Dialog"
            handleClose={this.closeSequenceSetupDialog}
            data={this.itemsForReorder(landingPageList)}
            isLoading={false}
            onSubmit={this.onSubmit}
            open={isSequenceSetupDialogOpen}
            isNewSaveButton
            listId="UnitItems"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ tutorials }) => ({
  landingPageList: tutorials.landingPageList,
});

const mapDispatchToProps = {
  fetchLandingPageListRequest: tutorialsActions.fetchLandingPageListRequest,
  editLandingPageRequest: tutorialsActions.editLandingPageRequest,
  updateLandingPageSequenceRequest: tutorialsActions.updateLandingPageSequenceRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCategory);
