import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EmptyList from 'components/EmptyList';
import ListVersionsItem from './ListVersionsItem';
import { ListContainer } from 'components/Layout/Containers';

const emptyListMessage = 'There are no units';

const List = ({ data, onItemClick, onDeleteVersion, openModal }) => (
  <ListContainer>
    {data && data.length ? (
      data
        .reverse()
        .map((unit, index) => (
          <ListVersionsItem
            key={`Unit${index}`}
            item={unit}
            index={index}
            onItemClick={onItemClick}
            onDeleteVersion={onDeleteVersion}
            openModal={openModal}
          />
        ))
    ) : (
      <EmptyList text={emptyListMessage} />
    )}
  </ListContainer>
);

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onDeleteVersion: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(List);
