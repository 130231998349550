import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { actions as authActions } from 'redux/auth';
import AppLoadingProgress from 'components/Progresses/AppLoading';
import ConfirmationDialog from 'components/Dialogs/Confirmation';
import ErrorDialog from 'components/Dialogs/Error';
import StartAppProgress from 'components/Progresses/StartApp';
import ErrorRoutePage from 'components/ErrorRoutePage';

import Login from 'modules/auth';
import Main from 'modules/main';
import UnsubcribePage from 'modules/auth/pages/UnsubscribePage';
import VerificationPage from 'modules/auth/pages/VerificationPage';
import PrivateRoute from './PrivateRoute';

class AppRouter extends PureComponent {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    requestCheckAuthentication: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.requestCheckAuthentication();
  }

  render() {
    const { authenticated, checked } = this.props;

    if (!checked) {
      return <StartAppProgress />;
    }

    return [
      <Router key="Router">
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot" component={Login} />
          <Route exact path="/activate" component={Login} />
          <Route exact path="/email/unsubscribe" component={UnsubcribePage} />
          <Route exact path="/verify" component={VerificationPage} />
          <Route exact path="/errorpage" component={ErrorRoutePage} />
          <PrivateRoute path="/" component={Main} authenticated={authenticated} />
        </Switch>
      </Router>,
      <AppLoadingProgress key="Global loader" />,
      <ConfirmationDialog key="Global confirmation" />,
      <ErrorDialog key="Global error" />,
    ];
  }
}

const mapStateToProps = ({ auth: { authenticated, checked } }) => ({
  authenticated,
  checked,
});

export default connect(mapStateToProps, { requestCheckAuthentication: authActions.authenticationCheckRequest })(
  AppRouter,
);
