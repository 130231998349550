import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as peopleActions } from 'redux/people';
import { actions as newSitesActions } from 'redux/newSites';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { createId, checkOptions, countUnitsAndItems } from 'components/Layout/ShortcutRules/helpers';
import {
  generatePersonRules,
  generateManagementRules,
  generateTeamRules,
  getInitialRules,
  initialRule,
} from 'components/Dialogs/Shortcut/Pickup/helpers';

import { ShortCutType, SiteType, ShortcutSchedule } from 'configs/propTypes';
import { AssignmentType } from 'configs/types';

import ShortcutForm from './Form';
import {
  overrideValues,
  initialValues,
  generateEditInitialValues,
  generateDate,
  ConfirmationDialogContent,
  optionRequestByType,
} from './helpers';
import validate from './validator';
import * as S from './styled';

class SiteSetup extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      sendSiteScheduleRequest: PropTypes.func.isRequired,
      editSiteScheduleRequest: PropTypes.func.isRequired,
      getAvailabilityOptionsRequest: PropTypes.func.isRequired,
      sendManagementOptionsRequest: PropTypes.func.isRequired,
      sendTeamOptionsRequest: PropTypes.func.isRequired,
      sendPersonOptionsRequest: PropTypes.func.isRequired,
      getJobsListRequest: PropTypes.func.isRequired,
      listStatusRequest: PropTypes.func.isRequired,
    }).isRequired,
    shortcut: ShortCutType.isRequired,
    currentSite: SiteType.isRequired,
    currentSchedule: ShortcutSchedule.isRequired,
    schedulesTrigger: PropTypes.number.isRequired,
    error: PropTypes.string.isRequired,
    appModule: PropTypes.string.isRequired,
    unitAndItems: PropTypes.arrayOf(PropTypes.number).isRequired,
    teamsList: PropTypes.shape().isRequired,
    jobsList: PropTypes.shape().isRequired,
    isActivator: PropTypes.bool.isRequired,
    setOverride: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    shortcutToPreview: PropTypes.shape().isRequired,
    siteSchedule: PropTypes.shape().isRequired,
  };

  getInitialValues = () => {
    const { currentSchedule, siteSchedule, isActivator } = this.props;
    if (currentSchedule.Id && isActivator) {
      return generateEditInitialValues(currentSchedule);
    }
    return siteSchedule?.IsScheduled ? generateEditInitialValues(siteSchedule) : initialValues;
  };

  state = {
    initialValues: this.getInitialValues(),
    isConfirmationOpened: false,
    managementRules: getInitialRules({ rules: this.props?.siteSchedule?.ManagementRules, name: 'management' }),
    personRules: getInitialRules({ rules: this.props?.siteSchedule?.PersonRules, name: 'person' }),
    teamRules: getInitialRules({ rules: this.props?.siteSchedule?.TeamRules, name: 'team' }),
    isErrorShown: false,
    previewNames: [],
  };

  componentDidMount() {
    const { appModule, actions } = this.props;

    if (appModule === 'shortcuts') {
      actions.getAvailabilityOptionsRequest();
    }
    actions.getJobsListRequest();
    actions.listStatusRequest();
  }

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onRequest = values => {
    const { actions, onNext, siteSchedule, currentSchedule, isActivator } = this.props;
    if (isActivator) {
      if (!currentSchedule.Id) {
        actions.sendSiteScheduleRequest(values);
        onNext();
      } else {
        actions.editSiteScheduleRequest({
          ...values,
          id: currentSchedule?.Id,
          onNext,
        });
      }
    } else if (!siteSchedule?.IsScheduled) {
      actions.sendSiteScheduleRequest(values);
      onNext();
    } else {
      actions.editSiteScheduleRequest({
        ...values,
        id: siteSchedule.Id,
        onNext,
      });
    }
  };

  onSend = ({ values }) => {
    const { currentSite, shortcut, appModule } = this.props;
    const { personRules, managementRules, teamRules } = this.state;

    const valuesToSend = {
      AssignmentType: AssignmentType[values.AssignmentType],
      RepeatCycle: values.RepeatCycle === 3 ? 4 : values.RepeatCycle,
      RepeatInterval: values.RepeatInterval,
      StartDate: generateDate(values.StartDate, values.startTime),
      EndDate: generateDate(values.EndDate, values.endTime),
      SiteID: currentSite.Id,
      ShortcutID: shortcut.Id,
    };

    if (appModule === 'shortcuts') {
      valuesToSend.PersonRules = generatePersonRules(values, personRules.rules)?.PersonRules;
      valuesToSend.TeamRules = generateTeamRules(values, teamRules.rules)?.TeamRules;
      valuesToSend.ManagementRules = generateManagementRules(values, managementRules.rules)?.ManagementRules;

      this.onRequest(valuesToSend);
    } else {
      this.onRequest(valuesToSend);
    }
  };

  getPreviewResults = (values, stateKey, requestName) => {
    const { currentSite, actions } = this.props;

    let valuesToSend;

    switch (requestName) {
      case 'person':
        valuesToSend = generatePersonRules(values, this.state[stateKey].rules);
        break;
      case 'management':
        valuesToSend = generateManagementRules(values, this.state[stateKey].rules);
        break;
      case 'team':
        valuesToSend = generateTeamRules(values, this.state[stateKey].rules);
        break;
      default:
        break;
    }

    const updatedValuesToSend = {
      ...valuesToSend,
      AssignmentType: AssignmentType[values.AssignmentType],
      SiteId: currentSite.Id,
    };

    let valueToCheck;

    switch (requestName) {
      case 'person':
        valueToCheck = valuesToSend.PersonRules;
        break;
      case 'management':
        valueToCheck = valuesToSend.ManagementRules;
        break;
      case 'team':
        valueToCheck = valuesToSend.TeamRules;
        break;
      default:
        break;
    }

    if (!checkOptions(valueToCheck)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState(state => ({ isErrorShown: false, previewNames: [...state.previewNames, requestName] }));
      if (requestName === 'management') {
        actions.sendManagementOptionsRequest(updatedValuesToSend);
      }
      if (requestName === 'team') {
        actions.sendTeamOptionsRequest(updatedValuesToSend);
      }
      if (requestName === 'person') {
        actions.sendPersonOptionsRequest(updatedValuesToSend);
      }
    }
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationOpened: false });
  };

  handleConfirmationDialog = (values, override) => {
    this.props.setOverride(override);
    this.closeConfirmationDialog();
    this.onSend({ values });
  };

  checkOverrideValues = values => {
    const { schedulesTrigger } = this.props;

    const diff = moment.duration(moment(values.StartDate).diff(moment(new Date()))).asDays();

    if (diff < overrideValues[schedulesTrigger]) {
      this.setState({ isConfirmationOpened: true });
    } else {
      this.setState({ isErrorShown: false, previewNames: [] });
      this.onSend({ values });
    }
  };

  onSubmitForm = values => {
    const { appModule, currentSite, actions } = this.props;
    const { personRules, managementRules, teamRules } = this.state;

    if (appModule === 'shortcuts') {
      const { PersonRules } = generatePersonRules(values, personRules.rules);
      const { TeamRules } = generateTeamRules(values, teamRules.rules);
      const { ManagementRules } = generateManagementRules(values, managementRules.rules);

      if (
        (values.AssignmentType === 2 && !checkOptions(PersonRules)) ||
        (values.AssignmentType === 1 && !checkOptions(TeamRules)) ||
        (values.AssignmentType === 0 && !checkOptions(ManagementRules))
      ) {
        this.setState({ isErrorShown: true });
      } else {
        const checkOptionRequest = actions[optionRequestByType[values.AssignmentType]];

        if (values.AssignmentType === 2) {
          this.getPreviewResults(values, 'managementRules', 'management');
        }
        checkOptionRequest({
          PersonRules,
          TeamRules,
          ManagementRules,
          AssignmentType: AssignmentType[values.AssignmentType],
          SiteId: currentSite.Id,
          onNext: () => this.checkOverrideValues(values),
        });
      }
    } else {
      this.checkOverrideValues(values);
    }
  };

  render() {
    const { currentSite, shortcut, appModule, currentSchedule, unitAndItems, siteSchedule, ...rest } = this.props;
    const { isConfirmationOpened, ...restState } = this.state;

    return (
      <S.Container>
        <S.MainTitle>{shortcut?.Name || ''}</S.MainTitle>
        <S.SiteBlock>
          <S.SecondaryTitle>
            {`${currentSite?.AdditionalSiteID.substring(0, 5) || ''} ${currentSite?.Name || ''}`}
          </S.SecondaryTitle>
          <S.Title>{`Units: ${unitAndItems[0]}`}</S.Title>
          <S.Title>{`Items: ${unitAndItems[1]}`}</S.Title>
        </S.SiteBlock>
        <Form
          onSubmit={this.onSubmitForm}
          validate={validate}
          initialValues={this.state.initialValues}
          render={({ handleSubmit, values, reset }) => (
            <form onSubmit={handleSubmit}>
              <ShortcutForm
                isEdit={siteSchedule?.IsScheduled}
                addOption={this.addOption}
                removeOption={this.removeOption}
                onChangeMatch={this.onChangeMatch}
                getPreviewResults={this.getPreviewResults}
                appModule={appModule}
                initialValues={this.state.initialValues}
                currentSchedule={currentSchedule}
                values={values}
                reset={reset}
                {...restState}
                {...rest}
              />
              <ActionsContainer>
                <ActionButton type="submit" label={`${siteSchedule?.IsScheduled ? 'Update' : 'Next'}`} isNext />
              </ActionsContainer>
              <ConfirmationDialog
                label="YES - OVERRIDE"
                labelCancel="NO - start next period"
                width="40%"
                isCancelNext
                text={<ConfirmationDialogContent />}
                open={isConfirmationOpened}
                onClick={() => this.handleConfirmationDialog(values, true)}
                handleClose={this.closeConfirmationDialog}
                onCancelClick={() => this.handleConfirmationDialog(values, false)}
              />
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts, settings, app, people, teams, newSites }, { currentSite }) => ({
  list: shortcuts.shortcutSitesInfo,
  shortcut: shortcuts.shortcut,
  schedulesTrigger: settings.list.TasksSchedulesTrigger,
  currentSchedule: shortcuts.currentSchedule,
  appModule: app.currentModule,
  error: shortcuts.error,
  availabilityOptions: people.availabilityOptions,
  manager: shortcuts.managementOptions,
  person: shortcuts.personOptions,
  team: shortcuts.teamOptions,
  shortcutToPreview: shortcuts.shortcutToPreview,
  siteSchedule: shortcuts.siteSchedule,
  unitAndItems: countUnitsAndItems(shortcuts.listOfItems, currentSite.Id),
  teamsList: teams.list,
  jobsList: newSites.jobsList,
  listStatus: shortcuts.listStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
      ...peopleActions,
      ...newSitesActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteSetup);
