import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Gateway = styled(Flex).attrs({
  w: 1,
})``;

export const Type = styled.div`
  display: block !important;
  width: 45%;
`;

export const Notes = styled.div`
  width: 55%;
  padding: 0 15px;
`;

export const Levels = styled.div`
  display: flex;
  min-width: 82px;
`;

export const LevelsTitle = styled.div``;

export const LevelsCount = styled.div`
  min-width: 27px;
  padding-left: 5px;
  text-align: center;
`;
