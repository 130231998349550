import React from 'react';

import { EMPTY_GUID } from 'helpers';

import { Text, Container, Cell, UpgradeRequest, TrendingUpImage, DemoTierText } from './styled';

const Item = onCellClick => tenant =>
  (
    <Container>
      <Cell>
        <Text>Name: {tenant.Name}</Text>
        {tenant.Notes ? <Text>Notes: {tenant.Notes}</Text> : null}
      </Cell>
      <Cell>
        <Text>
          Tier: {tenant.TierId === EMPTY_GUID ? <DemoTierText>Demo Tenants</DemoTierText> : tenant.TierInfo.Name}
        </Text>
      </Cell>
      <UpgradeRequest onClick={onCellClick(tenant)}>
        {tenant.IsUpgradeTenantRequestExists ? <TrendingUpImage upgrade={1} /> : <TrendingUpImage />}
      </UpgradeRequest>
    </Container>
  );

export default Item;
