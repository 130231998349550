import styled from 'styled-components';

import { isSafari } from 'helpers';

export const TextInput = styled.input`
  ${() => isSafari() && 'height: 17px'};
  width: fill-available;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  border: ${({ theme, meta }) => `1px solid ${meta.error && meta.touched ? theme.primaryRed : theme.primaryGrey}`};
  color: ${({ theme, meta }) => (meta.error && meta.touched ? theme.primaryRed : theme.mainGreen)};
  background: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  font-weight: 500;
  &::placeholder {
    color: ${({ theme, meta }) => (meta.error && meta.touched ? theme.primaryRed : theme.mainGreen)};
    font-size: 16px;
    font-weight: 500;
  }
`;
