import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`;

export const RowContainer = styled.div`
  display: flex;
`;

export const ItemContainer = styled.div`
  margin: 7px 2px;
  height: 17px;
  width: 42px;
  border: ${({ theme }) => `1px solid ${theme.primaryBlack}`}!important;
  background: ${({ theme, red }) => red && theme.mainRed};
  background: ${({ theme, green }) => green && theme.mainGreen};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 10px;
`;
