// this function created for a nested array to filter the inner array
// and if no result is found return the entire array empty
export const deepContains = (data, callback) =>
  data?.reduce((acc, el) => {
    const result = callback(el);
    if (result?.length) {
      return [...acc, { ...el, Data: result }];
    }

    return acc;
  }, []);
