export const validator = values => {
  const errors = {};

  if (!values.SensorTypeID) {
    errors.SensorTypeID = 'Required';
  }
  if (values.UnitId && !values.ItemId) {
    errors.ItemId = 'Required';
  }
  return errors;
};

export default validator;
