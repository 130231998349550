import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import TaskList from './pages/TasksList';
import UsersList from './pages/UsersList';

const Task = () => (
  <Switch>
    <Route exact path="/tasks" component={UsersList} />
    <Route exact path="/tasks/:id/:type/:date" component={TaskList} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Task;
