import React from 'react';
import { useDispatch } from 'react-redux';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useFormik } from 'formik';
import theme from 'theme';

import { actions as inventoryActions } from 'redux/inventory';

import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { Modal } from 'components/Modal';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';

import { AddOrRemovePartDialog, TransactionsDialog } from '../index';
import { TransactionReasonType } from '../../ui/InventoryChanges';
import { InventoryPartType, PartGroupsType, TransactionType } from 'types/inventory';
import { getModalContentByType } from './helpers';

import * as S from './styled';

type PropsType = {
  entityType: 'Part' | 'PartGroup';
  type: 'add' | 'addOrRemove' | 'edit';
  currentEntity: InventoryPartType | PartGroupsType;
  recordType: TransactionReasonType;
  transaction: TransactionType;
  editedField: 'BufferCount' | 'PurchaseTriggerCount';
  changeField?: (value: number) => void;
};

export const InventoryInfoDialog = NiceModal.create(
  ({ currentEntity, type, recordType, entityType, transaction, editedField, changeField }: PropsType) => {
    const modal = useModal();
    const dispatch = useDispatch();
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        newQuantity: 0,
        editedField: currentEntity[editedField],
      },
      onSubmit: () => {
        addOrRemovePartHandler('REMOVE');
      },
    });
    const MODALS = getModalContentByType(formik, entityType);
    const { title, content, negativeBtnLabel } = MODALS[type];
    const isPartInventory = entityType === 'Part';

    const receiveNewPart = () => {
      modal.hide();
      NiceModal.show(ConfirmationModal, {
        label: 'YES',
        labelCancel: 'RETURN',
        isCancelNext: true,
        text: (
          <S.ConfirmationModalContent>
            <b>
              This adds {formik.values.newQuantity} {isPartInventory ? 'parts' : 'groups'} to inventory
            </b>
            <div>Are you sure?</div>
          </S.ConfirmationModalContent>
        ),
        maxWidth: '600px',
        onClick: () => {
          const payload = {
            entityType,
            recordType,
            dto: {
              inventoryEntityId: currentEntity.Id,
              quantity: Number(formik.values.newQuantity),
            },
          };

          dispatch(inventoryActions.createPartTransactionRequest(payload));
          NiceModal.hide(ConfirmationModal);
          NiceModal.hide(TransactionsDialog);
          modal.hide();
        },
        onCancelClick: () => NiceModal.hide(ConfirmationModal),
      });
    };

    const addOrRemovePartHandler = (label: 'ADD' | 'REMOVE') => {
      modal.hide();
      NiceModal.show(AddOrRemovePartDialog, {
        title: 'Adjust Part Quantity',
        negativeBtnLabel: label,
        entityType,
        currentEntity,
        recordType,
        transaction,
      });
    };

    const editPart = () => {
      changeField?.(formik.values.newQuantity);
      modal.hide();
    };

    const callbackByType = {
      add: receiveNewPart,
      addOrRemove: () => addOrRemovePartHandler('ADD'),
      edit: editPart,
    };

    const onRequestClose = () => {
      formik.resetForm();
      modal.hide();
    };

    return (
      <Modal
        onRequestClose={onRequestClose}
        title={title}
        titleColor={theme.mainRed}
        isOpen={modal.visible}
        onAfterClose={modal.remove}
      >
        <S.RootContainer onSubmit={formik.handleSubmit}>
          {isPartInventory && <S.Title>{(currentEntity as InventoryPartType).MyPartID}</S.Title>}
          <S.BoldTitle>
            {isPartInventory
              ? (currentEntity as InventoryPartType).MyPartName
              : (currentEntity as PartGroupsType).MyGroupName}
          </S.BoldTitle>
          {content}
          <ActionsContainer gapValue={10} isColumn={type === 'addOrRemove'}>
            <ActionButton
              label={negativeBtnLabel}
              type="button"
              labelFontSize="18px"
              onClick={callbackByType[type]}
              // disabled={!formik.dirty}
              // isDisable={!formik.dirty}
            />
            {type === 'addOrRemove' && <ActionButton label="REMOVE" type="submit" labelFontSize="18px" />}
            <ActionButton
              type="button"
              isNext
              isGreenLabel
              label="CANCEL"
              labelFontSize="18px"
              onClick={onRequestClose}
            />
          </ActionsContainer>
        </S.RootContainer>
      </Modal>
    );
  },
);
