import styled from 'styled-components';
import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

export const FieldBlockContainer = styled.div`
  display: flex;
`;

export const Step = styled.div`
  background: ${({ theme }) => theme.mainRed};
  color: ${({ theme }) => theme.primaryWhite};
  width: 60px;
  border-radius: 7px;
  margin-bottom: -20px;
  margin-left: 35px;
  padding: 3px 0;
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 10px;
  width: 20%;

  input {
    font-size: 13px !important;
  }
`;

export const Container = styled.div`
  min-width: 675px;

  form {
    & > div:last-child {
      padding: 15px !important;

      & > button:first-child {
        margin-right: 10px !important;
      }

      & > button:last-child {
        margin-left: 10px !important;
      }
    }
  }
`;

export const SecondaryContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FieldLabel = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  font-weight: 300;
  padding: 13px 0 5px 13px;
  text-align: left;
`;

export const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primaryGrey};
  font-weight: 300;
  max-width: 450px;
  margin: 0 auto;
`;

export const SecondaryTitle = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  max-width: 450px;
  margin: 0 auto;
`;

export const MainTitle = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  margin: 0 auto;
`;

export const Block = styled(BorderRadiusContainer)`
  padding-bottom: 15px;
  margin-top: 35px;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
  text-align: left;
`;

export const SiteBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  border-top: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  margin: 15px 50px;
`;

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationText = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const radioStyle = {
  display: 'flex',
};

export const radioIntervalStyle = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  width: '100%',
};
