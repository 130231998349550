export function createInitialValues(scheduleDto) {
  const defaultDate = new Date();
  const initialValues = {
    ScheduleType: scheduleDto.ScheduleType,
    ScheduleData: {
      ScheduledDate: defaultDate,
      DailyStartDate: defaultDate,
      DailyRepeatUntil: defaultDate,
      DailyRepeatInterval: 1,
      WeeklyStartDate: defaultDate,
      WeeklyRepeatUntil: defaultDate,
      WeeklyRepeatInterval: 1,
      MonthlyStartDate: defaultDate,
      MonthlyRepeatUntil: defaultDate,
      MonthlyRepeatInterval: 1,
      YearlyStartDate: defaultDate,
      YearlyRepeatUntil: defaultDate,
      YearlyRepeatInterval: 1,
    },
  };

  switch (scheduleDto.ScheduleType) {
    case 'OneTime':
      initialValues.ScheduleData.ScheduledDate = new Date(scheduleDto.ScheduleData.ScheduledDate);
      break;
    case 'RecurringDaily':
      initialValues.ScheduleData.DailyStartDate = new Date(scheduleDto.ScheduleData.DailyStartDate);
      initialValues.ScheduleData.DailyRepeatUntil = new Date(scheduleDto.ScheduleData.DailyRepeatUntil);
      initialValues.ScheduleData.DailyRepeatInterval = scheduleDto.ScheduleData.DailyRepeatInterval;
      break;
    case 'RecurringWeekly':
      initialValues.ScheduleData.WeeklyStartDate = new Date(scheduleDto.ScheduleData.WeeklyStartDate);
      initialValues.ScheduleData.WeeklyRepeatUntil = new Date(scheduleDto.ScheduleData.WeeklyRepeatUntil);
      initialValues.ScheduleData.WeeklyRepeatInterval = scheduleDto.ScheduleData.WeeklyRepeatInterval;
      break;
    case 'RecurringMonthly':
      initialValues.ScheduleData.MonthlyStartDate = new Date(scheduleDto.ScheduleData.MonthlyStartDate);
      initialValues.ScheduleData.MonthlyRepeatUntil = new Date(scheduleDto.ScheduleData.MonthlyRepeatUntil);
      initialValues.ScheduleData.MonthlyRepeatInterval = scheduleDto.ScheduleData.MonthlyRepeatInterval;
      break;
    case 'RecurringYearly':
      initialValues.ScheduleData.YearlyStartDate = new Date(scheduleDto.ScheduleData.YearlyStartDate);
      initialValues.ScheduleData.YearlyRepeatUntil = new Date(scheduleDto.ScheduleData.YearlyRepeatUntil);
      initialValues.ScheduleData.YearlyRepeatInterval = scheduleDto.ScheduleData.YearlyRepeatInterval;
      break;
    default:
      return {};
  }

  return initialValues;
}
