import React from 'react';
import PropTypes from 'prop-types';

import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

const HeaderRightIcons = ({ module, openSideMenu, toggleInstructions }) => (
  <>
    {module !== 'activator' && (
      <ButtonIcon key="Instructions" tooltip="Instructions" onClick={toggleInstructions}>
        <S.IconInstructions />
      </ButtonIcon>
    )}
    <ButtonIcon key="Directions" tooltip="Menu" onClick={openSideMenu}>
      <S.IconDirections />
    </ButtonIcon>
  </>
);

HeaderRightIcons.propTypes = {
  module: PropTypes.string.isRequired,
  openSideMenu: PropTypes.func.isRequired,
  toggleInstructions: PropTypes.func.isRequired,
};

export default HeaderRightIcons;
