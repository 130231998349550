import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import theme from 'theme';

import validate from './validator';
import * as S from './styled';
import { UserTypes } from './constants';

class EditLandingPageDialog extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    pageToEdit: PropTypes.object.isRequired,
    editLandingPage: PropTypes.func.isRequired,
    pageIsEdit: PropTypes.bool.isRequired,
  };
  state = {
    isDeleteConfirmationOpened: false,
  };

  onSubmitForm = values => {
    const { pageToEdit, editLandingPage, handleExit } = this.props;
    const dto = {
      publishedName: values['published-name'],
      notes: values.notes,
      roles: pageToEdit.Roles,
      sequence: pageToEdit.Sequence,
      contentQty: pageToEdit.ContentQty,
      name: pageToEdit.Name,
      id: pageToEdit.Id,
      createdOn: pageToEdit.CreatedOn,
    };
    editLandingPage(dto);
    handleExit();
  };

  handleDeletePage = () => {
    const { pageToEdit, editLandingPage, handleExit } = this.props;
    const dto = {
      publishedName: '',
      notes: '',
      roles: pageToEdit.Roles,
      sequence: pageToEdit.Sequence,
      contentQty: pageToEdit.ContentQty,
      name: pageToEdit.Name,
      id: pageToEdit.Id,
      createdOn: pageToEdit.CreatedOn,
    };
    editLandingPage(dto);
    handleExit();
    this.closeDeleteConfirmationDialog();
  };

  openDeleteConfirmationDialog = () => {
    this.setState({ isDeleteConfirmationOpened: true });
  };

  closeDeleteConfirmationDialog = () => {
    this.setState({ isDeleteConfirmationOpened: false });
  };

  render() {
    const { isDeleteConfirmationOpened } = this.state;
    const { open, pageToEdit, pageIsEdit, handleClose } = this.props;

    return (
      <Dialog
        open={open}
        onRequestClose={handleClose}
        title={pageToEdit.PublishedName ? 'Tutorial Page Edit' : 'Tutorial Page Name'}
        titleColor={theme.primaryBlack}
      >
        <S.DialogContainer>
          <S.Title>
            System Page Name:<S.BoldText>{pageToEdit.Name}</S.BoldText>
          </S.Title>
          <S.FormContainer>
            <Form
              onSubmit={this.onSubmitForm}
              validate={validate}
              initialValues={{ 'published-name': pageToEdit, notes: pageToEdit.Notes }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <S.FieldBlock>
                    <Field
                      component={S.TextField}
                      name="published-name"
                      floatingLabelText="Published Page Name"
                      initialValue={pageToEdit.PublishedName}
                    />
                  </S.FieldBlock>
                  <S.Section>
                    <S.SectionHeader>Optional Notes</S.SectionHeader>
                    <S.SectionBody>
                      <S.FieldBlock>
                        <Field
                          component={S.NotesField}
                          name="notes"
                          floatingLabelText="Notes"
                          initialValue={pageToEdit.Notes}
                        />
                      </S.FieldBlock>
                    </S.SectionBody>
                  </S.Section>
                  <S.RolesText>Available to roles:</S.RolesText>
                  <S.RolesBlock>
                    {UserTypes.map(item => (
                      <S.RolesItem isColorRed={pageToEdit.Roles.includes(item.value)}>{item.name}</S.RolesItem>
                    ))}
                  </S.RolesBlock>
                  <ActionsContainer>
                    {pageIsEdit ? (
                      <ActionButton type="button" label="Delete Page" onClick={this.openDeleteConfirmationDialog} />
                    ) : (
                      <ActionButton type="button" label="Previous" isNext onClick={handleClose} />
                    )}
                    {pageIsEdit ? (
                      <ActionButton type="submit" label="Save" isNext />
                    ) : (
                      <ActionButton type="submit" label="Add Page" isNext />
                    )}
                  </ActionsContainer>
                </form>
              )}
            />
          </S.FormContainer>
          <ConfirmationDialog
            label="Delete"
            width={'40%'}
            maxWidth={'400px'}
            isCancelNext
            text={
              <S.ConfirmationContainer>
                <S.ConfirmationText>Are you sure you want to permanently delete this Tutorial Page?</S.ConfirmationText>
                <S.ConfirmationText>
                  <b>This cannot be undone!</b>
                </S.ConfirmationText>
              </S.ConfirmationContainer>
            }
            open={isDeleteConfirmationOpened}
            onClick={this.handleDeletePage}
            handleClose={this.closeDeleteConfirmationDialog}
          />
        </S.DialogContainer>
      </Dialog>
    );
  }
}

export default EditLandingPageDialog;
