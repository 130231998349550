import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider, ReactReduxContext, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Dialog } from 'components/Dialogs/v1/Base';
import Loader from 'components/Loader';
import { actions as scheduleActions } from 'redux/schedule';

import theme from 'theme';

import ChecklistSetup from './ShortcutSiteSetup';
import SelectTasks from './SelectTasks';

import * as S from './styled';

const STEPS = {
  0: {
    Component: ChecklistSetup,
  },
  1: {
    Component: SelectTasks,
  },
};

const ShortcutSetup = ({
  open,
  handleClose,
  handleExit,
  currentUnit,
  checklistToEdit,
  openTaskModal,
  dublicateChecklist,
  checkChecklistName,
  history,
}) => {
  const [step, setStep] = useState(0);
  const [currentChecklist, setCurrentChecklist] = useState({});
  const [overrideTime, setOverrideTime] = useState(false);
  const Step = STEPS[step].Component;

  const isLoading = useSelector(state => state.schedule.isLoading);
  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();

  const handlePrev = () => {
    setStep(prevState => prevState - 1);
  };

  const handleNext = () => {
    setStep(prevState => prevState + 1);
  };

  const setOverride = value => {
    setOverrideTime(value);
  };

  const onChecklistChange = checklist => {
    setCurrentChecklist(checklist);
  };

  const resetState = () => {
    setStep(0);
    setCurrentChecklist({});
  };

  const closeChecklist = () => {
    handleClose();
    resetState();
  };

  const exitChecklist = () => {
    handleExit();
    resetState();
  };

  useEffect(() => {
    dispatch(scheduleActions.fetchScheduleTemplatesRequest({ scheduleId: checklistToEdit.Id }));
  }, [checklistToEdit.Id, checklistToEdit.Name, dispatch]);

  useEffect(() => {
    resetState();
  }, [open]);

  return (
    <Dialog
      open={open}
      onRequestClose={step > 0 ? closeChecklist : exitChecklist}
      title={
        step > 0
          ? `${checklistToEdit.Name ? 'Edit' : 'New'} Checklist Items and Tasks`
          : `${checklistToEdit.Name ? 'Edit' : 'New'} Checklist`
      }
      titleColor={step > 0 ? theme.primaryBlue : theme.mainRed}
    >
      <S.DialogContainer>
        <Loader isLoading={isLoading} size="large" />
        <Provider store={store}>
          <Step
            onNext={handleNext}
            onBack={handlePrev}
            currentUnit={currentUnit}
            setCurrentChecklist={onChecklistChange}
            currentChecklist={currentChecklist}
            onClose={closeChecklist}
            onExit={exitChecklist}
            history={history}
            setOverride={setOverride}
            overrideTime={overrideTime}
            checklistToEdit={checklistToEdit}
            dublicateChecklist={dublicateChecklist}
            openTaskModal={openTaskModal}
            checkChecklistName={checkChecklistName}
          />
        </Provider>
      </S.DialogContainer>
    </Dialog>
  );
};

ShortcutSetup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleExit: PropTypes.func.isRequired,
  openTaskModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  currentUnit: PropTypes.shape().isRequired,
  checklistToEdit: PropTypes.shape().isRequired,
  dublicateChecklist: PropTypes.bool.isRequired,
  checkChecklistName: PropTypes.func.isRequired,
};

export default withRouter(ShortcutSetup);
