import React from 'react';
import PropTypes from 'prop-types';

import SimpleList from 'components/SimpleList';
import PdfItem from './PdfItem';

import { CancelButton, DialogContainer, CreateButton } from './styled';

const PdfReportDialog = ({ handleClose, open, title, pdfList, onSubmit, rightControls }) => (
  <DialogContainer
    actions={[<CancelButton onClick={handleClose} />, <CreateButton onClick={onSubmit} />]}
    onRequestClose={handleClose}
    open={open}
    title={title}
  >
    {pdfList && (
      <SimpleList key="Report List" data={pdfList} renderItemContent={PdfItem} renderRightControls={rightControls} />
    )}
  </DialogContainer>
);

PdfReportDialog.defaultProps = {
  endDate: null,
  startDate: null,
};

PdfReportDialog.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pdfList: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rightControls: PropTypes.func.isRequired,
};

export default PdfReportDialog;
