import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ScheduleInfo } from 'configs/propTypes';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as scheduleActions } from 'redux/schedule';

import StatusMenuDialog from 'components/Header/StatusMenuDialog';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { LoopIcon, PlayIconFilled } from 'components/Layout/Icons';
import EditScheduleModal from './EditScheduleModal';
import NotAssignedTasksModal from './NotAssignedTasksModal';
import theme from 'theme';
import * as S from './styled';

const returnAssignmentType = (type, assigneeId) => {
  if (type === 0) {
    return assigneeId ? 'Person' : 'Pick-Up';
  }
  return 'Team';
};

class SupervisorToolbar extends Component {
  static propTypes = {
    accessWarning: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    requestAssignEmployee: PropTypes.func.isRequired,
    requestChangeScheduleStatus: PropTypes.func.isRequired,
    requestScheduleAssignableUsers: PropTypes.func.isRequired,
    requestSetSupervisor: PropTypes.func.isRequired,
    unitInfo: ScheduleInfo.isRequired,
    details: PropTypes.shape.isRequired,
    onClickStatus: PropTypes.func.isRequired,
    setTimeFrame: PropTypes.func.isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  static defaultProps = {
    AllowUnassignedUserInSchedule: false,
  };

  state = {
    ids: [],
    isEditScheduleVisible: false,
    notAssignedTasksOpened: false,
    statusMenuDialogOpened: false,
    isConfirmationOverrideOpened: false,
  };

  goToConfirmationDialog = status => {
    this.props.openConfirmationDialog(
      'You are about to activate this Schedule. This cannot be undone. Continue?',
      () => this.changeScheduleStatus(status),
      'Activate Schedule?',
    );
  };

  activateCheckList = () => {
    this.closeNotAssignedTasksModal();
    this.goToConfirmationDialog('PendingActivation');
  };

  changeUnassignedTasksToOpen = () => {
    const { scheduleId, unitId } = this.props.match.params;
    const { ids } = this.state;

    this.closeNotAssignedTasksModal();
    this.props.requestAssignEmployee({
      scheduleId,
      unitId,
      userId: null,
      ids,
      IsOpenStatus: true,
      shouldActiveteSchedule: true,
    });
  };

  closeNotAssignedTasksModal = () => {
    this.setState({ notAssignedTasksOpened: false });
  };

  openNotAssignedTasksModal = () => {
    this.setState({ notAssignedTasksOpened: true });
  };

  onOpenAssignableUsers = () => {
    this.props.requestScheduleAssignableUsers();
  };

  setSupervisor = item => {
    const { unitId, scheduleId } = this.props.match.params;

    this.props.requestSetSupervisor({
      unitId,
      scheduleId,
      userId: item.Key,
    });
  };

  closeModal = () => {
    this.setState({
      isEditScheduleVisible: false,
      isDeactivateDialogVisible: false,
    });
  };

  changeScheduleStatus = status => {
    const { scheduleId, unitId } = this.props.match.params;

    this.props.requestChangeScheduleStatus({
      status,
      unitId,
      scheduleId,
    });
  };

  openEditScheduleModal = () => {
    if (this.props.unitInfo.ActiveStatus === 'Active') {
      this.props.openErrorDialog(this.props.accessWarning, 'Warning');
    } else {
      this.setState({ isEditScheduleVisible: true });
    }
  };

  onClickStatusButton = ({ isStart, isNextPeriod }) => {
    if (isStart || isNextPeriod) {
      this.setState({ isConfirmationOverrideOpened: true, isStart, isNextPeriod });
    } else {
      this.props.onClickStatus({ isStart, isNextPeriod });
      this.closeStatusMenu();
    }
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationOverrideOpened: false });
    this.closeStatusMenu();
  };

  confirmConfirmationDialog = isConfirm => {
    const { isStart, isNextPeriod } = this.state;

    this.props.onClickStatus({ isStart, isNextPeriod, ignoreLeadTime: isConfirm });
    this.setState({ isConfirmationOverrideOpened: false });
    this.closeStatusMenu();
  };

  closeStatusMenu = () => {
    this.setState({ statusMenuDialogOpened: false });
  };

  selectStatusIcon = () => {
    const { unitInfo } = this.props;

    if (unitInfo.ActiveStatus === 'Inactive') {
      return <PlayIconFilled color={theme.primaryGrey} />;
    } else if (!unitInfo.IsDynamic) {
      return <PlayIconFilled />;
    } else if (unitInfo.IsPaused === true) {
      return <PlayIconFilled />;
    }
    return <LoopIcon color={theme.primaryRed} />;
  };

  openStatusMenu = () => {
    this.setState({ statusMenuDialogOpened: true });
  };

  renderTitle = name => (
    <>
      <S.Checklist>Checklist: </S.Checklist>
      <S.UnitName>{name}</S.UnitName>
      <S.Checklist>Assigned to: </S.Checklist>
      <S.UnitName>
        {returnAssignmentType(this.props.details.ChecklistAssignmentType, this.props.details.AssigneeID)}
      </S.UnitName>
      {this.props.details.ChecklistAssignmentType !== 0 ? (
        <>
          <S.Checklist>Name: </S.Checklist>
          <S.UnitName>{this.props.details.TeamName}</S.UnitName>
        </>
      ) : (
        <>
          <S.Checklist>Name: </S.Checklist>
          <S.UnitName>
            {this.props.details.AssigneeID &&
            this.props.people.length &&
            this.props.people.find(item => item.Id === this.props.details.AssigneeID)
              ? this.props.people.find(item => item.Id === this.props.details.AssigneeID).Name
              : 'Anyone'}
          </S.UnitName>
        </>
      )}
    </>
  );

  render() {
    const { unitInfo, setTimeFrame } = this.props;
    const { isEditScheduleVisible, notAssignedTasksOpened, statusMenuDialogOpened, isConfirmationOverrideOpened } =
      this.state;

    return [
      <S.Container
        key="SupervisorToolbar"
        title={this.renderTitle(unitInfo.Name)}
        zIndex={4}
        rightButtons={[
          {
            icon: this.selectStatusIcon(),
            handler: this.openStatusMenu,
            hint: unitInfo.IsDynamic ? 'Dynamic' : 'Manual',
          },
        ]}
      />,
      <EditScheduleModal
        key="EditScheduleModal"
        open={isEditScheduleVisible}
        handleClose={this.closeModal}
        initialValues={{ Name: unitInfo.Name }}
      />,
      <StatusMenuDialog
        key="Status Menu Dialog"
        onRequestClose={this.closeStatusMenu}
        open={statusMenuDialogOpened}
        isDynamic={unitInfo.IsDynamic}
        activeStatus={unitInfo.ActiveStatus}
        isPaused={unitInfo.IsPaused}
        IsNextPeriodAvailable={unitInfo.IsNextPeriodAvailable}
        setTimeFrame={setTimeFrame}
        onClickStatusButton={this.onClickStatusButton}
      />,
      <NotAssignedTasksModal
        key="NotAssignedTasksModal"
        title="Some tasks are not assigned"
        open={notAssignedTasksOpened}
        handleChange={this.changeUnassignedTasksToOpen}
        handleClose={this.closeNotAssignedTasksModal}
        handleContinue={this.activateCheckList}
        onSubmit={() => {}}
        withCancel
        withSubmit={false}
      />,
      <ConfirmationDialog
        label="YES - OVERRIDE"
        labelCancel="NO - start next period"
        width={'40%'}
        maxWidth={'400px'}
        isCancelNext
        text={
          <S.ConfirmationContainer>
            <S.ConfirmationText>Start date is sooner than lead time for creating tasks!</S.ConfirmationText>
            <S.ConfirmationText>
              <b>Override the lead time?</b>
            </S.ConfirmationText>
          </S.ConfirmationContainer>
        }
        open={isConfirmationOverrideOpened}
        onClick={() => this.confirmConfirmationDialog(true)}
        handleClose={this.closeConfirmationDialog}
        onCancelClick={() => this.confirmConfirmationDialog(false)}
      />,
    ];
  }
}

const mapStateToProps = ({
  schedule: { assignableUsers, taskTemplates, details },
  settings: {
    list: { AllowUnassignedUserInSchedule },
  },
}) => ({
  AllowUnassignedUserInSchedule,
  assignableUsers,
  taskTemplates,
  details,
});

export default withRouter(
  connect(mapStateToProps, {
    openConfirmationDialog,
    openErrorDialog,
    requestAssignEmployee: scheduleActions.assignEmployeeRequest,
    requestChangeScheduleStatus: scheduleActions.changeScheduleStatusRequest,
    requestScheduleAssignableUsers: scheduleActions.fetchScheduleAssignableUsersRequest,
    requestSetSupervisor: scheduleActions.setSupervisorRequest,
  })(SupervisorToolbar),
);
