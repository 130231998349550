import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import InstructionsHandler from 'helpers/InstructionsHandler';

import HeaderLeftIcons from './HeaderLeftIcons';
import HeaderRightIcons from './HeaderRightIcons';
import InstructionsDialog from './InstructionsDialog';
import ProfileDialog from './ProfileDialog';
import SideMenuDialog from './SideMenuDialog';

import { Bar, Logo, titleStyle } from './styled';

const modulesWithoutInstructions = ['activator'];

class Header extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userType: PropTypes.number.isRequired,
  };

  state = {
    instructionsOpened: !InstructionsHandler.getStopFromShowingForModule(this.props.location),
    module: InstructionsHandler.getModuleFromLocation(this.props.location),
    profileDialogOpened: false,
    sideMenuDialogOpened: false,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const nextModule = InstructionsHandler.getModuleFromLocation(nextProps.location);

      if (this.state.module !== nextModule) {
        this.setState({
          module: nextModule,
          instructionsOpened: !InstructionsHandler.getStopFromShowingForModule(nextProps.location),
        });
      }
    }
  }

  closeInstructions = () => {
    this.setState({ instructionsOpened: false });
  };

  closeProfile = () => {
    this.setState({ profileDialogOpened: false });
  };

  openProfile = () => {
    this.setState({ profileDialogOpened: true, sideMenuDialogOpened: false });
  };

  closeSideMenu = () => {
    this.setState({ sideMenuDialogOpened: false });
  };

  openSideMenu = () => {
    this.setState({ sideMenuDialogOpened: true });
  };

  toggleInstructions = () => {
    this.setState({ instructionsOpened: !this.state.instructionsOpened, sideMenuDialogOpened: false });
  };

  toHome = () => {
    this.closeInstructions();
    this.props.history.push('/home');
  };

  toActivators = () => {
    this.closeInstructions();
    this.props.history.push('/activator');
  };

  toSelectedPage = path => {
    const { history } = this.props;

    this.closeSideMenu();
    history.push(path);
  };

  render() {
    const { instructionsOpened, module, profileDialogOpened, sideMenuDialogOpened } = this.state;
    const { userType } = this.props;

    return (
      <>
        <Bar
          key="InCheq App Header"
          title={<Logo />}
          iconElementLeft={
            <HeaderLeftIcons toHome={this.toHome} toActivators={this.toActivators} userType={userType} />
          }
          iconElementRight={
            <HeaderRightIcons
              module={module}
              openSideMenu={this.openSideMenu}
              toggleInstructions={this.toggleInstructions}
            />
          }
          titleStyle={titleStyle}
        />
        <ProfileDialog key="Profile Dialog" onRequestClose={this.closeProfile} open={profileDialogOpened} />
        <SideMenuDialog
          key="Side Menu Dialog"
          onRequestClose={this.closeSideMenu}
          openProfile={this.openProfile}
          open={sideMenuDialogOpened}
          toSelectedPage={this.toSelectedPage}
        />
        {!modulesWithoutInstructions.includes(module) && (
          <InstructionsDialog
            key="Instructions Dialog"
            module={module}
            onRequestClose={this.closeInstructions}
            open={instructionsOpened}
          />
        )}
      </>
    );
  }
}

export default withRouter(Header);
