import styled from 'styled-components';

import { WarningIcon } from 'components/Layout/Icons';

export const Container = styled.div`
  padding: 0 30px;
`;

export const ListItemContainer = styled.div`
  z-index: ${({ index }) => index} !important;
`;

export const SelectCheckobesContainer = styled.div`
  padding: 0 30px;
  margin-top: 5px;
  display: flex;
  align-items: flex-end;

  & > div {
    justify-content: center;
    color: ${({ theme }) => theme.primaryGrey} !important;
  }

  & > div:last-child {
    margin-left: 30px;
  }
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const ListItem = styled.li`
  min-height: 48px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > div:last-child {
    justify-content: center;
  }
`;

export const ItemNameAndCheckbox = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  word-break: break-all;
`;

export const ItemValue = styled.div`
  color: ${({ theme }) => theme.primaryDark};
  display: flex;
  align-items: center;
  width: 33%;
`;

export const IconWarning = styled(WarningIcon)`
  fill: ${({ theme }) => theme.primaryTasks} !important;
`;
