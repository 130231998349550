import { ListItem } from 'components/Layout/Section';
import React, { FC } from 'react';
import theme from 'theme';
import { DemoTasksIcon, ImageIcon, AccountsIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

type PropsType = {
  openCorporateLogoDialog: () => void;
  openDemoTasksDialog: () => void;
  openFunctionsDialog: () => void;
};

export const General: FC<PropsType> = ({ openCorporateLogoDialog, openDemoTasksDialog, openFunctionsDialog }) => (
  <ListItem
    zIndex={8}
    key="General"
    name="General"
    content={
      <>
        <ButtonIcon onClick={openCorporateLogoDialog} tooltip="Change logo">
          <ImageIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openDemoTasksDialog} tooltip="Demo">
          <DemoTasksIcon />
        </ButtonIcon>
        <ButtonIcon onClick={openFunctionsDialog} tooltip="Functions">
          <AccountsIcon color={theme.primaryWhite} />
        </ButtonIcon>
      </>
    }
  />
);
