import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';

export const FormContainer = styled.div`
  position: relative;
  margin: 0 20px 20px;
`;

export const IconButton = styled(ButtonIcon)`
  position: absolute !important;
  right: -10px;
  top: 18px;
`;
