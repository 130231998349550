import React from 'react';
import PropTypes from 'prop-types';

import { Container, CheckboxWrapper, StartButton, StopFromShowing } from './styled';

const Actions = ({ checkStopFromShowing, module, onStartClick, stopFromShowingChecked }) => (
  <Container>
    <CheckboxWrapper>
      <StopFromShowing checked={stopFromShowingChecked} module={module} onCheck={checkStopFromShowing} />
    </CheckboxWrapper>
    <StartButton module={module} onClick={onStartClick} />
  </Container>
);

Actions.propTypes = {
  checkStopFromShowing: PropTypes.func.isRequired,
  module: PropTypes.string.isRequired,
  onStartClick: PropTypes.func.isRequired,
  stopFromShowingChecked: PropTypes.bool.isRequired,
};

export default Actions;
