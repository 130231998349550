import React from 'react';
import PropTypes from 'prop-types';

import theme from 'theme';

import BaseDialog from 'components/Dialogs/Base';

import * as S from './styled';

const ApproveDialog = ({ open, text, onClose, onApprove }) => (
  <BaseDialog open={open} onRequestClose={onClose} title="Alert" titleColor={theme.primaryRed}>
    <S.DialogContainer>
      <S.DialogText>{text}</S.DialogText>
    </S.DialogContainer>
    <S.DialogControlsContainer>
      <S.DialogControlButton type="button" onClick={onClose}>
        Cancel
      </S.DialogControlButton>
      <S.DialogControlButton type="button" isApprove onClick={onApprove}>
        Yes
      </S.DialogControlButton>
    </S.DialogControlsContainer>
  </BaseDialog>
);

ApproveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
};

export default ApproveDialog;
