import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';
import { Checkbox } from 'material-ui';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 45vw;
  padding: 20px;
`;

const Wrapper = styled(BorderRadiusContainer)`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.darkGrey};
  border-bottom: 1px solid ${({ theme }) => theme.darkGrey};
  border-left: 1px solid ${({ theme }) => theme.darkGrey};
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
`;

const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
`;

const Title = styled.li`
  color: ${({ theme }) => theme.darkGrey};
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 10px;
`;

const Value = styled.li`
  color: ${({ theme }) => theme.mainRed};
  font-weight: 500;
  padding-bottom: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const CheckBox = styled(Checkbox).attrs({
  labelStyle: ({ theme }) => ({
    width: '100%',
    color: theme.darkGrey,
    marginRight: '10px',
  }),
  iconStyle: ({ theme }) => ({
    fill: theme.darkGrey,
  }),
})`
  width: auto !important;
`;

const Name = styled.p`
  color: ${({ theme }) => theme.mainRed};
`;

export { Container, List, Title, Value, Wrapper, Label, InfoContainer, CheckBox, Name };
