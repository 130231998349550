import styled from 'styled-components';
import { MonetizationOnIcon } from 'components/Layout/Icons';
import { Checkbox } from 'material-ui';
import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

export const BorderRadiusBlock = styled(BorderRadiusContainer)`
  position: relative;
`;

const PartInfoContainer = styled(BorderRadiusBlock)`
  margin: 0;
  margin-top: 20px;
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
`;

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  & > span {
    padding: 8px 4px;
  }
`;

const CheckBox = styled(Checkbox)`
  width: auto;
`;

const PartInfoSection = styled.section`
  padding: 10px;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
`;

const Col = styled.div`
  flex: 1;
`;

const PartInfo = styled.div`
  flex: 2;
  padding: 4px;
`;

const RightActionIcon = styled(PartInfo)`
  text-align: end;

  & svg {
    width: 24px;
    height: 24px;
  }
`;

const CostsInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MonetizationIcon = styled(MonetizationOnIcon)`
  color: ${({ theme }) => theme.primaryTasks} !important;
  width: 40px !important;
  height: 40px !important;
`;

const BoldLabel = styled.p`
  font-weight: bolder;
  font-size: 0.8rem;
`;

export {
  PartInfoContainer,
  Label,
  ListContainer,
  CheckBox,
  PartInfoSection,
  Col,
  Body,
  PartInfo,
  RightActionIcon,
  CostsInfoContainer,
  MonetizationIcon,
  BoldLabel,
};
