import styled from 'styled-components';
import { InputBlockLabel } from 'components/Layout/Containers';
import { SearchIcon } from 'components/Layout/Icons';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

const FormContainer = styled.div`
  margin: 0 20px;
`;

const InputContainer = styled.div`
  margin: 0 40px;
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
  padding: 16px;
  text-align: left;
`;

const SearchContainer = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
  margin: 20px 0;
`;

const NewItemContainer = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.lightGrey};
`;

const Search = styled(SearchIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

const TextField = styled(TextFieldAdapter).attrs({
  style: {
    width: '80%',
    height: '50px',
  },
  inputStyle: {
    height: 'auto',
  },
  floatingLabelStyle: {
    top: '16px',
  },
})``;

const ConfirmationTextContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 20px;

  & > p {
    color: ${({ theme }) => theme.mainRed};
  }
`;

export {
  FormContainer,
  InputContainer,
  Label,
  SearchContainer,
  NewItemContainer,
  Search,
  ConfirmationTextContainer,
  TextField,
};
