import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ContentContainer, ToolbarContainer } from 'components/Layout/Containers';

export const Container = styled(ContentContainer)``;

export const Toolbar = styled(ToolbarContainer)``;

export const Line = styled(Flex)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TextBlock = styled(Flex).attrs({
  column: true,
  justify: 'center',
  align: 'baseline',
})`
  &:first-child {
    width: 23%;
  }
  &:nth-child(2) {
    width: 15%;
    padding: 0 5px;
  }
  &:nth-child(3) {
    width: 52%;
  }
  &:last-child {
    width: 12%;
  }
`;

export const VersionTextBlock = styled(Flex).attrs({
  column: true,
  justify: 'center',
  align: 'center',
})`
  width: 105px;
  height: 100%;
  font-weight: bold;
`;
