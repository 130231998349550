import styled from 'styled-components';

import { PickerDate } from 'components/Layout/Pickers';

export const DatePicker = styled(PickerDate).attrs({
  style: {
    height: '0.1px',
    width: '0.1px',
  },
})``;

export default DatePicker;
