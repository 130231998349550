import styled from 'styled-components';

const sizes = {
  small: {
    width: '60px',
    height: '35px',
  },
  large: {
    width: '80px',
    height: '45px',
  },
};

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: ${({ labelPosition }) => (labelPosition === 'top' ? 'column' : 'row-reverse')};
  align-items: center;
`;

export const NumberFieldContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const NumberField = styled.input`
  ${({ size }) => sizes[size]};
  border-radius: 10px;
  background-color: ${({ background }) => background};
  text-align: center;
  border: ${({ theme, isError }) => `1px solid ${isError ? theme.primaryRed : theme.primaryGrey}`};
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 18px;
  font-weight: bold;
  ${({ labelPosition }) => labelPosition === 'top' && { marginTop: '20px' }};
`;

export const NumberControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -35px;
  ${({ labelPosition }) => labelPosition === 'top' && { top: '18px' }};
`;

export const ButtonIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0 6px;
`;
