import { bindActionCreators } from 'redux';
import { actions as globalSettingsActions } from 'redux/globalSettings';

const mapStateToProps = ({ globalSettings: { assetCategoryList, assetCategory, assetTypeList } }) => ({
  assetCategoryList,
  assetCategory,
  assetTypeList,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...globalSettingsActions,
    },
    dispatch,
  ),
});

export { mapStateToProps, mapDispatchToProps };
