import styled from 'styled-components';
import Checkbox from 'material-ui/Checkbox';

export const CheckboxButton = styled(Checkbox)`
  div > label {
    width: 360px !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
`;

export const styles = () => {};
