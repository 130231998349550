import React from 'react';
import moment from 'moment';

import * as S from 'modules/globalsettings/pages/styled';
import { RightItem } from './styled';

const Item = versionLegal => (
  <S.Group>
    <S.Item>Version: {versionLegal.VersionNumber}</S.Item>

    <S.Item>Notes: {versionLegal.VersionNotes ? versionLegal.VersionNotes : null}</S.Item>

    <RightItem>Saved: {moment(versionLegal.CreatedOn).format('ddd MMM DD-YYYY')}</RightItem>
  </S.Group>
);

export default Item;
