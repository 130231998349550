import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { ChevronUp, ChevronDown } from 'components/Layout/Icons';

import theme from 'theme';

import * as S from './styled';

const CustomNumberInputField = ({
  name,
  change,
  customChange,
  labelComponent,
  labelPosition,
  size,
  readOnly,
  background,
}) => {
  const increment = currentValue => {
    if (customChange) {
      customChange(1);
    }

    change(name, Number(currentValue) + 1);
  };

  const decrement = currentValue => {
    if (Number(currentValue) === 0) {
      return;
    }

    if (customChange) {
      customChange(-1);
    }

    change(name, Number(currentValue) - 1);
  };

  const onInputChange = (e, input) => {
    input.onChange(e);

    if (customChange) {
      customChange(Number(e.target.value - input.value));
    }
  };

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <S.FieldContainer labelPosition={labelPosition}>
          {labelComponent}
          <S.NumberFieldContainer>
            <S.NumberField
              {...input}
              onChange={e => onInputChange(e, input)}
              readOnly={readOnly}
              type="number"
              min="0"
              labelPosition={labelPosition}
              size={size}
              isError={meta.error && meta.touched}
              background={background}
            />
            {!readOnly && (
              <S.NumberControlsContainer labelPosition={labelPosition}>
                <S.ButtonIcon type="button" onClick={() => increment(input.value)}>
                  <ChevronUp viewBox="0 0 24 12" />
                </S.ButtonIcon>
                <S.ButtonIcon type="button" onClick={() => decrement(input.value)}>
                  <ChevronDown viewBox="0 0 24 24" />
                </S.ButtonIcon>
              </S.NumberControlsContainer>
            )}
          </S.NumberFieldContainer>
        </S.FieldContainer>
      )}
    </Field>
  );
};

CustomNumberInputField.propTypes = {
  change: PropTypes.func,
  customChange: PropTypes.func,
  labelComponent: PropTypes.node.isRequired,
  labelPosition: PropTypes.oneOf(['top', 'right']),
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
  readOnly: PropTypes.bool,
  background: PropTypes.string,
};

CustomNumberInputField.defaultProps = {
  labelPosition: 'right',
  size: 'small',
  readOnly: false,
  customChange: null,
  change: () => {},
  background: theme.lightGrey,
};

export default CustomNumberInputField;
