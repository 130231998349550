import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  fetchFavoriteSites,
  fetchItemsWithAlert,
  getMasterSensorRecordList,
  getWithInternalObjects,
  setSiteIsFavorite,
} from 'http/health';

import { actions } from './index';
import { formatServerError } from 'helpers';
import { formatAlertTrends, formatFavoriteSites } from './formatters';

function* getItemsWithAlert({ payload }) {
  try {
    const { data } = yield call(fetchItemsWithAlert, payload);
    yield put(actions.getItemsWithAlertSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.getItemsWithAlertFailure(formatServerError(error, 'An error occurred while fetching alerts data.')),
    );
  }
}

function* getFavoriteSites({ payload: { setInitialFavoriteSites, selectedSensor } }) {
  try {
    const { data } = yield call(fetchFavoriteSites);
    const favoriteSites = formatFavoriteSites(data.root);
    yield put(actions.getFavoriteSitesSuccess(favoriteSites));
    setInitialFavoriteSites(favoriteSites.siteIds, selectedSensor);
  } catch (error) {
    yield put(
      actions.getFavoriteSitesFailure(
        formatServerError(error, 'An error occurred while fetching favorite sites data.'),
      ),
    );
  }
}

function* setFavoriteSites({ payload: { isFavorite, newFavoriteSites, notFavoriteSites } }) {
  try {
    yield all([
      ...newFavoriteSites.map(siteId => call(setSiteIsFavorite, { siteId, isFavorite: true })),
      ...notFavoriteSites.map(siteId => call(setSiteIsFavorite, { siteId, isFavorite: false })),
    ]);
    yield put(actions.setFavoriteSitesSuccess());
    yield put(actions.getItemsWithAlertRequest(isFavorite || null));
  } catch (error) {
    yield put(
      actions.setFavoriteSitesFailure(
        formatServerError(error, 'An error occurred while fetching favorite sites data.'),
      ),
    );
  }
}

function* getAlertTrends({ payload: { id, alertTrendsVisible, openAlertTrends, status } }) {
  try {
    const [
      {
        data: { root: alertTrends },
      },
      {
        data: {
          entity: { Levels },
        },
      },
    ] = yield all([call(getMasterSensorRecordList, id), call(getWithInternalObjects, id)]);
    yield put(actions.getAlertTrendsSuccess(formatAlertTrends(alertTrends, Levels)));
    openAlertTrends(id, alertTrendsVisible, status);
  } catch (error) {
    yield put(
      actions.getAlertTrendsFailure(formatServerError(error, 'An error occurred while fetching Alert Trends data.')),
    );
  }
}

const healthSagas = [
  takeEvery(actions.getItemsWithAlertRequest, getItemsWithAlert),
  takeEvery(actions.getFavoriteSitesRequest, getFavoriteSites),
  takeEvery(actions.setFavoriteSitesRequest, setFavoriteSites),
  takeEvery(actions.getAlertTrendsRequest, getAlertTrends),
];

export default healthSagas;
