import styled from 'styled-components';
import { DirectionRunsIcon, LockIcon, LoyaltyIcon } from 'components/Layout/Icons';

export const typeRadioStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '-4px -10px 0px 0px',
  fontSize: '16px',
  width: '100%',
};

export const Lock = styled(LockIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})``;

export const Loyalty = styled(LoyaltyIcon)`
  color: ${({ theme }) => theme.darkGrey} !important;
`;

export const RadioText = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin: 0px -15px 10px 0px;
`;

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0;
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.mainLightBlue};
  color: ${({ theme }) => theme.primaryWhite};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 15px;
`;

export const SectionBody = styled.div`
  padding: 0 30px 15px;
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 15px 15px 0;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px !important;
  background-color: ${({ theme }) => theme.mainRed};
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TestRunIcon = styled(DirectionRunsIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;

export const Text = styled.span`
  margin-left: 10px;
`;
