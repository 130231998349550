import React from 'react';
import PropTypes from 'prop-types';

import { BackIcon, ImageIcon, PdfIcon, SettingsIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';

const TopBar = ({ goBack, openUploadImageDialog, openUploadPdfDialog, title, unitVersion }) => (
  <Subheader
    leftButtons={[
      {
        icon: <BackIcon />,
        handler: goBack,
        hint: 'Back',
      },
    ]}
    rightButtons={[
      {
        icon: <SettingsIcon />,
        hint: 'Edit',
        disabled: true,
      },
      {
        icon: <ImageIcon />,
        handler: openUploadImageDialog,
        hint: 'Photos',
      },
      {
        icon: <PdfIcon />,
        handler: openUploadPdfDialog,
        hint: 'PDFs',
      },
    ]}
    title={title}
    unitVersion={unitVersion}
    isSiteCentered
  />
);

TopBar.defaultProps = {
  title: '',
  unitVersion: '',
};

TopBar.propTypes = {
  goBack: PropTypes.func.isRequired,
  openUploadImageDialog: PropTypes.func.isRequired,
  openUploadPdfDialog: PropTypes.func.isRequired,
  title: PropTypes.string,
  unitVersion: PropTypes.string,
};

export default TopBar;
