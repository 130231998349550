/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Subheader from 'components/Subheader';
import { AddIcon, BackIcon } from 'components/Layout/Icons';

import { getSchedulerType } from 'helpers/storage';
import { actions as scheduleActions } from 'redux/schedule';

import * as S from './styled';

export const Header = ({ searchData, searchInList, unitName }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const type = getSchedulerType();

  const isNotShortcut = type !== 'shortcut';
  const isQuickTask = type === 'quickTask';

  const goBack = () => history.push(`/scheduler/${type}`);

  const addChecklistHandler = () => {
    dispatch(scheduleActions.setChecklistToEdit({}));

    dispatch(scheduleActions.setIsModalVisible(true));
  };

  return (
    <>
      <Subheader
        title={`Template: ${unitName}`}
        zIndex={5}
        top="0"
        rightContent={
          <>
            <S.RedText>Type:</S.RedText>
            &nbsp;<S.Text>{type} Checklist</S.Text>
          </>
        }
        isSiteOnLeftSide
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: goBack,
            hint: 'Back',
          },
        ]}
      />
      <Subheader
        title={<S.Checklists>Checklists</S.Checklists>}
        zIndex={4}
        rightButtons={[
          {
            icon: isNotShortcut && <AddIcon />,
            handler: isQuickTask ? undefined : addChecklistHandler,
            style: isQuickTask && { cursor: 'not-allowed' },
            hint: isQuickTask ? 'New QuickTasks are created through the TaskApp' : 'Add',
          },
        ]}
        top="0"
        isSearch
        searchData={searchData}
        searchInList={searchInList}
      />
    </>
  );
};
