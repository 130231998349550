import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ShowOnTablets } from 'components/Layout/Media';
import { ButtonIcon, ButtonFlat, ButtonRaised } from 'components/Layout/Buttons';
import { ImageIcon } from 'components/Layout/Icons';

export const CloseButton = styled(ButtonFlat).attrs({
  label: 'Close',
  primary: true,
})`
  ${ShowOnTablets}
`;

export const Footer = styled(Flex).attrs({
  justify: 'space-between',
  p: 2,
})``;

export const ListContainer = styled.div`
  padding: 20px 15px 22.5px 15px;
  height: 250px;
`;

export const UploadButton = styled(ButtonRaised).attrs({
  primary: true,
  labelStyle: { textTransform: 'none', fontWeight: 'normal' },
  style: { fontSize: '16px', borderRadius: '10px', height: '28px' },
})`
  min-width: 140px !important;
  button {
    span {
      font-size: 16px !important;
      line-height: 28px;
      padding: 5px 10px !important;
    }
  }
`;

export const Section = styled.div`
  margin: 10px 0px;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  min-height: 135px;
`;

export const FileSection = styled(Section)`
  padding: 0;
  overflow: hidden;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
`;

export const FileSectionHeader = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.mainLightBlue};
  color: ${({ theme }) => theme.primaryWhite};
  text-transform: capitalize;
  height: 40px;
`;

export const IconButton = styled(ButtonIcon).attrs({
  iconStyle: ({ theme }) => ({
    color: theme.primaryWhite,
  }),
  tooltipStyles: {
    top: '24px',
    whiteSpace: 'nowrap',
  },
})`
  padding: 0 !important;
  position: absolute !important;
  height: 40px !important;
  width: 40px !important;
  left: calc(100% - 40px);
`;

export const IconContainer = styled.span`
  position: absolute;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
`;

export const IconImage = styled(ImageIcon).attrs({})`
  margin: 8px 0 0 8px !important;
  color: ${({ theme }) => theme.primaryWhite} !important;
`;

export const LabelButton = styled.label`
  padding: 5px 15px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.lightGreenButton};
  color: ${({ theme }) => theme.greenButton};
  cursor: pointer;
  min-width: 85px;
`;

export const InputButton = styled.input`
  display: none;
`;

export const UploadImageRow = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  px: 2,
  py: 1,
})`
  overflow: hidden;
`;

export const FileName = styled.div`
  white-space: nowrap
  overflow: hidden;
  text-overflow: ellipsis;
`;
