import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';
import { Link } from 'react-router-dom';

import { PaperContainer } from 'components/Layout/Containers';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`;

const DashboardContainer = styled(Flex).attrs({
  align: 'center',
  column: true,
  justify: 'space-between',
  p: 2,
  w: [1, 0.95, 0.7, 0.65],
})`
  min-height: 0;
`;

const Header = styled(Box)`
  text-align: center;
  font-size: 30px;
  font-weight: 200;
  color: ${({ theme }) => theme.tenantNameColor};
  padding-bottom: 30px;
`;

const ColumnContainer = styled(Flex).attrs({
  column: true,
  w: [1, 0.9],
})`
  padding: 15px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  pointer-events: ${({ isDisableClick }) => (isDisableClick ? 'none' : 'pointer')};

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ModulePaper = styled(PaperContainer)`
  border-radius: 8px !important;
  background-color: ${({ color }) => color} !important;
  position: relative;
`;

const ModuleLabel = styled(Box).attrs({
  mr: 1,
})`
  flex: 1;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 30px;
  margin-left: 30px;

  @media only screen and (max-width: 330px) {
    margin-left: 15px;
  }
`;

const TasksNumberContainer = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 60px;
  height: 35px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primaryRed};
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModuleIconContainer = styled.div`
  border-radius: 8px 0 0 8px;
  height: 70px;
  width: 70px;
  background: ${({ theme, backgroundColor }) => backgroundColor || theme.secondaryYellow};
  display: flex;
  justify-content: center;
  align-items: center;

  && svg {
    color: ${({ color }) => color} !important;
    width: 50% !important;
    height: 50% !important;
  }
`;

const TotalTaskCountContainer = styled.div`
  border-radius: 10px;
  padding: 10px 16px;
  color: ${({ theme }) => theme.mainRed};
  background: ${({ theme }) => theme.requestsNumberColor};
  margin-right: 20px;
`;

const Tooltip = styled(ReactTooltip)`
  background-color: ${({ theme }) => theme.primaryWhite} !important;
  color: ${({ theme }) => theme.primaryBlack} !important;
  text-align: center;
  padding: 16px !important;
  max-width: 70px !important;

  &:after {
    display: none;
  }
`;

const PrimaryTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.primaryGrey} !important;
  color: ${({ theme }) => theme.primaryWhite} !important;
`;

export {
  TotalTaskCountContainer,
  Tooltip,
  PrimaryTooltip,
  Container,
  DashboardContainer,
  Header,
  ColumnContainer,
  LinkWrapper,
  ModulePaper,
  ModuleLabel,
  TasksNumberContainer,
  ModuleIconContainer,
};
