import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;

export const Blank = () => {};
