import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';
import IconButton from 'material-ui/IconButton';

import * as S from './styled';

class ListExchangeItem extends PureComponent {
  static propTypes = {
    getNameAndModel: PropTypes.func.isRequired,
    getCompanyAndSite: PropTypes.func.isRequired,
    getAddress: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    onItemClick: PropTypes.func.isRequired,
    onCheck: PropTypes.func.isRequired,
    checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  onCheck = (event, value, item) => {
    this.props.onCheck(item, value);
  };

  render() {
    const { item, getNameAndModel, getCompanyAndSite, getAddress, onItemClick, checked } = this.props;
    const isDemo = item.UnitType === 'Demo';
    const isPrivate = item.UnitType === 'Private';

    return (
      <S.Container>
        <S.Line isDemo={isDemo} onClick={() => onItemClick(item)}>
          <S.ListVersionBlock>{item.ListExchangeVersion}</S.ListVersionBlock>
          <S.TextBlock>
            <S.ItemText>
              <b>Name & Model: </b>&nbsp;{getNameAndModel(item)}
            </S.ItemText>
            <S.ItemText>
              <b>Vendor name: </b>&nbsp;{item.VendorName}
            </S.ItemText>
          </S.TextBlock>
          <S.TextBlock>
            <S.ItemText>
              <b>Industry: </b>&nbsp;{item.TenantIndustryName}
            </S.ItemText>
            <S.ItemText>
              <b>Company/Site: </b>&nbsp;{getCompanyAndSite(item)}
            </S.ItemText>
          </S.TextBlock>
          <S.TextBlock>
            <S.ItemText>{getAddress(item)}</S.ItemText>
          </S.TextBlock>
        </S.Line>
        <S.CheckboxBlock>
          {isDemo && (
            <IconButton tooltip="Demonstration">
              <S.Loyalty />
            </IconButton>
          )}
          {isPrivate && (
            <IconButton tooltip="Private">
              <S.Lock />
            </IconButton>
          )}
          <S.CheckBox
            fill={theme.mainRed}
            checked={checked.includes(item.OriginalId) || checked.includes(item.Id)}
            onCheck={(event, value) => this.onCheck(event, value, item)}
          />
        </S.CheckboxBlock>
      </S.Container>
    );
  }
}

export default ListExchangeItem;
