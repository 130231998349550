import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const InstructionBlock = ({ data, isHome }) => (
  <S.Instruction isHome={isHome}>
    <S.ModuleIcon color={data.color}>
      <data.icon style={S.IconStyle} />
      <S.Title title={data.title} module={data.title}>
        {data.title}
      </S.Title>
    </S.ModuleIcon>
    <S.InfoWrapper>
      <S.Purpose>
        <S.PurposeBold>Purpose:</S.PurposeBold>
        <S.Text>{data.purpose}</S.Text>
      </S.Purpose>
      <S.Options>
        {data.options.map(option => (
          <S.Option key={option.title}>
            <option.icon style={S.IconOptionStyle} />
            <S.Text>{option.title}</S.Text>
          </S.Option>
        ))}
      </S.Options>
      <S.SpecificIcon tooltip={data.tooltip}>
        <data.tooltipIcon />
      </S.SpecificIcon>
    </S.InfoWrapper>
  </S.Instruction>
);

InstructionBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    purpose: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.func.isRequired,
      }),
    ).isRequired,
    tooltipIcon: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  isHome: PropTypes.bool,
};

InstructionBlock.defaultProps = {
  isHome: true,
};

export default InstructionBlock;
