import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as distributionActions } from 'redux/distributions';

import Loader from 'components/Loader';
import ConfirmDialog from 'components/Dialogs/Confirm';
import Subheader from 'components/Subheader';
import Toggle from 'components/Toggle';
import { BackIcon } from 'components/Layout/Icons';
import TableList from 'components/TableList';
import BaseDialog from 'components/Dialogs/Base';
import DistributionForm from './DistributionForm';

import { firstLetterUpperCase, generateDistributionName } from 'modules/parts/pages/helpers';
import { partsToggle } from 'configs/toggles';
import { formatDate, formatTime } from './formatters';

import { tableHeads, tableColumns } from './constants';

import theme from 'theme';
import * as S from './styled';

class DistributionList extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      getDistributionsRequest: PropTypes.func.isRequired,
      deleteDistributionsRequest: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
      getDistributionDetailsRequest: PropTypes.func.isRequired,
      getDistributionsGroupsRequest: PropTypes.func.isRequired,
      getDistributionGroupDetailsRequest: PropTypes.func.isRequired,
      deleteDistributionsGroupsRequest: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    distributionsList: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          Id: PropTypes.string.isRequired,
          Name: PropTypes.string.isRequired,
          PartId: PropTypes.string.isRequired,
          dateExecuted: PropTypes.string.isRequired,
          time: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      PropTypes.array.isRequired,
    ]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    details: PropTypes.shape().isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  state = {
    searchData: '',
    mode: partsToggle.find(el => el.key === this.props.match.params.type),
    checked: new Array(this.props.distributionsList.length).fill(false),
    checkAll: false,
    isChecked: false,
    warningModalOpened: false,
    distributionDialogOpen: false,
    currentDistributionId: '',
    distributionItemConfirmModalOpened: false,
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getDistributionsRequest();
    actions.getDistributionsGroupsRequest();
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { type },
      },
    } = this.props;
    const {
      match: {
        params: { type: prevType },
      },
    } = prevProps;

    if (prevProps.distributionsList.length !== this.props.distributionsList.length) {
      this.setState({ checked: new Array(this.props.distributionsList.length).fill(false) });
    }
    if (prevType !== type) {
      this.setState({ isChecked: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;

    if (nextProps.error) {
      actions.openErrorDialog(nextProps.error, 'Error');
    }
  }

  handleOnChange = position => () => {
    const updatedCheckedState = this.state.checked.map((item, index) => (index === position ? !item : item));

    this.setState({
      checked: updatedCheckedState,
      isChecked: updatedCheckedState.filter(isChecked => isChecked).length > 0,
    });
  };

  openWarningModal = () => {
    this.setState({ warningModalOpened: true });
  };

  closeWarningModal = () => {
    this.setState({ warningModalOpened: false });
  };

  handleToggle = mode => {
    this.setState({ mode }, () => {
      this.props.history.push(`/settings/distribution/${mode.key}`);
    });
    this.setState({
      checked: this.state.checked.map(() => false),
      checkAll: false,
      isChecked: false,
    });
  };

  renderTitle = () => (
    <div>
      <h2>Distribution</h2>
      <Toggle config={partsToggle.map(item => item)} selected={this.state.mode.key} handler={this.handleToggle} />
    </div>
  );

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  backToSettings = () => {
    this.props.history.push('/settings');
  };

  filterList = item =>
    tableColumns[this.state.mode.key]
      .map(({ field }) => field)
      .some(
        field =>
          item[field] &&
          typeof item[field] === 'string' &&
          item[field].toLowerCase().includes(this.state.searchData.toLowerCase()),
      );

  selectAll = () => {
    const { checkAll, checked } = this.state;

    if (checkAll) {
      this.setState({
        checked: checked.map(() => false),
        checkAll: false,
        isChecked: false,
      });

      return;
    }

    this.setState({
      checked: checked.map(() => true),
      checkAll: true,
      isChecked: true,
    });
  };

  deleteDistribution = ({ deleteCascade, distributionListIds }) => {
    const { actions } = this.props;
    const { mode } = this.state;

    if (mode.key === partsToggle[0].key) {
      actions.deleteDistributionsRequest({ distributionListIds, deleteCascade });
    } else if (mode.key === partsToggle[1].key) {
      actions.deleteDistributionsGroupsRequest({ distributionListIds, deleteCascade });
    }
  };

  submitWarningModal = () => {
    const { distributionsList } = this.props;
    const { checked } = this.state;

    const ids = distributionsList.filter((_, index) => checked[index]).map(el => el.Id);

    if (ids.length < 1) return;

    this.deleteDistribution({ distributionListIds: ids, deleteCascade: false });
    this.closeWarningModal();
  };

  openDistributionIemConfirmModal = () => {
    this.setState({ distributionItemConfirmModalOpened: true });
  };

  closeDistributionIemConfirmModal = () => {
    this.setState({ distributionItemConfirmModalOpened: false });
  };

  deleteDistributionWithLinks = () => {
    const { currentDistributionId } = this.state;

    this.deleteDistribution({ distributionListIds: [currentDistributionId], deleteCascade: true });
    this.closeDistributionIemConfirmModal();
    this.closeDistributionDialog();
  };

  actions = (cancelHandler, deleteHandler) => [
    <S.CancelButton onClick={cancelHandler} />,
    <S.DeleteButton onClick={deleteHandler} label="Delete" />,
  ];

  detailsAction = [
    <S.DeleteButton
      onClick={this.openDistributionIemConfirmModal}
      label="Delete and Reverse this distribution (Remove Links with items)"
      fullWidth
      invert={1}
    />,
  ];

  renderTextForWarningModal = (title, subTitle) => (
    <S.ModalText>
      <p>
        <strong>{title}</strong>
      </p>
      {subTitle}
    </S.ModalText>
  );

  openDistributionDialog = ({ Id }) =>
    this.setState({
      distributionDialogOpen: true,
      currentDistributionId: Id,
    });

  closeDistributionDialog = () => this.setState({ distributionDialogOpen: false });

  viewDetails = ({ PartID, Id, PartGroupId }) => {
    const { actions } = this.props;
    const { mode } = this.state;

    if (mode.key === partsToggle[0].key) {
      actions.getDistributionDetailsRequest({ PartID, Id });
    } else if (mode.key === partsToggle[1].key) {
      actions.getDistributionGroupDetailsRequest({ PartGroupId, Id });
    }

    this.openDistributionDialog({ Id });
  };

  render() {
    const {
      searchData,
      mode,
      warningModalOpened,
      isChecked,
      distributionDialogOpen,
      distributionItemConfirmModalOpened,
    } = this.state;
    const {
      distributionsList,
      isLoading,
      details,
      match: {
        params: { type },
      },
    } = this.props;
    const isGroups = mode.key === partsToggle[1].key;

    return (
      <S.Page>
        <Loader isVisiible={isLoading} />
        <Subheader
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backToSettings,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              icon: (
                <S.DeleteIcon
                  color={isChecked && theme.primaryRed}
                  onClick={this.openWarningModal}
                  disabled={!isChecked ? 1 : 0}
                />
              ),
              disabled: !isChecked,
              hint: isChecked ? 'Delete' : '',
            },
          ]}
          title={this.renderTitle()}
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
        />
        <S.TableContainer mode={mode.key}>
          <TableList
            tableHeads={tableHeads[mode.key]}
            list={distributionsList.filter(this.filterList)}
            tableColumns={tableColumns[mode.key]}
            handlers={{
              handleOnChange: this.handleOnChange,
              selectAll: this.selectAll,
              viewDetails: this.viewDetails,
            }}
            checked={this.state.checked}
            checkedAll={this.state.checkAll}
          />
        </S.TableContainer>
        <ConfirmDialog
          open={warningModalOpened}
          title="Warning"
          text={this.renderTextForWarningModal(
            <span>
              {isGroups ? 'Groups' : 'Parts'} can ONLY be removed individually after this.
              <br />
              This can not be undone!
            </span>,
            'Are you sure you want to delete?',
          )}
          confirmText="Delete"
          cancelText="Cancel"
          onApprove={this.submitWarningModal}
          onReject={this.closeWarningModal}
        />
        <BaseDialog
          open={distributionDialogOpen}
          onRequestClose={this.closeDistributionDialog}
          title={`${firstLetterUpperCase(type)} Distribution`}
          type={type}
          actions={this.detailsAction}
        >
          <DistributionForm {...details} />
        </BaseDialog>
        <ConfirmDialog
          open={distributionItemConfirmModalOpened}
          title="Warning"
          text={this.renderTextForWarningModal(
            'Are you sure?',
            <>
              <div>
                <strong>This distribution will be completely deleted.</strong>
              </div>
              <div>
                <strong>This can not be undone!</strong>
              </div>
            </>,
          )}
          confirmText="Delete"
          cancelText="Cancel"
          onApprove={this.deleteDistributionWithLinks}
          onReject={this.closeDistributionIemConfirmModal}
        />
      </S.Page>
    );
  }
}

const mapStateToProps = ({ distributions: { details, error, list, isLoading } }, ownProps) => {
  const {
    match: {
      params: { type },
    },
  } = ownProps;

  const formatedList =
    list[type].forEach((el, index) => {
      list[type][index] = {
        ...el,
        dateExecuted: formatDate(el.LastExecutionDate),
        time: formatTime(el.LastExecutionDate),
        name: generateDistributionName(el.CreatedOn),
      };
    }) || list[type];

  return {
    error,
    distributionsList: formatedList,
    isLoading,
    details,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openErrorDialog,
      ...distributionActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributionList);
