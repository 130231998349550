import React from 'react';
import PropTypes from 'prop-types';
import { USER_TYPES } from '../../../../configs/enums';
import { ButtonIcon } from 'components/Layout/Buttons';
import { CopyIcon, ExportQRIcon, UploadFile } from 'components/Layout/Icons';

const RightControls = ({
  unit,
  userType,
  tenantIsOEM,
  allowSubmitToListExchange,
  openPdfDialog,
  openDuplicateDialog,
  onUploadButtonClick,
}) => {
  const isSystemAdmin = userType === USER_TYPES.SystemAdministrator;
  const isOEMRole = userType === USER_TYPES.OEMManager || userType === USER_TYPES.OEMAdministrator;
  const tenantAndRoleOEM = isOEMRole && tenantIsOEM;
  const showUploadButton = (isSystemAdmin || tenantAndRoleOEM) && allowSubmitToListExchange;

  return (
    <div>
      {showUploadButton && (
        <ButtonIcon onClick={onUploadButtonClick} tooltip="Upload to List Exchange" tooltipStyles={{ marginTop: -14 }}>
          <UploadFile />
        </ButtonIcon>
      )}
      {openPdfDialog && (
        <ButtonIcon
          onClick={e => {
            e.stopPropagation();
            openPdfDialog(unit);
          }}
          tooltip="QR pdf's"
        >
          <ExportQRIcon />
        </ButtonIcon>
      )}
      {openDuplicateDialog && (
        <ButtonIcon
          onClick={e => {
            e.stopPropagation();
            openDuplicateDialog(unit);
          }}
          tooltip="Duplicate"
        >
          <CopyIcon />
        </ButtonIcon>
      )}
    </div>
  );
};

RightControls.defaultProps = {
  openDragItemsListDialog: () => {},
  onUploadButtonClick: () => {},
  openPdfDialog: null,
  openDuplicateDialog: null,
};

RightControls.propTypes = {
  unit: PropTypes.object.isRequired,
  userType: PropTypes.number.isRequired,
  tenantIsOEM: PropTypes.bool.isRequired,
  allowSubmitToListExchange: PropTypes.bool.isRequired,
  onUploadButtonClick: PropTypes.func,
  openPdfDialog: PropTypes.func,
  openDuplicateDialog: PropTypes.func,
};

export default RightControls;
