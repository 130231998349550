import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as tutorialActions } from 'redux/tutorials';
import { INSTRUCTIONS_MODULES } from 'configs/routes';
import InstructionBlock from './InstructionBlock';
import SelectInstructionModule from './SelectInstructionModule';
import { getPageNameByModule } from 'helpers';

import * as S from './styled';

class Instructions extends React.PureComponent {
  componentDidMount() {
    const {
      module,
      actions: { fetchVideosCountRequest },
    } = this.props;
    const pageName = getPageNameByModule(module);

    if (pageName) {
      fetchVideosCountRequest(pageName);
    }
  }

  componentWillUnmount() {
    const {
      actions: { resetVideosCount },
    } = this.props;

    resetVideosCount();
  }

  render() {
    const { module, userType, onNext, videosCount, isLoading } = this.props;

    return (
      <S.Container>
        <S.Content>
          <S.HomeInstructionsWrapper>
            {module === 'home' && (
              <>
                <S.WelcomeMessage>Welcome</S.WelcomeMessage>
                <S.IntroText>Main components of incheq and summary of features</S.IntroText>
              </>
            )}
            <S.InstructionsContainer>
              {module === 'home'
                ? Object.values(INSTRUCTIONS_MODULES[userType]).map(item => (
                    <InstructionBlock key={item.title} data={item} />
                  ))
                : SelectInstructionModule(userType, module, onNext, videosCount, isLoading)}
            </S.InstructionsContainer>
          </S.HomeInstructionsWrapper>
        </S.Content>
      </S.Container>
    );
  }
}

Instructions.propTypes = {
  module: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userType: PropTypes.number.isRequired,
  videosCount: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    fetchVideosCountRequest: PropTypes.func.isRequired,
    resetVideosCount: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({
  auth: {
    user: { userType },
  },
  tutorials: { videosCount, loading },
}) => ({
  userType,
  videosCount,
  isLoading: loading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...tutorialActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Instructions);
