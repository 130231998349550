import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_GATEWAYS_REQUEST: undefined,
  GET_GATEWAYS_SUCCESS: undefined,
  GET_GATEWAYS_FAILURE: undefined,

  GET_GATEWAYS_BY_HUB_REQUEST: undefined,

  GET_SERVER_DATA_REQUEST: undefined,
  GET_SERVER_DATA_SUCCESS: undefined,
  GET_SERVER_DATA_FAILURE: undefined,

  GET_IOT_HUBS_REQUEST: undefined,
  GET_IOT_HUBS_SUCCESS: undefined,
  GET_IOT_HUBS_FAILURE: undefined,

  GET_REGIONS_REQUEST: undefined,
  GET_REGIONS_SUCCESS: undefined,
  GET_REGIONS_FAILURE: undefined,

  ADD_SERVER_REQUEST: undefined,
  ADD_SERVER_SUCCESS: undefined,
  ADD_SERVER_FAILURE: undefined,

  ADD_HUB_REQUEST: undefined,
  ADD_HUB_SUCCESS: undefined,
  ADD_HUB_FAILURE: undefined,

  DELETE_SERVER_REQUEST: undefined,
  DELETE_SERVER_SUCCESS: undefined,
  DELETE_SERVER_FAILURE: undefined,

  DELETE_HUB_REQUEST: undefined,
  DELETE_HUB_SUCCESS: undefined,
  DELETE_HUB_FAILURE: undefined,

  CREATE_GATEWAY_REQUEST: undefined,
  CREATE_GATEWAY_SUCCESS: undefined,
  CREATE_GATEWAY_FAILURE: undefined,

  GET_GATEWAY_REQUEST: undefined,
  GET_GATEWAY_SUCCESS: undefined,
  GET_GATEWAY_FAILURE: undefined,

  EDIT_GATEWAY_REQUEST: undefined,
  EDIT_GATEWAY_SUCCESS: undefined,
  EDIT_GATEWAY_FAILURE: undefined,

  DELETE_GATEWAY_REQUEST: undefined,
  DELETE_GATEWAY_SUCCESS: undefined,
  DELETE_GATEWAY_FAILURE: undefined,

  GET_RESOURCE_GROUPS_REQUEST: undefined,
  GET_RESOURCE_GROUPS_SUCCESS: undefined,
  GET_RESOURCE_GROUPS_FAILURE: undefined,

  DELETE_ALL_HUBS_FROM_TENANT_REQUEST: undefined,
  DELETE_ALL_HUBS_FROM_TENANT_SUCCESS: undefined,
  DELETE_ALL_HUBS_FROM_TENANT_FAILURE: undefined,

  GET_TENANT_HUBS_LIST_REQUEST: undefined,
  GET_TENANT_HUBS_LIST_SUCCESS: undefined,
  GET_TENANT_HUBS_LIST_FAILURE: undefined,

  DELETE_ALL_HUBS_FROM_TENANT_SITE_REQUEST: undefined,
  DELETE_ALL_HUBS_FROM_TENANT_SITE_SUCCESS: undefined,
  DELETE_ALL_HUBS_FROM_TENANT_SITE_FAILURE: undefined,

  DELETE_HUB_FROM_TENANT_SITE_REQUEST: undefined,
  DELETE_HUB_FROM_TENANT_SITE_SUCCESS: undefined,
  DELETE_HUB_FROM_TENANT_SITE_FAILURE: undefined,

  GET_NEW_RESOURCE_GROUP_NAME_BY_TENANT_REQUEST: undefined,
  GET_NEW_HUB_NAME_BY_SITE_REQUEST: undefined,
  GET_NEW_GATEWAY_NAME_IN_HUB_REQUEST: undefined,
  GET_NEW_RESOURCE_NAME_SUCCESS: undefined,
  GET_NEW_RESOURCE_NAME_FAILURE: undefined,

  GET_DEVICE_CONNECTION_STRING_REQUEST: undefined,
  GET_DEVICE_CONNECTION_STRING_SUCCESS: undefined,
  GET_DEVICE_CONNECTION_STRING_FAILURE: undefined,

  GET_RESOURCE_GROUP_INFO_REQUEST: undefined,
  GET_RESOURCE_GROUP_INFO_SUCCESS: undefined,
  GET_RESOURCE_GROUP_INFO_FAILURE: undefined,

  GET_HUB_TIER_INFO_REQUEST: undefined,
  GET_HUB_TIER_INFO_SUCCESS: undefined,
  GET_HUB_TIER_INFO_FAILURE: undefined,

  RESET_FIELD: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getGatewaysRequest, handlers.getGatewaysRequest],
    [actions.getGatewaysSuccess, handlers.getGatewaysSuccess],
    [actions.getGatewaysFailure, handlers.getGatewaysFailure],

    [actions.getGatewaysByHubRequest, handlers.getGatewaysByHubRequest],

    [actions.getServerDataRequest, handlers.getServerDataRequest],
    [actions.getServerDataSuccess, handlers.getServerDataSuccess],
    [actions.getServerDataFailure, handlers.getServerDataFailure],

    [actions.getIotHubsRequest, handlers.getIotHubsRequest],
    [actions.getIotHubsSuccess, handlers.getIotHubsSuccess],
    [actions.getIotHubsFailure, handlers.getIotHubsFailure],

    [actions.getRegionsRequest, handlers.getRegionsRequest],
    [actions.getRegionsSuccess, handlers.getRegionsSuccess],
    [actions.getRegionsFailure, handlers.getRegionsFailure],

    [actions.addServerRequest, handlers.addServerRequest],
    [actions.addServerSuccess, handlers.addServerSuccess],
    [actions.addServerFailure, handlers.addServerFailure],

    [actions.addHubRequest, handlers.addHubRequest],
    [actions.addHubSuccess, handlers.addHubSuccess],
    [actions.addHubFailure, handlers.addHubFailure],

    [actions.deleteServerRequest, handlers.deleteServerRequest],
    [actions.deleteServerSuccess, handlers.deleteServerSuccess],
    [actions.deleteServerFailure, handlers.deleteServerFailure],

    [actions.deleteHubRequest, handlers.deleteHubRequest],
    [actions.deleteHubSuccess, handlers.deleteHubSuccess],
    [actions.deleteHubFailure, handlers.deleteHubFailure],

    [actions.createGatewayRequest, handlers.createGatewayRequest],
    [actions.createGatewaySuccess, handlers.createGatewaySuccess],
    [actions.createGatewayFailure, handlers.createGatewayFailure],

    [actions.getGatewayRequest, handlers.getGatewayRequest],
    [actions.getGatewaySuccess, handlers.getGatewaySuccess],
    [actions.getGatewayFailure, handlers.getGatewayFailure],

    [actions.editGatewayRequest, handlers.editGatewayRequest],
    [actions.editGatewaySuccess, handlers.editGatewaySuccess],
    [actions.editGatewayFailure, handlers.editGatewayFailure],

    [actions.deleteGatewayRequest, handlers.deleteGatewayRequest],
    [actions.deleteGatewaySuccess, handlers.deleteGatewaySuccess],
    [actions.deleteGatewayFailure, handlers.deleteGatewayFailure],

    [actions.getResourceGroupsRequest, handlers.getResourceGroupsRequest],
    [actions.getResourceGroupsSuccess, handlers.getResourceGroupsSuccess],
    [actions.getResourceGroupsFailure, handlers.getResourceGroupsFailure],

    [actions.deleteAllHubsFromTenantRequest, handlers.deleteAllHubsFromTenantRequest],
    [actions.deleteAllHubsFromTenantSuccess, handlers.deleteAllHubsFromTenantSuccess],
    [actions.deleteAllHubsFromTenantFailure, handlers.deleteAllHubsFromTenantFailure],

    [actions.getTenantHubsListRequest, handlers.getTenantHubsListRequest],
    [actions.getTenantHubsListSuccess, handlers.getTenantHubsListSuccess],
    [actions.getTenantHubsListFailure, handlers.getTenantHubsListFailure],

    [actions.deleteAllHubsFromTenantSiteRequest, handlers.deleteAllHubsFromTenantSiteRequest],
    [actions.deleteAllHubsFromTenantSiteSuccess, handlers.deleteAllHubsFromTenantSiteSuccess],
    [actions.deleteAllHubsFromTenantSiteFailure, handlers.deleteAllHubsFromTenantSiteFailure],

    [actions.deleteHubFromTenantSiteRequest, handlers.deleteHubFromTenantSiteRequest],
    [actions.deleteHubFromTenantSiteSuccess, handlers.deleteHubFromTenantSiteSuccess],
    [actions.deleteHubFromTenantSiteFailure, handlers.deleteHubFromTenantSiteFailure],

    [actions.getNewResourceGroupNameByTenantRequest, handlers.getNewResourceGroupNameByTenantRequest],
    [actions.getNewHubNameBySiteRequest, handlers.getNewHubNameBySiteRequest],
    [actions.getNewGatewayNameInHubRequest, handlers.getNewGatewayNameInHubRequest],
    [actions.getNewResourceNameSuccess, handlers.getNewResourceNameSuccess],
    [actions.getNewResourceNameFailure, handlers.getNewResourceNameFailure],

    [actions.getDeviceConnectionStringRequest, handlers.getDeviceConnectionStringRequest],
    [actions.getDeviceConnectionStringSuccess, handlers.getDeviceConnectionStringSuccess],
    [actions.getDeviceConnectionStringFailure, handlers.getDeviceConnectionStringFailure],

    [actions.getResourceGroupInfoRequest, handlers.getResourceGroupInfoRequest],
    [actions.getResourceGroupInfoSuccess, handlers.getResourceGroupInfoSuccess],
    [actions.getResourceGroupInfoFailure, handlers.getResourceGroupInfoFailure],

    [actions.getHubTierInfoRequest, handlers.getHubTierInfoRequest],
    [actions.getHubTierInfoSuccess, handlers.getHubTierInfoSuccess],
    [actions.getHubTierInfoFailure, handlers.getHubTierInfoFailure],

    [actions.resetField, handlers.resetField],
  ]),
  initialState,
);

export default reducer;
