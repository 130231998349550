export const transformSystemLinksList = (systemLinks, LinkIntroText) =>
  systemLinks.reduce((obj, link, index) => {
    obj.LinkIntroText = LinkIntroText;
    obj[`${index}IsTaskSpecialist`] = link.IsTaskSpecialist;
    obj[`${index}IsAdminMngr`] = link.IsAdminMngr;
    obj[`${index}IsOEMMngr`] = link.IsOEMMngr;
    obj[`${index}Title`] = link.Title;
    obj[`${index}Subtext`] = link.Subtext;
    obj[`${index}Link`] = link.Link;
    return obj;
  }, {});

export const blank = [];
