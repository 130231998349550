import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ButtonFlat, ButtonRaised } from 'components/Layout/Buttons';

export const Container = styled(Flex).attrs({
  justify: 'space-between',
  p: 1,
})`
  background-color: ${({ theme }) => theme.lightGrey};
  box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 6px, rgba(0, 0, 0, 0.12) 0px -1px 4px;
  min-height: 52px;
  position: relative;
  z-index: 10;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const Centered = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% - 15px);
`;

export const Button = styled(ButtonFlat).attrs({
  primary: true,
})``;

export const ReleaseButton = styled(ButtonRaised).attrs({
  primary: true,
  disabledBackgroundColor: ({ theme }) => theme.disabledColor,
})`
  overflow: visible !important;
`;

export const Label = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  pointer-events: none;
  background: ${({ theme }) => theme.primaryRed};
  border-color: ${({ theme }) => theme.darkRed};
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0.1em 0.1em;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 14px;
  height: 20px;
  min-width: 20px;
  padding: 0 3px;
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 99;
`;
