export const tableConfigs = [
  { field: 'TasksAndInstructions' },
  { field: 'Name' },
  { field: 'Min' },
  { field: 'Max' },
  { field: 'Notes' },
  { field: 'Duplicate' },
];

export const tableHeads = [
  { title: 'Tasks' },
  { title: 'Instructions' },
  { title: 'Min' },
  { title: 'Max' },
  { title: 'Notes' },
  { title: '' },
];
