import moment from 'moment';

export const formatDate = dateString => moment(dateString).format('MMM-DD-YYYY');

export const getDateValue = date => {
  if (!date) return {};
  if (typeof date === 'string') return new Date(date);
  return date;
};

export const createUniqKey = pre => `${pre}_${new Date().getTime() + Math.random()}`;
