import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { isIE11 } from 'helpers';
import { SensorType } from 'configs/propTypes';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

const SensorsList = ({ deleteSensor, list, openLevelSettingsDialog }) => (
  <>
    {list.length ? (
      <S.Container>
        {Object.values(list).map(sensor => (
          <S.Sensor key={sensor.Id}>
            <S.Type>
              {sensor.SensorType.Type}
              <ButtonIcon onClick={() => deleteSensor(sensor)}>
                <S.IconDelete />
              </ButtonIcon>
            </S.Type>
            <S.Data>
              <S.InpurContainer>
                <Field
                  component={TextFieldAdapter}
                  name={`${sensor.Id}SerialNumber`}
                  floatingLabelText="Serial Number"
                />
                <S.SensorType>Reported Type: {sensor.SensorTypeVariable}</S.SensorType>
              </S.InpurContainer>
              <S.StatusAndLevels hasLevels={sensor.LevelsCount}>
                <S.StatusBlock>
                  Status
                  <ButtonIcon
                    tooltip={sensor.Status ? '' : "The data isn't in the rage of Levels Settings"}
                    tooltipPosition="top-left"
                    tooltipStyles={{
                      width: `${isIE11 ? 'auto' : '210px'}`,
                    }}
                  >
                    <S.IconWarning status={sensor.Status} />
                  </ButtonIcon>
                </S.StatusBlock>
                <S.LevelsBlock>
                  Levels
                  <S.LevelsCount>{sensor.LevelsCount}</S.LevelsCount>
                  {sensor.LevelsCount ? (
                    <ButtonIcon onClick={() => openLevelSettingsDialog(sensor)}>
                      <S.RightIcon />
                    </ButtonIcon>
                  ) : null}
                </S.LevelsBlock>
              </S.StatusAndLevels>
            </S.Data>
          </S.Sensor>
        ))}
      </S.Container>
    ) : (
      <S.EmptyListMessage>It would be great if some sensors were added here.</S.EmptyListMessage>
    )}
  </>
);

SensorsList.propTypes = {
  deleteSensor: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(SensorType).isRequired,
  openLevelSettingsDialog: PropTypes.func.isRequired,
};

export default SensorsList;
