/* eslint-disable no-else-return */
import moment from 'moment';

export const generateStartDate = values => {
  const date = values.StartDate;
  const modifedDate = new Date(date.setHours(0, 0, 0, 0));
  if (values.StartTime) {
    const time = values.StartTime;
    return moment(modifedDate)
      .add(time.getHours(), 'h')
      .add(time.getMinutes(), 'm')
      .add(time.getSeconds(), 's')
      .add(time.getMilliseconds(), 'ms')
      .toISOString();
  } else {
    return moment(modifedDate).toISOString();
  }
};

export const generateEndDate = values => {
  const date = values.EndDate;
  const modifedDate = new Date(date.setHours(23, 59, 59, 999));
  if (values.EndTime) {
    const timeReset = new Date(modifedDate.setHours(0, 0, 0, 0));
    const time = values.EndTime;
    return moment(timeReset)
      .add(time.getHours(), 'h')
      .add(time.getMinutes(), 'm')
      .add(time.getSeconds(), 's')
      .add(time.getMilliseconds(), 'ms')
      .toISOString();
  } else {
    return moment(modifedDate).toISOString();
  }
};
