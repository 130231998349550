import styled from 'styled-components';

import { ToolbarContainer } from 'components/Layout/Containers';
import { ForwardIcon } from 'components/Layout/Icons';

export const ConfirmationText = styled.div`
  color: ${({ theme }) => theme.primaryDark};
  font-size: 18px;
`;

export const TableContainer = styled.div`
  & td:nth-child(-n + 1) {
    border-right: 1px solid ${({ theme }) => theme.lightBlue};
  }

  & td:nth-child(1) {
    width: 30%;
    padding-left: 10px;
    background-color: ${({ theme }) => theme.lavenderBlush};
  }

  & td:nth-child(2) {
    width: 60%;
  }

  & td:nth-child(3) {
    min-width: 120px;
  }
`;

export const IconForward = styled(ForwardIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const RedText = styled.span`
  color: ${({ theme }) => theme.primaryScheduler};
  font-weight: 700;
`;

export const Toolbar = styled(ToolbarContainer)``;
