/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';

import { GLOBAL_MODULES, ROW_MODULES, ACTIVATOR_MODULES_DATA } from 'configs/routes';
import { USER_TYPES } from 'configs/enums';

import * as S from './styled';

const RowModules = ({ userType, totalCount }) => {
  const isAdmin = userType === USER_TYPES.SystemAdministrator;
  const hasPermissionToShowActivator =
    userType === USER_TYPES.SystemController ||
    userType === USER_TYPES.Manager ||
    userType === USER_TYPES.OEMAdministrator ||
    userType === USER_TYPES.OEMManager;

  return (
    <>
      {hasPermissionToShowActivator && (
        <S.Container type="activator">
          <S.LinkWrapper to={ACTIVATOR_MODULES_DATA.path}>
            <S.ModulePaper color={ACTIVATOR_MODULES_DATA.color}>
              <ACTIVATOR_MODULES_DATA.icon style={S.iconStyle} />
              <S.ActivatorLabel type="activator">{ACTIVATOR_MODULES_DATA.name}</S.ActivatorLabel>
            </S.ModulePaper>
          </S.LinkWrapper>
        </S.Container>
      )}
      {Object.values(ROW_MODULES[userType]).length || userType === USER_TYPES.DashboardUser ? (
        <S.Container>
          <S.LinksContainer>
            {userType === USER_TYPES.SystemAdministrator && (
              <S.LinkWrapper to={ACTIVATOR_MODULES_DATA.path} isAdmin={isAdmin}>
                <S.ModulePaper color={ACTIVATOR_MODULES_DATA.color}>
                  <ACTIVATOR_MODULES_DATA.icon style={S.iconStyle} />
                  <S.ActivatorLabel>{ACTIVATOR_MODULES_DATA.name}</S.ActivatorLabel>
                </S.ModulePaper>
              </S.LinkWrapper>
            )}
            {Object.values(GLOBAL_MODULES[userType]).map(item => (
              <S.HealthLink key={item.name} to={item.path} isAdmin={isAdmin}>
                <S.HealthModule color={item.color} isAdmin={isAdmin}>
                  <S.HealthLogoContainer>
                    <item.icon style={S.iconStyle} />
                    <S.HealthLabel textColor={item.textColor}>{item.name}</S.HealthLabel>
                  </S.HealthLogoContainer>
                  <S.HealthAlertsNumber>{totalCount}</S.HealthAlertsNumber>
                </S.HealthModule>
              </S.HealthLink>
            ))}
          </S.LinksContainer>
          <S.ModulesForUser>
            {Object.values(ROW_MODULES[userType]).map(item => (
              <S.CicleLinkWrapper key={item.name} to={item.path}>
                <S.CicleModulePaper color={item.color}>
                  <item.icon style={S.iconStyle} />
                </S.CicleModulePaper>
                <S.ModuleLabel>{item.name}</S.ModuleLabel>
              </S.CicleLinkWrapper>
            ))}
          </S.ModulesForUser>
        </S.Container>
      ) : null}
    </>
  );
};

RowModules.propTypes = {
  userType: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default RowModules;
