import { call, put, takeEvery } from 'redux-saga/effects';

import {
  duplicateUnitRequest,
  getListUnitsWithItems,
  getTenantToList,
  getUnitsList,
  getUnitsWithItems,
  getUpdateItems,
  postUnit,
  getUnitAssetCategoriesList,
  getUnitAssetTypesList,
} from 'http/units';
import { getSelectedSite } from 'helpers/storage';
import { formatServerError } from 'helpers';

import { openErrorDialog } from '../errorHandler';
import { actions as unitActions } from 'redux/unit';
import { actions } from './index';

export function* fetchUnitsList({ payload: { includeChecklistData, includeHidden, includeTaskData, filter } }) {
  try {
    const { data } = yield call(getUnitsList, includeHidden, includeChecklistData, includeTaskData, filter);

    yield put(actions.unitsListSuccess(data.root));
  } catch (error) {
    yield put(actions.unitsListFailure(error));
  }
}

function* addUnit({ payload }) {
  try {
    const { data } = yield call(postUnit, payload);
    yield* fetchUnitsList({ payload: { filter: { SiteID: getSelectedSite() } } });
    yield put(actions.addUnitSuccess(data.entity));
  } catch (error) {
    yield put(actions.addUnitFailure({ error, message: 'An error occurred while saving the Unit.' }));
  }
}

function* duplicateUnit({ payload: { Id, NewName } }) {
  try {
    const encodedName = encodeURIComponent(NewName);
    yield call(duplicateUnitRequest, Id, encodedName);
    yield* fetchUnitsList({ payload: { filter: { SiteID: getSelectedSite() } } });
  } catch (error) {
    yield put(
      actions.duplicateUnitFailure({
        error,
        message: formatServerError(error, 'An error occurred while duplicating the Unit.'),
      }),
    );
  }
}

function* fetchTenantToList({ payload }) {
  try {
    yield call(getTenantToList, payload);
  } catch (error) {
    yield put(actions.importTenantToListFailure(error.response.data));
  }
}

function* fetchUnitsWithItems({ payload }) {
  try {
    const { data } = yield call(getUnitsWithItems, payload);
    yield put(actions.getUnitsWithItemsSuccess(data.entity));
  } catch (error) {
    yield put(actions.getUnitsWithItemsFailure(error));
  }
}

function* fetchListUnitsWithItems({ payload }) {
  try {
    const { data } = yield call(getListUnitsWithItems, payload);
    yield put(actions.getListUnitsWithItemsSuccess(data.root));
  } catch (error) {
    yield put(actions.getListUnitsWithItemsFailure(error));
  }
}

function* fetchUpdateItems({ payload: { items, unitId } }) {
  try {
    const siteId = yield call(getSelectedSite);
    yield call(getUpdateItems, { siteId, items });

    yield put(unitActions.fetchUnitItemsRequest({ unitId }));
  } catch (error) {
    yield put(actions.updateItemsListFailure(error));
  }
}

function* fetchAssetCategoriesList() {
  try {
    const { data } = yield call(getUnitAssetCategoriesList);

    yield put(actions.fetchAssetCategoriesListSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
  }
}

function* fetchAssetTypesList({ payload }) {
  try {
    const { data } = yield call(getUnitAssetTypesList, { categoryID: payload });

    yield put(actions.fetchAssetTypesListSuccess(data.root));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
  }
}

const unitsSagas = [
  takeEvery(actions.addUnitRequest, addUnit),
  takeEvery(actions.duplicateUnitRequest, duplicateUnit),
  takeEvery(actions.unitsListRequest, fetchUnitsList),
  takeEvery(actions.importTenantToListRequest, fetchTenantToList),
  takeEvery(actions.getUnitsWithItemsRequest, fetchUnitsWithItems),
  takeEvery(actions.getListUnitsWithItemsRequest, fetchListUnitsWithItems),
  takeEvery(actions.updateItemsListRequest, fetchUpdateItems),
  takeEvery(actions.fetchAssetCategoriesListRequest, fetchAssetCategoriesList),
  takeEvery(actions.fetchAssetTypesListRequest, fetchAssetTypesList),
];

export default unitsSagas;
