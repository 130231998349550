import styled from 'styled-components';

import { DeleteIcon } from 'components/Layout/Icons';

export const IconDelete = styled(DeleteIcon)`
  color: ${({ theme }) => theme.mainRed} !important;
`;

export const Container = styled.div`
  font-size: 14px;
`;

export const ListItemContainer = styled.div<{ index: number }>`
  z-index: ${({ index }) => index} !important;
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const ListItem = styled.li`
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 35px;

  & > div:last-child {
    justify-content: flex-end;
  }
`;

export const ItemValue = styled.div`
  color: ${({ theme }) => theme.primaryDark};
  display: flex;
  align-items: center;
  width: 33%;
  word-break: break-word;
`;
