import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem } from 'components/Layout/Section';
import { ButtonIcon } from 'components/Layout/Buttons';
import { GateWayIcon, SensorsIcon } from 'components/Layout/Icons';

export const IoT = () => (
  <ListItem
    zIndex={7}
    key="IoT"
    name="IoT"
    content={
      <>
        <Link to="/settings/gateways">
          <ButtonIcon tooltip="Gateways">
            <GateWayIcon />
          </ButtonIcon>
        </Link>
        <Link to="/settings/sensortypes">
          <ButtonIcon tooltip="Sensor Templates">
            <SensorsIcon />
          </ButtonIcon>
        </Link>
      </>
    }
  />
);
