import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  SET_INDEX_ITEMS_SUCCESS: undefined,
  SET_INDEX_ITEMS_FAILURE: undefined,

  FETCH_ALL_INDEX_ITEMS_REQUEST: undefined,
  FETCH_TABLE_FIELDS_SUCCESS: undefined,

  FETCH_LIST_INDEX_RULE_REQUEST: undefined,
  FETCH_LIST_INDEX_RULE_SUCCESS: undefined,
  FETCH_LIST_INDEX_RULE_FAILURE: undefined,

  FETCH_INDEX_ITEM_PREVIEW_REQUEST: undefined,
  FETCH_INDEX_ITEM_PREVIEW_SUCCESS: undefined,
  FETCH_INDEX_ITEM_PREVIEW_FAILURE: undefined,

  FETCH_INDEX_ITEM_CHARACTERISTIC_PREVIEW_REQUEST: undefined,
  FETCH_INDEX_ITEM_CHARACTERISTIC_PREVIEW_SUCCESS: undefined,
  FETCH_INDEX_ITEM_CHARACTERISTIC_PREVIEW_FAILURE: undefined,

  FETCH_SHORTCUT_PREVIEW_REQUEST: undefined,
  FETCH_SHORTCUT_PREVIEW_SUCCESS: undefined,
  FETCH_SHORTCUT_PREVIEW_FAILURE: undefined,

  FETCH_INDEX_ITEM_CONFIG_REQUEST: undefined,
  FETCH_INDEX_ITEM_CONFIG_FAILURE: undefined,

  FETCH_INDEX_ITEM_CHARACTERISTIC_CONFIG_REQUEST: undefined,
  FETCH_INDEX_ITEM_CHARACTERISTIC_CONFIG_SUCCESS: undefined,
  FETCH_INDEX_ITEM_CHARACTERISTIC_CONFIG_FAILURE: undefined,

  CREATE_INDEX_RULE_REQUEST: undefined,
  CREATE_INDEX_RULE_SUCCESS: undefined,
  CREATE_INDEX_RULE_FAILURE: undefined,

  EDIT_INDEX_RULE_REQUEST: undefined,
  EDIT_INDEX_RULE_SUCCESS: undefined,
  EDIT_INDEX_RULE_FAILURE: undefined,

  DELETE_INDEX_RULE_REQUEST: undefined,
  DELETE_INDEX_RULE_SUCCESS: undefined,
  DELETE_INDEX_RULE_FAILURE: undefined,

  SET_INDEX_ITEM_SUCCESS: undefined,
  SET_INDEX_ITEM_FAILURE: undefined,

  FETCH_INDEX_ITEM_REQUEST: undefined,

  CREATE_INDEX_ITEM_REQUEST: undefined,

  EDIT_INDEX_ITEM_REQUEST: undefined,

  DELETE_INDEX_ITEM_REQUEST: undefined,

  SET_INDEX_ITEMS_STATUS: undefined,

  FETCH_CHARACTERISTIC_LIST_REQUEST: undefined,
  SET_CHARACTERISTIC_LIST_SUCCESS: undefined,

  FETCH_CHARACTERISTIC_ITEM_REQUEST: undefined,
  SET_CHARACTERISTIC_ITEM_SUCCESS: undefined,

  CREATE_CHARACTERISTIC_ITEM_REQUEST: undefined,

  UPDATE_CHARACTERISTIC_ITEM_REQUEST: undefined,

  DELETE_CHARACTERISTIC_ITEM_REQUEST: undefined,

  UPDATE_INDEX_ITEM_CONFIG_REQUEST: undefined,
  SET_INDEX_ITEM_CONFIG_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.setIndexItemsSuccess, handlers.setIndexItemsSuccess],
    [actions.setIndexItemsFailure, handlers.setIndexItemsFailure],

    [actions.fetchAllIndexItemsRequest, handlers.fetchAllIndexItemsRequest],

    [actions.fetchListIndexRuleRequest, handlers.fetchListIndexRuleRequest],
    [actions.fetchListIndexRuleSuccess, handlers.fetchListIndexRuleSuccess],
    [actions.fetchListIndexRuleFailure, handlers.fetchListIndexRuleFailure],

    [actions.fetchIndexItemPreviewRequest, handlers.fetchIndexItemPreviewRequest],
    [actions.fetchIndexItemPreviewSuccess, handlers.fetchIndexItemPreviewSuccess],
    [actions.fetchIndexItemPreviewFailure, handlers.fetchIndexItemPreviewFailure],

    [actions.fetchIndexItemCharacteristicPreviewRequest, handlers.fetchIndexItemCharacteristicPreviewRequest],
    [actions.fetchIndexItemCharacteristicPreviewSuccess, handlers.fetchIndexItemCharacteristicPreviewSuccess],
    [actions.fetchIndexItemCharacteristicPreviewFailure, handlers.fetchIndexItemCharacteristicPreviewFailure],

    [actions.fetchShortcutPreviewRequest, handlers.fetchShortcutPreviewRequest],
    [actions.fetchShortcutPreviewSuccess, handlers.fetchShortcutPreviewSuccess],
    [actions.fetchShortcutPreviewFailure, handlers.fetchShortcutPreviewFailure],

    [actions.fetchIndexItemConfigRequest, handlers.fetchIndexItemConfigRequest],
    [actions.fetchIndexItemConfigFailure, handlers.fetchIndexItemConfigFailure],

    [actions.fetchIndexItemCharacteristicConfigRequest, handlers.fetchIndexItemCharacteristicConfigRequest],
    [actions.fetchIndexItemCharacteristicConfigSuccess, handlers.fetchIndexItemCharacteristicConfigSuccess],
    [actions.fetchIndexItemCharacteristicConfigFailure, handlers.fetchIndexItemCharacteristicConfigFailure],

    [actions.createIndexRuleRequest, handlers.createIndexRuleRequest],
    [actions.createIndexRuleSuccess, handlers.createIndexRuleSuccess],
    [actions.createIndexRuleFailure, handlers.createIndexRuleFailure],

    [actions.editIndexRuleRequest, handlers.editIndexRuleRequest],
    [actions.editIndexRuleSuccess, handlers.editIndexRuleSuccess],
    [actions.editIndexRuleFailure, handlers.editIndexRuleFailure],

    [actions.deleteIndexRuleRequest, handlers.deleteIndexRuleRequest],
    [actions.deleteIndexRuleSuccess, handlers.deleteIndexRuleSuccess],
    [actions.deleteIndexRuleFailure, handlers.deleteIndexRuleFailure],

    [actions.setIndexItemSuccess, handlers.setIndexItemSuccess],
    [actions.setIndexItemFailure, handlers.setIndexItemFailure],

    [actions.fetchIndexItemRequest, handlers.fetchIndexItemRequest],

    [actions.createIndexItemRequest, handlers.createIndexItemRequest],

    [actions.editIndexItemRequest, handlers.editIndexItemRequest],

    [actions.deleteIndexItemRequest, handlers.deleteIndexItemRequest],

    [actions.setIndexItemsStatus, handlers.setIndexItemsStatus],

    [actions.fetchCharacteristicListRequest, handlers.fetchCharacteristicListRequest],

    [actions.setCharacteristicListSuccess, handlers.setCharacteristicListSuccess],

    [actions.fetchCharacteristicItemRequest, handlers.fetchCharacteristicItemRequest],

    [actions.setCharacteristicItemSuccess, handlers.setCharacteristicItemSuccess],

    [actions.createCharacteristicItemRequest, handlers.createCharacteristicItemRequest],

    [actions.updateCharacteristicItemRequest, handlers.updateCharacteristicItemRequest],

    [actions.deleteCharacteristicItemRequest, handlers.deleteCharacteristicItemRequest],

    [actions.updateIndexItemConfigRequest, handlers.updateIndexItemConfigRequest],

    [actions.setIndexItemConfigSuccess, handlers.setIndexItemConfigSuccess],
  ]),
  initialState,
);

export default reducer;
