const initialState = {
  error: '',
  iotHubsList: [],
  isLoading: false,
  isLongLoading: false,
  list: [],
  regionsList: [],
  selectedGateway: {},
  serversList: [],
  resourceGroupsList: [],
  tenantHubsList: [],
  newResource: {},
  hubGateways: [],
};

export const getGatewaysRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getGatewaysByHubRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getGatewaysSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  list: payload,
});

export const getGatewaysFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getServerDataRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getServerDataSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  serversList: payload,
});

export const getServerDataFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getIotHubsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getIotHubsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  iotHubsList: payload,
});

export const getIotHubsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getRegionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getRegionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  regionsList: payload,
});

export const getRegionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addServerRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addServerSuccess = state => ({
  ...state,
  isLoading: false,
});

export const addServerFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addHubRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
  isLongLoading: true,
});

export const addHubSuccess = state => ({
  ...state,
  isLoading: false,
  isLongLoading: false,
});

export const addHubFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
  isLongLoading: false,
});

export const deleteServerRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
  isLongLoading: true,
});

export const deleteServerSuccess = state => ({
  ...state,
  isLoading: false,
  isLongLoading: false,
});

export const deleteServerFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
  isLongLoading: false,
});

export const deleteHubRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteHubSuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteHubFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createGatewayRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createGatewaySuccess = state => ({
  ...state,
  isLoading: false,
});

export const createGatewayFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getGatewayRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getGatewaySuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  selectedGateway: payload,
});

export const getGatewayFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editGatewayRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editGatewaySuccess = state => ({
  ...state,
  isLoading: false,
});

export const editGatewayFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteGatewayRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteGatewaySuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteGatewayFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getResourceGroupsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getResourceGroupsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  resourceGroupsList: payload,
});

export const getResourceGroupsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const deleteAllHubsFromTenantRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteAllHubsFromTenantSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deleteAllHubsFromTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const getTenantHubsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getTenantHubsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  resourceGroupsList: payload,
});

export const getTenantHubsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const getTenantHubsListRequest = state => ({
  ...state,
  isLoading: true,
  error: '',
});

export const getTenantHubsListSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  tenantHubsList: payload,
});

export const getTenantHubsListFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload.message,
});

export const deleteAllHubsFromTenantSiteRequest = state => ({
  ...state,
  isLoading: true,
  error: '',
});

export const deleteAllHubsFromTenantSiteSuccess = state => ({
  ...state,
  isLoading: false,
  error: '',
});

export const deleteAllHubsFromTenantSiteFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload.message,
});

export const deleteHubFromTenantSiteRequest = state => ({
  ...state,
  isLoading: true,
  error: '',
});

export const deleteHubFromTenantSiteSuccess = state => ({
  ...state,
  isLoading: false,
  error: '',
});

export const deleteHubFromTenantSiteFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload.message,
});

export const getNewResourceGroupNameByTenantRequest = state => ({
  ...state,
  isLoading: true,
  error: '',
});

export const getNewHubNameBySiteRequest = state => ({
  ...state,
  isLoading: true,
  error: '',
});

export const getNewGatewayNameInHubRequest = state => ({
  ...state,
  isLoading: true,
  isLongLoading: true,
  error: '',
});

export const getNewResourceNameSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  isLongLoading: false,
  newResource: payload,
});

export const getNewResourceNameFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  isLongLoading: false,
  error: payload,
});

export const getDeviceConnectionStringRequest = state => ({
  ...state,
  isLoading: true,
  error: '',
});

export const getDeviceConnectionStringSuccess = state => ({
  ...state,
  isLoading: false,
  error: '',
});

export const getDeviceConnectionStringFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getResourceGroupInfoRequest = state => ({
  ...state,
  isLoading: true,
  error: '',
});

export const getResourceGroupInfoSuccess = state => ({
  ...state,
  isLoading: false,
  error: '',
});

export const getResourceGroupInfoFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const getHubTierInfoRequest = state => ({
  ...state,
  isLoading: true,
  error: '',
});

export const getHubTierInfoSuccess = state => ({
  ...state,
  isLoading: false,
  error: '',
});

export const getHubTierInfoFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const resetField = (state, { payload: { fieldName, value } }) => ({
  ...state,
  [fieldName]: value,
});

export default initialState;
