import { createSelector } from 'reselect';

import { stringComparator } from 'helpers';

const getList = list => list;
const getName = (list, name) => name;

export const getListWithAnassigned = createSelector([getList, getName], (list, name) => {
  const newList = [...list];

  newList.sort((a, b) => stringComparator(a.Name, b.Name, true));

  newList.unshift({
    Id: null,
    Name: `Select a${name === 'item' ? 'n' : ''} ${name}`,
  });

  return newList;
});

export const getSiteName = createSelector(
  [sitesList => sitesList, (sitesList, SiteId) => SiteId],
  (sitesList, siteId) => {
    const sensorsSite = sitesList.find(site => site.id === siteId);

    return sensorsSite ? `${sensorsSite.additionalId ? `${sensorsSite.additionalId} ` : ''}${sensorsSite.name}` : '';
  },
);
