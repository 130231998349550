import styled from 'styled-components';
import { FlatButton } from 'material-ui';
import { InputBlockLabel, BorderRadiusContainer } from 'components/Layout/Containers';

const Button = styled(FlatButton)`
  border: 1px solid ${({ theme }) => theme.mainRed} !important;
  color: ${({ theme }) => theme.mainRed} !important;
  width: 50% !important;
  background: ${({ theme }) => theme.lightGrey} !important;
  align-self: center;
  margin: 8px !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: bold !important;
`;

const Paragraph = styled.p`
  text-align: center;
  font-size: 20px;
  padding: 0 1rem;
  color: ${({ theme }) => theme.primaryBlack};
`;

const Container = styled(BorderRadiusContainer)`
  margin: 0;
  margin-bottom: 1rem;
  padding-bottom: 10px;
  &:last-child {
    margin-bottom: 10px;
    border: 1px solid yellow;
  } // @TODO doesn't work
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primaryLists};
  text-align: start;
  padding: 14px;
`;

export { Button, Paragraph, Container, Label };
