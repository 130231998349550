import styled from 'styled-components';
import { ForwardIcon, BackIcon, FormatLineSpacingIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import { fixedTableHeadStyles } from 'components/TableList/styled';

export const TableContainer = styled.div`
  ${fixedTableHeadStyles}

  & thead {
    color: ${({ theme }) => theme.primaryGrey};
  }

  thead > tr > th {
    font-weight: 500;
  }

  th,
  td {
    padding: 0 20px !important;
  }

  & td:nth-child(-n + 2) {
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
  }

  & td:nth-child(-n + 2) {
    background-color: ${({ theme }) => theme.lightBlue};
  }
`;

export const IconForward = styled(ForwardIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const Back = styled(BackIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const EmptyList = styled.div`
  margin: 0 auto;
  font-size: 16px;
  color: ${({ theme }) => theme.tenantNameColor};
`;

export const IconButton = styled(ButtonIcon).attrs({
  tooltipStyles: {
    top: '24px',
    right: '34px',
  },
})``;

export const FormatLineSpacing = styled(FormatLineSpacingIcon).attrs({})`
  &:active {
    color: ${({ theme }) => theme.primaryRed} !important;
  }
`;
