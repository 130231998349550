import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Content = styled(Flex).attrs({
  column: true,
})`
  height: calc(100% - 64px);
  background-color: ${({ theme }) => theme.lightGrey};
  overflow-y: auto;
`;

export const Styled = styled.div``;
