const validator = values => {
  const errors = {};

  if (!values.NewName) {
    errors.NewName = 'Required';
  }

  if (values.NewName === values.Name) {
    errors.NewName = 'New name should not match Current name';
  }

  return errors;
};

export default validator;
