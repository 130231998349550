import React, { FC } from 'react';

import BaseDialog from 'components/Dialogs/Base';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import * as S from './styled';

type PropsType = {
  open: boolean;
  name: string;
  type: string;
  handleClose: () => void;
  onPositiveHandler: () => void;
};

const DuplicateDialog: FC<PropsType> = ({ name, type, handleClose, open, onPositiveHandler }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <BaseDialog onRequestClose={handleClose} open={open} title={`Duplicate ${type}`}>
    <S.ContentContainer>
      <S.TaskType>{name}</S.TaskType>
      <S.Notification>{`This ${type} will be duplicated`}</S.Notification>
    </S.ContentContainer>
    <ActionsContainer>
      <ActionButton label="OK" isNext onClick={onPositiveHandler} />
    </ActionsContainer>
  </BaseDialog>
);

export default DuplicateDialog;
