import React, { FC } from 'react';
import { AdditionalInfo } from '../AdditionalInfo';
import { Switch } from 'components/Switch';
import { Textarea } from 'components/Textarea';

import * as S from './RequiresAttentionSectionStyles';

type PropsType = {
  formik: any;
};

export const RequiresAttentionSection: FC<PropsType> = ({ formik }) => {
  const { selectedSection } = formik.values;

  const showSelectedSection = (newSelectedSection: string) =>
    formik.setFieldValue('selectedSection', newSelectedSection);

  const hideSelectedSection = () => {
    formik.setFieldValue('selectedSection', '');
    formik.setFieldValue('isPartsRequireAttention', false);
  };

  const onRequiresAttentionToggle = (e: React.MouseEvent, isInputChecked: boolean) =>
    isInputChecked ? showSelectedSection('requiresAttention') : hideSelectedSection();

  return (
    <AdditionalInfo
      name="Requires Attention"
      isShown
      content={
        <div>
          <Switch
            name="isRequiredAttention"
            toggled={selectedSection === 'requiresAttention'}
            onToggle={onRequiresAttentionToggle}
          />
          {selectedSection === 'requiresAttention' && (
            <>
              <S.BoldLabel>Explain the task issue</S.BoldLabel>
              <Textarea
                {...formik.getFieldProps('taskIssue')}
                meta={formik.getFieldMeta('taskIssue')}
                name="taskIssue"
              />
            </>
          )}
        </div>
      }
    />
  );
};
