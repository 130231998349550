import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { DocumentItem } from 'configs/propTypes';
import { getDocumentsIdsList } from 'redux/documents/selectors';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as documentsActions } from 'redux/documents';
import { actions as documentActions } from 'redux/document';
import { BackIcon, SettingsIcon } from 'components/Layout/Icons';
import { ScrollableContentContainer, ContentContainer } from 'components/Layout/Containers';
import Subheader from 'components/Subheader';
import DocumentImage from './DocumentImage';
import Footer from './Footer';
import {
  Column,
  Content,
  Description,
  DescriptionLabel,
  DescriptionText,
  DescriptionMultiText,
  Documents,
} from './styled';
import AddEditDocumentDialog from '../AddEditDocumentDialog';

class DocumentsDetails extends Component {
  static propTypes = {
    clearImagesState: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    deleteRedirectFlag: PropTypes.func.isRequired,
    documentDeleted: PropTypes.bool.isRequired,
    documentEdited: PropTypes.bool.isRequired,
    documentItem: DocumentItem.isRequired,
    documentsIdsList: PropTypes.arrayOf(PropTypes.string).isRequired,
    editDocument: PropTypes.func.isRequired,
    fetchDocument: PropTypes.func.isRequired,
    fetchDocuments: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
  };

  state = {
    editDocumentDialogOpened: false,
  };

  componentDidMount() {
    const {
      fetchDocument,
      fetchDocuments,
      match: {
        params: { documentId },
      },
    } = this.props;

    fetchDocuments();
    fetchDocument(documentId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.documentEdited) {
      this.closeEditDocumentDialog();
    }

    if (nextProps.match.params.documentId !== this.props.match.params.documentId) {
      this.props.fetchDocument(nextProps.match.params.documentId);
    }
  }

  componentWillUnmount() {
    this.props.deleteRedirectFlag();
    this.props.clearImagesState();
  }

  onDeleteDocument = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Document? This cannot be undone!',
      () => this.props.deleteDocument(this.props.documentItem.Id),
      'Delete?',
    );
  };

  onNextDocument = () => {
    const {
      history: { push },
      match: {
        params: { documentId },
      },
      documentsIdsList,
    } = this.props;

    const nextDocumentIndex = documentsIdsList.indexOf(documentId) + 1;
    const index = nextDocumentIndex > documentsIdsList.length - 1 ? 0 : nextDocumentIndex;
    push(`../details/${documentsIdsList[index]}`);
  };

  onPreviousDocument = () => {
    const {
      history: { push },
      match: {
        params: { documentId },
      },
      documentsIdsList,
    } = this.props;

    const prevDocumentIndex = documentsIdsList.indexOf(documentId) - 1;
    const index = prevDocumentIndex < 0 ? documentsIdsList.length - 1 : prevDocumentIndex;
    push(`../details/${documentsIdsList[index]}`);
  };

  closeEditDocumentDialog = () => {
    this.setState({ editDocumentDialogOpened: false });
  };

  goBack = () => {
    this.props.history.push('/documents');
  };

  openEditDocumentDialog = () => {
    this.setState({ editDocumentDialogOpened: true });
  };

  render() {
    const { documentDeleted, documentItem, editDocument } = this.props;
    const { BackgroundImageId, BodyText, FooterImageId, HeaderImageId, HeaderText, PageNumber } = documentItem;

    if (documentDeleted) {
      return <Redirect to="/documents" />;
    }

    return [
      <ContentContainer key="Document details">
        <ScrollableContentContainer>
          <Subheader
            title={documentItem.HeaderText}
            leftButtons={[
              {
                icon: <BackIcon />,
                handler: this.goBack,
                hint: 'Back',
              },
            ]}
            rightButtons={[
              {
                icon: <SettingsIcon />,
                handler: this.openEditDocumentDialog,
                hint: 'Edit',
              },
            ]}
          />
          <Content>
            <Column>
              <Description>
                <DescriptionText>
                  <DescriptionLabel>Page Number:</DescriptionLabel>
                  {PageNumber}
                </DescriptionText>
                <DescriptionMultiText>
                  <DescriptionLabel>Header Text:</DescriptionLabel>
                  {HeaderText}
                </DescriptionMultiText>
                <DescriptionMultiText>
                  <DescriptionLabel>Body Text:</DescriptionLabel>
                  {BodyText}
                </DescriptionMultiText>
              </Description>
            </Column>
            <Column>
              <Documents>
                <DocumentImage id={HeaderImageId} type="Header Image" />
                <DocumentImage id={BackgroundImageId} type="Background Image" />
                <DocumentImage id={FooterImageId} type="Footer Image" />
              </Documents>
            </Column>
          </Content>
          <AddEditDocumentDialog
            documentItem={documentItem}
            handleClose={this.closeEditDocumentDialog}
            handleDelete={this.onDeleteDocument}
            onSubmit={editDocument}
            open={this.state.editDocumentDialogOpened}
            title="Edit Document"
            withDelete
          />
        </ScrollableContentContainer>
      </ContentContainer>,
      <Footer
        key="Document Details Footer"
        onNextDocument={this.onNextDocument}
        onPreviousDocument={this.onPreviousDocument}
      />,
    ];
  }
}

const mapStateToProps = ({ documentItem, documents }) => ({
  documentDeleted: documentItem.documentDeleted,
  documentEdited: documentItem.documentEdited,
  documentItem: documentItem.documentItem,
  documentsIdsList: getDocumentsIdsList(documents),
});

const mapDispatchToProps = {
  clearImagesState: documentActions.imagesStateClear,
  deleteDocument: documentActions.deleteDocumentRequest,
  deleteRedirectFlag: documentActions.redirectFlagUnset,
  editDocument: documentActions.editDocumentRequest,
  fetchDocument: documentActions.documentFetchRequest,
  fetchDocuments: documentsActions.documentsListRequest,
  openConfirmationDialog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentsDetails));
