export const validator = values => {
  const errors = {};

  if (!values.SalutationTaskSpecialist) {
    errors.SalutationTaskSpecialist = true;
  }
  if (!values.IntroTextTaskSpecialist) {
    errors.IntroTextTaskSpecialist = true;
  }
  if (!values.ClosingTextTaskSpecialist) {
    errors.ClosingTextTaskSpecialist = true;
  }
  if (!values.SalutationManagerAdmin) {
    errors.SalutationManagerAdmin = true;
  }
  if (!values.IntroTextManagerAdmin) {
    errors.IntroTextManagerAdmin = true;
  }
  if (!values.ClosingTextManagerAdmin) {
    errors.ClosingTextManagerAdmin = true;
  }
  if (!values.SalutationOEMManagerAdmin) {
    errors.SalutationOEMManagerAdmin = true;
  }
  if (!values.IntroTextOEMManagerAdmin) {
    errors.IntroTextOEMManagerAdmin = true;
  }
  if (!values.ClosingTextOEMManagerAdmin) {
    errors.ClosingTextOEMManagerAdmin = true;
  }

  return errors;
};

export default validator;
