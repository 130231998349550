import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { FieldRadio } from 'components/Layout/Inputs';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import SelectField from 'components/Forms/SelectFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';

import theme from 'theme';

import { matchOperator, constraints } from './constants';
import { changeOptionNameControl } from './helpers';
import * as S from './styled';

const ItemRules = ({
  title,
  rules,
  addOption,
  removeOption,
  onKeyDownHandler,
  onChangeHandler,
  isInitialValues,
  isEditMode,
  firstColumnOptions,
  initialValue,
  values,
}) => (
  <S.Section>
    <S.SectionHeader>Item</S.SectionHeader>
    <S.SectionBody>
      <S.RedTitle>{title}</S.RedTitle>
      <S.Section>
        <S.SectionHeader grey>Characteristics Rules</S.SectionHeader>
        <S.SectionBody>
          <S.MatchSection>
            <span>Match</span>
            <Field name={`match-${rules.name}`} defaultValue={rules.match.toString()}>
              {({ input }) => (
                <S.RadioGroup
                  name={input.name}
                  defaultSelected={rules.match.toString()}
                  onChange={(e, value) => onChangeHandler(e, value, rules.name, input)}
                  selectedValue={rules.match.toString()}
                >
                  {Object.keys(matchOperator).map(key => (
                    <FieldRadio
                      key={key}
                      value={key}
                      label={matchOperator[key]}
                      style={{ width: 'auto', marginRight: '15px', flex: key }}
                      iconStyle={{ fill: input.value === key && theme.primaryScheduler }}
                    />
                  ))}
                </S.RadioGroup>
              )}
            </Field>
          </S.MatchSection>
          {rules.rules.map(rule => (
            <S.RuleContainer key={`${rule.name}${rule.id}`}>
              <Field
                name={`optionName-${rule.id}`}
                component={SelectField}
                autoWidth
                onChangeControl={() => changeOptionNameControl(values, rule)}
                hintText={firstColumnOptions[0]}
                initialValue={isInitialValues ? rule[`optionName-${rule.id}`] : initialValue.is}
              >
                {firstColumnOptions.map(item => (
                  <SelectItem key={item} value={item} primaryText={item} />
                ))}
              </Field>
              <Field
                name={`constraint-${rule.id}`}
                component={SelectField}
                hintText={constraints[0]}
                disabled={isEditMode}
                initialValue={
                  isInitialValues && rule.optionConstraint !== 0 ? `${rule[`constraint-${rule.id}`]}` : initialValue.is
                }
              >
                {Object.keys(constraints).map(key => (
                  <SelectItem key={constraints[key]} value={key} primaryText={constraints[key]} />
                ))}
              </Field>
              <Field
                name={`optionValue-${rule.id}`}
                component={TextFieldAdapter}
                hintText="Enter Parameter"
                type={'text'}
                initialValue={isInitialValues ? rule[`optionValue-${rule.id}`] : initialValue.is}
                onKeyDown={e => onKeyDownHandler(e)}
              />
              <S.RuleButtonsGroup>
                {rules.rules.length > 1 ? (
                  <S.IconButton onClick={() => removeOption(rule.name, rule.id)}>
                    <S.RemoveIcon />
                  </S.IconButton>
                ) : null}
                <S.IconButton onClick={() => addOption(rule.name)}>
                  <S.AddIcon />
                </S.IconButton>
              </S.RuleButtonsGroup>
            </S.RuleContainer>
          ))}
        </S.SectionBody>
      </S.Section>
    </S.SectionBody>
  </S.Section>
);

ItemRules.propTypes = {
  title: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    name: PropTypes.string,
    match: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        optionName: PropTypes.string,
        optionConstraint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        optionValue: PropTypes.string,
        matchOperator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }).isRequired,
  isInitialValues: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  addOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  onKeyDownHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    is: PropTypes.string,
  }),
  values: PropTypes.shape().isRequired,
  firstColumnOptions: PropTypes.array.isRequired,
};

ItemRules.defaultProps = {
  initialValue: {
    is: '',
  },
};

export default ItemRules;
