import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as CS from 'components/Dialogs/styled';

import * as S from './styled';

const BASE_INDEX = 1000;

class CheckboxList extends Component {
  static propTypes = {
    selectedPage: PropTypes.shape().isRequired,
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setSelectedPage: PropTypes.func.isRequired,
  };

  onItemCheck = id => {
    const { setSelectedPage, list } = this.props;
    const page = list.find(item => item.Id === id);
    setSelectedPage(page);
  };

  onCheckboxClick = e => {
    e.stopPropagation();
  };

  render() {
    const { selectedPage, list } = this.props;

    return (
      <S.Container>
        <S.ListContainer>
          {list.length > 0 &&
            list.map(({ Id, Name, PublishedName }, index) => (
              <S.ListItemContainer key={Id} index={BASE_INDEX - index}>
                <S.ListItem disabled={PublishedName} onClick={() => (!PublishedName ? this.onItemCheck(Id) : null)}>
                  <S.ItemNameAndCheckbox>
                    <S.ItemRadio
                      checked={selectedPage.Id === Id}
                      onCheck={() => this.onItemCheck(Id)}
                      onClick={this.onCheckboxClick}
                      isRed={selectedPage.Id === Id}
                      disabled={PublishedName}
                    />
                    <S.SelectionItems>
                      <span>{Name}</span>
                      <span>{PublishedName}</span>
                    </S.SelectionItems>
                  </S.ItemNameAndCheckbox>
                </S.ListItem>
                <CS.DividerLine />
              </S.ListItemContainer>
            ))}
        </S.ListContainer>
      </S.Container>
    );
  }
}

export default CheckboxList;
