import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { PaperContainer } from 'components/Layout/Containers';
import { ButtonIcon } from 'components/Layout/Buttons';

const getTooltipPositionLeft = tooltip => {
  if (tooltip === 'Global') {
    return -10;
  } else if (tooltip === 'System') {
    return -13;
  }
  return -25;
};

export const IconStyle = {
  color: '#ffffff',
  height: '40px',
  width: '40px',
};

export const IconOptionStyle = {
  color: '#666666',
  height: '17px',
  width: '17px',
};

export const Instruction = styled(Flex).attrs({
  mb: '18px',
})`
  border: ${({ isHome }) => (isHome ? 'none' : '1px solid #fff')};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.46) 0px 3px 1px;
`;

export const ModuleIcon = styled(PaperContainer)`
  min-width: 120px;
  min-height: 120px;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  background-color: ${({ color }) => color} !important;
`;

export const Title = styled.div`
  font-family: 'Roboto Light', sans-serif;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 100;
  color: ${({ module, theme }) => (module === 'Health' ? theme.textGray : theme.primaryWhite)};
`;

export const InfoWrapper = styled.div`
  width: 100%;
  min-height: 90px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
`;

export const Purpose = styled.div`
  display: flex;
  font-size: 12px;
`;

export const PurposeBold = styled.div`
  font-weight: 500;
`;

export const Options = styled.div``;

export const Option = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const Text = styled.p`
  margin: 0 0 0 10px;
  font-size: 12px;
  font-weight: 100;
`;

export const SpecificIcon = styled(ButtonIcon).attrs({
  disableTouchRipple: true,
  tooltipStyles: props => ({
    top: 10,
    left: getTooltipPositionLeft(props.tooltip),
  }),
  iconStyle: {
    color: '#cccccc',
    height: '15px',
    width: '15px',
  },
})`
  width: 28px !important;
  height: 28px !important;
  padding: 0 !important;
  position: absolute !important;
  top: 0;
  right: 5px;
  & > div {
    padding: 5px 10px !important;
  }
  & > div > span {
    z-index: 1000 !important;
  }

  &:hover {
    transform: translateZ(0) scale(1);
  }
`;
