import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as settingsActions } from 'redux/settings';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import BaseDialog from 'components/Dialogs/Base';
import LogoField from './LogoField';

import * as S from './styled';

class LogoDialog extends PureComponent {
  static propTypes = {
    existsLogoFromTenant: PropTypes.bool,
    existsLogoEmailFromTenant: PropTypes.bool,
    isLoadingEmailLogo: PropTypes.bool.isRequired,
    isLoadingPDFLogo: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    hasLogoEmail: PropTypes.bool.isRequired,
    hasLogoPDF: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    requestDeletePhoto: PropTypes.func.isRequired,
    requestDeletePhotoEmail: PropTypes.func.isRequired,
    requestUploadPhoto: PropTypes.func.isRequired,
    requestUploadPhotoEmail: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    userType: PropTypes.number.isRequired,
  };

  static defaultProps = {
    existsLogoFromTenant: false,
    existsLogoEmailFromTenant: false,
  };

  state = {
    emailFiles: [],
    emailValue: '',
    pdfFiles: [],
    pdfValue: '',
  };

  onDeleteEmail = () => {
    const { userType } = this.props;
    const isMaster = userType === 8;

    this.props.openConfirmationDialog(
      'Are you sure you want to delete email Logo?',
      () => this.props.requestDeletePhotoEmail(isMaster),
      'Delete Photo?',
    );
  };

  onDeletePDF = () => {
    const { userType } = this.props;
    const isMaster = userType === 8;

    this.props.openConfirmationDialog(
      'Are you sure you want to delete PDF Logo?',
      () => this.props.requestDeletePhoto(isMaster),
      'Delete Photo?',
    );
  };

  onDropEmail = event => {
    this.setState({
      emailFiles: event.target.files,
      emailValue: event.target.value,
    });
  };

  onDropPDF = event => {
    this.setState({
      pdfFiles: event.target.files,
      pdfValue: event.target.value,
    });
  };

  onUploadEmail = () => {
    const { userType } = this.props;

    this.props.requestUploadPhotoEmail({
      file: this.state.emailFiles,
      isMaster: userType === 8,
    });
    this.setState({ emailFiles: [], emailValue: '' });
  };

  onUploadPDF = () => {
    const { userType } = this.props;

    this.props.requestUploadPhoto({
      file: this.state.pdfFiles,
      isMaster: userType === 8,
    });
    this.setState({ pdfFiles: [], pdfValue: '' });
  };

  onRequestClose = () => {
    const { handleClose } = this.props;

    this.setState(
      {
        emailValue: '',
        pdfValue: '',
      },
      () => handleClose(),
    );
  };

  render() {
    const {
      existsLogoFromTenant,
      existsLogoEmailFromTenant,
      isLoadingEmailLogo,
      isLoadingPDFLogo,
      hasLogoEmail,
      hasLogoPDF,
      open,
      title,
      userType,
    } = this.props;
    const { emailFiles, emailValue, pdfFiles, pdfValue } = this.state;

    return (
      <BaseDialog open={open} onRequestClose={this.onRequestClose} title={title}>
        <S.FormContainer>
          <LogoField
            description="PDF report Logo"
            files={pdfFiles}
            isLoading={isLoadingPDFLogo}
            isMaster={userType === 8}
            isPDF
            isTenantLogo={existsLogoFromTenant}
            hasLogo={hasLogoPDF}
            onDelete={this.onDeletePDF}
            onDrop={this.onDropPDF}
            onUpload={this.onUploadPDF}
            size="130x60"
            value={pdfValue}
          />
          <LogoField
            description="Email Logo"
            files={emailFiles}
            isLoading={isLoadingEmailLogo}
            isMaster={userType === 8}
            isTenantLogo={existsLogoEmailFromTenant}
            hasLogo={hasLogoEmail}
            onDelete={this.onDeleteEmail}
            onDrop={this.onDropEmail}
            onUpload={this.onUploadEmail}
            size="60x60"
            value={emailValue}
          />
        </S.FormContainer>
      </BaseDialog>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => ({
  existsLogoFromTenant: settings.list.ExistsLogoFromTenant,
  existsLogoEmailFromTenant: settings.list.ExistsLogoEmailFromTenant,
  isLoadingEmailLogo: settings.loadingEmailLogo,
  isLoadingPDFLogo: settings.loadingPDFLogo,
  hasLogoEmail: settings.logo.email,
  hasLogoPDF: settings.logo.pdf,
  userType: auth.user.userType,
});

const mapDispatchToProps = {
  openConfirmationDialog,
  requestDeletePhoto: settingsActions.deleteLogoRequest,
  requestDeletePhotoEmail: settingsActions.deleteLogoEmailRequest,
  requestUploadPhoto: settingsActions.uploadLogoRequest,
  requestUploadPhotoEmail: settingsActions.uploadLogoEmailRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoDialog);
