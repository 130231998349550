import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { UnitItemPictureType, UnitPictureType } from 'configs/propTypes';
import SimpleList from 'components/SimpleList';
import { DeleteIcon, EditIcon, VisibilityIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import ImageSwiper from 'components/ImageSwiper';

import * as S from './styled';

const renderItemContent = photo => photo.Name || 'No name image';

const PhotosBody = ({ photos, getUnitImage, onUpload, onDelete }) => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState('');
  const [isEditImage, setIsEditImage] = useState(false);
  const [lastUploadedPicture] = files;

  const onDrop = event => {
    setFiles([...event.target.files, ...files]);
    setValue(event.target.value);
  };

  const upload = () => {
    onUpload(files);
    setFiles([]);
    setValue('');
  };

  const goToEdit = () => setIsEditImage(true);

  const goToImages = () => setIsEditImage(false);

  const renderRightControls = photo => (
    <ButtonIcon onClick={() => onDelete(photo.Id, photo.Name)} tooltip="Delete">
      <DeleteIcon />
    </ButtonIcon>
  );

  return (
    <>
      <S.FileSection>
        <S.FileSectionHeader>
          <S.IconContainer>
            <S.IconImage />
          </S.IconContainer>
          <S.IconButton>
            {!isEditImage ? <EditIcon onClick={goToEdit} /> : <VisibilityIcon onClick={goToImages} />}
          </S.IconButton>
        </S.FileSectionHeader>
        <>
          {isEditImage ? (
            <S.ListContainer>
              <SimpleList
                emptyListMessage="There are no photos, yet."
                data={photos}
                renderItemContent={renderItemContent}
                renderRightControls={renderRightControls}
              />
            </S.ListContainer>
          ) : (
            <ImageSwiper
              getImageSource={getUnitImage}
              noPicturesMessage="There are no photos, yet."
              pictures={photos}
            />
          )}
          <S.UploadImageRow>
            <S.InputButton accept="image/*" id="image" onChange={onDrop} type="file" value={value} />
            <S.LabelButton htmlFor="image">Choose File</S.LabelButton>
            <S.FileName>{lastUploadedPicture?.name}</S.FileName>
            <S.UploadButton label="Upload New Photo" onClick={upload} disabled={!value} />
          </S.UploadImageRow>
        </>
      </S.FileSection>
    </>
  );
};

PhotosBody.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  getUnitImage: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(PropTypes.oneOfType([UnitItemPictureType, UnitPictureType])).isRequired,
};

export default PhotosBody;
