import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';

import { ScheduleDto } from 'configs/propTypes';

import MenuItem from 'material-ui/MenuItem';
import withInputModal from 'components/Dialogs/withInputModal';
import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import TextTooltipFieldAdapter from 'components/Forms/TextTooltipFieldAdapter';

import validate from './validator';

import { Block, FormContainer, Label, InputContainer, ListContainer } from './styled';

const SCHEDULE_DATA = {
  '': [],
  OneTime: [{ floatingLabelText: 'Date', name: 'ScheduleData.ScheduledDate', component: DatePickerAdapter }],
  RecurringDaily: [
    { floatingLabelText: 'Start Date', name: 'ScheduleData.DailyStartDate', component: DatePickerAdapter },
    { floatingLabelText: 'Repeat Until', name: 'ScheduleData.DailyRepeatUntil', component: DatePickerAdapter },
    {
      floatingLabelText: 'Repeat Interval',
      name: 'ScheduleData.DailyRepeatInterval',
      component: TextFieldAdapter,
      type: 'number',
    },
  ],
  RecurringWeekly: [
    { floatingLabelText: 'Start Date', name: 'ScheduleData.WeeklyStartDate', component: DatePickerAdapter },
    { floatingLabelText: 'Repeat Until', name: 'ScheduleData.WeeklyRepeatUntil', component: DatePickerAdapter },
    {
      floatingLabelText: 'Repeat Interval',
      name: 'ScheduleData.WeeklyRepeatInterval',
      component: TextFieldAdapter,
      type: 'number',
    },
  ],
  RecurringMonthly: [
    { floatingLabelText: 'Start Date', name: 'ScheduleData.MonthlyStartDate', component: DatePickerAdapter },
    { floatingLabelText: 'Repeat Until', name: 'ScheduleData.MonthlyRepeatUntil', component: DatePickerAdapter },
    {
      floatingLabelText: 'Repeat Interval',
      name: 'ScheduleData.MonthlyRepeatInterval',
      component: TextFieldAdapter,
      type: 'number',
    },
  ],
};

const tooltipText = (
  <ListContainer>
    <li>1 = every period</li>
    <li>2 = every other period (skip 1)</li>
    <li>3 = every other period (skip 2)</li>
    <li>etc.</li>
  </ListContainer>
);

const EditCheqListForm = () => (
  <FormContainer>
    <Field component={SelectFieldAdapter} name="ScheduleType" floatingLabelText="Schedule type">
      <MenuItem value="OneTime" primaryText="One Time" />
      <MenuItem value="RecurringDaily" primaryText="Daily" />
      <MenuItem value="RecurringWeekly" primaryText="Weekly" />
      <MenuItem value="RecurringMonthly" primaryText="Monthly" />
    </Field>
    <Block>
      <Label>Schedule Data</Label>
      <InputContainer>
        <FormSpy subscription={{ values: true }}>
          {({ values }) =>
            SCHEDULE_DATA[values.ScheduleType].map(item =>
              item.type ? (
                <TextTooltipFieldAdapter
                  key={item.name}
                  name={item.name}
                  labelText={item.floatingLabelText}
                  tooltip={tooltipText}
                  tooltipWidth="150px"
                  isHelp
                  isTooltipTop
                  {...item}
                />
              ) : (
                <Field key={item.name} name={item.name} component={item.component} {...item} />
              ),
            )
          }
        </FormSpy>
      </InputContainer>
    </Block>
  </FormContainer>
);

const EditCheqListWithInput = withInputModal(EditCheqListForm);

const onSubmit = (values, props) => {
  const { scheduleId, unitId, handleClose } = props;

  props.changeSchedule({
    scheduleId,
    unitId,
    scheduleInfo: values,
    closeModal: handleClose,
  });
};

const EditCheqListModal = props => (
  <EditCheqListWithInput
    {...props}
    title="Edit CheqList Schedule"
    onSubmit={values => onSubmit(values, props)}
    initialValues={props.scheduleDto}
    validate={validate}
  />
);

EditCheqListModal.propTypes = {
  unitId: PropTypes.string.isRequired,
  scheduleId: PropTypes.string.isRequired,
  scheduleDto: ScheduleDto.isRequired,
  changeSchedule: PropTypes.func.isRequired,
};

export default EditCheqListModal;
